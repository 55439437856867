import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useEffect } from 'react';

import { Globals } from '~/components/Globals/Globals';
import { TabActions, useTabs } from '~/components/Tabs/Tabs.web';

import type {
  NavType,
  RootStackParamList,
  ScreenDictionary,
} from '../navigation/Navigation.types';

const findScreenName = (
  key: string,
  item: ScreenDictionary[0],
  prevMemo: string[],
): string[] => {
  if (item.name === key) {
    return [...prevMemo, item.name];
  }

  let returnList = prevMemo;
  if (item.children) {
    let currentGroupName = '';
    for (let index = 0; index < item.children.length; index++) {
      const group = item.children[index];
      currentGroupName = group.groupName;
      const foundList = findScreenNameList(key, group.list, prevMemo);
      if (foundList.length) {
        returnList = [currentGroupName, ...foundList];
        break;
      }
    }
  }

  return returnList;
};

const findScreenNameList = (key: string, list: ScreenDictionary, prev: string[]) => {
  let foundList = [] as string[];
  for (let index = 0; index < list.length; index++) {
    const screenItem = list[index];
    const foundItem = findScreenName(key, screenItem, prev);
    if (foundItem.length) {
      foundList = [screenItem.name, ...foundItem];
      break;
    }
  }
  return foundList;
};

const getParamsUrl = (params: any) => {
  return Object.keys(params)
    .map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
    })
    .join('&');
};

export const writeUrl = (key: keyof RootStackParamList, params?: any) => {
  if (isWeb) {
    const paths = findScreenNameList(key, Globals.getScreenDictionary(), []).slice(0, -1);

    const newPath =
      `/${paths
        .map((p) =>
          p
            .replace(/ /g, '-')
            .split(/(?=[A-Z])/)
            .join('-')
            .toLowerCase(),
        )
        .join('/')}` + (params ? `?${getParamsUrl(params)}` : '');

    window.history.pushState('', '', newPath);
  }
};

export const useAttachParamsToUrl = (params?: any) => {
  const data = useRoute();
  useEffect(() => {
    if (isWeb && params) {
      writeUrl(data.name as any, params);
    }
  }, [data.name, params]);
};

export const useNavigateByTab = () => {
  const tabs = useTabs();

  return (screenName: string, params: any) => {
    const foundScreen = Globals.flattenScreens().find((s) => s.name === screenName);
    if (foundScreen) {
      const numberOfTabs = tabs.filter((t) => t.key.includes(screenName)).length;
      const title =
        params?.tabTitle ||
        (numberOfTabs > 0
          ? `${foundScreen?.label} (${numberOfTabs + 1})`
          : foundScreen?.label);

      const key =
        params?.key ||
        (numberOfTabs > 0
          ? `${foundScreen?.name} (${numberOfTabs + 1})`
          : foundScreen?.name);

      const Screen = foundScreen.component;

      if (foundScreen.singleTab) {
        const result = TabActions.selectTab(screenName, params);
        if (result) {
          return;
        }
      }

      TabActions.addTab({
        screen: <Screen route={{ params }} />,
        title,
        key,
        screenKey: screenName,
      });
    }
  };
};

export const useAppNavigation = () => {
  const navigate = useNavigation<NavType>();
  const tabNav = useNavigateByTab();

  return {
    navigate: ((screenName: any, params?: any) => {
      if (isNativeMobile) {
        navigate.navigate(screenName, params);
      } else {
        writeUrl(screenName, params);
        tabNav(screenName, params);
      }
    }) as NavType['navigate'],

    canGoBack: () => {
      if (isNativeMobile) {
        return navigate.canGoBack();
      }

      return false;
    },
    goBack: (() => {
      if (isNativeMobile) {
        navigate.goBack();
      }
    }) as NavType['goBack'],
    reset: ((...props) => {
      if (isNativeMobile) {
        navigate.reset(...props);
      }
    }) as NavType['reset'],
    dispatch: ((...props) => {
      if (isNativeMobile) {
        navigate.dispatch(...props);
      }
    }) as NavType['dispatch'],
  };
};

// export const use

export function useNavigateBack() {
  const navigation = useAppNavigation();
  return {
    navigation,
    goBack: () => {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Home' }],
        });
      }
    },
  };
}
