import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { SupportRequestOptions } from '@naus-code/naus-client-types';
import JSONTree from 'react-native-json-tree';

import { LinkText } from '~/components/LinkText';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { translate } from '~/utils/translation.utils';

export const RequestOptionsJSON = ({ options }: { options: SupportRequestOptions }) => {
  const modalKey = 'RequestOptionsJSON';
  const { openModal, closeModal } = useModal();

  return (
    <LinkText
      textStyle={{ textAlign: 'right' }}
      onPress={() => {
        openModal(
          <Modal.Container>
            <Modal.Header
              title={translate('supportRequests.details')}
              divider
              onPress={() => {
                closeModal(modalKey);
              }}
            />
            <ModalScrollView>
              <JSONTree
                shouldExpandNode={() => true}
                data={options as any}
                theme={{
                  scheme: 'google',
                  author: 'seth wright (http://sethawright.com)',
                  base00: '#1d1f21',
                  base01: '#282a2e',
                  base02: '#373b41',
                  base03: '#969896',
                  base04: '#b4b7b4',
                  base05: '#c5c8c6',
                  base06: '#e0e0e0',
                  base07: '#ffffff',
                  base08: '#CC342B',
                  base09: '#F96A38',
                  base0A: '#FBA922',
                  base0B: '#198844',
                  base0C: '#3971ED',
                  base0D: '#3971ED',
                  base0E: '#A36AC7',
                  base0F: '#3971ED',
                }}
                valueRenderer={(v) => {
                  return (
                    <View>
                      <Text.Small bold color="secondary-mid">
                        {v as any}
                      </Text.Small>
                    </View>
                  );
                }}
              />
            </ModalScrollView>
          </Modal.Container>,
          { key: modalKey },
        );
      }}
    >
      {translate('supportRequests.showRawData')}
    </LinkText>
  );
};
