import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { useCallback } from 'react';

import { PressableCard } from '~/components/Card/PressableCard';
import { useInsertTemplateAnswer } from '~/components/TemplateAnswers/TemplateAnswers';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { SupportConversationInputPanelProps } from './Conversation.utils';

export const TemplateButton = (props: SupportConversationInputPanelProps) => {
  const { openModal: openInsertAnswerTemplate } = useInsertTemplateAnswer();

  const onPressTemplates = useCallback(() => {
    const supportRequest = props.supportRequestRes?.supportRequest;
    if (supportRequest) {
      openInsertAnswerTemplate(supportRequest, props.textRef, props.advancedRef);
    }
  }, [props.supportRequestRes?.supportRequest]);

  return (
    <PressableCard onPress={onPressTemplates} bc="monochrome-light">
      <HStack space="1" justify="center" py="2">
        <AppText.MiscTag>{translate('supportRequests.templates')}</AppText.MiscTag>
        <Icon color="monochrome" icon="feedback" />
      </HStack>
    </PressableCard>
  );
};
