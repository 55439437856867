import { useGetAuthUser } from '~/screens/Auth/Auth.Context';
import { usersApi } from '~/screens/Users/Users.api';

export const useIsSystemUser = () => {
  const { currentUser } = useGetAuthUser();
  const userId = currentUser?.uid || '';
  const { data: userData, isLoading } = usersApi.useGetUserItemQuery(userId);

  if (!userData) {
    return false;
  }
  if (!userData && !isLoading) {
    return false;
  }

  return userData.adminUser.role === 'SYSTEM';
};
