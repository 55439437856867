/* eslint simple-import-sort/imports: 0 */
import { Text } from 'react-native';
import { Main } from './src/Main';

/**
 * Global imports
 */
import '~/navigation/Navigation.screens';
import '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.Menu';
import '~/screens/Support/Purchases/PurchaseCommon/Purchase.History';
import '~/components/Customer/Customer.Item';

(Text as any).defaultProps = (Text as any).defaultProps || {};
(Text as any).defaultProps.allowFontScaling = false;

export default Main;
