import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { CompanyConfigClientUpdatePassengerOperationsRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { companyConfigApi } from '~/screens/Configs/Company/CompanyConfigs.api';
import { translate } from '~/utils/translation.utils';

const updatePassengerOperationConfigModalKey = 'UpdatePassengerOperationConfig-ModalKey';

export const useUpdatePassengerOperationConfigConfigModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: ({
      companyId,
      passengerConfigCode,
      check,
    }: {
      companyId: string;
      passengerConfigCode: string;
      check: string;
    }) => {
      openModal(
        <UpdatePassengerOperationConfigConfigComponent
          companyId={companyId}
          passengerConfigCode={passengerConfigCode}
          check={check}
        />,
        {
          key: updatePassengerOperationConfigModalKey,
          type: 'action-sheet-full',
        },
      );
    },
    close: () => {
      closeModal(updatePassengerOperationConfigModalKey);
    },
  };
};

const UpdatePassengerOperationConfigConfigComponent = ({
  companyId,
  passengerConfigCode,
  check,
}: {
  companyId: string;
  passengerConfigCode: string;
  check: string;
}) => {
  const formRef = useRef(new FormHandler());
  const { closeModal } = useModal();

  const { data, isLoading: isLoadingForm } =
    companyConfigApi.useUpdateFerryPassengerOperationFormConfigQuery({
      companyId,
      ticketCode: passengerConfigCode,
      check,
    });

  const [updatePassengerOperationConfigConfig, { isLoading: isUpdating }] =
    companyConfigApi.useUpdateFerryPassengerOperationMutation();
  if (!data) {
    return null;
  }
  const { check: formCheck } = data;

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('companyConfig.updatePassengerOperationConfig')}
        onPress={() => closeModal(updatePassengerOperationConfigModalKey)}
      />
      <ModalScrollView>
        <FormContent isLoading={isLoadingForm} form={data.config} flex={1}>
          <FormBuilder data={data.config} form={formRef.current} />
        </FormContent>
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isUpdating}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              CompanyConfigClientUpdatePassengerOperationsRequest['config']
            >();

          if (valid) {
            const response = await updatePassengerOperationConfigConfig({
              config: value,
              passengerConfigCode,
              companyId,
              check: formCheck,
            });
            handleResponse({
              response,
              onSuccess: () => {
                closeModal(updatePassengerOperationConfigModalKey);
              },
              onError: () => {
                closeModal(updatePassengerOperationConfigModalKey);
              },
            });
          }
        }}
        onCancel={() => closeModal(updatePassengerOperationConfigModalKey)}
      />
    </Modal.Container>
  );
};
