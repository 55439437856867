import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  getAuth,
  initializeAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { getReactNativePersistence } from 'firebase/auth/react-native';

import { isDev } from '~/utils/constants.utils';

class FirebaseController {
  private _app: FirebaseApp;
  private _authFb: Auth;

  constructor() {
    this._app = initializeApp(JSON.parse(__FIREBASE_SERVICES__), 'app');
    this._authFb = isNativeMobile
      ? initializeAuth(this._app, {
          persistence: getReactNativePersistence(AsyncStorage),
        })
      : getAuth(this._app);
  }

  getDevMode() {
    return isDev;
  }

  private _auth = (): Auth => {
    return this._authFb;
  };

  async initiateNotifications() {}

  getAuth() {
    return this._auth();
  }

  async sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(this._auth(), email);
  }

  async signOut() {
    return this._auth().signOut().catch(console.error);
  }

  async signIn(email: string, password: string) {
    return signInWithEmailAndPassword(this._auth(), email, password).catch(console.error);
  }

  getPushId = () => {
    // Web no push id
    return '';
  };

  getCurrentUser = () => {
    return this._auth().currentUser;
  };
}

export const __DONT__IMPORT__firebase = new FirebaseController();
