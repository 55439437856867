import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect } from 'react';

import { SwitchStack } from '~/components/Stack/SwitchStack';
import { PurchaseListItemInternalComponent } from '~/screens/Support/Purchases/Purchase.ListItem';
import {
  purchaseActionManager,
  TicketSelectionPurchaseProps,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import { TicketDetails } from '~/screens/Support/Purchases/PurchaseItem';

export const getSelectedTickets = (
  props: TicketSelectionPurchaseProps & { forceSelectAll?: boolean },
) => {
  const purchaseTickets = props.purchase.tickets;
  const supportTicketIds = props.forceSelectAll
    ? purchaseTickets.map((t) => t._id)
    : props.supportRequest.ticketIds;
  const baseTickets = supportTicketIds
    ? purchaseTickets.filter((t) => supportTicketIds.some((id) => id === t._id))
    : purchaseTickets;

  return {
    relevantTicketIds: baseTickets.map((t) => t._id),
    relevantTickets: baseTickets,
    purchaseTickets,
  };
};

export const PurchaseTicketsToSelect = (
  props: TicketSelectionPurchaseProps & {
    title: string;
    forceSelectAll?: boolean;
    noBorder?: boolean;
  },
) => {
  const { relevantTicketIds, relevantTickets, purchaseTickets } =
    getSelectedTickets(props);

  const [checkedItems, setCheckedItems] =
    purchaseActionManager.state.useGlobalState('selectedTickets');

  useEffect(() => {
    setCheckedItems(relevantTicketIds);
  }, [relevantTicketIds.length]);

  return (
    <VStack p="3" space="6" bc="monochrome-extraLight" b={props.noBorder ? 0 : 1} br={12}>
      <Text.Small color="monochrome-mid">{props.title}</Text.Small>
      <SwitchStack space={isWeb ? '6' : '3'}>
        <VStack space="3" flex={isWeb ? 1 : undefined}>
          <HStack space="6">
            <CheckBox
              checked={
                props.forceSelectAll || checkedItems.length === relevantTicketIds.length
              }
              onPress={(isTicketChecked) => {
                if (props.forceSelectAll) {
                  return;
                }

                if (isTicketChecked) {
                  setCheckedItems(relevantTicketIds);
                } else {
                  setCheckedItems([]);
                }
              }}
            />
            <PurchaseListItemInternalComponent purchase={props.purchase} compareMode />
          </HStack>
          <View>
            <DottedLine />
          </View>
          <VStack space="4" flex={isWeb ? 1 : undefined}>
            {purchaseTickets.map((ticket, iy) => {
              const disabledTicket = !relevantTicketIds.some((id) => id === ticket._id);

              if (disabledTicket && !props.forceSelectAll) {
                return (
                  <HStack space="3" key={ticket._id} pl="6" style={{ opacity: 0.5 }}>
                    <CheckBox disabled checked={false} style={{ opacity: 0 }} />
                    <VStack space="2" flex={isNativeMobile ? 1 : undefined}>
                      <TicketDetails
                        ticket={ticket}
                        ccySymbol={props.purchase.ccySymbol}
                      />
                      <View>
                        {relevantTickets.length - 1 === iy ? null : <DottedLine />}
                      </View>
                    </VStack>
                  </HStack>
                );
              }

              return (
                <HStack space="3" key={ticket._id} pl="6">
                  <CheckBox
                    checked={
                      props.forceSelectAll || checkedItems.some((t) => t === ticket._id)
                    }
                    onPress={(isChecked) => {
                      if (props.forceSelectAll) {
                        return;
                      }

                      const newItems = checkedItems.filter((t) => t !== ticket._id);

                      setCheckedItems(isChecked ? [...newItems, ticket._id] : newItems);
                    }}
                  />
                  <VStack space="2" flex={isNativeMobile ? 1 : undefined}>
                    <TicketDetails ticket={ticket} ccySymbol={props.purchase.ccySymbol} />
                    <View>
                      {relevantTickets.length - 1 === iy ? null : <DottedLine />}
                    </View>
                  </VStack>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </SwitchStack>
    </VStack>
  );
};
