import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { FerryCompanyConfigListItem } from '@naus-code/naus-admin-types';
import { Image } from 'react-native';

export interface CompanyConfigListItemComponentProps {
  companyConfig: FerryCompanyConfigListItem;
}

export const CompanyConfigListItemComponent = ({
  companyConfig,
}: CompanyConfigListItemComponentProps) => {
  const { id, title, isBookingDisabled, operators } = companyConfig;
  return (
    <HStack space="2.5" flex={1}>
      <VStack flex={1}>
        <Text.Body2Medium color="monochrome">{id}</Text.Body2Medium>
        <Text.Small flex={1} bold numberOfLines={1}>
          {title}
        </Text.Small>
      </VStack>
      <OperatorImages operators={operators} />
      {isBookingDisabled ? <Icon color="monochrome" icon="disconnected" /> : null}
    </HStack>
  );
};

export const OperatorImages = ({
  operators,
}: {
  operators: {
    name: string;
    imgUrl: string;
  }[];
}) => {
  return (
    <HStack space="1">
      {operators.map((operator, ix) => {
        return (
          <View style={{ alignSelf: 'stretch' }} key={ix}>
            <CompanyImage imageUrl={operator.imgUrl} />
          </View>
        );
      })}
    </HStack>
  );
};

export const CompanyImage = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <View>
      <Image
        source={{
          uri: imageUrl,
        }}
        style={{ width: 40, height: 40, borderRadius: 12 }}
        resizeMode="contain"
      />
    </View>
  );
};
