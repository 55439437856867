import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

import { useAddCompanyConfig } from './CompanyConfigs.Add';
import { companyConfigApi } from './CompanyConfigs.api';

export const CompanyConfigListHeader = () => {
  const { openModal: openAddModal } = useAddCompanyConfig();

  return (
    <PageHeader
      title={translate('companyConfig.companyConfigs')}
      withBack={isNativeMobile}
      Subtitle={CompaniesSubtitle()}
      iconRight={{
        color: 'monochrome',
        icon: 'plus',
        onPress: () => openAddModal(),
      }}
    />
  );
};

const CompaniesSubtitle = () => {
  const { data: companiesData } = companyConfigApi.useGetCompanyConfigListQuery();
  if (companiesData === undefined) {
    return null;
  }

  const companyList = companiesData.ferryCompanies;

  return (
    <Text.Body2Regular color="monochrome-mid">{`${companyList.length} ${translate(
      'companyConfig.companies',
    )}`}</Text.Body2Regular>
  );
};
