import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { RouteNoDetails } from '@naus-code/naus-client-types';
import { useRef, useState } from 'react';

import { SearchList, SearchParams } from '~/components/Search/Search.List';
import { SearchPanel } from '~/components/Search/Search.Panel';

export const SearchConstructorPanel = (
  props: Partial<SearchParams> & {
    originInitialValue?: string;
    destinationInitialValue?: string;
    onSelectRoute: (route: RouteNoDetails | undefined) => void;
    purchaseId: string;
    onChange?: (id: string, value: any) => void;
    noBorder?: boolean;
  },
) => {
  const [searchParams, setSearchParams] = useState<SearchParams>({
    originId: props.originId ?? '',
    destinationId: props.destinationId ?? '',
    depDate: props.depDate ?? '',
    options: {
      passengers: props.options?.passengers ?? 1,
      vehicles: props.options?.vehicles ?? 0,
    },
    ccy: props.ccy ?? 'EUR',
  });

  const onChangeThresholdTimerRef = useRef(0);
  const formRef = useRef(
    new FormHandler({
      onChange: (id, value) => {
        clearTimeout(onChangeThresholdTimerRef.current);
        onChangeThresholdTimerRef.current = window.setTimeout(() => {
          setSearchParams({ ...searchParams, [id]: value });
        }, 1500);
      },
    }),
  );

  const [route, selectRoute] = useState<RouteNoDetails | undefined>();

  return (
    <VStack space="3">
      <View br={12} bc="monochrome-light" b={props.noBorder ? 0 : 1}>
        <SearchPanel
          depDate={searchParams.depDate}
          destinationId={searchParams.destinationId}
          originId={searchParams.originId}
          originInitialValue={props.originInitialValue ?? ''}
          destinationInitialValue={props.destinationInitialValue ?? ''}
          form={formRef.current}
          onSearch={() => {
            const { value, valid } = formRef.current.getValue<SearchParams>();
            if (valid) {
              selectRoute(undefined);
              setSearchParams({ ...searchParams, ...value });
            }
          }}
        />
      </View>

      <SearchList
        key={JSON.stringify(searchParams)}
        purchaseId={props.purchaseId}
        params={searchParams}
        onPressTicket={(selectedRoute) => {
          if (!route) {
            selectRoute(selectedRoute);
            props.onSelectRoute(selectedRoute);
          } else {
            selectRoute(undefined);
            props.onSelectRoute(undefined);
          }
        }}
        selectedId={route?.routeKey}
        setDepDate={(newDepDate: string) => {
          setSearchParams({ ...searchParams, depDate: newDepDate });
        }}
      />
    </VStack>
  );
};
