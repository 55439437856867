import { KeyVal } from '@naus-code/naus-server-utils-common-types';
import { create } from 'zustand';

export interface CreditLineState {
  total: KeyVal<number>;
  creditLine: KeyVal<KeyVal<number>>;
  setCreditLine: (companyCode: string, value: number, currency: string) => void;
}

export const useCreditLineStore = create<CreditLineState>((set) => ({
  total: {},
  creditLine: {},
  setCreditLine: (companyCode, value, currency) => {
    set((state) => {
      const newCredit = { ...state.creditLine[currency], [companyCode]: value };
      const total = Object.values(newCredit).reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      return {
        creditLine: {
          [currency]: newCredit,
        },
        total: {
          [currency]: total,
        },
      };
    });
  },
}));
