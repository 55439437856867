import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { UpdateCustomerRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { translate } from '~/utils/translation.utils';

import { customerApi } from '../utils/Customer.api';

const updateCustomerModalKey = 'UpdateCustomer-ModalKey';

export const useUpdateCustomerModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: ({ customerId }: { customerId: string }) => {
      openModal(<UpdateCustomerComponent customerId={customerId} />, {
        type: 'action-sheet-full',
        key: updateCustomerModalKey,
      });
    },
    close: () => {
      closeModal(updateCustomerModalKey);
    },
  };
};

const UpdateCustomerComponent = ({ customerId }: { customerId: string }) => {
  const formRef = useRef(new FormHandler());
  const { closeModal } = useModal();
  const { data: customerFormConfig, isLoading: isLoadingForm } =
    customerApi.useUpdateCustomerFormConfigQuery(customerId);
  const [updateCustomer, { isLoading: isUpdatingCustomer }] =
    customerApi.useUpdateCustomerMutation();

  if (isLoadingForm) {
    return <LoadingIndicator />;
  }

  if (!customerFormConfig) {
    return null;
  }

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('customer.updateCustomer')}
        onPress={() => {
          closeModal(updateCustomerModalKey);
        }}
      />
      <ModalScrollView>
        <FormContent isLoading={isLoadingForm} form={customerFormConfig} flex={1}>
          <FormBuilder data={customerFormConfig} form={formRef.current} />
        </FormContent>
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isUpdatingCustomer}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<UpdateCustomerRequest['customer']>();

          if (valid) {
            const response = await updateCustomer({
              customer: { ...value, _id: customerId },
            });
            handleResponse({
              response,
              onSuccess: () => {
                closeModal(updateCustomerModalKey);
              },
              onError: () => {
                closeModal(updateCustomerModalKey);
              },
            });
          }
        }}
        onCancel={() => closeModal(updateCustomerModalKey)}
      />
    </Modal.Container>
  );
};
