import { Modal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { ScrollView } from '@droplet-tech-code/core-elements/module/ui/View';
import { ScrollViewProps } from 'react-native';

export const ModalScrollView = (props: ScrollViewProps) => {
  return (
    <Modal.Body flex={1}>
      <ScrollView {...props} />
    </Modal.Body>
  );
};
