import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
  notEmpty,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  GetPurchaseResponse,
  SupportRequestBackendClient,
} from '@naus-code/naus-admin-types';
import { MutableRefObject, useEffect } from 'react';

import { SwitchStack } from '~/components/Stack/SwitchStack';
import { PurchaseListItemInternalComponent } from '~/screens/Support/Purchases/Purchase.ListItem';
import { CancelCustomer } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.Customer';
import { CancelCustomerAndProvider } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.CustomerAndProvider';
import { CancelProvider } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.Provider';
import {
  cancelPurchaseManager,
  CancelPurchaseProps,
  CancelPurchasePropsNative,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.utils';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { TicketDetails } from '~/screens/Support/Purchases/PurchaseItem';
import { translate } from '~/utils/translation.utils';

export const CancelPurchaseTicketsToSelectNative = (props: CancelPurchasePropsNative) => {
  const { checkedItems, setCheckedItems } = props;

  const supportTicketIds = props.supportRequest.ticketIds;
  const purchaseTickets = props.purchase.tickets;

  const baseTickets = supportTicketIds
    ? purchaseTickets.filter((t) => supportTicketIds.some((id) => id === t._id))
    : purchaseTickets;

  const baseTicketIds = baseTickets.map((t) => t._id);

  return (
    <VStack m="3" p="3" space="6" flex={isNativeMobile ? 1 : undefined}>
      <Text.Small color="monochrome-mid">
        {translate('purchases.purchaseTool.selectTicketsToCancel')}:
      </Text.Small>
      <SwitchStack space={isWeb ? '6' : '3'}>
        <VStack space="3" flex={1}>
          <HStack space="6">
            <CheckBox
              checked={checkedItems.length === baseTicketIds.length}
              onPress={(isTicketChecked) => {
                if (isTicketChecked) {
                  setCheckedItems(baseTicketIds);
                } else {
                  setCheckedItems([]);
                }
              }}
            />
            <PurchaseListItemInternalComponent purchase={props.purchase} compareMode />
          </HStack>
          <View>
            <DottedLine />
          </View>
          <VStack space="4" flex={isWeb ? 1 : undefined}>
            {purchaseTickets.map((ticket, iy) => {
              const disabledTicket = !baseTicketIds.some((id) => id === ticket._id);

              if (disabledTicket) {
                return (
                  <HStack space="3" key={ticket._id} pl="6" style={{ opacity: 0.5 }}>
                    <CheckBox disabled checked={false} style={{ opacity: 0 }} />
                    <VStack space="2" flex={isNativeMobile ? 1 : undefined}>
                      <TicketDetails
                        ticket={ticket}
                        ccySymbol={props.purchase.ccySymbol}
                      />
                      <View>{baseTickets.length - 1 === iy ? null : <DottedLine />}</View>
                    </VStack>
                  </HStack>
                );
              }

              return (
                <HStack space="3" key={ticket._id} pl="6">
                  <CheckBox
                    checked={checkedItems.some((t) => t === ticket._id)}
                    onPress={(isChecked) => {
                      const newItems = checkedItems.filter((t) => t !== ticket._id);
                      setCheckedItems(isChecked ? [...newItems, ticket._id] : newItems);
                    }}
                  />
                  <VStack space="2" flex={isNativeMobile ? 1 : undefined}>
                    <TicketDetails ticket={ticket} ccySymbol={props.purchase.ccySymbol} />
                    <View>{baseTickets.length - 1 === iy ? null : <DottedLine />}</View>
                  </VStack>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </SwitchStack>
    </VStack>
  );
};

export const CancelPurchaseTicketsToSelect = (props: CancelPurchaseProps) => {
  const supportTicketIds = props.supportRequest.ticketIds;
  const purchaseTickets = props.purchase.tickets;

  const baseTickets = supportTicketIds
    ? purchaseTickets.filter((t) => supportTicketIds.some((id) => id === t._id))
    : purchaseTickets;

  const baseTicketIds = baseTickets.map((t) => t._id);

  const [checkedItems, setCheckedItems] =
    cancelPurchaseManager.state.useGlobalState('selectedTickets');

  useEffect(() => {
    setCheckedItems(baseTicketIds);
  }, []);

  return (
    <VStack m="3" p="3" space="6">
      <Text.Small color="monochrome-mid">
        {translate('purchases.purchaseTool.selectTicketsToCancel')}:
      </Text.Small>
      <SwitchStack space={isWeb ? '6' : '3'}>
        <VStack space="3" flex={1}>
          <HStack space="6">
            <CheckBox
              checked={checkedItems.length === baseTicketIds.length}
              onPress={(isTicketChecked) => {
                if (isTicketChecked) {
                  setCheckedItems(baseTicketIds);
                } else {
                  setCheckedItems([]);
                }
              }}
            />
            <PurchaseListItemInternalComponent purchase={props.purchase} compareMode />
          </HStack>
          <View>
            <DottedLine />
          </View>
          <VStack space="4" flex={isWeb ? 1 : undefined}>
            {purchaseTickets.map((ticket, iy) => {
              const disabledTicket = !baseTicketIds.some((id) => id === ticket._id);

              if (disabledTicket) {
                return (
                  <HStack space="3" key={ticket._id} pl="6" style={{ opacity: 0.5 }}>
                    <CheckBox disabled checked={false} style={{ opacity: 0 }} />
                    <VStack space="2" flex={isNativeMobile ? 1 : undefined}>
                      <TicketDetails
                        ticket={ticket}
                        ccySymbol={props.purchase.ccySymbol}
                      />
                      <View>{baseTickets.length - 1 === iy ? null : <DottedLine />}</View>
                    </VStack>
                  </HStack>
                );
              }

              return (
                <HStack space="3" key={ticket._id} pl="6">
                  <CheckBox
                    checked={checkedItems.some((t) => t === ticket._id)}
                    onPress={(isChecked) => {
                      const newItems = checkedItems.filter((t) => t !== ticket._id);
                      setCheckedItems(isChecked ? [...newItems, ticket._id] : newItems);
                    }}
                  />
                  <VStack space="2" flex={isNativeMobile ? 1 : undefined}>
                    <TicketDetails ticket={ticket} ccySymbol={props.purchase.ccySymbol} />
                    <View>{baseTickets.length - 1 === iy ? null : <DottedLine />}</View>
                  </VStack>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </SwitchStack>
    </VStack>
  );
};

export const CancelPurchaseInput = ({
  formHandlerRef,
  purchaseId,
  supportRequest,
  purchase,
}: {
  purchaseId: string;
  purchase: GetPurchaseResponse['purchase'];
  formHandlerRef: MutableRefObject<FormHandler>;
  supportRequest?: SupportRequestBackendClient;
}) => {
  const [cancelMode, setCancelMode] = cancelPurchaseManager.state.useGlobalState('type');
  const [selectedTickets] = cancelPurchaseManager.state.useGlobalState('selectedTickets');
  const providerVoided = purchase.void || !!purchase.voidProviderOnly;
  const customerVoided = purchase.void || purchase.voidCustomerOnly;
  const visuallyVoided =
    purchase.void || purchase.voidPseudo || customerVoided || providerVoided;
  const completelyVoided = purchase.void || (providerVoided && customerVoided);

  const remainingOptions = [
    visuallyVoided
      ? null
      : {
          value: 'visual',
          label: translate('purchases.purchaseTool.visually'),
        },
    customerVoided
      ? null
      : {
          value: 'customer',
          label: translate('purchases.purchaseTool.customerOnly'),
        },
    providerVoided
      ? null
      : {
          value: 'provider',
          label: translate('purchases.purchaseTool.providerOnly'),
        },
    providerVoided || customerVoided
      ? null
      : {
          value: 'customer_and_provider',
          label: translate('purchases.purchaseTool.customerProviderOnly'),
        },
  ].filter(notEmpty);

  const {
    data: purchaseCalculation,
    isLoading,
    isFetching,
  } = purchaseActionApi.useGetCancelPurchaseCalculationQuery(
    {
      purchaseId,
      ticketIds: selectedTickets,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: completelyVoided,
    },
  );

  useEffect(() => {
    if (completelyVoided) {
      setCancelMode(undefined);
      return;
    }

    if (remainingOptions.some((option) => option.value === cancelMode)) {
      return;
    }

    const lastOption = remainingOptions[remainingOptions.length - 1];
    if (lastOption?.value) {
      setCancelMode(lastOption.value as any);
    }
  }, [remainingOptions, cancelMode, completelyVoided]);

  if (completelyVoided) {
    return null;
  }

  return (
    <VStack
      m="3"
      space="3"
      justify="flex-start"
      flex={isNativeMobile ? 1 : undefined}
      style={[isWeb && { minWidth: 375 }]}
    >
      <View p="3">
        <SelectInputBase
          label={translate('purchases.purchaseTool.selectTypeOfCancel')}
          noFeedback
          feedback=""
          type="single-select"
          values={cancelMode ? [cancelMode] : []}
          setValues={(nv) => {
            if (nv) {
              formHandlerRef.current = new FormHandler();
              setCancelMode(nv[0] as any);
            }
          }}
          options={remainingOptions}
        />
      </View>

      <DelayRender isLoading={isLoading || isFetching}>
        {cancelMode === 'provider' ? (
          <CancelProvider
            formHandlerRef={formHandlerRef}
            purchaseCalculation={purchaseCalculation}
            supportRequest={supportRequest}
          />
        ) : null}

        {cancelMode === 'customer' ? (
          <CancelCustomer
            formHandlerRef={formHandlerRef}
            purchaseCalculation={purchaseCalculation}
            supportRequest={supportRequest}
          />
        ) : null}

        {cancelMode === 'customer_and_provider' ? (
          <CancelCustomerAndProvider
            formHandlerRef={formHandlerRef}
            purchaseCalculation={purchaseCalculation}
            supportRequest={supportRequest}
          />
        ) : null}
      </DelayRender>
    </VStack>
  );
};
