import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { NoticeListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';
import { RefreshControl } from 'react-native';

import { FlatList } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { noticesApi } from '../notices.api';
import { NoticesListItemComponent } from '../Notices.ListItem';
import { ActiveNoticesListHeader } from './ActiveNotices.ListHeader';

export interface ActiveNoticesListProps {
  onPress: (item: NoticeListItemResponse) => void;
}

export const ActiveNoticesListNative = () => {
  const navigate = useAppNavigation();
  return (
    <ActiveNoticesList
      onPress={(item) => {
        navigate.navigate('Notice-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const ActiveNoticesList = (props: ActiveNoticesListProps) => {
  const { list, Search, isLoading, isFetching, refetch } = useSearchState({
    hook: noticesApi.useGetActiveNoticesListQuery,
    style: {
      height: 47,
    },
    keyToList: 'notices',
  });

  const renderItem = useCallback((activeNotice: NoticeListItemResponse) => {
    return <NoticesListItemComponent key={activeNotice.id} notice={activeNotice} />;
  }, []);

  const selectedRef = useRef('');
  const ItemSeparatorComponent = useItemSeparatorComponent();
  const { spacing } = useAppTheme();
  return (
    <VStack flex={1}>
      <ActiveNoticesListHeader refetch={refetch} isLoading={isLoading} />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        feedback={translate('notices.emptyActiveNotices')}
        isLoading={isLoading}
        data={list}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
