import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';

import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { translate } from '~/utils/translation.utils';

interface SendBookingConfirmationProps {
  email: string;
  bookingId: string;
}

export const useSendBookingConfirmationModal = (props: SendBookingConfirmationProps) => {
  const [sendBookingConfirmation, { isLoading }] =
    bookingApi.useSendConfirmationMutation();

  return useCreateManualFormModal({
    title: translate('bookings.bookingsForEmail'),
    subtitle: translate('bookings.enterEmail'),
    formData: [
      {
        items: [
          {
            type: 'email',
            label: translate('bookings.email'),
            id: 'email',
            initialValue: props.email,
          },
        ],
      },
    ],
    onConfirm: (value) => {
      sendBookingConfirmation({
        _id: props.bookingId,
        email: value.email,
      }).then((response) => {
        handleResponse({
          response,
          onSuccess: () => {},
        });
      });
    },
    isLoading: isLoading,
  });
};
