import { useFocusEffect } from "@react-navigation/native";
import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import { rootApi } from "../store/redux.utils";
import { STORE_TAGS } from "../store/storeTags";

export const useInvalidateOnFocus = (tags: (typeof STORE_TAGS)[number][]) => {
  const dispatch = useDispatch();
  const refMount = useRef(0);
  useFocusEffect(
    useCallback(() => {
      if (refMount.current > 0) {
        dispatch(rootApi.util.invalidateTags(tags));
      }
      refMount.current += 1;
    }, []),
  );
};
