import { AppViewProps } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  GetSupportRequestResponse,
  SupportRequestBackendClient,
} from '@naus-code/naus-admin-types';
import React from 'react';

import { Accordion, Section } from '~/components/Accordion/Accordion';
import {
  SupportRequestAutoTranslateContent,
  SupportRequestAutoTranslateHeader,
} from '~/components/SupportRequest/utils/SupportRequestAutoTranslate';

import {
  SupportRequestCustomerContent,
  SupportRequestCustomerHeader,
} from './utils/SupportRequestCustomer';
import {
  SupportRequestDataContent,
  SupportRequestDataHeader,
} from './utils/SupportRequestData';
import {
  SupportRequestDetailsContent,
  SupportRequestDetailsHeader,
} from './utils/SupportRequestDetails';
import {
  SupportRequestHistoryContent,
  SupportRequestHistoryHeader,
} from './utils/SupportRequestHistory';
import {
  SupportRequestNotesContent,
  SupportRequestNotesHeader,
} from './utils/SupportRequestNotes';
import {
  SupportRequestPaymentHistoryContent,
  SupportRequestPaymentHistoryHeader,
} from './utils/SupportRequestPaymentHistory';

interface SupportRequestAccordionProps extends AppViewProps {
  data: GetSupportRequestResponse;
}

export const SupportRequestAccordionComponent = (props: SupportRequestAccordionProps) => {
  const { data } = props;
  const supportRequestInfo = data?.supportRequest;
  const { check } = data;

  return (
    <SupportRequestAccordion check={check} supportRequestInfo={supportRequestInfo} />
  );
};

const SupportRequestDataSection = (
  supportRequestInfo: SupportRequestBackendClient,
): Section => {
  return {
    header: () => <SupportRequestDataHeader />,
    content: () => (
      <>
        <SupportRequestDataContent supportRequestInfo={supportRequestInfo} />
      </>
    ),
  };
};

const SupportRequestDetailsSection = (
  check: string,
  supportRequestInfo?: SupportRequestBackendClient,
): Section => {
  return {
    header: () => <SupportRequestDetailsHeader />,
    content: () => (
      <>
        {supportRequestInfo === undefined ? null : (
          <SupportRequestDetailsContent
            check={check}
            supportRequestInfo={supportRequestInfo}
          />
        )}
      </>
    ),
  };
};

export const SupportRequestTranslateSection = (
  supportId?: string, // check: string,
): Section => {
  return {
    header: () => <SupportRequestAutoTranslateHeader />,
    content: () =>
      supportId ? <SupportRequestAutoTranslateContent supportId={supportId} /> : <></>,
  };
};

const SupportRequestCustomerSection = (
  supportRequestInfo?: SupportRequestBackendClient,
): Section => {
  return {
    header: () => (
      <SupportRequestCustomerHeader supportRequestInfo={supportRequestInfo} />
    ),
    content: () => (
      <SupportRequestCustomerContent supportRequestInfo={supportRequestInfo} />
    ),
  };
};

const SupportRequestNotesSection = (
  supportRequestInfo?: SupportRequestBackendClient,
): Section => {
  return {
    header: () => (
      <>
        {supportRequestInfo === undefined ? null : (
          <SupportRequestNotesHeader supportRequestInfo={supportRequestInfo} />
        )}
      </>
    ),
    content: () => <SupportRequestNotesContent supportRequestInfo={supportRequestInfo} />,
  };
};

const SupportRequestHistorySection = (
  supportRequestInfo?: SupportRequestBackendClient,
): Section => {
  return {
    header: () => <SupportRequestHistoryHeader supportRequestInfo={supportRequestInfo} />,
    content: () => (
      <SupportRequestHistoryContent supportRequestInfo={supportRequestInfo} />
    ),
  };
};

const SupportRequestPaymentHistorySection = (
  supportRequestInfo?: SupportRequestBackendClient,
): Section => {
  return {
    header: () => (
      <SupportRequestPaymentHistoryHeader supportRequestInfo={supportRequestInfo} />
    ),
    content: () => (
      <SupportRequestPaymentHistoryContent supportRequestInfo={supportRequestInfo} />
    ),
  };
};

const SupportRequestAccordion = ({
  check,
  supportRequestInfo,
}: {
  check: string;
  supportRequestInfo?: SupportRequestBackendClient;
}) => {
  const sections = [
    SupportRequestDetailsSection(check, supportRequestInfo),
    SupportRequestTranslateSection(supportRequestInfo?._id),
    SupportRequestCustomerSection(supportRequestInfo),
    SupportRequestNotesSection(supportRequestInfo),
    SupportRequestHistorySection(supportRequestInfo),
    SupportRequestPaymentHistorySection(supportRequestInfo),
  ];

  if (supportRequestInfo?.paymentSession || supportRequestInfo?.requestOptions) {
    sections.unshift(SupportRequestDataSection(supportRequestInfo));
  }

  return (
    <Accordion pressable={false} sections={sections} expandedIndex={[0, 1, 2, 3, 4, 5]} />
  );
};
