import { notEmpty } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  GetTripScheduledChangeResponse,
  TripScheduledChangePurchase,
  TripScheduledChangeResult,
  TripScheduledChangeVerifyPurchasesResponse,
  TripScheduledStopChangeClient,
  TripScheduledStopClient,
} from '@naus-code/naus-admin-types';

export const isModificationChange = (
  item: TripScheduledStopClient | TripScheduledStopChangeClient,
): item is TripScheduledStopChangeClient => {
  /*
==common==

  originPortName: string;
  destinationPortName: string;


  oldDepartureDateOffset: number;
  newDepartureTime: string;
  newDepartureDateOffset: number;
  oldArrivalDateOffset: number;
  newArrivalTime: string;
  newArrivalDateOffset: number;

  */

  return (
    Object.hasOwn(item, 'oldDepartureDateOffset') ||
    Object.hasOwn(item, 'newDepartureTime') ||
    Object.hasOwn(item, 'newDepartureDateOffset') ||
    Object.hasOwn(item, 'oldArrivalDateOffset') ||
    Object.hasOwn(item, 'newArrivalTime') ||
    Object.hasOwn(item, 'newArrivalDateOffset')
  );
};

export type ScheduledListItemWithId = (
  | TripScheduledChangeResult
  | TripScheduledChangePurchase
) & { id: string };

export const isScheduledChangeResult = (
  props: ScheduledListItemWithId,
): props is TripScheduledChangeResult & { id: string } => {
  return Object.hasOwn(props, 'details');
};

export const constructScheduledListNicely = (
  data: GetTripScheduledChangeResponse | TripScheduledChangeVerifyPurchasesResponse,
): {
  title: string;
  data: ScheduledListItemWithId[];
}[] => {
  const resultList =
    data.scheduledChange.status === 'Completed'
      ? getResultList(data.scheduledChange.result)
      : undefined;

  if (resultList) {
    return resultList;
  }

  return [{ title: '', data: data.scheduledChange.selectedPurchasesToUpdate }];
};

export const getResultList = (
  list: (
    | GetTripScheduledChangeResponse
    | TripScheduledChangeVerifyPurchasesResponse
  )['scheduledChange']['result'],
): { title: string; data: ScheduledListItemWithId[] }[] | undefined => {
  if (!list) {
    return [];
  }

  return [
    list.unSuccessful.length
      ? {
          title: `Unsuccessful (${list.unSuccessful.length})`,
          data: list.unSuccessful.map((s) => ({ id: s.purchaseId, ...s })),
        }
      : undefined,
    list.successful.length
      ? {
          title: `Successful (${list.successful.length})`,
          data: list.successful.map((s) => ({ id: s.purchaseId, ...s })),
        }
      : undefined,
  ].filter(notEmpty);
};
