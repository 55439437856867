import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { GetNoticeResponse } from '@naus-code/naus-admin-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { Field } from '~/components/Card/Field';
import { useEditNotice } from '~/components/Notices/Modals/EditNotice';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { ScreenPropNoticeItem } from '~/navigation/Navigation.types';
import { translate } from '~/utils/translation.utils';

import { NoticeItemHeader } from './Notice.ItemHeader';
import { noticesApi } from './notices.api';

export const NoticeItemScreen = (props: ScreenPropNoticeItem) => {
  return <NoticeItem noticeId={props.route.params.id} onClose={() => {}} />;
};

export const NoticeItem = ({
  noticeId,
  onClose,
}: {
  noticeId: string;
  onClose: () => void;
}) => {
  const { data, isLoading, refetch } = noticesApi.useGetNoticeQuery(noticeId);
  const { open: openEdit } = useEditNotice();
  if (!data) {
    return <></>;
  }
  if (isLoading) {
    return <LoadingIndicator />;
  }
  const notice = data.notice;

  return (
    <VStack>
      <NoticeItemHeader
        notice={notice}
        onClose={onClose}
        onEdit={openEdit}
        onRefresh={refetch}
      />
      <VStack p="2.5">
        <NoticeItemComponent notice={notice} />
      </VStack>
    </VStack>
  );
};

const NoticeItemComponent = ({ notice }: { notice: GetNoticeResponse['notice'] }) => {
  const {
    userName,
    active,
    severity,
    textTitle,
    textPreview,

    dateCreated,
  } = notice;
  return (
    <CardWithHeader
      headerText={translate('notices.noticeDetails')}
      w={isWeb ? 600 : undefined}
    >
      <VStack space="2.5" p="2.5">
        <SwitchStack space="2.5">
          <VStack space="2.5" flex={isWeb ? 1 : undefined}>
            <Field label={translate('notices.userName')} value={userName} />
            <Field label={translate('notices.active')} value={active ? 'Yes' : 'No'} />
          </VStack>
          <VStack space="2.5" flex={isWeb ? 1 : undefined}>
            <Field label={translate('notices.severity')} value={severity + ''} />
            <Field label={translate('notices.dateCreated')} value={dateCreated} isDate />
          </VStack>
        </SwitchStack>
        <View />
        <AppText.MiscTag>{textTitle['en']}</AppText.MiscTag>
        <Divider />
        <VStack flex={isWeb ? 1 : undefined}>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {`${translate('companyConfig.descriptionPreview')} [${Object.keys(
              textPreview,
            ).join(',')}]`}
          </AppText.ExtraSmall>
          <Text.Small>{textPreview['en']}</Text.Small>
        </VStack>
        {notice.textBody ? (
          <VStack space="1">
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {`${translate('companyConfig.description')} [${Object.keys(
                notice.textBody,
              ).join(',')}]`}
            </AppText.ExtraSmall>
            <Text.Small>{notice.textBody['en']}</Text.Small>
          </VStack>
        ) : null}
      </VStack>
    </CardWithHeader>
  );
};
