import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { FerryCompanyOperator } from '@naus-code/naus-server-common-types';

import {
  useUpdateFerryCompanyOperatorModal,
  useUpdateFerryOperatorContactModal,
  useUpdateFerryOperatorCustomerOperationModal,
  useUpdateFerryOperatorETicketModal,
  useUpdateFerryOperatorTermsModal,
} from '~/components/Config/CompanyConfig/Modals/Company/Modals';
import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { CompanyOperatorCardWithHeader } from './CompanyOperatorCardWithHeader';

export const CompanyOperatorConfig = ({
  operator,
  companyId,
}: {
  companyId: string;
  operator: FerryCompanyOperator;
}) => {
  const { name } = operator;
  const { open: openUpdateFerryOperator } = useUpdateFerryCompanyOperatorModal(
    operator.id,
    companyId,
  );

  return (
    <CompanyOperatorCardWithHeader
      headerText={name}
      iconRightType="edit"
      iconRightOnPress={openUpdateFerryOperator}
      imageUrl={operator.imgUrl}
      p="2.5"
    >
      <OperatorInfo operator={operator} />
      <OperationList operatorId={operator.id} companyId={companyId} />
    </CompanyOperatorCardWithHeader>
  );
};

const OperatorInfo = ({ operator }: { operator: FerryCompanyOperator }) => {
  const { name, id, description, descriptionPreview } = operator;

  return (
    <VStack p="2.5" flex={1} space="2.5">
      <HStack>
        <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
          {translate('companyConfig.id')}
        </AppText.ExtraSmall>
        <Text.Small>{id}</Text.Small>
      </HStack>
      <HStack>
        <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
          {translate('companyConfig.name')}
        </AppText.ExtraSmall>
        <Text.Small>{name}</Text.Small>
      </HStack>
      {descriptionPreview ? (
        <VStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {`${translate('companyConfig.descriptionPreview')} [${Object.keys(
              descriptionPreview,
            ).join(',')}]`}
          </AppText.ExtraSmall>
          <Text.Small>{descriptionPreview['en']}</Text.Small>
        </VStack>
      ) : null}
      {description ? (
        <VStack space="1">
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {`${translate('companyConfig.description')} [${Object.keys(description).join(
              ',',
            )}]`}
          </AppText.ExtraSmall>
          <Text.Small>{description['en']}</Text.Small>
        </VStack>
      ) : null}
    </VStack>
  );
};

const OperationList = ({
  operatorId,
  companyId,
}: {
  operatorId: string;
  companyId: string;
}) => {
  const { open: openUpdateContactDetails } = useUpdateFerryOperatorContactModal(
    operatorId,
    companyId,
  );
  const { open: openUpdateETicket } = useUpdateFerryOperatorETicketModal(
    operatorId,
    companyId,
  );
  const { open: openUpdateTerms } = useUpdateFerryOperatorTermsModal(
    operatorId,
    companyId,
  );
  const { open: openUpdateCustomerOperation } =
    useUpdateFerryOperatorCustomerOperationModal(operatorId, companyId);
  return (
    <VStack space="2.5">
      <ConfigListItem
        text={translate('companyConfig.contactDetails')}
        iconRightType="edit"
        onPress={openUpdateContactDetails}
      />
      <ConfigListItem
        text={translate('companyConfig.eticketConfig')}
        iconRightType="edit"
        onPress={openUpdateETicket}
      />
      <ConfigListItem
        text={translate('companyConfig.termsConfig')}
        iconRightType="edit"
        onPress={openUpdateTerms}
      />
      <ConfigListItem
        text={translate('companyConfig.operationsCancellationsMod')}
        iconRightType="edit"
        onPress={openUpdateCustomerOperation}
      />
    </VStack>
  );
};
