import { SystemClientGetCreditResponse } from '@naus-code/naus-admin-types';
import { FinancialItem } from '@naus-code/naus-server-common-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'system';
export const financeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // @Get('/get-all-operator-credit/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getAllOperatorFinancialCreditLine() {
    //     return this._templateService.getAllOperatorFinancialCreditLine();
    // }

    getAllOperatorFinancialCreditLine: builder.query<FinancialItem[], void>({
      query: () => ({
        url: `${base_url}/get-all-operator-credit/`,
        method: 'Get',
      }),

      providesTags: ['*', 'getAllOperatorFinancialCreditLine'],
    }),

    // @Get('/get-operator-credit/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getOperatorFinancialCreditLine(@Params('id') companyId: string) {
    //     return this._templateService.getOperatorFinancialCreditLine(companyId);
    // }

    getOperatorFinancialCreditLine: builder.query<SystemClientGetCreditResponse, string>({
      query: (companyId) => ({
        url: `${base_url}/get-operator-credit/${companyId}`,
        method: 'Get',
      }),

      providesTags: ['*', 'getOperatorFinancialCreditLine'],
    }),
  }),
});
