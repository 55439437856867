import {
  ButtonInteractionState,
  IconButton,
} from '@droplet-tech-code/core-elements/module/ui/Button';
import { IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppViewProps } from '@droplet-tech-code/core-elements/module/ui/View';
import { Pressable } from 'react-native';

import { Card } from '~/components/Card/Card';
import { AppText } from '~/components/Text';

export interface ConfigListItemProps extends AppViewProps {
  text: string;
  iconLeftType?: IconsId;
  iconRightType: IconsId;
  onPress?: () => void;
}

export const ConfigListItem = (props: ConfigListItemProps) => {
  const disabled = props.onPress === undefined;
  return (
    <Pressable disabled={disabled} onPress={props.onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <Card
            p="2.5"
            bg={
              disabled ? 'monochrome-extraLight' : hovered ? 'monochrome-base' : undefined
            }
            {...props}
          >
            <HStack>
              {props.iconLeftType ? (
                <IconButton
                  color="monochrome"
                  icon={props.iconLeftType}
                  onPress={props.onPress}
                  disabled={disabled}
                />
              ) : (
                <></>
              )}
              <AppText.MiscTag color={disabled ? 'monochrome-mid' : 'black'} flex={1}>
                {props.text}
              </AppText.MiscTag>
              {props.children}
              <IconButton color="monochrome" icon={props.iconRightType} disabled />
            </HStack>
          </Card>
        );
      }}
    </Pressable>
  );
};
