import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  PurchaseListByDateRequest,
  PurchaseListItemResponse,
} from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';
import { RefreshControl } from 'react-native';

import { FlatList } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { PurchaseListItemComponent } from '../Purchase.ListItem';
import { purchaseApi } from '../Purchases.api';
import { usePurchaseByDateInputModal } from './PurchaseByDate.Utils';
import { PurchasesByDateListHeader } from './PurchasesByDate.ListHeader';

export interface PurchaseListByDateProps {
  onPress: (purchase: PurchaseListItemResponse) => void;
  dateRange: PurchaseListByDateRequest;
}

export const PurchaseListByDateNative = ({
  dateRange,
}: {
  dateRange: PurchaseListByDateRequest;
}) => {
  const navigate = useAppNavigation();
  return (
    <PurchaseListByDate
      dateRange={dateRange}
      onPress={(item) => {
        navigate.navigate('Purchase-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const PurchaseListByDate = (props: PurchaseListByDateProps) => {
  const selectedRef = useRef('');
  const { dateRange } = props;
  const { open: openChangeDate } = usePurchaseByDateInputModal();

  const { list, Search, isLoading, isFetching, refetch } = useSearchState({
    hook: purchaseApi.useGetPurchaseListByDateQuery,
    style: {
      height: 47,
    },
    keyToList: 'purchases',
    arg: dateRange,
  });

  const onReset = () => {
    openChangeDate();
  };

  const renderItem = useCallback((purchase: PurchaseListItemResponse) => {
    return (
      <PurchaseListItemComponent
        key={purchase.id}
        purchase={purchase}
        onPress={() => {
          selectedRef.current = purchase.id;
          props.onPress(purchase);
        }}
      />
    );
  }, []);

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <PurchasesByDateListHeader
        dateRange={dateRange}
        onPress={() => refetch()}
        numberOfResults={list.length + ''}
        onReset={onReset}
      />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        feedback={translate('purchases.emptyPurchases')}
        isLoading={isLoading}
        data={list}
        simple
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
