import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  PaymentSessionProduct,
  PaymentSessionProductSummary,
  PaymentStatus,
  PaymentSummary,
  PaymentSummaryMethod,
  PaymentSummaryMethodDetails,
  PaymentSummaryMethodDetailsCard,
  PaymentSummaryVoucher,
} from '@naus-code/naus-client-types';
import JSONTree from 'react-native-json-tree';

import { ExpandableField } from '~/components/Card/ExpandableField';
import { Field } from '~/components/Card/Field';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { AppText } from '~/components/Text';
import { CopyableText } from '~/components/Text/CopyableText';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

export const PaymentSessionKey = 'AddModal-PayementSession';
export const PaymentSessionModal = ({
  paymentSession,
}: {
  bookingId?: string;
  paymentSession: PaymentSessionProduct | PaymentSessionProductSummary;
}) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.paymentSession')}
        divider
        onPress={() => {
          closeModal(PaymentSessionKey);
        }}
      />
      <ModalScrollView>
        <PaymentSessionComponent paymentSession={paymentSession} />
      </ModalScrollView>
    </Modal.Container>
  );
};

export const usePaymentSession = () => {
  const { openModal } = useModal();

  return {
    open: (
      paymentSession: PaymentSessionProduct | PaymentSessionProductSummary,
      bookingId?: string,
    ) => {
      openModal(
        <PaymentSessionModal bookingId={bookingId} paymentSession={paymentSession} />,
        {
          key: PaymentSessionKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};

const PaymentSessionComponent = ({
  paymentSession,
}: {
  paymentSession: PaymentSessionProduct | PaymentSessionProductSummary;
}) => {
  const { type, paymentSummary, status } = paymentSession;

  const statusColor = getStatusColor(status);
  return (
    <VStack space="2.5" p="2.5">
      <AppText.Body2Medium color={statusColor}>{type.toUpperCase()}</AppText.Body2Medium>
      <Divider hairline />
      <VStack>
        <StatusComponent status={status} />
        {paymentSummary.paymentMethod.method?.type && (
          <Field
            label={translate('bookings.paymentSession.type')}
            value={paymentSummary.paymentMethod.method.type.toUpperCase()}
          />
        )}
        <PaymentSummaryComponent paymentSummary={paymentSummary} />
      </VStack>
      <ExpandableField label={translate('supportRequests.details')}>
        <PaymentSessionJSON paymentSession={paymentSession} />
      </ExpandableField>
    </VStack>
  );
};

const StatusComponent = ({ status }: { status: PaymentStatus }) => {
  const statusColor = getStatusColor(status);

  return (
    <VStack flex={1}>
      <Field
        color={statusColor}
        label={translate('bookings.paymentSession.status')}
        value={status.status}
      />
      {status.status === 'book-success-created' && (
        <Field
          label={translate('bookings.paymentSession.bookingRef')}
          value={status.bookingRef}
          isCopyable
        />
      )}
      {status.status === 'payment-error' && (
        <>
          <Field
            label={translate('bookings.paymentSession.errorType')}
            value={status.errorType}
          />
          <Field
            label={translate('bookings.paymentSession.message')}
            value={status.message ?? 'N/A'}
          />
        </>
      )}
      {status.status === 'voucher-error' && (
        <Field
          label={translate('bookings.paymentSession.errorType')}
          value={status.errorType}
        />
      )}
    </VStack>
  );
};

const getStatusColor = (paymentStatus: PaymentStatus): ColorKeys => {
  const { status } = paymentStatus;
  if (status.indexOf('error') !== -1) {
    return 'error';
  }
  if (status.indexOf('success') !== -1) {
    return 'success';
  }
  switch (status) {
    case 'cancelled':
      return 'monochrome';
    default:
      return 'warning';
  }
};

const PaymentSummaryComponent = ({
  paymentSummary,
}: {
  paymentSummary: PaymentSummary;
}) => {
  const { paymentMethod, currency, total, symbol, voucher } = paymentSummary;

  return (
    <VStack>
      <Field
        label={translate('bookings.paymentSession.currency')}
        value={`${currency}`}
      />
      <Field
        label={translate('bookings.paymentSession.total')}
        value={getPriceWithSymbol(total, symbol)}
      />
      {paymentSummary.serviceFee && (
        <Field
          label={translate('bookings.paymentSession.serviceFee')}
          value={getPriceWithSymbol(
            paymentSummary.serviceFee.valueInOriginalCcy,
            paymentSummary.serviceFee.originalCcy,
          )}
        />
      )}
      <VoucherPayment voucher={voucher} />
      <PaymentMethod paymentMethod={paymentMethod} />
    </VStack>
  );
};

const VoucherPayment = ({ voucher }: { voucher?: PaymentSummaryVoucher }) => {
  if (!voucher) {
    return null;
  }
  const {
    voucherTinyId,
    redeemValue,
    type,
    voucherCcy,
    remainingValueAfterRedeemCcy,
    voucherValueInVoucherCcy,
  } = voucher;
  return (
    <VStack>
      <Field label={translate('voucher.tinyId')} value={voucherTinyId} />
      <Field
        label={translate('voucher.value')}
        value={getPriceWithSymbol(voucherValueInVoucherCcy, voucherCcy)}
      />
      <Field
        label={translate('voucher.redeemedValue')}
        value={getPriceWithSymbol(redeemValue, voucherCcy)}
      />

      <Field
        label={translate('voucher.remainingValue')}
        value={getPriceWithSymbol(remainingValueAfterRedeemCcy, voucherCcy)}
      />
      <Field label={translate('voucher.type')} value={type} />
    </VStack>
  );
};

const PaymentMethod = ({ paymentMethod }: { paymentMethod: PaymentSummaryMethod }) => {
  const methodInfo = paymentMethod.method;
  const providers = paymentMethod.providers;
  return (
    <VStack>
      <Field
        label={translate('bookings.paymentSession.isDisabled')}
        value={paymentMethod.isDisabled ? 'True' : 'False'}
      />
      <Field
        label={translate('bookings.paymentSession.isFullVoucher')}
        value={paymentMethod.isFullVoucher ? 'True' : 'False'}
      />
      {!!providers.length && (
        <ExpandableField label={translate('bookings.paymentSession.providerInfo')}>
          {providers.map((provider, index) => {
            const { id, country, providerKey, region } = provider;
            return (
              <VStack space="1" p="2.5" key={provider.id} flex={1}>
                <Field label={translate('bookings.paymentSession.id')} value={id} />
                <HStack style={{ width: '100%' }}>
                  <Text.Small flex={1}>
                    {translate('bookings.paymentSession.key')}
                  </Text.Small>
                  <CopyableText
                    numberOfLines={1}
                    style={{ width: 200, overflow: 'hidden' }}
                    value={providerKey}
                  />
                </HStack>
                <Field
                  label={translate('bookings.paymentSession.country')}
                  value={country}
                />
                <Field
                  label={translate('bookings.paymentSession.region')}
                  value={region}
                />
                {index != providers.length && <Divider />}
              </VStack>
            );
          })}
        </ExpandableField>
      )}
      {methodInfo && <PaymentMethodInfo methodInfo={methodInfo} />}
    </VStack>
  );
};

const PaymentMethodInfo = ({
  methodInfo,
}: {
  methodInfo: PaymentSummaryMethodDetails;
}) => {
  return (
    <ExpandableField label={translate('bookings.paymentSession.paymentMethodDetails')}>
      <CardMethodInfo methodInfo={methodInfo as PaymentSummaryMethodDetailsCard} />
    </ExpandableField>
  );
};

const CardMethodInfo = ({
  methodInfo,
}: {
  methodInfo: PaymentSummaryMethodDetailsCard;
}) => {
  const {
    type,
    providerId,
    methodId,
    expiryMonth,
    expiryYear,
    brand,
    last4,
    expiry,
    country,
  } = methodInfo;
  return (
    <VStack p="2.5">
      <Field isCopyable label={translate('bookings.paymentSession.type')} value={type} />
      <Field
        label={translate('bookings.paymentSession.providerId')}
        value={providerId}
        isCopyable
      />
      <Field
        label={translate('bookings.paymentSession.methodId')}
        value={methodId}
        isCopyable
      />
      {methodInfo.intentId && (
        <Field
          label={translate('bookings.paymentSession.intentId')}
          value={methodInfo.intentId}
          isCopyable
        />
      )}
      <Field
        label={translate('bookings.paymentSession.expiryMonth')}
        value={expiryMonth + ''}
        isCopyable
      />
      <Field
        label={translate('bookings.paymentSession.expiryYear')}
        value={expiryYear + ''}
        isCopyable
      />
      <Field
        label={translate('bookings.paymentSession.brand')}
        value={brand.toUpperCase()}
        isCopyable
      />
      {methodInfo.brandProduct && (
        <Field
          label={translate('bookings.paymentSession.brandProduct')}
          value={methodInfo.brandProduct}
          isCopyable
        />
      )}
      <Field
        label={translate('bookings.paymentSession.last4')}
        value={last4}
        isCopyable
      />
      <Field
        label={translate('bookings.paymentSession.expiry')}
        value={expiry}
        isDate
        isCopyable
      />
      <Field
        label={translate('bookings.paymentSession.country')}
        value={country}
        isCopyable
      />
      <Field
        label={translate('bookings.paymentSession.saveCard')}
        value={methodInfo.saveCard ? 'Yes' : 'No'}
        isCopyable
      />
    </VStack>
  );
};

const PaymentSessionJSON = ({
  paymentSession,
}: {
  paymentSession: PaymentSessionProduct | PaymentSessionProductSummary;
}) => {
  return (
    <View p="2.5">
      <JSONTree
        // shouldExpandNode={() => true}
        data={paymentSession as any}
        theme={{
          scheme: 'google',
          author: 'seth wright (http://sethawright.com)',
          base00: '#1d1f21',
          base01: '#282a2e',
          base02: '#373b41',
          base03: '#969896',
          base04: '#b4b7b4',
          base05: '#c5c8c6',
          base06: '#e0e0e0',
          base07: '#ffffff',
          base08: '#CC342B',
          base09: '#F96A38',
          base0A: '#FBA922',
          base0B: '#198844',
          base0C: '#3971ED',
          base0D: '#3971ED',
          base0E: '#A36AC7',
          base0F: '#3971ED',
        }}
        valueRenderer={(v) => {
          return (
            <View>
              <Text.Small bold color="secondary-mid">
                {v as any}
              </Text.Small>
            </View>
          );
        }}
      />
    </View>
  );
};
