import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { paginationUtil } from '~/components/FlatList/FlatList.Paginated';
import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

import { purchaseApi } from '../Purchases.api';

export const PurchasesUpcomingListHeader = () => {
  const [invalidate, { isLoading }] =
    purchaseApi.useInvalidateGetPurchasesUpcomingMutation();

  const { data } = purchaseApi.useGetPurchasesUpcomingQuery(
    paginationUtil.lastReq.PurchasesUpcoming,
  );

  const count = data ? `(${data?.list.length || ''}${data?.isFinal ? '' : '+'})` : '';

  return (
    <PageHeader
      title={`${translate('purchases.purchasesUpcoming')} ${count}`}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress: () => {
          const options = paginationUtil.lastReq.PurchasesUpcoming;
          const lastItemId = paginationUtil.lists.PurchasesUpcoming;

          if (options) {
            invalidate({
              ...options,
              paginationRequest: {
                ...options.paginationRequest,
                lastItemId,
                refreshList: !!lastItemId,
              },
            });
          }
        },
        isLoading,
      }}
    />
  );
};
