import {
  ClientGetFormConfigWithCheckResponse,
  CompanyConfigClientCreateCompanyRequest,
  CompanyConfigClientCreateFerryCompanyDiscountRequest,
  CompanyConfigClientCreateFerryCompanyExtraTicketRequest,
  CompanyConfigClientCreateFerryCompanyPassengerTicketRequest,
  CompanyConfigClientCreateFerryCompanyVehicleTicketRequest,
  CompanyConfigClientCreateOperatorRequest,
  CompanyConfigClientGetFerryCompanyDiscountFormRequest,
  CompanyConfigClientGetFerryCompanyTicketFormRequest,
  CompanyConfigClientGetFerryConfigResponse,
  CompanyConfigClientGetFormConfigRequest,
  CompanyConfigClientGetListResponse,
  CompanyConfigClientUpdateActiveConfigRequest,
  CompanyConfigClientUpdateFerryOperationsRequest,
  CompanyConfigClientUpdateOperatorContactsRequest,
  CompanyConfigClientUpdateOperatorETicketConfigRequest,
  CompanyConfigClientUpdateOperatorOperationsRequest,
  CompanyConfigClientUpdateOperatorTermsRequest,
  CompanyConfigClientUpdatePassengerOperationsRequest,
  CompanyConfigUpdateFerryCompanyOperatorRequest,
  ConfigOperatorClientGetFormRequest,
} from '@naus-code/naus-admin-types';
import { ImageUploadResponse } from '@naus-code/naus-client-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'config/company';
export const companyConfigApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getFerryCompanyConfig: builder.query<
      CompanyConfigClientGetFerryConfigResponse,
      string
    >({
      query: (id) => ({
        url: `${base_url}/get-ferry-company/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getFerryCompanyConfig'],
    }),

    getCompanyConfigList: builder.query<CompanyConfigClientGetListResponse, void>({
      query: () => ({
        url: `${base_url}/get-list/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCompanyConfigList'],
    }),

    createFerryCompanyConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/create-ferry-company/`,
        method: 'GET',
      }),
      providesTags: ['*', 'createFerryCompanyConfigFormConfig'],
    }),

    addFerryCompanyConfig: builder.mutation<
      CompanyConfigClientGetListResponse,
      CompanyConfigClientCreateCompanyRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-ferry-company/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['addFerryCompanyConfig', 'getCompanyConfigList'],
    }),

    createFerryCompanyOperatorConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/create-ferry-operator/`,
        method: 'GET',
      }),
      providesTags: ['*', 'createFerryCompanyOperatorConfigFormConfig'],
    }),

    addFerryCompanyOperatorConfig: builder.mutation<
      CompanyConfigClientGetListResponse,
      CompanyConfigClientCreateOperatorRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-ferry-operator/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['addFerryCompanyOperatorConfig', 'getFerryCompanyConfig'],
    }),
    addFerryCompanyOperatorConfigImageToken: builder.mutation<
      ImageUploadResponse,
      FormData
    >({
      query: (data) => ({
        url: `${base_url}/img-upload/create-ferry-operator/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'addFerryCompanyOperatorConfig',
        'getFerryCompanyConfig',
        'addFerryCompanyOperatorConfigImageToken',
      ],
    }),

    //----------------COMPANY DETAILS-------------//

    // @Get('/form-config/update-ferry-company/:companyId', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyConfigFormConfig(
    //     @Params('companyId') companyId: string,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryCompanyConfigFormConfig(companyId);
    // }

    updateFerryCompanyConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      string
    >({
      query: (companyId: string) => ({
        url: `${base_url}/form-config/update-ferry-company/${companyId}`,
        method: 'GET',
      }),
      providesTags: ['updateFerryCompanyConfigFormConfig'],
    }),

    // @Post('/update-ferry-company/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyConfig(
    //     @Body() body: CompanyConfigClientCreateCompanyRequest,
    // ): Promise<CompanyConfigClientGetListResponse> {
    //     return this._companyConfigService.updateFerryCompanyConfig(body);
    // }

    updateFerryCompanyConfig: builder.mutation<
      CompanyConfigClientGetListResponse,
      CompanyConfigClientCreateCompanyRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-company/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'updateFerryCompanyConfigFormConfig',
        'getFerryCompanyConfig',
        'getCompanyConfigList',
      ],
    }),

    // @Get('/form-config/update-active-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryActiveConfigFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryActiveConfigFormConfig();
    // }
    updateFerryActiveConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      string
    >({
      query: (companyId: string) => ({
        url: `${base_url}/form-config/update-active-config/${companyId}`,
        method: 'GET',
      }),
      providesTags: ['updateFerryActiveConfigFormConfig'],
    }),

    // @Post('/update-active-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryActiveConfig(
    //     @Body() body: CompanyConfigClientUpdateActiveConfigRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryActiveConfig(body);
    // }
    updateFerryActiveConfig: builder.mutation<
      CompanyConfigClientGetListResponse,
      CompanyConfigClientUpdateActiveConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-active-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'updateFerryActiveConfigFormConfig',
        'getFerryCompanyConfig',
        'getCompanyConfigList',
      ],
    }),

    //-----------------------COMPANY TERMS-----------------//

    // @"POST"('/form-config/update-operator-terms/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperatorTermsConfigFormConfig(
    //     @Body() body: ConfigOperatorClientGetFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryOperatorTermsFormConfig(body);
    // }

    getUpdateFerryOperatorTermsConfigFormConfig: builder.mutation<
      ClientGetFormConfigWithCheckResponse,
      ConfigOperatorClientGetFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-operator-terms/`,
        method: 'POST',
        data,
      }),
    }),

    // @"POST"('/update-operations-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperations(
    //     @Body() body: CompanyConfigClientUpdateFerryOperationsRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryOperations(body);
    // }

    updateFerryOperationsConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientUpdateFerryOperationsRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-operations-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getFerryCompanyConfig', 'getUpdateFerryOperationsFormConfig'],
    }),

    getUpdateFerryOperationsFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      string
    >({
      query: (companyId) => ({
        url: `${base_url}/form-config/update-operations-config/${companyId}`,
        method: 'GET',
      }),
      providesTags: ['getUpdateFerryOperationsFormConfig'],
    }),
    //---------------UPDATE FERRY COMPANY OPERATOR---------------//

    // @Get('/form-config/update-ferry-operator/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyOperatorConfigFormConfig(
    //     @Params('id') id: string,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryCompanyOperatorConfigFormConfig(id);
    // }

    updateFerryCompanyOperatorConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      string
    >({
      query: (id) => ({
        url: `${base_url}/form-config/update-ferry-operator/${id}`,
        method: 'GET',
      }),
      providesTags: ['updateFerryCompanyOperatorConfigFormConfig'],
    }),

    // @Post('/update-ferry-operator/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyOperatorConfig(
    //     @Body() body: CompanyConfigUpdateFerryCompanyOperatorRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryCompanyOperatorConfig(body);
    // }

    updateFerryCompanyOperatorConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigUpdateFerryCompanyOperatorRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-operator/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getFerryCompanyConfig',
        'updateFerryCompanyOperatorConfigFormConfig',
      ],
    }),
    //---------------FERRY OPERATOR---------------//

    // @Post('/form-config/update-operator-contact/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperatorContactConfigFormConfig(
    //     @Body() body: ConfigOperatorClientGetFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryOperatorContactConfigFormConfig(body);
    // }
    updateFerryOperatorContactConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      ConfigOperatorClientGetFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-operator-contact/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryOperatorContactConfigFormConfig'],
    }),
    // @Post('/update-operator-contact/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperatorContactConfig(
    //     @Body() body: CompanyConfigClientUpdateOperatorContactsRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryOperatorContactConfig(body);
    // }
    updateFerryOperatorContactConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientUpdateOperatorContactsRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-operator-contact/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getFerryCompanyConfig',
        'updateFerryOperatorContactConfigFormConfig',
      ],
    }),
    // @Post('/form-config/update-operator-e-ticket/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperatorETicketConfigFormConfig(
    //     @Body() body: ConfigOperatorClientGetFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryOperatorETicketConfigFormConfig(body);
    // }
    updateFerryOperatorETicketConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      ConfigOperatorClientGetFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-operator-e-ticket/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryOperatorETicketConfigFormConfig'],
    }),
    // @Post('/update-operator-e-ticket/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperatorETicketConfig(
    //     @Body() body: CompanyConfigClientUpdateOperatorETicketConfigRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryOperatorETicketConfig(body);
    // }
    updateFerryOperatorETicketConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientUpdateOperatorETicketConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-operator-e-ticket/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getFerryCompanyConfig',
        'updateFerryOperatorETicketConfigFormConfig',
      ],
    }),
    // @Post('/form-config/update-operator-terms/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperatorTermsConfigFormConfig(
    //     @Body() body: ConfigOperatorClientGetFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryOperatorTermsFormConfig(body);
    // }
    updateFerryOperatorTermsConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      ConfigOperatorClientGetFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-operator-terms/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryOperatorTermsConfigFormConfig'],
    }),

    // @Post('/update-operator-terms/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperatorTermsConfig(
    //     @Body() body: CompanyConfigClientUpdateOperatorTermsRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryOperatorTerms(body);
    // }
    updateFerryOperatorTermsConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientUpdateOperatorTermsRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-operator-terms/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getFerryCompanyConfig',
        'updateFerryOperatorTermsConfigFormConfig',
      ],
    }),

    // @Post('/form-config/update-operator-custom-operation/', [
    //     applyRoleValidation(['ADMIN', 'SYSTEM']),
    // ])
    // updateFerryOperatorCustomerOperationFormConfig(
    //     @Body() body: ConfigOperatorClientGetFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryOperatorOperationsFormConfig(body);
    // }
    updateFerryOperatorCustomerOperationFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      ConfigOperatorClientGetFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-operator-custom-operation/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryOperatorCustomerOperationFormConfig'],
    }),

    // @Post('/update-operator-custom-operation/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryOperatorCustomerOperation(
    //     @Body() body: CompanyConfigClientUpdateOperatorOperationsRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryOperatorOperations(body);
    // }
    updateFerryOperatorCustomerOperation: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientUpdateOperatorOperationsRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-operator-custom-operation/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getFerryCompanyConfig',
        'updateFerryOperatorCustomerOperationFormConfig',
      ],
    }),

    // //---------------UPDATE FERRY RESIDENT CONFIG---------------//

    // @Get('/form-config/update-resident-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryResidentProviderFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryResidentProviderFormConfig();
    // }
    // @Post('/update-resident-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryResidentProvider(
    //     @Body() body: CompanyConfigClientUpdateFerryResidentConfigRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryResidentProvider(body);
    // }

    // //---------------FERRY DISCOUNT CONFIG---------------//

    // @Post('/form-config/create-ferry-discount-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addFerryCompanyDiscountConfigFormConfig(
    //     @Body() body: CompanyConfigClientGetFormConfigRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.addFerryCompanyDiscountConfigFormConfig(body);
    // }
    addFerryCompanyDiscountConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      CompanyConfigClientGetFormConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/create-ferry-discount-config/`,
        method: 'POST',
        data,
      }),
      providesTags: ['addFerryCompanyDiscountConfigFormConfig'],
    }),

    // @Post('/create-ferry-discount-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addFerryCompanyDiscountConfig(
    //     @Body() body: CompanyConfigClientCreateFerryCompanyDiscountRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.addFerryCompanyDiscountConfig(body);
    // }
    addFerryCompanyDiscountConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientCreateFerryCompanyDiscountRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-ferry-discount-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getFerryCompanyConfig'],
    }),
    // @Post('/form-config/update-ferry-discount-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyDiscountConfigFormConfig(
    //     @Body() body: CompanyConfigClientGetFerryCompanyDiscountFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryCompanyDiscountConfigFormConfig(body);
    // }
    updateFerryCompanyDiscountConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      CompanyConfigClientGetFerryCompanyDiscountFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-ferry-discount-config/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryCompanyDiscountConfigFormConfig'],
    }),

    // @Post('/update-ferry-discount-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyDiscountConfig(
    //     @Body() body: CompanyConfigClientCreateFerryCompanyDiscountRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryCompanyDiscountConfig(body);
    // }
    updateFerryCompanyDiscountConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientCreateFerryCompanyDiscountRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-discount-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'updateFerryCompanyDiscountConfigFormConfig',
        'getFerryCompanyConfig',
      ],
    }),
    //---------------FERRY PASSENGER CONFIG---------------//

    // @Get('/form-config/create-ferry-passenger-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addFerryCompanyPassengerConfigFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.addFerryCompanyPassengerConfigFormConfig();
    // }
    addFerryCompanyPassengerConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/create-ferry-passenger-config/`,
        method: 'GET',
      }),
      providesTags: ['addFerryCompanyPassengerConfigFormConfig'],
    }),
    // @Post('/create-ferry-passenger-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addFerryCompanyPassengerConfig(
    //     @Body() body: CompanyConfigClientCreateFerryCompanyPassengerTicketRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.addFerryCompanyPassengerConfig(body);
    // }
    addFerryCompanyPassengerConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientCreateFerryCompanyPassengerTicketRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-ferry-passenger-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getFerryCompanyConfig'],
    }),
    // @Get('/form-config/update-ferry-passenger-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyPassengerConfigFormConfig(
    //     body: CompanyConfigClientGetFerryCompanyTicketFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryCompanyPassengerConfigFormConfig(body);
    // }
    updateFerryCompanyPassengerConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      CompanyConfigClientGetFerryCompanyTicketFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-ferry-passenger-config/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryCompanyPassengerConfigFormConfig'],
    }),
    // @Post('/update-ferry-passenger-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyPassengerConfig(
    //     @Body() body: CompanyConfigClientCreateFerryCompanyPassengerTicketRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryCompanyPassengerConfig(body);
    // }
    updateFerryCompanyPassengerConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientCreateFerryCompanyPassengerTicketRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-passenger-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'updateFerryCompanyPassengerConfigFormConfig',
        'getFerryCompanyConfig',
      ],
    }),
    //------------------ PASSENGER CUSTOM OPERATIONS-------------------//
    // @Post('/form-config/update-ferry-passenger-operation-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryPassengerOperationFormConfig(
    //     @Body() body: CompanyConfigClientGetFerryCompanyTicketFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryPassengerOperationsFormConfig(body);
    // }
    updateFerryPassengerOperationFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      CompanyConfigClientGetFerryCompanyTicketFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-ferry-passenger-operation-config/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryCompanyPassengerOperationConfigFormConfig'],
    }),

    // @Post('/update-ferry-passenger-operation-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryPassengerOperation(
    //     @Body() body: CompanyConfigClientUpdatePassengerOperationsRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryPassengerOperationsConfig(body);
    // }
    updateFerryPassengerOperation: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientUpdatePassengerOperationsRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-passenger-operation-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'updateFerryCompanyPassengerOperationConfigFormConfig',
        'getFerryCompanyConfig',
      ],
    }),
    //---------------FERRY VEHICLE CONFIG---------------//

    // @Get('/form-config/create-ferry-vehicle-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addFerryCompanyVehicleConfigFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.addFerryCompanyVehicleConfigFormConfig();
    // }
    addFerryCompanyVehicleConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/create-ferry-vehicle-config/`,
        method: 'GET',
      }),
      providesTags: ['addFerryCompanyVehicleConfigFormConfig'],
    }),
    // @Post('/create-ferry-vehicle-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addFerryCompanyVehicleConfig(
    //     @Body() body: CompanyConfigClientCreateFerryCompanyVehicleTicketRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.addFerryCompanyVehicleConfig(body);
    // }
    addFerryCompanyVehicleConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientCreateFerryCompanyVehicleTicketRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-ferry-vehicle-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getFerryCompanyConfig'],
    }),
    // @Get('/form-config/update-ferry-vehicle-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyVehicleConfigFormConfig(
    //     body: CompanyConfigClientGetFerryCompanyTicketFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryCompanyVehicleConfigFormConfig(body);
    // }
    updateFerryCompanyVehicleConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      CompanyConfigClientGetFerryCompanyTicketFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-ferry-vehicle-config/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryCompanyVehicleConfigFormConfig'],
    }),
    // @Post('/update-ferry-vehicle-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyVehicleConfig(
    //     @Body() body: CompanyConfigClientCreateFerryCompanyVehicleTicketRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryCompanyVehicleConfig(body);
    // }
    updateFerryCompanyVehicleConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientCreateFerryCompanyVehicleTicketRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-vehicle-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'updateFerryCompanyVehicleConfigFormConfig',
        'getFerryCompanyConfig',
      ],
    }),
    //---------------FERRY EXTRA CONFIG---------------//

    // @Get('/form-config/create-ferry-extra-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addFerryCompanyExtraConfigFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.addFerryCompanyExtraConfigFormConfig();
    // }
    addFerryCompanyExtraConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/create-ferry-extra-config/`,
        method: 'GET',
      }),
      providesTags: ['addFerryCompanyExtraConfigFormConfig'],
    }),
    // @Post('/create-ferry-extra-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addFerryCompanyExtraConfig(
    //     @Body() body: CompanyConfigClientCreateFerryCompanyExtraTicketRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.addFerryCompanyExtraConfig(body);
    // }
    addFerryCompanyExtraConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientCreateFerryCompanyExtraTicketRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-ferry-extra-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getFerryCompanyConfig'],
    }),
    // @Get('/form-config/update-ferry-extra-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyExtraConfigFormConfig(
    //     body: CompanyConfigClientGetFerryCompanyTicketFormRequest,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._companyConfigService.updateFerryCompanyExtraConfigFormConfig(body);
    // }
    updateFerryCompanyExtraConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      CompanyConfigClientGetFerryCompanyTicketFormRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/update-ferry-extra-config/`,
        method: 'POST',
        data,
      }),
      providesTags: ['updateFerryCompanyExtraConfigFormConfig'],
    }),
    // @Post('/update-ferry-extra-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateFerryCompanyExtraConfig(
    //     @Body() body: CompanyConfigClientCreateFerryCompanyExtraTicketRequest,
    // ): Promise<CompanyConfigClientGetFerryConfigResponse> {
    //     return this._companyConfigService.updateFerryCompanyExtraConfig(body);
    // }
    updateFerryCompanyExtraConfig: builder.mutation<
      CompanyConfigClientGetFerryConfigResponse,
      CompanyConfigClientCreateFerryCompanyExtraTicketRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-extra-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'updateFerryCompanyExtraConfigFormConfig',
        'getFerryCompanyConfig',
      ],
    }),
  }),
});
