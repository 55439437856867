import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { IconCircle } from '@droplet-tech-code/core-elements/module/ui/Icon';
import {
  PopoverContainer,
  PopoverList,
} from '@droplet-tech-code/core-elements/module/ui/Popover';
import { PurchaseClientWithMetaWithTickets } from '@naus-code/naus-admin-types';
import { TouchableOpacity } from 'react-native';

import { Globals } from '~/components/Globals/Globals';
import { MenuActionItem } from '~/components/MenuActionItem';
import {
  PurchaseMenuItem,
  sortPurchaseMenuList,
  useGetPurchaseMenuList,
} from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.utils';

const PurchaseActionItem = ({
  item,
}: {
  item: PurchaseMenuItem;
  supportId?: string;
  purchase: PurchaseClientWithMetaWithTickets;
}) => {
  if ('item' in item) {
    return <>{item.item}</>;
  }

  return (
    <TouchableOpacity onPress={item.onPress} disabled={item.disabled}>
      <MenuActionItem icon={item.icon} text={item.text} disabled={item.disabled} />
    </TouchableOpacity>
  );
};

export const PurchaseMenu = ({
  purchase,
  supportId,
}: {
  purchase: PurchaseClientWithMetaWithTickets;
  supportId?: string;
}) => {
  const purchaseMenuList = useGetPurchaseMenuList(purchase, supportId);
  const sortedPurchaseMenuList = sortPurchaseMenuList(purchaseMenuList);

  return (
    <PopoverContainer
      width={250}
      maxHeight={320}
      Popover={
        <PopoverList
          list={sortedPurchaseMenuList
            // .filter((item) => !item.disabled)
            .map((item, index) => {
              return {
                item: (
                  <PurchaseActionItem
                    key={index}
                    item={item}
                    purchase={purchase}
                    supportId={supportId}
                  />
                ),
              };
            })}
        />
      }
    >
      {({ hovered }: ButtonInteractionState) => (
        <IconCircle icon="menu" hovered={hovered} />
      )}
    </PopoverContainer>
  );
};

Globals.PurchaseMenu = PurchaseMenu;
