import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropAreaLocations } from '~/navigation/Navigation.types';

import { AreaLocationItem } from './AreaLocation.Item';
import { AreaLocationList, AreaLocationListNative } from './AreaLocation.List';

export const AreaLocationsScreen = (props: ScreenPropAreaLocations) => {
  return (
    <PageContainer>
      <AreaLocationLayout {...props} />
    </PageContainer>
  );
};

export const AreaLocationLayout = (props: ScreenPropAreaLocations) => {
  const areaLocationId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(areaLocationId);
  }, [areaLocationId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <AreaLocationList onPress={(item) => setId(item.id)} />
      ) : (
        <AreaLocationListNative />
      )}
      {id ? <AreaLocationItem id={id} onClose={() => setId(undefined)} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
