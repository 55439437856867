/* eslint-disable @typescript-eslint/no-var-requires */
import type * as allTypes from "./firebase.web";

const { __DONT__IMPORT__firebase: firebase } = require(
  `./firebase${__PLATFORM__}`,
) as typeof allTypes;

if (!__PLATFORM__) {
  throw new Error(
    "To use firebase you must has platform set __PLATFORM__ set in webpack/metro bundler'",
  );
}

export { firebase };
