import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { useCallback, useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { translate } from '~/utils/translation.utils';

export const AssignUserModalKey = 'AddModal-AssignUser';

const AssignUserModal = ({ check, supportId }: { check: string; supportId: string }) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [assignUser, { isLoading: isAssigningUser }] =
    supportRequestActionsApi.useAssignAdminUserMutation();
  const [unassignUser, { isLoading: isRemovingUser }] =
    supportRequestActionsApi.useUnassignAdminUserMutation();

  const { data: supportRequest } = supportRequestApi.useGetSupportRequestQuery(supportId);

  const onAssignUser = useCallback(
    async ({ adminUserId }: { adminUserId: string }) => {
      const response = await assignUser({ adminUserId, supportId, check });
      if (!isAssigningUser) {
        handleResponse({
          response,
          successMessage: translate('supportRequests.userAssigned'),
        });
      }
    },
    [check, supportId],
  );

  const onUnassignUser = useCallback(async () => {
    const response = await unassignUser({ supportId, check });
    if (!isAssigningUser) {
      handleResponse({
        response,
        successMessage: translate('supportRequests.userUnassigned'),
      });
    }
    closeModal(AssignUserModalKey);
  }, [check, supportId]);

  return (
    <Modal.Container>
      <VStack style={{ maxWidth: 1000 }} flex={1}>
        <Modal.Header
          title={translate('supportRequests.assignUser')}
          divider
          children={
            supportRequest?.supportRequest._adminUserId ? (
              <UnassignUserButton
                onUnassignUser={onUnassignUser}
                isRemovingUser={isRemovingUser}
              />
            ) : null
          }
          onPress={() => {
            closeModal(AssignUserModalKey);
          }}
        />
        <ModalScrollView>
          <AssignUserForm form={formRef.current} supportId={supportId} />
        </ModalScrollView>
        <Modal.Footer
          divider
          onConfirm={() => {
            const { valid, value } = formRef.current.getValue<{
              [key: string]: string;
            }>();
            if (valid) {
              const response = onAssignUser({ adminUserId: value.adminUserId });
              handleResponse({ response, onSuccess: () => {} });
              closeModal(AssignUserModalKey);
            }
          }}
          onCancel={async () => {
            closeModal(AssignUserModalKey);
          }}
        />
      </VStack>
    </Modal.Container>
  );
};

export const useAssignUser = () => {
  const { openModal } = useModal();

  return {
    open: (check: string, supportId: string) => {
      openModal(<AssignUserModal check={check} supportId={supportId} />, {
        key: AssignUserModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};

const AssignUserForm = ({
  form,
  supportId,
}: {
  form: FormHandler;
  supportId: string;
}) => {
  const { data: formConfig } =
    supportRequestActionsApi.useAssignAdminUserFormConfigQuery(supportId);

  if (formConfig === undefined) {
    return null;
  }

  return (
    <FormContent isLoading={false} form={form} flex={1}>
      <FormBuilder data={formConfig} form={form} />
    </FormContent>
  );
};

const UnassignUserButton = ({
  onUnassignUser,
  isRemovingUser,
}: {
  onUnassignUser: () => void;
  isRemovingUser: boolean;
}) => {
  return (
    <IconButton
      icon="support-closed"
      color="monochrome"
      onPress={onUnassignUser}
      isLoading={isRemovingUser}
    />
  );
};
