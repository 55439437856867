import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { useRef } from 'react';

import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

export const PurchasesFromProvider = 'AddModal-ReservationFromProviderNavigate';
export const ReservationFromProviderNavigateModal = () => {
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();
  const navigation = useAppNavigation();

  return (
    <>
      <Modal.Header
        title={translate('purchases.reservationFromProvider')}
        subtitle={translate('purchases.enterReservationDetails')}
        divider
        onPress={() => {
          closeModal(PurchasesFromProvider);
        }}
      />
      <Modal.Body p="0">
        <FormBuilder
          data={[
            {
              items: [
                {
                  type: 'text',
                  label: translate('purchases.providerReservationCode'),
                  id: 'providerReservationCode',
                },
                {
                  type: 'text',
                  label: translate('purchases.provider'),
                  id: 'provider',
                },
                {
                  type: 'single-select',
                  list: [{ value: 'EUR' }, { value: 'USD' }, { value: 'GBP' }],
                  label: translate('purchases.ccy'),
                  id: 'ccy',
                },
              ],
            },
          ]}
          form={form.current}
        />
      </Modal.Body>
      <Modal.Footer
        divider
        onConfirm={async () => {
          const { valid, value } = form.current.getValue<{
            providerReservationCode: string;
            provider: string;
            ccy: string;
          }>();
          if (valid) {
            closeModal(PurchasesFromProvider);
            // navigation.navigate("ReservationByProvider", value);

            navigation.navigate('ReservationFromProvider', value);
          }
        }}
        onCancel={() => {
          closeModal(PurchasesFromProvider);
        }}
      />
    </>
  );
};

export const useReservationFromProviderNavigateModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: () => {
      openModal(<ReservationFromProviderNavigateModal />, {
        key: PurchasesFromProvider,
        type: 'pop-up',
      });
    },
    close: () => {
      closeModal(PurchasesFromProvider);
    },
  };
};
