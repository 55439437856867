import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useCallback } from 'react';

import { useAddNotice } from '~/components/Notices/Modals/CreateNotice';
import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

import { useInvalidatePendingNoticeList } from './PendingNotice.List';

export const PendingNoticesListHeader = () => {
  return (
    <PageHeader
      title={translate('notices.pendingNotices')}
      withBack={isNativeMobile}
      childRight={<RightActions />}
    />
  );
};

const RightActions = () => {
  const { open: openCreateNotice } = useAddNotice();
  const onPressCreateNotice = useCallback(() => {
    openCreateNotice();
  }, []);

  const { invalidate, isLoading } = useInvalidatePendingNoticeList();
  const onRefresh = useCallback(() => {
    invalidate();
  }, []);

  return (
    <HStack>
      <IconButton
        icon="refresh"
        onPress={onRefresh}
        isLoading={isLoading}
        color="monochrome"
      />
      <IconButton icon="plus" onPress={onPressCreateNotice} color="monochrome" />
    </HStack>
  );
};
