import { FormGroup } from '@droplet-tech-code/core-common-types';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { LangText } from '@naus-code/naus-server-utils-common-types';
import { useRef } from 'react';

import { Card } from '~/components/Card/Card';
import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { translate } from '~/utils/translation.utils';

import { formatThreeColumnForm, getAllResults, replaceText } from '../utils';

export const ManualFillInModalKey = 'AddModal-ManualFillIn';

const ManualFillInModal = ({
  text,
  onAddTemplateAnswer,
}: {
  text: LangText;
  onAddTemplateAnswer: (answer: string) => void;
}) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());
  const entries = getAllResults(text['en'], '{{', '}}');
  return (
    <Modal.Container>
      <VStack style={{ maxWidth: 1000 }} flex={1}>
        <Modal.Header
          title={translate('supportRequests.newSupportRequest')}
          subtitle={translate('supportRequests.enterNewSupportRequest')}
          divider
          onPress={() => {
            closeModal(ManualFillInModalKey);
          }}
        />
        <ModalScrollView>
          <ManualFillInForm entries={entries} form={formRef.current} />
          <Divider b={5} br={5} bc="primary-extraLight" />
          <PreviewField previewText={text['en']} />
        </ModalScrollView>
        <Modal.Footer
          divider
          onConfirm={() => {
            const { valid, value } = formRef.current.getValue();
            if (valid) {
              onAddTemplateAnswer(replaceText(value, text['en']));
              closeModal(ManualFillInModalKey);
            }
          }}
          onCancel={async () => {
            closeModal(ManualFillInModalKey);
          }}
        />
      </VStack>
    </Modal.Container>
  );
};

export const useManualFillIn = () => {
  const { openModal } = useModal();

  return {
    open: (text: LangText, onAddTemplateAnswer: (answer: string) => void) => {
      openModal(
        <ManualFillInModal text={text} onAddTemplateAnswer={onAddTemplateAnswer} />,
        {
          key: ManualFillInModalKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};
const ManualFillInForm = ({
  entries,
  form,
}: {
  entries: string[];
  form: FormHandler;
}) => {
  const formGroupList: FormGroup[] = entries.map((key: string) => {
    return {
      label: key,
      id: key,
      type: 'text',
    };
  });

  const data = formatThreeColumnForm(formGroupList);

  return (
    <FormContent isLoading={false} form={form} flex={1}>
      <FormBuilder data={data} form={form} />
    </FormContent>
  );
};

const PreviewField = ({ previewText }: { previewText: string }) => {
  return (
    <VStack p="2.5" space="2.5">
      <Text.Small color="monochrome-mid">
        {translate('supportRequests.previewText')}
      </Text.Small>
      <Card p="2.5" style={{ minHeight: 50 }}>
        <Text.Body2Regular style={{ flexWrap: 'wrap' }}>{previewText}</Text.Body2Regular>
      </Card>
    </VStack>
  );
};
