import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import {
  Divider,
  DottedLine,
} from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { ContentFeedbackHandler } from '@droplet-tech-code/core-elements/module/ui/Feedback/Feedback.Handler';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { PurchaseClientWithMetaWithTickets } from '@naus-code/naus-admin-types';
import { useState } from 'react';

import { LiveDateText } from '~/components/DateText';
import { Globals } from '~/components/Globals/Globals';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { PurchaseListItemInternalComponent } from '~/screens/Support/Purchases/Purchase.ListItem';
import {
  getIdFromPurchase,
  getPurchaseWithHistory,
  PurchaseMix,
} from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

import { TicketDetails } from '../PurchaseItem';
import { purchaseApi } from '../Purchases.api';

const ModalKey = 'PurchaseHistoryViewer';

const PurchaseHistoryViewer = ({ purchase }: { purchase: PurchaseMix }) => {
  const history = getPurchaseWithHistory(purchase);
  const { data: purchaseData } = purchaseApi.useGetPurchaseQuery(
    getIdFromPurchase(purchase),
  );
  const { closeModal } = useModal();

  const [currentIndex, setCurrentIndex] = useState(0);

  if (!history || history.length === 0) {
    return (
      <ContentFeedbackHandler
        data={undefined}
        isLoading={false}
        dataEmpty
        feedback={translate('feedback.emptyData')}
      >
        {''}
      </ContentFeedbackHandler>
    );
  }

  return (
    <VStack>
      <Modal.Header
        onPress={() => {
          closeModal(ModalKey);
        }}
        title={translate('purchases.purchaseHistory.purchaseHistory')}
        divider
      >
        <HStack space="1" justify="flex-end">
          <IconButton
            icon="chevron-left"
            onPress={() => {
              setCurrentIndex(Math.max(0, currentIndex - 1));
            }}
            disabled={currentIndex === 0}
          />

          <IconButton
            icon="chevron-right"
            onPress={() => {
              setCurrentIndex(Math.min(history.length - 1, currentIndex + 1));
            }}
            disabled={currentIndex === history.length - 1}
          />
        </HStack>
      </Modal.Header>

      <ModalScrollView>
        <VStack p="2.5">
          <HStack space="3" align="flex-start">
            <VStack space="2">
              <Text.Body2Regular>
                {translate('purchases.purchaseHistory.currentPurchase')}
              </Text.Body2Regular>
              <DottedLine />
              <PurchaseListItemInternalComponent purchase={purchase} compareMode />
            </VStack>

            <Divider vertical />

            <VStack space="2">
              <HStack>
                <Text.Body2Regular flex={1}>
                  {translate('purchases.purchaseHistory.historicalPurchase')}(
                  {currentIndex + 1} {'/'}
                  {history.length})
                </Text.Body2Regular>

                <LiveDateText
                  dateTime={history[currentIndex].timestamp}
                  formatOption="lifetimeDependent&fullDateTime"
                  type="body2Medium"
                />
              </HStack>
              <DottedLine />
              <HistoricalPurchaseItem
                purchase={
                  {
                    ...history[currentIndex].purchase,
                    tickets: [],
                  } as any
                }
              />
            </VStack>
          </HStack>
          {purchaseData &&
            purchaseData.purchase.tickets.map((ticket, index) => {
              return (
                <TicketDetails
                  key={index}
                  ticket={ticket}
                  ccySymbol={purchase.ccySymbol}
                  // withActions={withActions}
                />
              );
            })}
        </VStack>
      </ModalScrollView>
    </VStack>
  );
};

export const usePurchaseHistoryViewer = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (purchase: PurchaseMix) => {
      openModal(<PurchaseHistoryViewer purchase={purchase} />, {
        key: ModalKey,
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

Globals.usePurchaseHistoryViewer = usePurchaseHistoryViewer;

const HistoricalPurchaseItem = ({
  purchase,
}: {
  purchase: PurchaseClientWithMetaWithTickets;
}) => {
  return <PurchaseListItemInternalComponent purchase={purchase} compareMode />;
};
