import {
  AppTextProps,
  BaseTextProps,
  Text,
} from '@droplet-tech-code/core-elements/module/ui/Text';
import { copyToClipboard } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';

import { changeRefresh, DateFormatType, processDateTime } from '~/utils/date.utils';

interface LiveDateTextComponentProps extends BaseTextProps {
  dateTime: string;
  formatOption: DateFormatType;
  indexTextStyles?: (AppTextProps | undefined)[];
  isCopyable?: boolean;
  defaultText?: string;
}

export const LiveDateText = (props: LiveDateTextComponentProps) => {
  return <LiveDateTextComponent key={props.dateTime} {...props} />;
};

const LiveDateTextComponent = ({
  dateTime,
  formatOption,
  indexTextStyles,
  defaultText,
  isCopyable,
  ...textProps
}: LiveDateTextComponentProps) => {
  const { newRefreshTime: initRefreshTime, denomination: initDenomination } =
    changeRefresh(dateTime, 60 * 1000);
  const initFormattedDateTime = processDateTime(dateTime, formatOption, initDenomination);

  // 1. TIMERREF
  const timerRef = useRef<number>();
  const refreshTimeInMsRef = useRef<number>(initRefreshTime);
  const [formattedDateTime, setFormattedDateTime] =
    useState<string[]>(initFormattedDateTime);

  const doTimerThings = useCallback(() => {
    // Clear all timers
    clearTimeout(timerRef.current);
    // Run timer
    timerRef.current = window.setTimeout(() => {
      // Run my self again
      doTimerThings();
    }, refreshTimeInMsRef.current);

    const { needsChangeInRefresh, newRefreshTime, denomination } = changeRefresh(
      dateTime,
      refreshTimeInMsRef.current,
    );

    if (needsChangeInRefresh) {
      refreshTimeInMsRef.current = newRefreshTime;
    }
    const formattedDateTime = processDateTime(dateTime, formatOption, denomination);
    setFormattedDateTime(formattedDateTime);
  }, []);

  useEffect(() => {
    // 1. On mount run timer
    if (
      formatOption.includes('expiryDependent') ||
      formatOption.includes('lifetimeDependent') ||
      formatOption.includes('duration')
    ) {
      doTimerThings();
    }
    return () => {
      // Finally. Clear all timers on unmount
      clearTimeout(timerRef.current);
    };
  }, []);

  const isEmpty = formattedDateTime.filter((t) => !!t).length === 0;

  if (isEmpty && !defaultText) {
    return null;
  }

  return (
    <Pressable
      disabled={!isCopyable}
      onPress={() => {
        if (isCopyable) {
          copyToClipboard(formattedDateTime.join(' '));
        }
      }}
    >
      <Text {...textProps}>
        {isEmpty
          ? defaultText || ''
          : formattedDateTime.map((string, index) => {
              const otherProps = indexTextStyles?.[index];
              return (
                <Text key={index} {...textProps} {...otherProps}>
                  {string}
                </Text>
              );
            })}
      </Text>
    </Pressable>
  );
};
