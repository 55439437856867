import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  SupportConversationHandlerLink,
  SupportConversationHandlerResponseType,
} from '@naus-code/naus-client-types';
import React, { useCallback } from 'react';

import { PressableCard } from '~/components/Card/PressableCard';
import { PressableOption } from '~/components/SupportConversation/InputPanel/Conversation.Common';
import { useSupportConversationStore } from '~/components/SupportConversation/InputPanel/Conversation.store';
import { SupportConversationInputPanelProps } from '~/components/SupportConversation/InputPanel/Conversation.utils';
import { chatCache } from '~/components/SupportConversation/SupportConversation.utils';
import { AppText } from '~/components/Text';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { translate } from '~/utils/translation.utils';

export const SupportConversationActionToggles = (
  props: SupportConversationInputPanelProps,
) => {
  if (isNativeMobile) {
    return (
      <VStack space="2.5" style={{ alignSelf: 'stretch', justifyContent: 'flex-end' }}>
        <SendButton {...props} />
      </VStack>
    );
  }
  return (
    <VStack
      space="2.5"
      style={{ alignSelf: 'stretch', justifyContent: 'flex-end' }}
      pb="1"
    >
      <ActionToggles {...props} />
      {isWeb && <SendButton {...props} />}
    </VStack>
  );
};

export const ActionToggles = (props: SupportConversationInputPanelProps) => {
  return (
    <>
      <SendNotification {...props} />
      <SendEmail />
      <SendSms />
      <SendMessageQuote />
    </>
  );
};

const SendButton = (props: SupportConversationInputPanelProps) => {
  const [sendReply, { isLoading: isSendingReply }] =
    supportRequestActionsApi.useSendReplyMutation();
  const hasRated = !!props.supportRequestRes?.supportRequest.feedback?.rating;

  const setState = useSupportConversationStore((s) => s.setState);
  const isUploadingImage = useSupportConversationStore((s) => s.isUploadingImage);

  const advanced = useSupportConversationStore((s) => s.advanced);
  const reset = useSupportConversationStore((s) => s.reset);
  const previewImages = useSupportConversationStore((s) => s.previewImages);
  const checks = useSupportConversationStore((s) => ({
    sendSms: s.sendSms,
    sendEmail: s.sendEmail,
    sendNotification: s.sendNotification,
    sendMessageQuote: s.sendMessageQuote,
  }));

  const onPressSend = useCallback(async () => {
    const advancedValue = props.advancedRef.current.getValue<{
      responseType: SupportConversationHandlerResponseType;
      list?: string[];
      links?: SupportConversationHandlerLink[];
    }>();

    const textValue = props.textRef.current.getSingleValue({
      id: 'answer',
      showFeedback: false,
      validate: false,
    }) as string;

    if (
      hasRated &&
      props.advancedRef.current.getSingleValue({
        id: 'responseType',
        showFeedback: false,
        validate: false,
      }) === 'Rate'
    ) {
      showToast(translate('supportConversations.alreadyRated'), { type: 'error' });
      return;
    }

    const supportRequestRes = props.supportRequestRes;
    props.advancedRef.current = new FormHandler();

    if (!textValue || !supportRequestRes || (advanced && !advancedValue?.valid)) {
      return;
    }

    const response = await sendReply({
      check: supportRequestRes.check,
      supportId: props.supportId,
      message: {
        ...advancedValue?.value,
        responseType: advancedValue.value.responseType || 'Text',
        text: textValue.trim(),
      },

      imgTokens: previewImages.map((img) => img.token).filter((token) => !!token),
      comms: {
        showMessageInComm: checks.sendMessageQuote,
        notification: checks.sendNotification,
        email: checks.sendEmail,
        sms: checks.sendSms,
      },
    });

    handleResponse({
      response,
      onSuccess: () => {
        props.textRef.current.setInputValue('answer', '');
        setState({
          advanced: false,
          reset: !reset,
          previewImages: [],
        });
      },
      disableSuccessToast: true,
    });
    chatCache.delete(props.supportId);
  }, [
    props.supportId,
    props.textRef,
    props.advancedRef,
    props.supportRequestRes,
    checks,
    previewImages,
    advanced,
    setState,
  ]);

  if (isNativeMobile) {
    return (
      <VStack flex={1} justify="center">
        <IconButton
          icon="send"
          onPress={onPressSend}
          variant="filled"
          color="success"
          size="large"
          outerStyle={{ alignSelf: 'center' }}
        />
      </VStack>
    );
  }

  return (
    <PressableCard
      onPress={onPressSend}
      isLoading={isSendingReply}
      isDisabled={isUploadingImage}
      bc="monochrome-light"
    >
      <HStack justify="center">
        <AppText.MiscTag>{translate('supportRequests.send')}</AppText.MiscTag>
        <Icon color="monochrome" icon="send" />
      </HStack>
    </PressableCard>
  );
};

const SendNotification = (props: SupportConversationInputPanelProps) => {
  const sendNotification = useSupportConversationStore((s) => s.sendNotification);
  const setState = useSupportConversationStore((s) => s.setState);
  return (
    <PressableOption
      disabled={!props.supportRequestRes?.supportRequest.pushIds?.length}
      state={sendNotification}
      text={translate('supportRequests.notification')}
      onPress={() => setState({ sendNotification: !sendNotification })}
    />
  );
};
const SendEmail = () => {
  const sendEmail = useSupportConversationStore((s) => s.sendEmail);
  const setState = useSupportConversationStore((s) => s.setState);
  return (
    <PressableOption
      state={sendEmail}
      text={translate('supportRequests.email')}
      onPress={() => setState({ sendEmail: !sendEmail })}
    />
  );
};
const SendSms = () => {
  const sendSms = useSupportConversationStore((s) => s.sendSms);
  const setState = useSupportConversationStore((s) => s.setState);
  return (
    <PressableOption
      state={sendSms}
      text={translate('supportRequests.sms')}
      onPress={() => setState({ sendSms: !sendSms })}
    />
  );
};
const SendMessageQuote = () => {
  const sendMessageQuote = useSupportConversationStore((s) => s.sendMessageQuote);
  const setState = useSupportConversationStore((s) => s.setState);
  return (
    <PressableOption
      state={sendMessageQuote}
      text={translate('supportRequests.messageQuote')}
      onPress={() => setState({ sendMessageQuote: !sendMessageQuote })}
    />
  );
};
