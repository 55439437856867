import { paginationUtil } from '~/components/FlatList/FlatList.Paginated';
import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { translate } from '~/utils/translation.utils';

export const useBookingByCustomerIdInputModal = () => {
  const navigation = useAppNavigation();
  return useCreateManualFormModal({
    title: translate('bookings.bookingsForCustomerId'),
    subtitle: translate('bookings.enterCustomerId'),
    formData: [
      {
        items: [
          {
            type: 'text',
            label: translate('bookings.customerId'),
            id: 'customerId',
          },
        ],
      },
    ],
    onConfirm: (value) => {
      navigation.navigate('BookingsByCustomerId', value);
    },
  });
};

export const useInvalidateCustomerBookingListId = () => {
  const [invalidateCustomerBookingListId, { isLoading }] =
    bookingApi.useInvalidateBookingListByIdMutation();

  return {
    invalidate: async (customerId: string) => {
      const lastItemId = paginationUtil.lists.BookingsByEmail;
      if (lastItemId) {
        await invalidateCustomerBookingListId({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
          customerId,
        });
      }
    },
    isLoading,
  };
};
