import { spacing } from '@droplet-tech-code/core-elements/module/theme';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useCallback, useState } from 'react';

import { PageContainer } from '~/components/Page/Page.Container';
import { PageHeader } from '~/components/Page/Page.Header';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { SupportConfigOfficeItem } from './Office/SupportConfigOffice';
import { SupportConfigOpperationItem } from './Operation/SupportConfigOperation';
import { supportConfigApi } from './supportConfig.api';
import { SupportConfigList } from './SupportConfigList';
import { SupportConfigTemplateItem } from './Template/SupportConfigTemplate';

export const SupportConfigScreen = () => {
  return (
    <PageContainer>
      <SupportConfigScreenLayout />
    </PageContainer>
  );
};

export const SupportConfigScreenLayout = () => {
  const { data, refetch } = supportConfigApi.useGetSupportConfigQuery();

  const { navigate } = useAppNavigation();

  const [officeId, setOfficeId] = useState(
    data?.config.officeConfig.defaultOfficeId || '',
  );
  const [toggleOperation, setToggleOperation] = useState(false);
  const [toggleTemplate, setToggleTemplate] = useState(false);

  const onPressOffice = useCallback((id: string) => {
    setOfficeId(id);
    setToggleOperation(false);
    setToggleTemplate(false);
  }, []);

  const onPressOperation = useCallback(() => {
    setOfficeId('');
    setToggleOperation(true);
    setToggleTemplate(false);
  }, []);

  const onPressTemplate = useCallback(() => {
    setOfficeId('');
    setToggleOperation(false);
    setToggleTemplate(true);
  }, []);

  const onPressOfficeNative = useCallback((id: string) => {
    navigate('SupportConfigOffice', {
      id,
    });
  }, []);

  const onPressOperationNative = useCallback(() => {
    navigate('SupportConfigOperation');
  }, []);

  const onPressTemplateNative = useCallback(() => {
    navigate('SupportConfigTemplate');
  }, []);

  if (data === undefined) {
    return <></>;
  }

  return (
    <View flex={1}>
      <PageHeader
        title={translate('supportConfig.supportConfig')}
        iconRight={{ onPress: refetch, icon: 'refresh' }}
      />
      {isNativeMobile ? (
        <SupportConfigList
          onPressOffice={onPressOfficeNative}
          onPressOperation={onPressOperationNative}
          onPressTemplate={onPressTemplateNative}
        />
      ) : (
        <View flex={1}>
          <HStack flex={1} style={{ alignSelf: 'stretch' }}>
            <View flex={2} style={{ width: '40%', height: '100%' }}>
              <ScrollView
                contentContainerStyle={{
                  flexGrow: 1,
                  paddingHorizontal: spacing[1],
                }}
              >
                <SupportConfigList
                  onPressOffice={onPressOffice}
                  onPressOperation={onPressOperation}
                  onPressTemplate={onPressTemplate}
                />
              </ScrollView>
            </View>
            <View flex={3} style={{ height: '100%' }}>
              <ScrollView
                contentContainerStyle={{
                  flexGrow: 1,
                  paddingHorizontal: spacing[1],
                }}
              >
                <SupportConfigScreenItem
                  officeId={officeId}
                  toggleOperation={toggleOperation}
                  toggleTemplate={toggleTemplate}
                />
              </ScrollView>
            </View>
          </HStack>
        </View>
      )}
    </View>
  );
};

export const SupportConfigScreenItem = ({
  officeId,
  toggleOperation,
  toggleTemplate,
}: {
  officeId: string;
  toggleOperation: boolean;
  toggleTemplate: boolean;
}) => {
  if (officeId !== '') {
    return <SupportConfigOfficeItem officeId={officeId} />;
  }
  if (toggleOperation) {
    return <SupportConfigOpperationItem />;
  }
  if (toggleTemplate) {
    return <SupportConfigTemplateItem />;
  }
  return <></>;
};
