import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  CreateCustomerResponse,
  CreateVoucherForCustomerRequest,
  CustomerClientGetRecentPaymentsResponse,
  FindCustomerRequest,
  GetCustomerResponse,
  GetVoucherResponse,
  UpdateCustomerRequest,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'customer';
export const customerApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query<GetCustomerResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCustomer'],
    }),
    findCustomerByEmail: builder.query<GetCustomerResponse, FindCustomerRequest>({
      query: (data) => ({
        url: `${base_url}/find-by-email/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'findCustomerByEmail'],
    }),

    createCustomerVoucherFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-voucher/`,
        method: 'GET',
      }),
      providesTags: ['*', 'createCustomerVoucherFormConfig'],
    }),

    createCustomerVoucher: builder.mutation<
      GetVoucherResponse,
      CreateVoucherForCustomerRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-voucher/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createCustomerVoucher', 'getCustomer'],
    }),

    updateCustomerFormConfig: builder.query<ConfigForData, string>({
      query: (customerId) => ({
        url: `${base_url}/form-config/update/${customerId}`,
        method: 'GET',
      }),
      providesTags: ['updateCustomerFormConfig'],
    }),

    updateCustomer: builder.mutation<CreateCustomerResponse, UpdateCustomerRequest>({
      query: (data) => ({
        url: `${base_url}/update/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['updateCustomerFormConfig', 'getCustomer'],
    }),
    // @Get('/get-recent-payments/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getRecentPayments(@Params('id') id: string): Promise<CustomerClientGetRecentPaymentsResponse> {
    //     return this._customerService.getRecentPayments(id);
    // }
    getRecentPayments: builder.query<CustomerClientGetRecentPaymentsResponse, string>({
      query: (customerId) => ({
        url: `${base_url}/get-recent-payments/${customerId}`,
        method: 'GET',
      }),
      providesTags: ['getRecentPayments'],
    }),
  }),
});
