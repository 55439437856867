import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  GetTripScheduledChangeResponse,
  TripScheduledChangeVerifyPurchasesResponse,
} from '@naus-code/naus-admin-types';
import { useState } from 'react';
import { SectionList, SectionListRenderItem } from 'react-native';

import { AppText } from '~/components/Text';
import {
  constructScheduledListNicely,
  ScheduledListItemWithId,
} from '~/screens/ScheduledChanges/ChangeList/ScheduleChanges.utils';
import { translate } from '~/utils/translation.utils';

import { useViewPurchase } from '../Modal/PurchaseModal';
import { ScheduleChangePurchasesListItem } from './ScheduledChange.Purchase.ListItem';

export const ScheduleChangePurchasesListHeaderRow = ({
  widthDic,
  setAllSelected,
  selectNone,
  notSelectable,
}: {
  widthDic: { [key: string]: string };
  setAllSelected: () => void;
  selectNone: () => void;
  notSelectable?: boolean;
}) => {
  const [radio, setRadio] = useState(true);
  return (
    // <Card >
    <HStack p="2.5" space="1" style={{ overflow: 'hidden' }}>
      <HStack ml="1">
        {!notSelectable && (
          <IconButton
            iconStyle={{ width: 20 }}
            icon={radio ? 'check-box-on' : 'check-box-off'}
            color="monochrome"
            onPress={() => {
              if (radio) {
                selectNone();
                setRadio(false);
              } else {
                setAllSelected();
                setRadio(true);
              }
            }}
          />
        )}
        <HStack w={widthDic['0']}>
          <AppText.ExtraSmall color="monochrome-dark">
            {translate('scheduledChanges.vesselRes')}
          </AppText.ExtraSmall>
        </HStack>
        <HStack w={widthDic['1']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.date')}
          </Text.Small>
          <Text.Small align="center" color="monochrome-dark">
            {'|'}
          </Text.Small>
        </HStack>
        <HStack w={widthDic['2']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.depTime')}
          </Text.Small>
          <Text.Small align="center" color="monochrome-dark">
            {'|'}
          </Text.Small>
        </HStack>
        <HStack w={widthDic['3']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.origin')}
          </Text.Small>
          <Text.Small align="center" color="monochrome-dark">
            {'|'}
          </Text.Small>
        </HStack>
        <HStack w={widthDic['4']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.arrTime')}
          </Text.Small>
          <Text.Small align="center" color="monochrome-dark">
            {'|'}
          </Text.Small>
        </HStack>
        <HStack w={widthDic['5']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.destination')}
          </Text.Small>
        </HStack>
      </HStack>

      <View px="3">
        <Icon
          icon="arrow-right"
          size="large"
          color="monochrome-light"
          iconStyle={{ opacity: 0 }}
        />
      </View>
      {/* <Text.Body1Medium color="monochrome-dark">{"|"}</Text.Body1Medium> */}
      <HStack>
        <HStack w={widthDic['0']}>
          <AppText.ExtraSmall color="monochrome-dark" style={{ opacity: 0 }}>
            {translate('scheduledChanges.vesselRes')}
          </AppText.ExtraSmall>
        </HStack>
        <HStack w={widthDic['1']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.date')}
          </Text.Small>
          <Text.Small align="center" color="monochrome-dark">
            {'|'}
          </Text.Small>
        </HStack>
        <HStack w={widthDic['2']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.depTime')}
          </Text.Small>
          <Text.Small align="center" color="monochrome-dark">
            {'|'}
          </Text.Small>
        </HStack>
        <HStack w={widthDic['3']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.origin')}
          </Text.Small>
          <Text.Small align="center" color="monochrome-dark">
            {'|'}
          </Text.Small>
        </HStack>
        <HStack w={widthDic['4']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.arrTime')}
          </Text.Small>
          <Text.Small align="center" color="monochrome-dark">
            {'|'}
          </Text.Small>
        </HStack>
        <HStack w={widthDic['5']}>
          <Text.Small align="center" flex={1} color="monochrome-dark">
            {translate('scheduledChanges.destination')}
          </Text.Small>
        </HStack>
      </HStack>
    </HStack>
    // </Card>
  );
};

export const ChangeSchedulePurchasesList = ({
  selected,
  onSetSelected,
  widthDic,
  data,
  notSelectable,
}: {
  selected: string[];
  onSetSelected: (id: string) => void;
  widthDic: { [key: string]: string };
  data: GetTripScheduledChangeResponse | TripScheduledChangeVerifyPurchasesResponse;
  notSelectable?: boolean;
}) => {
  const updateChangesList = constructScheduledListNicely(data);

  const { open: openViewPurchaseModal } = useViewPurchase();

  const keyExtractor = (item: ScheduledListItemWithId, index: number) => item.id + index;
  const renderItem: SectionListRenderItem<ScheduledListItemWithId> = ({
    item: updateChanges,
  }) => {
    const isSelected = selected.indexOf(updateChanges.id) >= 0;
    return (
      <ScheduleChangePurchasesListItem
        onPress={() => openViewPurchaseModal(updateChanges.id)}
        isSelected={isSelected}
        widthDic={widthDic}
        updateChanges={updateChanges}
        notSelectable={notSelectable}
        onSelect={() => {
          onSetSelected(updateChanges.id);
        }}
      />
    );
  };

  return (
    // <VStack space="2.5" mt="2.5">

    <SectionList
      sections={updateChangesList}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={() => <View my="1.5" />}
      renderSectionHeader={({ section: { title } }) =>
        title ? (
          <View mb="3" mt="6">
            <Text.Body2Medium color="monochrome-extraDark">{title}</Text.Body2Medium>
          </View>
        ) : null
      }
    />

    // {updateChangesList.map((updateChanges, index) => {
    // const isSelected = selected.indexOf(updateChanges.id) >= 0;

    // return (
    //   <ScheduleChangePurchasesListItem
    //     onPress={() => openViewPurchaseModal(updateChanges.id)}
    //     isSelected={isSelected}
    //     key={index}
    //     widthDic={widthDic}
    //     updateChanges={updateChanges}
    //     notSelectable={notSelectable}
    //     onSelect={() => {
    //       onSetSelected(updateChanges.id);
    //     }}
    //   />
    // );
    // })}
    // </VStack>
  );
};
