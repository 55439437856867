import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Button, IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { ImageUpload } from '@droplet-tech-code/core-elements/module/ui/Inputs/ImagePicker/ImagePicker';
import { Modal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { Slider } from '@miblanchard/react-native-slider';
import mergeImages from 'merge-images';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Image, TextInput } from 'react-native';

import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { vesselApi } from '~/screens/Vessels/Vessels.api';

import EmptyCanvas from '../../../../assets/empty-2048.png';
import EmptyRectCanvas from '../../../../assets/empty-2048-rect.png';

function base64Resize(
  sourceBase64: string,
  scale: number,
  callBack: (newBase64: string) => void,
) {
  const _scale = scale;
  const img = document.createElement('img');
  img.setAttribute('src', sourceBase64);

  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width * _scale;
    canvas.height = img.height * _scale;

    const ctx = canvas.getContext('2d');
    const maxW = img.width * _scale;
    const maxH = img.height * _scale;

    const iw = img.width;
    const ih = img.height;
    const scl = Math.min(maxW / iw, maxH / ih);
    const iwScaled = iw * scl;
    const ihScaled = ih * scl;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    ctx?.drawImage(img, 0, 0, iwScaled, ihScaled);
    const newBase64 = canvas.toDataURL('image/jpeg', scl);

    callBack(newBase64);
  };
}

export const VesselImage = ({
  vesselId,
  onUpload,
}: {
  vesselId: string;
  onUpload?: () => void;
}) => {
  const { palette } = useAppTheme();
  const [topImage, setTopImage] = useState<string | undefined>();
  const [topImageDimensions, setTopImageDimensions] = useState<
    undefined | { width: number; height: number }
  >();

  const [aiImage, setAiImage] = useState<string | undefined>();
  const { monochrome } = useAppTheme().palette;
  const imagePositionRef = useRef({ x: 0, y: 0 });
  const [generateImage, { isLoading }] = vesselApi.useGenerateImageMutation();
  const [rescale, setScale] = useState(100);
  const [updateVesselImage, { isLoading: isUpdating }] =
    vesselApi.useUpdateVesselImageMutation();

  useEffect(() => {
    if (topImage) {
      Image.getSize(
        topImage,
        (width, height) => {
          setTopImageDimensions({ width, height });
        },
        console.error,
      );
    }
  }, [topImage]);

  const ratio = 0.4;

  const convert = async () => {
    base64Resize(topImage!, rescale / 100, (newB64Rescaled) => {
      mergeImages([
        {
          src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAACAAAAAgAAQMAAACIaUFzAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAIVSURBVHic7cExAQAAAMKg9U/tbQegAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA3gAIeAABeP6z1gAAAABJRU5ErkJggg==',
          opacity: 0,
        },
        {
          src: newB64Rescaled,
          opacity: 1,
          x: imagePositionRef.current.x * (1 / ratio),
          y: imagePositionRef.current.y * (1 / ratio),
        },
      ]).then(async (b64: string) => {
        const formData = new FormData();
        const blob = await fetch(b64).then((r) => r.blob());

        const file = new File([blob], 'filename.png', {
          type: 'image/png',
        });

        formData.append('file', file);

        const response = await generateImage({ formData, vesselId });

        handleResponse({
          response,
          onSuccess: ({ data }) => {
            const aiGenerated = 'data:image/png;base64,' + data;

            setAiImage(aiGenerated);
          },
        });
      });
    });
  };

  return (
    <>
      <ModalScrollView>
        <VStack space="3" p="3">
          <HStack space="3">
            <VStack space="1">
              <Text.Small color="monochrome-mid">Image Scale %</Text.Small>
              <TextInput
                style={{
                  minHeight: 45,
                  width: 75,
                  borderColor: palette.monochrome.extraLight,
                  borderWidth: 1,
                  borderRadius: 12,
                  paddingHorizontal: 6,
                }}
                value={String(rescale)}
                onChangeText={(v) => {
                  const value = Number(v);
                  if (!Number.isNaN(value) && value > 0 && value <= 1000) {
                    setScale(value);
                  }
                }}
              />
            </VStack>
            <VStack space="1" style={{ width: 300 }}>
              <Text.Small style={{ opacity: 0 }} color="monochrome-mid">
                Image Scale %
              </Text.Small>
              <View
                style={{
                  flex: 1,
                  marginLeft: 10,
                  marginRight: 10,
                  alignItems: 'stretch',
                  justifyContent: 'center',
                }}
              >
                <Slider
                  minimumTrackTintColor={palette.primary.mid}
                  thumbTintColor={palette.primary.light}
                  value={rescale}
                  minimumValue={1}
                  maximumValue={1000}
                  onValueChange={(v) => {
                    setScale(Math.floor(v as any as number));
                  }}
                />
              </View>
            </VStack>
            <View flex={1} />
            <ImageUpload
              disabled={isLoading}
              onImageBlob={async (_, imageBase64) => {
                if (isLoading) {
                  return;
                }
                setTopImage(imageBase64);
              }}
            >
              <View
                bg={isLoading ? 'monochrome-base' : 'white'}
                bc={isLoading ? 'monochrome-mid' : 'primary-mid'}
                b={1}
                p="3"
                br={12}
                align="center"
                justify="center"
              >
                <Text.Body1Regular color={isLoading ? 'monochrome-mid' : 'primary-mid'}>
                  Select image
                </Text.Body1Regular>
              </View>
            </ImageUpload>
            <Button
              fitToContent
              // isLoading={isLoading}
              variant="contained"
              color="success"
              disabled={!topImage || isLoading}
              onPress={convert}
            >
              AI Generate
            </Button>
          </HStack>
          <Divider />

          <HStack space="6" justify="center">
            {topImage && topImageDimensions ? (
              <View
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  width: 2048 * ratio,
                  height: 2048 * ratio,
                  // borderRadius: 12,
                  borderWidth: 3,
                  borderColor: 'green',
                }}
              >
                <Image
                  source={{ uri: EmptyRectCanvas }}
                  style={{
                    width: 2048 * ratio,
                    height: 2048 * ratio,
                    // @ts-expect-error
                    pointerEvents: 'none',
                    zIndex: 101,
                  }}
                />
                <TopImage
                  width={topImageDimensions.width * ratio * (rescale / 100)}
                  height={topImageDimensions.height * ratio * (rescale / 100)}
                  src={topImage}
                  imagePositionRef={imagePositionRef}
                />

                <Image
                  source={{ uri: EmptyCanvas }}
                  style={{
                    width: 2048 * ratio,
                    height: 2048 * ratio,
                    backgroundColor: monochrome.extraLight,
                  }}
                />
              </View>
            ) : (
              <ImageUpload
                onImageBlob={async (_, imageBase64) => {
                  setTopImage(imageBase64);
                }}
              >
                <View
                  style={{
                    borderStyle: 'dashed',
                    borderWidth: 1,
                    borderColor: palette.primary.light,
                    borderRadius: 12,
                    width: 2048 * ratio,
                    height: 2048 * ratio,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="plus-square" color="primary-light" />
                </View>
              </ImageUpload>
            )}

            <IconButton icon="refresh" onPress={convert} isLoading={isLoading} />

            {aiImage ? (
              <Image
                source={{ uri: aiImage }}
                style={{
                  width: 2048 * ratio,
                  height: 2048 * ratio,
                }}
              />
            ) : (
              <View
                style={{
                  borderStyle: 'dashed',
                  borderWidth: 1,
                  borderColor: palette.success.light,
                  borderRadius: 12,
                  width: 2048 * ratio,
                  height: 2048 * ratio,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              ></View>
            )}
          </HStack>
        </VStack>
      </ModalScrollView>
      <Modal.Footer divider>
        <HStack flex={1}>
          <View flex={1} />
          <Button
            fitToContent
            disabled={!aiImage}
            isLoading={isUpdating}
            onPress={async () => {
              if (!aiImage) {
                return;
              }

              const formData = new FormData();
              const blob = await fetch(aiImage).then((r) => r.blob());

              const file = new File([blob], 'filename.png', {
                type: 'image/png',
              });

              formData.append('file', file);

              const response = await updateVesselImage({
                vesselId,
                formData,
              });

              handleResponse({
                response,
                onSuccess: () => {
                  onUpload?.();
                },
              });
            }}
          >
            Confirm and save
          </Button>
        </HStack>
      </Modal.Footer>
    </>
  );
};

const TopImage = ({
  src,
  width,
  height,
  imagePositionRef,
}: {
  src: string;
  width: number;
  height: number;
  imagePositionRef: MutableRefObject<{ y: number; x: number }>;
}) => {
  const mouseRef = useRef({
    mouseDown: false,
    mousePosition: undefined as { y: number; x: number } | undefined,
  });

  const [position, setDimensions] = useState<{ top: number; left: number } | undefined>();

  useEffect(() => {
    const mouseUp = () => {
      mouseRef.current.mouseDown = false;
      mouseRef.current.mousePosition = undefined;
    };

    const mouseMove = (ev: any) => {
      if (!mouseRef.current.mouseDown) {
        return;
      }

      if (mouseRef.current.mousePosition) {
        const xDiff = ev.clientX - mouseRef.current.mousePosition.x;
        const yDiff = ev.clientY - mouseRef.current.mousePosition.y;

        mouseRef.current.mousePosition.x = ev.clientX;
        mouseRef.current.mousePosition.y = ev.clientY;

        imagePositionRef.current.x = imagePositionRef.current.x + xDiff;

        imagePositionRef.current.y = imagePositionRef.current.y + yDiff;

        setDimensions({
          top: imagePositionRef.current.y,
          left: imagePositionRef.current.x,
        });
      } else {
        mouseRef.current.mousePosition = {
          x: ev.clientX,
          y: ev.clientY,
        };
      }
    };

    document.addEventListener('mousemove', mouseMove);

    document.addEventListener('mouseup', mouseUp);

    return () => {
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('mousemove', mouseMove);
    };
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: position?.top ?? 0,
        left: position?.left ?? 0,
        width,
        height,
        zIndex: 100,
      }}
      onMouseDown={() => {
        mouseRef.current.mouseDown = true;
      }}
    >
      <Image
        source={{ uri: src }}
        style={{
          width,
          height,
        }}
      />
    </div>
  );
};
