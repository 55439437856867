import { useColorFromTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { useModalVerify } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { BookingClient, SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { SupportStatus } from '@naus-code/naus-client-types';
import React, { useCallback, useState } from 'react';

import { LiveDateText } from '~/components/DateText';
import { PressableOption } from '~/components/SupportConversation/InputPanel/Conversation.Common';
import { AppText } from '~/components/Text';
import { useGetAuthUserRole, useGetAuthUserRules } from '~/screens/Auth/Auth.Context';
import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { useInvalidateClosedSupportRequestList } from '~/screens/Support/Requests/Closed/SupportRequest.List';
import {
  useInvalidateCustomerRequestListByEmail,
  useInvalidateCustomerRequestListById,
} from '~/screens/Support/Requests/Customer/SupportRequest.List';
import { useInvalidatePausedSupportRequestList } from '~/screens/Support/Requests/Pending/SupportRequest.List';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { translate } from '~/utils/translation.utils';

import { RatingInfo } from '../../Ratings/RatingItem';
import { CopyableText } from '../../Text/CopyableText';
import { useAssignPurchase } from '../Modals/AssignPurchase';
import { useAssignUser } from '../Modals/AsssignUser';
import { useCreatePaymentCharge } from '../Modals/CreatePayment';
import { useCreatePaymentRefund } from '../Modals/CreateRefund';
import { useRefundPaymentDataModal } from '../Modals/RefundPaymentData';
import { formatMapToFormData, getColorFromStatus } from './SupportRequestStatus.utils';
import { ClickableListItem } from './utils';

export const SupportRequestDetailsHeader = () => {
  return (
    <>
      <View
        px="2.5"
        py="3"
        bg="monochrome-extraLight"
        justify="center"
        style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
      >
        <AppText.MiscTag type="heading1">
          {translate('supportRequests.details')}
        </AppText.MiscTag>
      </View>
      <Divider bg="monochrome-light" style={{ maxWidth: isWeb ? 400 : undefined }} />
    </>
  );
};

export const SupportRequestDetailsContent = ({
  check,
  supportRequestInfo,
}: {
  check: string;
  supportRequestInfo: SupportRequestBackendClient;
}) => {
  const { open: openAssignPurchase } = useAssignPurchase();
  const supportId = supportRequestInfo._id;
  const invalidateClosed = useInvalidateClosedSupportRequestList();
  const invalidatePaused = useInvalidatePausedSupportRequestList();
  const invalidateById = useInvalidateCustomerRequestListById();
  const invalidateByEmail = useInvalidateCustomerRequestListByEmail();

  const [sendCustomerReminder] =
    supportRequestActionsApi.useSendCustomerReminderMutation();

  const [setSupportRequestStatus] =
    supportRequestActionsApi.useChangeSupportStatusMutation();

  const onSendReminder = useCallback(
    async (method: {
      sendEmail: boolean;
      sendSms: boolean;
      sendNotification: boolean;
    }) => {
      const response = await sendCustomerReminder({
        supportId,
        check,
        notification: method.sendNotification,
        email: method.sendEmail,
        sms: method.sendSms,
      });
      handleResponse({
        response,
        successMessage: translate('supportRequests.reminderSent'),
      });
    },
    [check, supportId],
  );
  const {
    _customerId,
    dateUpdated,
    timestampLastNotified,
    p_email,
    p_customerName,
    status,
    ref,
  } = supportRequestInfo;
  const feedback = supportRequestInfo.feedback;

  const changeStatus = useCallback(
    async (status: SupportStatus) => {
      const response = await setSupportRequestStatus({
        status,
        check,
        supportId,
      });

      handleResponse({
        response,
        onSuccess: async () => {
          await Promise.all([
            invalidateClosed.invalidate(),
            invalidatePaused.invalidate(),
            invalidateById.invalidate(_customerId),
            invalidateByEmail.invalidate(p_email),
          ]).catch(console.error);
        },
      });
    },
    [check, supportId, _customerId, p_email],
  );
  //======================================//

  const colorKeyStatus = getColorFromStatus(status);
  const statusColor = useColorFromTheme(colorKeyStatus);

  return (
    <VStack space="2.5" flex={1} style={{ maxWidth: isWeb ? 400 : undefined }}>
      {!supportRequestInfo._customerIdClient && (
        <View px="3">
          <VStack p="2.5" br={20} bg="warning-extraLight">
            <AppText.MiscTag flex={1} align="center" color="warning-dark">
              {translate('supportRequests.hiddenFromCustomer')}
            </AppText.MiscTag>
          </VStack>
        </View>
      )}
      <VStack px="3" space="1">
        <View px="1">
          <View
            style={{
              width: '100%',
              backgroundColor: statusColor,
              height: 6,
              borderRadius: 6,
              alignSelf: 'center',
            }}
          />
        </View>
        <SelectInputBase
          values={[status]}
          setValues={(nv) => {
            if (nv !== undefined) {
              changeStatus(nv[0] as SupportStatus);
            }
          }}
          iconEnd={
            <HStack space="3">
              <View
                style={{
                  backgroundColor: statusColor,
                  width: 18 * 3,
                  height: 18,
                  borderRadius: 999,
                }}
              />
              <Icon icon="chevron-down" color="black" />
            </HStack>
          }
          options={formatMapToFormData()}
          type="single-select"
          noFeedback
          feedback=""
        />
      </VStack>
      <Divider />
      <HStack px="3">
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {translate('supportRequests.updated')}
        </AppText.ExtraSmall>
        <LiveDateText dateTime={dateUpdated} formatOption="fullDateTime" type="small" />
      </HStack>
      <Divider />
      <HStack px="3">
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {translate('supportRequests.lastReminded')}
        </AppText.ExtraSmall>
        {timestampLastNotified ? (
          <LiveDateText
            dateTime={timestampLastNotified}
            formatOption="fullDateTime"
            type="small"
          />
        ) : null}
      </HStack>
      <Divider />
      <HStack px="3">
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {translate('supportRequests.supportEmail')}
        </AppText.ExtraSmall>
        <CopyableText value={p_email} />
      </HStack>
      <Divider />
      <HStack px="3">
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {translate('supportRequests.customer')}
        </AppText.ExtraSmall>
        <CopyableText value={p_customerName} />
      </HStack>
      <Divider />
      <HStack px="3">
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {translate('customer.supportRef')}
        </AppText.ExtraSmall>
        <CopyableText value={ref} />
      </HStack>
      <Divider />
      {supportRequestInfo.trackId ? (
        <>
          <HStack px="3">
            <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
              {translate('purchases.trackingInfo.trackId')}
            </AppText.ExtraSmall>
            <CopyableText value={supportRequestInfo.trackId} />
          </HStack>
          <Divider />
        </>
      ) : null}
      <PaymentSection supportRequestInfo={supportRequestInfo} check={check} />
      <Divider />
      <RefundSection supportRequestInfo={supportRequestInfo} check={check} />
      <SendReminder
        canNotify={!!supportRequestInfo.pushIds?.length}
        onSendReminder={onSendReminder}
      />
      <Divider />

      <ClickableListItem
        icon="ticket"
        color="primary-mid"
        onPress={() => {
          openAssignPurchase(supportRequestInfo, check);
        }}
      >
        {` ${translate('supportRequests.assignPurchase')}`}
      </ClickableListItem>
      <Divider />
      <AdminUserInfo supportRequestInfo={supportRequestInfo} check={check} />
      <Divider />
      {feedback && (
        <VStack space="2.5">
          <View px="2.5">
            <RatingInfo feedback={feedback} />
          </View>
          <Divider />
        </VStack>
      )}
    </VStack>
  );
};

const AdminUserInfo = ({
  supportRequestInfo,
  check,
}: {
  supportRequestInfo: SupportRequestBackendClient;
  check: string;
}) => {
  const { open: openAssignUser } = useAssignUser();
  const { _adminUserId: adminUserId, _id: supportId, adminUserName } = supportRequestInfo;

  const canAssign = useGetAuthUserRules('assignSupportRequests');

  return (
    <HStack>
      <View px="2.5" flex={1}>
        <Text.Small color={adminUserId ? 'success-light' : 'monochrome'}>
          {adminUserId ? adminUserName : translate('supportRequests.unassigned')}
        </Text.Small>
      </View>
      {canAssign && (
        <ClickableListItem
          icon="support-agent"
          color="primary-mid"
          onPress={() => {
            openAssignUser(check, supportId);
          }}
        >
          {`${translate('supportRequests.assignUser')}`}
        </ClickableListItem>
      )}
    </HStack>
  );
};

const SendReminder = ({
  onSendReminder,
  canNotify,
}: {
  canNotify: boolean;
  onSendReminder: ({
    sendEmail,
    sendSms,
    sendNotification,
  }: {
    sendEmail: boolean;
    sendSms: boolean;
    sendNotification: boolean;
  }) => void;
}) => {
  const [sendSms, setSendSms] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  return (
    <HStack space="2.5" pl="3">
      <HStack>
        <PressableOption
          disabled={!canNotify}
          state={sendNotification}
          text={translate('supportRequests.notification')}
          onPress={() => setSendNotification(!sendNotification)}
        />
        <PressableOption
          state={sendEmail}
          text={translate('supportRequests.email')}
          onPress={() => setSendEmail(!sendEmail)}
        />
        <PressableOption
          state={sendSms}
          text={translate('supportRequests.sms')}
          onPress={() => setSendSms(!sendSms)}
        />
      </HStack>
      <View flex={1} />
      <ClickableListItem
        icon="bell"
        color="primary-mid"
        onPress={() => onSendReminder({ sendEmail, sendSms, sendNotification })}
      >
        {` ${translate('supportRequests.sendReminder')}`}
      </ClickableListItem>
    </HStack>
  );
};

const RefundSection = ({
  supportRequestInfo,
  check,
}: {
  supportRequestInfo: SupportRequestBackendClient;
  check: string;
}) => {
  const canApprove = useGetAuthUserRole() === 'SYSTEM';
  const canCreatePendingRefund = useGetAuthUserRules('canCreatePendingRefund');

  const { open: openRefundDetails } = useRefundPaymentDataModal();

  if (!canCreatePendingRefund) {
    return null;
  }

  const supportId = supportRequestInfo._id;
  const { openModal: openCreateRefund } = useCreatePaymentRefund();
  const { verifyAction } = useModalVerify();
  const { data } = bookingApi.useGetBookingQuery(supportRequestInfo.bookingId || '', {
    skip: !supportRequestInfo.bookingId,
  });

  const booking = data?.booking;

  const [cancelPendingRefund, { isLoading: isCancellingRefund }] =
    supportRequestActionsApi.useCancelPendingRefundMutation();
  const [approvePendingRefund, { isLoading: isApprovingRefund }] =
    supportRequestActionsApi.useApprovePendingRefundMutation();
  //========= Callbacks ============//
  const openCancelPendingRefund = useCallback(async () => {
    const verify = await verifyAction({
      title: translate('supportRequests.cancelPendingRefund'),
    });
    if (verify) {
      cancelPendingRefund({ supportId, check });
    }
  }, [check, supportId]);

  const openApprovePendingPayment = useCallback(async () => {
    const verify = await verifyAction({
      title: translate('supportRequests.approveRefund'),
    });
    if (verify) {
      approvePendingRefund({ supportId, check });
    }
  }, [check, supportId]);

  if (supportRequestInfo.pendingRefund) {
    const pendingRefund = supportRequestInfo.pendingRefund;
    const currency = getCurrencyFromPaymentSession(
      pendingRefund.paymentSessionId,
      booking,
    );

    return (
      <VStack space="2.5">
        <VStack br={15} bg={'primary-extraLight'} p="2.5">
          <HStack m="1" p="2.5" space="2">
            <AppText.ExtraSmall
              color="monochrome-extraDark"
              flex={1}
              onPress={() => openRefundDetails(pendingRefund, currency)}
            >
              {translate('supportRequests.pendingRefund')}
            </AppText.ExtraSmall>
            <AppText.Small>
              {getPriceWithSymbol(pendingRefund.value, currency || '')}
            </AppText.Small>
          </HStack>
          <HStack>
            {canApprove && (
              <ClickableListItem
                isLoading={isApprovingRefund}
                disable={isApprovingRefund || isCancellingRefund}
                color="primary-mid"
                onPress={openApprovePendingPayment}
              >
                {`${translate('supportRequests.approve')}`}
              </ClickableListItem>
            )}
            <View flex={1} />
            <ClickableListItem
              color="primary-mid"
              isLoading={isCancellingRefund}
              disable={isApprovingRefund || isCancellingRefund}
              onPress={openCancelPendingRefund}
            >
              {`${translate('supportRequests.cancelRefund')}`}
            </ClickableListItem>
          </HStack>
        </VStack>
        <Divider />
      </VStack>
    );
  }

  return (
    <VStack space="2.5">
      <ClickableListItem
        icon="custom-refundable"
        color="primary-mid"
        onPress={() => {
          openCreateRefund({
            supportRequestInfo,
            check,
          });
        }}
      >
        {` ${translate('supportRequests.createRefund')}`}
      </ClickableListItem>
      <Divider />
    </VStack>
  );
};

const PaymentSection = ({
  supportRequestInfo,
  check,
}: {
  supportRequestInfo: SupportRequestBackendClient;
  check: string;
}) => {
  const supportId = supportRequestInfo._id;

  const [cancelPendingPayment] =
    supportRequestActionsApi.useCancelPendingPaymentMutation();

  const { verifyAction: verifyCancelPayment } = useModalVerify();

  const { openModal: openCreatePayment } = useCreatePaymentCharge();
  //========= Callbacks ============//
  const openCancelPendingPayment = useCallback(async () => {
    await verifyCancelPayment({
      title: translate('supportRequests.cancelPendingPayment'),
    }).then((verify) => {
      if (verify) {
        cancelPendingPayment({ supportId, check });
      }
    });
  }, [check, supportId]);

  if (supportRequestInfo.pendingPayment) {
    const { pendingPayment } = supportRequestInfo;
    return (
      <VStack br={15} bg={'warning-extraLight'} p="2.5">
        <HStack m="1" space="2">
          <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
            {translate('supportRequests.pendingPayment')}
          </AppText.ExtraSmall>
          <AppText.Small>
            {getPriceWithSymbol(pendingPayment.value, pendingPayment.currency)}
          </AppText.Small>
          <AppText.Small>
            {translate('supportRequests.service', {
              value: getPriceWithSymbol(
                pendingPayment.serviceFee,
                pendingPayment.currency,
              ),
            })}
          </AppText.Small>
        </HStack>
        <ClickableListItem
          icon="euro"
          color="primary-mid"
          onPress={async () => await openCancelPendingPayment()}
        >
          {` ${translate('supportRequests.cancelPendingPayment')}`}
        </ClickableListItem>
      </VStack>
    );
  }

  return (
    <ClickableListItem
      icon="euro"
      color="primary-mid"
      onPress={() => {
        openCreatePayment({
          supportRequestInfo,
          check,
        });
      }}
    >
      {` ${translate('supportRequests.createPayment')}`}
    </ClickableListItem>
  );
};

const getCurrencyFromPaymentSession = (
  paymentSessionId: string,
  booking?: BookingClient,
) => {
  if (booking) {
    const paymentSession = booking.paymentSessions.find(
      (paymentSession) => paymentSession.id === paymentSessionId,
    );
    if (paymentSession) {
      return paymentSession.paymentSummary.currency;
    }
  }
  return '';
};
