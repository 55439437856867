import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropPortLocations } from '~/navigation/Navigation.types';

import { PortLocationItem } from './PortLocation.Item';
import { PortLocationList, PortLocationListNative } from './PortLocation.List';

export const PortLocationsScreen = (props: ScreenPropPortLocations) => {
  return (
    <PageContainer>
      <PortLocationLayout {...props} />
    </PageContainer>
  );
};

export const PortLocationLayout = (props: ScreenPropPortLocations) => {
  const portLocationId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(portLocationId);
  }, [portLocationId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <PortLocationList onPress={(item) => setId(item.id)} />
      ) : (
        <PortLocationListNative />
      )}
      {id ? <PortLocationItem id={id} onClose={() => setId(undefined)} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
