import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import {
  Modal,
  useModal,
  useModalVerify,
} from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  PurchaseClientCancelCustomerOptions,
  PurchaseClientCancelProviderOptionsExternal,
} from '@naus-code/naus-admin-types';
import { MutableRefObject, useState } from 'react';

import {
  CancellationMethod,
  CancelPurchaseFooterPropsNative,
  cancelPurchaseManager,
  CancelPurchaseProps,
  ModalActionPanelCancelKey,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.utils';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { translate } from '~/utils/translation.utils';

import { getPriceWithSymbol } from '../../../purchase.utils';

const getCancelHook = (method: CancellationMethod | undefined) => {
  switch (method) {
    case 'customer': {
      return purchaseActionApi.useCancelPurchaseCustomerMutation;
    }
    case 'provider': {
      return purchaseActionApi.useCancelPurchaseProviderMutation;
    }
    case 'visual': {
      return purchaseActionApi.useCancelPurchaseVisualMutation;
    }
    case 'customer_and_provider':
    default: {
      return purchaseActionApi.useCancelPurchaseProviderAndCustomerMutation;
    }
  }
};

export const CancelFooterNative = (
  props: CancelPurchaseFooterPropsNative & {
    formHandlerRef: MutableRefObject<FormHandler>;
  },
) => {
  const [silentOn, setSilentOn] = useState(false);
  const { closeModal } = useModal();
  const [cancelMode] = cancelPurchaseManager.state.useGlobalState('type');
  const [selectedTickets] = cancelPurchaseManager.state.useGlobalState('selectedTickets');
  const [doCancel, { isLoading }] = getCancelHook(cancelMode)();

  return (
    <Modal.Footer
      divider
      isLoading={isLoading}
      confirm={translate('purchases.purchaseTool.processCancellation')}
      confirmProps={{
        disabled: !cancelMode || !selectedTickets.length || props.currentPage != 1,
      }}
      onConfirm={async () => {
        if (!cancelMode) {
          return;
        }

        const { value, valid } = props.formHandlerRef.current.getValue<
          PurchaseClientCancelCustomerOptions &
            PurchaseClientCancelProviderOptionsExternal
        >();

        if (!valid) {
          return;
        }

        const response = await doCancel({
          customerOptions: {
            refundMethod: value.refundMethod,
            serviceFeeInBookCcy: value.serviceFeeInBookCcy,
            customerRefundInBookCcy: value.customerRefundInBookCcy,
            expectedRefundFromCompany: value.expectedRefundFromCompany,
          },
          providerOptions: {
            actualRefundFromCompany: value.actualRefundFromCompany,
            expectedRefundFromCompany: value.expectedRefundFromCompany,
            type: value.type,
            cancelDate: value.cancelDate,
          },
          cancelOptions: {
            purchaseId: props.purchaseId,
            ticketIds: selectedTickets,
            voidCategory: props.purchase.void_category ?? 'GENERAL',
            silentUpdate: silentOn,
          },
          supportId: props.supportRequest._id,
        });

        handleResponse({
          response,
          onSuccess: () => {
            closeModal(ModalActionPanelCancelKey);
          },
        });
      }}
    >
      <VStack space="1">
        <HStack space="1">
          <Toggle color="primary-mid" value={silentOn} onChange={(v) => setSilentOn(v)} />
          <Text.Body2Regular color="monochrome-extraDark">
            {translate('purchases.purchaseTool.purchaseCompare.silentUpdate')}
          </Text.Body2Regular>
        </HStack>
        {props.currentPage === 0 && (
          <Button
            onPress={() => {
              props.setCurrentPage(props.currentPage + 1);
            }}
          >
            <Text.Small color="white">{translate('common.next')}</Text.Small>
          </Button>
        )}
        {props.currentPage === 1 && (
          <Button
            onPress={() => {
              props.setCurrentPage(props.currentPage - 1);
            }}
          >
            <Text.Small color="white">{translate('common.back')}</Text.Small>
          </Button>
        )}
      </VStack>
    </Modal.Footer>
  );
};

export const CancelFooter = (
  props: CancelPurchaseProps & {
    formHandlerRef: MutableRefObject<FormHandler>;
  },
) => {
  const [silentOn, setSilentOn] = useState(false);
  const { closeModal } = useModal();
  const [cancelMode] = cancelPurchaseManager.state.useGlobalState('type');
  const [selectedTickets] = cancelPurchaseManager.state.useGlobalState('selectedTickets');
  const [doCancel, { isLoading }] = getCancelHook(cancelMode)();
  const { verifyAction } = useModalVerify();

  return (
    <Modal.Footer
      divider
      isLoading={isLoading}
      confirm={translate('purchases.purchaseTool.processCancellation')}
      confirmProps={{
        disabled: !cancelMode || !selectedTickets.length,
      }}
      onConfirm={async () => {
        if (!cancelMode) {
          return;
        }

        const { value, valid } = props.formHandlerRef.current.getValue<
          PurchaseClientCancelCustomerOptions &
            PurchaseClientCancelProviderOptionsExternal
        >();

        if (!valid) {
          return;
        }

        verifyAction({
          title: translate('supportRequests.verifyServiceFee'),
          description:
            translate('supportRequests.serviceFeeMakeSure') +
            '\n\nService fee: ' +
            getPriceWithSymbol(value.serviceFeeInBookCcy, props.purchase.ccySymbol),
        }).then(async (verify) => {
          if (!verify) {
            return;
          }
          const response = await doCancel({
            customerOptions: {
              refundMethod: value.refundMethod,
              serviceFeeInBookCcy: value.serviceFeeInBookCcy,
              customerRefundInBookCcy: value.customerRefundInBookCcy,
              expectedRefundFromCompany: value.expectedRefundFromCompany,
            },
            providerOptions: {
              actualRefundFromCompany: value.actualRefundFromCompany,
              expectedRefundFromCompany: value.expectedRefundFromCompany,
              type: value.type,
              cancelDate: value.cancelDate,
            },
            cancelOptions: {
              purchaseId: props.purchaseId,
              ticketIds: selectedTickets,
              voidCategory: props.purchase.void_category ?? 'GENERAL',
              silentUpdate: silentOn,
            },
            supportId: props.supportRequest._id,
          });

          handleResponse({
            response,
            onSuccess: () => {
              closeModal(ModalActionPanelCancelKey);
            },
          });
        });
      }}
    >
      <HStack space="1">
        <Toggle color="primary-mid" value={silentOn} onChange={(v) => setSilentOn(v)} />
        <Text.Body2Regular color="monochrome-extraDark">
          {translate('purchases.purchaseTool.purchaseCompare.silentUpdate')}
        </Text.Body2Regular>
      </HStack>
    </Modal.Footer>
  );
};
