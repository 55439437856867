import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  CreateLocationRequest,
  GetLocationResponse,
  LocationListResponse,
} from '@naus-code/naus-admin-types';
import { ImageUploadResponse } from '@naus-code/naus-client-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'data/port-location';
export const portLocationApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPortLocation: builder.query<GetLocationResponse, string>({
      query: (id) => ({
        url: `${base_url}/get-location/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPortLocation'],
    }),

    getPortLocationFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/get-location/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPortLocationFormConfig'],
    }),

    getPortLocationList: builder.query<LocationListResponse, void>({
      query: () => ({
        url: `${base_url}/get-list/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPortLocationList'],
    }),

    getCreatePortLocationFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-location/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCreatePortLocationFormConfig'],
    }),

    createPortLocation: builder.mutation<GetLocationResponse, CreateLocationRequest>({
      query: (data) => ({
        url: `${base_url}/create-location/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createPortLocation', 'getPortLocationList'],
    }),
    createLocationImageUpload: builder.mutation<ImageUploadResponse, FormData>({
      query: (data: FormData) => ({
        url: `${base_url}/img-upload/create-location/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'createPortLocation',
        'getCreatePortLocationFormConfig',
        'createLocationImageUpload',
      ],
    }),
  }),
});
