import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { useEffect, useState } from 'react';

import { useGetSelectedTrip } from '~/components/BasketManager/BasketManager.hooks';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { useAppSelector } from '~/store/redux.utils';
import { translate } from '~/utils/translation.utils';

import { AppText } from '../Text';

export const BasketPricing = () => {
  const footerButton = useAppSelector((s) => s.basket.selectTickets.footerButton);

  return (
    <VStack space="2" p="3" br={12} b={1} bc="monochrome-extraLight">
      <BasketTotalBase />

      {footerButton.error ? (
        <Text.Body2Medium align="center" color="error-mid">
          {footerButton.error}
        </Text.Body2Medium>
      ) : null}
    </VStack>
  );
};

export const BasketTotalBase = () => {
  const footerButton = useAppSelector((s) => s.basket.selectTickets.footerButton);
  const ccySymbol = useAppSelector((s) => s.basket.selectTickets.options.ccySymbol);
  const [_mounted, setMounted] = useState(false);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setMounted(true);
    }, 2500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (footerButton.status !== 'valid') {
    return null;
  }
  return (
    <VStack>
      <AppText.ExtraSmall color="monochrome">
        {translate('basketManager.fareDifference')}
      </AppText.ExtraSmall>
      <Text.Body1Regular>
        {getPriceWithSymbol(footerButton.value, ccySymbol)}
      </Text.Body1Regular>
    </VStack>
  );
};

export const BasketLegPricing = ({
  tripKey,
  modify,
  value,
}: {
  tripKey: string;
  modify?: boolean;
  value: string;
}) => {
  const trip = useGetSelectedTrip(tripKey);
  const footerButton = useAppSelector((s) => s.basket.selectTickets.footerButton);
  if (!trip) {
    return;
  }

  return (
    <VStack p="3" space="2">
      <HStack space="3">
        <Text.Body2Regular flex={1} color="monochrome-dark">
          {modify
            ? translate('basketManager.fareDifference')
            : translate('basketManager.legPricing')}
        </Text.Body2Regular>
        {footerButton.status === 'loading' ? (
          <LoadingIndicator />
        ) : (
          <Text.Body2Medium align="right" color="monochrome-extraDark">
            {value}
          </Text.Body2Medium>
        )}
      </HStack>

      {trip.error ? (
        <Text.Body2Medium align="center" color="error-mid">
          {trip.error}
        </Text.Body2Medium>
      ) : null}
    </VStack>
  );
};
