import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { PurchaseListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef, useState } from 'react';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import {
  PaginatedFlatList,
  paginationUtil,
} from '~/components/FlatList/FlatList.Paginated';
import { useChangeParam } from '~/components/Modals/ChangeParamModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { PurchaseListItemComponent } from '../Purchase.ListItem';
import { purchaseApi } from '../Purchases.api';
import { PurchasesListHeaderEmail } from '../PurchasesByEmail/PurchasesByEmail.ListHeader';
import { PurchasesListHeaderCustomerId } from './PurchasesByCustomerId.ListHeader';

export interface PurchaseListProps {
  onPress: (purchase: PurchaseListItemResponse) => void;
  email?: string;
  customerId?: string;
}

export const PurchaseListNative = ({
  email,
  customerId,
}: {
  email?: string;
  customerId?: string;
}) => {
  const navigate = useAppNavigation();
  return (
    <PurchaseList
      email={email}
      customerId={customerId}
      onPress={(item) => {
        navigate.navigate('Purchase-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const PurchaseList = (props: PurchaseListProps) => {
  const selectedRef = useRef('');
  const { spacing } = useAppTheme();
  const [email, setEmail] = useState(props.email);
  const [customerId, setCustomerId] = useState(props.customerId);

  const [invalidateByEmail, { isLoading: isLoadingEmail }] =
    purchaseApi.useInvalidatePurchaseListByEmailMutation();
  const [invalidateById, { isLoading: isLoadingId }] =
    purchaseApi.useInvalidatePurchaseListByCustomerIdMutation();

  const renderItem = useCallback(
    (purchase: PurchaseListItemResponse) => {
      return (
        <PurchaseListItemComponent
          key={purchase.id}
          purchase={purchase}
          onPress={() => {
            selectedRef.current = purchase.id;
            props.onPress(purchase);
          }}
        />
      );
    },
    [email, customerId],
  );

  const { open: openEditParam } = useChangeParam();

  const onEditEmail = (param: string) => {
    setEmail(param);
  };
  const onEditCustomerId = (param: string) => {
    setCustomerId(param);
  };
  const ItemSeparatorComponent = useItemSeparatorComponent();

  if (!email && !customerId) {
    return null;
  }
  const moreReqOptions = customerId ? { customerId } : { email };

  return (
    <VStack flex={1}>
      {customerId ? (
        <PurchasesListHeaderCustomerId
          customerId={customerId}
          onReset={() => {
            openEditParam({
              initialValue: customerId,
              title: translate('purchases.purchasesByCustomerId'),
              subtitle: translate('purchases.enterCustomerId'),
              label: translate('purchases.customerId'),
              onConfirm: onEditCustomerId,
            });
          }}
          onPress={() => {
            const options = paginationUtil.lastReq['PurchasesByEmail'];
            if (!options) {
              return;
            }
            invalidateById({
              ...options,
              paginationRequest: {
                ...options.paginationRequest,
                refreshList: true,
              },
            });
          }}
        />
      ) : (
        email && (
          <PurchasesListHeaderEmail
            email={email}
            onReset={() => {
              openEditParam({
                initialValue: email,
                title: translate('purchases.purchasesByEmail'),
                subtitle: translate('purchases.enterEmail'),
                label: translate('purchases.email'),
                type: 'email',
                onConfirm: onEditEmail,
              });
            }}
            onPress={() => {
              const options = paginationUtil.lastReq['PurchasesByEmail'];
              if (!options) {
                return;
              }
              invalidateByEmail({
                ...options,
                paginationRequest: {
                  ...options.paginationRequest,
                  refreshList: true,
                },
              });
            }}
          />
        )
      )}
      <PaginatedFlatList
        listId="PurchasesByEmail"
        feedback={translate('purchases.emptyPurchases')}
        hook={
          customerId
            ? purchaseApi.useGetPurchaseListByCustomerQuery
            : purchaseApi.useGetPurchaseListByEmailQuery
        }
        isLoading={isLoadingEmail || isLoadingId}
        invalidate={(options) => {
          if (customerId) {
            invalidateById(options as any);
          } else {
            invalidateByEmail(options as any);
          }
        }}
        moreReqOptions={moreReqOptions}
        pageSize={20}
        simple
        getId={(item) => item.id}
        showsVerticalScrollIndicator={isWeb}
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};

export const useInvalidateCustomerPurchaseListId = () => {
  const [invalidateCustomerPurchaseListId, { isLoading }] =
    purchaseApi.useInvalidatePurchaseListByCustomerIdMutation();

  return {
    invalidate: async (customerId: string) => {
      const lastItemId = paginationUtil.lists.PurchasesByEmail;
      if (lastItemId) {
        await invalidateCustomerPurchaseListId({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
          customerId,
        });
      }
    },
    isLoading,
  };
};

export const useInvalidateCustomerPurchaseListByEmail = () => {
  const [invalidateCustomerPurchaseListEmail, { isLoading }] =
    purchaseApi.useInvalidatePurchaseListByEmailMutation();

  return {
    invalidate: async (email: string) => {
      const lastItemId = paginationUtil.lists.PurchasesByEmail;
      if (lastItemId) {
        await invalidateCustomerPurchaseListEmail({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
          email,
        });
      }
    },
    isLoading,
  };
};
