import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { PortLocationDataCache } from '@naus-code/naus-client-types';
import { ConfigDetectorDataItem } from '@naus-code/naus-server-common-types';
import { useRef } from 'react';

import { companyDataConfigApi } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/companyDataConfigsApi';
import { useMissingLocationStore } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Locations/MissingLocationConfigs.store';

export type MissingLocationItem = ConfigDetectorDataItem<PortLocationDataCache>;
const ModalKey = 'useMissingLocationItemModal';

const MissingLocationItem = ({ item }: { item: MissingLocationItem }) => {
  const { closeModal } = useModal();
  const { data, isLoading } = companyDataConfigApi.useGetMissingLocationsQuery();
  const editTicket = useMissingLocationStore((s) => s.editTicket);
  const formRef = useRef(new FormHandler());
  return (
    <Modal.Container>
      <Modal.Header title={item.key} onPress={() => closeModal(ModalKey)} />
      <Modal.Body flex={1}>
        <DelayRender isLoading={isLoading}>
          {data ? (
            <FormBuilder form={formRef.current} data={data.config} initialValue={item} />
          ) : null}
        </DelayRender>
      </Modal.Body>
      <Modal.Footer
        confirm="Save & close"
        divider
        confirmProps={{
          disabled: isLoading || !data,
        }}
        onConfirm={() => {
          const { valid, value } = formRef.current.getValue<MissingLocationItem>();
          if (valid && data) {
            editTicket(value);
            closeModal(ModalKey);
          }
        }}
        onCancel={() => closeModal(ModalKey)}
      />
    </Modal.Container>
  );
};

export const useMissingLocationItemModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (item: MissingLocationItem) => {
      openModal(<MissingLocationItem item={item} />, { key: ModalKey });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
