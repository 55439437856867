import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { FerryCompanyOperationsConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { translate } from '~/utils/translation.utils';

import { ModificationsInfo } from './ModificationInfo';
import { ModificationPolicies } from './ModificationPolicies';

export const Modifications = ({
  allOperations,
}: {
  allOperations: FerryCompanyOperationsConfig;
}) => {
  const { modifyConfig, defaultModifyPolicies, specialModifyPolicies } = allOperations;
  return (
    <CardWithHeader headerText={translate('companyConfig.modifications')}>
      <VStack p="2.5">
        <ModificationsInfo modifyConfig={modifyConfig} />
        <Divider bg="primary-base" thickness={15} br={10} />
        <ModificationPolicies
          defaultPolicies={defaultModifyPolicies}
          specialPolicies={specialModifyPolicies}
        />
      </VStack>
    </CardWithHeader>
  );
};
