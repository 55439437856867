import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { onlyUnique } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useMemo, useRef } from 'react';
import { ScrollView } from 'react-native';

import { AnalysisOverview } from '~/screens/Finance/Transactions/Analysis/Analysis.Overview';
import { InputContainer } from '~/screens/Finance/Transactions/Transactions.Common';
import { useTransactionStore } from '~/screens/Finance/Transactions/Transactions.store';
import { useTransactionPeriodData } from '~/screens/Finance/Transactions/Transactions.utils';
import {
  TRANSACTION_CLIENT_DATE_FORMAT,
  TRANSACTION_SERVER_DATE_FORMAT,
} from '~/utils/date.utils';
import { translate } from '~/utils/translation.utils';

export const TransactionHeader = () => {
  return (
    <HStack align="flex-start" space="3" p="3" style={{ height: 350 }}>
      <VStack space="1.5" align="stretch">
        <DateRange />
        <View flex={1} />
        <CurrentlySelectedButton />
      </VStack>
      <OperatorCodes />
      <CreditCode />
      <View pl="10" />
      <AnalysisOverview />
      <View flex={999} />
    </HStack>
  );
};

const CurrentlySelectedButton = () => {
  const bookingId = useTransactionStore((s) => s.selectedTransaction?.bookingId);
  const setState = useTransactionStore((s) => s.setState);

  if (!bookingId) {
    return null;
  }
  return (
    <Button
      variant="outline"
      iconEnd="close"
      color="secondary"
      onPress={() => {
        setState({ selectedTransaction: undefined });
      }}
    >
      {translate('transactions.bookingFilter')}
    </Button>
  );
};

const DateRange = () => {
  const formRef = useRef(new FormHandler());
  const rangeRef = useRef({ from: '', to: '' });
  const dateRange = useTransactionStore((s) => s.range);
  const searchRange = useTransactionStore((s) => s.searchRange);
  const { refetch, isLoading } = useTransactionPeriodData();

  return (
    <InputContainer>
      <VStack space="3">
        <FormBuilder
          contentContainerStyle={{ paddingHorizontal: 0 }}
          form={formRef.current}
          data={[
            {
              items: [
                {
                  type: 'date',
                  label: 'From',
                  format: TRANSACTION_CLIENT_DATE_FORMAT,
                  id: 'from',
                  initialValue: dayjs(dateRange.from, TRANSACTION_SERVER_DATE_FORMAT)
                    .startOf('day')
                    .format(TRANSACTION_CLIENT_DATE_FORMAT),
                  feedbackOnlyWithError: true,
                },
              ],
            },
            {
              items: [
                {
                  type: 'date',
                  label: 'To',
                  format: TRANSACTION_CLIENT_DATE_FORMAT,
                  id: 'to',
                  initialValue: dayjs(dateRange.to, TRANSACTION_SERVER_DATE_FORMAT)
                    .startOf('day')
                    .format(TRANSACTION_CLIENT_DATE_FORMAT),
                  feedbackOnlyWithError: true,
                },
              ],
            },
          ]}
        />
        <Button
          isLoading={isLoading}
          onPress={() => {
            const { valid, value } = formRef.current.getValue<{
              from: string;
              to: string;
            }>();
            if (valid) {
              const from = dayjs(value.from, TRANSACTION_CLIENT_DATE_FORMAT).format(
                TRANSACTION_SERVER_DATE_FORMAT,
              );
              const to = dayjs(value.to, TRANSACTION_CLIENT_DATE_FORMAT).format(
                TRANSACTION_SERVER_DATE_FORMAT,
              );

              if (rangeRef.current.from === from && rangeRef.current.to === to) {
                refetch();
                return;
              }

              rangeRef.current.from = from;
              rangeRef.current.to = to;
              searchRange({
                from,
                to,
              });
            }
          }}
        >
          {translate('transactions.search')}
        </Button>
      </VStack>
    </InputContainer>
  );
};

const OperatorCodes = () => {
  const { data, isLoading } = useTransactionPeriodData();
  const filterOperatorIds = useTransactionStore((s) => s.filterOperatorIds);
  const selectedOperatorIds = useTransactionStore((s) => s.operatorIds);

  const disabled =
    Object.values(selectedOperatorIds).filter((item) => !!item).length === 0;

  const companyIds = useMemo(() => {
    if (!data) {
      return;
    }

    return data.list
      .filter((item) => !!item.companyId)
      .map((item) => item.companyId!)
      .filter(onlyUnique)
      .sort();
  }, [data?.list]);

  return (
    <InputContainer style={{ alignSelf: 'stretch' }}>
      <VStack space="3" style={{ width: 150 }} flex={1}>
        <Text.Small color="monochrome-mid">
          {translate('transactions.operator')}
        </Text.Small>
        <DelayRender isLoading={isLoading}>
          {companyIds ? (
            <ScrollView style={{ flex: 1 }}>
              {companyIds.map((companyId) => {
                const currentSelected = selectedOperatorIds[companyId];

                return (
                  <HStack space="1.5" key={companyId}>
                    <CheckBox
                      checked={currentSelected}
                      onPress={() => {
                        filterOperatorIds({
                          ...selectedOperatorIds,
                          [companyId]: !currentSelected,
                        });
                      }}
                    />
                    <Text.Body2Regular>{companyId}</Text.Body2Regular>
                  </HStack>
                );
              })}
            </ScrollView>
          ) : null}
        </DelayRender>
        <Button
          disabled={!companyIds || disabled}
          variant="text"
          color="primary"
          iconEnd="close"
          onPress={() => {
            filterOperatorIds({});
          }}
        >
          {translate('transactions.clear')}
        </Button>
      </VStack>
    </InputContainer>
  );
};

const CreditCode = () => {
  const { data, isLoading } = useTransactionPeriodData();
  const filterCreditCodes = useTransactionStore((s) => s.filterCreditCodes);
  const selectedCreditCodes = useTransactionStore((s) => s.creditCodes);
  const disabled =
    Object.values(selectedCreditCodes).filter((item) => !!item).length === 0;
  const creditCodes = useMemo(() => {
    if (!data) {
      return;
    }
    return data.list
      .filter((item) => !!item.creditCode)
      .map((item) => item.creditCode!)
      .filter(onlyUnique)
      .sort();
  }, [data?.list]);

  return (
    <InputContainer style={{ alignSelf: 'stretch' }}>
      <VStack space="3" style={{ width: 150 }} flex={1}>
        <Text.Small color="monochrome-mid">
          {translate('transactions.creditCode')}
        </Text.Small>
        <DelayRender isLoading={isLoading}>
          {creditCodes ? (
            <ScrollView style={{ flex: 1 }}>
              {creditCodes.map((creditCode) => {
                const currentSelected = selectedCreditCodes[creditCode];
                return (
                  <HStack space="1.5" key={creditCode}>
                    <CheckBox
                      checked={currentSelected}
                      onPress={() => {
                        filterCreditCodes({
                          ...selectedCreditCodes,
                          [creditCode]: !currentSelected,
                        });
                      }}
                    />
                    <Text.Body2Regular>{creditCode}</Text.Body2Regular>
                  </HStack>
                );
              })}
            </ScrollView>
          ) : null}
        </DelayRender>

        <Button
          disabled={!creditCodes || disabled}
          variant="text"
          color="primary"
          iconEnd="close"
          onPress={() => {
            filterCreditCodes({});
          }}
          style={{ padding: 0 }}
        >
          {translate('transactions.clear')}
        </Button>
      </VStack>
    </InputContainer>
  );
};
