import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { PurchaseListByDateRequest } from '@naus-code/naus-admin-types';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const PurchasesByDateListHeader = ({
  dateRange,
  numberOfResults,
  onReset,
  onPress,
}: {
  dateRange: PurchaseListByDateRequest;
  numberOfResults: string;
  onReset: () => void;
  onPress?: () => void;
}) => {
  return (
    <View p="2.5">
      <PageHeader
        title={`${translate('purchases.purchasesByDate')}`}
        withBack={isNativeMobile}
        iconRight={{
          color: 'monochrome',
          icon: 'refresh',
          onPress,
        }}
        childRight={<IconButton icon="reset" color="monochrome" onPress={onReset} />}
        Subtitle={<Subtitle dateRange={dateRange} numberOfResults={numberOfResults} />}
      />
    </View>
  );
};

const Subtitle = ({
  dateRange,
  numberOfResults,
}: {
  dateRange: PurchaseListByDateRequest;
  numberOfResults: string;
}) => {
  const formattedDateStart = dayjs(dateRange.startDate).format('ddd-DD-MMM-YYYY');
  const formattedDateEnd = dayjs(dateRange.endDate).format('ddd-DD-MMM-YYYY');
  return (
    <VStack>
      <HStack>
        <Text.Small style={{ marginTop: 5 }} numberOfLines={1}>
          {formattedDateStart}
        </Text.Small>
        <Text.Small color="monochrome-mid">{'-'}</Text.Small>
        <Text.Small style={{ marginTop: 5 }} numberOfLines={1}>
          {formattedDateEnd}
        </Text.Small>
      </HStack>
      <HStack>
        {dateRange.companyId && (
          <Text.Small style={{ marginTop: 5 }} numberOfLines={1} color="monochrome-dark">
            {`${translate('purchases.companyId')}: ${dateRange.companyId}, `}
          </Text.Small>
        )}
        {dateRange.vesselId && (
          <Text.Small style={{ marginTop: 5 }} numberOfLines={1} color="monochrome-dark">
            {`${translate('purchases.vesselId')}: ${dateRange.vesselId}`}
          </Text.Small>
        )}
      </HStack>
      <Text.Small color="monochrome-mid">
        {`${numberOfResults} ${translate('purchases.purchases')}`}
      </Text.Small>
    </VStack>
  );
};
