import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import {
  supportRequestActionsApi,
  SupportRequestCreatePaymentRefundRequestForm,
} from '~/screens/Support/Requests/supportRequestActions.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const CreatePaymentRefundKey = 'AddModal-CreateRefund';
export const CreatePaymentRefundModal = ({
  supportRequestInfo,
  check,
}: {
  supportRequestInfo: SupportRequestBackendClient;
  check: string;
}) => {
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();
  const supportId = supportRequestInfo._id;

  const [createPendingPaymentRefund, { isLoading: isCreatingPaymentRefund }] =
    supportRequestActionsApi.useCreatePendingPaymentRefundMutation();

  const { data: paymentCreateConfig } =
    supportRequestActionsApi.useCreatePaymentRefundFormConfigQuery(supportId);

  if (paymentCreateConfig === undefined) {
    return null;
  }

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.createRefund')}
        subtitle={translate('supportRequests.enterRefundDetails')}
        divider
        onPress={() => {
          closeModal(CreatePaymentRefundKey);
        }}
      />
      <Modal.Body p="0">
        <CreateRefundForm supportId={supportId} form={form.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingPaymentRefund}
        onConfirm={async () => {
          const { valid, value } =
            form.current.getValue<SupportRequestCreatePaymentRefundRequestForm>();

          if (valid) {
            const response = await createPendingPaymentRefund({
              ...value,
              supportId,
              check,
              paymentSessionDetails: {
                paymentSessionId: value.paymentSessionId,
                value: (value.value ?? 0) * 100,
              },
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(CreatePaymentRefundKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(CreatePaymentRefundKey);
        }}
      />
    </Modal.Container>
  );
};

export const useCreatePaymentRefund = () => {
  const { openModal } = useModal();

  return {
    openModal: ({
      supportRequestInfo,
      check,
    }: {
      supportRequestInfo: SupportRequestBackendClient;
      check: string;
    }) => {
      openModal(
        <CreatePaymentRefundModal
          supportRequestInfo={supportRequestInfo}
          check={check}
        />,
        {
          key: CreatePaymentRefundKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};

const CreateRefundForm = ({
  supportId,
  form,
}: {
  supportId: string;
  form: FormHandler;
}) => {
  const { data: paymentCreateConfig, isLoading: isLoadingPaymentConfig } =
    supportRequestActionsApi.useCreatePaymentRefundFormConfigQuery(supportId);

  return (
    <FormContent isLoading={isLoadingPaymentConfig} form={paymentCreateConfig} flex={1}>
      {paymentCreateConfig ? (
        <FormBuilder data={paymentCreateConfig} form={form} />
      ) : null}
    </FormContent>
  );
};
