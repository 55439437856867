import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { SupportRequestCancelOptions } from '@naus-code/naus-client-types';

import { Field } from '~/components/Card/Field';
import { LinkText } from '~/components/LinkText';
import { RequestOptionsJSON } from '~/components/SupportRequest/RequestOptions/RequestOptions.Raw';
import { getUserTicketName } from '~/components/SupportRequest/RequestOptions/RequestOptions.utils';
import { Table } from '~/components/Table/Table';
import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

export const CancelCalculationInfoBreakdown = ({
  options,
}: {
  options: SupportRequestCancelOptions;
}) => {
  const { data, isLoading } = bookingApi.useGetBookingQuery(
    options.calculation.bookingId,
  );
  const foundPurchase = data?.booking.purchases.find(
    (p) => p._id === options.calculation.purchaseId,
  );

  const ticketBreakdown = useCancelTicketBreakDown();

  return (
    <DelayRender isLoading={isLoading}>
      {foundPurchase ? (
        <VStack space="2.5">
          <Text.Body1Regular color="error-mid">CANCEL</Text.Body1Regular>
          <Field
            label={translate('supportRequests.requestOptions.expectedRefund')}
            value={getPriceWithSymbol(
              options.refundMethod.expectedRefund,
              `${foundPurchase.ccy} `,
            )}
            // color={breakdown.canCancel ? 'success-mid' : 'monochrome-light'}
            valueType="body2Medium"
          />
          <Field
            label={translate('supportRequests.requestOptions.serviceFee')}
            value={getPriceWithSymbol(options.refundMethod.fee, `${foundPurchase.ccy} `)}
            // color={breakdown.canCancel ? 'success-mid' : 'monochrome-light'}
            valueType="body2Medium"
          />
          <Field
            label={translate('supportRequests.requestOptions.refundMethod')}
            value={options.refundMethod.type === 'bank' ? 'Bank' : 'Voucher'}
            valueType="body2Medium"
          />
          <LinkText
            onPress={() => {
              ticketBreakdown.open(options);
            }}
          >
            {translate('supportRequests.requestOptions.ticketBreakdown')}
          </LinkText>
          <RequestOptionsJSON options={options} />
        </VStack>
      ) : (
        <Text.Body2Medium color="error-mid">
          {translate('supportRequests.requestOptions.noPurchase')}
        </Text.Body2Medium>
      )}
    </DelayRender>
  );
};

const TicketBreakDown = ({ options }: { options: SupportRequestCancelOptions }) => {
  const { data } = bookingApi.useGetBookingQuery(options.calculation.bookingId);
  const foundPurchase = data?.booking.purchases.find(
    (p) => p._id === options.calculation.purchaseId,
  );

  return (
    <DelayRender>
      <View bc="monochrome-extraLight" b={1} p="3" br={16}>
        <Table
          headers={[
            translate('supportRequests.requestOptions.ticketNo'),
            translate('supportRequests.requestOptions.ticketInfo'),
            translate('supportRequests.requestOptions.canCancel'),
            translate('supportRequests.requestOptions.originalValue'),
            translate('supportRequests.requestOptions.originalRefundValue'),

            translate('supportRequests.requestOptions.bookedValue'),
            translate('supportRequests.requestOptions.bookedRefundValue'),
          ]}
          data={options.calculation.ticketBreakdown.map((breakdown) => {
            const foundTicket = foundPurchase?.tickets.find(
              (t) => t._id === breakdown.ticketId,
            );

            const ticketName = getUserTicketName(foundTicket);

            return [
              foundTicket?.companyTicketNumberClean || breakdown.ticketId,
              ticketName,
              breakdown.canCancel ? 'YES' : 'NO',
              getPriceWithSymbol(
                breakdown.originalValue.valueInOriginalCcy,
                breakdown.originalValue.originalCcy + ' ',
              ),
              getPriceWithSymbol(
                breakdown.refund.valueInOriginalCcy,
                // TODO: change this to book CCY
                breakdown.refund.originalCcy + ' ',
              ),
              getPriceWithSymbol(
                breakdown.originalValue.valueInBookCcy,
                // TODO: change this to book CCY
                breakdown.originalValue.originalCcy + ' ',
              ),

              getPriceWithSymbol(
                breakdown.refund.valueInBookCcy,
                // TODO: change this to book CCY
                breakdown.refund.originalCcy + ' ',
              ),
            ];
          })}
        />
      </View>
    </DelayRender>
  );
};

const useCancelTicketBreakDown = () => {
  const ModalKey = 'useCancelTicketBreakDown';
  const { openModal, closeModal } = useModal();

  return {
    open: (options: SupportRequestCancelOptions) => {
      openModal(
        <Modal.Container>
          <Modal.Header title="Ticket breakdown" />
          <Modal.Body scroll>
            <TicketBreakDown options={options} />
          </Modal.Body>
        </Modal.Container>,
        { key: ModalKey },
      );
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
