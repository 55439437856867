// import { getErrorMessageAndCode } from "@droplet-tech-code/core-elements/module/utils/error";
import { Middleware } from '@reduxjs/toolkit';

export const alertMiddleware: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

  // if (isRejectedWithValue(action)) {
  //   console.warn("We got a rejected action!", action);

  //   const payload = action?.payload;

  //   const { message, code } = getErrorMessageAndCode(payload);
  //   switch (code) {
  //     case VERIFY_CODE: {
  //       break;
  //     }

  //     default: {
  //       showToast(message);
  //       break;
  //     }
  //   }
  // }

  return next(action);
};
