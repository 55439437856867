import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';

import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import {
  useInvalidateCustomerRequestListByEmail,
  useInvalidateCustomerRequestListById,
} from '~/screens/Support/Requests/Customer/SupportRequest.List';
import { translate } from '~/utils/translation.utils';

export const useSupportRequestByCustomer = () => {
  const navigation = useAppNavigation();
  const { invalidate: invalidateEmail } = useInvalidateCustomerRequestListByEmail();
  const { invalidate: invalidateCustomerId } = useInvalidateCustomerRequestListById();

  const navigate = (value: { email: string; customerId: string }) => {
    if (!value.customerId && !value.email) {
      showToast(translate('customer.provideOne'), { type: 'error' });
      return null;
    }

    if (value.customerId && !value.email) {
      invalidateCustomerId(value.customerId);
      navigation.navigate('CustomerSupportRequests', {
        customerId: value.customerId,
        email: '',
      });
    } else if (!value.customerId && value.email) {
      invalidateEmail(value.email);
      navigation.navigate(
        'CustomerSupportRequests',
        value as { email: string; customerId?: string },
      );
    } else if (value.customerId && value.email) {
      invalidateEmail(value.email);
      invalidateCustomerId(value.customerId);
      navigation.navigate(
        'CustomerSupportRequests',
        value as { email: string; customerId?: string },
      );
    }
  };

  return useCreateManualFormModal({
    title: translate('customer.supportRequestsForCustomer'),
    subtitle: translate('customer.enterEmail'),
    formData: [
      {
        items: [
          {
            type: 'email',
            label: translate('customer.email'),
            id: 'email',
            optional: true,
          },
          {
            type: 'text',
            label: translate('customer.customerId'),
            id: 'customerId',
            optional: true,
          },
        ],
      },
    ],
    onConfirm: (value) => {
      navigate(value);
    },
  });
};
