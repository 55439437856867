import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { isRTKErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { CreateLocationRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { getPlugins } from '~/components/Form/Form.Plugins';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { portLocationApi } from './PortLocations.api';

export const AddPortLocationModalKey = 'AddModal-PortLocation';
const AddPortLocationModal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [createPortLocation, { isLoading: isCreatingPortLocation }] =
    portLocationApi.useCreatePortLocationMutation();

  return (
    <>
      <Modal.Header
        title={translate('portLocations.newPortLocation')}
        subtitle={translate('portLocations.enterNewPortLocation')}
        divider
        onPress={() => {
          closeModal(AddPortLocationModalKey);
        }}
      />
      <Modal.Body p="0">
        <CreatePortLocationForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingPortLocation}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<CreateLocationRequest['location']>();

          if (valid) {
            const response = await createPortLocation({ location: value });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddPortLocationModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddPortLocationModalKey);
        }}
      />
    </>
  );
};

export const useAddPortLocation = () => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<AddPortLocationModal />, {
        key: AddPortLocationModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
const CreatePortLocationForm = ({ form }: { form: FormHandler }) => {
  const { data: portLocationCreateConfig, isLoading: isLoadingPortLocationConfig } =
    portLocationApi.useGetCreatePortLocationFormConfigQuery();

  const [addImage] = portLocationApi.useCreateLocationImageUploadMutation();

  const uploadImage = async (formData: FormData) => {
    const res = await addImage(formData);

    if (isRTKErrorResponse(res)) {
      throw res.error;
    }

    return res.data;
  };

  return (
    <FormContent
      isLoading={isLoadingPortLocationConfig}
      form={portLocationCreateConfig}
      flex={1}
    >
      {portLocationCreateConfig ? (
        <FormBuilder
          data={portLocationCreateConfig}
          form={form}
          extensions={getPlugins({ onImageUpload: uploadImage })}
        />
      ) : null}
    </FormContent>
  );
};
