import { getKeysOfObject } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  TransactionClient,
  TransactionClientGetListResponse,
} from '@naus-code/naus-admin-types';
import { KeyVal } from '@naus-code/naus-client-types';
import isPlainObject from 'lodash/isPlainObject';
import { useMemo } from 'react';

import { CellItem, RowItem, TableOptions } from '~/components/Table/Table.types';
import { TableHeaderProp } from '~/components/Table/Table_v2';
import { transactionApi } from '~/screens/Finance/Transactions/Transactions.api';
import { useTransactionStore } from '~/screens/Finance/Transactions/Transactions.store';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';

export type FlattenTransaction = {
  [key: string]: string | number | any[] | null | undefined;
};

const flattenObject = (obj: any, flatObj = {}, nestedKey = '') => {
  if (isPlainObject(obj)) {
    Object.keys(obj).forEach((key) => {
      if (isPlainObject(obj[key])) {
        flattenObject(obj[key], flatObj, nestedKey);
      } else {
        flatObj[key + nestedKey] = obj[key];
      }
    });
  }
  return flatObj as FlattenTransaction;
};

export const useFlattenTransactionForTable = (transactions: TransactionClient[]) => {
  return useMemo(() => {
    return transactions.map((transaction) => {
      return flattenObject(transaction);
    });
  }, []);
};

export const useExtractTransactionDataForTable = (
  transactions: TransactionClient[],
  { colDef, rowDef }: TableOptions<TransactionClient>,
) => {
  const headerKeys = getKeysOfObject(colDef);
  const rows = useMemo(() => {
    return transactions.map((transaction, rowIndex) => {
      return {
        item: transaction,
        rowStyle: [rowDef?.rowStyle, rowDef?.getRowStyle?.(transaction, rowIndex)],
        cells: headerKeys.map((colKey) => {
          const value = transaction[colKey];
          const cell = colDef[colKey]!;

          return {
            value,
            item: transaction,
            colKey,
            cellDef: cell,
          } as CellItem<TransactionClient, any>;
        }),
      };
    });
  }, [transactions, colDef]);

  const headers = useMemo(() => {
    return headerKeys.map((colKey) => {
      const cell = colDef[colKey]!;
      return {
        colKey,
        style: cell.headerStyle,
        width: cell.width,
      } as TableHeaderProp;
    });
  }, [headerKeys, colDef]);

  return {
    headers,
    rows,
  };
};

export const useTransactionPeriodData = (options?: { skip: boolean }) => {
  const dateRange = useTransactionStore((s) => s.range);
  const { data, isLoading, refetch } = transactionApi.useGetTransactionsPeriodQuery(
    {
      from: dateRange.from,
      to: dateRange.to,
    },
    options,
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export const useTransactionList = ({
  transactions,
}: {
  transactions: TransactionClientGetListResponse['list'];
}) => {
  const selectedCreditCodes = useTransactionStore((s) => s.creditCodes);
  const selectedOperatorIds = useTransactionStore((s) => s.operatorIds);
  const baseOperatorTransactions = useMemo(() => {
    return transactions.filter(
      (item) => item.origin === 'COMPANY' || item.dest === 'COMPANY',
    );
  }, [transactions]);

  const baseCustomerTransactions = useMemo(() => {
    return transactions.filter(
      (item) => item.origin === 'CUSTOMER' || item.dest === 'CUSTOMER',
    );
  }, [transactions]);

  const filteredTransactionsByOperatorIds = useMemo(() => {
    if (Object.values(selectedOperatorIds).every((item) => !item)) {
      return baseOperatorTransactions;
    }

    return baseOperatorTransactions.filter((item) => {
      if (item.companyId) {
        return !!selectedOperatorIds[item.companyId!];
      }
      return true;
    });
  }, [baseOperatorTransactions, selectedOperatorIds]);

  const selectedTransaction = useTransactionStore((s) => s.selectedTransaction);

  const operatorListBase = useMemo(() => {
    if (Object.values(selectedCreditCodes).every((item) => !item)) {
      return filteredTransactionsByOperatorIds;
    }

    return filteredTransactionsByOperatorIds.filter((item) => {
      if (item.creditCode) {
        return !!selectedCreditCodes[item.creditCode!];
      }
      return true;
    });
  }, [filteredTransactionsByOperatorIds, selectedCreditCodes]);

  const operatorList = useMemo(() => {
    if (selectedTransaction && selectedTransaction.bookingId) {
      return operatorListBase.filter(
        (item) => item.bookingId === selectedTransaction.bookingId,
      );
    }
    return operatorListBase;
  }, [operatorListBase, selectedTransaction]);

  const customerListBase = useMemo(() => {
    return baseCustomerTransactions
      .filter((t) => !!t.bookingId)
      .filter((customerTransaction) =>
        operatorList.some(
          (operatorTransaction) =>
            operatorTransaction.bookingId === customerTransaction.bookingId,
        ),
      );
  }, [operatorList, baseCustomerTransactions, selectedTransaction]);

  const customerList = useMemo(() => {
    if (selectedTransaction && selectedTransaction.bookingId) {
      return customerListBase.filter(
        (item) => item.bookingId === selectedTransaction.bookingId,
      );
    }
    return customerListBase;
  }, [customerListBase, selectedTransaction]);

  return {
    operatorList,
    customerList,
  };
};

export const sumValueFromTransaction = (
  transactions: TransactionClient[],
  key: string,
) => {
  return transactions.length
    ? `${getPriceWithSymbol(
        transactions.reduce((memo, next) => {
          return memo + (readNestedObject(next, key) ?? 0);
        }, 0),
        '',
      )} ${transactions[0].ccy}`
    : '-';
};

const readNestedObject = (obj: any, key: string) => {
  if (!key) {
    return obj;
  }
  const keys = key.split('.');
  return readNestedObject(obj[keys[0]], keys.slice(1).join(''));
};

export interface GroupAnalysis {
  operatorOut: number;
  operatorIn: number;
  operatorNet: number;
  operatorExtraRefund: number;

  customerPayments: number;
  customerRefunds: number;
  customerVoucherRefunds: number;
  customerServiceFee: number;
  customerCPC: number;
  customerNet: number;

  companyNet: number;
}

export function getAnalysis(
  transactions: TransactionClient[],
  options: { operator?: boolean; customer?: boolean },
) {
  const ccyAnalysis: KeyVal<GroupAnalysis> = {};

  for (const transaction of transactions) {
    const ccy = transaction.ccy;
    if (!ccyAnalysis[ccy]) {
      ccyAnalysis[ccy] = {
        operatorOut: 0,
        operatorIn: 0,
        operatorNet: 0,
        operatorExtraRefund: 0,

        customerPayments: 0,
        customerRefunds: 0,
        customerVoucherRefunds: 0,
        customerServiceFee: 0,
        customerCPC: 0,
        customerNet: 0,

        companyNet: 0,
      };
    }

    if (options.operator) {
      processOperator(transaction, ccyAnalysis[ccy]);
    }
    if (options.customer) {
      processCustomer(transaction, ccyAnalysis[ccy]);
    }
  }

  /**
   * Calculate the net of each
   */
  const currencies = Object.keys(ccyAnalysis);
  for (const currency of currencies) {
    const item = ccyAnalysis[currency];
    item.customerNet =
      item.customerPayments -
      item.customerRefunds -
      item.customerVoucherRefunds +
      item.customerServiceFee +
      item.customerCPC;

    item.operatorNet = item.operatorOut - item.operatorIn;
    item.companyNet = item.customerNet - item.operatorNet;
  }

  return ccyAnalysis;
}

function processOperator(transaction: TransactionClient, analysis: GroupAnalysis) {
  if (transaction.origin !== 'COMPANY' && transaction.dest !== 'COMPANY') {
    return;
  }
  if (transaction.type === 'in-flow') {
    addValue(analysis, 'operatorIn', transaction.value);
  }
  if (transaction.type === 'out-flow') {
    addValue(analysis, 'operatorOut', transaction.value);
  }

  if (typeof transaction.refundExtra === 'number') {
    addValue(analysis, 'operatorExtraRefund', transaction.refundExtra);
  }
}

function processCustomer(transaction: TransactionClient, analysis: GroupAnalysis) {
  if (transaction.origin !== 'CUSTOMER' && transaction.dest !== 'CUSTOMER') {
    return;
  }

  const serviceValue = transaction.serviceValue ?? 0;

  if (typeof transaction.payment?.value === 'number') {
    addValue(analysis, 'customerPayments', transaction.payment.value);
  }
  if (typeof transaction.paymentRefund?.value === 'number') {
    addValue(analysis, 'customerRefunds', transaction.paymentRefund.value);
  }
  if (typeof transaction.voucherCreate?.value === 'number') {
    addValue(analysis, 'customerVoucherRefunds', transaction.voucherCreate.value);
  }
  if (typeof transaction.payment?.paymentExtraCharge === 'number') {
    addValue(analysis, 'customerCPC', transaction.payment.paymentExtraCharge);
  }

  addValue(analysis, 'customerServiceFee', serviceValue);
}

function addValue(analysis: GroupAnalysis, key: keyof GroupAnalysis, value: number) {
  if (Number.isNaN(value)) {
    return;
  }
  analysis[key] = analysis[key] + value;
}

export const useIsSelectedTransaction = (row: RowItem<TransactionClient>) => {
  const bookingId = useTransactionStore((s) => s.selectedTransaction?.bookingId);
  return !!bookingId && bookingId === row.item.bookingId;
};
