import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { ScreenPropPurchasesByCustomerId } from '~/navigation/Navigation.types';

import { PurchaseItemScreenComponent } from '../PurchaseItem';
import { PurchaseList, PurchaseListNative } from './Purchase.List';

export const PurchasesByCustomerIdScreen = (props: ScreenPropPurchasesByCustomerId) => {
  return (
    <PageContainer>
      <PurchasesLayout {...props} />
    </PageContainer>
  );
};

export const PurchasesLayout = (props: ScreenPropPurchasesByCustomerId) => {
  const purchaseId = props.route.params?.id;
  const customerId = props.route.params?.customerId;

  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(purchaseId);
  }, [purchaseId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <PurchaseList onPress={(item) => setId(item.id)} customerId={customerId} />
      ) : (
        <PurchaseListNative customerId={customerId} />
      )}
      {id ? (
        <PurchaseItemScreenComponent purchaseId={id} onClose={() => setId(undefined)} />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
