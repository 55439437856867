import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { FerryCompanyOperationsConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { translate } from '~/utils/translation.utils';

import { OpenInfo } from './OpenInfo';
import { OpenPolicies } from './OpenPolicies';

export const Open = ({
  allOperations,
}: {
  allOperations: FerryCompanyOperationsConfig;
}) => {
  const { openConfig, defaultOpenPolicies, specialOpenPolicies } = allOperations;
  return (
    <CardWithHeader headerText={translate('companyConfig.open')}>
      <VStack p="2.5">
        <OpenInfo openConfig={openConfig} />
        <Divider bg="primary-base" thickness={15} br={10} />
        <OpenPolicies
          defaultPolicies={defaultOpenPolicies}
          specialPolicies={specialOpenPolicies}
        />
      </VStack>
    </CardWithHeader>
  );
};
