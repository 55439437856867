import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';

import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { useOperationConfigModal } from '~/components/Config/SystemConfig/Modals/OperationsConfig/OperationsConfig.Modal';
import { useUpdateSystemAppVersionModal } from '~/components/Config/SystemConfig/Modals/UpdateAppVersionModal';
import { translate } from '~/utils/translation.utils';

// import { systemConfigApi } from '../systemConfigs.api';

export const OtherConfig = () => {
  // const { data } = systemConfigApi.useGetSystemConfigQuery();
  const { open: openOperationConfig } = useOperationConfigModal();
  const { open: openUpdateAppVersionConfig } = useUpdateSystemAppVersionModal();
  // if (data === undefined) {
  //   return null;
  // }
  // const { operationConfig, paymentConfig, travelPackConfig } = data.config;
  // const onPressPlaceHolder = () => {
  //   operationConfig;
  //   paymentConfig;
  //   travelPackConfig;
  // };
  return (
    <VStack space="2.5">
      <ConfigListSectionTitle>
        <Text.Small color="monochrome-mid">{translate('systemConfig.other')}</Text.Small>
      </ConfigListSectionTitle>
      <ConfigListItem
        text={translate('systemConfig.systemOperationConfig')}
        iconRightType="edit"
        onPress={openOperationConfig}
      />
      <ConfigListItem
        text={translate('systemConfig.appVersionConfig')}
        iconRightType="edit"
        onPress={openUpdateAppVersionConfig}
      />
      <ConfigListItem
        text={translate('systemConfig.paymentConfig')}
        iconRightType="edit"
        // onPress={() => onPressPlaceHolder()}
      />
      <ConfigListItem
        text={translate('systemConfig.travelPackConfig')}
        iconRightType="edit"
        // onPress={onPressPlaceHolder}
      />
    </VStack>
  );
};
