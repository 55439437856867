import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';

import { AppText } from '../Text';

export const CategorySelector = ({
  category,
  categorySetArray,
  setCategory,
}: {
  category: string;
  categorySetArray: string[];
  setCategory: (category: string) => void;
}) => {
  return (
    <View>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <HStack p="2.5">
          <CategoryItem
            title={'All'}
            isOn={category === 'All'}
            onPress={() => {
              setCategory('All');
            }}
          />
          {categorySetArray.map((categoryTitle, index) => {
            return (
              <CategoryItem
                key={index + ''}
                title={categoryTitle}
                isOn={category === categoryTitle}
                onPress={() => {
                  setCategory(categoryTitle);
                }}
              />
            );
          })}
        </HStack>
      </ScrollView>
    </View>
  );
};

const CategoryItem = ({
  onPress,
  title,
  isOn,
}: {
  onPress: () => void;
  title: string;
  isOn: boolean;
}) => {
  return (
    <HStack>
      <IconButton
        icon={isOn ? 'check-box-on' : 'check-box-off'}
        size="small"
        color="monochrome"
        onPress={onPress}
      />
      <AppText.ExtraSmall color="monochrome-extraDark">{title}</AppText.ExtraSmall>
    </HStack>
  );
};
