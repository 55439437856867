import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const PurchasesListHeaderEmail = ({
  email,
  onReset,
  onPress,
}: {
  email: string;
  onReset: () => void;
  onPress?: () => void;
}) => {
  return (
    <PageHeader
      title={`${translate('purchases.purchasesByEmail')}`}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress,
      }}
      childRight={<IconButton icon="reset" color="monochrome" onPress={onReset} />}
      Subtitle={<Subtitle email={email} />}
    />
  );
};

const Subtitle = ({ email }: { email: string }) => {
  return (
    <Text.Body2Regular style={{ marginTop: 5 }} numberOfLines={1} color="monochrome-mid">
      {email}
    </Text.Body2Regular>
  );
};
