import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { SupportStatus } from '@naus-code/naus-client-types';

import { translate } from '~/utils/translation.utils';

const supportRequestMap = (): { [key in SupportStatus]: string } => {
  return {
    Open: translate('supportRequests.open'),
    Pending: translate('supportRequests.pending'),
    Paused: translate('supportRequests.paused'),
    Closed: translate('supportRequests.closed'),
    Deleted: translate('supportRequests.deleted'),
  };
};

export const displaySupportStatus = (status: SupportStatus) => {
  const displayStatus = supportRequestMap()[status];
  if (displayStatus === undefined) {
    return status;
  }
  return displayStatus;
};

export const submitSupportStatus = (displayStatus: string) => {
  const [status, _value] = Object.entries(supportRequestMap()).find(
    ([_key, value]) => value === displayStatus,
  ) || [undefined, undefined];

  if (status === undefined) {
    showToast(translate('supportRequests.statusNotFound'), { type: 'error' });
    return null;
  }

  return status;
};

export const formatMapToFormData = () => {
  return Object.entries(supportRequestMap())
    .map(([value, label]) => {
      return { label, value };
    })
    .filter((formData) => formData.value !== 'Deleted');
};

export const getColorFromStatus = (status: SupportStatus): ColorKeys => {
  switch (status) {
    case 'Open':
      return 'primary-light';
    case 'Pending':
      return 'warning-light';
    case 'Paused':
      return 'error-light';
    case 'Closed':
      return 'monochrome-light';
    default:
      return 'monochrome-light';
  }
};
