import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { SupportRequestModifyOptions } from '@naus-code/naus-client-types';

import { Field } from '~/components/Card/Field';
import { RequestOptionsJSON } from '~/components/SupportRequest/RequestOptions/RequestOptions.Raw';
import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { DATE_TIME_SERVER_FORMAT } from '~/utils/date.utils';
import { translate } from '~/utils/translation.utils';

export const ModifyCalculationInfoBreakdown = ({
  options,
}: {
  options: SupportRequestModifyOptions;
}) => {
  const { data, isLoading } = bookingApi.useGetBookingQuery(
    options.calculation.bookingId,
  );
  const foundPurchase = data?.booking.purchases.find(
    (p) => p._id === options.calculation.purchaseId,
  );

  return (
    <DelayRender isLoading={isLoading}>
      {foundPurchase ? (
        <VStack space="2.5" flex={1}>
          <Text.Body1Regular color="warning-light">MODIFY</Text.Body1Regular>
          <Field
            label={translate('companyConfig.canModify')}
            value={options.calculation.canModify ? 'YES' : 'NO'}
            color={options.calculation.canModify ? 'success-mid' : 'error-mid'}
            valueType="body2Medium"
          />

          <Field
            label={'Start time'}
            value={dayjs(options.calculation.policy.startTime as any).format(
              DATE_TIME_SERVER_FORMAT,
            )}
            valueType="body2Regular"
          />
          <Field
            label={'End time'}
            value={dayjs(options.calculation.policy.endTime as any).format(
              DATE_TIME_SERVER_FORMAT,
            )}
            valueType="body2Regular"
          />
          <Field
            label={'Request by'}
            value={
              options.calculation.policy.requestBy
                ? dayjs(options.calculation.policy.requestBy as any).format(
                    DATE_TIME_SERVER_FORMAT,
                  )
                : '-'
            }
            valueType="body2Regular"
          />

          <RequestOptionsJSON options={options} />
        </VStack>
      ) : null}
    </DelayRender>
  );
};
