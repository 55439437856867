import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  isNativeMobile,
  isWeb,
  notEmpty,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  PurchaseClientWithMetaWithTickets,
  PurchaseListItemResponse,
  SupportRequestBackendClient,
  TicketClient,
} from '@naus-code/naus-admin-types';
import { useCallback, useEffect, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';

import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { PurchaseListItemInternalComponent } from '~/screens/Support/Purchases/Purchase.ListItem';
import { TicketDetails } from '~/screens/Support/Purchases/PurchaseItem';
import { purchaseApi } from '~/screens/Support/Purchases/Purchases.api';
import { PurchaseList } from '~/screens/Support/Purchases/PurchasesByCustomerId/Purchase.List';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { translate } from '~/utils/translation.utils';

export const AssignPurchaseKey = 'AddModal-AssignPurchase';
export const AssignPurchaseModal = ({
  supportRequestInfo,
  check,
}: {
  supportRequestInfo: SupportRequestBackendClient;
  check: string;
}) => {
  const { closeModal } = useModal();

  const supportId = supportRequestInfo._id;
  const customerId = supportRequestInfo._customerId;
  const email = supportRequestInfo.p_email;
  const [selectedPurchase, setSelectedPurchase] = useState<
    PurchaseListItemResponse | undefined
  >(undefined);

  const [byEmail, setByEmail] = useState<boolean>(false);
  const [selectedTickets, setSelectedTickets] = useState<string[]>([]);

  const [assignPurchase, { isLoading: isAssigningPurchase }] =
    supportRequestActionsApi.useChangeSelectionReferencesMutation();

  const onSelectTicket = useCallback((selectedTickets: TicketClient[]) => {
    setSelectedTickets(selectedTickets.map((ticket) => ticket._id));
  }, []);

  const onSelectPurchase = useCallback((purchase: PurchaseListItemResponse) => {
    setSelectedPurchase(purchase);
  }, []);

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.assignPurchase')}
        divider
        children={
          <HStack p="2.5" space="2.5">
            <View flex={1} />
            <AppText.Small color="monochrome">
              {translate('screens.purchasesByEmail')}
            </AppText.Small>
            <Toggle
              value={byEmail}
              color="primary"
              onChange={() => setByEmail(!byEmail)}
            />
          </HStack>
        }
        onPress={() => {
          closeModal(AssignPurchaseKey);
        }}
      />
      <Modal.Body scroll p="0">
        {!selectedPurchase ? (
          byEmail ? (
            <PurchaseList key={'email-list'} email={email} onPress={onSelectPurchase} />
          ) : (
            <PurchaseList
              key={'id-list'}
              customerId={customerId}
              onPress={onSelectPurchase}
            />
          )
        ) : (
          <AssignPurchaseSelectionContainer
            purchaseListItem={selectedPurchase}
            onSelectTicket={onSelectTicket}
          />
        )}
      </Modal.Body>
      {selectedPurchase && (
        <Modal.Footer
          divider
          isLoading={isAssigningPurchase}
          onConfirm={async () => {
            if (!selectedPurchase) {
              return null;
            }
            const response = await assignPurchase({
              supportId,
              bookingId: selectedPurchase.bookingId,
              purchaseId: selectedPurchase.id,
              ticketIds: selectedTickets,
              check,
            });
            handleResponse({
              response,
              successMessage: translate('supportRequests.assignedPurchaseToSR'),
              onSuccess: () => {
                closeModal(AssignPurchaseKey);
              },
            });
          }}
          onCancel={() => {
            closeModal(AssignPurchaseKey);
          }}
        />
      )}
    </Modal.Container>
  );
};

export const useAssignPurchase = () => {
  const { openModal } = useModal();

  return {
    open: (supportRequestInfo: SupportRequestBackendClient, check: string) => {
      openModal(
        <AssignPurchaseModal supportRequestInfo={supportRequestInfo} check={check} />,
        {
          key: AssignPurchaseKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};

const AssignPurchaseSelectionContainer = ({
  purchaseListItem,
  onSelectTicket,
}: {
  onSelectTicket: (selectedTickets: TicketClient[]) => void;
  purchaseListItem: PurchaseListItemResponse;
}) => {
  const { data: purchaseData, isLoading } = purchaseApi.useGetPurchaseQuery(
    purchaseListItem.id,
  );
  const purchase: PurchaseClientWithMetaWithTickets | undefined = purchaseData?.purchase;
  const [checkedItems, setCheckedItems] = useState(purchase?.tickets.map(() => false));

  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setCheckedItems(purchase?.tickets.map(() => false));
  }, [purchase]);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (purchase === undefined || checkedItems === undefined) {
    return null;
  }

  return (
    <ScrollView>
      <VStack p="3" space="6">
        <Text.Small color="monochrome-mid">
          {translate('purchases.purchaseTool.selectPurchaseToAssign')}
        </Text.Small>
        <SwitchStack space={isWeb ? '6' : '3'}>
          <VStack space="3" flex={1}>
            <HStack space="3" pl="6">
              <CheckBox
                checked={allChecked}
                onPress={(isChecked) => {
                  const newItems = [...checkedItems].map(() => isChecked);
                  setCheckedItems(newItems);
                  setAllChecked(isChecked);
                  onSelectTicket(
                    newItems
                      .map((checked, ix) => (checked ? purchase.tickets[ix] : null))
                      .filter(notEmpty),
                  );
                }}
              />
              <PurchaseListItemInternalComponent purchase={purchase} compareMode />
            </HStack>
            <View>
              <DottedLine />
            </View>
            <VStack space="4" flex={isWeb ? 1 : undefined}>
              {purchase.tickets.map((ticket, iy) => {
                return (
                  <HStack space="3" key={iy} pl="6">
                    <CheckBox
                      checked={checkedItems[iy]}
                      onPress={(isChecked) => {
                        const newItems = [...checkedItems];
                        newItems[iy] = isChecked;
                        setCheckedItems(newItems);
                        onSelectTicket(
                          newItems
                            .map((checked, ix) => (checked ? purchase.tickets[ix] : null))
                            .filter(notEmpty),
                        );
                      }}
                    />
                    <VStack space="2" flex={isNativeMobile ? 1 : undefined}>
                      <TicketDetails ticket={ticket} ccySymbol={purchase.ccySymbol} />
                      <View>
                        {purchase.tickets.length - 1 === iy ? null : <DottedLine />}
                      </View>
                    </VStack>
                  </HStack>
                );
              })}
            </VStack>
          </VStack>
        </SwitchStack>
      </VStack>
    </ScrollView>
  );
};
