import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  SystemConfigAddFerryTermCategoryRequest,
  SystemConfigClientUpdateCompanyTermsConfigRequest,
  SystemConfigClientUpdatePrivacyTermsConfigRequest,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useUpdateCompanyTermsConfig = () => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      systemConfigApi.useGetUpdateCompanyTermsConfigFormConfigQuery();
    return { data, isLoading };
  }, []);

  const [updateCompanyTerms, { isLoading: isUpdatingTerms }] =
    systemConfigApi.useUpdateCompanyTermsConfigMutation();

  const onConfirm = async ({
    value,
    check,
  }: {
    value: SystemConfigClientUpdateCompanyTermsConfigRequest['companyTerms'];
    check?: string;
  }) => {
    return await updateCompanyTerms({
      companyTerms: value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('systemConfig.updateCompanyTerms'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingTerms,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdatePrivacyTermsConfig = () => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      systemConfigApi.useGetUpdatePrivacyTermsConfigFormConfigQuery();
    return { data, isLoading };
  }, []);

  const [updatePrivacyTerms, { isLoading: isUpdatingTerms }] =
    systemConfigApi.useUpdatePrivacyTermsConfigMutation();

  const onConfirm = async ({
    value,
    check,
  }: {
    value: SystemConfigClientUpdatePrivacyTermsConfigRequest['privacyPolicy'];
    check?: string;
  }) => {
    return await updatePrivacyTerms({
      privacyPolicy: value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('systemConfig.updatePrivacyTerms'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingTerms,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddTermCategory = ({ check }: { check?: string }) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [addTermCategory, { isLoading: isAddingTerm }] =
    systemConfigApi.useAddTermCategoryMutation();

  const onConfirm = async ({
    value,
    check,
  }: {
    value: SystemConfigAddFerryTermCategoryRequest['category'];
    check?: string;
  }) => {
    return await addTermCategory({
      category: value,
      check: check || '',
    });
  };

  return {
    open: (supportedLangs: string[]) => {
      const config: ConfigForData = [
        {
          items: [
            {
              type: 'text',
              label: translate('systemConfig.name'),
              id: 'name',
            },
            {
              type: 'lang-text',
              label: translate('systemConfig.displayName'),
              list: supportedLangs.map((langName) => {
                return { value: langName };
              }),
              id: 'displayName',
            },
          ],
        },
      ];
      openForm({
        modalTitle: translate('systemConfig.addTermCategory'),
        useFormHookHandler: () => {
          return { data: { config, check }, isLoading: false };
        },
        onConfirm,
        loadingAction: isAddingTerm,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
