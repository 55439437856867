import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const PausedSupportRequestsListHeader = ({
  refetch,
  isLoading,
}: {
  refetch: () => void;
  isLoading: boolean;
}) => {
  return (
    <PageHeader
      title={translate('supportRequests.pausedSupportRequests')}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress: () => {
          refetch();
        },
        isLoading,
      }}
    />
  );
};
