import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState } from "react";

export const mergeAndRemoveDuplicates = <T extends { id: string }>(
  originalList: T[],
  newList: T[],
) => {
  const toDeleteItems = [] as number[];
  const mappedList = originalList.map(
    (oItem) =>
      newList.find((nItem, ix) => {
        const isMatch = nItem.id === oItem.id;
        if (isMatch) {
          toDeleteItems.push(ix);
        }
        return isMatch;
      }) || oItem,
  );

  const freshNewList = newList.filter(
    (_, iy) => !toDeleteItems.some((v) => v === iy),
  );
  return [...mappedList, ...freshNewList];
};

export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return [value, () => setValue((value) => value + 1)] as const; // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

export const createPause = (delay: number) => {
  return new Promise((res) => {
    setTimeout(() => {
      res(null);
    }, delay);
  });
};

export const onlyUniqueByFunc: <T>(
  func: (item: T) => string,
) => (value: T, index: number, self: T[]) => boolean =
  (func) => (value, index, self) => {
    return self.findIndex((item) => func(item) === func(value)) === index;
  };

export const parseStorageItem = async <T>(
  key: string,
  storageString: string | null,
): Promise<T | undefined> => {
  if (storageString) {
    try {
      return JSON.parse(storageString);
    } catch (error) {
      await AsyncStorage.removeItem(key);
    }
  }
};

export const getLocalStorageItem = async <T>(
  key: string,
): Promise<T | undefined> => {
  const storageString = await AsyncStorage.getItem(key);
  return parseStorageItem(key, storageString);
};
