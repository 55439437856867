import { ViewStyle } from '@droplet-tech-code/core-elements/module/types/components';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  copyToClipboard,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { TicketClient } from '@naus-code/naus-admin-types';
import {
  FerryTrackingNotifierReceipt,
  PurchaseBackend,
} from '@naus-code/naus-client-types';
import { BookingRetrieveTicket } from '@naus-code/naus-server-common-types';
import { useCallback, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';

import { BookingInfo } from '~/components/Bookings/BookingInfo';
import { Card } from '~/components/Card/Card';
import { PageHeader } from '~/components/Page/Page.Header';
import { AppText } from '~/components/Text';
import { ScreenPropPurchaseItem } from '~/navigation/Navigation.types';
import { CompanyCreditByPurchaseId } from '~/screens/Finance/CreditLine/CreditLine.ByPurchaseId';
import { DATE_SERVER_FORMAT } from '~/utils/date.utils';

import { bookingApi } from '../Bookings/Bookings.api';
import { supportRequestApi } from '../Requests/SupportRequests.api';
import { PurchaseListItemInternalComponent } from './Purchase.ListItem';
import { getPriceWithSymbol } from './purchase.utils';
import { purchaseApi } from './Purchases.api';

export interface PurchaseItemProps {
  purchaseId: string;

  supportId?: string;
  // onClose?: () => void;
  withActions?: boolean;
  style?: ViewStyle;
}

export const PurchaseItem = ({
  purchaseId,
  supportId,
  withActions,
  style,
}: PurchaseItemProps) => {
  const { data: purchaseData, isLoading: isLoadingPurchaseData } =
    purchaseApi.useGetPurchaseQuery(purchaseId);

  const { data: supportData } = supportId
    ? supportRequestApi.useGetSupportRequestQuery(supportId)
    : { data: undefined };

  if (isLoadingPurchaseData) {
    return <LoadingIndicator />;
  }

  if (purchaseData === undefined) {
    return null;
  }

  const ticketIds = supportData?.supportRequest.ticketIds || [];
  const { purchase } = purchaseData;
  const tickets = purchase.tickets;
  const { ccySymbol } = purchase;

  const purchaseBackend = purchase as any as PurchaseBackend;
  const purchaseTrackingInfo: PurchaseTrackingInfo | undefined =
    purchaseBackend.purchaseProductType === 'FERRY'
      ? {
          trackId: purchaseBackend.trackId,
          trackIdTimestampSet: purchaseBackend.trackIdTimestampSet,
          trackEndTimestamp: purchaseBackend.trackEndTimestamp,
          actionNotification: purchaseBackend.actionNotification,
          etoNotification: purchaseBackend.etoNotification,
          etaNotification: purchaseBackend.etaNotification,
          departureGate: purchaseBackend.departureGate,
        }
      : undefined;

  return (
    <VStack
      space="4"
      style={[
        isWeb && {
          maxWidth: 600,
        },
        style,
      ]}
    >
      <PurchaseListItemInternalComponent
        purchase={purchase}
        supportId={supportId}
        isPrinted={purchaseBackend.printed}
        withActions={withActions}
        purchaseTrackingInfo={purchaseTrackingInfo}
      />
      <VStack space="2">
        {tickets.map((ticket, index) => {
          const isDisabled =
            ticketIds.length > 0 ? ticketIds.indexOf(ticket._id) === -1 : false;
          return (
            <TicketDetails
              key={index}
              ticket={ticket}
              ccySymbol={ccySymbol}
              disabled={isDisabled}
              voided={
                purchase.void ||
                purchase.voidCustomerOnly ||
                !!purchase.voidProviderOnly ||
                purchase.voidPseudo
              }
              // withActions={withActions}
            />
          );
        })}
      </VStack>
      <CompanyCreditByPurchaseId purchaseId={purchaseId} />
    </VStack>
  );
};

export const TicketDetails = ({
  ticket,
  ccySymbol,
  withActions,
  disabled,
  voided,
}: {
  ticket: TicketClient | BookingRetrieveTicket;
  ccySymbol: string;
  withActions?: boolean;
  disabled?: boolean;
  voided?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { name, icon } = getTicketName(ticket);

  const onClickExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  return (
    <>
      <HStack space="1" style={{ opacity: !disabled ? 1 : 0.3 }}>
        <Icon
          icon={voided ? 'close' : 'arrow-curve-right'}
          color={voided ? 'error-mid' : 'monochrome-mid'}
        />
        <VStack flex={1}>
          <HStack space="2.5">
            <HStack flex={1}>
              <Icon icon={icon} />
              <Text.Body2Regular>{name}</Text.Body2Regular>
            </HStack>
            <HStack>
              <Text.Body2Regular>
                {getPriceWithSymbol(ticket.valueCcy, ccySymbol)}
              </Text.Body2Regular>
              <IconButton
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                onPress={onClickExpand}
              />
              {withActions ? <IconButton icon="menu" onPress={() => {}} /> : null}
            </HStack>
          </HStack>
          <AppText.ExtraSmall>{`#${[
            ticket.companyTicketNumberClean || ticket.companyTicketNumber,
            ticket.companyTicketCode,
            ticket.companyDiscountCode,
          ]
            .filter((t) => !!t)
            .join(' | ')}`}</AppText.ExtraSmall>
        </VStack>
      </HStack>
      {expanded && <SingleTicketInfo ticket={ticket} />}
    </>
  );
};

const SingleTicketInfo = ({
  ticket,
}: {
  ticket: TicketClient | BookingRetrieveTicket;
}) => {
  const allowedFields = new Set([
    'p_firstName',
    'p_lastName',
    'p_sex',
    'p_documentType',
    'p_documentNumber',
    'p_documentExpirationDate',
    'p_birthDate',
    'p_birthPlace',
    'p_nationality',
    'seatNumber',
    'loyaltyNumber',
    'residentId',
    'companyTicketType',
    'companyTicketNumber',
    'companyTicketNumberClean',
    'category',
    'p_plate',
    'parentTicketNumber',
    'companyTicketLetter',
    'parentTicketId',
    'companyTicketCode',
    'providerTicketCode',
    'ticketName',
    'companyDiscountCode',
  ]);

  const dateFields = new Set(['p_birthDate']);
  const fields = Object.keys(ticket).filter((fieldName) => allowedFields.has(fieldName));

  return (
    <VStack pl="5" pr="10">
      {fields.map((field, index) => {
        return (
          <HStack key={index}>
            <Text.Small flex={1} color="monochrome-mid">{`${field}   `}</Text.Small>
            <Text.Small
              onPress={() => {
                if (dateFields.has(field)) {
                  copyToClipboard(dateReformat(ticket[field]));
                } else {
                  copyToClipboard(ticket[field]);
                }
              }}
              color="monochrome-extraDark"
            >
              {dateFields.has(field)
                ? ticket[field] === ''
                  ? '-'
                  : dateReformat(ticket[field])
                : ticket[field] === ''
                  ? '-'
                  : ticket[field]}
            </Text.Small>
          </HStack>
        );
      })}
    </VStack>
  );
};

export const PurchaseItemScreenComponent = ({
  purchaseId,
}: {
  purchaseId: string;
  onClose: () => void;
}) => {
  const { data } = purchaseApi.useGetPurchaseQuery(purchaseId);
  const { data: bookingData } = bookingApi.useGetBookingQuery(
    data?.purchase._bookingId ?? '',
    {
      skip: !data?.purchase._bookingId,
    },
  );

  if (data === undefined || bookingData === undefined) {
    return null;
  }

  const bookingId = data.purchase._bookingId;

  return (
    <ScrollView>
      <VStack p="2.5" flex={1} style={{ maxWidth: 600 }} space="2.5">
        <BookingInfo bookingId={bookingId} />
        <Card p="2.5" w={isWeb ? 600 : undefined}>
          <VStack>
            {bookingData.booking._purchaseIds.map((purchaseId, index) => {
              return (
                <VStack space="2.5" p="2.5" key={index}>
                  <PurchaseItem
                    withActions
                    purchaseId={purchaseId}
                    // onClose={onClose}
                  />
                  {index != bookingData.booking._purchaseIds.length - 1 && <DottedLine />}
                </VStack>
              );
            })}
          </VStack>
        </Card>
      </VStack>
    </ScrollView>
  );
};

export const PurchaseItemScreenNativeComponent = (props: ScreenPropPurchaseItem) => {
  const purchaseId = props.route.params.id;
  const { data, isLoading } = purchaseApi.useGetPurchaseQuery(purchaseId);

  // const navigation = useAppNavigation();

  return (
    <DelayRender isLoading={isLoading}>
      {data ? (
        <VStack flex={1}>
          <PageHeader title={data.purchase.bookingRef} withBack />
          <ScrollView>
            <VStack p="2.5" flex={1} style={[isWeb && { maxWidth: 600 }]}>
              <VStack space="2.5">
                <BookingInfo bookingId={data.purchase._bookingId} />
                <Card p="2.5">
                  <PurchaseItem withActions purchaseId={purchaseId} />
                </Card>
              </VStack>
            </VStack>
          </ScrollView>
        </VStack>
      ) : null}
    </DelayRender>
  );
};

const dateReformat = (date: string) => {
  return dayjs(date, DATE_SERVER_FORMAT).format('DD/MM/YY');
};

export const getTicketName = (ticket: TicketClient | BookingRetrieveTicket) => {
  let name = '';
  let icon: IconsId = 'ferry';
  switch (ticket.ticketType) {
    case 'Passenger':
      name = `${ticket.p_firstName} ${ticket.p_lastName} | ${ticket.p_sex}`;
      icon = 'passenger';
      break;
    case 'Extra':
      name = `${ticket.ticketName || ticket.name} (Extra)`;
      icon = 'extra-plus';
      break;
    case 'Vehicle':
      name = `${ticket.p_plate} (${ticket.category})`;
      icon = 'vehicle';
      break;
    default:
      break;
  }
  return {
    icon,
    name,
  };
};

export interface PurchaseTrackingInfo {
  trackId?: string;
  trackIdTimestampSet?: string;
  trackEndTimestamp?: string;
  actionNotification?: FerryTrackingNotifierReceipt;
  etoNotification?: FerryTrackingNotifierReceipt;
  etaNotification?: FerryTrackingNotifierReceipt;
  departureGate?: string;
}
