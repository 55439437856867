import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { AdminUserListItem } from '@naus-code/naus-admin-types';

export const AdminUserListItemComponent = ({ user }: { user: AdminUserListItem }) => {
  return (
    <VStack flex={1}>
      <HStack p="2" flex={1}>
        <Text.Body2Medium flex={1} bold numberOfLines={1}>
          {user.title}
        </Text.Body2Medium>
        <Text.Small align="right">{user.role}</Text.Small>
      </HStack>
      <HStack space="1" p="2" flex={1}>
        <View flex={1} />
        <HStack>
          <Icon icon="todo-list" />
          <Text.Small>{`x${user.openSupportRequests}`}</Text.Small>
        </HStack>
        <HStack>
          <Icon icon="todo-list" />
          <Text.Small>{`x${user.closedSupportRequests}`}</Text.Small>
        </HStack>
        <HStack>
          <Icon icon="todo-list" />
          <Text.Small>{`x${user.pendingSupportRequests}`}</Text.Small>
        </HStack>
      </HStack>
    </VStack>
  );
};
