import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { KeyVal } from '@naus-code/naus-client-types';
import { ScrollView } from 'react-native';

import {
  AnalysisRow,
  AnalysisRowItem,
} from '~/screens/Finance/Transactions/Transactions.Common';
import { GroupAnalysis } from '~/screens/Finance/Transactions/Transactions.utils';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

export const CustomerAnalysis = ({
  groupedAnalysis,
}: {
  groupedAnalysis: KeyVal<GroupAnalysis>;
}) => {
  const { spacing } = useAppTheme();

  const currencies = Object.keys(groupedAnalysis);

  return (
    <ScrollView
      style={{ alignSelf: 'stretch', flex: undefined }}
      contentContainerStyle={{ paddingHorizontal: spacing[1.5] }}
    >
      <VStack space="1.5">
        <Text.Body2Regular align="center" color="monochrome-mid">
          {translate('transactions.customerAnalysis')}
        </Text.Body2Regular>

        <AnalysisRow>
          {currencies.map((currency) => {
            const analysis = groupedAnalysis[currency];
            return (
              <AnalysisRowItem
                key={currency}
                currency={currency}
                label="Payments"
                value={getPriceWithSymbol(analysis.customerPayments, '')}
              />
            );
          })}
        </AnalysisRow>

        <AnalysisRow>
          {currencies.map((currency) => {
            const analysis = groupedAnalysis[currency];
            return (
              <AnalysisRowItem
                key={currency}
                currency={currency}
                label="Refunds"
                value={getPriceWithSymbol(analysis.customerRefunds, '')}
              />
            );
          })}
        </AnalysisRow>

        <AnalysisRow>
          {currencies.map((currency) => {
            const analysis = groupedAnalysis[currency];
            return (
              <AnalysisRowItem
                key={currency}
                currency={currency}
                label="Voucher refunds"
                value={getPriceWithSymbol(analysis.customerVoucherRefunds, '')}
              />
            );
          })}
        </AnalysisRow>

        <AnalysisRow>
          {currencies.map((currency) => {
            const analysis = groupedAnalysis[currency];
            return (
              <AnalysisRowItem
                key={currency}
                currency={currency}
                label="Service fee"
                value={getPriceWithSymbol(analysis.customerServiceFee, '')}
              />
            );
          })}
        </AnalysisRow>
        <AnalysisRow>
          {currencies.map((currency) => {
            const analysis = groupedAnalysis[currency];
            return (
              <AnalysisRowItem
                key={currency}
                label="CPC"
                currency={currency}
                value={getPriceWithSymbol(analysis.customerCPC, '')}
              />
            );
          })}
        </AnalysisRow>
      </VStack>
    </ScrollView>
  );
};
