import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropPurchasesByCRC } from '~/navigation/Navigation.types';

import { PurchaseItemScreenComponent } from '../PurchaseItem';
import { PurchaseList, PurchaseListNative } from './PurchaseByCRC.List';

export const PurchasesByCRCScreen = (props: ScreenPropPurchasesByCRC) => {
  return (
    <PageContainer>
      <PurchasesLayout {...props} />
    </PageContainer>
  );
};

export const PurchasesLayout = (props: ScreenPropPurchasesByCRC) => {
  const purchaseId = props.route.params?.id;
  const companyReservationCode = props.route.params?.companyReservationCode;

  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(purchaseId);
  }, [purchaseId, companyReservationCode]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <PurchaseList
          onPress={(item) => setId(item.id)}
          companyReservationCode={companyReservationCode}
        />
      ) : (
        <PurchaseListNative companyReservationCode={companyReservationCode} />
      )}
      {id ? (
        <PurchaseItemScreenComponent
          key={id}
          purchaseId={id}
          onClose={() => setId(undefined)}
        />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
