import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { SupportConfigUpdateTemplateAnswerRequest } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { supportConfigApi } from '~/screens/Configs/Support/supportConfig.api';
import { translate } from '~/utils/translation.utils';

export const useCreateTemplateAnswerCategoryModal = ({ check }: { check?: string }) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [createTemplateCategory, { isLoading: isCreatingCategory }] =
    supportConfigApi.useCreateTemplateCategoryMutation();

  const onConfirm = async ({
    value,
    check,
  }: {
    value: { templateCategory: string };
    check?: string;
  }) => {
    return await createTemplateCategory({
      category: value.templateCategory,
      check: check || '',
    });
  };

  return {
    open: () => {
      const config: ConfigForData = [
        {
          items: [
            {
              type: 'text',
              label: translate('supportConfig.name'),
              id: 'templateCategory',
            },
          ],
        },
      ];
      openForm({
        modalTitle: translate('supportConfig.createTemplateCategory'),
        useFormHookHandler: () => {
          return { data: { config, check }, isLoading: false };
        },
        onConfirm,
        loadingAction: isCreatingCategory,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateTemplateAnswerModal = ({
  templateAnswerId,
}: {
  templateAnswerId: string;
}) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      supportConfigApi.useGetUpdateAnswerTemplateFormConfigQuery(templateAnswerId);
    return { data, isLoading };
  }, [templateAnswerId]);

  const [updateTemplateAnswer, { isLoading: isCreatingCategory }] =
    supportConfigApi.useUpdateTemplateAnswerMutation();

  const onConfirm = async ({
    value,
    check,
  }: {
    value: SupportConfigUpdateTemplateAnswerRequest['templateAnswer'];
    check?: string;
  }) => {
    return await updateTemplateAnswer({
      templateAnswer: value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('supportConfig.updateTemplateAnswer'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isCreatingCategory,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
