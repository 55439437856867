import { PageContainer } from '~/components/Page/Page.Container';
import { ScreenPropVoucherByTinyId } from '~/navigation/Navigation.types';

import { VoucherItemComponentByTinyId } from '../Voucher.Item';

export const VoucherByTinyIdScreen = (props: ScreenPropVoucherByTinyId) => {
  return (
    <PageContainer>
      <VoucherByAdminRefLayout {...props} />
    </PageContainer>
  );
};

export const VoucherByAdminRefLayout = (props: ScreenPropVoucherByTinyId) => {
  const voucherTinyId = props.route.params.voucherTinyId;
  return <VoucherItemComponentByTinyId tinyId={voucherTinyId} onClose={() => {}} />;
};
