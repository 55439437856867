import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { BookingClientWithPurchasesAndTickets } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { PageHeader } from '~/components/Page/Page.Header';

export const BookingItemHeader = ({
  bookingData,
  onClose,
  onRefresh,
  isFetching,
}: {
  onClose?: () => void;
  onRefresh?: () => void;
  isFetching?: boolean;
  bookingData: BookingClientWithPurchasesAndTickets;
}) => {
  const BookingSubtitle = useCallback(() => {
    return (
      <VStack>
        <Text.Small color="monochrome-mid">{`${bookingData.p_firstName} ${bookingData.p_lastName}`}</Text.Small>
        <Text.Small color="monochrome-mid">{`${bookingData.p_email}`}</Text.Small>
      </VStack>
    );
  }, [bookingData]);

  return (
    <PageHeader
      title={bookingData.booking_ref}
      Subtitle={<BookingSubtitle />}
      withBack={isNativeMobile}
      iconRight={
        onRefresh
          ? {
              color: 'monochrome',
              icon: 'refresh',
              onPress: onRefresh,
              isLoading: isFetching,
            }
          : undefined
      }
      iconLeft={
        onClose
          ? {
              color: 'monochrome',
              icon: 'close',
              onPress: onClose,
            }
          : undefined
      }
    />
  );
};
