import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { ScreenPropCreateVoucher } from '~/navigation/Navigation.types';

import { VoucherItemComponent } from '../Voucher.Item';
import { CreateVoucherList, CreateVoucherListNative } from './CreateVoucherList';

export const CreateVoucherScreen = (props: ScreenPropCreateVoucher) => {
  return (
    <PageContainer>
      <CreateVoucherLayout {...props} />
    </PageContainer>
  );
};

export const CreateVoucherLayout = (props: ScreenPropCreateVoucher) => {
  const voucherList = props.route.params?.voucherList;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <CreateVoucherList
          voucherList={voucherList}
          onPress={(voucherId) => {
            setId(voucherId);
          }}
        />
      ) : (
        <CreateVoucherListNative voucherList={voucherList} />
      )}
      {id ? <VoucherItemComponent voucherId={id} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
