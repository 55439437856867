export type DeepLinkSearchItem = {
  origin: string;
  destination: string;
  date: string;
  indirect: boolean;
  routeKey?: string;
};

const directKey = "DIR" as const;
const indirectKey = "IND" as const;

export const constructSearchQueryV2 = (
  trips: DeepLinkSearchItem[],
  query?: { passengers: number; vehicles: number; currency?: string },
): string => {
  let queryString =
    `t=` +
    trips
      .map((trip) => {
        return `${trip.origin}+${trip.destination}+${trip.date}+${
          trip.indirect ? indirectKey : directKey
        }${trip.routeKey ? "+" + encodeURIComponent(trip.routeKey) : ""}`;
      })
      .join(",");

  if (query) {
    if (query.passengers) {
      queryString += `&p=${query.passengers}`;
    }

    if (query.vehicles) {
      queryString += `&v=${query.vehicles}`;
    }

    if (query.currency) {
      queryString += `&c=${query.currency}`;
    }
  }

  return "?" + queryString;
};
