import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import React from 'react';

import { LiveDateText } from '~/components/DateText';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { useAddNote } from '../Modals/AddNote';

export const SupportRequestNotesHeader = ({
  supportRequestInfo,
}: {
  supportRequestInfo: SupportRequestBackendClient;
}) => {
  const { openModal: openAddNote } = useAddNote({ supportRequestInfo });
  const numberOfNotes = supportRequestInfo?.notes?.length;
  return (
    <>
      <HStack
        px="2.5"
        py="3"
        bg="monochrome-extraLight"
        style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
      >
        <AppText.MiscTag type="heading1">
          {`${translate('supportRequests.notes')} `}
        </AppText.MiscTag>
        <View
          style={[
            {
              width: 30,
              height: 30,
              borderRadius: 30,
              // backgroundColor: color,
              justifyContent: 'center',
              alignItems: 'center',
              paddingHorizontal: 0,
              padding: 0,
              paddingVertical: 0,
            },
          ]}
          bg={numberOfNotes ? 'success-light' : undefined}
        >
          <Text.Small color={numberOfNotes ? 'success-base' : 'monochrome-dark'}>
            {numberOfNotes || '0'}
          </Text.Small>
        </View>
        <View flex={1} />
        <IconButton
          color="monochrome"
          shade="extraDark"
          variant="plain"
          icon="plus-square"
          onPress={() => {
            openAddNote();
          }}
        />
      </HStack>
      <Divider bg="monochrome-light" style={{ maxWidth: isWeb ? 400 : undefined }} />
    </>
  );
};

export const SupportRequestNotesContent = ({
  supportRequestInfo,
}: {
  supportRequestInfo?: SupportRequestBackendClient;
}) => {
  if (supportRequestInfo === undefined) {
    return <></>;
  }
  const { notes } = supportRequestInfo;

  return (
    <VStack
      space="2.5"
      p={notes.length ? '2.5' : undefined}
      style={{ maxWidth: isWeb ? 400 : undefined }}
    >
      {notes.map((supportNote, ix) => {
        const { note, timestamp, adminUser } = supportNote;
        return (
          <VStack key={supportNote.timestamp}>
            <HStack>
              <AppText.Body2Regular color="monochrome-extraDark" flex={1}>
                {`${adminUser ?? 'System'}`}
              </AppText.Body2Regular>
              <LiveDateText
                dateTime={timestamp}
                formatOption="fullDateTime"
                color="monochrome-extraDark"
                type="body2Regular"
              />
            </HStack>
            <Text.Body2Regular>{note}</Text.Body2Regular>
            {ix !== notes.length - 1 && <Divider />}
          </VStack>
        );
      })}
    </VStack>
  );
};
