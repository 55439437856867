import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { BookingListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { PaginatedFlatList } from '~/components/FlatList/FlatList.Paginated';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { BookingListItemComponent } from '../Booking.ListItem';
import { bookingApi } from '../Bookings.api';
import { BookingsListHeader } from './BookingsRecent.ListHeader';

export interface BookingListProps {
  onPress: (booking: BookingListItemResponse) => void;
}

export const BookingListNative = () => {
  const navigate = useAppNavigation();
  return (
    <BookingList
      onPress={(item) => {
        navigate.navigate('Booking-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const BookingList = (props: BookingListProps) => {
  const selectedRef = useRef('');
  const { spacing } = useAppTheme();

  const renderItem = useCallback((booking: BookingListItemResponse) => {
    return <BookingListItemComponent key={booking.id} booking={booking} />;
  }, []);

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <BookingsListHeader />
      <PaginatedFlatList
        listId="BookingsRecent"
        feedback={translate('bookings.emptyBookings')}
        hook={bookingApi.useGetRecentBookingListQuery}
        pageSize={20}
        getId={(item) => item.id}
        showsVerticalScrollIndicator={isWeb}
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },
          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
