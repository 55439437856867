import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { BookingClientWithPurchasesAndTickets } from '@naus-code/naus-admin-types';

import { PressableCard } from '~/components/Card/PressableCard';
import { LiveDateText } from '~/components/DateText';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { usePaymentSession } from '~/components/SupportRequest/Modals/PaymentSession';
import { translate } from '~/utils/translation.utils';

export const PaymentSessionSelectorKey = 'AddModal-PayementSessionSelector';
export const PaymentSelectorModal = ({
  bookingInfo,
}: {
  bookingInfo: BookingClientWithPurchasesAndTickets;
}) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.selectPaymentSession')}
        subtitle={`${bookingInfo.paymentSessions.length} ${translate(
          'bookings.sessions',
        )}`}
        divider
        onPress={() => {
          closeModal(PaymentSessionSelectorKey);
        }}
      />
      <ModalScrollView>
        <VStack p="2.5" space="2.5" flex={1}>
          {bookingInfo.paymentSessions.map((paymentSession, index) => {
            return (
              <PaymentSelectorListItem
                bookingId={bookingInfo._id}
                paymentSession={paymentSession}
                key={index}
              />
            );
          })}
        </VStack>
      </ModalScrollView>
    </Modal.Container>
  );
};

export const usePaymentSelector = () => {
  const { openModal } = useModal();

  return {
    open: (bookingInfo: BookingClientWithPurchasesAndTickets) => {
      openModal(<PaymentSelectorModal bookingInfo={bookingInfo} />, {
        key: PaymentSessionSelectorKey,
        type: 'action-sheet-full',
      });
    },
  };
};

const PaymentSelectorListItem = ({
  bookingId,
  paymentSession,
}: {
  bookingId;
  paymentSession: BookingClientWithPurchasesAndTickets['paymentSessions'][0];
}) => {
  const { open: openPaymentSession } = usePaymentSession();

  return (
    <PressableCard
      h={50}
      onPress={() => {
        // closeModal(PaymentSessionSelectorKey);
        openPaymentSession(paymentSession, bookingId);
      }}
    >
      <HStack p="2.5" justify="space-between">
        <LiveDateText
          align="right"
          dateTime={paymentSession.lastUpdated}
          formatOption="lifetimeDependent&fullDateTime"
          type="extraSmall"
        />
        <Text.Small>{'|'}</Text.Small>
        <Text.ExtraSmall>{paymentSession.type}</Text.ExtraSmall>
        <Text.Small>{'|'}</Text.Small>
        <Text.ExtraSmall>{paymentSession.status.status}</Text.ExtraSmall>
      </HStack>
    </PressableCard>
  );
};
