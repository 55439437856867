import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageContainer } from '~/components/Page/Page.Container';
import { PageHeader } from '~/components/Page/Page.Header';
import { MissingVehicleQueryList } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vehicles/MissingVehicleConfigs.List';
import { useMissingVehicleStore } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vehicles/MissingVehicleConfigs.store';
import { translate } from '~/utils/translation.utils';

import { companyDataConfigApi } from '../companyDataConfigsApi';

export const MissingVehicleConfigsScreen = () => {
  return (
    <PageContainer>
      <PageHeader
        title={translate('companyDataConfigs.missingVehicleConfigs')}
        withBack={isNativeMobile}
        childRight={<ImportButton />}
      />
      <MissingVehicleQueryList />
    </PageContainer>
  );
};

const ImportButton = () => {
  const [importTickets, { isLoading: isImporting }] =
    companyDataConfigApi.useAddMissingVehiclesMutation();
  const selectAll = useMissingVehicleStore((s) => s.selectAll);
  const deselectAll = useMissingVehicleStore((s) => s.deselectAll);
  const selectedTickets = useMissingVehicleStore((s) => s.selectedTickets);
  const { data, isLoading } = companyDataConfigApi.useGetMissingVehiclesQuery();
  return (
    <HStack space="3">
      <Button
        disabled={!data || data.list.length === 0 || isLoading || isImporting}
        variant="outline"
        onPress={() => {
          if (!data) {
            return;
          }

          if (selectedTickets.length === 0) {
            selectAll(data.list);
          } else {
            deselectAll();
          }
        }}
      >
        {selectedTickets.length === 0 ? 'Select all' : 'Deselect all'}
      </Button>
      <Button
        isLoading={isImporting}
        disabled={!data || isLoading || selectedTickets.length === 0}
        onPress={async () => {
          const response = await importTickets({ list: selectedTickets });

          handleResponse({
            response,
          });
        }}
      >
        Import {selectedTickets.length} tickets
      </Button>
    </HStack>
  );
};
