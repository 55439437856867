import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { FormBuilder } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { useEffect, useState } from 'react';

import {
  CancelPurchaseForm,
  getCancelRefundType,
  getServiceFee,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.utils';

export const CancelForm = (props: CancelPurchaseForm & { config: ConfigForData }) => {
  const { formHandlerRef, purchaseCalculation, supportRequest } = props;
  const initialRefundMethod = getCancelRefundType(supportRequest);
  // const [refundMethod, setRefundMethod] = useState(initialRefundMethod);
  const [cancelPanelState, setCancelPanelState] = useState({
    type: 'api',
    actualRefundFromCompany: purchaseCalculation?.purchaseRefund.valueInOriginalCcy || 0,
    expectedRefundFromCompany:
      purchaseCalculation?.purchaseRefund.valueInOriginalCcy || 0,
    customerRefundInBookCcy: purchaseCalculation?.purchaseRefund.valueInBookCcy || 0,
    refundMethod: initialRefundMethod || 'bank',
  });
  // const [cancelType, setCancelType] = useState('api');

  useEffect(() => {
    const tempState = {};
    const unsubscribe = formHandlerRef.current.subscribeToValueChange((id, value) => {
      tempState[id] = value;
    });
    setCancelPanelState(tempState as any);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <FormBuilder
      key={cancelPanelState['refundmethod']}
      data={props.config}
      form={formHandlerRef.current}
      initialValue={{
        type: cancelPanelState['type'],
        actualRefundFromCompany: cancelPanelState['actualRefundFromCompany'],
        expectedRefundFromCompany: cancelPanelState['expectedRefundFromCompany'],
        customerRefundInBookCcy: cancelPanelState['customerRefundInBookCcy'],
        serviceFeeInBookCcy:
          cancelPanelState['serviceFeeInBookCcy'] ||
          getServiceFee(cancelPanelState['refundmethod'], props) ||
          0,
        initialRefundMethod: cancelPanelState['refundMethod'],
      }}
    />
  );
};
