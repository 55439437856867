import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import {
  AppViewProps,
  ScrollView,
  View,
} from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  SupportConversation,
  SupportConversationCustomer,
  SupportConversationHandler,
} from '@naus-code/naus-client-types';
import { LangText } from '@naus-code/naus-server-utils-common-types';
import React, { useState } from 'react';
import { Image, Pressable } from 'react-native';

import { SrcImageProvider } from '~/components/SupportConversation/ChatImage';
import { getBaseUrl } from '~/utils/constants.utils';
import { getFullLang, translate } from '~/utils/translation.utils';

import { LiveDateText } from '../DateText';
import { getInitials } from '../Initials';
import { HandlerMetaPopover, OptionsPopover } from './BubbleUtils/utils';
import { useOpenImage } from './Modals/OpenImage';
import { isHandlerMessage, openSupportLink } from './SupportConversation.utils';

export interface ChatMessageItemProps extends AppViewProps {
  item: SupportConversation;
  supportRequestId: string;
  check: string;
  preview?: boolean;
}

export const SupportConversationBubble = (props: ChatMessageItemProps) => {
  const isHandler = isHandlerMessage(props.item);
  const { item } = props;
  const { timestamp, imgIds } = item;
  const { open: openImage } = useOpenImage();
  const background = isHandler ? 'primary-base' : 'warning-base';

  return (
    <View
      bg={props.preview ? 'white' : background}
      br={20}
      p="2.5"
      style={{ maxWidth: 600, width: '100%' }}
    >
      <VStack>
        <HStack align="center" pb="2">
          <Text.Small color="monochrome-mid">
            {isHandler
              ? translate('supportConversations.agent')
              : translate('supportConversations.customer')}
          </Text.Small>
          {isHandlerMessage(item) && item.handler && (
            <Text.Small color="monochrome-mid">{` - ${item.handler}`}</Text.Small>
          )}
          <View flex={1} />
          <LiveDateText
            dateTime={timestamp}
            formatOption="lifetimeDependent"
            color="monochrome-mid"
            type="small"
          />
          {!props.preview && props.item.type === 'handler' ? (
            <OptionsPopover
              supportRequestId={props.supportRequestId}
              check={props.check}
              item={props.item}
              offsetY={0}
            />
          ) : null}
        </HStack>

        {imgIds?.length && !props.preview ? (
          <HStack space="3" style={{ flexWrap: 'wrap' }}>
            {imgIds.map((imgSrc) => {
              return (
                <SrcImageProvider
                  key={imgSrc}
                  src={`${getBaseUrl()}support-request/get/img/${imgSrc}`}
                >
                  {({ src, loading }) => {
                    if (loading) {
                      return <LoadingIndicator />;
                    }

                    if (isNativeMobile) {
                      return (
                        <Image
                          source={{
                            uri: src,
                          }}
                          style={{
                            height: 400,
                            width: '100%',
                            paddingBottom: 12,
                          }}
                        />
                      );
                    }

                    return (
                      <img
                        onKeyDown={() => {}}
                        onClick={() => {
                          openImage(
                            `${getBaseUrl()}support-request/get/img/${imgSrc}`,
                            props.item,
                          );
                        }}
                        src={src}
                        crossOrigin="anonymous"
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: 'contain',
                          objectPosition: 'center',
                          paddingBottom: 12,
                        }}
                      />
                    );
                  }}
                </SrcImageProvider>
              );
            })}
          </HStack>
        ) : null}

        {/* <Text.Body1Regular numberOfLines={props.preview ? 4 : undefined}>
          {text || (props.preview ? '[image]' : '')}
        </Text.Body1Regular> */}

        <VStack space="1.5">
          <MessageTranslated {...props} />
          <MessageFooter {...props} />
        </VStack>
      </VStack>
    </View>
  );
};

const MessageTranslatedHandler = (
  props: SupportConversationHandler & { preview?: boolean },
) => {
  const [expanded, setExpanded] = useState(false);
  const textTrans: LangText = props.textTrans ?? {};
  const localeKeys = Object.keys(textTrans);

  /**
   * Initially there should only be 1 translation. But it could be something in the future to have more than 1 translation
   */
  const chosenLocale = localeKeys[0] ?? '';
  const translatedText = textTrans[chosenLocale];
  const hasBeenTranslated = !!translatedText;
  const originalText = props.text || (props.preview ? '[image]' : '');
  const topText = hasBeenTranslated ? translatedText : originalText;
  const bottomText = hasBeenTranslated ? originalText : '';

  return (
    <VStack space="1.5">
      <VStack>
        {hasBeenTranslated && chosenLocale ? (
          <HStack justify="flex-end">
            <Text.ExtraSmall color="monochrome-mid">
              {translate('supportRequests.autoTranslate')}
              {' - '}
            </Text.ExtraSmall>
            <Text.ExtraSmall color="monochrome-mid">
              {getFullLang(chosenLocale)}
            </Text.ExtraSmall>
          </HStack>
        ) : null}
        <Text.Body1Regular numberOfLines={props.preview ? 4 : undefined}>
          {topText}
        </Text.Body1Regular>
      </VStack>

      {bottomText ? (
        <Pressable onPress={() => setExpanded(!expanded)}>
          <VStack space="3" mt="3" p="3" bg="white" br={12}>
            <HStack align="center" justify="flex-end" space="1.5">
              {expanded ? null : (
                <Text.ExtraSmall color="monochrome-mid" numberOfLines={1} flex={1}>
                  {bottomText}
                </Text.ExtraSmall>
              )}
              <Text.ExtraSmall color="monochrome-mid">
                {translate('supportRequests.originalText')}
              </Text.ExtraSmall>

              <Icon icon={expanded ? 'chevron-up' : 'chevron-down'} />
            </HStack>
            {expanded ? (
              <Text.Body1Regular color="monochrome-dark">{bottomText}</Text.Body1Regular>
            ) : null}
          </VStack>
        </Pressable>
      ) : null}
    </VStack>
  );
};

const MessageTranslatedCustomer = (
  props: SupportConversationCustomer & { preview?: boolean },
) => {
  const [expanded, setExpanded] = useState(false);
  const englishTranslated = props.textTrans?.['en'];
  const hasBeenTranslated = !!englishTranslated;
  const originalText = props.text || (props.preview ? '[image]' : '');
  const topText = hasBeenTranslated ? englishTranslated : originalText;
  const bottomText = hasBeenTranslated ? originalText : '';
  return (
    <VStack space="1.5">
      <VStack>
        {hasBeenTranslated ? (
          <HStack justify="flex-end">
            <Text.ExtraSmall color="monochrome-mid">
              {translate('supportRequests.autoTranslate')}
              {' - '}
            </Text.ExtraSmall>
            <Text.ExtraSmall color="monochrome-mid">{getFullLang('en')}</Text.ExtraSmall>
          </HStack>
        ) : null}
        <Text.Body1Regular numberOfLines={props.preview ? 4 : undefined}>
          {topText}
        </Text.Body1Regular>
      </VStack>

      {bottomText ? (
        <Pressable onPress={() => setExpanded(!expanded)}>
          <VStack space="3" mt="3" p="3" bg="white" br={12}>
            <HStack align="center" justify="flex-end" space="1.5">
              {expanded ? null : (
                <Text.ExtraSmall color="monochrome-mid" numberOfLines={1} flex={1}>
                  {bottomText}
                </Text.ExtraSmall>
              )}
              <Text.ExtraSmall color="monochrome-mid">
                {translate('supportRequests.originalText')}
              </Text.ExtraSmall>
              {props.detectedLang ? (
                <Text.ExtraSmall color="monochrome-mid">
                  {' - '}
                  {getFullLang(props.detectedLang)}
                </Text.ExtraSmall>
              ) : null}

              <Icon icon={expanded ? 'chevron-up' : 'chevron-down'} />
            </HStack>
            {expanded ? (
              <Text.Body1Regular color="monochrome-dark">{bottomText}</Text.Body1Regular>
            ) : null}
          </VStack>
        </Pressable>
      ) : null}
    </VStack>
  );
};

const MessageTranslated = (props: ChatMessageItemProps) => {
  return isHandlerMessage(props.item)
    ? MessageTranslatedHandler(props.item)
    : MessageTranslatedCustomer(props.item);
};

const MessageFooter = (props: ChatMessageItemProps) => {
  return (
    <VStack>
      {isHandlerMessage(props.item) ? (
        <>
          {props.item.customerViewedTimestamp && (
            <HStack align="baseline">
              <View flex={1} />
              <Text.Body2Regular color="primary-dark">{'seen '}</Text.Body2Regular>
              <LiveDateText
                dateTime={props.item.customerViewedTimestamp}
                formatOption="lifetimeDependent"
                type="small"
                color="monochrome-dark"
              />
            </HStack>
          )}
          <Divider my="2" bg="monochrome-light" />
          <HStack>{renderMetaData(props.item)}</HStack>
        </>
      ) : (
        <VStack mt="2" space="2">
          <Divider />
          <HStack space="2.5">
            {props.item.deviceId || props.item.version ? (
              <Text.ExtraSmall color="monochrome-mid" style={{ maxWidth: 400 }}>
                {[props.item.version, props.item.deviceId.split('_').join(' ')]
                  .filter((v) => !!v)
                  .join(', ')}
              </Text.ExtraSmall>
            ) : null}
            <ViewedByHandlers item={props.item} />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};

const ViewedByHandlers = ({ item }: { item: SupportConversation }) => {
  const handlersViewed = Object.values(item.handlerViewed);
  return (
    <ScrollView horizontal>
      <HStack flex={1} space="2.5">
        {handlersViewed.map((handler, index) => {
          const initials = getInitials(handler.name);
          return (
            <HandlerMetaPopover
              key={index}
              initials={initials}
              handler={handler}
              offsetY={0}
            />
          );
        })}
      </HStack>
    </ScrollView>
  );
};

const renderMetaData = (item: SupportConversationHandler) => {
  return (
    <VStack space="2" flex={1}>
      <HStack align="center">
        <Text.Small color="monochrome-mid">
          {translate('supportConversations.responseType') + ': '}
        </Text.Small>
        <Text.Small color="monochrome-mid">{item.responseType}</Text.Small>
        <HStack mx="2.5" flex={1}>
          <ViewedByHandlers item={item} />
        </HStack>
        <HStack space="0.5">
          {item.comms.notifSent ? (
            <Icon icon="bell" size="small" color="monochrome-mid" />
          ) : null}
          {item.comms.emailSent ? (
            <Icon icon="email" size="small" color="monochrome-mid" />
          ) : null}
          {item.comms.smsSent ? (
            <Text.ExtraSmall color="monochrome-mid">SMS</Text.ExtraSmall>
          ) : null}
          {item.comms.showMessageInComm ? (
            <Icon icon="terms-of-use" size="small" color="monochrome-mid" />
          ) : null}
        </HStack>
      </HStack>
      {renderLinks(item)}
      {item.list ? (
        <VStack space="1">
          {item.list.map((listItem, ix) => {
            return (
              <Text.Small color="monochrome-mid" key={ix}>
                {listItem}
              </Text.Small>
            );
          })}
        </VStack>
      ) : null}
    </VStack>
  );
};

const renderLinks = (item: SupportConversationHandler) => {
  if (!item.links) {
    return null;
  }

  return (
    <VStack space="0.5" bg="monochrome-base" br={16} p="2">
      <Text.Small color="monochrome-mid">{translate('supportConfig.links')}</Text.Small>
      {item.links.map((link, ix) => {
        return (
          <React.Fragment key={ix}>
            <HStack>
              <Text.Body2Regular style={{ flex: 1 }}>{link.text}</Text.Body2Regular>
              <IconButton
                icon="link"
                onPress={async () => {
                  openSupportLink(link);
                }}
              />
            </HStack>
            {item.links!.length - 1 === ix ? null : <Divider />}
          </React.Fragment>
        );
      })}
    </VStack>
  );
};
