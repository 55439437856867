import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { FerryCompanyOperator } from '@naus-code/naus-server-common-types';
import { ScrollView } from 'react-native-gesture-handler';

import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { ScreenPropCompanyOperatorsConfig } from '~/navigation/Navigation.types';
import { translate } from '~/utils/translation.utils';

import { CompanyConfigItemHeader } from '../../CompanyConfig.ItemHeader';
import { companyConfigApi } from '../../CompanyConfigs.api';
import { CompanyOperatorConfig } from './CompanyOperator';
import { useAddCompanyOperatorConfig } from './CompanyOperator.add';

export const CompanyOperatorsConfig = ({ companyid }: { companyid: string }) => {
  const { data: companyConfigGetForm } =
    companyConfigApi.useGetFerryCompanyConfigQuery(companyid);

  const { openModal: openAddCompanyOperator } = useAddCompanyOperatorConfig();

  if (companyConfigGetForm === undefined) {
    return null;
  }

  const allOperators = companyConfigGetForm.config.operatorsConfig;
  return (
    <VStack flex={1}>
      {isNativeMobile ? <CompanyConfigItemHeader id={companyid} /> : <></>}
      <ScrollView>
        <VStack p="2.5" space="2.5">
          <ConfigListSectionTitle>
            <HStack>
              <Text.Small flex={1} color="monochrome-mid">
                {translate('companyConfig.operators')}
              </Text.Small>
              <IconButton
                icon="plus"
                onPress={() => {
                  openAddCompanyOperator(companyid);
                }}
              />
            </HStack>
          </ConfigListSectionTitle>
          {allOperators.map((operator: FerryCompanyOperator, index) => {
            return (
              <CompanyOperatorConfig
                operator={operator}
                key={index}
                companyId={companyid}
              />
            );
          })}
        </VStack>
      </ScrollView>
    </VStack>
  );
};

export const CompanyOperatorsConfigScreen = (props: ScreenPropCompanyOperatorsConfig) => {
  return <CompanyOperatorsConfig companyid={props.route.params.id} />;
};
