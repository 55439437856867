import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  CreateVoucherRequest,
  GetVoucherResponse,
  UpdateVoucherRequest,
  UpdateVoucherResponse,
  VoucherListResponse,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'voucher';
export const voucherApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getVoucherListByCustomerId: builder.query<VoucherListResponse, string>({
      query: (customerId: string) => ({
        url: `${base_url}/get-list-by-customer-id/${customerId}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getVoucherListByCustomerId'],
    }),

    //     //Get
    //     @Get('/get-voucher/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //     getVoucher(@Params('id') id: string): Promise<GetVoucherResponse> {
    //         return this._voucherService.getVoucher(id);
    //     }
    getVoucher: builder.query<GetVoucherResponse, string>({
      query: (voucherId: string) => ({
        url: `${base_url}/get-voucher/${voucherId}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getVoucher'],
    }),

    getVoucherByTinyId: builder.query<GetVoucherResponse, string>({
      query: (tinyId: string) => ({
        url: `${base_url}/get-voucher-by-tiny-id/${tinyId}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getVoucherByTinyId'],
    }),

    //     //Create
    //     @Get('/form-config/create-voucher/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //     createVoucherFormConfig(): Promise<ConfigForData> {
    //         return this._voucherService.getVoucherCreateForm();
    //     }
    createVoucherFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-voucher/`,
        method: 'GET',
      }),
      providesTags: ['*', 'createVoucherFormConfig'],
    }),
    //     @Post('/create-voucher/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //     createVoucher(@Body() body: CreateVoucherRequest): Promise<VoucherListResponse> {
    //         return this._voucherService.createVoucher(body);
    //     }

    createVoucher: builder.mutation<VoucherListResponse, CreateVoucherRequest>({
      query: (data) => ({
        url: `${base_url}/create-voucher/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getVoucher',
        'getVoucherListByCustomerId',
        'getVoucherListByAdminReference',
      ],
    }),

    //     @Get('/get-list-by-admin-reference/:ref', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //     getVoucherListByAdminReference(@Params('ref') ref: string): Promise<VoucherListResponse> {
    //         return this._voucherService.getVoucherListByAdminReference(ref);
    //     }

    getVoucherListByAdminReference: builder.query<VoucherListResponse, string>({
      query: (adminRef: string) => ({
        url: `${base_url}/get-list-by-admin-reference/${adminRef}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getVoucherListByAdminReference'],
    }),
    //     //Update
    //     @Get('/form-config/update-voucher/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //     updateVoucherFormConfig(@Params('id') id: string): Promise<ConfigForData> {
    //         return this._voucherService.getVoucherUpdateForm(id);
    //     }
    updateVoucherFormConfig: builder.query<ConfigForData, string>({
      query: (voucherId) => ({
        url: `${base_url}/form-config/update-voucher/${voucherId}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getVoucherListByAdminReference'],
    }),

    //     @Post('/update-voucher/', [applyRoleValidation(['SYSTEM'])])
    //     updateVoucher(@Body() body: UpdateVoucherRequest): Promise<UpdateVoucherResponse> {
    //         return this._voucherService.updateVoucher(body);
    //     }
    updateVoucher: builder.mutation<UpdateVoucherResponse, UpdateVoucherRequest>({
      query: (data) => ({
        url: `${base_url}/update-voucher/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getVoucher',
        'getVoucherListByCustomerId',
        'getVoucherListByAdminReference',
      ],
    }),
    //     @Get('/void-voucher/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //     voidVoucher(@Params('id') id: string): Promise<UpdateVoucherResponse> {
    //         return this._voucherService.voidVoucher(id);
    //     }
    // }
    voidVoucher: builder.mutation<ConfigForData, string>({
      query: (voucherId) => ({
        url: `${base_url}/void-voucher/${voucherId}`,
        method: 'Get',
      }),
      invalidatesTags: [
        'getVoucher',
        'getVoucherListByCustomerId',
        'getVoucherListByAdminReference',
      ],
    }),

    // @Get('/get-list-by-email/:email', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getVoucherListByEmail(@Params('email') email: string): Promise<VoucherListResponse> {
    //     return this._voucherService.getVoucherListByEmail(email);
    // }
    getVoucherListByEmail: builder.query<VoucherListResponse, string>({
      query: (email: string) => ({
        url: `${base_url}/get-list-by-email/${email}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getVoucherListByEmail'],
    }),
  }),
});
