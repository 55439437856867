import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';

import { translate } from './translation.utils';

export const DATE_SERVER_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_SERVER_FORMAT = `${DATE_SERVER_FORMAT} HH:mm`;
export const TRANSACTION_SERVER_DATE_FORMAT = DATE_TIME_SERVER_FORMAT;
export const TRANSACTION_CLIENT_DATE_FORMAT = 'HH:mm DD/MM/YYYY';

export type DateFormatType =
  | 'lifetimeDependent'
  | 'lifetimeDependent&fullDateTime'
  | 'lifetimeDependent&UnderDay'
  | 'expiryDependent'
  | 'fullDate'
  | 'fullDateTime'
  | 'fullDateTimeSeconds'
  | 'duration'
  | 'fullDate&duration'
  | 'fullExpiryDependent&Date';

export type DateTimeDenomination = 'm' | 'h' | 'd';

export const getEventCreatedDate = (date: string) => {
  return dayjs(date).format('HH:mm DD/MM/YY');
};

export const getNoticeCreatedDate = (date: string) => {
  return dayjs(date).format('HH:mm DD/MM/YY');
};

export function processDateTime(
  dateTime: string, // ISO format
  option: DateFormatType,
  dateTimeDenomination?: DateTimeDenomination,
): string[] {
  switch (option) {
    case 'fullExpiryDependent&Date':
      return processFullUntilDateAndDate(dateTime, dateTimeDenomination || 'h'); //xd xh xm HH:mm DD/MM/YYYY
    case 'expiryDependent':
      return processUntilDate(dateTime, dateTimeDenomination || 'h'); //xd xh xm
    case 'lifetimeDependent&fullDateTime':
      return processFullFromDateAndDate(dateTime, dateTimeDenomination || 'h'); //x ago HH:mm DD/MM/YYYY
    case 'lifetimeDependent':
      return processFromDate(dateTime, dateTimeDenomination || 'h'); //x ago | HH:mm DD/MM/YYYY
    case 'lifetimeDependent&UnderDay':
      return processFromDateUnderDay(dateTime, dateTimeDenomination || 'h'); //x ago < 1day
    case 'fullDate':
      return processFullDate(dateTime); //DD/MM/YYYY
    case 'fullDateTime':
      return processFullDateTime(dateTime); //HH:mm DD/MM/YYYY
    case 'fullDateTimeSeconds':
      return processFullDateTimeSeconds(dateTime); //HH:mm:ss DD/MM/YYYY
    case 'duration':
      return processDuration(dateTime); //- xd xh xm
    case 'fullDate&duration':
      return processFullDataDuration(dateTime); // HH:mm DD/MM/YYYY ( - xd xh xm)
    default:
      return processFullDateTime(dateTime); //HH:mm DD/MM/YYYY
  }
}

function processFullUntilDateAndDate(
  dateTime: string,
  dateTimeDenomination: DateTimeDenomination,
) {
  const diff = Math.abs(dayjs().diff(dateTime, dateTimeDenomination));
  const fullDate = processFullDate(dateTime)[0];
  let diffhrs = '';
  let diffmins = '';

  if (dateTimeDenomination === 'd') {
    if (diff > 1) {
      return [`${fullDate}`];
    }
    diffhrs = ` ${Math.abs(dayjs().diff(dateTime, 'h')) % 24}h`;
    diffmins = ` ${Math.abs(dayjs().diff(dateTime, 'm')) % 60}m`;
  }
  if (dateTimeDenomination === 'h') {
    diffmins = ` ${Math.abs(dayjs().diff(dateTime, 'm')) % 60}m`;
  }
  return [`${diff}${dateTimeDenomination}${diffhrs}${diffmins} | ${fullDate}`];
}

function processFullFromDateAndDate(
  dateTime: string,
  dateTimeDenomination: DateTimeDenomination,
) {
  const diff = Math.abs(dayjs().diff(dateTime, dateTimeDenomination));
  const fullDate = processFullDateTime(dateTime)[0];
  let diffhrs = '';
  let diffmins = '';

  if (dateTimeDenomination === 'd') {
    if (diff > 1) {
      return [`${fullDate}`];
    }
    diffhrs = ` ${Math.abs(dayjs().diff(dateTime, 'h')) % 24}h`;
    diffmins = ` ${Math.abs(dayjs().diff(dateTime, 'm')) % 60}m`;
  }
  if (dateTimeDenomination === 'h') {
    diffmins = `${Math.abs(dayjs().diff(dateTime, 'm')) % 60}m`;
  }
  return [
    `${diff}${dateTimeDenomination}${diffhrs}${diffmins} ${translate(
      'dateUtils.ago',
    )} | ${fullDate}`,
  ];
}

function processUntilDate(dateTime: string, dateTimeDenomination: DateTimeDenomination) {
  const diff = Math.abs(dayjs().diff(dateTime, dateTimeDenomination));
  let diffhrs = '';
  if (dateTimeDenomination === 'd') {
    diffhrs = `${Math.abs(dayjs().diff(dateTime, 'h')) % 24}h`;
  }
  return [`${diff}${dateTimeDenomination} ${diffhrs}`];
}

function processFromDateUnderDay(
  dateTime: string,
  dateTimeDenomination: DateTimeDenomination,
) {
  const diff = Math.abs(dayjs().diff(dateTime, dateTimeDenomination));
  if (dateTimeDenomination === 'm') {
    return [`${diff}${dateTimeDenomination} ${translate('dateUtils.ago')}`];
  }
  if (dateTimeDenomination === 'h') {
    const timeOfEvent = dayjs(dateTime).format('HH:mm');
    return [
      `${timeOfEvent} (${diff}${dateTimeDenomination} ${translate('dateUtils.ago')})`,
    ];
  }
  return [``];
}

function processFromDate(dateTime: string, dateTimeDenomination: DateTimeDenomination) {
  const diff = Math.abs(dayjs().diff(dateTime, dateTimeDenomination));
  if (dateTimeDenomination === 'm') {
    return [`${diff}${dateTimeDenomination} ${translate('dateUtils.ago')}`];
  }
  if (dateTimeDenomination === 'h') {
    const timeOfEvent = dayjs(dateTime).format('HH:mm');
    return [
      `${timeOfEvent} (${diff}${dateTimeDenomination} ${translate('dateUtils.ago')})`,
    ];
  }
  if (dateTimeDenomination === 'd') {
    const timeOfEvent = dayjs(dateTime).format('HH:mm');
    const dateOfEvent = dayjs(dateTime).format('ddd DD-MMM-YY');
    return [`${timeOfEvent} ${dateOfEvent}`];
  }
  return processFullDate(dateTime);
}

function processFullDate(dateTime: string) {
  return [dayjs(dateTime).format('ddd DD-MMM-YY')];
}

function processFullDateTime(dateTime: string) {
  return [dayjs(dateTime).format('HH:mm ddd DD-MMM-YY')];
}
function processFullDateTimeSeconds(dateTime: string) {
  return [dayjs(dateTime).format('HH:mm:ss ddd DD-MMM-YY')];
}

function processDuration(dateTime: string) {
  const preHours = dayjs(dateTime).diff(dayjs(), 'h');
  const isNegative = preHours < 0;
  const hours = Math.abs(preHours);
  const days = Math.floor(hours / 24);
  const hoursRemainder = hours % 24;

  const dayText = days !== 0 ? `${isNegative && days !== 0 ? '-' : ''}${days}d` : '';

  const bothZero = !dayText && !hoursRemainder;

  if (bothZero) {
    return [`${dayjs(dateTime).diff(dayjs(), 'm')}m`];
  }

  return [`${dayText} ${isNegative ? '-' : ''}${hoursRemainder}h`.trim()];
}

function processFullDataDuration(dateTime: string) {
  const duration = processDuration(dateTime);
  const fullDate = processFullDate(dateTime);
  return [`${fullDate} `, `(${duration})`];
}

export function getRefreshTime(timeDiff: number): {
  refreshTime: number;
  denomination: DateTimeDenomination;
} {
  //timeDiff in seconds;
  if (timeDiff < 60 * 60) {
    return { refreshTime: 60 * 1000, denomination: 'm' };
  }
  if (timeDiff < 60 * 60 * 24) {
    return { refreshTime: 60 * 60 * 1000, denomination: 'h' };
  }
  //returns milliseconds
  return { refreshTime: 60 * 60 * 24 * 10000, denomination: 'd' };
}

export function changeRefresh(
  dateTime: string,
  currentRefresh: number,
): {
  needsChangeInRefresh: boolean;
  newRefreshTime: number;
  denomination: DateTimeDenomination;
} {
  const timeDiff = Math.abs(dayjs().diff(dateTime, 'seconds'));
  const { refreshTime: newRefreshTime, denomination } = getRefreshTime(timeDiff);

  const needsChangeInRefresh = currentRefresh != newRefreshTime;
  return { needsChangeInRefresh, newRefreshTime, denomination };
}
