import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';

import { Globals } from '~/components/Globals/Globals';

export const CustomerModalKey = 'CustomerItem';

export const useCustomerModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (customerId: string, supportEmail: string) => {
      openModal(
        <Globals.CustomerItem customerId={customerId} supportEmail={supportEmail} />,
        {
          type: 'action-sheet-full',
          key: CustomerModalKey,
        },
      );
    },
    close: () => {
      closeModal(CustomerModalKey);
    },
  };
};
