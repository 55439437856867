import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropBookingsRecent } from '~/navigation/Navigation.types';

import { BookingItem } from '../BookingItem';
import { BookingList, BookingListNative } from './BookingRecent.List';

export const BookingsRecentScreen = (props: ScreenPropBookingsRecent) => {
  return (
    <PageContainer>
      <BookingsLayout {...props} />
    </PageContainer>
  );
};

export const BookingsLayout = (props: ScreenPropBookingsRecent) => {
  const bookingId = props.route.params?.id;

  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(bookingId);
  }, [bookingId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? <BookingList onPress={(item) => setId(item.id)} /> : <BookingListNative />}
      {id ? <BookingItem bookingId={id} onClose={() => setId(undefined)} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
