import { SelectedValueItem } from '@droplet-tech-code/core-common-types';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { useRef } from 'react';

import { useGetAuthUserRules } from '~/screens/Auth/Auth.Context';
import { translate } from '~/utils/translation.utils';

export const FilterSupportTypeKey = 'AddModal-PurchaseByDate';
export const FilterSupportTypeModal = ({
  onFilter,
  categories,
  uniqueUserIds,
  onlyOwn,
}: {
  onFilter: ({
    category,
    userIds,
    showOwnRequestsOnly,
  }: {
    category: string;
    userIds: Set<string>;
    showOwnRequestsOnly?: boolean;
  }) => void;
  categories: string[];
  uniqueUserIds: [string, string][];
  onlyOwn: boolean;
}) => {
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();
  const canAssignSupportRequests = useGetAuthUserRules('assignSupportRequests');
  const formattedCategory = categories.map((category) => {
    return { value: category };
  });
  const formattedUniqueUserIds = [] as SelectedValueItem[];

  for (const user of uniqueUserIds) {
    const [userId, userName] = user;
    formattedUniqueUserIds.push({
      label: userName,
      value: userId,
    });
  }

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.filterRequests')}
        subtitle={translate('supportRequests.selectSupportType')}
        divider
        onPress={() => {
          closeModal(FilterSupportTypeKey);
        }}
      />
      <Modal.Body>
        <FormBuilder
          data={[
            {
              items: [
                {
                  type: 'single-select',
                  list: formattedCategory,
                  optional: true,
                  label: translate('supportRequests.supportType'),
                  id: 'category',
                },
              ],
            },
            canAssignSupportRequests
              ? {
                  items: [
                    {
                      type: 'multi-select',
                      list: formattedUniqueUserIds,
                      optional: true,
                      label: translate('supportRequests.selectUsers'),
                      id: 'users',
                    },
                  ],
                }
              : {
                  items: [
                    {
                      type: 'toggle',
                      label: translate('supportRequests.showOwnRequestsOnly'),
                      optional: true,
                      initialValue: onlyOwn,
                      id: 'onlyOwn',
                    },
                  ],
                },
          ]}
          form={form.current}
        />
      </Modal.Body>
      <Modal.Footer
        divider
        onConfirm={async () => {
          const { valid, value } = form.current.getValue<{
            category?: string;
            onlyOwn: boolean;
            users?: string[];
          }>();
          if (valid) {
            const selectedUsersSet = new Set<string>();
            value.users?.forEach((userId) => selectedUsersSet.add(userId));
            onFilter({
              category: value.category || '',
              showOwnRequestsOnly: value.onlyOwn,
              userIds: selectedUsersSet,
            });
            closeModal(FilterSupportTypeKey);
          }
        }}
        onCancel={() => {
          closeModal(FilterSupportTypeKey);
        }}
      />
    </Modal.Container>
  );
};

export const useFilterSupportType = () => {
  const { openModal } = useModal();

  return {
    open: (
      onFilter: ({
        category,
        userIds,
        showOwnRequestsOnly,
      }: {
        category: string;
        userIds: Set<string>;
        showOwnRequestsOnly?: boolean;
      }) => void,
      categories: string[],
      onlyOwn: boolean,
      uniqueUserIds: [string, string][],
    ) => {
      openModal(
        <FilterSupportTypeModal
          onFilter={onFilter}
          categories={categories}
          onlyOwn={onlyOwn}
          uniqueUserIds={uniqueUserIds}
        />,
        {
          key: FilterSupportTypeKey,
          type: 'action-sheet',
        },
      );
    },
  };
};
