import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SystemConfigUpdateFerryNotifierConfigRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const UpdateNotifierModalKey = 'UpdateModal-Notifier';
const UpdateNotifierModal = ({ check }: { check: string }) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [updateNotifier, { isLoading: isUpdatingNotifier }] =
    systemConfigApi.useUpdateFerryNotifierConfigMutation();

  return (
    <>
      <Modal.Header
        title={translate('systemConfig.updateNotifier')}
        subtitle={translate('systemConfig.enterNewNotifier')}
        divider
        onPress={() => {
          closeModal(UpdateNotifierModalKey);
        }}
      />
      <Modal.Body p="0">
        <NotifierUpdateForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isUpdatingNotifier}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              SystemConfigUpdateFerryNotifierConfigRequest['trackNotifier']
            >();

          if (valid) {
            const response = await updateNotifier({
              check,
              trackNotifier: value,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(UpdateNotifierModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(UpdateNotifierModalKey);
        }}
      />
    </>
  );
};

export const useUpdateNotifier = ({ check }: { check: string }) => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<UpdateNotifierModal check={check} />, {
        key: UpdateNotifierModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};

const NotifierUpdateForm = ({ form }: { form: FormHandler }) => {
  const { data: notifierUpdateConfig, isLoading: isLoadingNotifierConfig } =
    systemConfigApi.useUpdateFerryNotifierConfigFormConfigQuery();

  return (
    <FormContent isLoading={isLoadingNotifierConfig} form={notifierUpdateConfig} flex={1}>
      {notifierUpdateConfig ? (
        <FormBuilder data={notifierUpdateConfig.config} form={form} />
      ) : null}
    </FormContent>
  );
};
