import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import {
  getScreenHeight,
  getScreenWidth,
} from '@droplet-tech-code/core-elements/module/utils/utils.ui';
import React from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { Pressable } from 'react-native';

export const isHoveredState = [false, false, false, false];

export const menuFloaterState = createGlobalState({
  layout: {
    visible: false,
    layout: undefined as undefined | { x: number; y: number; height?: number },
    children: null as React.ReactNode,
  },
});

export const MenuFloater = () => {
  const [layout, setMenuLayout] = menuFloaterState.useGlobalState('layout');
  const { shading, spacing } = useAppTheme();
  if (!layout.visible || !layout.children) {
    return null;
  }
  const xOffset = -200;

  const isOffScreen =
    layout.layout?.height &&
    layout.layout?.y &&
    getScreenHeight() - layout.layout?.y < layout.layout?.height;

  return (
    <Pressable
      onPress={() => {
        isHoveredState[0] = false;
        isHoveredState[1] = false;
        isHoveredState[2] = false;
        setMenuLayout({
          visible: false,
          layout: { x: -100, y: -100 },
          children: null,
        });
      }}
      style={{
        position: 'absolute',
        left: 109,
        height: '100%',
        width: getScreenWidth() - 300,
      }}
      onHoverIn={() => {
        isHoveredState[0] = true;
      }}
      onHoverOut={() => {
        isHoveredState[0] = false;
        window.setTimeout(() => {
          if (isHoveredState.every((s) => !s)) {
            setMenuLayout({
              visible: false,
              layout: { x: xOffset, y: 0 },
              children: null,
            });
          }
        }, 50);
      }}
    >
      <Pressable
        style={[
          {
            position: 'absolute',
            left: layout.layout?.x,
            top: layout.layout?.y,
            backgroundColor: 'white',
            borderRadius: 16,
            minHeight: 450,
            ...shading.regular,
          },
          !layout.layout?.height && { opacity: 0 },

          isOffScreen && layout.layout?.height && layout.layout?.y
            ? getScreenHeight() - layout.layout.height >= 0
              ? {
                  bottom: layout.layout.y,
                  maxHeight: Math.min(layout.layout.height, layout.layout.height),
                }
              : {
                  top: spacing[3],
                  maxHeight: Math.min(
                    getScreenHeight() - spacing[3] * 2,
                    layout.layout.height,
                  ),
                }
            : undefined,
        ]}
        onLayout={(e) => {
          if (!layout.layout?.height) {
            setMenuLayout({
              ...layout,
              layout: {
                ...layout.layout!,
                height: e.nativeEvent.layout.height,
              },
            });
          }
        }}
        onHoverIn={() => {
          isHoveredState[1] = true;
        }}
        onHoverOut={() => {
          isHoveredState[1] = false;
          window.setTimeout(() => {
            if (
              isHoveredState[0] &&
              !isHoveredState[1] &&
              !isHoveredState[2] &&
              !isHoveredState[3]
            ) {
              setMenuLayout({
                visible: false,
                layout: { x: xOffset, y: 0 },
                children: null,
              });
            }
          }, 50);
        }}
      >
        {layout.children}
      </Pressable>
    </Pressable>
  );
};
