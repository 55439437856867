import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Pressable, PressableProps } from 'react-native';

import { AppText } from '~/components/Text';

export const PressableOption = ({
  state,
  text,
  disabled,
  onPress,
}: {
  state: boolean;
  text: string;
  disabled?: boolean;
  onPress: () => void;
}) => {
  return (
    <Pressable onPress={onPress} disabled={disabled}>
      <HStack space="1">
        <Icon
          color={
            disabled
              ? 'monochrome-light'
              : state
                ? 'monochrome-extraDark'
                : 'monochrome-mid'
          }
          icon={disabled ? 'check-box-off' : state ? 'check-box-on' : 'check-box-off'}
        />
        <AppText.ExtraSmall color={disabled ? 'monochrome-mid' : undefined}>
          {text}
        </AppText.ExtraSmall>
      </HStack>
    </Pressable>
  );
};

export const PressableOptionLabel = ({
  state,
  text,
  disabled,
  onPress,
  style,
}: {
  state: boolean;
  text: string;
  disabled?: boolean;
  onPress: () => void;
  style?: PressableProps['style'];
}) => {
  return (
    <Pressable onPress={onPress} disabled={disabled} style={style}>
      <HStack space="1">
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {text}
        </AppText.ExtraSmall>
        <Icon
          color={
            disabled
              ? 'monochrome-light'
              : state
                ? 'monochrome-extraDark'
                : 'monochrome-mid'
          }
          icon={disabled ? 'close' : state ? 'check-box-on' : 'check-box-off'}
        />
      </HStack>
    </Pressable>
  );
};
