import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';

import { AppText } from '../Text';
import { Card } from './Card';

export const CardWithHeader = (props: CardHeaderProps) => {
  const children = props.children;
  return (
    <Card {...props}>
      <HStack p="2.5" justify="center">
        {props.iconLeftType ? (
          <IconButton
            color="monochrome"
            icon={props.iconLeftType}
            onPress={props.iconLeftOnPress}
            iconStyle={{ justifyContent: 'flex-start' }}
          />
        ) : (
          <></>
        )}
        <View flex={1} justify="center" align="center">
          <AppText.MiscTag type="heading1" style={{ fontSize: 10, lineHeight: 20 }}>
            {`${props.headerText}`}
          </AppText.MiscTag>
        </View>
        {props.iconRightType ? (
          <IconButton
            color="monochrome"
            icon={props.iconRightType}
            onPress={props.iconRightOnPress}
            iconStyle={{ justifyContent: 'flex-start' }}
          />
        ) : (
          <></>
        )}
        {props.rightChild ? (
          <HStack style={{ justifyContent: 'flex-end' }}>{props.rightChild()}</HStack>
        ) : null}
      </HStack>
      <Divider bg="monochrome-extraLight" />
      {children}
    </Card>
  );
};

interface CardHeaderProps extends AppViewProps {
  headerText: string;
  shadingOff?: boolean;
  iconRightOnPress?: () => void;
  iconLeftOnPress?: () => void;
  iconRightType?: IconsId;
  iconLeftType?: IconsId;
  rightChild?: () => JSX.Element;
}
