import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';

import { LiveDateText } from '~/components/DateText';
import { Pill } from '~/components/Pill/Pill';
import { AppText } from '~/components/Text';
import { ToolTip } from '~/components/ToolTip/ToolTip';
import { PurchaseMix } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

export const PurchaseSplitPill = ({ purchase }: { purchase: PurchaseMix }) => {
  if (purchase.split) {
    return (
      <Pill color="secondary">
        <AppText.Small color="secondary">{translate('purchases.split')}</AppText.Small>
      </Pill>
    );
  }

  return null;
};

export const PurchaseOpenPill = ({ purchase }: { purchase: PurchaseMix }) => {
  if (purchase.open) {
    return (
      <Pill color="primary">
        <VStack align="center">
          <AppText.Small color="primary">
            {translate('supportRequests.open')}
          </AppText.Small>
          {purchase.openExpiry && (
            <LiveDateText
              align="right"
              numberOfLines={1}
              color="primary"
              dateTime={purchase.openExpiry}
              formatOption="fullExpiryDependent&Date"
              type="extraSmall"
            />
          )}
        </VStack>
      </Pill>
    );
  }
  return null;
};

export const PurchaseVoidPill = ({ purchase }: { purchase: PurchaseMix }) => {
  const category = purchase.void_category ?? '';

  if (purchase.void) {
    return (
      <ToolTip text={category} bg="monochrome-base" textColor="monochrome-extraDark">
        <Pill color="error">
          <AppText.Small color="error">{translate('supportRequests.void')}</AppText.Small>
        </Pill>
      </ToolTip>
    );
  }

  if (purchase.voidProviderOnly) {
    return (
      <ToolTip text={category} bg="monochrome-base" textColor="monochrome-extraDark">
        <Pill color="error">
          <AppText.Small color="error">
            {translate('supportRequests.voidProvider')}
          </AppText.Small>
        </Pill>
      </ToolTip>
    );
  }

  if (purchase.voidCustomerOnly) {
    return (
      <ToolTip text={category} bg="monochrome-base" textColor="monochrome-extraDark">
        <Pill color="error">
          <AppText.Small color="error">
            {translate('supportRequests.voidCustomer')}
          </AppText.Small>
        </Pill>
      </ToolTip>
    );
  }

  if (purchase.voidPseudo) {
    return (
      <ToolTip text={category} bg="monochrome-base" textColor="monochrome-extraDark">
        <Pill color="error">
          <AppText.Small color="error">
            {translate('supportRequests.voidPseudo')}
          </AppText.Small>
        </Pill>
      </ToolTip>
    );
  }

  return null;
};
