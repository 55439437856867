import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

import { useInvalidateScheduledChangesList } from './ScheduledChanges.List';

export const ScheduledChangeHeader = () => {
  const { invalidate, isLoading } = useInvalidateScheduledChangesList();
  return (
    <PageHeader
      title={translate('scheduledChanges.scheduledChange')}
      withBack={isNativeMobile}
      iconRight={{
        icon: 'refresh',
        isLoading,
        onPress: () => {
          invalidate();
        },
      }}
    />
  );
};
