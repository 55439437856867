import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppViewProps } from '@droplet-tech-code/core-elements/module/ui/View';

export const ConfigListSectionTitle = (props: AppViewProps) => {
  return (
    <VStack justify="flex-end" h={64} space="2.5">
      {props.children}
      <Divider bg="monochrome-dark" />
    </VStack>
  );
};
