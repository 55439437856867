import { PageContainer } from '~/components/Page/Page.Container';
import { ScreenPropSupportRequestById } from '~/navigation/Navigation.types';

import { SupportRequestItem } from '../SupportRequestItem';
import { supportRequestApi } from '../SupportRequests.api';

export const SupportRequestByIdScreen = (props: ScreenPropSupportRequestById) => {
  return (
    <PageContainer>
      <SupportRequestLayout {...props} />
    </PageContainer>
  );
};

export const SupportRequestLayout = (props: ScreenPropSupportRequestById) => {
  const supportRef = props.route.params.ref;
  const { data } = supportRequestApi.useGetSupportRequestByRefQuery(supportRef);
  if (!data) {
    return null;
  }
  const supportId = data.supportRequest._id;
  return <SupportRequestItem supportId={supportId} />;
};
