import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { FerryCompanyOperationsConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const BookingRules = ({
  allOperations,
}: {
  allOperations: FerryCompanyOperationsConfig;
}) => {
  const { bookingRules } = allOperations;
  return (
    <CardWithHeader headerText={translate('companyConfig.bookingRules')}>
      <VStack p="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.minutesBeforeDeparture')}
          </AppText.ExtraSmall>
          <Text.Small>
            {bookingRules.minimumMinutesBeforeDepartureBookingAllowed}
          </Text.Small>
        </HStack>
      </VStack>
    </CardWithHeader>
  );
};
