import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';

import { FlatList } from '~/components/FlatList/FlatList';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { SupportRequestListItemComponent } from '../SupportRequest.ListItem';
import { supportRequestApi } from '../SupportRequests.api';
import { PurchaseSupportRequestsListHeader } from './SupportRequestsPurchase.ListHeader';

export interface SupportRequestListProps {
  onPress: (item: SupportRequestListItemResponse) => void;
  supportRequestId?: string;
  purchaseId: string;
}

export const SupportRequestListNative = ({ purchaseId }: { purchaseId: string }) => {
  const navigate = useAppNavigation();
  return (
    <SupportRequestList
      purchaseId={purchaseId}
      onPress={(item) => {
        navigate.navigate('SupportRequest-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const SupportRequestList = (props: SupportRequestListProps) => {
  const selectedRef = useRef('');

  const { list, Search, isLoading } = useSearchState({
    hook: supportRequestApi.useGetSupportRequestListByPurchaseIdQuery,
    style: {
      height: 47,
    },
    keyToList: 'supportRequests',
    arg: props.purchaseId,
  });

  const renderItem = useCallback(
    (supportRequest: SupportRequestListItemResponse) => {
      return (
        <SupportRequestListItemComponent
          key={supportRequest.id}
          supportRequest={supportRequest}
          onPress={() => {
            props.onPress(supportRequest);
          }}
        />
      );
    },
    [props.supportRequestId],
  );

  return (
    <VStack flex={1}>
      <PurchaseSupportRequestsListHeader
        purchaseId={props.purchaseId}
        numberOfRequests={list.length + ''}
      />
      {Search}
      <Divider />
      <FlatList
        feedback={translate('supportRequests.emptySupportRequest')}
        data={list}
        isLoading={isLoading}
        showsVerticalScrollIndicator={isWeb}
        simple
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
      />
    </VStack>
  );
};
