import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { PurchaseListByDateRequest } from '@naus-code/naus-admin-types';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { ScreenPropPurchasesByDate } from '~/navigation/Navigation.types';

import { PurchaseItemScreenComponent } from '../PurchaseItem';
import { PurchaseListByDate, PurchaseListByDateNative } from './PurchaseByDate.List';

export const PurchasesByDateScreen = (props: ScreenPropPurchasesByDate) => {
  return (
    <PageContainer>
      <PurchasesLayout {...props} />
    </PageContainer>
  );
};

export const PurchasesLayout = (props: ScreenPropPurchasesByDate) => {
  const purchaseId = props.route.params?.id;
  const { startDate, endDate } = props.route.params;
  const companyId = props.route.params?.companyId;
  const vesselId = props.route.params?.vesselId;
  const dateRange: PurchaseListByDateRequest = {
    startDate,
    endDate,
    companyId,
    vesselId,
  };

  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(purchaseId);
  }, [purchaseId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <PurchaseListByDate onPress={(item) => setId(item.id)} dateRange={dateRange} />
      ) : (
        <PurchaseListByDateNative dateRange={dateRange} />
      )}
      {id ? (
        <PurchaseItemScreenComponent purchaseId={id} onClose={() => setId(undefined)} />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
