import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  CreateSupportRequestRequest,
  GetSupportRequestResponse,
  SupportRequestListByCustomerIdRequest,
  SupportRequestListByEmailRequest,
  SupportRequestListFullResponse,
  SupportRequestListRequest,
  SupportRequestListResponse,
  SupportRequestPendingListRequest,
} from '@naus-code/naus-admin-types';
import isEqual from 'lodash/isEqual';
import { io } from 'socket.io-client';

import { rootApi } from '~/store/redux.utils';
import { APP_URLS } from '~/utils/constants.utils';
import { fetchManager } from '~/utils/network.utils';
import { createPause, mergeAndRemoveDuplicates } from '~/utils/utils.helper';

import { playNewMessage } from '../../../utils/utils.notification';

const base_url = 'support-request';
export const supportRequestApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSupportRequestClosed: builder.query<
      SupportRequestListResponse,
      SupportRequestListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-closed-list/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getSupportRequestClosed'],
    }),

    invalidateGetSupportRequestClosed: builder.mutation<
      SupportRequestListResponse,
      SupportRequestListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-closed-list/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            supportRequestApi.util.updateQueryData(
              'getSupportRequestClosed',
              req,
              (draft) => {
                draft.isFinal = data.isFinal;
                draft.list = data.list;
              },
            ),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),

    getSupportRequestPaused: builder.query<
      SupportRequestListResponse,
      SupportRequestListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-paused-list/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getSupportRequestPaused'],
    }),

    invalidateGetSupportRequestPaused: builder.mutation<
      SupportRequestListResponse,
      SupportRequestListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-paused-list/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            supportRequestApi.util.updateQueryData(
              'getSupportRequestPaused',
              req,
              (draft) => {
                draft.isFinal = data.isFinal;
                draft.list = data.list;
              },
            ),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),

    getSupportRequestPending: builder.query<
      SupportRequestListFullResponse,
      SupportRequestPendingListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-pending-list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'getSupportRequestPending'],
    }),
    getSupportRequest: builder.query<GetSupportRequestResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get-support-request/${id}`,
        method: 'GET',
      }),
      async onCacheEntryAdded(supportId, options) {
        // create a websocket connection when the cache subscription starts
        const {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          // dispatch,
        } = options;

        const token = (await fetchManager.getHeaders())['auth-token'];
        const socket = io(APP_URLS.BASE, {
          auth: {
            'auth-token': token,
          },
        });

        socket.on('connect', async () => {
          await createPause(1000);
          socket.emit('support-get', {
            supportId,
          });
        });

        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          // const listener = (event: MessageEvent) => {
          //   const data = JSON.parse(event.data);
          socket.on('support-get', (event: GetSupportRequestResponse) => {
            updateCachedData((draft) => {
              if (
                event.supportRequest.conversation.length >
                  draft.supportRequest.conversation.length &&
                event.supportRequest.conversation[
                  event.supportRequest.conversation.length - 1
                ]?.type === 'customer'
              ) {
                playNewMessage();
              }

              draft.supportRequest = event.supportRequest;
              draft.check = event.check;
            });
          });

          // ws.addEventListener("message", listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        // io().close();
        socket.close();
      },
      keepUnusedDataFor: 0,
      providesTags: ['*', 'getSupportRequest'],
    }),

    getSupportRequestByRef: builder.query<GetSupportRequestResponse, string>({
      query: (ref: string) => ({
        url: `${base_url}/get-support-request-by-ref/${ref}`,
        method: 'GET',
      }),
      providesTags: [],
    }),

    deleteSupportRequest: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `${base_url}/delete-support-request/${id}`,
        method: 'GET',
      }),
      invalidatesTags: [
        'getSupportRequest',
        'getSupportRequestPending',
        'getSupportRequestClosed',
        'getSupportRequestPaused',
        'getSupportRequestListByCustomerId',
        'getSupportRequestListByEmail',
      ],
    }),

    createSupportRequestFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-support-request/`,
        method: 'GET',
      }),
      providesTags: ['*', 'createSupportRequestFormConfig'],
    }),
    createSupportRequest: builder.mutation<
      GetSupportRequestResponse,
      CreateSupportRequestRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-support-request/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createSupportRequest', 'getSupportRequestPending'],
    }),
    getSupportRequestListByCustomerId: builder.query<
      SupportRequestListResponse,
      SupportRequestListByCustomerIdRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-customer-id/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getSupportRequestListByCustomerId'],
    }),

    invalidateSupportRequestListByCustomerId: builder.mutation<
      SupportRequestListResponse,
      SupportRequestListByCustomerIdRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-customer-id/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            supportRequestApi.util.updateQueryData(
              'getSupportRequestListByCustomerId',
              req,
              (draft) => {
                draft.isFinal = data.isFinal;
                draft.list = data.list;
              },
            ),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),

    getSupportRequestListByEmail: builder.query<
      SupportRequestListResponse,
      SupportRequestListByEmailRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-email/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getSupportRequestListByEmail'],
    }),

    invalidateSupportRequestListByEmail: builder.mutation<
      SupportRequestListResponse,
      SupportRequestListByEmailRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-email/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            supportRequestApi.util.updateQueryData(
              'getSupportRequestListByEmail',
              req,
              (draft) => {
                draft.isFinal = data.isFinal;
                draft.list = data.list;
              },
            ),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
    // @Get('/get-list-by-purchase-id/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getSupportRequestListByPurchaseId(
    //     @Params('id') id: string,
    // ): Promise<SupportRequestListFullResponse> {
    //     return this._supportRequestService.getSupportRequestListByPurchaseId(id);
    // }

    getSupportRequestListByPurchaseId: builder.query<
      SupportRequestListFullResponse,
      string
    >({
      query: (id: string) => ({
        url: `${base_url}/get-list-by-purchase-id/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getSupportRequestListByPurchaseId'],
    }),
  }),
});
