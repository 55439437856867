import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

import { useAddVessel } from './Vessels.Add';

export const VesselListHeader = () => {
  const { openModal: openAddModal } = useAddVessel();

  return (
    <PageHeader
      title={translate('vessels.vessels')}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'plus',
        onPress: () => openAddModal(),
      }}
    />
  );
};
