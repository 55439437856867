import { IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { popoverControl } from '@droplet-tech-code/core-elements/module/ui/Popover';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  copyToClipboard,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  BookingPurchaseRetrieveClient,
  PurchaseClientWithMetaWithTickets,
  PurchaseListItemResponse,
} from '@naus-code/naus-admin-types';
import React from 'react';
import { Linking, TouchableOpacity } from 'react-native';

import { useCreateSupport } from '~/components/Customer/Modals/CreateRequest';
import { MenuActionItem } from '~/components/MenuActionItem';
import { useSendETicketModal } from '~/components/Purchases/Modals/SendPurchaseEticket';
import { ModalActionPanelCancelKey } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.utils';
import {
  useClearAll,
  useModifyPanel,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Modify/Modify.Panel';
import { useOpenPanel } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Open/Open.Panel';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { useReservationFromProviderModal } from '~/screens/Support/Purchases/PurchaseTools/Modals/ReservationFromProvider.Modal';
import { translate } from '~/utils/translation.utils';
import { constructSearchQueryV2 } from '~/utils/utils.search';

import { usePurchaseAgencies } from '../PurchaseCommon/Purchase.Agencies';
import { usePurchaseCapabilities } from '../PurchaseCommon/Purchase.Capabilities';
import { usePurchaseCompanyTerms } from '../PurchaseCommon/Purchase.Terms';
import { ActionPanelCancel } from './ActionPanel/ActionPanel.Cancel';
import {
  ModalSplitPurchaseKey,
  SplitPurchase,
} from './ActionPanel/ActionPanel.SplitPurchase';

export const isPurchaseClientWithMetaWithTickets = (
  purchase:
    | PurchaseListItemResponse
    | PurchaseClientWithMetaWithTickets
    | BookingPurchaseRetrieveClient,
): purchase is PurchaseClientWithMetaWithTickets => {
  return !!(purchase as any)?._id;
};

export type MenuItemV1 = {
  id: MenuKey;
  icon: IconsId;
  text: string;
  onPress: () => void;
  disabled?: boolean;
};
export type MenuItemV2 = { item: React.ReactNode; id: MenuKey };

export type PurchaseMenuItem = ReturnType<typeof useGetPurchaseMenuList>[0];

type MenuKey =
  | 'cancelTrip'
  | 'ammendBooking'
  | 'openConvert'
  | 'splitTrip'
  | 'retrieveFromProvider'
  | 'divide'
  | 'markPrinted'
  | 'holdTrip'
  | 'divide'
  | 'capabilities'
  | 'operatorTerms'
  | 'operatorAgencies'
  | 'divide'
  | 'PurchaseId'
  | 'searchTrip'
  | 'sendETicket'
  | 'createSupportRequest';

const menuOrderKey = [
  'sendETicket',
  'cancelTrip',
  'ammendBooking',
  'openConvert',
  'splitTrip',
  'retrieveFromProvider',
  'divider',
  'markPrinted',
  'holdTrip',
  'divider',
  'capabilities',
  'operatorTerms',
  'operatorAgencies',
  'divider',
  'PurchaseId',
  'searchTrip',
  'createSupportRequest',
];

export const useGetPurchaseMenuList = (
  purchase: PurchaseClientWithMetaWithTickets,
  supportId?: string,
): (MenuItemV1 | MenuItemV2)[] => {
  const { openModal, closeModal } = useModal();

  const capabilities = usePurchaseCapabilities();
  const companyTerms = usePurchaseCompanyTerms();
  const agencies = usePurchaseAgencies();
  const modifyPanel = useModifyPanel();
  const sendETicket = useSendETicketModal({
    email: purchase.p_email,
    bookingId: purchase._bookingId,
    purchaseId: purchase._id,
  });
  const openPanel = useOpenPanel();
  const reservationFromProvider = useReservationFromProviderModal();
  const createSupportRequest = useCreateSupport();
  const clearAll = useClearAll();
  const hasPdf = !!purchase.purchasePdfId;

  return [
    {
      id: 'PurchaseId',
      icon: 'copy' as IconsId,
      text: purchase._id,
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        copyToClipboard(purchase._id);
      },
    },

    ...(isWeb
      ? [
          {
            id: 'ammendBooking',
            icon: 'edit-info' as IconsId,
            disabled: !supportId,
            text: translate('purchases.purchaseTool.modifyPurchase'),
            onPress: () => {
              closeModal('openPopover');
              popoverControl.closePopover();
              if (!supportId) {
                return null;
              }
              clearAll();

              modifyPanel.open({
                purchaseId: purchase._id,
                supportId,
                bookingId: purchase._bookingId,
              });
            },
          } as MenuItemV1,
        ]
      : []),

    {
      id: 'openConvert',
      icon: 'convert-open' as IconsId,
      disabled: !supportId,
      text: translate('purchases.purchaseTool.openPurchase'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        if (!supportId) {
          return null;
        }

        openPanel.open({ purchaseId: purchase._id, supportId });
      },
    },

    {
      id: 'capabilities' as const,
      icon: 'thunder-storm' as IconsId,
      // disabled: !supportId,
      text: translate('purchases.purchaseTool.seeCapabilities'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        capabilities.open(purchase._id);
      },
    },

    {
      id: 'cancelTrip' as const,
      icon: 'ticket-cancellation' as IconsId,
      disabled: !supportId,
      text: translate('purchases.purchaseTool.seeCancelPanel'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        if (!supportId) {
          return null;
        }
        openModal(<ActionPanelCancel purchaseId={purchase._id} supportId={supportId} />, {
          key: ModalActionPanelCancelKey,
          type: 'action-sheet-full',
        });
      },
    },

    {
      id: 'operatorTerms' as const,
      icon: 'terms-of-use' as IconsId,
      text: translate('purchases.purchaseTool.seeCompanyTerms'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        companyTerms.open(purchase._id);
      },
    },

    {
      id: 'operatorAgencies' as const,
      icon: 'small-office' as IconsId,
      text: translate('purchases.purchaseTool.seeAgencies'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        agencies.open(purchase._id);
      },
    },

    {
      id: 'splitTrip' as const,
      disabled: !supportId,
      icon: 'track' as IconsId,
      text: translate('purchases.purchaseTool.splitPurchase'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        if (!supportId) {
          return null;
        }
        openModal(<SplitPurchase supportId={supportId} purchaseId={purchase._id} />, {
          key: ModalSplitPurchaseKey,
          type: 'action-sheet-full',
        });
      },
    },

    {
      id: 'retrieveFromProvider' as const,
      icon: 'info' as IconsId,
      text: translate('purchases.purchaseTool.viewReservationFromProvider'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        reservationFromProvider.open(
          purchase.providerReservationCode,
          purchase.provider,
          purchase.ccy,
        );
      },
    },
    {
      id: 'searchTrip' as const,
      disabled: !supportId,
      icon: 'search' as IconsId,
      text: translate('purchases.purchaseTool.searchTrip'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        Linking.openURL(
          `https://openferry.com/app/booking/search${constructSearchQueryV2(
            [
              {
                date: purchase.departureDate,
                destination: purchase.destination,
                origin: purchase.origin,
                indirect: false,
              },
            ],
            {
              passengers: purchase.tickets.filter((t) => t.ticketType === 'Passenger')
                .length,
              vehicles: purchase.tickets.filter((t) => t.ticketType === 'Vehicle').length,
            },
          )}`,
        );
      },
    },

    {
      id: 'holdTrip',
      item: <HoldMenuButton purchase={purchase} />,
    },
    {
      id: 'markPrinted',
      item: <PrintedMenuButton purchase={purchase} />,
    },
    {
      id: 'createSupportRequest' as const,
      icon: 'support-agent' as IconsId,
      text: translate('purchases.purchaseTool.createSupportRequest'),
      onPress: () => {
        closeModal('openPopover');
        popoverControl.closePopover();
        createSupportRequest.open({
          bookingId: purchase._bookingId,
          customerId: purchase._customerId,
          purchaseId: purchase._id,
          ticketIds: purchase._ticketIds,
        });
      },
    },
    ...(hasPdf
      ? [
          {
            id: 'sendETicket',
            icon: 'e-ticket' as IconsId,
            text: translate('purchases.purchaseTool.sendETicket'),
            onPress: () => {
              closeModal('openPopover');
              popoverControl.closePopover();
              sendETicket.open();
            },
          } as MenuItemV1,
        ]
      : []),
  ];
};

const HoldMenuButton = ({
  purchase,
}: {
  purchase: PurchaseClientWithMetaWithTickets;
}) => {
  const [toggleHold, { isLoading }] = purchaseActionApi.useToggleHoldMutation();
  return (
    <TouchableOpacity
      onPress={async () => {
        const response = await toggleHold(purchase._id);
        handleResponse({
          response,
          onSuccess: () => {
            popoverControl.closePopover();
          },
        });
      }}
    >
      <MenuActionItem
        icon="circle-pause"
        text={
          purchase.holdAction
            ? translate('purchases.purchaseTool.unHoldPurchase')
            : translate('purchases.purchaseTool.holdPurchase')
        }
        isLoading={isLoading}
      />
    </TouchableOpacity>
  );
};

const PrintedMenuButton = ({
  purchase,
}: {
  purchase: PurchaseClientWithMetaWithTickets;
}) => {
  const [togglePrinted, { isLoading }] = purchaseActionApi.useTogglePrintedMutation();
  return (
    <TouchableOpacity
      onPress={async () => {
        const response = await togglePrinted(purchase._id);
        handleResponse({
          response,
          onSuccess: () => {
            popoverControl.closePopover();
          },
        });
      }}
    >
      <MenuActionItem
        icon="printer"
        text={
          purchase.printed
            ? translate('purchases.purchaseTool.unSetPrinted')
            : translate('purchases.purchaseTool.setPrinted')
        }
        isLoading={isLoading}
      />
    </TouchableOpacity>
  );
};

export const sortPurchaseMenuList = (menu: (MenuItemV1 | MenuItemV2)[]) => {
  const sorted = [] as (MenuItemV1 | MenuItemV2)[];
  for (const id of menuOrderKey) {
    const item = menu.find((item) => item.id === id);

    if (!item) {
      continue;
    }
    sorted.push(item);
  }
  return sorted;
};
