import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { isRTKErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { Vessel } from '@naus-code/naus-client-types';
import { MutableRefObject } from 'react';

import { getPlugins } from '~/components/Form/Form.Plugins';
import { vesselApi } from '~/screens/Vessels/Vessels.api';

export const VesselEdit = ({
  vesselId,
  vessel,
  formRef,
}: {
  formRef: MutableRefObject<FormHandler>;
  vesselId: string;
  vessel?: Vessel;
}) => {
  const { data, isLoading } = vesselApi.useGetUpdateVesselFormConfigQuery(vesselId);

  const [addImage] = vesselApi.useCreateVesselUploadImageMutation();

  const uploadImage = async (formData: FormData) => {
    const res = await addImage(formData);

    if (isRTKErrorResponse(res)) {
      throw res.error;
    }

    return res.data;
  };

  return (
    <DelayRender isLoading={isLoading}>
      {data ? (
        <FormBuilder
          form={formRef.current}
          data={data}
          initialValue={vessel}
          extensions={getPlugins({ onImageUpload: uploadImage })}
        />
      ) : null}
    </DelayRender>
  );
};
