import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast/Toast';
import {
  isRTKError,
  isRTKErrorResponse,
  RTKErrorResponse,
} from '@droplet-tech-code/core-elements/module/utils/error';
import { FetchManager } from '@droplet-tech-code/core-elements/module/utils/network';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { firebase } from '~/utils/firebase';

import { APP_VERSION, getBaseUrl } from './constants.utils';
import { translate } from './translation.utils';

export const VERIFY_CODE = '2710';

let deviceId = '';
const getDeviceId = async () => {
  if (deviceId) {
    return deviceId;
  }

  const fetchedId = await AsyncStorage.getItem('@deviceId');

  if (fetchedId) {
    deviceId = fetchedId;
    return deviceId;
  }

  const newDeviceId = new Date().valueOf() + '';
  deviceId = newDeviceId;

  AsyncStorage.setItem('@deviceId', newDeviceId);

  return newDeviceId;
};

export const fetchManager = new FetchManager({
  appVersion: APP_VERSION,
  getBaseUrl,
  debug: false,
  defaultErrorMessage: 'Fetch error',
  getDeviceId: async () => getDeviceId(),
  getLang: () => 'en',
  getPushId: () => firebase.getPushId(),
  getUserId: async () => {
    const auth = firebase.getCurrentUser();
    return auth?.uid || '';
  },
  getUserToken: async () => {
    const auth = firebase.getCurrentUser();
    return auth?.getIdToken() || '';
  },
});

export const handleRTKResponse = <T extends { data: X } | RTKErrorResponse, X>({
  response,
  onSuccess,
}: {
  onSuccess: (response: T) => void;
  response: T;
}) => {
  if (isRTKErrorResponse(response)) {
    const error = response.error;
    showToast(
      isRTKError(error) ? error.data.message : translate('feedback.genericError'),
    );
    return;
  }

  onSuccess(response);
};
