import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { paginationUtil } from '~/components/FlatList/FlatList.Paginated';
import { PageHeader } from '~/components/Page/Page.Header';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
// import { useAddSupportRequest } from "~/components/SupportRequest/Modals/AddSupportRequest";
import { translate } from '~/utils/translation.utils';

export const ClosedSupportRequestsListHeader = () => {
  // const { openModal: openAddSupportRequest } = useAddSupportRequest();

  const [invalidate, { isLoading }] =
    supportRequestApi.useInvalidateGetSupportRequestClosedMutation();

  return (
    <PageHeader
      title={translate('supportRequests.closedSupportRequests')}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress: () => {
          const options = paginationUtil.lastReq.SupportRequestsClosed;
          const lastItemId = paginationUtil.lists.SupportRequestsClosed;

          if (options) {
            invalidate({
              ...options,
              paginationRequest: {
                ...options.paginationRequest,
                lastItemId,
                refreshList: !!lastItemId,
              },
            });
          }
        },
        isLoading,
      }}
    />
  );
};
