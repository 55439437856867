import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import React, { useEffect, useRef, useState } from 'react';
import { Animated, Pressable } from 'react-native';
import { Easing } from 'react-native-reanimated';

import { Section } from '~/components/Accordion/Accordion';
import { Card } from '~/components/Card/Card';

export const SupportConfigAccordion = ({
  single,
  sections,
  onPressItem,
  expandedIndex = [],
}: {
  single?: boolean;
  sections: Section[];
  onPressItem?: () => void;
  expandedIndex?: number[];
}) => {
  const headerHeight = 56;
  const bodyHeight = 300;
  const { palette, components, shading } = useAppTheme();

  const [activeIndexes, setActiveIndex] = useState<number[]>(expandedIndex);
  const refHeight = useRef<number | undefined>(isWeb ? undefined : bodyHeight);

  const changeSetActive = (newIndex: number) => {
    if (single) {
      setActiveIndex(activeIndexes[0] === newIndex ? [] : [newIndex]);
      return;
    }

    const doesItExist = activeIndexes.some((ai) => ai === newIndex);
    if (doesItExist) {
      return setActiveIndex(activeIndexes.filter((ai) => ai !== newIndex));
    }

    return setActiveIndex([...activeIndexes, newIndex]);
  };

  return (
    <VStack
      p="0.5"
      flex={1}
      style={[
        { overflow: 'hidden' },
        isNativeMobile && {
          borderWidth: 1,
          borderRadius: components.textInput?.borderRadius ?? 16,
          borderColor: palette.monochrome.extraLight,
        },
      ]}
    >
      {sections.map((section, ix) => {
        const expanded = activeIndexes.some((ai) => ai === ix);
        const prevExpanded = activeIndexes.some((ai) => ai === ix - 1);
        return (
          <React.Fragment key={ix}>
            <Card style={shading.light}>
              <View style={{ height: headerHeight }}>
                <Pressable
                  style={[
                    { flex: 1 },
                    ix > 0 &&
                      prevExpanded && {
                        borderTopWidth: 1,
                        borderColor: palette.monochrome.extraLight,
                      },
                  ]}
                  onPress={() => {
                    if (onPressItem !== undefined) {
                      onPressItem();
                    }
                    changeSetActive(ix);
                  }}
                >
                  {section.header()}
                </Pressable>
              </View>
              <Collapsible refHeight={refHeight} expanded={expanded} section={section} />
            </Card>
          </React.Fragment>
        );
      })}
    </VStack>
  );
};

const Collapsible = ({
  expanded,
  section,
  refHeight,
  offset = 0,
}: {
  offset?: number;
  expanded: boolean;
  section: Section;
  refHeight: React.MutableRefObject<number | undefined>;
  closedInitially?: boolean;
}) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const [height] = useState(new Animated.Value(isWeb ? 0 : refHeight.current ?? 0));

  useEffect(() => {
    Animated.timing(height, {
      toValue: expanded ? refHeight.current ?? 0 : 0,
      duration: 100,
      useNativeDriver: false,
      easing: Easing.ease,
    }).start();
  }, [expanded, mounted, height]);

  const InnerContent = section.content();

  return (
    <Animated.View
      style={[
        expanded && isWeb && { flex: 1 },
        {
          height,
          overflow: 'hidden',
        },
      ]}
      onLayout={(e) => {
        if (refHeight.current === undefined && isWeb) {
          refHeight.current = e.nativeEvent.layout.height - offset;
          setMounted(true);
        }
      }}
    >
      <Divider bg="monochrome-mid" />
      {InnerContent}
    </Animated.View>
  );
};
