import { PageContainer } from "~/components/Page/Page.Container";
import { ScreenPropBookingsByRef } from "~/navigation/Navigation.types";

import { BookingItem } from "../BookingItem";
import { bookingApi } from "../Bookings.api";

export const BookingsByReferenceScreen = (props: ScreenPropBookingsByRef) => {
  return (
    <PageContainer>
      <BookingsLayout {...props} />
    </PageContainer>
  );
};

export const BookingsLayout = (props: ScreenPropBookingsByRef) => {
  const reference = props.route.params.bookingRef;
  const { data } = bookingApi.useGetBookingFromRefQuery(reference);
  const bookingId = data?.booking._id;

  if (!bookingId) {
    return null;
  }

  return <BookingItem bookingId={bookingId} />;
};
