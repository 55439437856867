import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { SupportTypeConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { useAddSupportTypeModal } from '~/components/Config/SupportConfig/Modals/OperationConfig/Modals';
import { useUpdateSupportTypeModal } from '~/components/Config/SupportConfig/Modals/OperationConfig/UpdateSupportTypeModal';
import { Table } from '~/components/Table/Table';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const SupportTypes = ({ supportTypes }: { supportTypes: SupportTypeConfig[] }) => {
  const headers = [
    translate('supportConfig.id'),
    translate('supportConfig.name'),
    translate('supportConfig.english'),
    translate('supportConfig.langs'),
    '',
    translate('supportConfig.hidden'),
  ];
  const tableData = supportTypesToTableData(supportTypes);
  const supportIds = supportTypes.map((supportType) => supportType.id);
  const { open: openAddSupportType } = useAddSupportTypeModal();
  return (
    <CardWithHeader
      headerText={translate('supportConfig.supportTypes')}
      iconRightType="plus"
      iconRightOnPress={openAddSupportType}
    >
      <Table
        headers={headers}
        data={tableData}
        insertColumn={colorColumn({ supportTypes })}
        insertIndex={3}
        rowHeight={35}
        Button={(supportTypeId) => EditSupportTypeButton({ supportTypeId })}
        dataIds={supportIds}
      ></Table>
    </CardWithHeader>
  );
};

const EditSupportTypeButton = ({ supportTypeId }: { supportTypeId: string }) => {
  const { open: openUpdateSupportType } = useUpdateSupportTypeModal();

  return (
    <IconButton
      icon="edit"
      color="monochrome"
      onPress={() =>
        openUpdateSupportType({
          supportTypeId,
        })
      }
    />
  );
};

const supportTypesToTableData = (supportTypes: SupportTypeConfig[]): string[][] => {
  return supportTypes.map((supportType) => {
    const dataRow = [] as string[];
    const supportableLangs = `[${Object.keys(supportType.displayText).join(',')}]`;

    dataRow.push(
      supportType.id,
      supportType.text,
      supportType.displayText['en'],
      supportableLangs,
      '',
      supportType.hidden ? translate('supportConfig.yes') : '-',
    );
    return dataRow;
  });
};

const colorColumn = ({ supportTypes }: { supportTypes: SupportTypeConfig[] }) => {
  return (
    <VStack py="1" space="2.5" key={'color-column'}>
      <View h={35} justify="center" align="center">
        <Text.Small align="center" color="monochrome-dark">
          {translate('supportConfig.color')}
        </Text.Small>
      </View>
      {supportTypes.map((supportType, index) => {
        const { color } = supportType;
        return (
          <View
            p="1"
            br={20}
            align="center"
            justify="center"
            key={index}
            h={35}
            style={{ backgroundColor: color }}
          >
            <AppText.Small align="center">{color}</AppText.Small>
          </View>
        );
      })}
    </VStack>
  );
};
