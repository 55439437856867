import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { PurchaseListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef, useState } from 'react';
import { RefreshControl } from 'react-native';

import { FlatList } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { useChangeParam } from '~/components/Modals/ChangeParamModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { PurchaseListItemComponent } from '../Purchase.ListItem';
import { purchaseApi } from '../Purchases.api';
import { PurchasesPRCListHeader } from './PurchasesByPRC.ListHeader';

export interface PurchaseListProps {
  onPress: (purchase: PurchaseListItemResponse) => void;
  providerReservationCode: string;
}

export const PurchaseListNative = ({
  providerReservationCode,
}: {
  providerReservationCode: string;
}) => {
  const navigate = useAppNavigation();
  return (
    <PurchaseList
      providerReservationCode={providerReservationCode}
      onPress={(item) => {
        navigate.navigate('Purchase-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const PurchaseList = (props: PurchaseListProps) => {
  const selectedRef = useRef('');

  const [providerReservationCode, setProviderReservationCode] = useState(
    props.providerReservationCode,
  );

  const { list, Search, isLoading, isFetching, refetch } = useSearchState({
    hook: purchaseApi.useGetPurchaseListByProviderResCodeQuery,
    style: {
      height: 47,
    },
    keyToList: 'purchases',
    arg: { providerReservationCode },
  });

  const renderItem = useCallback((purchase: PurchaseListItemResponse) => {
    return (
      <PurchaseListItemComponent
        key={purchase.id}
        purchase={purchase}
        onPress={() => {
          selectedRef.current = purchase.id;
          props.onPress(purchase);
        }}
      />
    );
  }, []);
  const { open: openEditParam } = useChangeParam();

  const onEditProviderCode = (param: string) => {
    setProviderReservationCode(param);
  };

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <PurchasesPRCListHeader
        providerCode={providerReservationCode}
        onReset={() => {
          openEditParam({
            initialValue: providerReservationCode,
            title: translate('purchases.purchasesByCompanyTicketNumber'),
            subtitle: translate('purchases.enterTicketNumber'),
            label: translate('purchases.companyTicketNumber'),
            onConfirm: onEditProviderCode,
          });
        }}
        onPress={() => refetch()}
      />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        feedback={translate('purchases.emptyPurchases')}
        isLoading={isLoading}
        simple
        data={list}
        renderItem={renderItem}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
