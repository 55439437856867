import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const CancellationInfo = ({ cancelConfig }: { cancelConfig: CancelConfig }) => {
  return (
    <SwitchStack py="2.5" space="2.5">
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canCancel')}
          </AppText.ExtraSmall>
          <Text.Small> {cancelConfig.canCancel ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canCancelAPI')}
          </AppText.ExtraSmall>
          <Text.Small>{cancelConfig.canCancelViaApi ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canCancelAPIPartial')}
          </AppText.ExtraSmall>
          <Text.Small>
            {cancelConfig.canCancelPartialViaApi ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canCancelAPIAllowCustomer')}
          </AppText.ExtraSmall>
          <Text.Small>
            {cancelConfig.allowCustomerToAutoCancel ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canCancelAPIAllowCustomerPartial')}
          </AppText.ExtraSmall>
          <Text.Small>
            {cancelConfig.allowCustomerToAutoCancelPartial ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canAskFees')}
          </AppText.ExtraSmall>
          <Text.Small>{cancelConfig.doesAskForFeesCancel ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
      </VStack>
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canCancelAfterOpen')}
          </AppText.ExtraSmall>
          <Text.Small>{cancelConfig.canCancelAfterOpen ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.dateUsedForOpen')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 10 }} />
          <Text.Small flex={1} numberOfLines={1} align="right">
            {cancelConfig.canCancelAfterOpenDateUsed}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canCancelAfterModify')}
          </AppText.ExtraSmall>
          <Text.Small>{cancelConfig.canCancelAfterModify ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.dateUsedForModify')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 10 }} />
          <Text.Small flex={1} numberOfLines={1} align="right">
            {cancelConfig.canCancelAfterModifyDateUsed}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.cancelGracePeriod')}
          </AppText.ExtraSmall>
          <Text.Small>{cancelConfig.cancelGraceMinutesAfterBooing}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.overrideCancelationRefundAfterOpen')}
          </AppText.ExtraSmall>
          <Text.Small>{cancelConfig.overrideCancelAfterOpenRefund || 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.requiresPrintToCancel')}
          </AppText.ExtraSmall>
          <Text.Small>
            {cancelConfig.requiresPrintForCancel ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
      </VStack>
    </SwitchStack>
  );
};

interface CancelConfig {
  canCancel: boolean;
  canCancelAfterOpen: boolean;
  canCancelAfterOpenDateUsed: 'CURRENT_DATE' | 'DATE_CONVERTED';
  canCancelAfterModify: boolean;
  canCancelAfterModifyDateUsed: 'CURRENT_DATE' | 'DATE_CONVERTED';
  cancelGraceMinutesAfterBooing: number;
  requiresPrintForCancel: boolean;
  canCancelViaApi: boolean;
  canCancelPartialViaApi: boolean;
  allowCustomerToAutoCancel: boolean;
  allowCustomerToAutoCancelPartial: boolean;
  overrideCancelAfterOpenRefund: false | number;
  doesAskForFeesCancel: boolean;
}
