import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { EventListItemResponse } from '@naus-code/naus-admin-types';

import { PageContainer } from '~/components/Page/Page.Container';
import { PageHeader } from '~/components/Page/Page.Header';
import { EventList } from '~/screens/Events/Events.List';
import { translate } from '~/utils/translation.utils';

import { EventItem, EventItemModalKey } from './Events.Item';

export const EventsScreen = () => {
  const { openModal } = useModal();
  const onPress = (v: EventListItemResponse) => {
    openModal(<EventItem item={v} />, {
      key: EventItemModalKey,
      type: 'action-sheet-full',
    });
  };

  return (
    <PageContainer>
      {isNativeMobile ? <PageHeader title={translate('events.events')} withBack /> : null}
      <EventList onPress={onPress} />
    </PageContainer>
  );
};
