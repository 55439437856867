import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import {
  ConfigDetectorCompanyData,
  FerryCompanyVehicleTicketConfig,
} from '@naus-code/naus-server-common-types';
import { useRef } from 'react';

import { companyDataConfigApi } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/companyDataConfigsApi';
import { useMissingVehicleStore } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vehicles/MissingVehicleConfigs.store';

export type MissingVehicleItem =
  ConfigDetectorCompanyData<FerryCompanyVehicleTicketConfig>;

const ModalKey = 'useMissingVehicleItemModal';

const MissingVehicleItem = ({ item }: { item: MissingVehicleItem }) => {
  const { closeModal } = useModal();
  const { data, isLoading } = companyDataConfigApi.useGetMissingVehiclesQuery();
  const editTicket = useMissingVehicleStore((s) => s.editTicket);
  const formRef = useRef(new FormHandler());
  return (
    <Modal.Container>
      <Modal.Header title={item.key} onPress={() => closeModal(ModalKey)} />
      <Modal.Body flex={1}>
        <DelayRender isLoading={isLoading}>
          {data ? (
            <FormBuilder form={formRef.current} data={data.config} initialValue={item} />
          ) : null}
        </DelayRender>
      </Modal.Body>
      <Modal.Footer
        confirm="Save & close"
        divider
        confirmProps={{
          disabled: isLoading || !data,
        }}
        onConfirm={() => {
          const { valid, value } = formRef.current.getValue<MissingVehicleItem>();
          if (valid && data) {
            editTicket(value);
            closeModal(ModalKey);
          }
        }}
      />
    </Modal.Container>
  );
};

export const useMissingVehicleItemModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (item: MissingVehicleItem) => {
      openModal(<MissingVehicleItem item={item} />, { key: ModalKey });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
