import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { Image, StyleProp, TextStyle } from 'react-native';

import { PressableCard } from '~/components/Card/PressableCard';
import { ToolTip } from '~/components/ToolTip/ToolTip';
import {
  isScheduledChangeResult,
  ScheduledListItemWithId,
} from '~/screens/ScheduledChanges/ChangeList/ScheduleChanges.utils';

export const ScheduleChangePurchasesListItem = ({
  widthDic,
  updateChanges,
  isSelected,
  onSelect,
  onPress,
  notSelectable,
}: {
  isSelected: boolean;
  widthDic: { [key: string]: string };
  updateChanges: ScheduledListItemWithId;
  onSelect: () => void;
  onPress: () => void;
  notSelectable?: boolean;
}) => {
  const changes = isScheduledChangeResult(updateChanges)
    ? updateChanges.details
    : updateChanges;

  const [dateOld, depOld] = changes.oldDepartureDateTime.split(' ');
  const [dateNew, depNew] = changes.newDepartureDateTime
    ? changes.newDepartureDateTime.split(' ')
    : [undefined, undefined];

  const [_arrDate, arrOld] = changes.oldArrivalDateTime.split(' ');
  const [_arrDateNew, arrNew] = changes.newArrivalDateTime
    ? changes.newArrivalDateTime.split(' ')
    : [undefined, undefined];

  const { providerResNumber, resNumber, oldVesselName, origin, destination, email } =
    changes;

  return (
    <PressableCard
      onPress={notSelectable ? onPress : onSelect}
      p="2.5"
      py="8"
      bc={isSelected ? 'primary' : 'monochrome-extraLight'}
    >
      <HStack space="1" style={{ overflow: 'hidden' }}>
        {!notSelectable && (
          <IconButton
            onPress={onSelect}
            iconStyle={{ width: 20, justifyContent: 'flex-start' }}
            color="monochrome"
            icon={isSelected ? 'check-box-on' : 'check-box-off'}
          />
        )}
        <DataHStack
          email={email}
          widthDic={widthDic}
          operatorImgUrl={changes.oldOperatorImg}
          dateDisplay={dateOld}
          dateCompare={dateNew}
          depDisplay={depOld}
          depCompare={depNew}
          arrDisplay={arrOld}
          arrCompare={arrNew}
          providerCode={providerResNumber}
          companyCode={resNumber}
          origin={origin}
          dest={destination}
          vesselNameDisplay={oldVesselName}
          vesselNameCompare={changes.newVesselName}
        />
        {/* <Text.Body1Medium color="monochrome-dark">{"|"}</Text.Body1Medium> */}
        <View px="3">
          <Icon icon="arrow-right" size="large" />
        </View>
        <DataHStack
          email={email}
          widthDic={widthDic}
          operatorImgUrl={changes.oldOperatorImg}
          dateDisplay={dateNew || dateOld}
          dateCompare={dateOld}
          depDisplay={depNew || depOld}
          depCompare={depOld}
          arrDisplay={arrNew || arrOld}
          arrCompare={arrOld}
          providerCode={providerResNumber}
          companyCode={resNumber}
          origin={origin}
          dest={destination}
          vesselNameDisplay={changes.newVesselName || oldVesselName}
          vesselNameCompare={oldVesselName}
          isNew
          isCancelled={changes.cancelled}
        />

        {isScheduledChangeResult(updateChanges) ? (
          <>
            <Divider vertical style={{ marginVertical: -16 }} bg="monochrome-mid" />
            <HStack ml="3" px="3" space="2">
              {updateChanges.sentNotif ? (
                <Icon icon="bell" size="small" color="monochrome-extraDark" />
              ) : null}
              {updateChanges.sentEmail ? (
                <Icon icon="email" size="small" color="monochrome-extraDark" />
              ) : null}
              {updateChanges.sentSms ? (
                <Text.ExtraSmall color="monochrome-extraDark">SMS</Text.ExtraSmall>
              ) : null}

              {updateChanges.error ? (
                <ToolTip
                  iconSize="small"
                  text={updateChanges.error}
                  iconColor="error-mid"
                  bg="error-mid"
                />
              ) : null}
            </HStack>
          </>
        ) : null}

        {!notSelectable && <IconButton icon="menu" onPress={onPress} />}
      </HStack>
    </PressableCard>
  );
};

const DataHStack = ({
  email,
  widthDic,
  operatorImgUrl,
  dateDisplay,
  dateCompare,
  vesselNameDisplay,
  vesselNameCompare,
  providerCode,
  companyCode,
  depDisplay,
  depCompare,
  origin,
  arrDisplay,
  arrCompare,
  dest,
  isNew,
  isCancelled,
}: ScheduleChangeListItemDataProps) => {
  const { palette } = useAppTheme();
  const textStyles: StyleProp<TextStyle> = isCancelled
    ? { textDecorationLine: 'line-through', color: palette.error.mid }
    : undefined;
  return (
    <HStack>
      <HStack w={widthDic['0']}>
        <CompanyInfo
          operatorImgUrl={operatorImgUrl}
          displayVessel={vesselNameDisplay}
          compareVessel={vesselNameCompare}
          companyCode={companyCode}
          providerCode={providerCode}
          isNew={isNew}
          email={email}
        />
      </HStack>
      <HStack w={widthDic['1']}>
        <Text.Small align="center" flex={1} style={textStyles}>
          <ChangedText
            displayText={dateDisplay}
            comparedText={dateCompare}
            isNew={isNew}
            styles={textStyles}
          />
        </Text.Small>
        <Text.Small align="center" color="monochrome-dark">
          {'|'}
        </Text.Small>
      </HStack>
      <HStack w={widthDic['2']}>
        <Text.Small align="center" flex={1} style={textStyles}>
          <ChangedText
            displayText={depDisplay}
            comparedText={depCompare}
            isNew={isNew}
            styles={textStyles}
          />
        </Text.Small>
        <Text.Small align="center" color="monochrome-dark">
          {'|'}
        </Text.Small>
      </HStack>
      <HStack w={widthDic['3']}>
        <Text.Small align="center" flex={1} style={textStyles}>
          {origin}
        </Text.Small>
        <Text.Small align="center" color="monochrome-dark">
          {'|'}
        </Text.Small>
      </HStack>
      <HStack w={widthDic['4']}>
        <Text.Small align="center" flex={1} style={textStyles}>
          <ChangedText
            displayText={arrDisplay}
            comparedText={arrCompare}
            isNew={isNew}
            styles={textStyles}
          />
        </Text.Small>
        <Text.Small align="center" color="monochrome-dark">
          {'|'}
        </Text.Small>
      </HStack>
      <HStack w={widthDic['5']}>
        <Text.Small align="center" flex={1} style={textStyles}>
          {dest}
        </Text.Small>
      </HStack>
    </HStack>
  );
};

const CompanyInfo = ({
  email,
  operatorImgUrl,
  displayVessel,
  compareVessel,
  companyCode,
  providerCode,
  isNew,
}: {
  email: string;
  operatorImgUrl: string;
  compareVessel?: string;
  displayVessel: string;
  companyCode: string;
  providerCode: string;
  isNew?: boolean;
}) => {
  return (
    <HStack space="2.5">
      <CompanyImage imageUrl={operatorImgUrl} />
      <VStack flex={1}>
        <ChangedText
          displayText={displayVessel}
          comparedText={compareVessel}
          alignLeft
          isNew={isNew}
        />
        <Text.ExtraSmall
          numberOfLines={1}
          color="monochrome-mid"
          style={{ overflow: 'hidden' }}
        >{`${providerCode} | ${companyCode}`}</Text.ExtraSmall>
        <Text.ExtraSmall
          numberOfLines={1}
          color="monochrome-mid"
          style={{ overflow: 'hidden' }}
        >
          {email}
        </Text.ExtraSmall>
      </VStack>
    </HStack>
  );
};

const ChangedText = ({
  displayText,
  comparedText,
  isNew,
  alignLeft,
  styles,
}: {
  displayText: string;
  comparedText?: string;
  isNew?: boolean;
  alignLeft?: boolean;
  styles?: StyleProp<TextStyle>;
}) => {
  const hasChanged = comparedText !== undefined && displayText !== comparedText;
  const color = !hasChanged ? 'monochrome-dark' : isNew ? 'success-mid' : 'warning-mid';

  return (
    <Text
      align={alignLeft ? 'left' : 'center'}
      flex={1}
      color={color}
      type={hasChanged ? 'body2Medium' : 'small'}
      style={[hasChanged && { fontWeight: 'bold' }, styles]}
    >
      {displayText}
    </Text>
  );
};

const CompanyImage = ({ imageUrl }: { imageUrl?: string }) => {
  return (
    <View br={8} style={[{ overflow: 'hidden' }]}>
      <Image
        source={{
          uri: imageUrl,
        }}
        style={{ width: 30, height: 30 }}
      />
    </View>
  );
};

interface ScheduleChangeListItemDataProps {
  widthDic: { [key: string]: string };

  email: string;

  operatorImgUrl: string;
  vesselNameDisplay: string;
  vesselNameCompare?: string;
  providerCode: string;
  companyCode: string;
  dateDisplay: string;
  dateCompare?: string;
  depCompare?: string;
  depDisplay: string;
  origin: string;
  arrDisplay: string;
  arrCompare?: string;
  dest: string;
  isNew?: boolean;
  isCancelled?: boolean;
}
