import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { TemplateAnswer } from '@naus-code/naus-server-common-types';
import { useCallback, useState } from 'react';

import { Section } from '~/components/Accordion/Accordion';
import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import {
  useCreateTemplateAnswerCategoryModal,
  useUpdateTemplateAnswerModal,
} from '~/components/Config/SupportConfig/Modals/TemplateAnswersConfig/Modals';
import { SupportConfigAccordion } from '~/components/Config/SupportConfig/SupportConfigAccordion';
import { PageHeader } from '~/components/Page/Page.Header';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { useAddTemplateAnswer } from '../../../../components/Config/SupportConfig/Modals/TemplateAnswersConfig/TemplateAnswer.Add';
import { SystemLang } from '../../config.utils';
import { supportConfigApi } from '../supportConfig.api';

export const SupportConfigTemplateItem = () => {
  const { list: templateAnswers, Search } = useSearchState({
    hook: supportConfigApi.useGetSupportConfigQuery,
    style: {
      height: 47,
    },
    keyToList: 'config.templateAnswerConfig.templateAnswers',
  });
  const { data } = supportConfigApi.useGetSupportConfigQuery();

  const { openModal: openAddTemplateAnswer } = useAddTemplateAnswer();
  const { open: openAddTemplateCategory } = useCreateTemplateAnswerCategoryModal({
    check: data?.check,
  });

  const allCategoriesSet = new Set() as Set<string>;
  templateAnswers.forEach((answer) => {
    allCategoriesSet.add(answer.category);
  });

  const allCategoriesArray = Array.from(allCategoriesSet);

  return (
    <>
      <PageHeader
        title={translate('supportConfig.templateAnswers')}
        withBack={isNativeMobile}
      />
      <VStack space="2.5">
        <HStack py="2.5" space="2.5">
          <ConfigListItem
            text={translate('supportConfig.newCategory')}
            iconRightType="plus"
            onPress={openAddTemplateCategory}
            flex={1}
          />
          <ConfigListItem
            text={translate('supportConfig.newTemplateAnswer')}
            iconRightType="plus"
            onPress={openAddTemplateAnswer}
            flex={1}
          />
        </HStack>
        {Search}
        {allCategoriesArray.map((category: string, index) => {
          const categoryData = templateAnswers.filter(
            (categoryAnswers) => categoryAnswers.category === category,
          );
          return (
            <TemplateAnswerSection key={index} category={category} data={categoryData} />
          );
        })}
      </VStack>
    </>
  );
};

const TemplateAnswerSection = ({
  category,
  data,
}: {
  category: string;
  data: TemplateAnswer[];
}) => {
  return (
    <VStack space="2.5">
      <ConfigListSectionTitle>
        <Text.Small color="monochrome-mid">{`${category} (${data.length})`}</Text.Small>
      </ConfigListSectionTitle>
      <VStack space="2.5">
        {data.map((answerTemplate: TemplateAnswer, index) => {
          return <TemplateAnswerItem key={index} answerTemplate={answerTemplate} />;
        })}
      </VStack>
    </VStack>
  );
};

const answerTemplateSections = (
  answerTemplate: TemplateAnswer,
  rightIconType: IconsId,
): Section => {
  const { title, isDynamic } = answerTemplate;
  return {
    header: () =>
      TemplateHeader({
        headerText: title,
        leftIcon: isDynamic,
        rightIcon: rightIconType,
        templateAnswerId: answerTemplate.id,
      }),
    content: () => TemplateContent({ answerTemplate }),
  };
};

const TemplateHeader = ({
  headerText,
  rightIcon,
  templateAnswerId,
  leftIcon,
}: {
  headerText: string;
  rightIcon: IconsId;
  templateAnswerId: string;
  leftIcon?: boolean;
}) => {
  const { open: openUpdateTemplateAnswerModal } = useUpdateTemplateAnswerModal({
    templateAnswerId,
  });
  return (
    <HStack h={44} p="2.5" justify="center" align="center">
      {leftIcon ? (
        <Icon color="monochrome" icon="lightning-bolt" />
      ) : (
        <Icon color="monochrome" icon="lightning-bolt" iconStyle={{ opacity: 0 }} />
      )}
      <AppText.MiscTag align="center" flex={1} type="small">
        {headerText}
      </AppText.MiscTag>
      <IconButton
        icon="edit"
        color="monochrome"
        onPress={openUpdateTemplateAnswerModal}
      />
      <Icon icon={rightIcon} />
    </HStack>
  );
};

const TemplateContent = ({ answerTemplate }: { answerTemplate: TemplateAnswer }) => {
  const list = answerTemplate.list;
  const links = answerTemplate.links;
  const { text: langText } = answerTemplate;

  const supportedLanguages = Object.keys(langText);
  const listText = list?.map((langText) => langText[SystemLang]).join(',');

  return (
    <VStack p="2.5" space="2.5">
      <HStack>
        <AppText.ExtraSmall flex={1}>
          {translate('supportConfig.list')}
        </AppText.ExtraSmall>
        <Text.Small>{`[${listText || ' '}]`}</Text.Small>
      </HStack>
      <HStack>
        <AppText.ExtraSmall flex={1}>
          {translate('supportConfig.links')}
        </AppText.ExtraSmall>
        <Text.Small>{`[${
          links?.map((link) => link.text[SystemLang]) || ' '
        }]`}</Text.Small>
      </HStack>
      <Divider br={10} thickness={15} bg="primary-extraLight" />
      <AppText.ExtraSmall>{`${translate('supportConfig.text')} [${supportedLanguages.join(
        ',',
      )}]`}</AppText.ExtraSmall>
      <View>
        <Text.Small>{langText[SystemLang]}</Text.Small>
      </View>
    </VStack>
  );
};

const TemplateAnswerItem = ({ answerTemplate }: { answerTemplate: TemplateAnswer }) => {
  const chevronDown = 'chevron-down' as IconsId;
  const chevronUp = 'chevron-up' as IconsId;
  const [rightIconType, setRightIconType] = useState(chevronDown);

  const sections = answerTemplateSections(answerTemplate, rightIconType);

  const toggleIcon = useCallback(() => {
    if (rightIconType === chevronDown) {
      setRightIconType(chevronUp);
    } else {
      setRightIconType(chevronDown);
    }
  }, [rightIconType]);

  return (
    <View>
      <SupportConfigAccordion onPressItem={toggleIcon} single sections={[sections]} />
    </View>
  );
};
