import { OperatingTimeScheduleSpecial } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { Table } from '~/components/Table/Table';
import { translate } from '~/utils/translation.utils';

export const OfficeSpecialDates = ({
  officeSpecialDates,
}: {
  officeSpecialDates: OperatingTimeScheduleSpecial[];
}) => {
  const headers = [
    translate('supportConfig.periodName'),
    translate('supportConfig.startDate'),
    translate('supportConfig.endDate'),
    `${translate('supportConfig.annual')}?`,
    translate('supportConfig.startHour'),
    translate('supportConfig.startMinute'),
    translate('supportConfig.endHour'),
    translate('supportConfig.endMinute'),
  ];

  const dataForTable = officeSpecialDatesToDataTable(officeSpecialDates);
  return (
    <CardWithHeader headerText={translate('supportConfig.officeSpecialDates')}>
      <Table headers={headers} data={dataForTable} rowHeight={5} />
    </CardWithHeader>
  );
};

const officeSpecialDatesToDataTable = (
  officeSpecialDates: OperatingTimeScheduleSpecial[],
): string[][] => {
  return officeSpecialDates.map((specialDate) => {
    const dataRow = [] as string[];

    dataRow.push(
      specialDate.title,
      specialDate.startDate,
      specialDate.endDate,
      specialDate.repeatsAnnually
        ? translate('supportConfig.yes')
        : translate('supportConfig.no'),
    );

    if (specialDate.schedule === undefined) {
      dataRow.push('-', '-', '-', '-');
      return dataRow;
    }
    const { startHour, startMinute, endHour, endMinute } = specialDate.schedule;

    dataRow.push(startHour + '', startMinute + '', endHour + '', endMinute + '');

    return dataRow;
  });
};
