import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  SupportConversationHandler,
  SupportConversationHandlerLink,
  SupportConversationHandlerResponseType,
} from '@naus-code/naus-client-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { SupportConversationInputPanel } from '../InputPanel/Conversation.InputPanel';

export const EditReplyModalKey = 'AddModal-AreaLocation';
const EditReplyModal = ({
  supportRequestId,
  check,
  message,
}: {
  supportRequestId: string;
  check: string;
  message: SupportConversationHandler;
}) => {
  const { closeModal } = useModal();
  const textRef = useRef(new FormHandler());

  const [editRequest, { isLoading }] = supportRequestActionsApi.useEditReplyMutation();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.editReply')}
        divider
        onPress={() => {
          closeModal(EditReplyModalKey);
        }}
      />
      <ModalScrollView>
        <EditReplyForm supportId={supportRequestId} textRef={textRef} message={message} />
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } = textRef.current.getValue<EditFormType>();
          if (valid) {
            const response = await editRequest({
              supportId: supportRequestId,
              check,
              messageId: message.id,
              message: {
                links: value.links,
                text: value.answer,
                responseType: value.responseType,
              },
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(EditReplyModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(EditReplyModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useEditReply = () => {
  const { openModal } = useModal();

  return {
    open: (
      supportRequestId: string,
      check: string,
      message: SupportConversationHandler,
    ) => {
      openModal(
        <EditReplyModal
          supportRequestId={supportRequestId}
          check={check}
          message={message}
        />,
        {
          key: EditReplyModalKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};

const EditReplyForm = ({
  supportId,
  textRef,
  message,
}: {
  supportId: string;
  message: SupportConversationHandler;
  textRef: React.MutableRefObject<FormHandler>;
}) => {
  const advancedRef = useRef(new FormHandler());

  const { data: editFormConfig, isLoading } =
    supportRequestActionsApi.useEditReplyFormConfigQuery();

  const { data: supportRequestInfo } =
    supportRequestApi.useGetSupportRequestQuery(supportId);

  return (
    <FormContent isLoading={isLoading} form={editFormConfig} flex={1}>
      <VStack>
        {editFormConfig ? (
          <FormBuilder
            data={editFormConfig}
            form={textRef.current}
            initialValue={{
              responseType: message.responseType,
              links: message.links,
              list: message.list,
            }}
          />
        ) : null}
        {supportRequestInfo && (
          <SupportConversationInputPanel
            supportId={supportId}
            textRef={textRef}
            advancedRef={advancedRef}
            supportRequestRes={supportRequestInfo}
            isSimple
            initialText={message.text}
          />
        )}
      </VStack>
    </FormContent>
  );
};

interface EditFormType {
  answer: string;
  links?: SupportConversationHandlerLink[];
  responseType: SupportConversationHandlerResponseType;
}
