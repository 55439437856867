import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { SystemOperationConfig } from '@naus-code/naus-server-common-types';
import { useCallback } from 'react';

import { Field } from '~/components/Card/Field';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { TextWithSwitch } from '~/screens/Configs/System/FerryConfig/FerryFormConfig';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { translate } from '~/utils/translation.utils';

import {
  useAddAdminUserGroupModal,
  useAddSystemLanguageModal,
  useAddTaskCategoryModal,
} from './Modals';

const OperationConfigModalKey = 'OperationConfig-ModalKey';
export const useOperationConfigModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: () => {
      return openModal(<OperationConfigModal />, {
        key: OperationConfigModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(OperationConfigModalKey);
    },
  };
};

const OperationConfigModal = () => {
  return (
    <Modal.Container>
      <Modal.Header title={translate('systemConfig.systemOperationConfig')} divider />
      <ModalScrollView>
        <OperationConfigComponent />
      </ModalScrollView>
    </Modal.Container>
  );
};

const OperationConfigComponent = () => {
  const { data, isLoading } = systemConfigApi.useGetOperationConfigQuery();
  const { open: openAddTaskModal } = useAddTaskCategoryModal({
    check: data?.check,
  });
  const { open: openAddAdminUserGroup } = useAddAdminUserGroupModal({
    check: data?.check,
  });
  const { open: openAddSystemLanguage } = useAddSystemLanguageModal();
  if (isLoading) {
    return (
      <View h={100}>
        <LoadingIndicator />
      </View>
    );
  }
  if (!data) {
    return null;
  }

  const { check, config } = data;
  const {
    adminUserSubGroups,
    languagesAccepted,
    maximumVoucherDuration,
    minimumVoucherDuration,
    taskCategories,
  } = config;

  return (
    <VStack p="2.5">
      <HStack space="2.5" flex={1}>
        <Text.Small flex={1}>{translate('systemConfig.taskCategories')}</Text.Small>
        <Text.Small color="monochrome-extraDark">
          {`[${taskCategories.join(' , ')}]`}
        </Text.Small>
        <IconButton icon="plus" color="monochrome" onPress={openAddTaskModal} />
      </HStack>
      <HStack space="2.5" flex={1}>
        <Field
          color="monochrome-extraDark"
          label={translate('systemConfig.languagesAccepted')}
          value={`[${languagesAccepted.map((lang) => lang.code).join(' , ')}]`}
        />
        <IconButton icon="plus" color="monochrome" onPress={openAddSystemLanguage} />
      </HStack>
      <HStack space="2.5" flex={1}>
        <Field
          color="monochrome-extraDark"
          label={translate('systemConfig.adminUserSubGroups')}
          value={`[${adminUserSubGroups.join(' , ')}]`}
        />
        <IconButton icon="plus" color="monochrome" onPress={openAddAdminUserGroup} />
      </HStack>
      <HStack space="2.5" flex={1}>
        <Field
          color="monochrome-extraDark"
          label={translate('systemConfig.maximumVoucherDuration')}
          value={maximumVoucherDuration + ''}
        />
        <IconButton icon="plus" disabled iconStyle={{ opacity: 0 }} />
      </HStack>
      <HStack space="2.5" flex={1}>
        <Field
          color="monochrome-extraDark"
          label={translate('systemConfig.minimumVoucherDuration')}
          value={minimumVoucherDuration + ''}
        />
        <IconButton icon="plus" disabled iconStyle={{ opacity: 0 }} />
      </HStack>
      <HStack space="2.5" flex={1}>
        <View flex={1}>
          <VoucherPaymentToggle config={config} check={check} />
        </View>
        <IconButton icon="plus" disabled iconStyle={{ opacity: 0 }} />
      </HStack>
    </VStack>
  );
};
const VoucherPaymentToggle = ({
  config,
  check,
}: {
  config: SystemOperationConfig;
  check: string;
}) => {
  const isVoucherPaymentEnabled = config.isVoucherPaymentEnabled;
  const [disableTracking] = systemConfigApi.useDisableVoucherPaymentMutation();
  const [enableTracking] = systemConfigApi.useEnableVoucherPaymentMutation();
  const toggleTracking = useCallback(async () => {
    if (isVoucherPaymentEnabled.enabled) {
      await disableTracking({ check });
    } else {
      await enableTracking({ check });
    }
  }, [check]);

  return (
    <TextWithSwitch
      type="small"
      color="black"
      text={translate('systemConfig.isVoucherPaymentEnabled')}
      value={isVoucherPaymentEnabled.enabled}
      onPress={toggleTracking}
    />
  );
};
