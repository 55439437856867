import { isErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { CreateVoucherRequest } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { voucherApi } from '~/screens/Vouchers/voucherApi';
import { translate } from '~/utils/translation.utils';

export const useCreateVoucherModal = () => {
  const navigate = useAppNavigation();
  const { open: openFormModal } = useFormModal();

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } = voucherApi.useCreateVoucherFormConfigQuery();
    return { data: { config }, isLoading };
  }, []);
  const [createVoucher, { isLoading: isCreatingVoucher }] =
    voucherApi.useCreateVoucherMutation();

  const onConfirm = async ({ value }: { value: CreateVoucherRequest['voucher'] }) => {
    const response = await createVoucher({
      voucher: { ...value, value: value.value * 100 },
    });
    if (isErrorResponse(response)) {
      return response;
    }
    const voucherList = response.data;
    navigate.navigate('CreateVoucher', { voucherList });

    return response;
  };

  return {
    open: () => {
      openFormModal({
        modalTitle: translate('voucher.createVoucher'),
        useFormHookHandler,
        loadingAction: isCreatingVoucher,
        onConfirm,
      });
    },
  };
};
