import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { purchaseApi } from '~/screens/Support/Purchases/Purchases.api';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';

export interface OpenPanelProps {
  supportId: string;
  purchaseId: string;
}

export const useGetOpenTicketData = (props: OpenPanelProps) => {
  const [ticketIds] = purchaseActionManager.state.useGlobalState('selectedTickets');

  const { data: purchaseRes, isLoading: isPurchaseLoading } =
    purchaseApi.useGetPurchaseQuery(props.purchaseId);

  const { data: supportRequestRes, isLoading: isSupportLoading } =
    supportRequestApi.useGetSupportRequestQuery(props.supportId);
  const requestOptions = supportRequestRes?.supportRequest.requestOptions;

  const { data: purchaseCalculationRes, isLoading: isPurchaseCalcLoading } =
    purchaseActionApi.useOpenPurchaseCalculationQuery(
      { purchaseId: props.purchaseId, ticketIds },
      { skip: !!requestOptions },
    );

  const validUntil =
    purchaseCalculationRes?.validUntil ??
    (requestOptions?.type === 'open_v2'
      ? (requestOptions?.calculation.validUntil as any as string | undefined)
      : undefined);

  return {
    purchaseRes,
    isPurchaseLoading,
    supportRequestRes,
    isSupportLoading,
    purchaseCalculationRes,
    isPurchaseCalcLoading,
    ticketIds,
    disabled: !validUntil,
    validUntil,
  };
};

export const useSelectOpenHook = (
  type: 'visual' | 'provider-api' | 'provider-external',
): OpenVisualType | OpenProviderType => {
  if (type === 'visual') {
    return purchaseActionApi.useOpenPurchaseVisualMutation;
  }
  return purchaseActionApi.useOpenPurchaseApiMutation;
};

export type OpenVisualType = typeof purchaseActionApi.useOpenPurchaseVisualMutation;
export type OpenProviderType = typeof purchaseActionApi.useOpenPurchaseApiMutation;
