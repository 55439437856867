import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportOperationConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { useEditBufferMinutes } from '~/components/Config/SupportConfig/Modals/OperationConfig/Modals';
import { PageHeader } from '~/components/Page/Page.Header';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { supportConfigApi } from '../supportConfig.api';
import { SupportTypes } from './SupportTypes';

export const SupportConfigOpperationItem = () => {
  const { data } = supportConfigApi.useGetSupportConfigQuery();

  if (data === undefined) {
    return <></>;
  }

  const operationConfig = data.config.operationConfig;
  const { supportTypes } = operationConfig;

  return (
    <>
      <PageHeader
        title={translate('supportConfig.operation')}
        withBack={isNativeMobile}
      />
      <VStack space="2.5" flex={1} p="2.5">
        <OptionsList operationConfig={operationConfig} check={data.check} />
        <SupportTypes supportTypes={supportTypes} />
      </VStack>
    </>
  );
};

const OptionsList = ({
  operationConfig,
  check,
}: {
  operationConfig: SupportOperationConfig;
  check: string;
}) => {
  const { bufferMinutesForRequest } = operationConfig;
  const { open: openEditMinutes } = useEditBufferMinutes({ check });

  return (
    <CardWithHeader headerText={translate('supportConfig.options')}>
      <VStack p="2.5">
        <HStack space="2.5">
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('supportConfig.bufferMinutesForRequest')}
          </AppText.ExtraSmall>
          <Text.Small>{bufferMinutesForRequest}</Text.Small>
          <IconButton icon="edit" color="monochrome" onPress={openEditMinutes} />
        </HStack>
      </VStack>
    </CardWithHeader>
  );
};
