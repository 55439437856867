import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import {
  useModal,
  useModalVerify,
} from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { VoucherClient } from '@naus-code/naus-admin-types';
import { VoucherRedeem } from '@naus-code/naus-client-types';
import { useState } from 'react';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { Field } from '~/components/Card/Field';
import { useCustomerModal } from '~/components/Customer/Customer.Modal';
import { LiveDateText } from '~/components/DateText';
import { PageHeader } from '~/components/Page/Page.Header';
import { Pill } from '~/components/Pill/Pill';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { CopyableText } from '~/components/Text/CopyableText';
import { useUpdateVoucher } from '~/components/Voucher/Modals/UpdateVoucherModal';
import { VoucherModalKey } from '~/components/Voucher/Voucher.utils';
import { useAppNavigation } from '~/hooks/nav.hook';
import { ScreenPropVoucherItem } from '~/navigation/Navigation.types';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

import { usersApi } from '../Users/Users.api';
import { voucherApi } from './voucherApi';

export const VoucherItemScreen = (props: ScreenPropVoucherItem) => {
  const voucherId = props.route.params.id;
  const { data: voucherData, isLoading } = voucherApi.useGetVoucherQuery(voucherId);
  const { open: openUpdateVoucher } = useUpdateVoucher(voucherId);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!voucherData) {
    return null;
  }
  return (
    <VStack>
      <PageHeader
        withBack={isNativeMobile}
        title={voucherData.voucher.tinyId}
        iconRight={{
          icon: 'edit',
          onPress: openUpdateVoucher,
        }}
      />
      <VStack p="2.5">
        <VoucherItem voucher={voucherData.voucher} />
      </VStack>
    </VStack>
  );
};

export const VoucherItemComponent = ({
  voucherId,
  onClose,
}: {
  voucherId: string;
  onClose?: () => void;
}) => {
  const { data: voucherData, isLoading } = voucherApi.useGetVoucherQuery(voucherId);
  const { open: openUpdateVoucher } = useUpdateVoucher(voucherId);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!voucherData) {
    return null;
  }
  return (
    <VStack>
      <PageHeader
        withBack={isNativeMobile}
        title={voucherData.voucher.tinyId}
        iconLeft={{ icon: 'close', onPress: onClose }}
        iconRight={{ icon: 'edit', onPress: openUpdateVoucher }}
      />
      <VStack p="2.5">
        <VoucherItem voucher={voucherData.voucher} />
      </VStack>
    </VStack>
  );
};

export const VoucherItemComponentByTinyId = ({
  tinyId,
  onClose,
}: {
  tinyId: string;
  onClose?: () => void;
}) => {
  const { data: voucherData, isLoading } = voucherApi.useGetVoucherByTinyIdQuery(tinyId);
  const { open: openUpdateVoucher } = useUpdateVoucher(voucherData?.voucher._id || '');

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!voucherData) {
    return null;
  }
  return (
    <>
      <VStack>
        <PageHeader
          withBack={isNativeMobile}
          title={voucherData.voucher.tinyId}
          iconLeft={{ icon: 'close', onPress: onClose }}
          iconRight={{ icon: 'edit', onPress: openUpdateVoucher }}
        />
        <VStack p="2.5">
          <VoucherItem voucher={voucherData.voucher} />
        </VStack>
      </VStack>
    </>
  );
};

const VoidVoucher = ({ voucherId, tinyId }: { tinyId: string; voucherId: string }) => {
  const [voidVoucher, { isLoading: isVoiding }] = voucherApi.useVoidVoucherMutation();
  const { verifyAction, cancelVerify } = useModalVerify();

  const onVoidVoucher = () => {
    verifyAction({
      title: `${translate('voucher.voucherVoid')}: ${tinyId}`,
      description: translate('voucher.voidDescription'),
    }).then((verify) => {
      if (verify) {
        voidVoucher(voucherId);
      }
      cancelVerify();
    });
  };

  return (
    <IconButton
      icon="bin"
      size="medium"
      color={'error'}
      onPress={onVoidVoucher}
      isLoading={isVoiding}
    />
  );
};

export const VoucherItem = ({
  voucher,
  customerEmail,
}: {
  voucher: VoucherClient;
  customerEmail?: string;
}) => {
  const { closeModal } = useModal();
  const { open: openCustomerModal } = useCustomerModal();

  const {
    tinyId,
    dateExpire,
    remainingValue,
    redeems,
    ccy,
    void: isVoid,
    canBeRefunded,
    minimumBookingValue,
    dateCreated,
    customerExpireEmailTimestamp,
  } = voucher;
  const onPressCustomerEmail = (email: string, customerId: string) => {
    closeModal(VoucherModalKey);
    openCustomerModal(customerId, email);
  };
  return (
    <CardWithHeader
      w={isNativeMobile ? undefined : 600}
      headerText={`${translate('voucher.voucherInfo')}`}
      rightChild={() => {
        return (
          <>
            {isVoid ? (
              <HStack>
                {isWeb && <View flex={1} />}
                <Pill color="error">
                  <Text.Small color="error">
                    {translate('supportRequests.void')}
                  </Text.Small>
                </Pill>
              </HStack>
            ) : (
              <VoidVoucher tinyId={tinyId} voucherId={voucher._id} />
            )}
          </>
        );
      }}
    >
      <SwitchStack p="2.5" space={isNativeMobile ? '1' : '2.5'}>
        <VStack flex={isWeb ? 1 : undefined} space="2">
          <HStack space="6" justify="space-between">
            <Text.Small color="monochrome-extraDark" type="small">
              {translate('voucher.tinyId')}
            </Text.Small>
            <Text.Body2Regular>{tinyId}</Text.Body2Regular>
          </HStack>
          <HStack space="6" justify="space-between">
            <Text.Small color="monochrome-extraDark" type="small">
              {translate('voucher.expiryDate')}
            </Text.Small>
            <LiveDateText
              align="right"
              numberOfLines={1}
              dateTime={dateExpire}
              formatOption="fullDateTime"
              type="small"
            />
          </HStack>
          {voucher.adminReference && (
            <Field
              labelColor="monochrome-extraDark"
              label={translate('voucher.reference')}
              value={voucher.adminReference}
            />
          )}
          {voucher.adminId && (
            <HStack justify="space-between">
              <Text.Small color="monochrome-extraDark" flex={1}>
                {translate('voucher.adminId')}
              </Text.Small>
              <AdminUserDetails adminId={voucher.adminId} />
              <CopyableText
                numberOfLines={1}
                style={{ width: 80, overflow: 'hidden' }}
                value={voucher.adminId}
              />
            </HStack>
          )}
        </VStack>
        <VStack flex={isWeb ? 1 : undefined} space="2">
          <HStack justify="space-between">
            <Text.Small color="monochrome-extraDark" flex={1}>
              {translate('voucher.remainingValue')}
            </Text.Small>
            <Text.Small color="success-mid">
              {getPriceWithSymbol(remainingValue, ccy)}
            </Text.Small>
          </HStack>
          <HStack align="flex-start" pt="2">
            <Text.Small color="monochrome-extraDark" flex={1}>
              {translate('voucher.redeemedValue')}
            </Text.Small>
            <Redeems redeems={redeems} customerId={voucher.customerId} />
          </HStack>
          <Field
            labelColor="monochrome-extraDark"
            label={translate('voucher.dateCreated')}
            isDate
            value={dateCreated}
          />
          <Field
            labelColor="monochrome-extraDark"
            label={translate('voucher.canBeRefunded')}
            value={canBeRefunded ? 'Yes' : 'No'}
          />
          {minimumBookingValue && (
            <Field
              labelColor="monochrome-extraDark"
              label={translate('voucher.minimumBookingValue')}
              value={minimumBookingValue ? 'Yes' : 'No'}
            />
          )}
          {customerExpireEmailTimestamp && (
            <Field
              labelColor="monochrome-extraDark"
              label={translate('voucher.customerExpireEmailTimestamp')}
              isDate
              value={customerExpireEmailTimestamp ? 'Yes' : 'No'}
            />
          )}

          {voucher.p_email && (
            <HStack space="6" justify="space-between">
              <Text.Small color="monochrome-extraDark" type="small">
                {translate('voucher.email')}
              </Text.Small>
              <Text.Small
                color="primary"
                numberOfLines={1}
                onPress={() => {
                  if (!voucher.customerId) {
                    return null;
                  }
                  onPressCustomerEmail(
                    voucher.p_email || customerEmail || '',
                    voucher.customerId,
                  );
                }}
              >
                {voucher.p_email}
              </Text.Small>
            </HStack>
          )}
          {voucher.customerId && (
            <HStack space="6" justify="space-between">
              <Text.Small numberOfLines={1} color="monochrome-extraDark" type="small">
                {translate('voucher.customerId')}
              </Text.Small>
              <Text.Small
                numberOfLines={1}
                flex={1}
                color="primary"
                onPress={() => {
                  if (!voucher.customerId) {
                    return null;
                  }
                  onPressCustomerEmail(
                    voucher.p_email || customerEmail || '',
                    voucher.customerId,
                  );
                }}
              >
                {voucher.customerId}
              </Text.Small>
            </HStack>
          )}
        </VStack>
      </SwitchStack>
    </CardWithHeader>
  );
};

const AdminUserDetails = ({ adminId }: { adminId: string }) => {
  const { data: userData, isLoading } = usersApi.useGetUserItemQuery(adminId);
  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!userData) {
    return <Text.Small>{'-'}</Text.Small>;
  }
  return (
    <Text.Small>
      {`${userData.adminUser.name} ${userData.adminUser.lastName} - `}
    </Text.Small>
  );
};

const Redeems = ({
  redeems,
  customerId,
  customerEmail,
}: {
  redeems: VoucherRedeem[];
  customerId?: string;
  customerEmail?: string;
}) => {
  const totalRedeemed = redeems.reduce(
    (total: number, redeem: VoucherRedeem) => total + redeem.value,
    0,
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const { navigate } = useAppNavigation();
  if (redeems.length === 0) {
    return <Text.Small color="monochrome-mid">{0}</Text.Small>;
  }
  const currency = redeems[0].ccy;

  return (
    <>
      {isExpanded ? (
        <VStack>
          <Text.Small align="right" color="primary" onPress={() => setIsExpanded(false)}>
            {' '}
            {getPriceWithSymbol(totalRedeemed, currency)}
          </Text.Small>
          {redeems.map((redeem, index) => {
            const { timestamp, value, ccy } = redeem;
            return (
              <HStack key={index} justify="space-between">
                {redeem.bookingId && (
                  <AppText.ExtraSmall
                    onPress={() => {
                      if (customerId) {
                        navigate('BookingsByCustomerId', {
                          customerId: customerId,
                          id: redeem.bookingId,
                        });
                      }
                      if (customerEmail) {
                        navigate('BookingsByEmail', {
                          email: customerEmail,
                          id: redeem.bookingId,
                        });
                      }
                    }}
                  >
                    {redeem.bookingId}
                  </AppText.ExtraSmall>
                )}
                <AppText.ExtraSmall>
                  {getPriceWithSymbol(value, ccy) + '  '}
                </AppText.ExtraSmall>
                <LiveDateText
                  align="right"
                  numberOfLines={1}
                  dateTime={timestamp}
                  formatOption="fullDate"
                  type="extraSmall"
                />
              </HStack>
            );
          })}
        </VStack>
      ) : (
        <Text.Small color="primary" onPress={() => setIsExpanded(true)}>
          {getPriceWithSymbol(totalRedeemed, currency)}
        </Text.Small>
      )}
    </>
  );
};
