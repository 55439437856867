import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { EventListItemResponse } from '@naus-code/naus-admin-types';

import { getEventCreatedDate } from '~/utils/date.utils';

export const EventsListItemComponent = ({ event }: { event: EventListItemResponse }) => {
  const { title, source, date, description } = event;

  return (
    <HStack flex={1}>
      <VStack flex={1}>
        <Text.Body2Medium>{title}</Text.Body2Medium>
        {description ? (
          <Text.Body2Regular color="monochrome-extraDark">
            {description}
          </Text.Body2Regular>
        ) : null}
      </VStack>

      <VStack>
        <Text.Body2Regular align="right" color="monochrome-extraDark">
          {getEventCreatedDate(date)}
        </Text.Body2Regular>

        <Text.Small color="monochrome-mid" align="right">
          {source}
        </Text.Small>
      </VStack>
    </HStack>
  );
};
