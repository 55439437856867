import { AppThemeProvider } from '@droplet-tech-code/core-elements/module/theme';
import { SafeAreaView } from '@droplet-tech-code/core-elements/module/ui/SafeAreaView';
import { ToastProvider } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { initiateApp } from '@droplet-tech-code/core-elements/module/utils/utils.imports';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { Buffer } from 'buffer';
import { StatusBar } from 'expo-status-bar';
import { StatusBar as RNStatusBar } from 'react-native';
import { Provider as StoreProvider } from 'react-redux';

// import { NavigationRouter } from "./navigation/Navigation.Root";
import { MenuFloater } from '~/navigation/Navigation.MenuFloat';
import { NavigationRouter } from '~/navigation/Navigation.Root';

import { DevModeBar } from './components/DevMode/DevMode';
import { LoadingPage } from './components/Page/Page.utils';
import { useAppSetup } from './hooks/app.hook';
import { AuthProvider } from './screens/Auth/Auth.Context';
import { storeOnlyForProviderUse } from './store/configureStore';
import { getAppTheme } from './utils/theme.utils';

window.Buffer = Buffer;

initiateApp();

export const Main = () => {
  return (
    <StoreProvider store={storeOnlyForProviderUse}>
      <AppThemeProvider theme={getAppTheme()}>
        <SafeAreaView
          style={[isNativeMobile && { paddingTop: RNStatusBar.currentHeight }]}
        >
          <AuthProvider>
            <View bg="white" style={{ width: '100%', height: '100%' }}>
              <DevModeBar />
              <ActionSheetProvider>
                <Content />
              </ActionSheetProvider>
            </View>
          </AuthProvider>
        </SafeAreaView>
        <StatusBar style="dark" backgroundColor="#FFFFFF" />
        <MenuFloater />
        <ToastProvider />
      </AppThemeProvider>
    </StoreProvider>
  );
};

const Content = () => {
  const loaded = useAppSetup();

  if (!loaded) {
    return <LoadingPage />;
  }

  return <NavigationRouter />;
};
