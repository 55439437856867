import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { OfficeOperatingConfig } from '@naus-code/naus-server-common-types';
import { useCallback } from 'react';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { LinkText } from '~/components/LinkText';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { supportConfigApi } from '../supportConfig.api';

export const OfficeInfo = ({
  officeInfo,
  check,
}: {
  check: string;
  officeInfo: OfficeOperatingConfig;
}) => {
  const { officeId, officeName, country, timezone, countries, isActive } = officeInfo;

  const [setDefault] = supportConfigApi.useSetDefaultOfficeMutation();

  const onPressDefault = useCallback(() => {
    setDefault({ check, officeId });
  }, []);

  return (
    <>
      <CardWithHeader
        headerText={translate('supportConfig.officeInfo')}
        iconLeftType={isActive ? 'disconnected' : undefined}
      >
        <SwitchStack p="2.5" space={isNativeMobile ? '1' : undefined}>
          <VStack
            p={isNativeMobile ? undefined : '2.5'}
            space="1"
            style={isWeb && { width: '50%' }}
          >
            <HStack>
              <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
                {translate('supportConfig.id')}
              </AppText.ExtraSmall>
              <Text.Small align="right">{`${officeId}`}</Text.Small>
            </HStack>
            <HStack>
              <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
                {translate('supportConfig.name')}
              </AppText.ExtraSmall>
              <Text.Small align="right">{officeName}</Text.Small>
            </HStack>
            <HStack>
              <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
                {translate('supportConfig.country')}
              </AppText.ExtraSmall>
              <Text.Small align="right">{country}</Text.Small>
            </HStack>
          </VStack>
          <VStack
            p={isNativeMobile ? undefined : '2.5'}
            space="1"
            style={isWeb && { width: '50%' }}
          >
            <HStack>
              <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
                {translate('supportConfig.timezone')}
              </AppText.ExtraSmall>
              <Text.Small align="right">{`${timezone}`}</Text.Small>
            </HStack>
            <HStack>
              <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
                {translate('supportConfig.servingCountries')}
              </AppText.ExtraSmall>
              <Text.Small align="right">{`[${countries}]`}</Text.Small>
            </HStack>
            <HStack>
              <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
                {translate('supportConfig.default')}
              </AppText.ExtraSmall>
              <View align="flex-end">
                <LinkText onPress={onPressDefault}>{`${translate(
                  'supportConfig.setAsDefault',
                )}`}</LinkText>
              </View>
            </HStack>
          </VStack>
        </SwitchStack>
      </CardWithHeader>
    </>
  );
};
