import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Pressable, TextStyle } from 'react-native';

export interface LinkTextProps {
  onPress: () => void;
  children: string;
  isLoading?: boolean;
  textStyle?: TextStyle;
}

export const LinkText = ({ onPress, isLoading, children, textStyle }: LinkTextProps) => {
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Pressable onPress={onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <HStack space="1">
            <Text.Small
              color="primary-mid"
              style={[
                hovered && {
                  textDecorationLine: 'underline',
                },
                textStyle,
              ]}
            >
              {children}
            </Text.Small>
          </HStack>
        );
      }}
    </Pressable>
  );
};
