import { create } from 'zustand';

export interface PreviewImageItem {
  token: string;
  base64: string;
  id: string;
}

export interface SupportConversationState {
  advanced: boolean;
  reset: boolean;

  sendMessageQuote: boolean;
  sendSms: boolean;
  sendEmail: boolean;
  sendNotification: boolean;
  isUploadingImage: boolean;

  previewImages: PreviewImageItem[];
}

export interface SupportConversationStore extends SupportConversationState {
  setPreviewImages: (_: PreviewImageItem[]) => void;
  setState: (_: Partial<SupportConversationState>) => void;
}

export const useSupportConversationStore = create<SupportConversationStore>((set) => ({
  previewImages: [],

  advanced: false,
  reset: false,

  sendMessageQuote: false,
  sendEmail: false,
  sendNotification: false,
  sendSms: false,

  isUploadingImage: false,

  setState: (partialState) => {
    set(partialState);
  },

  setPreviewImages: (previewImages) =>
    set({
      previewImages,
    }),
}));
