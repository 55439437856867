import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  isMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { PurchaseListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { PaginatedFlatList } from '~/components/FlatList/FlatList.Paginated';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { PurchaseListItemComponent } from '../Purchase.ListItem';
import { purchaseApi } from '../Purchases.api';
import { PurchasesUpcomingListHeader } from './PurchasesUpcoming.ListHeader';

export interface PurchaseListProps {
  onPress: (purchase: PurchaseListItemResponse) => void;
  providerReservationCode: string;
}

export const PurchaseListNative = ({
  providerReservationCode,
}: {
  providerReservationCode: string;
}) => {
  const navigate = useAppNavigation();
  return (
    <PurchaseList
      providerReservationCode={providerReservationCode}
      onPress={(item) => {
        navigate.navigate('Purchase-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const PurchaseList = (props: PurchaseListProps) => {
  const selectedRef = useRef('');

  const renderItem = useCallback((purchase: PurchaseListItemResponse) => {
    return (
      <PurchaseListItemComponent
        key={purchase.id}
        purchase={purchase}
        onPress={() => {
          selectedRef.current = purchase.id;
          props.onPress(purchase);
        }}
      />
    );
  }, []);

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <PurchasesUpcomingListHeader />
      <Divider />
      <PaginatedFlatList
        listId="PurchasesUpcoming"
        feedback={translate('purchases.emptyPurchases')}
        hook={purchaseApi.useGetPurchasesUpcomingQuery}
        pageSize={20}
        simple
        getId={(item) => item.id}
        showsVerticalScrollIndicator={isWeb}
        renderItem={renderItem}
        contentContainerStyle={[
          isMobile && [{ paddingHorizontal: 10 }],
          [{ paddingTop: 10 }],
        ]}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
