import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { SearchRouteMetaTrip, SupportRequestOptions } from '@naus-code/naus-client-types';

import { searchApi } from '~/components/Search/Search.api';
import { TripBody, TripHeader, TripNotice } from '~/components/Search/Search.List';
import { getTripInnerNotice } from '~/components/Search/Search.utils';
import { translate } from '~/utils/translation.utils';

export const ModifySnapshot = ({ options }: { options?: SupportRequestOptions }) => {
  if (!options) {
    return null;
  }

  if (options?.type != 'modify_v2' && options?.type != 'modify_v3') {
    return null;
  }

  const snapshot = options.snapshot;

  if (snapshot) {
    return <RequestOptionsSnapshot snapshot={snapshot} />;
  }
  const { ccy, trips } = options.routePrice;

  const tripKeys = trips.map((t) => t.key) ?? [];
  const { data: tripDetails } = searchApi.useGetTripDetailsQuery({
    tripKeys,
    ccy,
  });

  if (!tripDetails) {
    return null;
  }

  const originalRequestedTrip = tripDetails?.trips[0];

  return (
    <HStack space="1.5" align="center">
      <VStack space="1" flex={1}>
        <Text.Small color="monochrome-dark">
          {translate('supportRequests.requestOptions.customerRequested')}
        </Text.Small>
        <ReqOptionsTripInfo route={originalRequestedTrip} />
      </VStack>
    </HStack>
  );
};

const RequestOptionsSnapshot = ({ snapshot }: { snapshot: SearchRouteMetaTrip }) => {
  return (
    <HStack space="1.5" align="center">
      <VStack space="1" flex={1}>
        <Text.Small color="monochrome-dark">
          {translate('supportRequests.requestOptions.customerRequested')}
        </Text.Small>
        <ReqOptionsTripInfo route={snapshot} />
      </VStack>
    </HStack>
  );
};

const ReqOptionsTripInfo = ({ route }: { route: SearchRouteMetaTrip }) => {
  const notice = getTripInnerNotice(route);
  return (
    <View p="3" bc="monochrome-light" b={1} br={12}>
      <VStack space="1">
        {notice && <TripNotice notice={notice} />}
        <TripHeader trip={route} />
        <TripBody trip={route} compact />
      </VStack>
    </View>
  );
};
