import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  AppViewProps,
  ScrollView,
  View,
} from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { getScreenWidth } from '@droplet-tech-code/core-elements/module/utils/utils.ui';

export const PageContainer = (props: AppViewProps) => {
  if (isWeb) {
    return (
      <ScrollView
        horizontal
        bg="white"
        flex={1}
        {...props}
        // style={{
        //   maxWidth: getScreenWidth(),
        // }}
        // contentContainerStyle={{
        //   maxWidth: getScreenWidth(),
        // }}
      />
    );
  }

  return <View flex={1} {...props} />;
};

export const ScreenTwoPageLayout = ({
  children,
  width = 400,
}: {
  children: [JSX.Element, JSX.Element];
  width?: number | string;
}) => {
  if (isWeb) {
    return (
      <HStack
        flex={1}
        bg="monochrome-base"
        style={{ alignItems: 'flex-start', maxWidth: getScreenWidth() }}
      >
        <View style={{ maxWidth: width, width, height: '100%' }}>{children[0]}</View>
        <Divider vertical />
        <View
          flex={1}
          style={{ height: '100%', overflow: 'hidden' }}
          bg="monochrome-base"
        >
          {children[1]}
        </View>
      </HStack>
    );
  }
  return children[0];
};
