import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';

import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { translate } from '~/utils/translation.utils';

import { ModalScrollView } from '../Modals/Modal.utils';
import { SupportRequestAccordionComponent } from './SupportRequestAccordion';

export const useSupportDetailsModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (supportRequestId: string) => {
      openModal(<SupportDetailsModal supportRequestId={supportRequestId} />, {
        key: SupportDetailsModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(SupportDetailsModalKey);
    },
  };
};

export const SupportDetailsModalKey = 'SupportDetails-ModalKey';

export const SupportDetailsModal = ({
  supportRequestId,
}: {
  supportRequestId: string;
}) => {
  const { closeModal } = useModal();
  const { data, isLoading } =
    supportRequestApi.useGetSupportRequestQuery(supportRequestId);
  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (!data) {
    return null;
  }
  const { check, supportRequest } = data;

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.details')}
        onPress={() => closeModal(SupportDetailsModalKey)}
      />
      <ModalScrollView>
        <SupportRequestAccordionComponent data={{ check, supportRequest }} />
      </ModalScrollView>
    </Modal.Container>
  );
};
