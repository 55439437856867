import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  AreaListResponse,
  CreateAreaRequest,
  GetAreaResponse,
} from '@naus-code/naus-admin-types';
import { ImageUploadResponse } from '@naus-code/naus-client-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'data/area';

export const areaLocationApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAreaLocationList: builder.query<AreaListResponse, void>({
      query: () => ({
        url: `${base_url}/get-list/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getAreaLocationList'],
    }),
    getAreaLocation: builder.query<GetAreaResponse, string>({
      query: (id) => ({
        url: `${base_url}/get-area/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getAreaLocation'],
    }),
    getAreaLocationFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-area/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getAreaLocationFormConfig'],
    }),

    getCreateAreaLocationFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-area/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCreateAreaLocationFormConfig'],
    }),

    createAreaLocation: builder.mutation<GetAreaResponse, CreateAreaRequest>({
      query: (data) => ({
        url: `${base_url}/create-area/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createAreaLocation', 'getAreaLocationList'],
    }),

    createAreaLocationImageUpload: builder.mutation<ImageUploadResponse, FormData>({
      query: (data: FormData) => ({
        url: `${base_url}/img-upload/create-area/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createAreaLocation', 'createAreaLocationImageUpload'],
    }),
  }),
});
