import { useState } from "react";

import {
  PageContainer,
  ScreenTwoPageLayout,
} from "~/components/Page/Page.Container";

import { ScheduledChangeItem } from "./ScheduledChanges.Item";
import { ScheduledChangesList } from "./ScheduledChanges.List";

export const ScheduledChangeScreen = () => {
  return (
    <PageContainer>
      <ScheduledChanges />
    </PageContainer>
  );
};

export const ScheduledChanges = () => {
  const [scheduledChangeId, setScheduledChangeId] = useState<
    string | undefined
  >();

  return (
    <ScreenTwoPageLayout>
      <ScheduledChangesList
        onPress={(id: string) => {
          setScheduledChangeId(id);
        }}
      />
      <>
        {scheduledChangeId ? (
          <ScheduledChangeItem
            scheduledChangeId={scheduledChangeId}
            unsetScheduledChangeId={() => setScheduledChangeId(undefined)}
          />
        ) : null}
      </>
    </ScreenTwoPageLayout>
  );
};
