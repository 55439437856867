import {
  GetAdminUserResponse,
  SupportRequestListItemResponse,
} from '@naus-code/naus-admin-types';

export const filterAdminuser = (
  item: SupportRequestListItemResponse,
  onlyOwn: boolean,
  userData?: GetAdminUserResponse,
) => {
  if (!onlyOwn) {
    //filter is off
    return true;
  }
  return onlyOwn && item.adminId === userData?.adminUser._id;
};

export const filterCategory = (
  item: SupportRequestListItemResponse,
  category: string,
) => {
  if (category === '') {
    //filter is off
    return true;
  }
  return item.title === category;
};

export const filterUserId = (
  item: SupportRequestListItemResponse,
  userIds: Set<string>,
) => {
  if (userIds.size == 0) {
    //filter is off
    return true;
  }
  if (!item.adminId) {
    //filter is on and not interested in unassigned
    return false;
  }
  return userIds.has(item.adminId);
};
