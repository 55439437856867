import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { TransactionClient } from '@naus-code/naus-admin-types';

import { JSONView } from '~/components/JsonView';
import { translate } from '~/utils/translation.utils';

const ModalKey = 'useViewTransaction';

interface ViewTransactionProps {
  transaction: TransactionClient;
}

const ViewTransactionModal = (props: ViewTransactionProps) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <Modal.Header
        title={props.transaction.bookingId || props.transaction._id}
        onPress={() => {
          closeModal(ModalKey);
        }}
      />

      <Modal.Body scroll p="3">
        <VStack space="3" b={1} br={12} bc="monochrome-light" p="3">
          <Text.Body2Regular color="monochrome-mid">
            {translate('transactions.rawData')}
          </Text.Body2Regular>
          <JSONView data={props.transaction} />
        </VStack>
      </Modal.Body>
    </Modal.Container>
  );
};

export const useViewTransaction = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (props: ViewTransactionProps) => {
      openModal(<ViewTransactionModal {...props} />, { key: ModalKey });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
