import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

export const usePurchaseByPRCInputModal = () => {
  const navigation = useAppNavigation();

  return useCreateManualFormModal({
    title: translate('purchases.purchasesForPRC'),
    subtitle: translate('purchases.enterPRC'),
    formData: [
      {
        items: [
          {
            type: 'text',
            label: translate('purchases.providerReservationCode'),
            id: 'providerReservationCode',
          },
        ],
      },
    ],
    onConfirm: (value) => {
      navigation.navigate('PurchasesByPRC', value);
    },
  });
};
