import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropPurchasesByCTN } from '~/navigation/Navigation.types';

import { PurchaseItemScreenComponent } from '../PurchaseItem';
import { PurchaseList, PurchaseListNative } from './PurchaseByCTN.List';

export const PurchasesByCTNScreen = (props: ScreenPropPurchasesByCTN) => {
  return (
    <PageContainer>
      <PurchasesLayout {...props} />
    </PageContainer>
  );
};

export const PurchasesLayout = (props: ScreenPropPurchasesByCTN) => {
  const spurchaseId = props.route.params?.id;
  const companyTicketNumber = props.route.params.companyTicketNumber;
  const companyId = props.route.params?.companyId;

  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(spurchaseId);
  }, [spurchaseId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <PurchaseList
          onPress={(item) => setId(item.id)}
          companyTicketNumber={companyTicketNumber}
          companyId={companyId}
        />
      ) : (
        <PurchaseListNative companyTicketNumber={companyTicketNumber} />
      )}
      {id ? (
        <PurchaseItemScreenComponent
          key={id}
          purchaseId={id}
          onClose={() => setId(undefined)}
        />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
