import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { createContext } from 'react';

import { isDev } from '~/utils/constants.utils';

export const DevModeContext = createContext({
  // devMode: false,
  // allowDevToggle: false,
  // setDevMode: (_: boolean) => {},
  // setAllowDevToggle: (_: boolean) => {},
});

export const DevModeProvider = ({ children }: { children: React.ReactNode }) => {
  // const [devMode, setDevMode] = useState(false);
  // const [allowDevToggle, setAllowDevToggle] = useState(isWeb);

  // useEffect(() => {
  //   (async () => {
  //     const devMode = await firebase.getDevMode();
  //     setDevMode(devMode);
  //   })();
  // }, []);

  return (
    <DevModeContext.Provider
      value={
        {
          // devMode,
          // allowDevToggle,
          // setAllowDevToggle: (v) => {
          //   setAllowDevToggle(v);
          // },
          // setDevMode: (v) => {
          //   setDevMode(v);
          // },
        }
      }
    >
      {children}
    </DevModeContext.Provider>
  );
};

// export const useDevMode = () => {
//   return useContext(DevModeContext);
// };

// export const DevModeToggle = () => {
//   const { setDevMode, devMode } = useDevMode();
//   const dispatch = useDispatch();

//   return (
//     <HStack space="2.5">
//       <Text.Small color={devMode ? 'secondary-mid' : 'monochrome-mid'}>
//         Dev mode
//       </Text.Small>
//       <Toggle
//         value={devMode}
//         color="secondary-light"
//         onChange={async (value) => {
//           // await AsyncStorage.setItem(firebase.firebaseStorageKey, String(v)).catch(
//           //   console.error,
//           // );

//           dispatch(rootApi.util.resetApiState());
//           setDevMode(value);
//         }}
//       />
//     </HStack>
//   );
// };

export const DevModeBar = () => {
  // const { devMode } = useDevMode();

  if (!isDev) {
    return null;
  }

  return <View style={{ width: '100%', height: 6 }} bg="secondary-light" />;
};
