import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Icon, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { TemplateAnswer } from '@naus-code/naus-server-common-types';
import { Pressable } from 'react-native';

import { getLangText } from '~/utils/translation.utils';

export const TemplateAnswerListItem = ({
  supportLang,
  answer,
  addAnswer,
}: {
  supportLang: string;
  answer: TemplateAnswer;
  addAnswer: () => void;
}) => {
  const langText = getLangText(supportLang, answer.text);
  const isEditable = langText ? langText.indexOf('{{') >= 0 : false;
  const iconRightType: IconsId | undefined = answer.isDynamic
    ? 'lightning-bolt'
    : isEditable
      ? 'edit-info'
      : undefined; //lightning-bolt ; edit-info

  return (
    <Pressable onPress={addAnswer}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <View
            br={10}
            p="2.5"
            b={1}
            bc={hovered ? 'primary-extraLight' : 'monochrome-extraLight'}
            bg={hovered ? 'primary-base' : 'white'}
            style={[
              { overflow: 'hidden' },
              // shading.light
            ]}
          >
            <HStack>
              <Text.Small bold flex={1} color="monochrome-extraDark">
                {answer.title}
              </Text.Small>
              {iconRightType ? (
                <Icon icon={iconRightType} size="medium" color="black" />
              ) : null}
            </HStack>
            <Text.Small color="monochrome-dark">{answer.text[supportLang]}</Text.Small>
          </View>
        );
      }}
    </Pressable>
  );
};
