import { useAppSelector } from "~/store/redux.utils";

export const useGetSelectedTrip = (tripKey: string) => {
  return useAppSelector((s) =>
    s.basket.selectTickets.trips.find((t) => t.tripKey === tripKey),
  );
};

export const usePassengerEdit = (passengerId: string) => {
  return useAppSelector((s) =>
    s.basket.passengersAndVehiclesEdit.passengers.find(
      (p) => p.passengerId === passengerId,
    ),
  );
};

export const useVehicleEdit = (vehicleId: string) => {
  return useAppSelector((s) =>
    s.basket.passengersAndVehiclesEdit.vehicles.find(
      (v) => v.vehicleId === vehicleId,
    ),
  );
};

export const useGetSelectedPassenger = (
  tripKey: string,
  passengerId: string,
) => {
  const trip = useGetSelectedTrip(tripKey);
  return trip?.passengers.find((p) => p.passengerId === passengerId);
};
