import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';

import { CancelForm } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.Form';
import { CancelPurchaseForm } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.utils';
import { DATE_TIME_SERVER_FORMAT } from '~/utils/date.utils';

const config = (cancelDateInit: string): ConfigForData => {
  return [
    {
      items: [
        {
          id: 'type',
          type: 'single-select',
          list: [
            {
              value: 'external',
              label: 'Externally',
            },
            {
              value: 'api',
              label: 'API',
            },
          ],
          label: 'Provider cancellation type',
          feedbackOnlyWithError: true,
        },
      ],
    },
    {
      items: [
        {
          id: 'expectedRefundFromCompany',
          type: 'number',
          label: 'Expected refund from company',
          feedbackOnlyWithError: true,
          multipleOf: 100,
        },
      ],
    },
    {
      items: [
        {
          id: 'blank',
          type: 'blank',
          conditions: [
            {
              values: [{ listenTo: 'type', value: 'external' }],
              formGroup: {
                id: 'actualRefundFromCompany',
                type: 'number',
                label: 'Actual refund from company',
                feedbackOnlyWithError: true,
                multipleOf: 100,
              },
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          id: 'customerRefundInBookCcy',
          type: 'number',
          label: 'Expected refund for customer (in customer’s currency)',
          feedbackOnlyWithError: true,
          multipleOf: 100,
        },
      ],
    },
    {
      items: [
        {
          id: 'refundMethod',
          type: 'single-select',
          label: 'Refund method',
          list: [
            {
              value: 'bank',
              label: 'Bank refund',
            },
            {
              value: 'voucher',
              label: 'Voucher refund',
            },
          ],
          feedbackOnlyWithError: true,
        },
      ],
    },
    {
      items: [
        {
          id: 'serviceFeeInBookCcy',
          type: 'number',
          label: 'Service fee',
          feedbackOnlyWithError: true,
          multipleOf: 100,
        },
      ],
    },
    {
      items: [
        {
          id: 'blank-2',
          type: 'blank',
          feedbackOnlyWithError: true,
          conditions: [
            {
              values: [{ listenTo: 'type', value: 'external' }],
              formGroup: {
                id: 'cancelDate',
                type: 'date',
                feedbackOnlyWithError: true,
                format: DATE_TIME_SERVER_FORMAT,
                label: 'Cancel date',
                initialValue: cancelDateInit,
              },
            },
          ],
        },
      ],
    },
  ];
};

export const CancelCustomerAndProvider = (props: CancelPurchaseForm) => {
  return (
    <CancelForm {...props} config={config(dayjs().format(DATE_TIME_SERVER_FORMAT))} />
  );
};
