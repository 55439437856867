// flow/search

import {
  PurchaseClientGenerateRoutePriceRequest,
  PurchaseClientGenerateRoutePriceResponse,
  PurchaseClientVerifyPurchasePriceRequest,
  PurchaseClientVerifyPurchasePriceResponse,
  TripProviderSearchModifyRequest,
} from '@naus-code/naus-admin-types';
import {
  SearchClientGetNoDetailFinishedParsedResponse,
  SearchClientGetRequest,
  SearchClientGetTripDetailsRequest,
  SearchClientGetTripDetailsResponse,
  SearchClientLocationsRequest,
  SearchClientLocationsResponse,
} from '@naus-code/naus-client-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'flow/search';
export const searchApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // @Post('/locations/v1/')
    // searchLocations(
    //     @Body() body: SearchClientLocationsRequest,
    // ): Promise<SearchClientLocationsResponse> {
    //     return this._searchService.searchLocations(body);
    // }

    searchLocations: builder.query<
      SearchClientLocationsResponse,
      SearchClientLocationsRequest
    >({
      query: (data) => ({
        url: `${base_url}/locations/v1`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'searchLocations'],
    }),

    // @Post('/route-full/')
    // searchRouteV4(
    //     @Body() body: SearchClientGetRequest,
    // ): Promise<SearchClientGetNoDetailParsedResponse> {
    //     return this._searchService.searchRoute(body);
    // }
    searchRouteFull: builder.query<
      SearchClientGetNoDetailFinishedParsedResponse,
      SearchClientGetRequest
    >({
      query: (data) => ({
        url: `${base_url}/route-full/v1`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'searchRouteFull'],
    }),

    // @Post('/route-modify/v1/')
    // searchRouteModify(
    //     @Body() body: TripProviderSearchModifyRequest,
    // ): Promise<SearchClientGetNoDetailFinishedParsedResponse> {
    //     return this._searchService.searchRouteModify(body);
    // }

    searchRouteModify: builder.query<
      SearchClientGetNoDetailFinishedParsedResponse,
      TripProviderSearchModifyRequest
    >({
      query: (data) => ({
        url: `${base_url}/route-modify/v1`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'searchRouteModify'],
    }),

    // @Post('/trip-details/v2/')
    // getTripDetails(
    //     @Body() body: SearchClientGetTripDetailsRequest,
    // ): Promise<SearchClientGetTripDetailsResponse> {
    //     return this._searchService.getTripDetails(body);
    // }

    getTripDetails: builder.query<
      SearchClientGetTripDetailsResponse,
      SearchClientGetTripDetailsRequest
    >({
      query: (data) => ({
        url: `${base_url}/trip-details/v2`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'getTripDetails'],
    }),

    // @Post('/verify-purchase-modify/v1/')
    // verifyPricePurchaseModify(
    //     @Body() body: PurchaseClientVerifyPurchasePriceRequest,
    // ): Promise<PurchaseClientVerifyPurchasePriceResponse> {
    //     return this._priceService.verifyPurchaseRoutePrice(body);
    // }

    verifyPricePurchaseModify: builder.mutation<
      PurchaseClientVerifyPurchasePriceResponse,
      PurchaseClientVerifyPurchasePriceRequest
    >({
      query: (data) => ({
        url: `flow/price/verify-purchase-modify/v1`,
        method: 'POST',
        data,
      }),
      // providesTags: ['*', "searchRouteFull"],
    }),

    // @Post('/generate-purchase-route-price/v1/')
    // verifyPricePurchaseGenerateRoutePrice(
    //     @Body() body: PurchaseClientGenerateRoutePriceRequest,
    // ): Promise<PurchaseClientGenerateRoutePriceResponse> {
    //     return this._priceService.generatePurchaseRoutePrice(body);
    // }
    verifyPricePurchaseGenerateRoutePrice: builder.query<
      PurchaseClientGenerateRoutePriceResponse,
      PurchaseClientGenerateRoutePriceRequest
    >({
      query: (data) => ({
        url: `flow/price/generate-purchase-route-price/v1`,
        method: 'POST',
        data,
      }),
      providesTags: [
        '*',
        'PurchaseClientGenerateRoutePriceResponse',
        'verifyPricePurchaseGenerateRoutePrice',
      ],
    }),
  }),
});
