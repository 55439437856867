import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { FerryCompanyOperationsConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { translate } from '~/utils/translation.utils';

import { CancellationInfo } from './CancellationInfo';
import { CancellationPolicies } from './CancellationPolicies';

export const Cancellations = ({
  allOperations,
}: {
  allOperations: FerryCompanyOperationsConfig;
}) => {
  const { cancelConfig, defaultCancelPolicies, specialCancelPolicies } = allOperations;
  return (
    <CardWithHeader headerText={translate('companyConfig.cancellations')}>
      <VStack p="2.5">
        <CancellationInfo cancelConfig={cancelConfig} />
        <Divider bg="primary-base" thickness={15} br={10} />
        <CancellationPolicies
          defaultPolicies={defaultCancelPolicies}
          specialPolicies={specialCancelPolicies}
        />
      </VStack>
    </CardWithHeader>
  );
};
