import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';

import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { translate } from '~/utils/translation.utils';

import { OfficesList } from './Office/OfficesList';
import { supportConfigApi } from './supportConfig.api';

export const SupportConfigList = ({
  onPressOffice,
  onPressOperation,
  onPressTemplate,
}: {
  onPressOffice: (id: string) => void;
  onPressOperation: () => void;
  onPressTemplate: () => void;
}) => {
  const { data } = supportConfigApi.useGetSupportConfigQuery();
  if (data === undefined) {
    return <></>;
  }

  return (
    <VStack p="2.5" space="2.5">
      <OfficesList onPressOffice={onPressOffice} />
      <ConfigListSectionTitle>
        <Text.Small color="monochrome-mid">
          {translate('supportConfig.config')}
        </Text.Small>
      </ConfigListSectionTitle>
      <ConfigListItem
        text={translate('supportConfig.operation')}
        onPress={onPressOperation}
        iconRightType="chevron-right"
      />
      <ConfigListItem
        text={translate('supportConfig.templateAnswers')}
        onPress={onPressTemplate}
        iconRightType="chevron-right"
      />
    </VStack>
  );
};

export const SupportConfigListNative = () => {
  return <></>;
};
