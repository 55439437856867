import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Image } from 'react-native';

import ErrorSvg from '../../assets/error.svg';

export const ErrorFeedback = ({ message }: { message?: string }) => {
  return (
    <VStack bg="white" p="12" align="center" justify="center" space="3">
      <Image
        source={{ uri: ErrorSvg as any }}
        style={{ width: 150, height: 150 }}
        resizeMode="contain"
      />

      <Text.Body1Regular>{message || 'Error occurred'}</Text.Body1Regular>
    </VStack>
  );
};
