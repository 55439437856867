import {
  ClientGetFormConfigWithCheckResponse,
  SupportConfigAddOfficeConfigRequest,
  SupportConfigAddSupportTypeRequest,
  SupportConfigClientGetResponse,
  SupportConfigCreateTemplateAnswerCategoryRequest,
  SupportConfigCreateTemplateAnswerRequest,
  SupportConfigEnableDisableConfigRequest,
  SupportConfigGetOfficeConfigResponse,
  SupportConfigGetOperationConfigResponse,
  SupportConfigGetTemplateAnswerConfigResponse,
  SupportConfigUpdateBufferMinutesRequest,
  SupportConfigUpdateOfficeConfigRequest,
  SupportConfigUpdateSupportTypeRequest,
  SupportConfigUpdateTemplateAnswerRequest,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'config/support';
export const supportConfigApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSupportConfig: builder.query<SupportConfigClientGetResponse, void>({
      query: () => ({
        url: `${base_url}/get/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getSupportConfig'],
    }),
    getCreateOfficeFormConfig: builder.query<ClientGetFormConfigWithCheckResponse, void>({
      query: () => ({
        url: `${base_url}/form-config/create-office/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCreateOfficeFormConfig'],
    }),
    createOffice: builder.mutation<
      SupportConfigGetOfficeConfigResponse,
      SupportConfigAddOfficeConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-office/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createOffice', 'getSupportConfig'],
    }),
    getUpdateOfficeFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      string
    >({
      query: (id) => ({
        url: `${base_url}/form-config/update-office/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getUpdateOfficeFormConfig'],
    }),
    updateOffice: builder.mutation<
      SupportConfigGetOfficeConfigResponse,
      SupportConfigUpdateOfficeConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-office/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['updateOffice', 'getUpdateOfficeFormConfig', 'getSupportConfig'],
    }),
    setDefaultOffice: builder.mutation<
      SupportConfigGetOfficeConfigResponse,
      SupportConfigEnableDisableConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/set-default-office/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'setDefaultOffice',
        'getUpdateOfficeFormConfig',
        'getSupportConfig',
      ],
    }),
    //============= TEMPLATE CONFIGS ==================
    getCreateAnswerTemplateFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/create-template-answer/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getCreateAnswerTemplateFormConfig'],
    }),
    getUpdateAnswerTemplateFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      string
    >({
      query: (templateAnswerId: string) => ({
        url: `${base_url}/form-config/update-template-answer/${templateAnswerId}`,
        method: 'GET',
      }),
      providesTags: ['getUpdateAnswerTemplateFormConfig'],
    }),

    createAnswerTemplate: builder.mutation<
      SupportConfigGetTemplateAnswerConfigResponse,
      SupportConfigCreateTemplateAnswerRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-template-answer/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createAnswerTemplate', 'getSupportConfig'],
    }),
    createTemplateCategory: builder.mutation<
      SupportConfigGetTemplateAnswerConfigResponse,
      SupportConfigCreateTemplateAnswerCategoryRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-template-answer-category/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createAnswerTemplate', 'getSupportConfig'],
    }),

    // @Post('/update-template-answer/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateTemplateAnswer(
    //     @Body() body: SupportConfigUpdateTemplateAnswerRequest,
    // ): Promise<SupportConfigGetTemplateAnswerConfigResponse> {
    //     return this._supportConfigService.updateTemplateAnswer(body);
    // }
    updateTemplateAnswer: builder.mutation<
      SupportConfigGetTemplateAnswerConfigResponse,
      SupportConfigUpdateTemplateAnswerRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-template-answer/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getUpdateAnswerTemplateFormConfig', 'getSupportConfig'],
    }),

    //=================OPERATIONS CONFIG===================//
    // @Post('/update-support-request-buffer/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateSupportRequestBuffer(
    //     @Body() body: SupportConfigUpdateBufferMinutesRequest,
    // ): Promise<SupportConfigGetOperationConfigResponse> {
    //     return this._supportConfigService.updateBufferMinutes(body);
    // }

    // //
    // @Get('/form-config/add-support-type/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addSupportTypeFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._supportConfigService.addSupportTypeFormConfig();
    // }
    addSupportTypeFormConfig: builder.query<ClientGetFormConfigWithCheckResponse, void>({
      query: () => ({
        url: `${base_url}/form-config/add-support-type/`,
        method: 'GET',
      }),
      providesTags: ['addSupportTypeFormConfig'],
    }),

    // @Post('/add-support-type/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // addSupportType(
    //     @Body() body: SupportConfigAddSupportTypeRequest,
    // ): Promise<SupportConfigGetOperationConfigResponse> {
    //     return this._supportConfigService.addSupportType(body);
    // }
    addSupportType: builder.mutation<
      SupportConfigGetOperationConfigResponse,
      SupportConfigAddSupportTypeRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-support-type/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportConfig', 'updateSupportTypeFormConfig'],
    }),

    //   @Get('/form-config/update-support-type/:supportTypeId', [
    //     applyRoleValidation(['ADMIN', 'SYSTEM']),
    // ])
    // updateSupportTypeFormConfig(
    //     @Params('supportTypeId') supportTypeId: string,
    // ): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._supportConfigService.updateSupportTypeFormConfig(supportTypeId);
    // }
    updateSupportTypeFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      string
    >({
      query: (supportTypeId: string) => ({
        url: `${base_url}/form-config/update-support-type/${supportTypeId}`,
        method: 'GET',
      }),
      providesTags: ['updateSupportTypeFormConfig'],
    }),

    // @Post('/update-support-type/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateSupportType(
    //     @Body() body: SupportConfigUpdateSupportTypeRequest,
    // ): Promise<SupportConfigGetOperationConfigResponse> {
    //     return this._supportConfigService.updateSupportType(body);
    // }
    updateSupportType: builder.mutation<
      SupportConfigGetOperationConfigResponse,
      SupportConfigUpdateSupportTypeRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-support-type/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportConfig', 'updateSupportTypeFormConfig'],
    }),

    // @Post('/update-support-request-buffer/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateSupportRequestBuffer(
    //     @Body() body: SupportConfigUpdateBufferMinutesRequest,
    // ): Promise<SupportConfigGetOperationConfigResponse> {
    //     return this._supportConfigService.updateBufferMinutes(body);
    // }
    updateSupportRequestBuffer: builder.mutation<
      SupportConfigGetOperationConfigResponse,
      SupportConfigUpdateBufferMinutesRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-support-request-buffer/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSupportConfig'],
    }),
  }),
});
