import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { ContentFeedbackHandler } from '@droplet-tech-code/core-elements/module/ui/Feedback/Feedback.Handler';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  ActivePolicyClient,
  PurchaseGetCancelPoliciesResponse,
  PurchaseGetCapabilitiesResponse,
  PurchaseGetModifyPoliciesResponse,
  PurchaseGetOpenPoliciesResponse,
} from '@naus-code/naus-admin-types';
import {
  DefaultCancelPolicy,
  DefaultModifyPolicy,
  DefaultOpenPolicy,
} from '@naus-code/naus-client-types';

import { Field } from '~/components/Card/Field';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { translate } from '~/utils/translation.utils';

const ModalKey = 'seeCapabilities';

const PurchaseCapabilities = ({ purchaseId }: { purchaseId: string }) => {
  const capabilitiesResponse =
    purchaseActionApi.useGetPurchaseCapabilitiesQuery(purchaseId);

  const { data: capabilitiesData, isLoading: isLoadingCapabilities } =
    capabilitiesResponse;

  const policiesModifyResponse =
    purchaseActionApi.useGetModifyPurchasePoliciesQuery(purchaseId);

  const policiesCancelResponse =
    purchaseActionApi.useGetCancelPurchasePoliciesQuery(purchaseId);

  const policiesOpenResponse =
    purchaseActionApi.useGetOpenPurchasePoliciesQuery(purchaseId);

  const { data: policiesDataModify, isLoading: isLoadingPoliciesModify } =
    policiesModifyResponse;

  const { data: policiesDataCancel, isLoading: isLoadingPoliciesCancel } =
    policiesCancelResponse;

  const { data: policiesDataOpen, isLoading: isLoadingPoliciesOpen } =
    policiesOpenResponse;

  handleResponse({ response: policiesModifyResponse });
  handleResponse({ response: policiesCancelResponse });
  handleResponse({ response: policiesOpenResponse });

  handleResponse({ response: capabilitiesResponse });

  const policiesData =
    !policiesDataModify && !policiesDataCancel && !policiesDataOpen
      ? undefined
      : { policiesDataModify, policiesDataCancel, policiesDataOpen };

  const { closeModal } = useModal();
  return (
    <Modal.Container>
      <ContentFeedbackHandler
        data={capabilitiesData && policiesData}
        dataEmpty={!capabilitiesData && !policiesData}
        feedback={translate('common.noDataFound')}
        isLoading={
          isLoadingCapabilities ||
          isLoadingPoliciesModify ||
          isLoadingPoliciesCancel ||
          isLoadingPoliciesOpen
        }
      >
        <Modal.Header
          title={translate('purchases.purchaseTool.capabilities')}
          onPress={() => {
            closeModal(ModalKey);
          }}
          divider
        />
        <ModalScrollView>
          <CapabilitiesAndPolicies
            capabilitiesData={capabilitiesData}
            policiesData={policiesData}
          />
        </ModalScrollView>
        {/* <Modal.Footer onCancel={() => closeModal("seeCapabilities")} /> */}
      </ContentFeedbackHandler>
    </Modal.Container>
  );
};

export const usePurchaseCapabilities = () => {
  const { closeModal, openModal } = useModal();

  return {
    open: (purchaseId: string) => {
      openModal(<PurchaseCapabilities purchaseId={purchaseId} />, {
        key: ModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

const CapabilitiesAndPolicies = ({
  capabilitiesData,
  policiesData,
}: {
  capabilitiesData: PurchaseGetCapabilitiesResponse | undefined;
  policiesData: AllPolicies | undefined;
}) => {
  return (
    <SwitchStack p="2.5" space="2.5" flex={1}>
      {capabilitiesData && (
        <VStack
          flex={isNativeMobile ? 1 : undefined}
          space="2"
          w={policiesData && isWeb ? 200 : undefined}
        >
          {Object.entries(capabilitiesData.capabilities)
            .sort((a, b) => Number(b[1]) - Number(a[1]))
            .map((value, ix) => {
              const label = value[0].slice(3);
              const fieldValue =
                value[1] === undefined ? '-' : String(value[1] ? 'YES' : 'NO');
              return <Field label={label} value={fieldValue} key={ix} />;
            })}
          {isWeb && <View flex={1} />}
        </VStack>
      )}
      {policiesData && (
        <SwitchStack
          space="2.5"
          flex={1}
          // style={{ height: "100%" }}
        >
          <Divider vertical={isWeb} />
          <VStack>
            {policiesData.policiesDataCancel && (
              <PolicyInfo
                policyTitle={'cancel'}
                policies={policiesData.policiesDataCancel.policies}
              />
            )}
            {policiesData.policiesDataModify && (
              <PolicyInfo
                policyTitle={'modify'}
                policies={policiesData.policiesDataModify.policies}
              />
            )}
            {policiesData.policiesDataOpen && (
              <PolicyInfo
                policyTitle={'open'}
                policies={policiesData.policiesDataOpen.policies}
              />
            )}
            {isWeb && <View flex={1} />}
          </VStack>
        </SwitchStack>
      )}
    </SwitchStack>
  );
};

const PolicyInfo = ({
  policies,
  policyTitle,
}: {
  policies:
    | ActivePolicyClient<DefaultModifyPolicy>[]
    | ActivePolicyClient<DefaultCancelPolicy>[]
    | ActivePolicyClient<DefaultOpenPolicy>[];
  policyTitle: string;
}) => {
  if (policies.length === 0) {
    return null;
  }

  const currentPolicyIndex = getCurrentPolicyIndex(policies);

  return (
    <VStack
      space="2.5"
      // style={{ height: "100%" }}
    >
      <Text.Body2Medium>{policyTitle}</Text.Body2Medium>
      {policies.map((policy, index) => {
        return (
          <HStack
            p="1"
            key={index}
            b={currentPolicyIndex === index ? 2 : 0}
            bc="primary-light"
            br={10}
          >
            <PolicyItem policy={policy} />
          </HStack>
        );
      })}
      {/* <HStack flex={1} /> */}
    </VStack>
  );
};

const PolicyItem = ({ policy }: { policy: ActivePolicyClient<DefaultModifyPolicy> }) => {
  const { startTime, endTime } = policy;
  const { id, name } = policy.policy;

  return (
    <VStack flex={1}>
      <Field label="id" value={id} />
      <Field
        label={translate('purchases.purchaseTool.purchasePolicy.policyName')}
        value={name}
      />
      <Field
        label={translate('purchases.purchaseTool.purchasePolicy.startTime')}
        value={startTime}
        isDate
      />
      <Field
        label={translate('purchases.purchaseTool.purchasePolicy.endTime')}
        value={endTime}
        isDate
      />
    </VStack>
  );
};

const getCurrentPolicyIndex = (
  policies:
    | ActivePolicyClient<DefaultModifyPolicy>[]
    | ActivePolicyClient<DefaultCancelPolicy>[]
    | ActivePolicyClient<DefaultOpenPolicy>[],
): number => {
  const currentTime = dayjs().unix();

  let currentPolicyIndex = -1;
  let earliest = dayjs(policies[0].startTime).unix();
  let earliestIndex = 0;

  policies.forEach((policy, index) => {
    const end = dayjs(policy.endTime).unix();
    const start = dayjs(policy.startTime).unix();
    if (currentTime < end && currentTime > start) {
      currentPolicyIndex = index;
    }
    if (start < earliest) {
      earliest = start;
      earliestIndex = index;
    }
  });

  if (currentPolicyIndex === -1 && currentTime < earliest) {
    currentPolicyIndex = earliestIndex;
  }

  return currentPolicyIndex;
};

interface AllPolicies {
  policiesDataModify?: PurchaseGetModifyPoliciesResponse;
  policiesDataCancel?: PurchaseGetCancelPoliciesResponse;
  policiesDataOpen?: PurchaseGetOpenPoliciesResponse;
}
