import {
  ButtonInteractionState,
  IconButton,
} from '@droplet-tech-code/core-elements/module/ui/Button';
import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  RouteNoDetails,
  RouteTrip,
  RouteTripNoDetails,
  SearchRouteMetaTrip,
} from '@naus-code/naus-client-types';
import { Image, Pressable } from 'react-native';

import { FlatList } from '~/components/FlatList/FlatList';
import { Pill } from '~/components/Pill/Pill';
import { searchApi } from '~/components/Search/Search.api';
import { getTripInnerNotice } from '~/components/Search/Search.utils';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { DATE_SERVER_FORMAT } from '~/utils/date.utils';

export interface SearchParams {
  originId: string;
  destinationId: string;
  depDate: string;

  options: {
    passengers: number;
    vehicles: number;
  };
  ccy: string;
}

export const SearchList = ({
  params,
  onPressTicket,
  selectedId,
  purchaseId,
  setDepDate,
}: {
  params: SearchParams;
  onPressTicket: (props: RouteNoDetails) => void;
  purchaseId: string;
  selectedId?: string;
  setDepDate: (newDepDate: string) => void;
}) => {
  const { data, isFetching, isLoading } = searchApi.useSearchRouteModifyQuery(
    {
      origin: params.originId,
      destination: params.destinationId,
      depDate: params.depDate,
      purchaseId,
      // options: {
      //   passenger: params.options.passengers,
      //   vehicle: params.options.vehicles,
      //   pet: 0,
      //   moto: 0,
      // },
      // ccy: params.ccy,
    },
    {
      skip: !params.originId || !params.destinationId || !params.depDate,
    },
  );

  const routes = data?.routes || [];
  const filteredRoutes = selectedId
    ? routes.filter((r) => r.routeKey === selectedId)
    : routes;

  const finalData = filteredRoutes.map((r) => ({ ...r, id: r.routeKey }));
  const waiting = isLoading || isFetching;

  return (
    <DelayRender isLoading={waiting}>
      <HStack space="3">
        <Text.Body2Regular color="monochrome-mid" style={{ marginBottom: 12 }}>
          {finalData.length} trips found for on{' '}
          <Text.Body1Medium>"{params.depDate}"</Text.Body1Medium>
        </Text.Body2Regular>
        <View flex={1} />
        <HStack>
          <IconButton
            icon="chevron-left"
            onPress={() => {
              setDepDate(
                dayjs(params.depDate, DATE_SERVER_FORMAT)
                  .subtract(1, 'day')
                  .format(DATE_SERVER_FORMAT),
              );
            }}
          />
          <IconButton
            icon="chevron-right"
            onPress={() => {
              setDepDate(
                dayjs(params.depDate, DATE_SERVER_FORMAT)
                  .add(1, 'day')
                  .format(DATE_SERVER_FORMAT),
              );
            }}
          />
        </HStack>
      </HStack>
      <FlatList
        isLoading={waiting}
        data={finalData}
        simple
        contentContainerStyle={{ paddingHorizontal: 0 }}
        renderItem={(props) => {
          const selected = selectedId === props.routeKey;

          if (props.type === 'in-direct') {
            const trip1 = props.trips[0];

            const layover = trip1.layoverMinutes
              ? minutesToDuration(trip1.layoverMinutes)
              : '';
            return (
              <SearchContainer
                bc={selected ? 'primary-mid' : undefined}
                bg={selected ? 'primary-base' : undefined}
              >
                <VStack align="center">
                  <SearchTicketItem trip={trip1} params={params} />
                  <Pill color="secondary">Layover: {layover || 'None'}</Pill>
                  <SearchTicketItem trip={props.trips[1]} params={params} />
                </VStack>
              </SearchContainer>
            );
          }

          const notice = getTripInnerNotice(props.trip);
          const disabled = !!notice;
          return (
            <Pressable
              disabled={disabled}
              onPress={() => {
                onPressTicket(props);
              }}
            >
              {({ hovered }: ButtonInteractionState) => {
                return (
                  <SearchContainer
                    bc={selected ? 'primary-mid' : 'monochrome-extraLight'}
                    bg={hovered || selected ? 'primary-base' : 'white'}
                    style={[disabled && { opacity: 0.6 }]}
                  >
                    <HStack space="1">
                      <CheckBox
                        disabled={disabled}
                        checked={selected}
                        onPress={() => {
                          onPressTicket(props);
                        }}
                      />
                      <SearchTicketItem
                        trip={props.trip}
                        params={params}
                        notice={notice}
                      />
                    </HStack>
                  </SearchContainer>
                );
              }}
            </Pressable>
          );
        }}
      />
    </DelayRender>
  );
};

const minutesToDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  return `${hours}h ${mins}m`;
};

const SearchContainer = (props: AppViewProps) => {
  return <View p="1.5" b={1} bc="monochrome-extraLight" br={12} {...props} />;
};

export const SearchTicketItem = (props: {
  trip: RouteTripNoDetails | RouteTrip | SearchRouteMetaTrip;
  params?: SearchParams;
  notice?: string;
  compact?: boolean;
  // disabled?: boolean;
}) => {
  const trip = props.trip;
  const options = props.params?.options;
  const eTicket = trip.ticketType !== 'Paper';

  return (
    <VStack space="1" flex={1}>
      {props.notice && <TripNotice notice={props.notice} />}
      <HStack space="3" justify="space-between">
        <TripHeader trip={trip} compact={props.compact} />
        <TripBody trip={trip} compact={props.compact} />
        <HStack space="0.5">
          <VehicleIcons trip={trip} />

          {trip.tripType === 'FERRY' && (
            <Icon
              icon="bed-cabin"
              color={trip.cabin ? 'primary' : 'monochrome'}
              crossed={!trip.cabin}
            />
          )}

          <Icon
            icon="qr-code"
            color={eTicket ? 'primary' : 'monochrome'}
            crossed={!eTicket}
          />
        </HStack>
        <VStack space="2">
          <VStack>
            <Text.Small color="monochrome-light">Base price</Text.Small>
            <Text.Body1Medium>
              {getPriceWithSymbol(trip.pricePp, trip.ccySymbol)}
            </Text.Body1Medium>
          </VStack>
          {options ? (
            <VStack>
              <HStack space="1">
                <HStack>
                  <Icon icon="passenger" size="small" color="monochrome" />
                  <Text.Small color="monochrome-mid">{options.passengers}</Text.Small>
                </HStack>
                <HStack>
                  <Icon icon="vehicle" size="small" color="monochrome" />
                  <Text.Small color="monochrome-mid">{options.vehicles}</Text.Small>
                </HStack>
              </HStack>
            </VStack>
          ) : null}
        </VStack>
      </HStack>
    </VStack>
  );
};

export const TripNotice = ({ notice }: { notice: string }) => {
  return (
    <HStack justify="center">
      <View bg="secondary-base" px="3" py="1" align="center" justify="center">
        <Text.Body1Medium align="center" color="secondary-extraDark">
          {notice}
        </Text.Body1Medium>
      </View>
    </HStack>
  );
};

export const TripBody = ({
  trip,
  compact,
}: {
  trip: RouteTripNoDetails | RouteTrip | SearchRouteMetaTrip;
  compact?: boolean;
}) => {
  const isOvernight = trip.depDateDisplay != trip.arrDateDisplay;

  return (
    <VStack style={[!compact && { width: 275 }]}>
      <HStack flex={1}>
        <Text.ExtraSmall>{trip.depDateDisplay}</Text.ExtraSmall>
        {isOvernight && (
          <>
            <View flex={1} />
            <Text.ExtraSmall>{trip.arrDateDisplay}</Text.ExtraSmall>
          </>
        )}
      </HStack>
      <HStack space="1" justify="space-between">
        <VStack>
          <Text.Small style={{ fontWeight: 'bold' }}>{trip.depTimeDisplay} </Text.Small>
          <Text.Small>{`${trip.originDisplay} (${trip.origin})`}</Text.Small>
        </VStack>

        <Text.Body2Medium>{`→`}</Text.Body2Medium>

        <VStack>
          <Text.Small align="right" style={{ fontWeight: 'bold' }}>
            {trip.arrTimeDisplay}
          </Text.Small>
          <Text.Small align="right">{`${trip.destDisplay} (${trip.dest})`}</Text.Small>
        </VStack>
      </HStack>
    </VStack>
  );
};

export const TripHeader = ({
  trip,
  body,
  compact,
}: {
  trip: RouteTripNoDetails | RouteTrip | SearchRouteMetaTrip;
  body?: boolean;
  compact?: boolean;
}) => {
  return (
    <VStack space="2" style={[!compact && { width: 200 }]}>
      <HStack space="3">
        <Image
          style={{ width: 45, height: 45, borderRadius: 12 }}
          source={{
            uri: trip.operatorImg,
          }}
        />
        <VStack>
          <Text.ExtraSmall>{trip.vesselName}</Text.ExtraSmall>
          <Text.Small>
            {trip.operatorName} ({trip.companyId})
          </Text.Small>
        </VStack>
        {body ? <TripBody trip={trip} /> : null}
      </HStack>
    </VStack>
  );
};

const VehicleIcons = ({
  trip,
}: {
  trip: RouteTripNoDetails | RouteTrip | SearchRouteMetaTrip;
}) => {
  if (trip.tripType === 'FERRY') {
    const available = trip.vehicle;
    if (available) {
      const tripNoDetails = trip as RouteTripNoDetails;
      return <AvailableVehicleIcons trip={tripNoDetails} />;
    }
    return <Icon icon="vehicles" color="monochrome" crossed />;
  }

  return null;
};

const AvailableVehicleIcons = ({ trip }: { trip: RouteTripNoDetails }) => {
  const bothSoldOut = trip.motoSoldOut && trip.vehicleSoldOut;
  if (bothSoldOut) {
    return <Icon icon="vehicles" color="error" />;
  }

  const bothAvailable = !trip.motoSoldOut && !trip.vehicleSoldOut;

  if (bothAvailable) {
    return <Icon icon="vehicles" color="primary" />;
  }

  return (
    <HStack space="1.5">
      <Icon icon="vehicle" color={trip.vehicleSoldOut ? 'error' : 'primary'} />
      <Icon icon="motorcycle" color={trip.motoSoldOut ? 'error' : 'primary'} />
    </HStack>
  );
};
