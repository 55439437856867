import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { CompanyConfigClientCreateFerryCompanyDiscountRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { companyConfigApi } from '~/screens/Configs/Company/CompanyConfigs.api';
import { translate } from '~/utils/translation.utils';

const updateDiscountModalKey = 'UpdateDiscount-ModalKey';

export const useUpdateDiscountConfigModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: ({
      companyId,
      discountCode,
      check,
    }: {
      companyId: string;
      discountCode: string;
      check: string;
    }) => {
      openModal(
        <UpdateDiscountConfigComponent
          companyId={companyId}
          discountCode={discountCode}
          check={check}
        />,
        { key: updateDiscountModalKey, type: 'action-sheet-full' },
      );
    },
    close: () => {
      closeModal(updateDiscountModalKey);
    },
  };
};

const UpdateDiscountConfigComponent = ({
  companyId,
  discountCode,
  check,
}: {
  companyId: string;
  discountCode: string;
  check: string;
}) => {
  const formRef = useRef(new FormHandler());
  const { closeModal } = useModal();

  const { data, isLoading: isLoadingForm } =
    companyConfigApi.useUpdateFerryCompanyDiscountConfigFormConfigQuery({
      companyId,
      discountCode,
      check,
    });

  const [updateDiscountConfig, { isLoading: isUpdating }] =
    companyConfigApi.useUpdateFerryCompanyDiscountConfigMutation();
  if (!data) {
    return null;
  }
  const { check: formCheck } = data;

  return (
    <Modal.Container>
      <Modal.Header title={translate('companyConfig.addFerryCompanyDiscount')} />
      <ModalScrollView>
        <FormContent isLoading={isLoadingForm} form={data.config} flex={1}>
          <FormBuilder data={data.config} form={formRef.current} />
        </FormContent>
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isUpdating}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              CompanyConfigClientCreateFerryCompanyDiscountRequest['config']
            >();

          if (valid) {
            const response = await updateDiscountConfig({
              config: value,
              companyId,
              check: formCheck,
            });
            handleResponse({
              response,
              onSuccess: () => {
                closeModal(updateDiscountModalKey);
              },
              onError: () => {
                closeModal(updateDiscountModalKey);
              },
            });
          }
        }}
        onCancel={() => closeModal(updateDiscountModalKey)}
      />
    </Modal.Container>
  );
};
