import {
  EventListResponse,
  GetEventResponse,
  PaginatedListRequest,
} from '@naus-code/naus-admin-types';
import isEqual from 'lodash/isEqual';

import { mergeAndRemoveDuplicates } from '~/utils/utils.helper';

import { rootApi } from '../../store/redux.utils';

export const eventsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getEventList: builder.query<EventListResponse, PaginatedListRequest>({
      query: (data) => ({
        url: `event/get-list/`,
        method: 'POST',
        data,
      }),

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },

      providesTags: ['*', 'Event_List'],
    }),

    getEventItem: builder.query<GetEventResponse, string>({
      query: (id) => ({
        url: `event/get-event/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'Event_Item'],
    }),
  }),
});
