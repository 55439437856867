import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { CreateNoticeRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { noticesApi } from '~/screens/Notices/notices.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const AddNoticeModalKey = 'AddModal-Notice';
const AddNoticeModal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [createNotice, { isLoading: isCreatingNotice }] =
    noticesApi.useCreateNoticeMutation();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('notices.createNotice')}
        divider
        onPress={() => {
          closeModal(AddNoticeModalKey);
        }}
      />
      <ModalScrollView>
        <CreateNoticeForm form={formRef.current} />
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isCreatingNotice}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<CreateNoticeRequest['notice']>();

          if (valid) {
            const response = await createNotice({ notice: value });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddNoticeModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddNoticeModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useAddNotice = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: () => {
      openModal(<AddNoticeModal />, {
        key: AddNoticeModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(AddNoticeModalKey);
    },
  };
};

const CreateNoticeForm = ({ form }: { form: FormHandler }) => {
  const { data: createNoticeConfig, isLoading: isLoadingNoticeConfig } =
    noticesApi.useCreateNoticeFormConfigQuery();

  return (
    <VStack flex={1}>
      <FormContent isLoading={isLoadingNoticeConfig} form={createNoticeConfig}>
        {createNoticeConfig ? (
          <FormBuilder data={createNoticeConfig} form={form} />
        ) : null}
      </FormContent>
    </VStack>
  );
};
