import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropCompanyConfig } from '~/navigation/Navigation.types';

import { CompanyConfigItem } from './CompanyConfig.Item';
import { CompanyConfigList, CompanyConfigListNative } from './CompanyConfig.List';

export const CompanyConfigScreen = (props: ScreenPropCompanyConfig) => {
  return (
    <PageContainer>
      <CompanyConfigLayout {...props} />
    </PageContainer>
  );
};

export const CompanyConfigLayout = (props: ScreenPropCompanyConfig) => {
  const companyConfigId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(companyConfigId);
  }, [companyConfigId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <CompanyConfigList onPress={(item) => setId(item.id)} />
      ) : (
        <CompanyConfigListNative />
      )}
      {id ? <CompanyConfigItem id={id} onClose={() => setId(undefined)} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
