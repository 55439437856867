import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { SupportPaymentHistory } from '@naus-code/naus-client-types';
import React from 'react';

import { AppText } from '~/components/Text';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

import { Field } from '../../Card/Field';

export const SupportRequestPaymentHistoryHeader = ({
  supportRequestInfo,
}: {
  supportRequestInfo?: SupportRequestBackendClient;
}) => {
  return (
    <>
      <HStack
        px="2.5"
        py="3"
        bg="monochrome-extraLight"
        style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
      >
        <AppText.MiscTag flex={1} type="heading1">
          {`${translate('supportRequests.paymentHistory')} (${
            supportRequestInfo?.paymentHistory.length ?? 0
          })`}
        </AppText.MiscTag>
      </HStack>
      <Divider bg="monochrome-light" style={{ maxWidth: isWeb ? 400 : undefined }} />
    </>
  );
};

export const SupportRequestPaymentHistoryContent = ({
  supportRequestInfo,
}: {
  supportRequestInfo?: SupportRequestBackendClient;
}) => {
  if (supportRequestInfo === undefined) {
    return <></>;
  }

  const { paymentHistory } = supportRequestInfo;

  return (
    <VStack space="2.5" style={{ maxWidth: isWeb ? 400 : undefined }}>
      {paymentHistory.map((paymentHistory) => {
        return (
          <React.Fragment key={paymentHistory.id}>
            <SupportRequestPaymentHistoryListItem {...paymentHistory} />
            <Divider />
          </React.Fragment>
        );
      })}
    </VStack>
  );
};

const SupportRequestPaymentHistoryListItem = (paymentHistory: SupportPaymentHistory) => {
  const { paymentSessionId, description, value, serviceFee, currency, status, type } =
    paymentHistory;

  return (
    <VStack p="2.5">
      <HStack flex={1}>
        <Text.Small numberOfLines={1} color="monochrome-mid">
          {paymentSessionId}
        </Text.Small>
        {paymentHistory.inProgress && (
          <Text.Small numberOfLines={1} color="warning-light">
            {translate('supportRequests.inProgress')}
          </Text.Small>
        )}
      </HStack>
      <Text.Body2Regular>{description}</Text.Body2Regular>
      <Field
        label={translate('supportRequests.value')}
        value={getPriceWithSymbol(value, currency)}
      />
      <Field
        label={translate('supportRequests.serviceFee')}
        value={getPriceWithSymbol(serviceFee, currency)}
      />
      <Field label={translate('supportRequests.status')} value={status} />
      <Field label={translate('supportRequests.type')} value={type} />
      {paymentHistory.error && (
        <Field label={translate('supportRequests.error')} value={paymentHistory.error} />
      )}
      {paymentHistory.voucherId && (
        <Field
          label={translate('supportRequests.voucherId')}
          value={paymentHistory.voucherId}
        />
      )}
    </VStack>
  );
};
