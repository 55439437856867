import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { TransactionClient } from '@naus-code/naus-admin-types';

import { PageContainer } from '~/components/Page/Page.Container';
import { ScreenPropTransactions } from '~/navigation/Navigation.types';
import { CustomerTransactionQueryList } from '~/screens/Finance/Transactions/Transactions.CustomerList';
import { TransactionHeader } from '~/screens/Finance/Transactions/Transactions.Header';
import { CompanyTransactionQueryList } from '~/screens/Finance/Transactions/Transactions.OperatorList';

export const TransactionsScreen = (props: ScreenPropTransactions) => {
  return (
    <PageContainer>
      <TransactionLayout {...props} />
    </PageContainer>
  );
};

export const TransactionLayout = (_: ScreenPropTransactions) => {
  return (
    <VStack flex={1} bg="white" space="1.5">
      <TransactionHeader />
      <Divider />
      <TransactionSplitList />
    </VStack>
  );
};

export const TransactionSplitList = ({ list }: { list?: TransactionClient[] }) => {
  return (
    <HStack space="1" flex={1} style={{ alignSelf: 'stretch' }}>
      <View flex={1} style={{ alignSelf: 'stretch' }}>
        <CompanyTransactionQueryList list={list} />
      </View>
      <Divider vertical />
      <View flex={1} style={{ alignSelf: 'stretch' }}>
        <CustomerTransactionQueryList list={list} />
      </View>
    </HStack>
  );
};
