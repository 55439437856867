import { configureStore } from "@reduxjs/toolkit";

import { alertMiddleware } from "./middleware";
import { rootApi } from "./redux.utils";
import { store } from "./registry";
import { rootReducer } from "./rootReducer";

export const storeOnlyForProviderUse = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // REGISTER,
        ],
      },
    }).concat(rootApi.middleware, alertMiddleware),
});

store.register(storeOnlyForProviderUse);
