import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { BaseInput } from '@droplet-tech-code/core-elements/module/ui/Inputs/BaseInputs';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  BookingPurchaseRetrieveClient,
  GetPurchaseResponse,
} from '@naus-code/naus-admin-types';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { ErrorFeedback } from '~/components/Feedback/Error.Feedback';
import { AppText } from '~/components/Text';
import {
  AutoUpdateInner,
  DoNothingKey,
  processPurchaseRequest,
  TripSelector,
} from '~/screens/Support/Purchases/PurchaseFromProvider/PurchaseFromProvider.Compare';
import { purchaseApi } from '~/screens/Support/Purchases/Purchases.api';
import { translate } from '~/utils/translation.utils';

import { ProviderOptions, purchaseActionManager } from '../../Common/ActionPanel.utils';
import { ModifyPanelProps, resetBasketManager } from '../Modify.utils';
import { useModifyAutoUpdateStore } from './Modify.AutoUpdate.State';

interface FieldData {
  reservationNumber: string;
  value: number;
  dateModified: string;
}

export const AutoUpdateTripManagerWrapper = (props: ModifyPanelProps) => {
  const autoUpdateFieldsRef = useRef({
    value: 0,
    dateModified: dayjs().format('YYYY-MM-DD HH:mm').toString(),
  } as FieldData);
  resetBasketManager();

  return (
    <AutoUpdateTripManager autoUpdateFieldsRef={autoUpdateFieldsRef} props={props} />
  );
};

export const AutoUpdateTripManager = ({
  props,
  autoUpdateFieldsRef,
}: {
  props: ModifyPanelProps;
  autoUpdateFieldsRef: MutableRefObject<FieldData>;
}) => {
  const { purchaseId } = props;

  return (
    <VStack>
      <AutoUpdateFields autoUpdateFieldsRef={autoUpdateFieldsRef} />
      <AutoUpdateInfo purchaseId={purchaseId} />
    </VStack>
  );
};

const AutoUpdateInfo = ({ purchaseId }: { purchaseId: string }) => {
  const resCode = useModifyAutoUpdateStore((s) => s.resCode);

  const {
    data: providerData,
    isLoading,
    isFetching,
  } = purchaseApi.useGetPurchaseRetrieveFromProviderQuery(
    {
      purchaseId,
      resCode,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !resCode,
    },
  );

  const {
    data: internalPurchase,
    isLoading: isLoadingInternalPurchase,
    isFetching: isFetchingInternal,
  } = purchaseApi.useGetPurchaseQuery(purchaseId, {
    refetchOnMountOrArgChange: true,
  });

  const providerPurchases = providerData?.reservation.purchases;

  if (!resCode) {
    return null;
  }

  return (
    <DelayRender
      isLoading={
        isFetching || isFetchingInternal || isLoading || isLoadingInternalPurchase
      }
    >
      {providerPurchases?.length && internalPurchase ? (
        <AutoUpdateContainer
          providerPurchases={providerPurchases}
          internalPurchase={internalPurchase}
        />
      ) : (
        <ErrorFeedback />
      )}
    </DelayRender>
  );
};

const AutoUpdateContainer = ({
  providerPurchases,
  internalPurchase,
}: {
  providerPurchases: BookingPurchaseRetrieveClient[];
  internalPurchase: GetPurchaseResponse;
}) => {
  const [providerPurchaseIndex, setProviderPurchaseIndex] = useState<number>(0);
  const resCode = useModifyAutoUpdateStore((s) => s.resCode);
  const providerPurchase = providerPurchases[providerPurchaseIndex];
  const [_, setPurchaseRequest] =
    purchaseActionManager.state.useGlobalState('purchaseRequest');
  const [linkValues, setLinkValues] = useState<{
    [key: string]: string;
  }>(
    Object.assign(
      {},
      ...providerPurchase.tickets.map((_, tIx) => ({
        [String(tIx)]: DoNothingKey,
      })),
    ),
  );

  useEffect(() => {
    if (!resCode) {
      return;
    }
    const purchaseRequest = processPurchaseRequest({
      linkValues,
      internalPurchase,
      providerPurchase,
    });

    setPurchaseRequest(purchaseRequest);
  }, [linkValues, internalPurchase, providerPurchase]);

  return (
    <VStack space="2.5" pt="2.5">
      <TripSelector
        providerPurchases={providerPurchases}
        providerPurchaseIndex={providerPurchaseIndex}
        setProviderPurchaseIndex={setProviderPurchaseIndex}
      />
      <AutoUpdateInner
        internalPurchase={internalPurchase}
        providerPurchase={providerPurchase}
        linkValues={linkValues}
        setLinkValues={setLinkValues}
        setSilentOn={(_set: boolean) => {}}
      />
    </VStack>
  );
};

const AutoUpdateFields = ({
  autoUpdateFieldsRef,
}: {
  autoUpdateFieldsRef: MutableRefObject<FieldData>;
}) => {
  const setResCode = useModifyAutoUpdateStore((s) => s.setResCode);
  const [_, setProviderOptions] =
    purchaseActionManager.state.useGlobalState('providerOptions');

  const onFindReservation = () => {
    const { value, dateModified, reservationNumber } = autoUpdateFieldsRef.current;
    setResCode(reservationNumber);
    setProviderOptions({
      expectedChargeByCompany: value,
      modifyDate: dateModified,
    } as ProviderOptions);
  };

  return (
    <VStack space="2.5">
      <HStack space="2.5">
        <FieldInput
          label={translate('supportRequests.modifyPanel.reservationNumber')}
          onChangeText={(v) => (autoUpdateFieldsRef.current['reservationNumber'] = v)}
        />
        <View style={{ alignSelf: 'flex-end' }}>
          <Button size="small" variant="contained" onPress={onFindReservation}>
            <Text.Small color="white">
              {translate('supportRequests.modifyPanel.findReservation')}
            </Text.Small>
          </Button>
        </View>
      </HStack>
      <HStack space="2.5">
        <FieldInput
          label={translate('supportRequests.modifyPanel.valueOfOpCurrency')}
          initialValue={autoUpdateFieldsRef.current['value'].toFixed(2).toString()}
          onChangeText={(v) => {
            const numerical = Number(v);
            if (isNaN(numerical)) {
              autoUpdateFieldsRef.current['value'] = 0;
            } else {
              autoUpdateFieldsRef.current['value'];
            }
          }}
        />
        <FieldInput
          label={translate('supportRequests.modifyPanel.dateModified')}
          initialValue={autoUpdateFieldsRef.current['dateModified']}
          onChangeText={(v) => (autoUpdateFieldsRef.current['dateModified'] = v)}
        />
      </HStack>
    </VStack>
  );
};

const FieldInput = ({
  label,
  onChangeText,
  initialValue,
}: {
  label: string;
  onChangeText: (v: string) => void;
  initialValue?: string;
}) => {
  const [textValue, setTextValue] = useState<string>(initialValue ?? '');

  return (
    <VStack space="2.5" flex={1}>
      <AppText.ExtraSmall>{label}</AppText.ExtraSmall>
      <BaseInput
        value={textValue}
        onChangeText={(v) => {
          setTextValue(v);
          onChangeText(v);
        }}
      />
    </VStack>
  );
};
