import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { CustomerClient } from '@naus-code/naus-admin-types';

import { useCreateSupport } from '~/components/Customer/Modals/CreateRequest';
import { useAppNavigation } from '~/hooks/nav.hook';
import { useInvalidateCustomerBookingListId } from '~/screens/Support/Bookings/BookingsByCustomerId/BookingByCustomerId.Utils';
import { useInvalidateCustomerBookingListByEmail } from '~/screens/Support/Bookings/BookingsByEmail/BookingByEmail.Utils';
import {
  useInvalidateCustomerPurchaseListByEmail,
  useInvalidateCustomerPurchaseListId,
} from '~/screens/Support/Purchases/PurchasesByCustomerId/Purchase.List';
import {
  useInvalidateCustomerRequestListByEmail,
  useInvalidateCustomerRequestListById,
} from '~/screens/Support/Requests/Customer/SupportRequest.List';
import { translate } from '~/utils/translation.utils';

import { PressableCard } from '../Card/PressableCard';
import { SwitchStack } from '../Stack/SwitchStack';
import { SupportDetailsModalKey } from '../SupportRequest/MobileSupportPanel';
import { useAddCustomerVoucher } from './Modals/CreateVoucher';
import { useRecentPayments } from './Modals/RecentPayments';

export const CustomerActions = ({
  customer,
  supportEmail,
}: {
  customer: CustomerClient;
  supportEmail: string;
}) => {
  const { open: openVoucherModal } = useAddCustomerVoucher();
  const { closeModal } = useModal();
  const { navigate } = useAppNavigation();

  //Invalidations
  const { invalidate: invalidateEmailRequests, isLoading: isLoadingReqByEmail } =
    useInvalidateCustomerRequestListByEmail();
  const { invalidate: invalidateCustomerIdRequests, isLoading: isLoadingReqById } =
    useInvalidateCustomerRequestListById();

  const {
    invalidate: invalidateCustomerIdPurchases,
    isLoading: isLoadingPurchasesByEmail,
  } = useInvalidateCustomerPurchaseListId();
  const {
    invalidate: invalidateCustomerEmailPurchases,
    isLoading: isLoadingPurchasesById,
  } = useInvalidateCustomerPurchaseListByEmail();

  const { invalidate: invalidateCustomerIdBookings, isLoading: isLoadingBookingId } =
    useInvalidateCustomerBookingListId();
  const {
    invalidate: invalidateCustomerEmailBookings,
    isLoading: isLoadingBookingEmail,
  } = useInvalidateCustomerBookingListByEmail();
  const create = useCreateSupport();
  const { open: openRecentPayments } = useRecentPayments();

  return (
    <VStack space="2.5" p="2.5">
      <PressableCard
        p="2.5"
        py="4"
        bc="monochrome-light"
        onPress={() => {
          openRecentPayments(customer._id);
        }}
      >
        <Text.Body2Regular align="center" color="monochrome-dark">
          {translate('customer.recentPayments')}
        </Text.Body2Regular>
      </PressableCard>
      <PressableCard
        p="2.5"
        py="4"
        bc="monochrome-light"
        onPress={() => {
          openVoucherModal(customer._id);
        }}
      >
        <Text.Body2Regular align="center" color="monochrome-dark">
          {translate('customer.createVoucher')}
        </Text.Body2Regular>
      </PressableCard>
      <PressableCard
        p="2.5"
        py="4"
        bc="monochrome-light"
        onPress={() => {
          create.open({
            customerId: customer._id,
          });
        }}
      >
        <Text.Body2Regular align="center" color="monochrome-dark">
          {translate('customer.createSupportRequest')}
        </Text.Body2Regular>
      </PressableCard>
      <SwitchStack space="2.5">
        <VStack flex={1}>
          <PressableCard
            isDisabled={isLoadingReqByEmail}
            p="2.5"
            py="4"
            bc="monochrome-light"
            onPress={async () => {
              await invalidateCustomerIdRequests(customer._id);
              if (customer.p_email) {
                await invalidateEmailRequests(customer.p_email);
              }
              navigate('CustomerSupportRequests', {
                email: customer.p_email || supportEmail,
                customerId: customer._id,
              });
              closeModal('CustomerItem');
              closeModal(SupportDetailsModalKey);
            }}
          >
            {isLoadingReqByEmail ? (
              <LoadingIndicator />
            ) : (
              <Text.Body2Regular align="center" color="monochrome-dark">
                {translate('customer.customerSupportRequests')}
              </Text.Body2Regular>
            )}
          </PressableCard>
        </VStack>
        <VStack flex={1}>
          <PressableCard
            isDisabled={isLoadingReqById}
            p="2.5"
            py="4"
            bc="monochrome-light"
            onPress={async () => {
              if (!customer.p_email) {
                showToast(translate('customer.emailNotFound'), {
                  type: 'error',
                });
                return null;
              }
              await invalidateCustomerIdRequests(customer._id);
              if (customer.p_email) {
                await invalidateEmailRequests(customer.p_email);
              }
              navigate('CustomerSupportRequests', {
                email: supportEmail,
              });
              closeModal('CustomerItem');
              closeModal(SupportDetailsModalKey);
            }}
          >
            {isLoadingReqById ? (
              <LoadingIndicator />
            ) : (
              <Text.Body2Regular align="center" color="monochrome-dark">
                {translate('customer.customerSupportRequestsByEmail')}
              </Text.Body2Regular>
            )}
          </PressableCard>
        </VStack>
      </SwitchStack>
      <SwitchStack space="2.5">
        <VStack flex={1}>
          <PressableCard
            isDisabled={isLoadingPurchasesByEmail}
            p="2.5"
            py="4"
            bc="monochrome-light"
            onPress={async () => {
              await invalidateCustomerIdPurchases(customer._id);
              navigate('PurchasesByCustomerId', {
                customerId: customer._id,
              });
              closeModal('CustomerItem');
              closeModal(SupportDetailsModalKey);
            }}
          >
            {isLoadingPurchasesByEmail ? (
              <LoadingIndicator />
            ) : (
              <Text.Body2Regular align="center" color="monochrome-dark">
                {translate('customer.customerPurchases')}
              </Text.Body2Regular>
            )}
          </PressableCard>
        </VStack>
        <VStack flex={1}>
          <PressableCard
            isDisabled={isLoadingPurchasesById}
            p="2.5"
            py="4"
            bc="monochrome-light"
            onPress={async () => {
              if (!customer.p_email && !supportEmail) {
                showToast(translate('customer.emailNotFound'), {
                  type: 'default',
                });
                // return null;
              }
              await invalidateCustomerEmailPurchases(customer.p_email || supportEmail);
              navigate('PurchasesByEmail', {
                email: customer.p_email || supportEmail,
              });
              closeModal('CustomerItem');
              closeModal(SupportDetailsModalKey);
            }}
          >
            {isLoadingPurchasesById ? (
              <LoadingIndicator />
            ) : (
              <Text.Body2Regular align="center" color="monochrome-dark">
                {translate('customer.customerPurchasesEmail')}
              </Text.Body2Regular>
            )}
          </PressableCard>
        </VStack>
      </SwitchStack>
      <SwitchStack space="2.5">
        <VStack flex={1}>
          <PressableCard
            isDisabled={isLoadingBookingEmail}
            p="2.5"
            py="4"
            bc="monochrome-light"
            onPress={async () => {
              await invalidateCustomerIdBookings(customer._id);

              navigate('BookingsByCustomerId', {
                customerId: customer._id,
              });
              closeModal('CustomerItem');
              closeModal(SupportDetailsModalKey);
            }}
          >
            {isLoadingBookingEmail ? (
              <LoadingIndicator />
            ) : (
              <Text.Body2Regular align="center" color="monochrome-dark">
                {translate('customer.customerBookings')}
              </Text.Body2Regular>
            )}
          </PressableCard>
        </VStack>
        <VStack flex={1}>
          <PressableCard
            isDisabled={isLoadingBookingId}
            p="2.5"
            py="4"
            bc="monochrome-light"
            onPress={async () => {
              if (!customer.p_email) {
                showToast(translate('customer.emailNotFound'), {
                  type: 'default',
                });
                // return null;
              }
              await invalidateCustomerEmailBookings(customer.p_email || supportEmail);
              navigate('BookingsByEmail', {
                email: customer.p_email || supportEmail,
              });
              closeModal('CustomerItem');
              closeModal(SupportDetailsModalKey);
            }}
          >
            {isLoadingBookingId ? (
              <LoadingIndicator />
            ) : (
              <Text.Body2Regular align="center" color="monochrome-dark">
                {translate('customer.customerBookingsEmail')}
              </Text.Body2Regular>
            )}
          </PressableCard>
        </VStack>
      </SwitchStack>
    </VStack>
  );
};
