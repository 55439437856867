import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { AdminUserListItem } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';
import { RefreshControl } from 'react-native';

import { useCreateAdminUserModal } from '~/components/AdminUsers/Modals/createUserModal';
import { FlatList } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { PageHeader } from '~/components/Page/Page.Header';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { usersApi } from '../Users.api';
import { AdminUserListItemComponent } from './AdminUser.ListItem';

export interface AdminUserListProps {
  onPress: (item: AdminUserListItem) => void;
}

export const AdminUserListNative = () => {
  const navigate = useAppNavigation();
  return (
    <AdminUserList
      onPress={(item) => {
        navigate.navigate('AdminUser-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const AdminUserList = (props: AdminUserListProps) => {
  const { spacing, shading } = useAppTheme();
  const { data, isLoading, isFetching, refetch } = usersApi.useGetUserAdminAllListQuery();

  const renderItem = useCallback((user: AdminUserListItem) => {
    return <AdminUserListItemComponent key={user.id} user={user} />;
  }, []);

  const list = data?.list || [];

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <AdminUserListHeader />
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        itemStyle={shading.light}
        feedback={translate('users.emptyAdminUser')}
        isLoading={isLoading}
        data={list}
        renderItem={renderItem}
        onPressItem={props.onPress}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};

const AdminUserListHeader = () => {
  const { open: openUserCreate } = useCreateAdminUserModal();
  return (
    <PageHeader
      childRight={<IconButton onPress={openUserCreate} icon="plus" color="monochrome" />}
      title={translate('users.adminUsers')}
      withBack={isNativeMobile}
    />
  );
};
