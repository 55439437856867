import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { VoucherRedeem } from '@naus-code/naus-client-types';
import { useState } from 'react';

import { VoucherModalKey } from '~/components/Voucher/Voucher.utils';
import { useAppNavigation } from '~/hooks/nav.hook';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { voucherApi } from '~/screens/Vouchers/voucherApi';
import { translate } from '~/utils/translation.utils';

import { CardWithHeader } from '../Card/CardWithHeader';
import { Field } from '../Card/Field';
import { useCustomerModal } from '../Customer/Customer.Modal';
import { LiveDateText } from '../DateText';
import { SwitchStack } from '../Stack/SwitchStack';
import { AppText } from '../Text';

export const VoucherItem = ({
  voucherId,
  customerEmail,
}: {
  voucherId: string;
  customerEmail?: string;
}) => {
  const { data: voucherData } = voucherApi.useGetVoucherQuery(voucherId);
  const { closeModal } = useModal();
  const { open: openCustomerModal } = useCustomerModal();
  if (voucherData === undefined) {
    return null;
  }
  const voucher = voucherData.voucher;
  if (voucher === undefined) {
    return null;
  }

  const { tinyId, dateExpire, remainingValue, redeems, ccy } = voucher;
  const onPressCustomerEmail = (email: string, customerId: string) => {
    closeModal(VoucherModalKey);
    openCustomerModal(customerId, email);
  };
  return (
    <CardWithHeader
      w={isNativeMobile ? undefined : 600}
      shadingOff
      headerText={`${translate('voucher.voucherInfo')}`}
    >
      <SwitchStack p="2.5" space={isNativeMobile ? '1' : '2.5'}>
        <VStack flex={1} space="2">
          <HStack space="6" justify="space-between">
            <Text.Small color="monochrome-extraDark" type="small">
              {translate('voucher.tinyId')}
            </Text.Small>
            <Text.Body2Regular>{tinyId}</Text.Body2Regular>
          </HStack>
          <HStack space="6" justify="space-between">
            <Text.Small color="monochrome-extraDark" type="small">
              {translate('voucher.expiryDate')}
            </Text.Small>
            <LiveDateText
              align="right"
              numberOfLines={1}
              dateTime={dateExpire}
              formatOption="fullExpiryDependent&Date"
              type="small"
            />
          </HStack>
          {voucher.adminReference && (
            <Field
              label={translate('voucher.reference')}
              value={voucher.adminReference}
            />
          )}
          {voucher.adminId && (
            <Field label={translate('voucher.adminId')} value={voucher.adminId} />
          )}
        </VStack>
        <VStack flex={1} space="2">
          <HStack justify="space-between">
            <Text.Small color="monochrome-extraDark" flex={1}>
              {translate('voucher.remainingValue')}
            </Text.Small>
            <Text.Small>{getPriceWithSymbol(remainingValue, ccy)}</Text.Small>
          </HStack>
          <HStack align="flex-start" pt="2">
            <Text.Small color="monochrome-extraDark" flex={1}>
              {translate('voucher.redeemedValue')}
            </Text.Small>
            <Redeems redeems={redeems} customerId={voucher.customerId} />
          </HStack>
          {voucher.p_email && (
            <HStack space="6" justify="space-between">
              <Text.Small color="monochrome-extraDark" type="small">
                {translate('voucher.email')}
              </Text.Small>
              <Text.Small
                color="primary"
                numberOfLines={1}
                onPress={() => {
                  if (!voucher.customerId) {
                    return null;
                  }
                  onPressCustomerEmail(
                    voucher.p_email || customerEmail || '',
                    voucher.customerId,
                  );
                }}
              >
                {voucher.p_email}
              </Text.Small>
            </HStack>
          )}
          {voucher.customerId && (
            <HStack space="6" justify="space-between">
              <Text.Small numberOfLines={1} color="monochrome-extraDark" type="small">
                {translate('voucher.customerId')}
              </Text.Small>
              <Text.Small
                numberOfLines={1}
                flex={1}
                color="primary"
                onPress={() => {
                  if (!voucher.customerId) {
                    return null;
                  }
                  onPressCustomerEmail(
                    voucher.p_email || customerEmail || '',
                    voucher.customerId,
                  );
                }}
              >
                {voucher.customerId}
              </Text.Small>
            </HStack>
          )}
        </VStack>
      </SwitchStack>
    </CardWithHeader>
  );
};

const Redeems = ({
  redeems,
  customerId,
  customerEmail,
}: {
  redeems: VoucherRedeem[];
  customerId?: string;
  customerEmail?: string;
}) => {
  const totalRedeemed = redeems.reduce(
    (total: number, redeem: VoucherRedeem) => total + redeem.value,
    0,
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const { navigate } = useAppNavigation();
  if (redeems.length === 0) {
    return <Text.Small color="monochrome-mid">{0}</Text.Small>;
  }
  const currency = redeems[0].ccy;

  return (
    <>
      {isExpanded ? (
        <VStack>
          <Text.Small align="right" color="primary" onPress={() => setIsExpanded(false)}>
            {' '}
            {getPriceWithSymbol(totalRedeemed, currency)}
          </Text.Small>
          {redeems.map((redeem, index) => {
            const { timestamp, value, ccy } = redeem;
            return (
              <HStack key={index} justify="space-between">
                {redeem.bookingId && (
                  <AppText.ExtraSmall
                    onPress={() => {
                      if (customerId) {
                        navigate('BookingsByCustomerId', {
                          customerId,
                          id: redeem.bookingId,
                        });
                      }
                      if (customerEmail) {
                        navigate('BookingsByEmail', {
                          email: customerEmail,
                          id: redeem.bookingId,
                        });
                      }
                    }}
                  >
                    {redeem.bookingId}
                  </AppText.ExtraSmall>
                )}
                <AppText.ExtraSmall>
                  {getPriceWithSymbol(value, ccy) + '  '}
                </AppText.ExtraSmall>
                <LiveDateText
                  align="right"
                  numberOfLines={1}
                  dateTime={timestamp}
                  formatOption="fullDate"
                  type="extraSmall"
                />
              </HStack>
            );
          })}
        </VStack>
      ) : (
        <Text.Small color="primary" onPress={() => setIsExpanded(true)}>
          {getPriceWithSymbol(totalRedeemed, currency)}
        </Text.Small>
      )}
    </>
  );
};
