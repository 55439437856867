import { ConfigForData } from '@droplet-tech-code/core-common-types';

import { CancelForm } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.Form';
import { CancelPurchaseForm } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.utils';

const config: ConfigForData = [
  {
    items: [
      {
        id: 'expectedRefundFromCompany',
        type: 'number',
        label: 'Expected refund from company',
        feedbackOnlyWithError: true,
        multipleOf: 100,
      },
    ],
  },

  {
    items: [
      {
        id: 'customerRefundInBookCcy',
        type: 'number',
        label: 'Expected refund for customer (in customer’s currency)',
        feedbackOnlyWithError: true,
        multipleOf: 100,
      },
    ],
  },

  {
    items: [
      {
        id: 'refundMethod',
        type: 'single-select',
        label: 'Refund method',
        list: [
          {
            value: 'bank',
            label: 'Bank refund',
          },
          {
            value: 'voucher',
            label: 'Voucher refund',
          },
        ],
        feedbackOnlyWithError: true,
      },
    ],
  },
  {
    items: [
      {
        id: 'serviceFeeInBookCcy',
        type: 'number',
        label: 'Service fee',
        feedbackOnlyWithError: true,
        multipleOf: 100,
      },
    ],
  },
];

export const CancelCustomer = (props: CancelPurchaseForm) => {
  return <CancelForm {...props} config={config} />;
};
