import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { getScreenWidth } from '@droplet-tech-code/core-elements/module/utils/utils.ui';
import { GetVesselResponse } from '@naus-code/naus-admin-types';
import { Image, Pressable } from 'react-native';

import { Card } from '~/components/Card/Card';
import { VesselImage } from '~/screens/Vessels/Item/Vessel.Image';
import { translate } from '~/utils/translation.utils';

export const VesselPreviews = ({ data }: { data: GetVesselResponse }) => {
  const { openModal, closeModal } = useModal();
  return (
    <VStack space="3" p="3" flex={1}>
      <HStack>
        <View flex={1} />
        <Button
          variant="outline"
          onPress={() => {
            openModal(
              <>
                <Modal.Header
                  title="Vessel preview"
                  divider
                  onPress={() => {
                    closeModal('VesselPreviews');
                  }}
                />
                <VesselImage
                  vesselId={data.vessel.id}
                  onUpload={() => {
                    closeModal('VesselPreviews');
                  }}
                />
              </>,
              {
                key: 'VesselPreviews',
                modalStyle: {
                  minWidth: getScreenWidth() * 0.9,
                  maxWidth: getScreenWidth() * 0.9,
                },
              },
            );
          }}
        >
          {translate('vessels.generateSizes')}
        </Button>
      </HStack>

      <OriginalImage data={data} />
      <BannerImage data={data} />
      <FrameImage data={data} />
      <ThumbImage data={data} />
      <SmallImage data={data} />
    </VStack>
  );
};

const OriginalImage = ({ data }: { data: GetVesselResponse }) => {
  const { openModal } = useModal();

  const resize = 0.5;
  return (
    <Pressable
      onPress={() => {
        openModal(
          <Modal.Body p="3">
            <Image
              style={{
                width: 652,
                height: 300,
                backgroundColor: 'grey',
              }}
              source={{
                uri: data.ogImg,
              }}
            />
          </Modal.Body>,
          {
            key: 'original-vessel',
          },
        );
      }}
    >
      <Card p="3">
        <VStack space="3">
          <Text.Body2Regular>
            {translate('vessels.originalImage')} (652 x 300)
          </Text.Body2Regular>
          <Image
            style={{
              width: 652 * resize,
              height: 300 * resize,
              backgroundColor: 'grey',
            }}
            source={{
              uri: data.ogImg,
            }}
          />
        </VStack>
      </Card>
    </Pressable>
  );
};

const BannerImage = ({ data }: { data: GetVesselResponse }) => {
  const { openModal } = useModal();

  const resize = 0.5;
  return (
    <Pressable
      onPress={() => {
        openModal(
          <Modal.Body p="3">
            <Image
              style={{
                width: 1024,
                height: 350,
                backgroundColor: 'grey',
              }}
              source={{
                uri: data.bannerImg,
              }}
            />
          </Modal.Body>,
          {
            key: 'banner-vessel',
          },
        );
      }}
    >
      <Card p="3">
        <VStack space="3">
          <Text.Body2Regular>
            {translate('vessels.bannerImage')} (1024 x 350)
          </Text.Body2Regular>
          <Image
            style={{
              width: 1024 * resize,
              height: 350 * resize,
              backgroundColor: 'grey',
            }}
            source={{
              uri: data.bannerImg,
            }}
          />
        </VStack>
      </Card>
    </Pressable>
  );
};

const FrameImage = ({ data }: { data: GetVesselResponse }) => {
  const resize = 0.5;
  const { openModal } = useModal();

  return (
    <Pressable
      onPress={() => {
        openModal(
          <Modal.Body p="3">
            <Image
              style={{
                width: 650,
                height: 271,
                backgroundColor: 'grey',
              }}
              source={{
                uri: data.frameImg,
              }}
            />
          </Modal.Body>,
          {
            key: 'frame-vessel',
          },
        );
      }}
    >
      <Card p="3">
        <VStack space="3">
          <Text.Body2Regular>{translate('vessels.frame')} (650 x 271)</Text.Body2Regular>
          <Image
            style={{
              width: 650 * resize,
              height: 271 * resize,
              backgroundColor: 'grey',
            }}
            source={{
              uri: data.frameImg,
            }}
          />
        </VStack>
      </Card>
    </Pressable>
  );
};

const ThumbImage = ({ data }: { data: GetVesselResponse }) => {
  const resize = 0.5;
  const { openModal } = useModal();

  return (
    <Pressable
      onPress={() => {
        openModal(
          <Modal.Body p="3">
            <Image
              style={{
                width: 340,
                height: 150,
                backgroundColor: 'grey',
              }}
              source={{
                uri: data.thumbnailImg,
              }}
            />
          </Modal.Body>,
          {
            key: 'thumb-vessel',
          },
        );
      }}
    >
      <Card p="3">
        <VStack space="3">
          <Text.Body2Regular>{translate('vessels.thumb')} (340 x 150)</Text.Body2Regular>
          <Image
            style={{
              width: 340 * resize,
              height: 150 * resize,
              backgroundColor: 'grey',
            }}
            source={{
              uri: data.thumbnailImg,
            }}
          />
        </VStack>
      </Card>
    </Pressable>
  );
};

const SmallImage = ({ data }: { data: GetVesselResponse }) => {
  const resize = 0.5;
  const { openModal } = useModal();

  return (
    <Pressable
      onPress={() => {
        openModal(
          <Modal.Body p="3">
            <Image
              style={{
                width: 145,
                height: 80,
                backgroundColor: 'grey',
              }}
              source={{
                uri: data.tinyThumbnailImg,
              }}
            />
          </Modal.Body>,
          {
            key: 'small-vessel',
          },
        );
      }}
    >
      <Card p="3">
        <VStack space="3">
          <Text.Body2Regular>{translate('vessels.small')} (145 x 80)</Text.Body2Regular>
          <Image
            style={{
              width: 145 * resize,
              height: 80 * resize,
              backgroundColor: 'grey',
            }}
            source={{
              uri: data.tinyThumbnailImg,
            }}
          />
        </VStack>
      </Card>
    </Pressable>
  );
};
