import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { ScreenPropVouchersByEmail } from '~/navigation/Navigation.types';

import { VoucherItemComponent } from '../Voucher.Item';
import { VoucherByEmailList, VoucherByEmailListNative } from './VoucherByEmail.List';

export const VoucherByEmailScreen = (props: ScreenPropVouchersByEmail) => {
  return (
    <PageContainer>
      <VoucherByEmailLayout {...props} />
    </PageContainer>
  );
};

export const VoucherByEmailLayout = (props: ScreenPropVouchersByEmail) => {
  const voucherEmail = props.route.params?.voucherEmail;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <VoucherByEmailList
          voucherEmail={voucherEmail}
          onPress={(voucherId) => {
            setId(voucherId);
          }}
        />
      ) : (
        <VoucherByEmailListNative voucherEmail={voucherEmail} />
      )}
      {id ? (
        <VoucherItemComponent voucherId={id} onClose={() => setId(undefined)} />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
