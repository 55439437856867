import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const ActiveNoticesListHeader = ({
  refetch,
  isLoading,
}: {
  refetch: () => void;
  isLoading?: boolean;
}) => {
  return (
    <PageHeader
      title={translate('notices.activeNotices')}
      withBack={isNativeMobile}
      iconRight={{
        icon: 'refresh',
        onPress: refetch,
        color: 'monochrome',
        isLoading,
      }}
    />
  );
};
