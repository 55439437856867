import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  getErrorResponse,
  isRTKErrorResponse,
} from '@droplet-tech-code/core-elements/module/utils/error';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { PurchaseClientGetProviderReservationWithBookingIdRequest } from '@naus-code/naus-admin-types';
import { useRef, useState } from 'react';

import { BookingInfo } from '~/components/Bookings/BookingInfo';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { PurchaseListItemInternalComponent } from '~/screens/Support/Purchases/Purchase.ListItem';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { TicketDetails } from '~/screens/Support/Purchases/PurchaseItem';
import { purchaseApi } from '~/screens/Support/Purchases/Purchases.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

const ModalImportPurchaseFromProvider = 'ModalImportPurchaseFromProvider';

const ImportPurchaseFromProvider = ({
  bookingId,
  resCode,
  provider,
  supportId,
}: PurchaseClientGetProviderReservationWithBookingIdRequest & {
  supportId: string;
}) => {
  // bookingId

  const [checkedItem, setCheckedItem] = useState<number | undefined>(undefined);
  const { closeModal } = useModal();
  const [silentOn, setSilentOn] = useState(false);
  const [refreshPDF, setRefreshPdf] = useState(false);
  const { data, isLoading } =
    purchaseApi.useGetPurchaseRetrieveFromProviderWithBookingIdQuery({
      bookingId,
      resCode,
      provider,
    });

  const [importPurchase, { isLoading: isImporting }] =
    purchaseActionApi.useImportPurchaseFromProviderMutation();

  return (
    <DelayRender isLoading={isLoading}>
      {data ? (
        <VStack
          flex={1}
          style={[
            isWeb && {
              maxHeight: window.innerHeight * 0.85,
            },
          ]}
        >
          <Modal.Header
            title=""
            divider
            onPress={() => {
              closeModal(ModalImportPurchaseFromProvider);
            }}
            // children={isWeb ? TripSelector : null}
          />

          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              paddingTop: isNativeMobile ? 12 : undefined,
            }}
          >
            {/* {isNativeMobile ? TripSelector : null} */}
            <SwitchStack space="3" align={isWeb ? 'flex-start' : undefined}>
              <VStack space="3" p="4" flex={1}>
                <Text.Body2Regular>
                  {translate('purchases.purchaseTool.purchaseCompare.currentPurchase')}
                </Text.Body2Regular>
                <BookingInfo bookingId={bookingId} supportId={supportId} />
                {data.reservation.purchases.map((purchase, ix) => {
                  const isChecked = checkedItem === ix;
                  return (
                    <HStack key={ix} space="3">
                      <CheckBox
                        checked={isChecked}
                        onPress={() => {
                          setCheckedItem(isChecked ? undefined : ix);
                        }}
                      />
                      <VStack
                        bg={isChecked ? 'primary-base' : 'monochrome-base'}
                        br={12}
                        p="3"
                        b={1}
                        bc={isChecked ? 'primary-mid' : 'white'}
                      >
                        <PurchaseListItemInternalComponent
                          key={ix}
                          purchase={purchase}
                          compareMode
                        />
                        <DottedLine />
                        <VStack space="4">
                          {purchase.tickets.map((ticket, iy) => {
                            return (
                              <VStack space="2" key={iy}>
                                <TicketDetails
                                  ticket={ticket}
                                  ccySymbol={purchase.ccySymbol}
                                />

                                <View>
                                  {purchase.tickets.length - 1 === iy ? null : (
                                    <DottedLine />
                                  )}
                                </View>
                              </VStack>
                            );
                          })}
                        </VStack>
                      </VStack>
                    </HStack>
                  );
                })}
              </VStack>
            </SwitchStack>
          </ScrollView>

          <Modal.Footer
            divider
            isLoading={isLoading || isImporting}
            confirm={translate(
              silentOn
                ? 'purchases.purchaseTool.purchaseCompare.updateSilent'
                : 'purchases.purchaseTool.purchaseCompare.update',
            )}
            onConfirm={async () => {
              if (checkedItem === undefined || !data.reservation.purchases.length) {
                return;
              }

              const response = await importPurchase({
                bookingId,
                silentUpdate: silentOn,
                refreshPdf: refreshPDF,
                supportId,
                purchase: data.reservation.purchases[checkedItem],
              });

              handleRTKResponse({
                response,
                onSuccess: (data) => {
                  if (isRTKErrorResponse(data)) {
                    showToast(getErrorResponse(data.error).message, {
                      type: 'error',
                    });
                    return;
                  }
                  closeModal(ModalImportPurchaseFromProvider);
                },
              });
            }}
            onCancel={() => {
              // closeModal(TabModalKey);
              closeModal(ModalImportPurchaseFromProvider);
            }}
          >
            <SwitchStack space="3">
              <HStack space="1">
                <Toggle
                  color="primary-mid"
                  value={silentOn}
                  onChange={(v) => setSilentOn(v)}
                />
                <Text.Body2Regular color="monochrome-extraDark">
                  {translate('purchases.purchaseTool.purchaseCompare.silentUpdate')}
                </Text.Body2Regular>
              </HStack>

              <HStack space="1">
                <Toggle
                  color="primary-mid"
                  value={refreshPDF}
                  onChange={(v) => setRefreshPdf(v)}
                />
                <Text.Body2Regular color="monochrome-extraDark">
                  {translate('purchases.purchaseTool.purchaseCompare.refreshPDF')}
                </Text.Body2Regular>
              </HStack>
            </SwitchStack>
          </Modal.Footer>
        </VStack>
      ) : null}
    </DelayRender>
  );
};

const useModalInputProvider = () => {
  const ModalKey = new Date().valueOf() + 'resCode';
  const { openModal, closeModal } = useModal();

  const { data, isLoading } =
    purchaseApi.useGetPurchaseRetrieveFromProviderFormConfigQuery();

  const refCurrent = {
    resolve: (() => {}) as (
      value:
        | Omit<PurchaseClientGetProviderReservationWithBookingIdRequest, 'bookingId'>
        | undefined
        | PromiseLike<
            | Omit<PurchaseClientGetProviderReservationWithBookingIdRequest, 'bookingId'>
            | undefined
          >,
    ) => void,
  };

  const form = useRef(new FormHandler());

  return {
    enterCode: async (): Promise<
      | Omit<PurchaseClientGetProviderReservationWithBookingIdRequest, 'bookingId'>
      | undefined
    > => {
      return new Promise((res) => {
        refCurrent.resolve = res;
        openModal(
          <VStack>
            <Modal.Header
              title={translate('purchases.purchaseTool.purchaseCompare.resCode')}
              divider
              onPress={() => {
                refCurrent.resolve(undefined);
                closeModal(ModalKey);
              }}
            />
            <DelayRender isLoading={isLoading}>
              {data ? <FormBuilder form={form.current} data={data} /> : null}
            </DelayRender>

            <Modal.Footer
              divider
              onConfirm={() => {
                const { valid, value } = form.current.getValue<{
                  resCode: string;
                  provider: string;
                  currency: string;
                }>();
                if (valid) {
                  refCurrent.resolve(value);
                  closeModal(ModalKey);
                }
              }}
              onCancel={() => {
                refCurrent.resolve(undefined);
                closeModal(ModalKey);
              }}
            />
          </VStack>,
          { key: ModalKey, type: 'pop-up' },
        );
      });
    },
    cancel: () => {
      refCurrent.resolve(undefined);
      closeModal(ModalKey);
    },
  };
};

export const useImportPurchaseFromProvider = () => {
  const { openModal, closeModal } = useModal();
  const inputResCode = useModalInputProvider();
  return {
    open: async ({ bookingId, supportId }: { bookingId: string; supportId: string }) => {
      const value = await inputResCode.enterCode();

      if (!value) {
        return;
      }

      openModal(
        <ImportPurchaseFromProvider
          {...value}
          bookingId={bookingId}
          supportId={supportId}
        />,
        {
          key: ModalImportPurchaseFromProvider,
          type: 'action-sheet-full',
        },
      );
    },
    close: () => {
      closeModal(ModalImportPurchaseFromProvider);
    },
  };
};
