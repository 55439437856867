import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import {
  copyToClipboard,
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { CustomerClient, RatingClient } from '@naus-code/naus-admin-types';
import { CustomerDevice } from '@naus-code/naus-client-types';
import { useState } from 'react';
import { Pressable } from 'react-native';

import { translate } from '~/utils/translation.utils';

import { Field } from '../Card/Field';
import { SwitchStack } from '../Stack/SwitchStack';
import { useVoucherModal } from '../Voucher/Modals/Voucher.Modal';
import {
  PassengerInfo,
  RatingCustomerInfo,
  VehicleInfo,
} from './utils/CusotmerInfo.utils';

export const CustomerCard = ({ customer }: { customer: CustomerClient }) => {
  const { open: openVoucher } = useVoucherModal();

  const {
    p_firstName,
    p_lastName,
    p_phone,
    p_email,
    lang,
    voucherCount,
    devices,
    _id: customerId,
  } = customer;

  const device = devices[devices.length - 1] as CustomerDevice | undefined;

  return (
    <SwitchStack p="2.5" space={isNativeMobile ? '1' : '2.5'}>
      <VStack space="2" flex={isWeb ? 1 : undefined}>
        <HStack space="6" justify="space-between">
          <Text.Small color="monochrome-extraDark" type="small">
            {translate('customer.nameUpper')}
          </Text.Small>
          <Text.Body2Regular>{`${p_firstName}, ${p_lastName}`}</Text.Body2Regular>
        </HStack>
        <HStack space="6" justify="space-between">
          <Text.Small color="monochrome-extraDark" type="small">
            {translate('customer.emailUpper')}
          </Text.Small>
          <Text.Body2Regular align="right">{p_email}</Text.Body2Regular>
        </HStack>
        <HStack space="6" justify="space-between">
          <Text.Small color="monochrome-extraDark" type="small">
            {translate('customer.phoneUpper')}
          </Text.Small>
          <Text.Body2Regular align="right">{p_phone}</Text.Body2Regular>
        </HStack>
        <HStack>
          <Text.Small flex={1} color="monochrome-extraDark" type="small">
            {translate('customer.languageUpper')}
          </Text.Small>
          <Text.Body2Regular align="right">{`${lang}`}</Text.Body2Regular>
        </HStack>
        <HStack>
          <Text.Small flex={1} color="monochrome-dark" type="small">
            {translate('customer.vouchers')}
          </Text.Small>
          <Pressable
            disabled={voucherCount === 0}
            onPress={() => openVoucher(customer._id, p_email)}
          >
            <Text.Body2Regular
              color={voucherCount > 0 ? 'primary' : 'monochrome-mid'}
              style={{
                textDecorationLine: voucherCount > 0 ? 'underline' : 'none',
                alignSelf: 'flex-end',
              }}
              align="right"
            >{`${voucherCount}`}</Text.Body2Regular>
          </Pressable>
        </HStack>
        <HStack>
          <Text.Small flex={1} color="monochrome-extraDark" type="small">
            {translate('customer.deviceId')}
          </Text.Small>

          <Text.Body2Regular
            style={{
              alignSelf: 'flex-end',
            }}
            align="right"
          >
            {device ? device._id.split('_').join(' ') : 'N/A'}
          </Text.Body2Regular>
        </HStack>

        <HStack>
          <Text.Small flex={1} color="monochrome-extraDark" type="small">
            {translate('customer.appVersion')}
          </Text.Small>
          <Text.Body2Regular
            style={{
              alignSelf: 'flex-end',
              width: 200,
            }}
            align="right"
            numberOfLines={2}
            onPress={() => {
              if (device?.version) {
                copyToClipboard(device.version);
              }
            }}
          >
            {device?.version ? device.version : 'N/A'}
          </Text.Body2Regular>
        </HStack>
        <HStack>
          <Field
            label={translate('customer.id')}
            value={customerId}
            isCopyable
            valueType="small"
          />
        </HStack>
      </VStack>
      <VStack flex={isNativeMobile ? undefined : 1} space={isNativeMobile ? '1' : '2.5'}>
        <SavedVehicles customer={customer} />
        <SavedPassengers customer={customer} />
        <Ratings customer={customer} />
      </VStack>
    </SwitchStack>
  );
};

const Ratings = ({ customer }: { customer: CustomerClient }) => {
  const ratings = customer.ratings;
  const [isExpanded, setExpanded] = useState(false);

  if (!ratings) {
    return null;
  }

  const averageRating =
    Math.round(
      (ratings.reduce((sum, rating: RatingClient) => rating.rating + sum, 0) /
        ratings.length) *
        10,
    ) / 10;

  return (
    <VStack>
      <HStack align="center" justify="center">
        <Text.Small flex={1} color="monochrome-extraDark" type="small">
          {translate('customer.ratings')}
        </Text.Small>
        <Text.Small>{averageRating}</Text.Small>
        <Icon icon="star-filled" iconStyle={{ alignSelf: 'center', paddingBottom: 5 }} />
        <IconButton
          icon={isExpanded ? 'chevron-up' : 'chevron-down'}
          onPress={() => {
            setExpanded(!isExpanded);
          }}
        />
      </HStack>
      {isExpanded &&
        ratings.map((rating, index) => {
          return (
            <VStack key={index}>
              {index != 0 && <Divider />}
              <RatingCustomerInfo rating={rating} />
            </VStack>
          );
        })}
    </VStack>
  );
};

const SavedVehicles = ({ customer }: { customer: CustomerClient }) => {
  const { savedVehicles } = customer;
  const [isExpanded, setExpanded] = useState(false);
  if (savedVehicles.length === 0) {
    return null;
  }
  return (
    <VStack>
      <HStack>
        <Text.Small flex={1} color="monochrome-extraDark" type="small">
          {translate('customer.savedVehicles')}
        </Text.Small>
        <IconButton
          icon={isExpanded ? 'chevron-up' : 'chevron-down'}
          onPress={() => {
            setExpanded(!isExpanded);
          }}
        />
      </HStack>
      {isExpanded &&
        savedVehicles.map((vehicle, index) => {
          return (
            <VStack key={index}>
              {index != 0 && <Divider />}
              <VehicleInfo vehicle={vehicle} />
            </VStack>
          );
        })}
    </VStack>
  );
};

const SavedPassengers = ({ customer }: { customer: CustomerClient }) => {
  const { savedPassengers } = customer;
  const [isExpanded, setExpanded] = useState(false);
  if (savedPassengers.length === 0) {
    return null;
  }
  return (
    <VStack>
      <HStack>
        <Text.Small flex={1} color="monochrome-extraDark" type="small">
          {translate('customer.savedPassengers')}
        </Text.Small>
        <IconButton
          icon={isExpanded ? 'chevron-up' : 'chevron-down'}
          onPress={() => {
            setExpanded(!isExpanded);
          }}
        />
      </HStack>
      {isExpanded &&
        savedPassengers.map((passenger, index) => {
          return (
            <VStack key={index}>
              {index != 0 && <Divider />}
              <PassengerInfo passenger={passenger} />
            </VStack>
          );
        })}
    </VStack>
  );
};
