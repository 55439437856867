import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropPurchasesByPRC } from '~/navigation/Navigation.types';

import { PurchaseItemScreenComponent } from '../PurchaseItem';
import { PurchaseList, PurchaseListNative } from './PurchaseByPRC.List';

export const PurchasesByPRCScreen = (props: ScreenPropPurchasesByPRC) => {
  return (
    <PageContainer>
      <PurchasesLayout {...props} />
    </PageContainer>
  );
};

export const PurchasesLayout = (props: ScreenPropPurchasesByPRC) => {
  const purchaseId = props.route.params?.id;
  const providerReservationCode = props.route.params?.providerReservationCode;

  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(purchaseId);
  }, [purchaseId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <PurchaseList
          onPress={(item) => setId(item.id)}
          providerReservationCode={providerReservationCode}
        />
      ) : (
        <PurchaseListNative providerReservationCode={providerReservationCode} />
      )}
      {id ? (
        <PurchaseItemScreenComponent purchaseId={id} onClose={() => setId(undefined)} />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
