import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SupportConfigAddOfficeConfigRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { getPlugins } from '~/components/Form/Form.Plugins';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { supportConfigApi } from '../../supportConfig.api';

export const AddOfficeModalKey = 'AddModal-Office';
const AddOfficeModal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [createOffice, { isLoading: isCreatingOffice }] =
    supportConfigApi.useCreateOfficeMutation();
  const { data: addOfficeConfig } = supportConfigApi.useGetCreateOfficeFormConfigQuery();

  if (addOfficeConfig === undefined) {
    return <></>;
  }
  if (addOfficeConfig?.check === undefined) {
    return <></>;
  }

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportConfig.newOffice')}
        subtitle={translate('supportConfig.enterNewOffice')}
        divider
        onPress={() => {
          closeModal(AddOfficeModalKey);
        }}
      />
      <ModalScrollView>
        <CreateOfficeForm form={formRef.current} />
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isCreatingOffice}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              SupportConfigAddOfficeConfigRequest['officeConfig']
            >();

          if (valid) {
            const response = await createOffice({
              check: addOfficeConfig.check,
              officeConfig: value,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddOfficeModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddOfficeModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useAddOffice = () => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<AddOfficeModal />, {
        key: AddOfficeModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
const CreateOfficeForm = ({ form }: { form: FormHandler }) => {
  const { data: addOfficeConfig, isLoading: isLoadingOfficeConfig } =
    supportConfigApi.useGetCreateOfficeFormConfigQuery();

  if (addOfficeConfig === undefined) {
    return <></>;
  }

  const { config } = addOfficeConfig;

  return (
    <FormContent isLoading={isLoadingOfficeConfig} form={addOfficeConfig} flex={1}>
      {addOfficeConfig ? (
        <FormBuilder data={config} form={form} extensions={getPlugins()} />
      ) : null}
    </FormContent>
  );
};
