import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { customerApi } from '~/components/Customer/utils/Customer.api';
import { PageHeader } from '~/components/Page/Page.Header';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const BookingsListHeaderCustomerId = ({
  customerId,
  onReset,
  onPress,
}: {
  customerId: string;
  onReset: () => void;
  onPress?: () => void;
}) => {
  return (
    <PageHeader
      title={translate('bookings.bookingsByCustomerId')}
      Subtitle={Subtitle({ customerId })}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress,
      }}
      childRight={<IconButton icon="reset" color="monochrome" onPress={onReset} />}
    />
  );
};

const Subtitle = ({ customerId }: { customerId: string }) => {
  const { data: customerData } = customerId
    ? customerApi.useGetCustomerQuery(customerId)
    : { data: undefined };

  const email = customerData?.customer.p_email;

  return (
    <>
      <AppText.Small color="monochrome-mid">{customerId}</AppText.Small>
      {email && <AppText.Small color="monochrome-mid">{email}</AppText.Small>}
    </>
  );
};
