import {
  AppTheme,
  defaultTheme,
  typography,
} from '@droplet-tech-code/core-elements/module/theme';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { palette } from '~/theme/palette';

export const getAppTheme = (): AppTheme => {
  const { components } = defaultTheme;
  const typographyProps = typography(isWeb ? 0.9 : 1, 2);
  return {
    ...defaultTheme,
    palette: {
      ...palette,
      monochrome: {
        ...palette.monochrome,
        base: '#fbfbfb',
      },
    },
    shading: {
      ...defaultTheme.shading,
      regular: {
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 3,
      },
    },
    typography: typographyProps,
    components: {
      ...components,

      modalHeader: {
        ...components.modalHeader,
        title: {
          ...components.modalHeader.title,
          ...(isNativeMobile ? { type: 'body2Medium' } : {}),
        },
        subtitle: {
          ...components.modalHeader.subtitle,
          ...(isNativeMobile ? { type: 'small' } : {}),
        },
      },
      formInputLabel: {
        ...components.formInputLabel,
        ...typographyProps.small,
      },
    },
  };
};
