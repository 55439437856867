import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

import { useAddPortLocation } from './PortLocations.Add';

export const PortLocationListHeader = () => {
  const { openModal: openAddModal } = useAddPortLocation();

  return (
    <PageHeader
      title={translate('portLocations.portLocations')}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'plus',
        onPress: () => openAddModal(),
      }}
    />
  );
};
