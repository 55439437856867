import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { FerryCompanyConfigListItem } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';
import { RefreshControl } from 'react-native';

import { FlatList } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { CompanyConfigListHeader } from './CompanyConfig.ListHeader';
import { CompanyConfigListItemComponent } from './CompanyConfig.ListItem';
import { companyConfigApi } from './CompanyConfigs.api';

export interface CompanyConfigListProps {
  onPress: (item: FerryCompanyConfigListItem) => void;
}

export const CompanyConfigListNative = () => {
  const navigate = useAppNavigation();
  return (
    <CompanyConfigList
      onPress={(item) => {
        navigate.navigate('CompanyConfig-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const CompanyConfigList = (props: CompanyConfigListProps) => {
  const { list, Search, isLoading, isFetching, refetch } = useSearchState({
    hook: companyConfigApi.useGetCompanyConfigListQuery,
    style: {
      height: 47,
    },
    keyToList: 'ferryCompanies',
  });

  const selectedRef = useRef('');

  const { spacing } = useAppTheme();

  const renderItem = useCallback((companyConfig: FerryCompanyConfigListItem) => {
    return (
      <CompanyConfigListItemComponent
        key={companyConfig.id}
        companyConfig={companyConfig}
      />
    );
  }, []);

  const ItemSeparatorComponent = useItemSeparatorComponent();

  if (list === undefined) {
    return <></>;
  }

  return (
    <VStack flex={1}>
      <CompanyConfigListHeader />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        feedback={translate('companyConfig.emptyCompanyConfigs')}
        isLoading={isLoading}
        data={list}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
