import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { SupportPendingRefund } from '@naus-code/naus-client-types';

import { Field } from '~/components/Card/Field';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

const RefundPaymentDataModalKey = 'RefundPaymentDataModalKey';

export const RefundPaymentDataModal = ({
  currency,
  refundData,
}: {
  currency: string;
  refundData: SupportPendingRefund;
}) => {
  const { closeModal } = useModal();
  const { description, adminName, paymentSessionId, value, bookingId, inProgress } =
    refundData;

  return (
    <Modal.Container style={{ flex: undefined }}>
      <Modal.Header
        title={translate('bookings.paymentSession.refundPayment')}
        divider
        onPress={() => {
          closeModal(RefundPaymentDataModalKey);
        }}
      />
      <Modal.Body style={{ flex: undefined }} p="2.5">
        <VStack>
          <Field
            label={translate('supportRequests.refund.description')}
            value={description}
          />
          <Field
            label={translate('supportRequests.refund.adminName')}
            value={adminName}
          />
          <Field
            label={translate('supportRequests.refund.value')}
            value={getPriceWithSymbol(value, currency)}
          />
          <Field
            label={translate('supportRequests.refund.inProgress')}
            value={inProgress ? 'TRUE' : 'FALSE'}
          />
          <Field
            label={translate('supportRequests.refund.paymentSessionId')}
            value={paymentSessionId}
          />
          <Field
            label={translate('supportRequests.refund.bookingId')}
            value={bookingId}
          />
        </VStack>
      </Modal.Body>
      <Modal.Footer
        divider
        onCancel={() => {
          closeModal(RefundPaymentDataModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useRefundPaymentDataModal = () => {
  const { openModal } = useModal();
  return {
    open: (refundData: SupportPendingRefund, currency: string) => {
      openModal(<RefundPaymentDataModal refundData={refundData} currency={currency} />, {
        key: RefundPaymentDataModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
