import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const AddNoteKey = 'AddModal-AddNote';
export const AddNoteModal = ({
  supportRequestInfo,
}: {
  supportRequestInfo: SupportRequestBackendClient;
}) => {
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();

  const supportId = supportRequestInfo._id;
  const [addNote, { isLoading: isAddingNote }] =
    supportRequestActionsApi.useAddSupportNoteMutation();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.addNote')}
        subtitle={translate('supportRequests.enterNoteText')}
        divider
        onPress={() => {
          closeModal(AddNoteKey);
        }}
      />
      <Modal.Body p="0">
        <FormBuilder
          data={[
            {
              items: [
                {
                  type: 'text',
                  multiline: 5,
                  label: translate('supportRequests.note'),
                  id: 'note',
                },
              ],
            },
          ]}
          form={form.current}
        />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isAddingNote}
        onConfirm={async () => {
          const { valid, value } = form.current.getValue<{ note: string }>();
          if (valid) {
            const response = await addNote({
              ...value,
              supportId,
              check: '',
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddNoteKey);
              },
            });
            closeModal(AddNoteKey);
          }
        }}
        onCancel={() => {
          closeModal(AddNoteKey);
        }}
      />
    </Modal.Container>
  );
};

export const useAddNote = ({
  supportRequestInfo,
}: {
  supportRequestInfo: SupportRequestBackendClient;
}) => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<AddNoteModal supportRequestInfo={supportRequestInfo} />, {
        key: AddNoteKey,
        type: 'action-sheet-full',
      });
    },
  };
};
