import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { transactionApi } from '~/screens/Finance/Transactions/Transactions.api';
import { TransactionSplitList } from '~/screens/Finance/Transactions/Transactions.Screen';
import { translate } from '~/utils/translation.utils';

export const BookingTransactionKey = 'AddModal-CreatePayment';
export const BookingTransactionsModal = ({ bookingId }: { bookingId: string }) => {
  const { closeModal } = useModal();
  const { data: bookingTransactionData } =
    transactionApi.useGetTransactionByBookingQuery(bookingId);

  const { spacing } = useAppTheme();

  if (!bookingTransactionData) {
    return null;
  }
  const bookingTransactionList = bookingTransactionData.list;

  return (
    <Modal.Container
      style={
        isWeb
          ? {
              width: window.innerWidth - spacing[6],
              // maxWidth: window.innerWidth - spacing[6],
              maxWidth: window.innerWidth * 0.8,
            }
          : undefined
      }
    >
      <Modal.Header
        title={translate('bookingTransactions.bookingTransactions')}
        subtitle={`${bookingTransactionList.length}
                ${translate('bookingTransactions.transactions')}`}
        divider
        onPress={() => {
          closeModal(BookingTransactionKey);
        }}
      />
      <Modal.Body flex={1}>
        <TransactionSplitList list={bookingTransactionList} />
      </Modal.Body>
    </Modal.Container>
  );
};

export const useBookingTransaction = () => {
  const { openModal } = useModal();

  return {
    open: (bookingId: string) => {
      openModal(<BookingTransactionsModal bookingId={bookingId} />, {
        key: BookingTransactionKey,
        type: 'action-sheet-full',
      });
    },
  };
};
