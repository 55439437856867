import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SystemConfigUpdateFerryBookingConfigRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const UpdateBookingModalKey = 'UpdateModal-Booking';
const UpdateBookingModal = ({ check }: { check: string }) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [updateBooking, { isLoading: isUpdatingBooking }] =
    systemConfigApi.useUpdateFerryBookingConfigMutation();

  return (
    <>
      <Modal.Header
        title={translate('systemConfig.updateBookingRules')}
        subtitle={translate('systemConfig.enterNewBookingRules')}
        divider
        onPress={() => {
          closeModal(UpdateBookingModalKey);
        }}
      />
      <Modal.Body p="0">
        <BookingUpdateForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isUpdatingBooking}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              SystemConfigUpdateFerryBookingConfigRequest['bookingConfig']
            >();

          if (valid) {
            const response = await updateBooking({
              check,
              bookingConfig: value,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(UpdateBookingModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(UpdateBookingModalKey);
        }}
      />
    </>
  );
};

export const useUpdateBooking = ({ check }: { check: string }) => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<UpdateBookingModal check={check} />, {
        key: UpdateBookingModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};

const BookingUpdateForm = ({ form }: { form: FormHandler }) => {
  const { data: bookingUpdateConfig, isLoading: isLoadingBookingConfig } =
    systemConfigApi.useUpdateFerryBookingConfigFormConfigQuery();

  return (
    <FormContent isLoading={isLoadingBookingConfig} form={bookingUpdateConfig} flex={1}>
      {bookingUpdateConfig ? (
        <FormBuilder data={bookingUpdateConfig.config} form={form} />
      ) : null}
    </FormContent>
  );
};
