import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { UserRules } from '@naus-code/naus-admin-types';
// import { onAuthStateChanged, Unsubscribe, User } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from 'react';

import { firebase } from '~/utils/firebase';

import { usersApi } from '../Users/Users.api';

export const AuthContext = createContext({
  currentUser: null as { uid: string; email: string | null } | null,
  authenticating: false,
});
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<
    { uid: string; email: string | null } | null | undefined
  >();
  const [authenticating, setAuthenticating] = useState(true);

  useEffect(() => {
    const auth = firebase.getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAuthenticating(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <DelayRender isLoading={currentUser === undefined}>
      {currentUser === undefined ? null : (
        <AuthContext.Provider value={{ currentUser, authenticating }}>
          <DelayRender>{children}</DelayRender>
        </AuthContext.Provider>
      )}
    </DelayRender>
  );
};

export const useGetAuthUser = () => {
  return useContext(AuthContext);
};

export const useGetAuthUserRules = (rule: keyof UserRules) => {
  const { currentUser } = useContext(AuthContext);
  const { data: user } = usersApi.useGetUserItemQuery(currentUser?.uid || '');
  if (user?.adminUser.role === 'SYSTEM') {
    return true;
  }
  return user?.adminUser.rules.userRules?.[rule];
};

export const useGetAuthUserRole = () => {
  const { currentUser } = useContext(AuthContext);
  const { data: user } = usersApi.useGetUserItemQuery(currentUser?.uid || '');

  return user?.adminUser.role;
};
