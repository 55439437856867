import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { FerryCompanyVehicleTicketConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { useUpdateVehicleTicketConfigModal } from '~/components/Config/CompanyConfig/Modals/Tickets/UpdateVehicleTicketModal';
import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const VehicleConfig = ({
  vehicleConfig,
  check,
  companyId,
}: {
  check: string;
  companyId: string;
  vehicleConfig: FerryCompanyVehicleTicketConfig;
}) => {
  const { name, disabled } = vehicleConfig;
  const { open: openUpdateVehicleConfig } = useUpdateVehicleTicketConfigModal();

  return (
    <CardWithHeader
      headerText={name}
      iconRightType="edit"
      iconRightOnPress={() => {
        openUpdateVehicleConfig({
          companyId,
          check,
          vehicleTicketCode: vehicleConfig.code,
        });
      }}
      iconLeftType={disabled ? 'disconnected' : undefined}
    >
      <VStack p="2.5">
        <VehicleInfo vehicleConfig={vehicleConfig} />
        <ConfigListItem
          text={translate('companyConfig.operationsCancellationsMod')}
          iconRightType="edit"
          onPress={() => {}}
        />
      </VStack>
    </CardWithHeader>
  );
};

const VehicleInfo = ({
  vehicleConfig,
}: {
  vehicleConfig: FerryCompanyVehicleTicketConfig;
}) => {
  const {
    name,
    code,
    dataConfigId,
    category,
    extraPriceValidation,
    customExtras,
    minLength,
    maxLength,
    maxHeight,
  } = vehicleConfig;

  return (
    <SwitchStack py="2.5" space="2.5">
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.companyCode')}
          </AppText.ExtraSmall>
          <Text.Small>{code}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.name')}
          </AppText.ExtraSmall>
          <Text.Small>{name}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.DataId')}
          </AppText.ExtraSmall>
          <Text.Small>{dataConfigId}</Text.Small>
        </HStack>
      </VStack>
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.category')}
          </AppText.ExtraSmall>
          <Text.Small>{`[${category.join(',')}]`}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {`${translate('companyConfig.minLength')} (cm)`}
          </AppText.ExtraSmall>
          <Text.Small>{minLength}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {`${translate('companyConfig.maxLength')} (cm)`}
          </AppText.ExtraSmall>
          <Text.Small>{maxLength}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {`${translate('companyConfig.maxHeight')} (cm)`}
          </AppText.ExtraSmall>
          <Text.Small>{maxHeight || '-'}</Text.Small>
        </HStack>
        {extraPriceValidation ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.extraValidation')}
            </AppText.ExtraSmall>
            <Text.Small>
              {`[${Object.entries(extraPriceValidation)
                .filter(([_key, value]) => value !== undefined)
                .map(([key, _value]) => key)
                .join(',')}]`}
            </Text.Small>
          </HStack>
        ) : null}
        {customExtras ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.customExtras')}
            </AppText.ExtraSmall>
            <Text.Small>{`[${customExtras.join(',')}]`}</Text.Small>
          </HStack>
        ) : null}
      </VStack>
    </SwitchStack>
  );
};
