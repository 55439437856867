import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ViewStyle } from '@droplet-tech-code/core-elements/module/types/components';
import { ContentFeedbackHandler } from '@droplet-tech-code/core-elements/module/ui/Feedback/Feedback.Handler';
import {
  EmptyList,
  FlatList as FlatListCore,
} from '@droplet-tech-code/core-elements/module/ui/FlatList/FlatList';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { FlatListProps } from 'react-native';

import { translate } from '../../utils/translation.utils';
import { ListItem } from './FlatList.Item';

export type ListRenderItem<T> = (item: T, index: number) => JSX.Element;

export interface ListBaseProps<T> extends Omit<FlatListProps<T>, 'data' | 'renderItem'> {
  renderItem: ListRenderItem<T>;
  onPressItem?: (item: T, index: number) => void;
  isLoading?: boolean;
  feedback?: string;
  simple?: boolean;
  itemStyle?: ViewStyle;
  idKey?: string;
}
export interface ListProps<T> extends ListBaseProps<T> {
  data: T[];
}

export const FlatList = <T,>({
  isLoading,
  feedback,
  data,
  simple,
  ...props
}: ListProps<T>) => {
  const { spacing } = useAppTheme();

  return (
    <VStack flex={1}>
      <ContentFeedbackHandler
        data={data}
        isLoading={isLoading || false}
        feedback={feedback ?? translate('feedback.emptyData')}
        EmptyDataFeedBack={<EmptyList />}
      >
        <FlatListCore
          {...props}
          idKey={props.idKey ?? 'id'}
          contentContainerStyle={[
            { flexGrow: 1 },
            isWeb && { paddingHorizontal: spacing[2] },
            props.contentContainerStyle,
          ]}
          data={data}
          renderItem={({ item, index }) => {
            if (simple) {
              return props.renderItem(item, index);
            }
            return (
              <ListItem
                onPress={() => props.onPressItem?.(item, index)}
                style={props.itemStyle}
              >
                {props.renderItem(item, index)}
              </ListItem>
            );
          }}
          ItemSeparatorComponent={
            props.ItemSeparatorComponent ||
            (() => {
              return <View my="1" />;
            })
          }
          ListFooterComponent={
            props.ListFooterComponent || <View align="center" style={{ height: 30 }} />
          }
          ListEmptyComponent={<EmptyList />}
        />
      </ContentFeedbackHandler>
    </VStack>
  );
};
