import {
  ColorKeys,
  getColorFromProvidedTheme,
  useAppTheme,
} from '@droplet-tech-code/core-elements/module/theme';
import { Icon, IconsId, IconSize } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { getScreenWidth } from '@droplet-tech-code/core-elements/module/utils/utils.ui';
import { useState } from 'react';
import TooltipComponent from 'rn-tooltip';

export const ToolTip = ({
  text,
  width,
  children,
  icon,
  iconColor,
  iconSize,
  textColor,
  bg,
}: {
  text: string;
  width?: number;
  children?: React.ReactNode;
  icon?: IconsId;
  iconColor?: ColorKeys;
  iconSize?: IconSize;
  textColor?: ColorKeys;
  bg?: ColorKeys;
}) => {
  const theme = useAppTheme();
  const [isOpen, setOpen] = useState(false);
  const toolTipBg = getColorFromProvidedTheme(bg || 'primary-light', theme);

  const defaultWeb = isMobile ? getScreenWidth() * 0.75 : 300;
  const toolTipWidth = width || defaultWeb;
  const spacingVertical = theme.spacing['3'];
  const [toolTipHeight, setToolTipHeight] = useState(0);

  return (
    <TooltipComponent
      ref={(ev) => ev?.toggleTooltip}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setToolTipHeight(0);
        setOpen(false);
      }}
      actionType="press"
      backgroundColor={toolTipBg}
      withOverlay={false}
      width={toolTipWidth}
      height={toolTipHeight + spacingVertical * 2}
      popover={
        <View
          style={[
            { width: toolTipWidth, padding: spacingVertical },
            !isOpen && { position: 'absolute' },

            isWeb && {
              ...theme.shading.regular,
              borderRadius: 12,
            },
          ]}
          onLayout={(e) => {
            if (!isOpen) {
              setToolTipHeight(e.nativeEvent.layout.height);
            }
          }}
        >
          <Text.Body2Regular color={textColor ?? 'white'}>{text}</Text.Body2Regular>
        </View>
      }
    >
      {children || (
        <Icon icon={icon || 'info'} size={iconSize} color={iconColor || 'monochrome'} />
      )}
    </TooltipComponent>
  );
};
