import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const CustomerSupportRequestsListHeader = ({
  numberOfRequests,
  email,
}: {
  numberOfRequests?: string;
  email: string;
}) => {
  return (
    <PageHeader
      title={translate('supportRequests.customerSupportRequests')}
      Subtitle={Subtitle({ email, numberOfRequests })}
      withBack={isNativeMobile}
    />
  );
};

const Subtitle = ({
  email,
  numberOfRequests,
}: {
  email: string;
  numberOfRequests?: string;
}) => {
  return (
    <View pt="1">
      <Text.Small color="monochrome-mid">{`${email} - ${
        numberOfRequests || ''
      } ${translate('supportRequests.requests')}`}</Text.Small>
    </View>
  );
};
