import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { FerryCompanyVehicleTicketConfig } from '@naus-code/naus-server-common-types';
import { useCallback } from 'react';
import { RefreshControl } from 'react-native-gesture-handler';

import { useAddFerryVehicleTicketModal } from '~/components/Config/CompanyConfig/Modals/Tickets/Modals';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { FlatList } from '~/components/FlatList/FlatList';
import { ScreenPropVehicleConfigs } from '~/navigation/Navigation.types';
import { translate } from '~/utils/translation.utils';

import { CompanyConfigItemHeader } from '../../CompanyConfig.ItemHeader';
import { companyConfigApi } from '../../CompanyConfigs.api';
import { VehicleConfig } from './VehicleConfig';

export const VehicleConfigs = ({ id }: { id: string }) => {
  const {
    list: vehicleConfigs,
    Search,
    isLoading,
    isFetching,
    refetch,
  } = useSearchState({
    hook: companyConfigApi.useGetFerryCompanyConfigQuery,
    style: {
      height: 47,
    },
    arg: id,
    keyToList: 'config.ticketTypeConfig.vehicleConfigs',
  });

  const { spacing } = useAppTheme();
  const { data } = companyConfigApi.useGetFerryCompanyConfigQuery(id);
  const { open: openAddVehicleTicketModal } = useAddFerryVehicleTicketModal(id);

  const allVehicleConfigs = vehicleConfigs.map((vehicleConfig) => {
    return { ...vehicleConfig, id: vehicleConfig.code };
  });

  const ItemSeparatorComponent = useCallback(() => <View my="1" />, []);

  const renderItem = (
    vehicleConfig: FerryCompanyVehicleTicketConfig & { id: string },
  ) => {
    return (
      <VehicleConfig
        companyId={id}
        check={data?.check || ''}
        vehicleConfig={vehicleConfig}
        key={vehicleConfig.id}
      />
    );
  };

  return (
    <VStack flex={1}>
      {isNativeMobile ? <CompanyConfigItemHeader id={id} /> : <></>}
      <VStack flex={1} p="2.5" space="2.5">
        <ConfigListSectionTitle>
          <HStack>
            <Text.Small flex={1} color="monochrome-mid">
              {translate('companyConfig.vehicleConfigs')}
            </Text.Small>
            <IconButton icon="plus" onPress={openAddVehicleTicketModal} />
          </HStack>
        </ConfigListSectionTitle>
        {Search}
        <FlatList
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
          }
          contentContainerStyle={[
            {
              paddingTop: spacing[2.5],
            },

            isNativeMobile && { paddingHorizontal: spacing[2] },
          ]}
          simple
          scrollEnabled
          feedback={translate('companyConfig.emptyVehicleConfigs')}
          isLoading={isLoading}
          data={allVehicleConfigs}
          renderItem={renderItem}
          ItemSeparatorComponent={ItemSeparatorComponent}
        />
      </VStack>
    </VStack>
  );
};

export const VehicleConfigsScreen = (props: ScreenPropVehicleConfigs) => {
  return <VehicleConfigs id={props.route.params.id} />;
};
