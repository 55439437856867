import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { CompanyActiveConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { useUpdateActiveConfigModal } from '~/components/Config/CompanyConfig/Modals/Company/Modals';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const ActiveConfigCard = ({
  companyId,
  activeConfig,
  check,
}: {
  companyId: string;
  activeConfig: CompanyActiveConfig;
  check: string;
}) => {
  const {
    isSearchVisible,
    isSearchEnabled,
    isSearchTemporarilyDisabled,
    isBookingEnabled,
    isBookingTemporarilyDisabled,
    isTrackEnabled,
  } = activeConfig;
  const { open: openUpdateActiveConfig } = useUpdateActiveConfigModal(companyId, check);
  return (
    <CardWithHeader
      headerText={translate('companyConfig.activeConfig')}
      iconRightType="edit"
      iconRightOnPress={openUpdateActiveConfig}
    >
      <SwitchStack p="2.5" space="2.5">
        <VStack space="2.5" flex={isWeb ? 1 : undefined}>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.searchVisible')}
            </AppText.ExtraSmall>
            <Text.Small>
              {isSearchVisible.enabled
                ? translate('companyConfig.true')
                : translate('companyConfig.false')}
            </Text.Small>
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.searchEnabled')}
            </AppText.ExtraSmall>
            <Text.Small>
              {isSearchEnabled.enabled
                ? translate('companyConfig.true')
                : translate('companyConfig.false')}
            </Text.Small>
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.searchTempDisabled')}
            </AppText.ExtraSmall>
            <Text.Small>
              {isSearchTemporarilyDisabled.enabled
                ? translate('companyConfig.true')
                : translate('companyConfig.false')}
            </Text.Small>
          </HStack>
        </VStack>
        <VStack space="2.5" flex={isWeb ? 1 : undefined}>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.bookingEnabled')}
            </AppText.ExtraSmall>
            <Text.Small>
              {isBookingEnabled.enabled
                ? translate('companyConfig.true')
                : translate('companyConfig.false')}
            </Text.Small>
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.bookingTempDisabled')}
            </AppText.ExtraSmall>
            <Text.Small>
              {isBookingTemporarilyDisabled.enabled
                ? translate('companyConfig.true')
                : translate('companyConfig.false')}
            </Text.Small>
          </HStack>

          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.trackEnabled')}
            </AppText.ExtraSmall>
            <Text.Small>
              {isTrackEnabled.enabled
                ? translate('companyConfig.true')
                : translate('companyConfig.false')}
            </Text.Small>
          </HStack>
        </VStack>
      </SwitchStack>
    </CardWithHeader>
  );
};
