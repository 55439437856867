import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  TripScheduledCancellation,
  TripScheduledChange,
  TripScheduledModification,
} from '@naus-code/naus-admin-types';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { translate } from '~/utils/translation.utils';

import { scheduledChangesApi } from '../SchedulesChanges.api';

const separationOffset = -5;

export const BaseCreateScheduleChangeForm = ({
  isLoading,
  form,
  formConfigData,
}: {
  isLoading: boolean;
  form: MutableRefObject<FormHandler>;
  formConfigData?: ConfigForData;
}) => {
  if (!formConfigData) {
    return null;
  }
  const newFormConfigData = formConfigData.slice(0, separationOffset);

  return (
    <VStack>
      <FormContent isLoading={isLoading} form={form}>
        <FormBuilder data={newFormConfigData} form={form.current} />
      </FormContent>
    </VStack>
  );
};

export const ExtendedCreateScheduleChangeForm = ({
  selection,
  form,
  formConfigData,
  cancellationForm,
  modificationForm,
}: {
  selection: string;
  form: MutableRefObject<FormHandler>;
  formConfigData?: ConfigForData;
  cancellationForm: MutableRefObject<FormHandler>;
  modificationForm: MutableRefObject<FormHandler>;
}) => {
  const [verifyForm, { isLoading: isVerifyingForm }] =
    scheduledChangesApi.useLazyVerifyFormScheduledChangeQuery();

  const [stateIndex, setStateIndex] = useState<number>(0);

  const [newFormConfigData, setFormConfigData] = useState<ConfigForData | undefined>(
    formConfigData?.slice(
      formConfigData.length + separationOffset,
      formConfigData.length,
    ),
  );

  const onUpdateConfig = useCallback(
    (data: ConfigForData | undefined) => {
      setFormConfigData(data);
      setStateIndex((index) => index + 1);
    },
    [stateIndex, newFormConfigData],
  );
  useEffect(() => {
    setFormConfigData(
      formConfigData?.slice(
        formConfigData.length + separationOffset,
        formConfigData.length,
      ),
    );
  }, [formConfigData]);

  const onPullStopData = useCallback(async () => {
    const { valid, value } = form.current.getValue<TripScheduledChange>();
    const { valid: validCancellation, value: valueCancellation } =
      cancellationForm.current.getValue<TripScheduledCancellation>();
    const { valid: validModification, value: valueModification } =
      modificationForm.current.getValue<TripScheduledModification>();

    if (valid && validCancellation && validModification) {
      const newValue = reconstructedRequest(
        value,
        valueModification,
        valueCancellation,
        selection,
      );
      const response = await verifyForm({ scheduledChange: newValue });
      handleResponse({
        response,
        onSuccess: ({ data }) => {
          onUpdateConfig(data);
        },
        successMessage: translate('scheduledChanges.verified'),
      });
    } else {
      showToast(translate('scheduledChanges.invalidData'), { type: 'error' });
    }
  }, [selection]);

  if (!newFormConfigData) {
    return null;
  }

  return (
    <VStack space="2.5">
      <ScheduleChangeType
        key={stateIndex + selection}
        modificationForm={modificationForm}
        cancellationForm={cancellationForm}
        selection={selection}
        formConfigData={newFormConfigData}
      />
      {selection === 'modification' && (
        <AutofillStops onPullStopData={onPullStopData} isVerifying={isVerifyingForm} />
      )}
    </VStack>
  );
};

const ScheduleChangeType = ({
  selection,
  formConfigData,
  modificationForm,
  cancellationForm,
}: {
  selection?: string;
  formConfigData: ConfigForData;
  modificationForm: MutableRefObject<FormHandler>;
  cancellationForm: MutableRefObject<FormHandler>;
}) => {
  if (selection === 'cancellation') {
    const cancellationRefund =
      formConfigData.at(-3)?.['items'][0]['conditions'][0]['formGroup'];
    const cancellationConfig =
      formConfigData.at(-2)?.['items'][0]['conditions'][0]['formGroup'];

    if (!cancellationConfig) {
      return null;
    }

    return (
      <FormContent isLoading={false} form={cancellationForm}>
        <FormBuilder
          data={[{ items: [cancellationRefund] }, { items: [cancellationConfig] }]}
          form={cancellationForm.current}
        />
      </FormContent>
    );
  }
  if (selection === 'modification') {
    const modificationRefund =
      formConfigData.at(-3)?.['items'][0]['conditions'][1]['formGroup'];
    const modificationConfig =
      formConfigData.at(-1)?.['items'][0]['conditions'][0]['formGroup'];

    if (!modificationConfig) {
      return null;
    }

    return (
      <FormContent isLoading={false} form={modificationForm}>
        <FormBuilder
          data={[{ items: [modificationRefund] }, { items: [modificationConfig] }]}
          form={modificationForm.current}
        />
      </FormContent>
    );
  }
  return null;
};

const AutofillStops = ({
  onPullStopData,
  isVerifying,
}: {
  onPullStopData: () => void;
  isVerifying?: boolean;
}) => {
  return (
    <VStack p="2.5" space="2.5" bg="white">
      <Button
        onPress={onPullStopData}
        variant="outline"
        isLoading={isVerifying}
        iconEnd="arrow-down"
      >
        {translate('scheduledChanges.pullStopData')}
      </Button>
    </VStack>
  );
};

export const hasStopData = (formData?: ConfigForData) => {
  if (!formData) {
    return undefined;
  }
  return !!formData.at(-1)?.['items'][0]['conditions'][0]['formGroup']['initialValue'];
};

export const reconstructedRequest = (
  value: TripScheduledChange,
  valueModification: TripScheduledModification,
  validCancellation: TripScheduledCancellation,
  selection?: string,
): TripScheduledChange => {
  if (selection === 'modification') {
    return { ...value, ...valueModification, type: selection } as TripScheduledChange;
  }
  return { ...value, ...validCancellation, type: selection } as TripScheduledChange;
};
