import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  VoucherListItemResponse,
  VoucherListResponse,
} from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';

import { FlatList } from '~/components/FlatList/FlatList';
import { PageHeader } from '~/components/Page/Page.Header';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { VoucherListItemComponent } from '../Voucher.ListItem';

interface CreateVoucherListProps {
  voucherList: VoucherListResponse;
  onPress: (voucherId: string) => void;
}

export const CreateVoucherListNative = ({
  voucherList,
}: {
  voucherList: VoucherListResponse;
}) => {
  const navigate = useAppNavigation();
  return (
    <CreateVoucherList
      voucherList={voucherList}
      onPress={(itemId) => {
        navigate.navigate('Voucher-Item', {
          id: itemId,
        });
      }}
    />
  );
};

export const CreateVoucherList = (props: CreateVoucherListProps) => {
  const selectedRef = useRef('');

  const renderItem = useCallback(
    (voucher: VoucherListItemResponse) => {
      return (
        <VoucherListItemComponent
          key={voucher.id}
          voucher={voucher}
          selected={selectedRef.current === voucher.id}
          onPress={() => props.onPress(voucher.id)}
        />
      );
    },
    [props.voucherList],
  );

  return (
    <VStack space="2.5" flex={1}>
      <PageHeader title={translate('voucher.createdVouchers')} />
      <FlatList
        feedback={translate('supportRequests.emptySupportRequest')}
        data={props.voucherList.vouchers}
        isLoading={false}
        showsVerticalScrollIndicator={isWeb}
        simple
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v.id);
        }}
      />
    </VStack>
  );
};
