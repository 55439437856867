import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

export const useSupportRequestByPurchase = () => {
  const navigation = useAppNavigation();

  return useCreateManualFormModal({
    title: translate('purchases.supportRequestsForPurchase'),
    subtitle: translate('purchases.enterPurchaseId'),
    formData: [
      {
        items: [
          {
            type: 'text',
            label: translate('purchases.purchaseId'),
            id: 'purchaseId',
          },
        ],
      },
    ],
    onConfirm: (value) => {
      navigation.navigate('PurchaseSupportRequests', value);
    },
  });
};
