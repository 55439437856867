import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  TripScheduledCancellation,
  TripScheduledChange,
  TripScheduledChangeVerifyPurchasesResponse,
  TripScheduledModification,
} from '@naus-code/naus-admin-types';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { translate } from '~/utils/translation.utils';

import { scheduledChangesApi } from '../SchedulesChanges.api';
import {
  BaseCreateScheduleChangeForm,
  ExtendedCreateScheduleChangeForm,
  reconstructedRequest,
} from './ScheduleChange.utils';
import { ScheduledChangeHeader } from './ScheduledChange.Create.Header';
import { CreateScheduleChangeItem } from './ScheduledChanges.Create.Item';

export const CreateScheduledChangeScreen = () => {
  return (
    <PageContainer>
      <CreateScheduledChanges />
    </PageContainer>
  );
};

export const CreateScheduledChanges = () => {
  const formRef = useRef(new FormHandler());
  const changeTypeRef = useRef<'modification' | 'cancellation'>('cancellation');
  const cancellationForm = useRef(new FormHandler());
  const modificationForm = useRef(new FormHandler());

  const [createData, setCreateData] = useState<
    TripScheduledChangeVerifyPurchasesResponse | undefined
  >(undefined);

  return (
    <ScreenTwoPageLayout>
      <ConfigForSchedulesChange
        form={formRef}
        changeTypeRef={changeTypeRef}
        cancellationForm={cancellationForm}
        modificationForm={modificationForm}
        setCreateData={setCreateData}
      />
      <>
        {createData ? (
          <CreateScheduleChangeItem
            data={createData}
            changeTypeRef={changeTypeRef}
            form={formRef}
            cancellationForm={cancellationForm}
            modificationForm={modificationForm}
          />
        ) : null}
      </>
    </ScreenTwoPageLayout>
  );
};

const ConfigForSchedulesChange = ({
  form,
  cancellationForm,
  changeTypeRef,
  modificationForm,
  setCreateData,
}: {
  form: MutableRefObject<FormHandler>;
  changeTypeRef: MutableRefObject<string>;
  cancellationForm: MutableRefObject<FormHandler>;
  modificationForm: MutableRefObject<FormHandler>;
  setCreateData: (data: TripScheduledChangeVerifyPurchasesResponse) => void;
}) => {
  const { data: scheduledChangeConfig, isLoading } =
    scheduledChangesApi.useGetCreateScheduledChangeFormConfigQuery();

  const [verify] = scheduledChangesApi.useLazyVerifyScheduledChangeQuery();

  const [formConfigData, setFormConfigData] = useState<ConfigForData | undefined>(
    scheduledChangeConfig,
  );

  const [selection, setSelection] = useState<string[] | undefined>();

  useEffect(() => {
    if (selection) {
      changeTypeRef.current = selection.at(0) as string;
    }
  }, [selection]);

  useEffect(() => {
    setFormConfigData(scheduledChangeConfig);
  }, [isLoading]);

  const onVerify = useCallback(async () => {
    const { valid, value } = form.current.getValue<TripScheduledChange>();
    const { valid: validCancellation, value: valueCancellation } =
      cancellationForm.current.getValue<TripScheduledCancellation>();
    const { valid: validModification, value: valueModification } =
      modificationForm.current.getValue<TripScheduledModification>();

    if (valid && validCancellation && validModification) {
      const newValue = reconstructedRequest(
        value,
        valueModification,
        valueCancellation,
        selection?.at(0),
      );

      const response = await verify({ scheduledChange: newValue });
      handleResponse({
        response,
        successMessage: translate('scheduledChanges.verified'),
        onSuccess: ({ data }) => {
          if (data) {
            setCreateData(data);
          }
        },
      });
    } else {
      showToast(translate('scheduledChanges.invalidData'), { type: 'error' });
    }
  }, [setCreateData, selection]);

  return (
    <VStack flex={1} bg="white">
      <ScheduledChangeHeader />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <VStack flex={1}>
          <BaseCreateScheduleChangeForm
            isLoading={isLoading}
            form={form}
            formConfigData={formConfigData}
          />
          <View p="2.5">
            <SelectInputBase
              type="single-select"
              values={selection}
              setValues={setSelection}
              feedback=""
              options={[
                { label: 'Cancellation', value: 'cancellation' },
                { label: 'Modification', value: 'modification' },
              ]}
            />
          </View>
          <ExtendedCreateScheduleChangeForm
            selection={selection?.at(0) || ''}
            form={form}
            cancellationForm={cancellationForm}
            modificationForm={modificationForm}
            formConfigData={formConfigData}
          />
        </VStack>
      </ScrollView>
      <PullPurchases onVerify={onVerify} />
    </VStack>
  );
};

const PullPurchases = ({ onVerify }: { onVerify: () => void }) => {
  return (
    <VStack p="2.5" space="2.5" bg="white" style={{ width: '100%' }}>
      <HStack align="center" space="2.5" justify="space-between">
        <Button onPress={onVerify} iconEnd="calendar-arrow-in">
          {translate('schedulesChanges.createV2')}
        </Button>
      </HStack>
    </VStack>
  );
};
