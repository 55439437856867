import { ColorCategory } from '@droplet-tech-code/core-elements/module/theme';
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
} from '@droplet-tech-code/core-elements/module/ui/Button';
import { IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import {
  HStack,
  StackProps,
  VStack,
} from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { DrawerActions } from '@react-navigation/native';

import { useAppNavigation, useNavigateBack } from '../../hooks/nav.hook';

export const PageHeaderContainer = (props: StackProps) => {
  return (
    <HStack
      justify="center"
      space="3"
      px="4"
      align="center"
      {...props}
      style={[
        {
          height: 80,
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.2,
          shadowRadius: 2,
        },
        props.style,
      ]}
    />
  );
};

export interface PageHeaderProps {
  title: string;
  Subtitle?: React.ReactNode;
  withBack?: boolean;
  iconRight?: IconButtonProps;
  iconLeft?: IconButtonProps;
  childRight?: React.ReactNode;
  childLeft?: React.ReactNode;
}

export const PageHeader = ({
  title,
  Subtitle,
  iconRight,
  childRight,
  childLeft,
  iconLeft,
  withBack,
}: PageHeaderProps) => {
  const { goBack } = useNavigateBack();
  return (
    <View bg="white">
      <HStack
        style={[{ height: 80, borderBottomWidth: 1 }]}
        px="5"
        space="2.5"
        bc="monochrome-extraLight"
      >
        {withBack ? (
          <IconButton
            color="monochrome"
            variant="semi-filled"
            icon="chevron-left"
            onPress={() => {
              goBack();
            }}
          />
        ) : iconLeft ? (
          <IconButton color="monochrome" variant="semi-filled" {...iconLeft} />
        ) : childLeft ? (
          <HStack>{childLeft}</HStack>
        ) : null}
        <VStack flex={1}>
          <Text.Body1Medium numberOfLines={1} align="left">
            {title}
          </Text.Body1Medium>
          {isWeb ? Subtitle : null}
        </VStack>

        {childRight ? <HStack>{childRight}</HStack> : null}

        {iconRight ? (
          <IconButton color="monochrome" variant="semi-filled" {...iconRight} />
        ) : null}
      </HStack>
    </View>
  );
};

export const PageHomeHeader = () => {
  // const { data, isLoading } = userApi.useGetUserQuery(undefined, {
  //   pollingInterval: 1000 * 60 * 10,
  //   refetchOnFocus: true,
  // });
  const navigation = useAppNavigation();
  return (
    <HStack style={{ height: 80 }} px="5" space="2.5">
      <IconButton
        color="monochrome"
        variant="semi-filled"
        icon="hamburger-menu"
        onPress={() => {
          navigation.dispatch(DrawerActions.openDrawer());
        }}
      />

      <Text.Body2Medium
        bold
        color="primary-mid"
        style={{ letterSpacing: 8 }}
        flex={1}
        align="center"
      >
        OPENFERRY ADMIN APP
      </Text.Body2Medium>
    </HStack>
  );
};

export const PageContentWide = ({
  title,
  btnRight,
}: {
  title: string;
  btnRight?: ButtonProps;
}) => {
  if (isNativeMobile) {
    return null;
  }

  return (
    <HStack style={{ height: 92 }} space="2.5">
      <Text.SectionTitle style={{ flex: 1 }}>{title}</Text.SectionTitle>
      {btnRight ? <Button color="primary" variant="contained" {...btnRight} /> : null}
    </HStack>
  );
};

export interface PageHeaderProps {
  title: string;
  withBack?: boolean;
  iconRight?: IconButtonProps;
  iconLeft?: IconButtonProps;
}

export interface PageHeaderAltProps {
  title: string;
  action?: {
    icon: IconsId;
    onPress: () => void;
    text: string;
    color?: ColorCategory;
  };
}
