import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import JSONTree from 'react-native-json-tree';

import { ModalScrollView } from '~/components/Modals/Modal.utils';

export const JSONView = ({ data }: { data: any }) => {
  return (
    <JSONTree
      data={data as any}
      hideRoot
      theme={{
        scheme: 'google',
        author: 'seth wright (http://sethawright.com)',
        base00: '#1d1f21',
        base01: '#282a2e',
        base02: '#373b41',
        base03: '#969896',
        base04: '#b4b7b4',
        base05: '#c5c8c6',
        base06: '#e0e0e0',
        base07: '#ffffff',
        base08: '#CC342B',
        base09: '#F96A38',
        base0A: '#FBA922',
        base0B: '#198844',
        base0C: '#3971ED',
        base0D: '#3971ED',
        base0E: '#A36AC7',
        base0F: '#3971ED',
      }}
      valueRenderer={(v) => {
        return (
          <View>
            <Text.Small bold color="secondary-mid">
              {v as any}
            </Text.Small>
          </View>
        );
      }}
      shouldExpandNode={() => true}
    />
  );
};

const ModalKey = 'JSONView';

export const useJsonViewerModal = () => {
  const { openModal, closeModal } = useModal();
  const { spacing } = useAppTheme();
  return {
    open: ({ data, title }: { title: string; data: any }) => {
      openModal(
        <Modal.Container>
          <Modal.Header
            title={title}
            onPress={() => {
              closeModal(ModalKey);
            }}
          />
          <ModalScrollView contentContainerStyle={{ padding: spacing[3] }}>
            <JSONView data={data} />
          </ModalScrollView>
        </Modal.Container>,
        {
          key: ModalKey,
        },
      );
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
