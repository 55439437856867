import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { getPlugins } from '~/components/Form/Form.Plugins';

import { portLocationApi } from './PortLocations.api';

export const PortLocationItem = ({ id }: { id: string; onClose: () => void }) => {
  const formRef = useRef(new FormHandler());
  return <PortLocationItemComponent id={id} form={formRef.current} />;
};

const PortLocationItemComponent = ({ id, form }: { id: string; form: FormHandler }) => {
  const { data: portLocationGetForm, isLoading: isLoadingPortLocationConfig } =
    portLocationApi.useGetPortLocationFormConfigQuery();

  const { data } = portLocationApi.useGetPortLocationQuery(id);
  if (data === undefined) {
    return null;
  }
  const portLocationData = data.location;

  return (
    <FormContent
      isLoading={isLoadingPortLocationConfig}
      form={portLocationGetForm}
      flex={1}
      bg="white"
      p="3"
    >
      {portLocationGetForm ? (
        <FormBuilder
          value={portLocationData}
          data={portLocationGetForm}
          form={form}
          // readonly
          extensions={getPlugins()}
        />
      ) : null}
    </FormContent>
  );
};
