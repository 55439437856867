import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { CompanyContactDetail } from '@naus-code/naus-client-types';

import { Field } from '~/components/Card/Field';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import {
  hasDistribution,
  SubDistributionPill,
} from '~/screens/Support/Purchases/Purchase.ListItem';
import { PurchaseMix } from '~/screens/Support/Purchases/purchase.utils';
import { purchaseApi } from '~/screens/Support/Purchases/Purchases.api';
import { translate } from '~/utils/translation.utils';

const ModalKey = 'usePurchaseCompanyContactDetails';

export const usePurchaseCompanyContactDetails = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (purchase: PurchaseMix) => {
      openModal(<PurchaseCompanyContactDetailsModal purchase={purchase} />, {
        key: ModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

const PurchaseCompanyContactDetailsModal = ({ purchase }: { purchase: PurchaseMix }) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container w={500}>
      <Modal.Header
        onPress={() => {
          closeModal(ModalKey);
        }}
        title={translate('purchases.companyDetails.companyDetails')}
        children={<SubDistributionPill purchase={purchase} />}
      />
      <Divider hairline />
      <ModalScrollView>
        <VStack style={{ maxHeight: 500 }}>
          <PurchaseCompanyContactDetailsCard purchase={purchase} />
        </VStack>
      </ModalScrollView>
    </Modal.Container>
  );
};

const PurchaseCompanyContactDetailsCard = ({ purchase }: { purchase: PurchaseMix }) => {
  return (
    <VStack space="2.5" p="2.5">
      <SubDistributionContacts purchase={purchase} />
      <CompanyContactList purchase={purchase} />
    </VStack>
  );
};

const SubDistributionContacts = ({ purchase }: { purchase: PurchaseMix }) => {
  if (hasDistribution(purchase)) {
    if (purchase.subDistributionProvider === 'LIKNOSS') {
      return (
        <VStack space="2.5">
          <Text.Body1Medium>
            {purchase.subDistributionProvider + ' subdistributor'}
          </Text.Body1Medium>
          {liknoss_contact.map((contactItem) => (
            <CompanyContactItem companyContact={contactItem} />
          ))}
        </VStack>
      );
    }
    if (purchase.subDistributionProvider === 'DIRECTFERRIES') {
      return (
        <VStack space="2.5">
          <Text.Body1Medium>{purchase.subDistributionProvider}</Text.Body1Medium>
        </VStack>
      );
    }
  }

  return null;
};

const CompanyContactList = ({ purchase }: { purchase: PurchaseMix }) => {
  const { data, isLoading } = purchaseApi.useGetPurchaseCompanyContactDetailsQuery(
    purchase.companyId,
  );

  if (isLoading) {
    return (
      <Modal.Container>
        <LoadingIndicator />
      </Modal.Container>
    );
  }

  if (!data || data.companyContactDetails.length === 0) {
    return (
      <Text.Small align="center" flex={1}>
        {translate('purchases.companyDetails.noDetails')}
      </Text.Small>
    );
  }

  return (
    <VStack space="2.5">
      <Divider />
      <Text.Body1Medium>{purchase.companyId}</Text.Body1Medium>
      {data.companyContactDetails.map((companyContact) => {
        return <CompanyContactItem companyContact={companyContact} />;
      })}
    </VStack>
  );
};

const CompanyContactItem = ({
  companyContact,
}: {
  companyContact: CompanyContactDetail;
}) => {
  const allContactData = companyContact.data
    .split(',')
    .map((contact) => contact.replaceAll(' ', ''));
  return (
    <VStack p="2.5" b={1} br={5}>
      <Field value={companyContact.title} valueType="small" />
      {allContactData.map((contact, index) => (
        <Field
          label={index === 0 ? companyContact.type : ''}
          value={contact}
          valueType="small"
          color="black"
          isCopyable
        />
      ))}
      {companyContact.description && (
        <>
          <VStack>
            <Text.Small color="black">
              {translate('purchases.companyDetails.moreInfo')}
            </Text.Small>
            <Text.Small color="monochrome-dark">{companyContact.description}</Text.Small>
          </VStack>
        </>
      )}
    </VStack>
  );
};

const liknoss_contact: CompanyContactDetail[] = [
  {
    type: 'Telephone',
    title: 'Technical Support Office',
    data: '+30 211 9558888',
  },
  {
    type: 'Telephone',
    title: 'General Office',
    data: '+30 211 9558800',
  },
  {
    type: 'Email',
    title: 'Support Office',
    data: 'support@liknoss.com',
  },
  {
    type: 'Email',
    title: 'Content Office',
    data: 'ContentGroup@liknoss.com',
  },
];
