import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { NoticeListItemResponse } from '@naus-code/naus-admin-types';

import { LiveDateText } from '~/components/DateText';

export interface NoticesListItemComponentProps {
  notice: NoticeListItemResponse;
}

export const NoticesListItemComponent = ({ notice }: NoticesListItemComponentProps) => {
  const { title, dateCreated, description } = notice;
  return (
    <HStack flex={1}>
      <VStack flex={1}>
        <LiveDateText
          dateTime={dateCreated}
          formatOption="fullDateTime"
          type="small"
          align="left"
          color="monochrome-extraDark"
        />
        <Text.Body2Medium>{title}</Text.Body2Medium>
        {description ? (
          <Text.Body2Regular color="monochrome-extraDark">
            {description}
          </Text.Body2Regular>
        ) : null}
      </VStack>
    </HStack>
  );
};
