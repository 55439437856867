import {
  ButtonInteractionState,
  IconButton,
} from '@droplet-tech-code/core-elements/module/ui/Button';
import { IconCircle } from '@droplet-tech-code/core-elements/module/ui/Icon';
import {
  useModal,
  useModalVerify,
} from '@droplet-tech-code/core-elements/module/ui/Modal';
import {
  PopoverContainer,
  popoverControl,
  PopoverList,
} from '@droplet-tech-code/core-elements/module/ui/Popover';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { Pressable, TouchableOpacity } from 'react-native';

import { LiveDateText } from '~/components/DateText';
import { LinkText } from '~/components/LinkText';
import { MenuActionItem } from '~/components/MenuActionItem';
import { PageHeader } from '~/components/Page/Page.Header';
import { Pill } from '~/components/Pill/Pill';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { useSupportDetailsModal } from '~/components/SupportRequest/MobileSupportPanel';
import { useChangeSupportType } from '~/components/SupportRequest/Modals/ChangeSupportType';
import { AppText } from '~/components/Text';
import { useAppNavigation } from '~/hooks/nav.hook';
import { useGetAuthUser } from '~/screens/Auth/Auth.Context';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { translate } from '~/utils/translation.utils';

import { supportRequestActionsApi } from './supportRequestActions.api';

export const SupportRequestItemHeader = ({
  supportRequest,
  check,
  onClose,
  refetch,
}: {
  supportRequest: SupportRequestBackendClient;
  check: string;
  onClose?: () => void;
  refetch?: () => void;
}) => {
  const { closeModal } = useModal();
  const [deleteRequest, { isLoading: isDeleting }] =
    supportRequestApi.useDeleteSupportRequestMutation();
  const { verifyAction } = useModalVerify();
  const { open: openChangeType } = useChangeSupportType();
  const { open: openDetails } = useSupportDetailsModal();

  if (supportRequest === undefined) {
    return null;
  }

  const { p_email, ref, dateCreated, supportTypeTitle } = supportRequest;
  return (
    <PageHeader
      title={supportTypeTitle}
      withBack={isNativeMobile}
      iconLeft={{
        color: 'monochrome',
        icon: 'close',
        onPress: onClose,
      }}
      childRight={
        <HStack space="3" flex={1}>
          <AssignSelfButton supportRequest={supportRequest} check={check} />
          {refetch ? (
            <IconButton
              color="monochrome"
              icon="refresh"
              onPress={() => {
                refetch();
              }}
            />
          ) : null}
          <PopoverContainer
            width={250}
            maxHeight={320}
            offsetX={-5}
            Popover={
              <PopoverList
                list={[
                  {
                    item: (
                      <TouchableOpacity
                        onPress={async () => {
                          closeModal('openPopover');
                          popoverControl.closePopover();
                          await verifyAction({
                            title: supportRequest.ref,
                            description: translate('supportRequests.areYouSureDelete'),
                          }).then(async (verify) => {
                            if (verify) {
                              await deleteRequest(supportRequest._id);
                            }
                          });
                        }}
                      >
                        <DeleteButton isLoading={isDeleting} />
                      </TouchableOpacity>
                    ),
                  },
                  {
                    item: (
                      <TouchableOpacity
                        onPress={async () => {
                          closeModal('openPopover');
                          popoverControl.closePopover();
                          openChangeType(check, supportRequest);
                        }}
                      >
                        <ChangeTypeButton />
                      </TouchableOpacity>
                    ),
                  },
                  ...(isNativeMobile
                    ? [
                        {
                          item: (
                            <TouchableOpacity
                              onPress={async () => {
                                closeModal('openPopover');
                                popoverControl.closePopover();
                                openDetails(supportRequest._id);
                              }}
                            >
                              <DetailsButton />
                            </TouchableOpacity>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            }
          >
            {({ hovered }: ButtonInteractionState) => {
              {
                return <IconCircle icon="menu" hovered={hovered} />;
              }
            }}
          </PopoverContainer>
        </HStack>
      }
      Subtitle={
        isWeb ? (
          <SupportRequestItemHeaderSubTitle
            email={p_email}
            reference={ref}
            dateCreated={dateCreated}
            trackId={
              supportRequest.supportTypeId === 'tracking-report' && supportRequest.trackId
                ? supportRequest.trackId
                : undefined
            }
          />
        ) : null
      }
    />
  );
};

const AssignSelfButton = ({
  supportRequest,
  check,
}: {
  supportRequest: SupportRequestBackendClient;
  check: string;
}) => {
  const currentUserId = useGetAuthUser().currentUser?.uid;
  const [assignUser, { isLoading: isAssigning }] =
    supportRequestActionsApi.useAssignAdminUserSelfMutation();
  const [unassignUser, { isLoading: isUnassigning }] =
    supportRequestActionsApi.useUnassignAdminUserSelfMutation();
  const isOwn = supportRequest._adminUserId === currentUserId;

  if (supportRequest._adminUserId && !isOwn) {
    return null;
  }

  return (
    <Pressable
      onPress={() => {
        if (isOwn) {
          unassignUser({
            check,
            supportId: supportRequest._id,
          });
        } else {
          assignUser({
            check,
            supportId: supportRequest._id,
          });
        }
      }}
    >
      <Pill bg={isOwn ? 'success' : undefined} color={isOwn ? 'success' : 'monochrome'}>
        <HStack space="1">
          <IconButton
            color={isOwn ? 'success' : 'monochrome'}
            icon="customer-support-v2"
            size="small"
            isLoading={isAssigning || isUnassigning}
          />
          {isOwn ? (
            <AppText.MiscTag color="success">
              {translate('supportRequests.assigned')}
            </AppText.MiscTag>
          ) : (
            <AppText.MiscTag color="monochrome-dark">
              {translate('supportRequests.assignSelf')}
            </AppText.MiscTag>
          )}
        </HStack>
      </Pill>
    </Pressable>
  );
};

const ChangeTypeButton = ({ isLoading }: { isLoading?: boolean }) => {
  return (
    <MenuActionItem
      text={translate('supportRequests.changeType')}
      icon="edit"
      isLoading={isLoading}
      disabled={isLoading}
    />
  );
};

const DeleteButton = ({ isLoading }: { isLoading?: boolean }) => {
  return (
    <MenuActionItem
      text={translate('common.delete')}
      icon="bin"
      isLoading={isLoading}
      disabled={isLoading}
    />
  );
};

const DetailsButton = ({ isLoading }: { isLoading?: boolean }) => {
  return (
    <MenuActionItem
      text={translate('supportRequests.details')}
      icon="todo-list"
      isLoading={isLoading}
      disabled={isLoading}
    />
  );
};

export const SupportRequestItemHeaderSubTitle = ({
  email,
  reference,
  dateCreated,
  trackId,
}: {
  email: string;
  reference: string;
  dateCreated: string;
  trackId?: string;
}) => {
  const navigate = useAppNavigation();

  return (
    <SwitchStack space="1" style={{ overflow: 'hidden' }}>
      <Text.Body2Regular numberOfLines={1} color="monochrome-dark">{`${reference} ${
        isWeb ? '|' : ''
      }`}</Text.Body2Regular>

      {trackId ? (
        <Text.Body2Regular numberOfLines={1} color="monochrome-dark">
          {`${trackId} ${isWeb ? '|' : ''}`}
        </Text.Body2Regular>
      ) : null}
      <LinkText
        onPress={() => {
          navigate.navigate('PurchasesByEmail', {
            email,
            tabTitle: email + ' - Purchases',
          });
        }}
      >{`${translate('supportRequests.seePurchasesByEmail', {
        email,
      })} ${isWeb ? '|' : ''}`}</LinkText>
      <LiveDateText
        dateTime={dateCreated}
        formatOption="fullDateTime"
        type="body2Regular"
        color="monochrome-dark"
      />
    </SwitchStack>
  );
};
