import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useCallback, useRef } from 'react';
import { RefreshControl } from 'react-native';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { useInvalidateOnFocus } from '~/utils/invalidate.utils';

import { FlatList } from '../../../components/FlatList/FlatList';
import { useAppNavigation } from '../../../hooks/nav.hook';
import { translate } from '../../../utils/translation.utils';
import { vesselApi } from '../Vessels.api';
import { VesselListItem } from '../Vessels.types';
import { VesselListHeader } from './Vessel.ListHeader';
import { VesselListItemComponent } from './Vessel.ListItem';

export interface VesselListProps {
  onPress: (item: VesselListItem) => void;
}

export const VesselListNative = () => {
  const navigate = useAppNavigation();
  return (
    <VesselList
      onPress={(item) => {
        navigate.navigate('Vessel-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const VesselList = (props: VesselListProps) => {
  useInvalidateOnFocus(['getVesselList']);
  const { list, Search, isLoading, isFetching, refetch } = useSearchState({
    hook: vesselApi.useGetVesselListQuery,
    style: {
      height: 47,
    },
  });

  const selectedRef = useRef('');
  const { spacing } = useAppTheme();

  const renderItem = useCallback((vessel) => {
    return (
      <VesselListItemComponent
        key={vessel.id}
        vessel={vessel}
        onPress={() => {
          selectedRef.current = vessel.id;
          props.onPress(vessel);
        }}
      />
    );
  }, []);

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <VesselListHeader />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        feedback={translate('vessels.emptyVessel')}
        isLoading={isLoading}
        data={list}
        simple
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
