import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { PurchaseListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef, useState } from 'react';
import { RefreshControl } from 'react-native';

import { FlatList } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { useChangeParam } from '~/components/Modals/ChangeParamModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { PurchaseListItemComponent } from '../Purchase.ListItem';
import { purchaseApi } from '../Purchases.api';
import { PurchasesCTNListHeader } from './PurchasesByCTN.ListHeader';

export interface PurchaseListProps {
  onPress: (purchase: PurchaseListItemResponse) => void;
  companyTicketNumber: string;
  companyId?: string;
}

export const PurchaseListNative = ({
  companyTicketNumber,
}: {
  companyTicketNumber: string;
}) => {
  const navigate = useAppNavigation();
  return (
    <PurchaseList
      companyTicketNumber={companyTicketNumber}
      onPress={(item) => {
        navigate.navigate('Purchase-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const PurchaseList = (props: PurchaseListProps) => {
  const selectedRef = useRef('');

  const { companyId } = props;
  const [companyTicketNumber, setCompanyTicketNumber] = useState(
    props.companyTicketNumber,
  );
  const { list, Search, isLoading, isFetching, refetch } = useSearchState({
    hook: purchaseApi.useGetPurchaseListByCompanyTicketNumberQuery,
    style: {
      height: 47,
    },
    keyToList: 'purchases',
    arg: { companyTicketNumber, companyId },
  });

  const renderItem = useCallback((purchase: PurchaseListItemResponse) => {
    return (
      <PurchaseListItemComponent
        key={purchase.id}
        purchase={purchase}
        onPress={() => {
          selectedRef.current = purchase.id;
          props.onPress(purchase);
        }}
      />
    );
  }, []);
  const { open: openEditParam } = useChangeParam();

  const onEditCompanyTicketNumber = (param: string) => {
    setCompanyTicketNumber(param);
  };

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <PurchasesCTNListHeader
        companyName={companyId || ''}
        companyTicketNumber={companyTicketNumber}
        onReset={() => {
          openEditParam({
            initialValue: companyTicketNumber,
            title: translate('purchases.purchasesByCompanyTicketNumber'),
            subtitle: translate('purchases.enterTicketNumber'),
            label: translate('purchases.companyTicketNumber'),
            onConfirm: onEditCompanyTicketNumber,
          });
        }}
        onPress={() => refetch()}
      />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        feedback={translate('purchases.emptyPurchases')}
        isLoading={isLoading}
        data={list}
        renderItem={renderItem}
        simple
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
