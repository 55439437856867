import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportConversation } from '@naus-code/naus-client-types';
import { useCallback, useEffect, useRef } from 'react';
import { FlatList, Pressable } from 'react-native';

import { SupportConversationInputPanel } from '~/components/SupportConversation/InputPanel/Conversation.InputPanel';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { translate } from '~/utils/translation.utils';

import { CardWithHeader } from '../Card/CardWithHeader';
import { SupportConversationBubble } from './SupportConversation.Bubble';
import { isHandlerMessage } from './SupportConversation.utils';

interface SupportConversationProps {
  supportConversation?: SupportConversation[];
  check: string;
  supportRequestId: string;
  preview?: boolean;
}

export const SupportRequestConversation = (props: SupportConversationProps) => {
  const scrollRef = useRef<FlatList<SupportConversation> | null>();
  const { spacing } = useAppTheme();
  useEffect(() => {
    scrollRef.current?.scrollToEnd({ animated: false });
    window.setTimeout(() => {
      scrollRef.current?.scrollToEnd({ animated: false });
    }, 550);
  }, [props.supportConversation?.length]);

  const keyExtractor = useCallback((item: SupportConversation, _: number) => {
    return item.id;
  }, []);

  const { supportConversation, supportRequestId, check } = props;
  const List = (
    <FlatList
      ref={(e) => {
        scrollRef.current = e;
      }}
      contentContainerStyle={[
        {
          flexGrow: 1,
          padding: spacing[3],
        },

        props.preview && {
          paddingVertical: 32,
        },
      ]}
      onContentSizeChange={() => {
        // scrollRef.current?.scrollToEnd({ animated: false });
      }}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={() => <View my="1" />}
      data={supportConversation || []}
      renderItem={({ item: conversation }) => {
        return (
          <HStack space="2.5">
            {!isHandlerMessage(conversation) ? null : <View flex={1} bg="secondary" />}
            <SupportConversationBubble
              bg="error"
              supportRequestId={supportRequestId}
              check={check}
              item={conversation}
              style={[
                {
                  alignSelf: isHandlerMessage(conversation) ? 'flex-start' : 'flex-end',
                },
              ]}
            />
            {!isHandlerMessage(conversation) ? <View flex={1} /> : null}
          </HStack>
        );
      }}
    />
  );

  if (props.preview) {
    return List;
  }

  return (
    <CardWithHeader
      headerText={translate('supportConversations.conversation')}
      flex={isNativeMobile ? undefined : 1}
      style={[isWeb && { width: '100%' }]}
    >
      {List}
    </CardWithHeader>
  );
};

const ModalKey = 'SupportRequestConversation';

export const SupportRequestConversationPreview = (props: SupportConversationProps) => {
  const { openModal } = useModal();

  const lastMessage = props.supportConversation || [];

  return (
    <Pressable
      disabled={lastMessage.length === 0}
      onPress={() => {
        openModal(<MobileConversationPanel {...props} />, {
          key: ModalKey,
          type: 'action-sheet-full',
        });
      }}
    >
      <CardWithHeader headerText={translate('supportConversations.conversation')}>
        <HStack p="3">
          <View flex={1}>
            {lastMessage.length ? (
              <SupportConversationBubble
                preview
                supportRequestId={props.supportRequestId}
                check={props.check}
                item={lastMessage[lastMessage.length - 1]}
              />
            ) : (
              <Text.Body1Medium>
                {translate('supportConversations.noConversation')}
              </Text.Body1Medium>
            )}
          </View>
          <Icon icon="chevron-right" color="monochrome-extraDark" customSize={32} />
        </HStack>
      </CardWithHeader>
    </Pressable>
  );
};

const MobileConversationPanel = (props: SupportConversationProps) => {
  const { closeModal } = useModal();
  const { data, isLoading } = supportRequestApi.useGetSupportRequestQuery(
    props.supportRequestId || '',
    {
      skip: !props.supportRequestId,
      refetchOnMountOrArgChange: !!props.supportRequestId,
    },
  );

  const textRef = useRef(new FormHandler());
  const advancedRef = useRef(new FormHandler());

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportConversations.conversation')}
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <Modal.Body flex={1}>
        <VStack flex={1}>
          <ScrollView flex={1}>
            <SupportRequestConversation
              {...props}
              preview
              supportConversation={
                data?.supportRequest.conversation || props.supportConversation
              }
            />
          </ScrollView>
        </VStack>
        <DelayRender isLoading={isLoading}>
          {data ? (
            <SupportConversationInputPanel
              key={props.supportRequestId}
              textRef={textRef}
              advancedRef={advancedRef}
              supportRequestRes={data}
              supportId={props.supportRequestId}
            />
          ) : null}
        </DelayRender>
      </Modal.Body>
    </Modal.Container>
  );
};
