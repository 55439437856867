import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { useModalVerify } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { GetNoticeResponse } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { Field } from '~/components/Card/Field';
import { PageHeader } from '~/components/Page/Page.Header';

import { noticesApi } from './notices.api';
import { useInvalidatePendingNoticeList } from './PendingNotices/PendingNotice.List';

export const NoticeItemHeader = ({
  notice,
  onClose,
  onRefresh,
  onEdit,
}: {
  notice: GetNoticeResponse['notice'];
  onClose: () => void;
  onRefresh: () => void;
  onEdit: (notice: GetNoticeResponse['notice']) => void;
}) => {
  const [deleteNotice] = noticesApi.useDeleteNoticeMutation();
  const { invalidate: invalidatePendingNotices } = useInvalidatePendingNoticeList();
  const { verifyAction } = useModalVerify();

  const onDelete = useCallback(() => {
    verifyAction().then(async (verify) => {
      if (verify) {
        const response = await deleteNotice(notice._id);
        handleResponse({
          response,
          onSuccess: () => {
            onClose();
          },
        });
        invalidatePendingNotices();
      }
    });
  }, [notice]);

  const NoticeSubtitle = useCallback(() => {
    return (
      <VStack>
        <Field value={notice.dateCreated} isDate />
      </VStack>
    );
  }, [notice]);

  return (
    <PageHeader
      title={notice.textTitle['en']}
      Subtitle={<NoticeSubtitle />}
      withBack={isNativeMobile}
      iconLeft={{
        color: 'monochrome',
        icon: 'close',
        onPress: onClose,
      }}
      childRight={
        <RightActions
          onDelete={onDelete}
          onEdit={() => onEdit(notice)}
          onRefresh={onRefresh}
        />
      }
    />
  );
};

const RightActions = ({
  onEdit,
  onRefresh,
  onDelete,
}: {
  onEdit: () => void;
  onRefresh: () => void;
  onDelete: () => void;
}) => {
  return (
    <HStack>
      <IconButton icon="refresh" color="monochrome" onPress={onRefresh} />
      <IconButton icon="edit" color="monochrome" onPress={onEdit} />
      <IconButton icon="bin" color="monochrome" onPress={onDelete} />
    </HStack>
  );
};
