import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useState } from 'react';

import { customerApi } from '~/components/Customer/utils/Customer.api';
import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { ScreenPropCustomerSupportRequests } from '~/navigation/Navigation.types';

import { SupportRequestItem } from '../SupportRequestItem';
import { SupportRequestList, SupportRequestListNative } from './SupportRequest.List';

export const CustomerSupportRequestsScreen = (
  props: ScreenPropCustomerSupportRequests,
) => {
  return (
    <PageContainer>
      <CustomerSupportRequestsLayout {...props} />
    </PageContainer>
  );
};

export const CustomerSupportRequestsLayout = (
  props: ScreenPropCustomerSupportRequests,
) => {
  const supportRequestId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(supportRequestId);
  const email = props.route.params.email;
  const customerId = props.route.params.customerId;

  const { data: customerData, isLoading } = customerApi.useGetCustomerQuery(
    customerId || '',
    {
      skip: !customerId,
    },
  );

  // if (email === "" && customerId) {
  //   const { data: customerData } = customerApi.useGetCustomerQuery(customerId);
  //   if (customerData === undefined) return null;
  //   email = customerData.customer.p_email || customerData.customer._id;
  // }

  if (email === '' && !customerId) {
    return null;
  }
  const cEmail = customerData?.customer.p_email || email;
  const cId = customerData?.customer._id || customerId;

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <SupportRequestList
          isLoading={isLoading}
          email={cEmail}
          customerId={cId}
          supportRequestId={id}
          onPress={(supportRequest) => setId(supportRequest.id)}
        />
      ) : (
        <SupportRequestListNative
          email={customerData?.customer.p_email || email}
          customerId={customerData?.customer._id || customerId}
        />
      )}
      {id ? (
        <SupportRequestItem supportId={id} onClose={() => setId(undefined)} />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
