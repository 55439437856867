import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { ScrollView } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageContainer } from '~/components/Page/Page.Container';

import { FerryFormConfig } from './FerryConfig/FerryFormConfig';
import { OtherConfig } from './OtherConfig/OtherConfig';
import { SystemConfigHeader } from './SystemConfigHeader';
import { TermsConfig } from './TermsConfig/TermsConfig';

export const SystemConfigScreen = () => {
  return (
    <PageContainer>
      <SystemConfigScreenLayout />
    </PageContainer>
  );
};

export const SystemConfigScreenLayout = () => {
  return (
    <>
      <SystemConfigHeader />
      <ScrollView style={isWeb && { width: 892 }}>
        <VStack p="2.5">
          <FerryFormConfig />
          <TermsConfig />
          <OtherConfig />
        </VStack>
      </ScrollView>
    </>
  );
};
