import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  CreateNoticeRequest,
  CreateNoticeResponse,
  GetNoticeResponse,
  NoticeActiveListResponse,
  NoticeListResponse,
  PaginatedListRequest,
  UpdateNoticeRequest,
  UpdateNoticeResponse,
} from '@naus-code/naus-admin-types';
import isEqual from 'lodash/isEqual';

import { rootApi } from '~/store/redux.utils';
import { mergeAndRemoveDuplicates } from '~/utils/utils.helper';

const base_url = 'notice';
export const noticesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // @Get('/get-notice/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getNotice(@Params('id') id: string): Promise<GetNoticeResponse> {
    //     return this._noticeService.getNotice(id);
    // }

    getNotice: builder.query<GetNoticeResponse, string>({
      query: (id) => ({
        url: `${base_url}/get-notice/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getNotice'],
    }),

    deleteNotice: builder.mutation<GetNoticeResponse, string>({
      query: (id) => ({
        url: `${base_url}/delete-notice/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['getActiveNoticesList', 'getPendingNoticesList', 'getNotice'],
    }),

    getPendingNoticesList: builder.query<NoticeListResponse, PaginatedListRequest>({
      query: (data) => ({
        url: `${base_url}/get-list/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getPendingNoticesList'],
    }),

    invalidatePendingNotices: builder.mutation<NoticeListResponse, PaginatedListRequest>({
      query: (data) => ({
        url: `${base_url}/get-list/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            noticesApi.util.updateQueryData('getPendingNoticesList', req, (draft) => {
              draft.isFinal = data.isFinal;
              draft.list = data.list;
            }),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),

    getActiveNoticesList: builder.query<NoticeActiveListResponse, void>({
      query: () => ({
        url: `${base_url}/get-active-list/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getActiveNoticesList'],
    }),

    // @Get('/form-config/create-notice/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // createNoticeFormConfig(): Promise<ConfigForData> {
    //     return this._noticeService.getNoticeCreateForm();
    // }

    createNoticeFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-notice/`,
        method: 'GET',
      }),
      providesTags: ['*', 'createNoticeFormConfig'],
    }),
    // @Post('/create-notice/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // createNotice(@Body() body: CreateNoticeRequest): Promise<CreateNoticeResponse> {
    //     return this._noticeService.createNotice(body);
    // }
    createNotice: builder.mutation<CreateNoticeResponse, CreateNoticeRequest>({
      query: (data) => ({
        url: `${base_url}/create-notice/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getActiveNoticesList', 'getPendingNoticesList'],
    }),

    // @Post('/update-notice/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateNotice(@Body() body: UpdateNoticeRequest): Promise<UpdateNoticeResponse> {
    //     return this._noticeService.updateNotice(body);
    // }
    updateNotice: builder.mutation<UpdateNoticeResponse, UpdateNoticeRequest>({
      query: (data) => ({
        url: `${base_url}/update-notice/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getActiveNoticesList', 'getPendingNoticesList', 'getNotice'],
    }),
  }),
});
