import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

interface ModificationConfig {
  canModify: boolean;
  numberOfModificationsAllowed: number;
  requiresPrintForModify: boolean;
  requiresOpenForModify: boolean;
  canModifyViaApi: boolean;
  canModifyPartialViaApi: boolean;
  allowCustomerToAutoModify: boolean;
  allowCustomerToAutoModifyPartial: boolean;
}

export const ModificationsInfo = ({
  modifyConfig,
}: {
  modifyConfig: ModificationConfig;
}) => {
  return (
    <SwitchStack py="2.5" space="2.5">
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canModify')}
          </AppText.ExtraSmall>
          <Text.Small> {modifyConfig.canModify ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canModifyAPI')}
          </AppText.ExtraSmall>
          <Text.Small>{modifyConfig.canModifyViaApi ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canModifyAPIPartial')}
          </AppText.ExtraSmall>
          <Text.Small>
            {modifyConfig.canModifyPartialViaApi ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canModifyAPIAllowCustomer')}
          </AppText.ExtraSmall>
          <Text.Small>
            {modifyConfig.allowCustomerToAutoModify ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canModifyAPIAllowCustomerPartial')}
          </AppText.ExtraSmall>
          <Text.Small>
            {modifyConfig.allowCustomerToAutoModifyPartial ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
      </VStack>
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.numberModifications')}
          </AppText.ExtraSmall>
          <Text.Small>{modifyConfig.numberOfModificationsAllowed}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.requiresOpenToModify')}
          </AppText.ExtraSmall>
          <Text.Small>{modifyConfig.requiresOpenForModify ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.requiresPrintToModify')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 10 }} />
          <Text.Small flex={1} numberOfLines={1} align="right">
            {modifyConfig.requiresPrintForModify ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
      </VStack>
    </SwitchStack>
  );
};
