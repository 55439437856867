import type { storeOnlyForProviderUse } from './configureStore';
import type { rootReducer } from './rootReducer';

type StoreType = typeof storeOnlyForProviderUse;
export type RootState = ReturnType<typeof rootReducer>;
class StoreRegistry {
  private _store: StoreType;
  register(storeReg: StoreType) {
    this._store = storeReg;
  }

  getStore() {
    return this._store;
  }

  get getState() {
    return this._store.getState;
  }

  get dispatch() {
    return this._store.dispatch;
  }
}

export const store = new StoreRegistry();
