import { ViewStyle } from '@droplet-tech-code/core-elements/module/types/components';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useState } from 'react';

import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { ActiveConfigCard } from './Cards/ActiveConfigCard';
import { AgencyContactDetailsCard } from './Cards/AgencyContactDetailsCard';
import { CompanyInfoCard } from './Cards/CompanyInfoCard';
import { CompanyConfigItemHeader } from './CompanyConfig.ItemHeader';
import { companyConfigApi } from './CompanyConfigs.api';
import { CompanyOperationsConfig } from './SidePanels/CompanyOperations/CompanyOperations';
import { CompanyOperatorsConfig } from './SidePanels/CompanyOperators/CompanyOperators';
import { DiscountsConfig } from './SidePanels/Discounts/DiscountsConfig';
import { ExtraConfigs } from './SidePanels/ExtraConfigs/ExtraConfigs';
import { Loyalty } from './SidePanels/Loyalty';
import { PassengerConfigs } from './SidePanels/PassengerConfigs/PassengerConfigs';
import { Resident } from './SidePanels/Resident';
import { VehicleConfigs } from './SidePanels/VehicleConfigs/VehicleConfigs';
import { Vessels } from './SidePanels/Vessels';

type SidePanelScreenIds =
  | 'CompanyOperatorsConfig'
  | 'CompanyOperationsConfig'
  | 'DiscountsConfig'
  | 'ExtraConfigs'
  | 'Loyalty'
  | 'PassengerConfigs'
  | 'Resident'
  | 'VehicleConfigs'
  | 'Vessels';

export const CompanyConfigItem = ({ id }: { id: string; onClose: () => void }) => {
  const [sidePanelId, setSidePanelId] = useState('' as SidePanelScreenIds);

  if (isNativeMobile) {
    return (
      <VStack>
        <CompanyConfigItemHeader id={id} />
        <CompanyConfigItemComponent id={id} setSidePanelId={() => {}} />
      </VStack>
    );
  }

  return (
    <VStack flex={1}>
      <CompanyConfigItemHeader id={id} />
      <HStack flex={1}>
        <CompanyConfigItemComponent id={id} setSidePanelId={setSidePanelId} />
        <View style={{ height: '100%', width: '50%' }}>
          <SidePanel screenId={sidePanelId} id={id} />
        </View>
      </HStack>
    </VStack>
  );
};

const SidePanel = ({ screenId, id }: { screenId?: SidePanelScreenIds; id: string }) => {
  switch (screenId) {
    case 'CompanyOperatorsConfig':
      return <CompanyOperatorsConfig companyid={id} />;
    case 'CompanyOperationsConfig':
      return <CompanyOperationsConfig id={id} />;
    case 'DiscountsConfig':
      return <DiscountsConfig id={id} />;
    case 'ExtraConfigs':
      return <ExtraConfigs id={id} />;
    case 'Loyalty':
      return <Loyalty />;
    case 'PassengerConfigs':
      return <PassengerConfigs id={id} />;
    case 'Resident':
      return <Resident />;
    case 'VehicleConfigs':
      return <VehicleConfigs id={id} />;
    case 'Vessels':
      return <Vessels />;
    default:
      return <></>;
  }
};

const CompanyConfigItemComponent = ({
  id,
  style,
  setSidePanelId,
}: {
  id: string;
  style?: ViewStyle;
  setSidePanelId: (id: SidePanelScreenIds) => void;
}) => {
  const { data: companyConfigGetForm, isLoading } =
    companyConfigApi.useGetFerryCompanyConfigQuery(id);

  if (isLoading) {
    return (
      <View flex={1} align="center" justify="center">
        <LoadingIndicator />
      </View>
    );
  }

  if (companyConfigGetForm === undefined) {
    return null;
  }
  const { config: companyConfigData, check } = companyConfigGetForm;
  const { agencyContactDetails, activeConfig } = companyConfigData;

  if (isNativeMobile) {
    return (
      <VStack p="2.5" space="2.5">
        <CompanyInfoCard companyInfo={companyConfigData} />
        <ActiveConfigCard
          activeConfig={activeConfig}
          companyId={companyConfigData.id}
          check={check}
        />
      </VStack>
    );
  }

  return (
    <VStack style={{ height: '100%', width: '50%' }}>
      <ScrollView style={style}>
        <VStack p="2.5" space="2.5">
          <CompanyInfoCard companyInfo={companyConfigData} />
          <ActiveConfigCard
            activeConfig={activeConfig}
            companyId={companyConfigData.id}
            check={check}
          />
          <AgencyContactDetailsCard agencyContactDetails={agencyContactDetails} />
          <CompanyConfigs setSidePanelId={setSidePanelId} id={id} />
          <TicketConfigs setSidePanelId={setSidePanelId} id={id} />
          <OtherConfigs />
        </VStack>
      </ScrollView>
    </VStack>
  );
};

const TicketConfigs = ({
  setSidePanelId,
  id,
}: {
  setSidePanelId: (sidePanelId: string) => void;
  id: string;
}) => {
  const { navigate } = useAppNavigation();
  return (
    <>
      <ConfigListSectionTitle>
        <Text.Small color="monochrome-mid">
          {translate('companyConfig.ticketConfigs')}
        </Text.Small>
      </ConfigListSectionTitle>
      <VStack py="2.5" space="2.5">
        <ConfigListItem
          text={translate('companyConfig.discounts')}
          iconRightType="chevron-right"
          onPress={() => {
            if (isNativeMobile) {
              navigate('DiscountsConfig', { id });
            } else {
              setSidePanelId('DiscountsConfig');
            }
          }}
        />
        <ConfigListItem
          text={translate('companyConfig.passengerConfigs')}
          iconRightType="chevron-right"
          onPress={() => {
            if (isNativeMobile) {
              navigate('PassengerConfigs', { id });
            } else {
              setSidePanelId('PassengerConfigs');
            }
          }}
        />
        <ConfigListItem
          text={translate('companyConfig.vehicleConfigs')}
          iconRightType="chevron-right"
          onPress={() => {
            if (isNativeMobile) {
              navigate('VehicleConfigs', { id });
            } else {
              setSidePanelId('VehicleConfigs');
            }
          }}
        />
        <ConfigListItem
          text={translate('companyConfig.extraConfigs')}
          iconRightType="chevron-right"
          onPress={() => {
            if (isNativeMobile) {
              navigate('ExtraConfigs', { id });
            } else {
              setSidePanelId('ExtraConfigs');
            }
          }}
        />
        <ConfigListItem
          text={translate('companyConfig.extraInputConfigs')}
          iconRightType="chevron-right"
        />
        <ConfigListItem
          text={translate('companyConfig.defaultExtraConfigs')}
          iconRightType="chevron-right"
        />
      </VStack>
    </>
  );
};

const CompanyConfigs = ({
  setSidePanelId,
  id,
}: {
  setSidePanelId: (sidePanelId: SidePanelScreenIds) => void;
  id: string;
}) => {
  const { navigate } = useAppNavigation();
  return (
    <>
      <ConfigListSectionTitle>
        <Text.Small color="monochrome-mid">
          {translate('companyConfig.company')}
        </Text.Small>
      </ConfigListSectionTitle>
      <VStack py="2.5" space="2.5">
        <ConfigListItem
          text={translate('companyConfig.operators')}
          iconRightType="chevron-right"
          onPress={() => {
            if (isNativeMobile) {
              navigate('CompanyOperatorsConfig', { id });
            } else {
              setSidePanelId('CompanyOperatorsConfig');
            }
          }}
        />
        <ConfigListItem
          text={translate('companyConfig.operationsCancellationsMod')}
          iconRightType="chevron-right"
          onPress={() => {
            if (isNativeMobile) {
              navigate('CompanyOperationsConfig', { id });
            } else {
              setSidePanelId('CompanyOperationsConfig');
            }
          }}
        />
      </VStack>
    </>
  );
};

const OtherConfigs = () => {
  return (
    <>
      <ConfigListSectionTitle>
        <Text.Small color="monochrome-mid">
          {translate('companyConfig.company')}
        </Text.Small>
      </ConfigListSectionTitle>
      <VStack py="2.5" space="2.5">
        <ConfigListItem
          text={translate('companyConfig.loyalty')}
          iconRightType="chevron-right"
        />
        <ConfigListItem
          text={translate('companyConfig.resident')}
          iconRightType="chevron-right"
        />
        <ConfigListItem
          text={translate('companyConfig.vessels')}
          iconRightType="chevron-right"
        />
      </VStack>
    </>
  );
};
