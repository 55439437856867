import {
  createBasketManager,
  createBasketSlice,
} from '@naus-code/naus-client-common/src/GlobalContext/GlobalContext.basket';

import { store } from '~/store/registry';
import { DATE_SERVER_FORMAT } from '~/utils/date.utils';

const storeRef = { current: store.getStore() };

function initializeStore() {
  setTimeout(() => {
    storeRef.current = store.getStore();
    if (!storeRef.current) {
      initializeStore();
    }
  });
}

initializeStore();
const configuredBasketManager = createBasketManager(
  {
    // /**
    //  * Server side work
    //  */
    // onVerifyLoyalty: getVerifyLoyaltyApi(args?.fetchManager || fetchManager),
    // onVerifyPrice: getVerifyPriceApi(args?.fetchManager || fetchManager),

    config: {
      DATE_FORMAT_CLIENT: DATE_SERVER_FORMAT,
      DATE_FORMAT_SERVER: DATE_SERVER_FORMAT,
    },
    devOptions: {
      disableAutoPriceFetch: undefined,
      disablePriceFetch: undefined,
      enableLogs: false,
      loadTestTranslations: true,
      mockPriceFetch: undefined,
    },
  },
  storeRef,
);

export const basketManager = configuredBasketManager.basketManager;

export const basketManagerSlice = createBasketSlice(basketManager);
