import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { AppViewProps } from '@droplet-tech-code/core-elements/module/ui/View';
import { useState } from 'react';

interface ExpandableFieldProps extends AppViewProps {
  label: string;
}

export const ExpandableField = (props: ExpandableFieldProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <VStack>
      <HStack>
        <Text.Small flex={1} color="monochrome-dark">
          {props.label}
        </Text.Small>
        <IconButton
          icon={expanded ? 'chevron-up' : 'chevron-down'}
          onPress={() => setExpanded(!expanded)}
        />
      </HStack>
      {expanded && props.children}
    </VStack>
  );
};
