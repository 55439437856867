import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import React, { useCallback } from 'react';

import { ConversationImageUploader } from '~/components/SupportConversation/InputPanel/Conversation.ImageUploader';
import { SupportConversationInputPanelProps } from '~/components/SupportConversation/InputPanel/Conversation.utils';
import {
  chatCache,
  ChatInput,
} from '~/components/SupportConversation/SupportConversation.utils';

export const SupportConversationTextBox = (props: SupportConversationInputPanelProps) => {
  const onChangeForm = useCallback(() => {
    chatCache.addToCache(
      props.supportId,
      props.textRef.current.getSingleValue({ id: 'answer' }),
    );
  }, []);

  return (
    <ChatInput
      iconEnd={<ConversationImageUploader />}
      textInputProps={{
        spellCheck: true,
        autoCorrect: true,
        multiline: true,
        nativeID: 'chat-input',
      }}
      onChangeForm={onChangeForm}
      initialValue={props.initialText}
      form={props.textRef.current}
      config={{
        multiline: isWeb ? 7 : 5,
        type: 'text',
        id: 'answer',
        optional: true,
        label: undefined,
        noFeedback: true,
      }}
      style={[{ maxHeight: 300 }]}
    />
  );
};
