import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { ScrollView } from 'react-native-gesture-handler';

import { BookingInfo } from '~/components/Bookings/BookingInfo';
import { Card } from '~/components/Card/Card';
import { ScreenPropBookingItem } from '~/navigation/Navigation.types';

import { PurchaseItem } from '../Purchases/PurchaseItem';
import { BookingItemHeader } from './BookingItem.ItemHeader';
import { bookingApi } from './Bookings.api';

export const BookingItemScreen = (props: ScreenPropBookingItem) => {
  return <BookingItem bookingId={props.route.params.id} onClose={() => {}} />;
};

export const BookingItem = ({
  bookingId,
  onClose,
}: {
  bookingId: string;
  onClose?: () => void;
}) => {
  const { data, refetch, isFetching } = bookingApi.useGetBookingQuery(bookingId);

  if (data === undefined) {
    return null;
  }

  const purchaseIds = data.booking._purchaseIds;

  return (
    <VStack flex={1}>
      <BookingItemHeader
        bookingData={data.booking}
        onClose={onClose}
        onRefresh={refetch}
        isFetching={isFetching}
      />
      <ScrollView>
        <VStack p="2.5" flex={1}>
          <VStack space="2.5">
            <BookingInfo bookingId={bookingId} />
            {purchaseIds.map((purchaseId) => {
              return (
                <Card key={purchaseId} p="2.5" w={isWeb ? 600 : undefined}>
                  <PurchaseItem
                    purchaseId={purchaseId}
                    // onClose={onClose}
                    withActions
                  />
                </Card>
              );
            })}
          </VStack>
        </VStack>
      </ScrollView>
    </VStack>
  );
};
