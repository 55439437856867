import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import alertMp3 from '../assets/alert_sound.mp3';
import newMessageMp3 from '../assets/message_alert.mp3';

export function showWebNotification({
  message,
  navigate,
}: {
  message: string;
  navigate?: () => void;
}) {
  if (isNativeMobile) {
    return;
  }

  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const notification = new Notification(message);

    notification.onclick = () => {
      navigate?.();
    };
    // …
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        const notification = new Notification(message);
        notification.onclick = () => {
          navigate?.();
        };
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
}

const playingCheck = {
  notification: false,
  newMessage: false,
};

export function playNotificationAlert() {
  if (isWeb) {
    const audio = new Audio(alertMp3);
    const ele = document.createElement('div');

    ele.onclick = () => {
      if (playingCheck.notification) {
        return;
      }
      playingCheck.notification = true;
      setTimeout(() => {
        (async () => {
          try {
            await audio.play();
          } finally {
            playingCheck.notification = false;
          }
        })();
      });
    };
    ele.click();
  }
}

export function playNewMessage() {
  if (isWeb) {
    const audio = new Audio(newMessageMp3);
    const ele = document.createElement('div');

    ele.onclick = () => {
      if (playingCheck.newMessage) {
        return;
      }
      playingCheck.newMessage = true;
      setTimeout(() => {
        (async () => {
          try {
            await audio.play();
          } finally {
            playingCheck.newMessage = false;
          }
        })();
      });
    };
    ele.click();
  }
}
