import { Icon, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { RouteTrip } from '@naus-code/naus-client-types';

export const AttentionTripInfoLine = ({
  trip,
  center,
}: {
  trip: RouteTrip;
  center?: boolean;
}) => {
  return (
    <HStack
      align="center"
      space="3"
      justify={center ? 'center' : 'flex-start'}
      style={{ flexWrap: 'wrap' }}
    >
      <Text.Body2Medium>{`${trip.originDisplay} -> ${trip.destDisplay}`}</Text.Body2Medium>
      <Text.Body2Regular color="monochrome-extraLight">•</Text.Body2Regular>
      <Text.Body2Medium color="monochrome-mid">{`${trip.depTimeDisplay} ${dayjs(
        trip.depDateDisplay,
        'DD/MM/YY',
      ).format('ddd DD MMMM')}`}</Text.Body2Medium>
    </HStack>
  );
};

export const AttentionTicketChangeItem = ({
  text,
  icon,
  active,
}: {
  text: JSX.Element;
  icon: IconsId;
  active: boolean;
}) => {
  return (
    <HStack
      bg="primary-extraLight"
      br={12}
      px="3"
      py="2"
      space="1"
      align="center"
      style={{ height: 36 }}
    >
      <Icon color={active ? 'black' : 'monochrome-mid'} icon={icon} />
      {text}
    </HStack>
  );
};
