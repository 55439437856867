import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  isNativeMobile,
  isWeb,
  notEmpty,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { GetPurchaseResponse, TicketClient } from '@naus-code/naus-admin-types';
import { useState } from 'react';

import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { PurchaseListItemInternalComponent } from '~/screens/Support/Purchases/Purchase.ListItem';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { TicketDetails } from '~/screens/Support/Purchases/PurchaseItem';
import { purchaseApi } from '~/screens/Support/Purchases/Purchases.api';
import { translate } from '~/utils/translation.utils';

export const ModalSplitPurchaseKey = 'SplitPurchase';
export type SplitPurchaseProps = {
  purchaseId: string;
  supportId: string;
};

const SplitPurchaseHeader = () => {
  const { closeModal } = useModal();

  return (
    <Modal.Header
      title={translate('purchases.purchaseTool.splitPurchase')}
      divider
      onPress={() => {
        closeModal(ModalSplitPurchaseKey);
      }}
    />
  );
};

export const SplitPurchase = ({ purchaseId, supportId }: SplitPurchaseProps) => {
  return (
    <Modal.Container>
      <SplitPurchaseHeader />
      <PurchaseSplit supportId={supportId} purchaseId={purchaseId} />
    </Modal.Container>
  );
};

const PurchaseSplit = (props: SplitPurchaseProps) => {
  const { data, isLoading } = purchaseApi.useGetPurchaseQuery(props.purchaseId);
  return (
    <DelayRender noDelay={!!data} isLoading={isLoading}>
      {data ? <PurchaseSplitContainer {...props} purchase={data.purchase} /> : null}
    </DelayRender>
  );
};

const PurchaseSplitContainer = (
  props: { purchase: GetPurchaseResponse['purchase'] } & SplitPurchaseProps,
) => {
  const [checkedItems, setCheckedItems] = useState(
    props.purchase.tickets.map(() => false),
  );
  const tickets = checkedItems
    .map((checked, ix) => (checked ? props.purchase.tickets[ix] : null))
    .filter(notEmpty);

  return (
    <>
      <ModalScrollView>
        <VStack style={{ flex: 1 }} p="3" space="6">
          <Text.Small color="monochrome-mid">
            {translate('purchases.purchaseTool.selectTicketsToSplit')}
          </Text.Small>
          <SwitchStack space={isWeb ? '6' : '3'}>
            <VStack space="3">
              <HStack space="6">
                <CheckBox
                  checked={checkedItems.every((v) => v)}
                  onPress={(v) => {
                    setCheckedItems(checkedItems.map(() => v));
                  }}
                />
                <PurchaseListItemInternalComponent
                  purchase={props.purchase}
                  compareMode
                />
              </HStack>
              <View>
                <DottedLine />
              </View>
              <VStack space="4" flex={isWeb ? 1 : undefined}>
                {props.purchase.tickets.map((ticket, iy) => {
                  return (
                    <HStack space="3" key={iy} pl="6">
                      <CheckBox
                        checked={checkedItems[iy]}
                        onPress={(isChecked) => {
                          const newItems = [...checkedItems];
                          newItems[iy] = isChecked;
                          setCheckedItems(newItems);
                        }}
                      />
                      <VStack space="2" flex={isNativeMobile ? 1 : undefined}>
                        <TicketDetails
                          ticket={ticket}
                          ccySymbol={props.purchase.ccySymbol}
                        />
                        <View>
                          {props.purchase.tickets.length - 1 === iy ? null : (
                            <DottedLine />
                          )}
                        </View>
                      </VStack>
                    </HStack>
                  );
                })}
              </VStack>
            </VStack>
          </SwitchStack>
        </VStack>
      </ModalScrollView>
      <SplitPurchaseFooter
        tickets={tickets}
        purchaseId={props.purchaseId}
        supportId={props.supportId}
      />
    </>
  );
};

const SplitPurchaseFooter = (
  props: {
    tickets: TicketClient[];
  } & SplitPurchaseProps,
) => {
  const [splitPurchase, { isLoading: isSplitting }] =
    purchaseActionApi.useSplitPurchaseMutation();
  const [silentOn, setSilentOn] = useState(false);

  const { closeModal } = useModal();

  return (
    <Modal.Footer
      divider
      isLoading={isSplitting}
      confirm={translate('purchases.purchaseTool.splitPurchase')}
      onConfirm={async () => {
        const response = await splitPurchase({
          purchaseId: props.purchaseId,
          supportId: props.supportId,
          ticketIdsForNewPurchase: props.tickets.map((t) => t._id),
        });

        handleResponse({ response });
        closeModal(ModalSplitPurchaseKey);
      }}
    >
      <HStack space="1">
        <Toggle color="primary-mid" value={silentOn} onChange={(v) => setSilentOn(v)} />
        <Text.Body2Regular color="monochrome-extraDark">
          {translate('purchases.purchaseTool.purchaseCompare.silentUpdate')}
        </Text.Body2Regular>
      </HStack>
    </Modal.Footer>
  );
};
