import { UpdateVoucherRequest } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { voucherApi } from '~/screens/Vouchers/voucherApi';
import { translate } from '~/utils/translation.utils';

export const useUpdateVoucher = (voucherId: string) => {
  const { open: openFormModal } = useFormModal();
  const modalTitle = translate('voucher.updateVoucher');

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } =
      voucherApi.useUpdateVoucherFormConfigQuery(voucherId);
    return { data: { config }, isLoading };
  }, [voucherId]);

  const [updateVoucher, { isLoading: loadingAction }] =
    voucherApi.useUpdateVoucherMutation();

  const onConfirm = async ({ value }: { value: UpdateVoucherRequest['voucher'] }) => {
    return await updateVoucher({
      voucherId,
      voucher: { ...value, value: value.value ? value.value * 100 : 0 },
    });
  };

  return {
    open: () => {
      openFormModal({
        modalTitle,
        useFormHookHandler,
        onConfirm,
        loadingAction,
      });
    },
  };
};
