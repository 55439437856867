import { ScreenPropVesselItem } from "~/navigation/Navigation.types";

import { VesselItem } from "./Vessel.Item";

export const VesselItemScreen = (props: ScreenPropVesselItem) => {
  return (
    <VesselItem
      key={props.route.params.id}
      id={props.route.params.id}
      onClose={() => {}}
    />
  );
};
