import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SystemConfigUpdateFerryServiceCostConfigRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const UpdateServiceCostModalKey = 'UpdateModal-ServiceCost';
const UpdateServiceCostModal = ({ check }: { check: string }) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [updateServiceCost, { isLoading: isUpdatingServiceCost }] =
    systemConfigApi.useUpdateFerryServiceCostConfigMutation();

  return (
    <>
      <Modal.Header
        title={translate('systemConfig.updateServiceCost')}
        subtitle={translate('systemConfig.enterNewServiceCost')}
        divider
        onPress={() => {
          closeModal(UpdateServiceCostModalKey);
        }}
      />
      <Modal.Body p="0">
        <ServiceCostUpdateForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isUpdatingServiceCost}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              SystemConfigUpdateFerryServiceCostConfigRequest['serviceCostConfig']
            >();

          if (valid) {
            const response = await updateServiceCost({
              check,
              serviceCostConfig: value,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(UpdateServiceCostModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(UpdateServiceCostModalKey);
        }}
      />
    </>
  );
};

export const useUpdateServiceCost = ({ check }: { check: string }) => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<UpdateServiceCostModal check={check} />, {
        key: UpdateServiceCostModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};

const ServiceCostUpdateForm = ({ form }: { form: FormHandler }) => {
  const { data: serviceCostUpdateConfig, isLoading: isLoadingServiceCostConfig } =
    systemConfigApi.useUpdateFerryServiceCostConfigFormConfigQuery();

  return (
    <FormContent
      isLoading={isLoadingServiceCostConfig}
      form={serviceCostUpdateConfig}
      flex={1}
    >
      {serviceCostUpdateConfig ? (
        <FormBuilder data={serviceCostUpdateConfig.config} form={form} />
      ) : null}
    </FormContent>
  );
};
