import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { popoverControl } from '@droplet-tech-code/core-elements/module/ui/Popover';
import {
  HStack,
  StackProps,
  VStack,
} from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { TransactionClient } from '@naus-code/naus-admin-types';
import { useMemo } from 'react';
import { Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native';

import { MenuActionItem } from '~/components/MenuActionItem';
import { RowItem } from '~/components/Table/Table.types';
import { useAppNavigation } from '~/hooks/nav.hook';
import { useViewTransaction } from '~/screens/Finance/Transactions/Transactions.Modal';
import { useTransactionStore } from '~/screens/Finance/Transactions/Transactions.store';
import { useIsSelectedTransaction } from '~/screens/Finance/Transactions/Transactions.utils';

export const InputContainer = (props: StackProps) => (
  <VStack b={1} br={12} bc="monochrome-light" p="3" {...props} />
);

export const AnalysisRow = (props: StackProps) => <HStack space="1.5" {...props} />;
export const AnalysisRowItem = (
  props: StackProps & { value: string; currency: string; label: string },
) => (
  <InputContainer {...props} style={[{ width: 275 }, props.style]} px="3" py="1.5">
    <HStack space="3">
      <Text.Small numberOfLines={1} style={{ width: 110 }} color="monochrome-mid">
        {props.label}
      </Text.Small>
      <View flex={1} />
      <HStack space="1.5">
        <Text.Small color="monochrome-dark" align="left">
          {props.currency}
        </Text.Small>
        <Text.Body2Regular align="right">{props.value}</Text.Body2Regular>
      </HStack>
    </HStack>
  </InputContainer>
);

export const useGetTransactionOption = (row: RowItem<TransactionClient>) => {
  const viewer = useViewTransaction();
  const navigation = useAppNavigation();
  const modals = useModal();

  return useMemo(() => {
    return [
      {
        item: <MenuActionItem icon="info" text="Data" />,
        onPress: () => {
          popoverControl.closePopover();
          viewer.open({ transaction: row.item });
        },
      },
      {
        item: (
          <MenuActionItem
            icon="book"
            disabled={!row.item.bookingId}
            text="Go to booking"
          />
        ),
        onPress: () => {
          popoverControl.closePopover();
          modals.closeAll();
          if (row.item.bookingId) {
            navigation.navigate('Booking-Item', {
              id: row.item.bookingId,
            });
          }
        },
      },
    ];
  }, [row]);
};

export const LinkedIndicator = (props: AppViewProps) => {
  return (
    <View
      {...props}
      style={[
        {
          position: 'absolute',
          width: 8,
          height: '100%',
          borderRadius: 999,
          backgroundColor: 'white',
        },
        props.style,
      ]}
    />
  );
};

export const LinkedTransactionIndicator = ({
  row,
}: {
  row: RowItem<TransactionClient>;
}) => {
  const selected = useIsSelectedTransaction(row);
  const hoveredByOther = useTransactionStore(
    (s) => row.item.bookingId && s.hoveredBookingId === row.item.bookingId,
  );
  const { palette } = useAppTheme();

  return (
    <LinkedIndicator
      style={[
        !selected && hoveredByOther
          ? {
              backgroundColor: palette.secondary.light,
            }
          : undefined,
      ]}
    />
  );
};

export const TransactionHoverContainer = ({
  row,
  ...props
}: PressableProps & { row: RowItem<TransactionClient> }) => {
  const setState = useTransactionStore((s) => s.setState);
  const selected = useIsSelectedTransaction(row);
  const { palette } = useAppTheme();

  return (
    <Pressable
      {...props}
      style={({ hovered }: ButtonInteractionState) => {
        return [
          {
            borderColor: 'white',
            borderWidth: 1,
            borderRadius: 12,
            overflow: 'hidden',
            flex: 1,
          },
          props.style,
          selected && { borderColor: palette.primary.light },
          hovered && { backgroundColor: palette.primary.base },
        ] as StyleProp<ViewStyle>;
      }}
      onPress={() => {
        if (selected) {
          setState({ selectedTransaction: undefined });
        } else {
          setState({ selectedTransaction: row.item });
        }
      }}
      onHoverIn={() => {
        setState({ hoveredBookingId: row.item.bookingId });
      }}
      onHoverOut={() => {
        setState({ hoveredBookingId: undefined });
      }}
    />
  );
};
