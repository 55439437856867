import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';

import { CancelForm } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.Form';
import { CancelPurchaseForm } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.utils';
import { DATE_TIME_SERVER_FORMAT } from '~/utils/date.utils';

const config = (date: string): ConfigForData => [
  {
    items: [
      {
        id: 'type',
        type: 'single-select',
        list: [
          {
            value: 'external',
            label: 'Externally',
          },
          {
            value: 'api',
            label: 'API',
          },
        ],
        initialValue: 'api',
        label: 'Provider cancellation type',
        feedbackOnlyWithError: true,
      },
    ],
  },
  {
    items: [
      {
        id: 'expectedRefundFromCompany',
        type: 'number',
        label: 'Expected refund from company',
        feedbackOnlyWithError: true,
        multipleOf: 100,
      },
    ],
  },
  {
    items: [
      {
        id: 'blank',
        type: 'blank',
        feedbackOnlyWithError: true,
        conditions: [
          {
            values: [{ listenTo: 'type', value: 'external' }],
            formGroup: {
              id: 'actualRefundFromCompany',
              type: 'number',
              label: 'Actual refund from company',
              feedbackOnlyWithError: true,
              multipleOf: 100,
            },
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        id: 'blank-2',
        type: 'blank',
        feedbackOnlyWithError: true,
        conditions: [
          {
            values: [{ listenTo: 'type', value: 'external' }],
            formGroup: {
              id: 'cancelDate',
              type: 'date',
              feedbackOnlyWithError: true,
              format: DATE_TIME_SERVER_FORMAT,
              label: 'Cancel date',
              initialValue: date,
            },
          },
        ],
      },
    ],
  },
];

export const CancelProvider = (props: CancelPurchaseForm) => {
  return (
    <CancelForm {...props} config={config(dayjs().format(DATE_TIME_SERVER_FORMAT))} />
  );
};
