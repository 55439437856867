import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import React from 'react';
import { Image } from 'react-native';

import { useSupportConversationStore } from '~/components/SupportConversation/InputPanel/Conversation.store';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';

export const ConversationPreviewImages = () => {
  const previewImageBaseList = useSupportConversationStore((s) => s.previewImages);
  const setPreviewImages = useSupportConversationStore((s) => s.setPreviewImages);
  const [_, { isLoading: isUploading }] =
    supportRequestActionsApi.useReplyToSupportUploadImageMutation();
  const { shading } = useAppTheme();

  const previewImages = previewImageBaseList.filter((img) => !!img.base64);

  if (!previewImages.length) {
    return null;
  }

  return (
    <HStack space="3">
      {previewImages.map((previewImage, ix) => {
        return (
          <View
            key={String(ix)}
            style={{
              position: 'relative',
              width: 150,
              height: 150,
              borderRadius: 12,
            }}
          >
            <Image
              source={{ uri: previewImage.base64 }}
              style={{ width: 150, height: 150, borderRadius: 12 }}
            />
            {isUploading && ix === previewImages.length - 1 ? (
              <View
                bg="white"
                align="center"
                justify="center"
                style={{
                  opacity: 0.8,
                  position: 'absolute',
                  width: 150,
                  height: 150,
                  borderRadius: 12,
                  top: 0,
                  right: 0,
                }}
              >
                <LoadingIndicator />
              </View>
            ) : null}
            <IconButton
              style={{
                top: -12,
                right: -12,
                position: 'absolute',
              }}
              outerStyle={{
                backgroundColor: 'white',
                ...shading.light,
              }}
              color="monochrome"
              icon="close"
              size="small"
              onPress={() => {
                setPreviewImages(previewImages.filter((_, iy) => iy !== ix));
              }}
            />
          </View>
        );
      })}
    </HStack>
  );
};
