import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { customerApi } from '~/components/Customer/utils/Customer.api';
import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const PurchasesListHeaderCustomerId = ({
  customerId,
  onReset,
  onPress,
}: {
  customerId: string;
  onReset: () => void;
  onPress?: () => void;
}) => {
  return (
    <PageHeader
      title={`${translate('purchases.purchasesByCustomerId')}`}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress,
      }}
      childRight={<IconButton icon="reset" color="monochrome" onPress={onReset} />}
      Subtitle={<Subtitle customerId={customerId} />}
    />
  );
};

const Subtitle = ({ customerId }: { customerId: string }) => {
  const { data: customerData } = customerId
    ? customerApi.useGetCustomerQuery(customerId)
    : { data: undefined };

  const email = customerData?.customer.p_email;

  return (
    <>
      <Text.Body2Regular
        style={{ marginTop: 5 }}
        numberOfLines={1}
        color="monochrome-mid"
      >
        {customerId}
      </Text.Body2Regular>
      {email && (
        <Text.Body2Regular
          style={{ marginTop: 5 }}
          numberOfLines={1}
          color="monochrome-mid"
        >
          {email}
        </Text.Body2Regular>
      )}
    </>
  );
};
