import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { Pressable, TouchableOpacity } from 'react-native';

export const PressableCard = (props: PressableCardProps) => {
  return (
    <>
      {isNativeMobile ? (
        <TouchableOpacity
          onPress={props.onPress}
          disabled={props.isDisabled || props.isLoading}
        >
          <View
            justify="center"
            br={5}
            b={1}
            bc="monochrome-light"
            style={[props.style]}
            {...props}
          >
            {props.children}
          </View>
        </TouchableOpacity>
      ) : (
        <Pressable onPress={props.onPress} disabled={props.isDisabled || props.isLoading}>
          {({ hovered }: ButtonInteractionState) => {
            return (
              <View
                justify="center"
                bg={hovered ? 'primary-base' : 'white'}
                br={5}
                h={26}
                b={1}
                bc="monochrome-light"
                style={[props.style]}
                {...props}
              >
                <View align="center" justify="center">
                  <HStack style={{ position: 'absolute', alignSelf: 'center' }}>
                    {props.isLoading && <LoadingIndicator />}
                  </HStack>
                  <View style={{ opacity: props.isLoading ? 0 : 1 }}>
                    {props.children}
                  </View>
                </View>
              </View>
            );
          }}
        </Pressable>
      )}
    </>
  );
};

interface PressableCardProps extends AppViewProps {
  onPress: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}
