import { Icon, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';

export const MenuActionItem = (props: {
  disabled?: boolean;
  text: string;
  icon: IconsId;
  isLoading?: boolean;
}) => {
  return (
    <HStack space="3">
      <Icon icon={props.icon} color="monochrome-dark" />
      <Text.Body2Regular>{props.text}</Text.Body2Regular>
      {props.isLoading ? <LoadingIndicator /> : null}
    </HStack>
  );
};
