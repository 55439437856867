import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { AxiosBaseQuery } from '@droplet-tech-code/core-elements/module/utils/network';
import { PaginatedListRequest, PaginatedListResponse } from '@naus-code/naus-admin-types';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import React, { useCallback, useEffect, useState } from 'react';

import { translate } from '../../utils/translation.utils';
import { LinkText } from '../LinkText';
import { FlatList, ListBaseProps } from './FlatList';
import { PaginatedItemId } from './FlatList.utils';

export type PaginatedHook<T, U> = UseQuery<
  QueryDefinition<
    PaginatedListRequest & U,
    AxiosBaseQuery,
    // BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
    any,
    PaginatedListResponse<T>,
    'rootApi'
  >
>;

export interface PaginatedFlatListProps<T, U> extends ListBaseProps<T> {
  hook: PaginatedHook<T, U>;
  moreReqOptions?: U;
  pageSize: number;
  listId: PaginatedItemId;
  // withMaxWidth?: boolean;
  getId: (item: T) => string;
  onSuccess?: () => void;
  invalidate?: (arg: PaginatedListRequest & U) => void;
}

class PaginationUtil {
  lists: { [key in PaginatedItemId]?: string } = {};
  lastReq: { [key in PaginatedItemId]?: any } = {};
}

export const paginationUtil = new PaginationUtil();

export function PaginatedFlatList<T extends { id: string }, U>({
  moreReqOptions,
  pageSize,
  listId,
  // withMaxWidth,
  getId,
  hook,
  onSuccess,
  invalidate,
  ...props
}: PaginatedFlatListProps<T, U>) {
  const [lastItemId, setLastItemId] = useState<string | undefined>(undefined);
  paginationUtil.lastReq[listId] = {
    paginationRequest: {
      lastItemId,
      itemCount: pageSize,
    },
    ...(moreReqOptions as any),
  };

  const hookRes = hook(paginationUtil.lastReq[listId]);
  const { data, isLoading, isFetching, isSuccess } = hookRes;

  useEffect(() => {
    /**
     * If any of the moreReqOptions change than invalidate also if it has mounted before
     */

    if (paginationUtil.lists[listId]) {
      invalidate?.(paginationUtil.lastReq[listId]);
    }
  }, [JSON.stringify(moreReqOptions)]);

  useEffect(() => {
    if (data?.list.length) {
      const id = getId(data.list[data.list.length - 1]);
      paginationUtil.lists[listId] = id;
    } else {
      paginationUtil.lists[listId] = '';
    }
  }, [data]);

  useEffect(() => {
    if (!isFetching && isSuccess) {
      onSuccess?.();
    }
  }, [isSuccess, isFetching]);

  const isFinal = !!data?.isFinal;

  const ListFooterComponent = useCallback(
    () => (
      <View align="center" style={[!isFinal && { height: 30 }]}>
        <View flex={1} justify="center" align="center">
          {isLoading || isFetching ? (
            <LoadingIndicator />
          ) : isFinal ? null : (
            <LinkText
              onPress={() => {
                if (data) {
                  const id = getId(data.list[data.list.length - 1]);
                  // paginationUtil.lists[listId] = id;
                  setLastItemId(id);
                }
              }}
            >
              {translate('feedback.loadMore')}
            </LinkText>
          )}
        </View>
      </View>
    ),
    [isFinal, data, isLoading, isFetching],
  );

  return (
    <FlatList
      {...props}
      isLoading={props.isLoading || isLoading}
      contentContainerStyle={[props.contentContainerStyle]}
      data={data?.list || []}
      refreshing={isFetching}
      onEndReached={async () => {
        if (data?.list.length && !isFinal) {
          const id = getId(data.list[data.list.length - 1]);
          // paginationUtil.lists[listId] = id;
          setLastItemId(id);
        }
      }}
      ListFooterComponent={ListFooterComponent}
    />
  );
}
