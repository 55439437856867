import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { useFileViewer } from '@droplet-tech-code/core-elements/module/ui/UploadFiles/UploadFile.View';
import { Pressable } from 'react-native';

import { getBaseUrl } from '~/utils/constants.utils';

export const PurchasePDF = ({
  pdfId,
  disabled,
}: {
  pdfId: string;
  disabled?: boolean;
}) => {
  const { openModal } = useFileViewer();

  return (
    <Pressable
      disabled={disabled}
      onPress={async () => {
        openModal({
          baseUrl: getBaseUrl(),
          pdfLink: `${getBaseUrl()}purchase/get-pdf/${pdfId}`,
          contentType: 'application/pdf',
        } as any);
      }}
    >
      <HStack bg="secondary-extraLight" br={5} px="0.5" py="1">
        <Icon size="small" color="monochrome-extraDark" icon="pdf" />
        <Text.Small color="monochrome-extraDark">PDF</Text.Small>
      </HStack>
    </Pressable>
  );
};
