import {
  BookingPurchaseRetrieveClient,
  PurchaseClientWithMetaWithTickets,
  PurchaseListItemResponse,
} from '@naus-code/naus-admin-types';

export type PurchaseMix =
  | PurchaseListItemResponse
  | PurchaseClientWithMetaWithTickets
  | BookingPurchaseRetrieveClient;

export const ccyToSymbolDictionary = {
  ['EUR']: '€ ',
  ['USD']: '$ ',
  ['GBP']: '£ ',
};

export const getPriceWithSymbol = (
  price: number | undefined,
  ccySymbolText: string,
  options?: { withoutSymbolIfZero?: boolean; noZero?: boolean; thousandsComma?: boolean },
) => {
  if (price === undefined || (options?.noZero && price === 0)) {
    return options?.withoutSymbolIfZero ? '-' : `${ccySymbolText}-`;
  }

  let priceString = (price / 100).toFixed(2);

  if (options?.thousandsComma) {
    priceString = addThousandsComma(priceString);
  }

  return ccySymbolText + priceString;
};

const addThousandsComma = (numberString: string) => {
  const [whole, decimal] = numberString.split('.');

  const wholeReversed = whole.split('').reverse();
  const wholeWithCommas = wholeReversed.reduce((cache, current, index) => {
    if (index % 3 === 0 && index != 0) {
      return current + ',' + cache;
    }
    return current + cache;
  }, '');

  return wholeWithCommas + '.' + decimal;
};

export const getPurchasePdfId = (purchase: PurchaseMix): string | undefined => {
  return (purchase as any)?.purchasePdfId;
};

export const isNonPurchaseListItem = (
  purchase: PurchaseMix,
): purchase is PurchaseClientWithMetaWithTickets | BookingPurchaseRetrieveClient => {
  return !!(purchase as any)?.tickets;
};

export const getPurchaseWithHistory = (purchase: PurchaseMix) => {
  return (purchase as PurchaseClientWithMetaWithTickets)?.history as
    | PurchaseClientWithMetaWithTickets['history']
    | undefined;
};

export const getIdFromPurchase = (purchase: PurchaseMix) => {
  if ('id' in purchase) {
    return purchase.id;
  } else if ('_id' in purchase) {
    return purchase._id;
  }
  return '';
};
