import { translate as coreTranslate } from '@droplet-tech-code/core-elements/module/lang/lang';
import { NestedPaths } from '@droplet-tech-code/core-elements/module/types/nestedPaths';
import { LangText } from '@naus-code/naus-server-utils-common-types';

export const en = {
  screens: {
    home: 'Home',
    events: 'Events',
    vessels: 'Vessels',
    portLocations: 'Port Locations',
    portLocationItem: 'Port Location',
    areaLocations: 'Area Locations',
    areaLocationItem: 'Area Location',
    users: 'Users',
    userItem: 'User',
    activeNotices: 'Active notices',
    pendingNotices: 'Pending notices',
    noticeItem: 'Notice-Item',
    customerRequests: 'Customer requests',
    closedRequests: 'Closed requests',
    pausedRequests: 'Pending requests',
    supportRequestById: 'Search For Support Request',
    pendingRequests: 'New requests',
    purchaseSupportRequests: 'Purchase support requests',
    purchasesByEmail: 'Find purchases by email',
    purchasesByCustomerId: 'Find purchases by customer id',
    purchasesByCRC: 'Find purchases by company #',
    purchasesByPRC: 'Find purchases by provider #',
    purchasesByCTN: 'Find purchases by company ticket #',
    purchasesUpcoming: 'Upcoming purchases',
    purchasesByDate: 'Purchases by date',
    bookingsByEmail: 'Find bookings by email',
    bookingsByRef: 'Find bookings by reference',

    // bookingsById: 'Bookings by id',

    bookingsByCustomerId: 'Find bookings by customer Id',
    customer: 'Customer',
    findCustomerByEmail: 'Find customer by email',
    systemConfig: 'System Config',
    supportConfig: 'Support Config',
    supportConfigItem: 'Support Config Item',
    vesselItem: 'Vessel Item',
    bookingItem: 'Booking Item',
    officeConfig: 'Office Config',
    operationConfig: 'Operation Config',
    templateConfig: 'Template Config',
    companyConfig: 'Company Config',
    companyConfigItem: 'Company Config Item',
    companyOperatorsConfig: 'Company Operators Config',
    vehicleConfigs: 'Vehicle Config',
    discountsConfig: 'Discounts Config',
    passengerConfigs: 'Passengers Config',
    extraConfigs: 'Extra Configs',
    companyOperationsConfig: 'Company Operations Config',
    supportRequestItem: 'Support Request Item',
    missingVesselConfigs: 'Missing Vessel Configs',
    missingPassengerConfigs: 'Missing Passenger Configs',
    missingVehicleConfigs: 'Missing Vehicle Configs',
    missingLocationConfigs: 'Missing Location Configs',
    missingDiscountConfigs: 'Missing Discount Configs',
    missingExtraConfigs: 'Missing Extra Configs',
    bookingsRecent: 'Recent Bookings',
    ReservationFromProvider: 'Reservation From Provider',
    createVoucher: 'Create voucher',
    vouchersByAdminRef: 'Vouchers by admin reference',
    vouchersByTinyId: 'Voucher by tiny id',
    vouchersByEmail: 'Vouchers by email',
    voucherItem: 'Voucher item',
    companyData: 'Company data',
  },
  groups: {
    data: 'Data',
    adminUsers: 'Admin Users',
    notices: 'Notices',
    requests: 'Requests',
    purchases: 'Purchases',
    bookings: 'Bookings',
    configs: 'Configs',
    missingConfigs: 'Missing Configs',
    purchaseTools: 'Purchase Tools',
    operators: 'Operators',
    vouchersOperations: 'Voucher operations',
    voucherLists: 'Voucher lists',
    scheduledChanges: 'Scheduled Changes',
  },
  basketManager: {
    ticketSelection: 'Ticket Selection',
    loyalty: 'Loyalty',
    discount: 'Discount',
    vehicle: 'Vehicle',
    guest: 'Guest',
    noPassenger: 'No passenger found',
    sex: 'Sex',
    ageGroup: 'Age group',
    noVehicle: 'No vehicle found',
    lengthOfVehicle: 'Length of vehicle',
    discounts: 'Discounts',
    seating: 'Seating',
    limited: 'Limited',
    capacity: 'Capacity',
    ticketsLeft: 'Tickets left',
    totalPricing: 'Total pricing',
    fareDifference: 'Fare Difference',
    legPricing: 'Leg pricing',
  },

  transactions: {
    transactions: 'Transactions',
    emptyTransactionList: 'No transactions found',
    bookingFilter: 'Booking Filter',
    search: 'Search',
    operator: 'Operator',
    clear: 'Clear',
    creditCode: 'Credit Code',
    customerAnalysis: 'Customer Analysis',
    rawData: 'Raw Data',
    operatorAnalysis: 'Operator Analysis',
    companyAnalysis: 'Company Analysis',
  },

  events: {
    events: 'Events',
    noEvents: 'No events',
    noEvent: 'No event',
  },

  feedback: {
    genericError: 'Error occurred',
    loadMore: 'Load more',
    emptyData: 'No data',
  },
  auth: {
    errorLog: 'System could not log you in',
    forgotPassword: 'Forgot password',
    resetLink: 'A reset link has been sent to your email inbox.',
    noResetLink: 'Could not reset link',
    email: 'Email',
    password: 'Password',
    signIn: 'Sign in',
    enterEmail: 'Please enter your email to reset your password',
    invalidEmail:
      'Invalid email or username, please make sure your email is spelt correctly.',
    disable: 'You have been disabled from the system. Please contact support.',
    invalidPassword: 'Invalid password.',
    errorLogSupport: 'Error logging in. Please contact support.',
    signOut: 'Sign out',
  },
  users: {
    updateUserInfo: 'Update user info',
    updateUserRules: 'Update user rules',
    emptyAdminUser: 'No users received',
    adminUsers: 'Admin users',
    notFound: 'Admin user not found.',
    userInfo: 'User Info',
    id: 'ID',
    email: 'Email',
    name: 'Name',
    lastName: 'Last name',
    role: 'Role',
    officeId: 'Office ID',
    userSupport: 'User support',
    acceptsSupport: 'Accepts support',
    pausedSupportRequests: 'Pending support requests',
    closedSupportRequests: 'Closed support requests',
    pendingSupportRequests: 'New support requests',
    openSupportRequests: 'Open support requests',
    pendingTasks: 'Pending tasks',
    yes: 'Yes',
    no: 'No',
    userHR: 'User HR',
    subgroup: 'Subgroup',
    manager: 'Manager',
    manages: 'Manages',
    //====
    userRules: 'User rules',
    accessSysConfig: 'System config ',
    accessCompanyConfig: 'Company config',
    accessSupportConfig: 'Support config',
    accessLiveTrack: 'Live track',
    accessRegions: 'Regions',
    accessAreas: 'Areas',
    accessLocations: 'Locations',
    accessVessels: 'Vessels',
    accessEvents: 'Events',
    accessTasks: 'Tasks',
    accessUsers: 'Users',
    accessSupport: 'Support',
    accessTech: 'Tech',
    accessScheduledChanges: 'Access Scheduled Changes',
    activateScheduledChanges: 'Activate Scheduled Changes',
    assignSupportRequests: 'Assign Support Requests',
    canCreatePendingRefund: 'Can Create Pending Refund',
    canApprovePendingRefund: 'Can Approve Pending Refund',
    granted: 'Granted',
    notGranted: 'Denied',
    createUserInfo: 'Create User',
  },
  vessels: {
    vessels: 'Vessels',
    originalImage: 'Original Image',
    newVessel: 'Add new vessel',
    enterNewVessel: 'Enter vessel details',
    emptyVessel: 'No vessels found',

    bannerImage: 'Banner image',
    frame: 'Frame',
    thumb: 'Thumb',
    small: 'Small',

    generateSizes: 'Generate sizes',
  },

  // companyData: {
  //   companyData: "Company data",
  // },

  portLocations: {
    newPortLocation: 'Add new port location',
    enterNewPortLocation: 'Enter port location details',
    portLocations: 'Port Locations',
    emptyPortLocations: 'No ports found',
  },
  areaLocations: {
    areaLocations: 'Area locations',
    emptyAreaLocation: 'No areas found',
    newAreaLocation: 'Area locations',
    enterNewAreaLocation: 'No area found',
  },
  notices: {
    activeNotices: 'Active notices',
    emptyActiveNotices: 'No active notices found',
    pendingNotices: 'Pending notices',
    emptyPendingNotices: 'No pending notices found',
    editNotice: 'Edit notice',
    createNotice: 'Create notice',
    noticeDetails: 'Notice details',
    adminUserId: 'Admin user id',
    userName: 'User name',
    active: 'Active',
    severity: 'Severity',
    dateCreated: 'Date created',
  },
  supportRequests: {
    supportRef: 'Support Request Ref',
    enterRef: 'Enter support request ref',
    verifyServiceFee: 'Please verify service fee',
    serviceFeeMakeSure:
      'Please make sure that the service fee you have entered is correct',
    closedSupportRequests: 'Closed Support Requests',
    selectPaymentSession: 'Select Payment Session',
    purchaseSupportRequests: 'Purchase Support Requests',
    pausedSupportRequests: 'Pending Support Requests',
    pendingSupportRequests: 'New Support Requests',
    userUnassigned: 'Support Request Unassigned',
    emptySupportRequest: 'No support requests found',
    selectUsers: 'Select users',
    statusNotFound: 'Support status type not found',
    customer: 'Customer',
    actionBy: 'Action by',
    editReply: 'Edit Reply',
    supportEmail: 'Support email',
    requests: 'requests',
    createPayment: 'Create payment',
    createRefund: 'Create refund',
    assignPurchase: 'Assign purchase',
    details: 'Details',
    sendReminder: 'Send Reminder',
    reminderSent: 'Reminder Sent!',
    paymentHistory: 'Payment history',
    history: 'History',
    pendingPayment: 'Pending payment',
    pendingRefund: 'Pending Refund',
    approve: 'Approve',
    approveRefund: 'Approve refund',
    cancelPendingRefund: 'Cancel pending refund',
    cancelRefund: 'Cancel refund',
    service: 'Service: {{value}}',
    notes: 'Notes',
    lastReminded: 'Last reminded',
    cancelPendingPayment: 'Cancel pending payment',
    assignUser: 'Assign user',
    assignSelf: 'Assign self',
    unassigned: 'Unassigned',
    assigned: 'Assigned',
    nameUpper: 'Name',
    emailUpper: 'Email',
    updated: 'Updated',
    dateRegarding: 'Date regarding',
    noTripsFound: 'No trips based on your search params',
    enterPaymentDetails: 'Enter payment details',
    enterRefundDetails: 'Enter refund details',
    addNote: 'Add new note',
    enterNoteText: 'Write note text',
    note: 'Note',
    open: 'Open',
    paused: 'Pending',
    closed: 'Closed',
    pending: 'New',
    deleted: 'Deleted',
    emptyTemplateAnswers: 'No template answers found',
    all: 'All',
    general: 'General',
    cancellations: 'Cancellations',
    modifications: 'Modifications',
    voucher: 'Voucher',
    refunds: 'Refunds',
    faq: 'FAQ',
    howto: 'How-to',
    templateAnswers: 'Template Answers inputted to {{locale}}',
    templates: 'Templates',

    viewIn: 'View in:',
    preview: 'Preview',

    notification: 'Notification',
    email: 'Email',
    sms: 'SMS',
    messageQuote: 'Quote message',
    send: 'Send',
    advanced: 'Advanced',
    newSupportRequest: 'New Support Request',
    enterNewSupportRequest: 'Enter support request details',
    selectUser: 'Select admin user to assign',
    userAssigned: 'User Assigned!',
    data: 'Data',
    paymentSession: 'Payment session',
    previewText: 'Preview text',
    historyItem: 'History Item',
    void: 'Cancel-Complete',
    voidPseudo: 'Operator cancelled sailing',
    voidProvider: 'Cancel-Provider',
    voidCustomer: 'Cancel-Customer',

    customerSupportRequests: 'Customer support requests',
    recent: 'Recent',

    seePurchasesByEmail: '{{email}} [Purchases]',
    Device: 'Last device',
    changeLang: 'Change language',
    chooseLang: 'Choose Language',
    lang: 'Language',
    cleanSearch: 'Clean search (w/o cache)',

    error: 'Error',
    type: 'Type',
    inProgress: 'in progress',
    status: 'Status',
    serviceFee: 'Service fee',
    value: 'Value',
    voucherId: 'Voucher id',
    hiddenFromCustomer: 'hidden from customer',
    assignedPurchaseToSR: 'Successfully assigned purchase and tickets to support request',
    showRawData: 'Show raw data',
    changeType: 'Change type',
    enterNewRequestType: 'Enter new support type',
    successfullyChangedType: 'Support request type changed successfully!',
    filterRequests: 'Filter Support Requests',
    selectSupportType: 'Select support type',
    supportType: 'Support Type',
    showOwnRequestsOnly: 'Only show requests assigned to me',
    areYouSureDelete: 'Are you sure you want to delete this request?',

    feedback: 'Feedback',

    targetTranslation: 'Target translation',
    autoTranslate: 'Auto translate',
    originalText: 'Original text',
    autoTranslateAgentDisabled: 'Agent auto translate disabled',
    autoTranslateCustomerDisabled: 'Customer auto translate disabled',
    autoTranslateAgent: '{{from}} to {{to}} - Agent',
    autoTranslateCustomer: '{{from}} to {{to}} - Customer',

    requestOptions: {
      requestOptions: 'Request options',
      ticketBreakdown: 'See ticket breakdown',
      noPurchase: 'No purchase found',
      canCancel: 'Able to cancel',
      customerRequested: 'Customer requested to amend to:',
      noCustomerRequestOptions: 'No customer request options',
      manuallyChangeTrip: 'Manually amend trip',

      originalValue: "Operator's value",
      bookedValue: 'Booking value',
      originalRefundValue: "Operator's refund",
      bookedRefundValue: "Customer's refund",

      expectedRefund: 'Expected refund',
      serviceFee: 'Service fee',
      refundMethod: 'Refund method',

      ticketNo: 'TicketNo.',
      ticketInfo: 'Ticket Info',
    },
    modifyPanel: {
      findReservation: 'Find Reservation',
      reservationNumber: 'Reservation number',
      valueOfOpCurrency: 'Value of change in operator currency',
      dateModified: 'Date modified (UTC)',
    },
    refund: {
      description: 'Description',
      paymentSessionId: 'Payment Session Id',
      bookingId: 'Booking Id',
      adminName: 'Admin Name',
      inProgress: 'In Progress',
      value: 'Value',
    },
  },
  ticketSearch: {
    pastTrip: 'Trip is in the past',
    tooCloseToDep: "Trip arrival is too close to the next trip's departure",
    soldOut: 'Sold out',
    unavailable: 'Unavailable',
    limitedSeats: 'Limited seats',
    limitedVehicles: 'Limited vehicles',
  },

  attention: {
    changedDetected: 'Changes detected',
    lowAvailability: 'Low availability',
    notAvailable:
      'Some tickets are not available any more, we have substituted them with available options',
    lowAvailableInfo:
      'Some of the tickets you have chosen are low on availability and therefore your booking may be unsuccessful',
    pricesChanged: "Some tickets' prices have changed, see the breakdown below",
    verifyChanges: 'Verify changes',
    // continueWithChanges: 'Continue with changes',
    // continueWithPrice: 'Continue with new price',
    changeSelection: 'Change selection',
    // continueWithSelection: 'Continue with current selections',
    oldPrice: 'Old price',
    newPrice: 'New price',
    overallPriceChange: 'Overall price change',
    left: '{{count}} left',
  },

  scheduledChanges: {
    createScheduledChange: 'Create scheduled change',
    suggestFirstStop: 'Suggest first stop',
    pullStopData: 'Fill other stops',
    invalidData: 'Invalid data',
    verified: 'Verified',
    vesselRes: 'Vessel \nRes code | Company code \nEmail',
    date: 'Date',
    depTime: 'Dep. Time',
    origin: 'Origin',
    arrTime: 'Arr. Time',
    destination: 'Dest.',
    purchaseDetails: 'Purchase details',
    empty: 'No scheduled changes found.',
    successfullyCreated: 'Successfully created schedule change. Find changes in pending.',
    scheduledChange: 'Schedule changes',
    scheduledChangeDetails: 'Scheduled change details',
    title: 'Subject',
    adminUser: 'Admin user',
    startDate: 'Start date',
    endDate: 'End date',
    dateCreated: 'Date created',
    status: 'Status',
    adminUserIdActivated: 'Admin user id activated',
    dateActivated: 'Date activated',
    description: 'Description',
    originPortName: 'Origin port name',
    oldDepartureDateOffset: 'Old dep date offset',
    newDepartureTime: 'New dep time',
    newDepartureDateOffset: 'New dep date offset',
    destinationPortName: 'Dest port name',
    oldArrivalDateOffset: 'Old arr date offset',
    newArrivalTime: 'New arr time',
    newArrivalDateOffset: 'New arr date offset',
    stopIndex: 'Stop index',
    stopChangeDetails: 'Stop change details',
    activateScheduleChanges: 'Activate scheduled changes',
    deleteScheduledChanges: 'Delete scheduled changes',
    successfullyActivated: 'Scheduled change activated',
    completedScheduledChange: 'Completed scheduled changes',
    successfullyDeleted: 'Scheduled change deleted',
  },

  purchases: {
    reservationFromProvider: 'Retrieve reservation from provider',
    enterReservationDetails: 'Enter reservation details',
    provider: 'Provider',
    ccy: 'ccy',
    purchasesUpcoming: 'Upcoming purchases',
    purchasesByEmail: 'Purchases by email',
    purchasesByCustomerId: 'Purchases by customer Id',
    purchasesByDate: 'Purchases by Date',
    companyId: 'Company Id',
    vesselId: 'Vessel Id',
    purchasesForDate: 'Purchases for Date',
    enterDateDetails: 'Please enter date details',
    emptyPurchases: ' No purchases found',
    purchasesForEmail: 'Find purchases by email',
    purchasesForCustomerId: 'Find purchases by customer Id',
    enterEmail: 'Enter email',
    enterCustomerId: 'Enter customer Id',
    customerId: 'Customer id',
    email: 'email',
    purchasesByCompanyResCode: 'Purchases by Company Reservation Code',
    companyReservationCode: 'company reservation code',
    purchasesForCRC: 'Find purchases for company reservation code',
    purchasesForPRC: 'Find purchases for provider reservation code',
    purchasesForCTN: 'Find purchases for company ticket number',
    enterCRC: 'Enter company reservation code',
    enterPRC: 'Enter provider reservation code',
    enterCTN: 'Enter company ticket number',
    providerReservationCode: 'Provider reservation code',
    purchasesByProviderResCode: 'Purchases by Provider Reservation Code',
    purchasesByCompanyTicketNumber: 'Purchases by Company Ticket Number',
    purchases: 'Purchases',
    showCredit: 'Company credit',
    noCredit: 'No company credit',
    supportRequestsForPurchase: 'Support requests for purchase',
    enterPurchaseId: 'Enter purchase id',
    purchaseId: 'purchase id',
    provideId: 'Please provide a purchase id',
    companyTicketNumber: 'Company ticket number',
    enterTicketNumber: 'Enter company ticket number',
    onHold: 'On hold',
    printed: 'Printed',
    split: 'Split',
    hasPdf: 'Has PDF',
    notifyingMethod: 'Notifying method',
    companyDetails: {
      companyDetails: 'Company Contact Details',
      moreInfo: 'More Info',
      noDetails: 'No Company Details',
    },
    purchaseTool: {
      createSupportRequest: 'Create support request',
      sendETicket: 'Send e-ticket',
      cancelVisually: 'Cancel visually',
      autoUpdatePurchase: 'Auto update purchase',
      cancelPartialPurchase: 'Cancel partial purchase',
      cancelWholePurchase: 'Cancel whole purchase',
      manualTripSelector: 'Manual trip selector',
      creditAfter: 'Credit after: ',
      valueOfModification: 'Value of amendment: ',
      viewPurchase: 'View trip',
      holdPurchase: 'Hold trip',
      unHoldPurchase: 'Un-hold trip',
      setPrinted: 'Mark as printed',
      unSetPrinted: 'Un-mark as printed',
      paymentSession: 'Payment session',
      selectLinkedPaymentSessions: 'Select linked payment session(s):',
      youHaveManuallySelected: 'You have manually selected:',

      // convertToOpenVisually: 'Convert to open visually',
      // convertToOpenPartially: 'Convert to open partially',
      // convertAllToOpen: 'Convert all to open',
      splitPurchase: 'Split trip',
      getPurchaseFromProvider: 'View purchase item from provider',
      seeCancelPanel: 'Cancel trip',
      viewReservationFromProvider: 'Retrieve trip from provider',

      modifyPurchase: 'Amend trip',
      openPurchase: 'Open-convert trip',

      modifyTickets: 'Amend tickets',
      silentlyModify: 'Silently amend',
      openTicket: 'Open tickets',
      openVisually: 'Open visually',
      openProvider: 'Open with provider',

      silentlyOpen: 'Silently open',
      forceSelect: 'Force select all tickets in purchase',

      searchTrip: 'Search trip',
      areYouSureYouWantToCancel: 'Are you sure you want to cancel?',

      seeCompanyTerms: 'Operator terms',
      seeAgencies: 'Operator agencies',
      seeCapabilities: 'See capabilities',
      importPurchaseToBooking: 'Import purchase',

      capabilities: 'Capabilities',
      updated: 'Updated {{date}}',
      hasCoordinates: 'Coordinates',
      selectTypeOfCancel: 'Select type of cancellation',
      cancelPurchase: 'Cancel purchase',
      provider: 'Provider',
      alreadyCancelled: 'Already cancelled externally',
      visually: 'Visually',
      customerOnly: 'Customer side',
      providerOnly: 'Provider side',
      customerProviderOnly: 'Customer & Provider',
      visuallyCancel: 'Visually cancel',
      providerCancel: 'Provider cancel (API)',

      selectTicketsToCancel: 'Select tickets to cancel',
      selectTicketsToModify: 'Select tickets to amend',
      selectTicketsToOpen: 'Select tickets to open',

      selectTicketsToSplit: 'Select tickets to split',
      selectPurchaseToAssign: 'Select purchase to assign',
      showPurchaseCalc: 'Show purchase calcs',
      purchaseCalculations: 'Purchase calculations',
      providerCalculations: 'Provider calculations',

      refundInputs: 'Refund inputs',

      expectedRefund: 'Expected refund',
      serviceFee: 'Service fee',
      voucherServiceFee: 'Voucher service fee',

      refundMethod: 'Refund Method',
      bankRefund: 'Bank refund',
      voucherRefund: 'Voucher refund',
      convertToOpen: 'Convert to open',
      refund: 'Refund',

      processCancellation: 'Process cancellation',

      purchasePolicy: {
        endTime: 'End time',
        startTime: 'Start time',
        requestBy: 'Request by',
        hoursBeforeEnd: 'Hours before end',
        policyName: 'Policy name',
        refundPercentage: 'Refund percentage',
        viewPolicy: 'View Policy',
      },

      purchaseCompare: {
        doNothing: 'Do nothing',
        import: 'Only import',
        linkTo: 'Replace {{name}}',
        attach: 'Import & attach - {{name}}',

        currentPurchase: 'Current purchase',
        newProviderPurchase: 'New provider purchase',

        resCode: 'Confirm provider code',
        enterNewResCode: 'Enter new reservation code',
        silentUpdate: 'Silent update',
        refreshPDF: 'Refresh PDF',

        update: 'Update',
        updateSilent: 'Update silent',
        linked: 'Linked',
        replacedWith: 'Replaced by {{name}}',
        attachedAndImported: 'Imported & attached',

        imported: 'Imported',
        outcome: 'Outcome',
        changes: 'Changes',
        noChanges: 'No changes',
      },
    },
    trackingInfo: {
      trackingInfo: 'Tracking info',
      trackId: 'Track ID',
      trackIdTimestampSet: 'Track ID set timestamp',
      trackEndTimestamp: 'Track end timestamp',
      departureGate: 'Departure gate',
      actionNotification: 'Action notification',
      actionNotificationTimestamp: 'Action notification timestamp',
      etoNotification: 'ETO notification',
      etoNotificationTimestamp: 'ETO notification timestamp',
      etaNotification: 'ETA notification',
      etaNotificationTimestamp: 'ETA notification timestamp',
    },
    purchaseHistory: {
      purchaseHistory: 'Purchase history',
      currentPurchase: 'Current purchase',
      historicalPurchase: 'Historical purchase',
    },
  },

  schedulesChanges: {
    create: 'Create a new scheduled change',
    createV2: 'Pull Purchases',
    pending: 'Scheduled changes',
    completed: 'Completed changes',
  },

  operators: {
    allCreditLines: 'All credit lines',
    creditLine: 'Credit lines',
  },

  bookings: {
    bookingNotFound: 'Booking not Found',
    bookingsRecent: 'Recent Bookings',
    bookingsByEmail: 'Bookings by Email',
    bookingsByCustomerId: 'Bookings by Customer Id',
    emptyBookings: 'No bookings found',
    email: 'email',
    enterReference: 'enter booking reference',
    reference: 'reference',
    bookingsForEmail: 'Find bookings for email',
    enterEmail: 'Enter email',
    sessions: 'sessions',
    bookingInfo: 'BOOKING INFO',
    phoneUpper: 'Phone',
    nameUpper: 'Name',
    emailUpper: 'Email',
    languageUpper: 'Language',
    bookingRef: 'Booking ref',
    resendBooking: 'Resend booking',
    sendInvoice: 'Send invoice',

    invoice: {
      companyName: 'Company Name',
      companyNo: 'Company Number',
      companyAddress: 'Company Address',
      country: 'Country',
      taxNumber: 'Tax No.',
      taxOffice: 'Tax Office',
      invoiceDate: 'Invoice Date',
    },
    resendBookingEmail: 'Resend booking email',
    paymentSessions: 'Payment sessions',
    createdUpper: 'Created',
    customer: 'Customer',
    providerCode: 'Provider Code',
    companyAccessCode: 'Access Code',
    companyCode: 'Company Code',
    purchaseTicketType: 'Ticket type',
    viewCustomer: 'View customer',
    paymentSession: {
      valueToBeRefunded: 'Value to be refunded',
      refundPayment: 'Refund Payment',
      paymentMethodDetails: 'Payment method details',
      currency: 'Currency',
      total: 'Total',
      status: 'Status',
      serviceFee: 'Service fee',
      type: 'Type',
      providerId: 'Provider id',
      methodId: 'Method id',
      intentId: 'Intent id',
      expiryMonth: 'Expiry month',
      expiryYear: 'Expiry year',
      brand: 'Brand',
      last4: 'Last 4 digits',
      expiry: 'Expiry',
      country: 'Country',
      brandProduct: 'Brand Product',
      saveCard: 'Save card',
      bookingRef: 'Booking ref',
      errorType: 'Error Type',
      message: 'Error message',
      isFullVoucher: 'Full voucher',
      isDisabled: 'Disabled',
      providerInfo: 'Provider info',
      id: 'Id',
      key: 'Key',
      region: 'Region',
    },
    bookingsForCustomerId: 'Find bookings for customer id',
    enterCustomerId: 'Enter customer id',
    customerId: 'customer id',
    rawData: 'Raw Data',
  },
  customer: {
    customerInfo: 'Customer Info',
    phoneUpper: 'Phone',
    nameUpper: 'Name',
    languageUpper: 'Language',
    emailUpper: 'Email',
    vouchers: 'Vouchers',
    createVoucher: 'Create voucher',
    infoNotFound: 'Customer info not found.',
    newVoucher: 'Create new voucher',
    customerSupportRequests: 'Customer support requests',
    customerSupportRequestsByEmail: 'Customer requests by support email',
    customerPurchases: 'Customer purchases',
    customerPurchasesEmail: 'Customer purchases by email',
    customerBookings: 'Customer bookings',
    customerBookingsEmail: 'Customer bookings by email',
    createSupportRequest: 'Create support request',
    email: 'Email',
    emailNotFound: 'Customer email not found, retrieved with support email.',
    customerId: 'Customer id',
    enterEmail: 'Enter customer email or id',
    supportRequestsForCustomer: 'Support requests for customer',
    provideOne: 'Provide at least one piece of information',
    supportRef: 'Support request ref',
    deviceId: 'Device',
    appVersion: 'App version',
    vehicleName: 'Vehicle name',
    savedVehicles: 'Saved vehicles',
    savedPassengers: 'Saved passengers',
    category: 'Category',
    plateNumber: 'plateNumber',
    id: 'id',
    passengerName: 'Name',
    birthDate: 'Birth date',
    isDefault: 'Default',
    loyaltyNumber: 'Loyalty number',
    loyaltyName: 'Loyalty name',
    height: 'Height',
    length: 'Length',
    model: 'Model',
    brand: 'Brand',
    updateCustomer: 'Update Customer Info',
    ratings: 'Ratings',
    supportId: 'Support id',
    recentPayments: 'Recent payments',
    payment: {
      date: 'date',
      value: 'value',
      ccy: 'ccy',
      status: 'status',
      statusProvider: 'statusProvider',
      last4: 'last4',
      brand: 'brand',
      wallet: 'wallet',
      country: 'country',
    },
  },

  createSupport: {
    createSupportRequest: 'Create support request',
  },

  dateUtils: {
    ago: 'ago',
  },
  supportConversations: {
    agent: 'Agent',
    customer: 'Customer',
    responseType: 'Response type',
    conversation: 'Conversation',
    thisWillDelete: "This will delete the message on the chat customer's chat",
    deleteSuccess: 'Delete successful',
    name: 'Name',
    handlerId: 'Handler id',
    timestamp: 'Timestamp',
    noConversation: 'No conversation yet',
    alreadyRated: 'User has already rated',
  },
  systemConfig: {
    systemConfig: 'System Config',
    tripConfig: 'Trip Config',
    search: 'Search',
    book: 'Book',
    track: 'Track',
    providers: 'Providers',
    residentProviders: 'Resident providers',
    loyaltyProviders: 'Loyalty providers',
    vesselType: 'Vessel types',
    serviceCost: 'Service cost',
    updateServiceCost: 'Update service cost',
    enterNewServiceCost: 'Enter new service cost',
    notifier: 'Track Notifier',
    updateNotifier: 'Update track notifier',
    enterNewNotifier: 'Enter new track notifier',
    bookingRules: 'Booking Rules',
    updateBookingRules: 'Update Booking Rules',
    enterNewBookingRules: 'Enter new booking rules',
    terms: 'Terms',
    companyTerms: 'Company terms',
    privacyTerms: 'Privacy terms',
    pickupServiceTerms: 'Pickup service terms',
    travelGuiderTerms: 'Travel guide terms',
    travelPackTerms: 'Travel pack terms',
    other: 'Other',
    systemOperationConfig: 'System operation config',
    paymentConfig: 'Payment config',
    travelPackConfig: 'Travel Pack Config',
    updateCompanyTerms: 'Update system company terms',
    updatePrivacyTerms: 'Update system privacy terms',
    name: 'Name',
    displayName: 'Display name',
    addTermCategory: 'Add term category',
    taskCategories: 'Task categories',
    languagesAccepted: 'Languages accepted',
    adminUserSubGroups: 'Admin user subgroups',
    maximumVoucherDuration: 'Maximum voucher duration',
    minimumVoucherDuration: 'Minimum voucher duration',
    isVoucherPaymentEnabled: 'Voucher payment',
    addAdminUserSubGroup: 'Add admin user subgroup',
    appVersionConfig: 'App version config',
    updateAppVersion: 'Update App Version',
  },
  supportConfig: {
    supportConfig: 'Support config',
    offices: 'Offices',
    default: 'Default',
    config: 'Config',
    operation: 'Operation',
    templateAnswers: 'Template answers',
    officeInfo: 'Office Info',
    id: 'ID',
    name: 'Name',
    country: 'Country',
    timezone: 'Timezone',
    servingCountries: 'Serving countries',
    setAsDefault: 'Set as default',
    officeTimes: 'Office times',
    officeSpecialDates: 'Special Dates',
    yes: 'Yes',
    no: 'No',
    options: 'Options',
    supportTypes: 'Support Types',
    bufferMinutesForRequest: 'Buffer time for request to be completed (Minutes)',
    color: 'Color',
    english: 'English',
    langs: 'Langs',
    hidden: 'Hidden',
    template: 'Template Answers',
    newTemplateAnswer: 'New Template Answer',
    newCategory: 'New Category',
    createTemplateCategory: 'Create template category',
    updateTemplateAnswer: 'Update template answer',
    links: 'Links',
    list: 'List',
    text: 'Text',
    day: 'Day',
    startHour: 'Start hour',
    startMinute: 'Start minute',
    endHour: 'End hour',
    endMinute: 'End minute',
    annual: 'Annual',
    startDate: 'Start Date',
    endDate: 'End Date',
    periodName: 'Period name',
    enterNewOffice: 'Enter new office',
    newOffice: 'New Office',
    updateOffice: 'Update office',
    updateOfficeDetails: 'Update office details',
    updateOperation: 'Update Operation',
    updateOperationDetails: 'Update operation details',
    enterNewTemplateAnswer: 'Enter new template answer',
    addSupportType: 'Add support type',
    updateSupportType: 'Update support type',
    minutes: 'minutes',
    editBufferMinutes: 'Edit Support request buffer minutes',
  },
  companyConfig: {
    emptyCompanyConfigs: 'No company configs found',
    companyConfigs: 'Companies',
    companies: 'companies',
    companyInfo: 'Company info',
    id: 'ID',
    provider: 'Provider',
    providerCode: 'Provider code',
    countries: 'Countries',
    currency: 'Currency',
    commission: 'Commission',
    description: 'Description',
    activeConfig: 'Active Config',
    searchVisible: 'Search visible',
    searchEnabled: 'Search enabled',
    searchTempDisabled: 'Search temporarily disabled',
    bookingEnabled: 'Booking enabled',
    bookingTempDisabled: 'Booking temporarily disabled',
    trackEnabled: 'Track enabled',
    agencyContactDetails: 'Agency contact details',
    true: 'TRUE',
    false: 'FALSE',
    company: 'Company',
    operators: 'Operators',
    operationsCancellationsMod: 'Operations, Cancellations, Amendments etc.',
    ticketConfigs: 'Ticket configs',
    discounts: 'Discounts',
    passengerConfigs: 'Passenger Configs',
    vehicleConfigs: 'Vehicle Configs',
    extraConfigs: 'Extra Configs',
    extraInputConfigs: 'Extra Input Configs',
    defaultExtraConfigs: 'Default Extra Configs',
    loyalty: 'Loyalty',
    resident: 'Resident',
    vessels: 'Vessels',
    name: 'Name',
    descriptionPreview: 'Description preview',
    contactDetails: 'Contact details',
    eticketConfig: 'e-ticket config',
    termsConfig: 'Terms config',
    companyCode: 'Company code',
    percentage: 'Percentage',
    appliesToTickets: 'Applies to tickets',
    DataId: 'Data Id',
    requiresExtraInput: 'Requires extra input',
    requiresLoyalty: 'Requires loyalty',
    requiresData: 'Require data',
    showInStatic: 'Show in static',
    category: 'Category',
    extraValidation: 'Extra validation',
    customExtras: 'Custom extras',
    capacity: 'Capacity',
    wholeUse: 'Whole use',
    genderSpecific: 'Gender specific',
    features: 'Features',
    emptyPassengerConfigs: 'No passenger configs found',
    emptyDiscountsConfig: 'No discount configs found',
    emptyVehicleConfigs: 'No vehicle configs found',
    minLength: 'Min length',
    maxLength: 'Max length',
    maxHeight: 'Max height',
    emptyExtraConfigs: 'No extra configs found',
    bookingRules: 'Booking Rules',
    minutesBeforeDeparture: 'Minutes before departure booking allowed',
    cancellations: 'Cancellations',
    canCancel: 'Can cancel',
    canCancelAPI: 'Can cancel via API',
    canCancelAPIPartial: 'Can cancel partial via API',
    canCancelAPIAllowCustomer: 'Allow customer to cancel via API',
    canCancelAPIAllowCustomerPartial: 'Allow customer to cancel partial via API',
    canAskFees: 'Can ask for cancellation fees',
    canCancelAfterOpen: 'Can cancel after open',
    dateUsedForOpen: 'Date used for cancellation of open',
    canCancelAfterModify: 'Can cancel after amending',
    dateUsedForModify: 'Date used for cancellation amendment',
    cancelGracePeriod: 'Cancel grace period after booking in minutes',
    overrideCancelationRefundAfterOpen: 'Override cancellation refund % after open',
    requiresPrintToCancel: 'Requires print to cancel',
    hoursBeforeEnd: 'Hours before end',
    daysBeforeEnd: 'Days before end',
    defaultCancellationPolicies: 'Default Cancellation Policies',
    periods: 'Periods',
    regions: 'Regions',
    locationIds: 'Location Ids',
    departingFrom: 'Departing from',
    arrivingTo: 'Arriving to',
    canModify: 'Can amend',
    canModifyAPI: 'Can amend via API',
    canModifyAPIPartial: 'Can amend partial via API',
    canModifyAPIAllowCustomer: 'Allow customer to amend via API',
    canModifyAPIAllowCustomerPartial: 'Allow customer to amend partial via API',
    numberModifications: 'Number of amendments allowed',
    requiresOpenToModify: 'Requires open to amend',
    requiresPrintToModify: 'Requires print to amend',
    modifications: 'Amendments',
    defaultModificationPolicies: 'Default amendment policies ',
    open: 'Open',
    canOpen: 'Can open',
    canOpenAPI: 'Can open via API',
    canOpenAPIPartial: 'Can open partial via API',
    canOpenAPIAllowCustomer: 'Allow customer to open via API',
    canOpenAPIAllowCustomerPartial: 'Allow customer to open partial via API',
    canOpenAfterModify: 'Can open after amendment',
    requiresPrintToOpen: 'Requires print to open',
    openValidityDays: 'Open validity days',
    openValidityFrom: 'Open validity from',
    openValidityTillEnd: 'Open validity till end of year',
    canUseDiffRoute: 'Can use for different route',
    canUseDiffPassenger: 'Can use for different passenger',
    defaultOpenPolicies: 'Default Open Policies',
    newCompanyConfig: 'New company config',
    enterNewCompanyConfig: 'Enter company config details',
    newCompanyOperatorConfig: 'New operator config',
    enterNewCompanyOperatorConfig: 'Enter operator details',
    updateFerryCompany: 'Update ferry company info',
    updateActiveConfig: 'Update active config',
    updateFerryCompanyOperator: 'Update ferry company operator',
    updateFerryOperatorContact: 'Update ferry operator contact details',
    updateFerryOperatorETicket: 'Update ferry operator e-Ticket config',
    updateFerryOperatorTerms: 'Update ferry operator terms config',
    updateFerryOperatorCustomerOperation:
      'Update ferry operator custom operations config',
    updateFerryCompanyOperations: 'Update ferry company operations config',
    addFerryCompanyDiscount: 'Add ticket discount config',
    addPassengerTicket: 'Add passenger ticket config',
    updatePassengerTicket: 'Update passenger ticket config',
    updateVehicleTicket: 'Update vehicle ticket config',
    addVehicleTicket: 'Add vehicle ticket config',
    addExtraTicket: 'Add extra ticket config',
    updateExtraTicket: 'Update extra ticket config',
    updatePassengerOperationConfig: 'Update passenger operation config',
  },
  companyDataConfigs: {
    missingVesselConfigs: 'Missing vessel configs',
    vesselConfigs: 'vessel configs',
    missingPassengerConfigs: 'Missing passenger configs',
    passengerConfigs: 'passenger configs',
    missingVehicleConfigs: 'Missing vehicle configs',
    vehicleConfigs: 'vehicle configs',
    missingLocationConfigs: 'Missing location configs',
    locationConfigs: 'location configs',
    missingDiscountConfigs: 'Missing discount configs',
    discountConfigs: 'discount configs',
    missingExtraConfigs: 'Missing extra configs',
    extraConfigs: 'extra configs',
    submit: 'Submit',
    edited: 'Edited',
  },
  routeSearch: {
    origin: 'Origin',
    destination: 'Destination',
    date: 'Date',
    search: 'Search',
  },
  common: {
    delete: 'Delete',
    close: 'Close',
    success: 'Success',
    edit: 'Edit',
    noDataFound: 'No data found',
    next: 'Next',
    back: 'Back',
  },
  voucher: {
    value: 'Value',
    type: 'type',
    voucherInfo: 'Voucher info',
    expiryDate: 'Expiry date',
    customerVouchers: 'Customer vouchers',
    tinyId: 'Tiny id',
    remainingValue: 'Remaining value',
    redeemedValue: 'Redeemed value',
    email: 'Email',
    customerId: ' Customer id',
    adminId: 'Issued by',
    reference: 'Reference',
    adminReference: 'Admin reference',
    createVoucher: 'Create Voucher',
    updateVoucher: 'Update Voucher',
    voucherVoid: 'Voiding voucher',
    voidDescription:
      'This action will disable the voucher. \nThis voucher will not be usable',
    createdVouchers: 'Created voucher',
    voucherByRef: 'Vouchers by reference',
    enterAdminReference: 'Enter admin reference',
    voucherAdminRef: 'Voucher admin reference',
    voucherTinyId: 'Voucher tiny id',
    enterTinyId: 'Enter tiny id',
    user: 'User',
    dateCreated: 'Date created',
    canBeRefunded: 'Refundable',
    minimumBookingValue: 'Minimum booking value',
    customerExpireEmailTimestamp: 'Customer email expiry',
    enterEmail: 'Enter email',
    voucherEmail: 'Voucher email',
    voucherByEmail: 'Voucher by email',
  },
  bookingTransactions: {
    emptyBookingTransactionList: 'No transactions found',
    bookingTransactions: 'Transactions for booking',
    transactions: 'Transactions',
    areYouSureDelete: 'Are you sure you want to delete?',
  },
  financials: {
    creditLines: {
      total: 'Total',
      credit: 'Credit',
    },
  },

  lang: {
    english: 'English (en)',
    greek: 'Greek (el)',
    italian: 'Italian (it)',
    french: 'French (fr)',
    german: 'German (de)',
    spanish: 'Spanish (es)',
  },
};

export type TKeys = NestedPaths<typeof en>;

export const translate = (key: TKeys, options?: { [key: string]: string }) => {
  return coreTranslate(key as any, options);
};

export const getLangText = (currentLanguage: string, text: LangText) => {
  return text[currentLanguage] || text['en'] || undefined;
};

export const getFullLang = (langPre: string) => {
  const lang = langPre.toLowerCase();
  switch (lang) {
    case 'en':
      return translate('lang.english');
    case 'el':
      return translate('lang.greek');

    case 'es':
      return translate('lang.spanish');

    case 'de':
      return translate('lang.german');

    case 'it':
      return translate('lang.italian');

    case 'fr':
      return translate('lang.french');

    default:
      return lang;
  }
};

export const getAvailableLangs = () => {
  return ['en', 'el', 'es', 'de', 'it', 'fr'];
};
