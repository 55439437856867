import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  SupportRequestFillDynamicTemplateAnswersResponse,
  SupportRequestGetTemplateAnswersResponse,
} from '@naus-code/naus-admin-types';
import { LangText } from '@naus-code/naus-server-utils-common-types';
import React, { MutableRefObject, useCallback, useRef, useState } from 'react';
import { RefreshControl } from 'react-native-gesture-handler';

import { FlatList, ListRenderItem } from '~/components/FlatList/FlatList';
import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { translate } from '~/utils/translation.utils';

import { CategorySelector } from './TemplateAnswers.CategorySelection';
import {
  getTemplateListWithIds,
  ListItemRenderItem,
  useTemplateAnswerStore,
} from './TemplateAnswers.utils';

export const TemplateAnswersBody = ({
  supportLang,
  textRef,
  formRef,
  addDynamicTemplateAnswer,
}: {
  supportLang: string;
  formRef: MutableRefObject<FormHandler>;
  textRef: MutableRefObject<FormHandler>;
  addDynamicTemplateAnswer: (templateAnswerId: string) => Promise<
    | {
        data: SupportRequestFillDynamicTemplateAnswersResponse;
      }
    | {
        error: unknown;
      }
  >;
}) => {
  const viewLocaleFromHover = useTemplateAnswerStore((s) => s.viewLocaleFromHover);
  const viewLocaleFromDropdown = useTemplateAnswerStore((s) => s.viewLocaleFromDropdown);
  const viewAsLocale = viewLocaleFromHover || viewLocaleFromDropdown || supportLang;

  const {
    list: templateList,
    Search,
    isLoading,
    isFetching,
    refetch,
  } = useSearchState({
    hook: supportRequestActionsApi.useGetTemplateAnswersQuery,
    style: {
      height: 47,
    },
    keyToList: 'templateAnswers',
    searchTextExtractor: (item) => {
      return item.answers
        .flatMap((item: { text: LangText }) => item.text[viewAsLocale])
        .join('');
    },
  });

  const { spacing } = useAppTheme();
  const [category, setCategory] = useState('All');
  const categorySetRef = useRef(new Set<string>());
  const ItemSeparatorComponent = useItemSeparatorComponent();

  const templateListWithIds = getTemplateListWithIds({
    templateList,
    category,
    categorySetRef,
  });
  const categorySetArray = Array.from(categorySetRef.current);

  const renderItem: ListRenderItem<
    SupportRequestGetTemplateAnswersResponse['templateAnswers'][0] & { id: string }
  > = useCallback(
    ({ id, category, answers }) => {
      return (
        <ListItemRenderItem
          id={id}
          category={category}
          answers={answers}
          supportLang={viewAsLocale}
          categorySetRef={categorySetRef}
          textRef={textRef}
          formRef={formRef}
          addDynamicTemplateAnswer={addDynamicTemplateAnswer}
        />
      );
    },
    [viewAsLocale],
  );

  return (
    <VStack>
      {Search}
      <Divider bg="monochrome-light" />
      <CategorySelector
        category={category}
        categorySetArray={categorySetArray}
        setCategory={setCategory}
      />
      <Divider bg="monochrome-light" />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },
          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        feedback={translate('supportRequests.emptyTemplateAnswers')}
        isLoading={isLoading}
        data={templateListWithIds}
        simple
        renderItem={renderItem}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
