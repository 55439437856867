import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { SystemConfigAddLanguage } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useAddTaskCategoryModal = ({ check }: { check?: string }) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [addTermCategory, { isLoading: isAddingTerm }] =
    systemConfigApi.useAddTaskCategoryMutation();

  const onConfirm = async ({
    value,
    check,
  }: {
    value: { taskCategory: string };
    check?: string;
  }) => {
    return await addTermCategory({
      taskCategory: value.taskCategory,
      check: check || '',
    });
  };

  return {
    open: () => {
      const config: ConfigForData = [
        {
          items: [
            {
              type: 'text',
              label: translate('systemConfig.name'),
              id: 'taskCategory',
            },
          ],
        },
      ];
      openForm({
        modalTitle: translate('systemConfig.addTermCategory'),
        useFormHookHandler: () => {
          return { data: { config, check }, isLoading: false };
        },
        onConfirm,
        loadingAction: isAddingTerm,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddAdminUserGroupModal = ({ check }: { check?: string }) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [addAdminUserSubGroup, { isLoading: isAddingUserGroup }] =
    systemConfigApi.useAddAdminUserSubGroupMutation();

  const onConfirm = async ({
    value,
    check,
  }: {
    value: { adminUserGroup: string };
    check: string;
  }) => {
    return await addAdminUserSubGroup({
      subGroup: value.adminUserGroup,
      check: check || '',
    });
  };

  return {
    open: () => {
      const config: ConfigForData = [
        {
          items: [
            {
              type: 'text',
              label: translate('systemConfig.name'),
              id: 'adminUserGroup',
            },
          ],
        },
      ];
      openForm({
        modalTitle: translate('systemConfig.addAdminUserSubGroup'),
        useFormHookHandler: () => {
          return { data: { config, check }, isLoading: false };
        },
        onConfirm,
        loadingAction: isAddingUserGroup,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddSystemLanguageModal = () => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [addSystemLanguage, { isLoading: isAddingLanguage }] =
    systemConfigApi.useAddSystemLanguageMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } = systemConfigApi.useAddSystemLanguageFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: SystemConfigAddLanguage;
    check?: string;
  }) => {
    return await addSystemLanguage({
      ...value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('systemConfig.addAdminUserSubGroup'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isAddingLanguage,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
