import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SupportConfigCreateTemplateAnswerRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { supportConfigApi } from '~/screens/Configs/Support/supportConfig.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const AddTemplateAnswerModalKey = 'AddModal-TemplateAnswer';
const AddTemplateAnswerModal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [createTemplateAnswer, { isLoading: isCreatingTemplateAnswer }] =
    supportConfigApi.useCreateAnswerTemplateMutation();
  const { data: addTemplateAnswerConfig } =
    supportConfigApi.useGetCreateAnswerTemplateFormConfigQuery();

  if (addTemplateAnswerConfig === undefined) {
    return <></>;
  }
  if (addTemplateAnswerConfig?.check === undefined) {
    return <></>;
  }

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportConfig.newTemplateAnswer')}
        subtitle={translate('supportConfig.enterNewTemplateAnswer')}
        divider
        onPress={() => {
          closeModal(AddTemplateAnswerModalKey);
        }}
      />
      <Modal.Body p="0">
        <CreateTemplateAnswerForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingTemplateAnswer}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              SupportConfigCreateTemplateAnswerRequest['templateAnswer']
            >();

          if (valid) {
            const response = await createTemplateAnswer({
              check: addTemplateAnswerConfig.check,
              templateAnswer: value,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddTemplateAnswerModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddTemplateAnswerModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useAddTemplateAnswer = () => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<AddTemplateAnswerModal />, {
        key: AddTemplateAnswerModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
const CreateTemplateAnswerForm = ({ form }: { form: FormHandler }) => {
  const { data: addTemplateAnswerConfig, isLoading: isLoadingTemplateAnswerConfig } =
    supportConfigApi.useGetCreateAnswerTemplateFormConfigQuery();

  if (addTemplateAnswerConfig === undefined) {
    return <></>;
  }

  const { config } = addTemplateAnswerConfig;

  return (
    <FormContent
      isLoading={isLoadingTemplateAnswerConfig}
      form={addTemplateAnswerConfig}
      flex={1}
    >
      {addTemplateAnswerConfig ? <FormBuilder data={config} form={form} /> : null}
    </FormContent>
  );
};
