import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import {
  ConfigDetectorCompanyData,
  FerryCompanyPassengerTicketConfig,
} from '@naus-code/naus-server-common-types';
import { useRef } from 'react';

import { companyDataConfigApi } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/companyDataConfigsApi';
import { useMissingPassengerStore } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Passengers/MissingPassengerConfigs.store';

import MISSING_CONFIG from './MissingPassengerConfigs.Config.json';

export type MissingPassengerItem =
  ConfigDetectorCompanyData<FerryCompanyPassengerTicketConfig> & {
    category: 'seat' | 'cabin';
  };

const ModalKey = 'useMissingPassengerItemModal';

const MissingPassengerItem = ({ item }: { item: MissingPassengerItem }) => {
  const { closeModal } = useModal();
  const { data, isLoading } = companyDataConfigApi.useGetMissingPassengersQuery();
  const editTicket = useMissingPassengerStore((s) => s.editTicket);
  const formRef = useRef(new FormHandler());
  return (
    <Modal.Container>
      <Modal.Header title={item.key} onPress={() => closeModal(ModalKey)} />
      <Modal.Body flex={1}>
        <DelayRender isLoading={isLoading}>
          {data ? (
            <FormBuilder
              form={formRef.current}
              data={(MISSING_CONFIG as ConfigForData) ?? data.config}
              initialValue={item}
            />
          ) : null}
        </DelayRender>
      </Modal.Body>
      <Modal.Footer
        confirm="Save & close"
        divider
        confirmProps={{
          disabled: isLoading || !data,
        }}
        onConfirm={() => {
          const { valid, value } = formRef.current.getValue<MissingPassengerItem>();
          if (valid && data) {
            editTicket(value);
            closeModal(ModalKey);
          }
        }}
        onCancel={() => closeModal(ModalKey)}
      />
    </Modal.Container>
  );
};

export const useMissingPassengerItemModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (item: MissingPassengerItem) => {
      openModal(<MissingPassengerItem item={item} />, { key: ModalKey });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

// const __MOCK__CONFIG: ConfigForData = [
//   {
//     itemProps: {
//       hideFeedback: true,
//       style: {
//         minHeight: 0,
//         paddingVertical: 4,
//       },
//     },
//     items: [
//       {
//         id: 'id',
//         type: 'text',
//         readonly: true,
//         label: 'ID',
//       },
//       {
//         id: 'key',
//         type: 'text',
//         readonly: true,
//         label: 'Ticket ref key',
//       },
//       {
//         id: 'companyId',
//         type: 'text',
//         readonly: true,
//         label: 'Company ID',
//       },
//     ],
//   },
//   {
//     itemProps: {
//       hideFeedback: true,
//       style: {
//         minHeight: 0,
//         paddingVertical: 4,
//       },
//     },
//     items: [
//       {
//         id: 'category',
//         type: 'single-select',
//         label: 'Category',
//         initialValue: 'seat',
//         list: [
//           {
//             value: 'seat',
//             label: 'Seat',
//           },
//           {
//             value: 'cabin',
//             label: 'Cabin',
//           },
//         ],
//       },
//       {
//         id: 'blank',
//         type: 'blank',
//       },
//       {
//         id: 'blank',
//         type: 'blank',
//       },
//     ],
//   },
//   {
//     type: 'separator',
//   },
//   {
//     itemProps: {
//       hideLabel: true,
//       hideFeedback: true,
//     },
//     items: [
//       {
//         id: 'data',
//         type: 'form-item',
//         valueType: 'item',
//         label: 'Cabin information',
//         preview: [],
//         config: [],
//         conditions: [
//           {
//             values: [
//               {
//                 value: 'seat',
//                 listenTo: 'category',
//               },
//             ],
//             formGroup: {
//               id: 'data',
//               type: 'form-item',
//               valueType: 'item',
//               label: 'Seat Config',
//               preview: [],
//               config: [
//                 {
//                   itemProps: {
//                     hideFeedback: true,
//                     style: {
//                       minHeight: 0,
//                       paddingVertical: 8,
//                     },
//                   },
//                   items: [
//                     {
//                       id: 'code',
//                       type: 'text',
//                       readonly: true,
//                       label: 'Company code',
//                     },
//                     {
//                       id: 'name',
//                       type: 'text',
//                       readonly: true,
//                       label: 'Name (by company)',
//                     },
//                     {
//                       id: 'disabled',
//                       type: 'toggle',
//                       label: 'Disabled?',
//                     },
//                   ],
//                 },
//                 {
//                   itemProps: {
//                     hideFeedback: true,
//                     style: {
//                       minHeight: 0,
//                       paddingVertical: 4,
//                     },
//                   },
//                   items: [
//                     {
//                       id: 'type',
//                       readonly: true,
//                       type: 'text',
//                       label: 'Type',
//                       initialValue: 'seat',
//                       style: {
//                         flex: 1,
//                       },
//                     },
//                     {
//                       id: 'category',
//                       optional: true,
//                       type: 'single-select',
//                       label: 'Seat Category',
//                       list: [
//                         {
//                           value: 'deck',
//                         },
//                         {
//                           value: 'lounge',
//                         },
//                         {
//                           value: 'numbered',
//                         },
//                       ],
//                       style: {
//                         flex: 1,
//                       },
//                     },
//                     {
//                       id: 'extraPriceValidation',
//                       optional: true,
//                       type: 'form-item',
//                       valueType: 'item',
//                       label: 'Extra price validation',
//                       preview: [],
//                       config: [
//                         {
//                           itemProps: {
//                             hideFeedback: true,
//                             hideLabel: true,
//                             optional: true,
//                             style: {
//                               minHeight: 0,
//                               paddingVertical: 4,
//                             },
//                           },
//                           items: [
//                             {
//                               id: 'gender',
//                               type: 'toggle',
//                               label: 'gender',
//                               optional: true,
//                             },
//                             {
//                               id: 'passengerType',
//                               type: 'toggle',
//                               label: 'passengerType',
//                               optional: true,
//                             },
//                             {
//                               id: 'surname',
//                               type: 'toggle',
//                               label: 'surname',
//                               optional: true,
//                             },
//                             {
//                               id: 'name',
//                               type: 'toggle',
//                               label: 'name',
//                               optional: true,
//                             },
//                             {
//                               id: 'nationality',
//                               type: 'toggle',
//                               label: 'nationality',
//                               optional: true,
//                             },
//                           ],
//                         },
//                         {
//                           itemProps: {
//                             hideFeedback: true,
//                             hideLabel: true,
//                             optional: true,
//                             style: {
//                               minHeight: 0,
//                               paddingVertical: 4,
//                             },
//                           },
//                           items: [
//                             {
//                               id: 'birthDate',
//                               type: 'toggle',
//                               label: 'birthDate',
//                               optional: true,
//                             },
//                             {
//                               id: 'birthPlace',
//                               type: 'toggle',
//                               label: 'birthPlace',
//                               optional: true,
//                             },
//                             {
//                               id: 'idOrPassportDoc',
//                               type: 'toggle',
//                               label: 'idOrPassportDoc',
//                               optional: true,
//                             },
//                             {
//                               id: 'expirationDoc',
//                               type: 'toggle',
//                               label: 'expirationDoc',
//                               optional: true,
//                             },
//                             {
//                               id: 'document',
//                               type: 'toggle',
//                               label: 'document',
//                               optional: true,
//                             },
//                           ],
//                         },
//                         {
//                           itemProps: {
//                             hideFeedback: true,
//                             optional: true,
//                           },
//                           items: [
//                             {
//                               id: 'loyalty',
//                               type: 'toggle',
//                               label: 'loyalty',
//                               optional: true,
//                             },
//                             {
//                               id: 'residentLoyalty',
//                               type: 'toggle',
//                               label: 'residentLoyalty',
//                               optional: true,
//                             },
//                             {
//                               id: 'limit',
//                               type: 'number',
//                               optional: true,
//                               label: 'limit?',
//                             },
//                             {
//                               id: 'blank',
//                               type: 'blank',
//                             },
//                             {
//                               id: 'blank',
//                               type: 'blank',
//                             },
//                           ],
//                         },
//                       ],
//                       style: {
//                         flex: 2,
//                       },
//                     },
//                   ],
//                 },
//               ],
//             },
//           },
//           {
//             values: [
//               {
//                 value: 'cabin',
//                 listenTo: 'category',
//               },
//             ],
//             formGroup: {
//               id: 'data',
//               type: 'form-item',
//               valueType: 'item',
//               label: 'Cabin Config',
//               preview: [],
//               config: [
//                 {
//                   itemProps: {
//                     hideFeedback: true,
//                     style: {
//                       minHeight: 0,
//                       paddingVertical: 8,
//                     },
//                   },
//                   items: [
//                     {
//                       id: 'code',
//                       type: 'text',
//                       readonly: true,
//                       label: 'Company code',
//                     },
//                     {
//                       id: 'name',
//                       type: 'text',
//                       readonly: true,
//                       label: 'Name (by company)',
//                     },
//                     {
//                       id: 'disabled',
//                       type: 'toggle',
//                       label: 'Disabled?',
//                     },
//                   ],
//                 },
//                 {
//                   hide: true,
//                   items: [
//                     {
//                       id: 'type',
//                       readonly: true,
//                       type: 'text',
//                       label: 'Type',
//                       initialValue: 'cabin',
//                     },
//                   ],
//                 },
//                 {
//                   itemProps: {
//                     hideFeedback: true,
//                     hideLabel: true,
//                     optional: true,
//                     style: {
//                       minHeight: 0,
//                       paddingVertical: 4,
//                     },
//                   },
//                   items: [
//                     {
//                       id: 'extraPriceValidation',
//                       optional: true,
//                       type: 'form-item',
//                       valueType: 'item',
//                       label: 'Extra price validation',
//                       preview: [],
//                       noFeedback: true,
//                       config: [
//                         {
//                           itemProps: {
//                             hideFeedback: true,
//                             hideLabel: true,
//                             optional: true,
//                             style: {
//                               minHeight: 0,
//                               paddingVertical: 4,
//                             },
//                           },
//                           items: [
//                             {
//                               id: 'gender',
//                               type: 'toggle',
//                               label: 'gender',
//                               optional: true,
//                             },
//                             {
//                               id: 'passengerType',
//                               type: 'toggle',
//                               label: 'passengerType',
//                               optional: true,
//                             },
//                             {
//                               id: 'surname',
//                               type: 'toggle',
//                               label: 'surname',
//                               optional: true,
//                             },
//                             {
//                               id: 'name',
//                               type: 'toggle',
//                               label: 'name',
//                               optional: true,
//                             },
//                             {
//                               id: 'nationality',
//                               type: 'toggle',
//                               label: 'nationality',
//                               optional: true,
//                             },
//                           ],
//                         },
//                         {
//                           itemProps: {
//                             hideFeedback: true,
//                             hideLabel: true,
//                             optional: true,
//                             style: {
//                               minHeight: 0,
//                               paddingVertical: 4,
//                             },
//                           },
//                           items: [
//                             {
//                               id: 'birthDate',
//                               type: 'toggle',
//                               label: 'birthDate',
//                               optional: true,
//                             },
//                             {
//                               id: 'birthPlace',
//                               type: 'toggle',
//                               label: 'birthPlace',
//                               optional: true,
//                             },
//                             {
//                               id: 'idOrPassportDoc',
//                               type: 'toggle',
//                               label: 'idOrPassportDoc',
//                               optional: true,
//                             },
//                             {
//                               id: 'expirationDoc',
//                               type: 'toggle',
//                               label: 'expirationDoc',
//                               optional: true,
//                             },
//                             {
//                               id: 'document',
//                               type: 'toggle',
//                               label: 'document',
//                               optional: true,
//                             },
//                           ],
//                         },
//                         {
//                           itemProps: {
//                             hideFeedback: true,
//                             optional: true,
//                           },
//                           items: [
//                             {
//                               id: 'loyalty',
//                               type: 'toggle',
//                               label: 'loyalty',
//                             },
//                             {
//                               id: 'residentLoyalty',
//                               type: 'toggle',
//                               label: 'residentLoyalty',
//                             },
//                             {
//                               id: 'limit',
//                               type: 'number',
//                               label: 'limit?',
//                             },
//                             {
//                               id: 'blank',
//                               type: 'blank',
//                             },
//                             {
//                               id: 'blank',
//                               type: 'blank',
//                             },
//                           ],
//                         },
//                       ],
//                     },
//                   ],
//                 },
//                 {
//                   style: {
//                     paddingTop: 8,
//                   },
//                   items: [
//                     {
//                       id: 'capacity',
//                       type: 'number',
//                       label: 'Capacity',
//                     },
//                     {
//                       id: 'wholeUse',
//                       type: 'toggle',
//                       label: 'Whole Use?',
//                     },
//                     {
//                       id: 'genderSpecific',
//                       type: 'single-select',
//                       optional: true,
//                       label: 'Gender specific',
//                       list: [
//                         {
//                           value: 'M',
//                           label: 'Male (M)',
//                         },
//                         {
//                           value: 'F',
//                           label: 'Female (F)',
//                         },
//                       ],
//                     },
//                     {
//                       id: 'blank',
//                       type: 'blank',
//                     },
//                   ],
//                 },
//                 {
//                   itemProps: {
//                     hideFeedback: true,
//                     hideLabel: true,
//                     optional: true,
//                     style: {
//                       minHeight: 0,
//                       paddingVertical: 4,
//                     },
//                   },
//                   items: [
//                     {
//                       id: 'luxury',
//                       type: 'toggle',
//                       label: 'luxury',
//                     },
//                     {
//                       id: 'window',
//                       type: 'toggle',
//                       label: 'window',
//                     },

//                     {
//                       id: 'pet',
//                       type: 'toggle',
//                       label: 'pet',
//                     },
//                     {
//                       id: 'blank',
//                       type: 'blank',
//                     },
//                   ],
//                 },
//                 {
//                   itemProps: {
//                     hideFeedback: true,
//                     hideLabel: true,
//                     optional: true,
//                     style: {
//                       minHeight: 0,
//                       paddingVertical: 4,
//                     },
//                   },
//                   items: [
//                     {
//                       id: 'facilities',
//                       type: 'toggle',
//                       label: 'facilities',
//                     },
//                     {
//                       id: 'pure',
//                       type: 'toggle',
//                       label: 'pure',
//                     },
//                     {
//                       id: 'wc',
//                       type: 'toggle',
//                       label: 'wc',
//                     },
//                     {
//                       id: 'basin',
//                       type: 'toggle',
//                       label: 'basin',
//                     },
//                   ],
//                 },
//                 {
//                   itemProps: {
//                     hideFeedback: true,
//                     hideLabel: true,
//                     optional: true,
//                     style: {
//                       minHeight: 0,
//                       paddingVertical: 4,
//                     },
//                   },
//                   items: [
//                     {
//                       id: 'bunk',
//                       type: 'toggle',
//                       label: 'bunk',
//                     },
//                     {
//                       id: 'balcony',
//                       type: 'toggle',
//                       label: 'balcony',
//                     },
//                     {
//                       id: 'lateCheckout',
//                       type: 'toggle',
//                       label: 'lateCheckout',
//                     },
//                     {
//                       id: 'specialNeeds',
//                       type: 'toggle',
//                       label: 'specialNeeds',
//                     },
//                   ],
//                 },
//               ],
//             },
//           },
//         ],
//       },
//     ],
//   },
// ];
