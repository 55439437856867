import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { Image } from 'react-native';

import { Card } from '~/components/Card/Card';
import { AppText } from '~/components/Text';

export const CompanyOperatorCardWithHeader = (props: CardHeaderProps) => {
  const children = props.children;
  return (
    <Card {...props}>
      <HStack p="2.5" justify="center">
        {props.imageUrl ? (
          <View style={{ alignSelf: 'stretch' }}>
            <CompanyImage imageUrl={props.imageUrl} />
          </View>
        ) : (
          <></>
        )}
        <View flex={1} p="2.5" justify="center" align="center">
          <AppText.MiscTag type="heading1" style={{ fontSize: 10, lineHeight: 20 }}>
            {`${props.headerText}`}
          </AppText.MiscTag>
        </View>
        {props.iconRightType ? (
          <IconButton
            color="monochrome"
            icon={props.iconRightType}
            onPress={props.iconRightOnPress}
            iconStyle={{ justifyContent: 'flex-start' }}
          />
        ) : (
          <></>
        )}
      </HStack>
      <Divider bg="monochrome-extraLight" />
      {children}
    </Card>
  );
};

interface CardHeaderProps extends AppViewProps {
  headerText: string;
  iconRightOnPress?: () => void;
  iconRightType?: IconsId;
  imageUrl?: string;
}

const CompanyImage = ({ imageUrl }: { imageUrl?: string }) => {
  return (
    <View br={15} style={{ overflow: 'hidden' }}>
      <Image
        borderRadius={15}
        source={{
          uri: imageUrl,
        }}
        style={{ width: 60, height: 60 }}
      />
    </View>
  );
};
