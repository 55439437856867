import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { FormBuilder } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { ScrollView } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PressableCard } from '~/components/Card/PressableCard';
import { useSupportConversationStore } from '~/components/SupportConversation/InputPanel/Conversation.store';
import { SupportConversationInputPanelProps } from '~/components/SupportConversation/InputPanel/Conversation.utils';
import { AppText } from '~/components/Text';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { translate } from '~/utils/translation.utils';

import { ActionToggles } from './Conversation.ActionToggles';

export const SupportConversationAdvanced = (
  props: SupportConversationInputPanelProps,
) => {
  const { data, isLoading } = supportRequestActionsApi.useSendReplyFormConfigQuery();

  const advanced = useSupportConversationStore((s) => s.advanced);
  const reset = useSupportConversationStore((s) => s.reset);

  return (
    <ScrollView>
      <VStack style={[advanced ? { opacity: 1 } : { opacity: 0, height: 0 }]} space="2.5">
        {isNativeMobile ? (
          <VStack>
            <ActionToggles {...props} />
          </VStack>
        ) : null}
        <DelayRender isLoading={isLoading}>
          {data ? (
            <FormBuilder
              key={reset + ''}
              data={data}
              form={props.advancedRef.current}
              contentContainerStyle={{ flex: undefined }}
              initialValue={{ responseType: 'Text' }}
            />
          ) : null}
        </DelayRender>
      </VStack>
    </ScrollView>
  );
};

export const AdvancedButton = () => {
  const advanced = useSupportConversationStore((s) => s.advanced);
  const setState = useSupportConversationStore((s) => s.setState);

  return (
    <PressableCard
      onPress={() => setState({ advanced: !advanced })}
      bc={advanced ? 'primary-light' : 'monochrome-light'}
      style={{ overflow: 'hidden' }}
    >
      <HStack justify="center" bg={advanced ? 'primary-extraLight' : 'white'} p="2">
        <AppText.MiscTag color={advanced ? 'primary-dark' : 'black'}>
          {translate('supportRequests.advanced')}
        </AppText.MiscTag>
        <Icon
          color={advanced ? 'primary' : 'monochrome'}
          icon={advanced ? 'chevron-up' : 'filter'}
        />
      </HStack>
    </PressableCard>
  );
};
