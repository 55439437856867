import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { DocumentPicker } from '@droplet-tech-code/core-elements/module/ui/Inputs/DocumentPicker/DocumentPicker';
import { useListenToImageClipboard } from '@droplet-tech-code/core-elements/module/ui/Inputs/TextInput/TextInput.utils';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import mime from 'mime';
import { useCallback } from 'react';

import {
  PreviewImageItem,
  useSupportConversationStore,
} from '~/components/SupportConversation/InputPanel/Conversation.store';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';

export const ConversationImageUploader = () => {
  const [uploadImage, { isLoading: isUploading }] =
    supportRequestActionsApi.useReplyToSupportUploadImageMutation();
  const previewImages = useSupportConversationStore((s) => s.previewImages);
  const setPreviewImages = useSupportConversationStore((s) => s.setPreviewImages);
  const setStateConv = useSupportConversationStore((s) => s.setState);
  const getBase64 = useCallback(
    async (file: Blob, nameId: string): Promise<PreviewImageItem[] | undefined> => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const newImages = [
            ...previewImages,
            { id: nameId, token: '', base64: reader.result as string },
          ];
          setPreviewImages(newImages);
          resolve(newImages);
        };
        reader.onerror = () => {
          resolve(undefined);
        };
      });
    },
    [previewImages],
  );

  const uploadFile = useCallback(
    async (formData: FormData) => {
      if (isUploading) {
        return;
      }

      if (previewImages.length >= 4) {
        showToast('Max limit of images is 4');
        return;
      }

      const nameId = dayjs().toISOString();

      formData.forEach((fileItem) => {
        getBase64(fileItem as any, nameId).then(async (newImages) => {
          if (!newImages) {
            return;
          }
          setStateConv({ isUploadingImage: true });

          const response = await uploadImage(formData);

          handleResponse({
            response,
            disableSuccessToast: true,
            onSuccess: ({ data }) => {
              setPreviewImages(
                newImages.map((image) => {
                  if (image.id === nameId) {
                    return {
                      ...image,
                      token: data.token,
                    };
                  }

                  return image;
                }),
              );
              setStateConv({ isUploadingImage: false });
            },
            onError: () => {
              setStateConv({ isUploadingImage: false });
            },
          });
        });
      });
    },
    [previewImages],
  );

  useListenToImageClipboard({
    onImagePaste: async (clipboardImage) => {
      const formData = new FormData();

      const foobar = await fetch(clipboardImage.data);
      const blob = await foobar.blob();
      const extension = mime.getExtension(blob.type)?.toLocaleLowerCase();

      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        const newImage = dayjs().toISOString();
        const name = 'image-' + newImage + `.${extension}`;
        formData.append('file', blob, name);
        uploadFile(formData);
      }
    },
  });

  return (
    <DocumentPicker showPreview={false} uploadFile={uploadFile}>
      {isUploading ? <LoadingIndicator /> : <Icon icon="image" color="primary" />}
    </DocumentPicker>
  );
};
