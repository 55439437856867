import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { VoucherListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef, useState } from 'react';

import { FlatList } from '~/components/FlatList/FlatList';
import { useChangeParam } from '~/components/Modals/ChangeParamModal';
import { PageHeader } from '~/components/Page/Page.Header';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { VoucherListItemComponent } from '../Voucher.ListItem';
import { voucherApi } from '../voucherApi';

interface VoucherListProps {
  voucherAdminRef: string;
  onPress: (voucherId: string) => void;
}

export const VoucherListNative = ({ voucherAdminRef }: { voucherAdminRef: string }) => {
  const navigate = useAppNavigation();
  return (
    <VoucherList
      voucherAdminRef={voucherAdminRef}
      onPress={(itemId) => {
        navigate.navigate('Voucher-Item', {
          id: itemId,
        });
      }}
    />
  );
};

export const VoucherList = (props: VoucherListProps) => {
  const selectedRef = useRef('');
  const [voucherAdminRef, setVoucherAdminRef] = useState(props.voucherAdminRef);

  const { data: voucherData, isLoading } =
    voucherApi.useGetVoucherListByAdminReferenceQuery(props.voucherAdminRef);
  const { spacing } = useAppTheme();

  const renderItem = useCallback(
    (voucher: VoucherListItemResponse) => {
      return (
        <VoucherListItemComponent
          key={voucher.id}
          voucher={voucher}
          selected={selectedRef.current === voucher.id}
          onPress={() => props.onPress(voucher.id)}
        />
      );
    },
    [voucherAdminRef],
  );

  const { open: openEditVoucherAdminRef } = useChangeParam();

  const onEditParmeter = (param: string) => {
    setVoucherAdminRef(param);
  };

  if (!voucherData) {
    return null;
  }

  return (
    <VStack space="2.5" flex={1}>
      <PageHeader
        withBack={isNativeMobile}
        title={translate('voucher.voucherByRef')}
        iconRight={{
          icon: 'reset',
          onPress: () => {
            openEditVoucherAdminRef({
              title: translate('voucher.voucherAdminRef'),
              subtitle: translate('voucher.enterAdminReference'),
              label: translate('voucher.voucherAdminRef'),
              onConfirm: onEditParmeter,
            });
          },
        }}
        Subtitle={<Text.Small>{voucherAdminRef}</Text.Small>}
      />
      <FlatList
        feedback={translate('supportRequests.emptySupportRequest')}
        data={voucherData?.vouchers}
        isLoading={isLoading}
        simple
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        showsVerticalScrollIndicator={isWeb}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v.id);
        }}
      />
    </VStack>
  );
};
