import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  PurchaseClientAutoUpdateRequest,
  PurchaseClientAutoUpdateResponse,
  // PurchaseClientModifyProviderResponse,
  PurchaseUpdate,
} from '@naus-code/naus-admin-types';
import { RoutePrice } from '@naus-code/naus-client-types';
import { useState } from 'react';

import { useJsonViewerModal } from '~/components/JsonView';
import { PaymentSessionSelector } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.PaymentSession';
import { PurchaseTicketsToSelect } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.TicketSelector';
import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import {
  ModifyPanelProps,
  resetBasketManager,
  useModifyData,
  useModifyInitial,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Modify/Modify.utils';
import { translate } from '~/utils/translation.utils';

import { AutoUpdateResponseMessage } from '../../../PurchaseFromProvider/PurchaseFromProvider.Compare';
import { purchaseActionApi } from '../../PurchaseActions.api';
import { ApiTripManager } from './Api/Modify.ApiTripManager';
import { useModifyAutoUpdateStore } from './Auto-update/Modify.AutoUpdate.State';
import { AutoUpdateTripManagerWrapper } from './Auto-update/Modify.AutoUpdateTripManager';
import { ManualTripManager } from './Manual/Modify.ManualTripManager';

const ModalKey = 'useModifyPanel';

export type ModificationType = 'Api' | 'Manual' | 'Auto-update';

const ModifyPanel = (props: ModifyPanelProps) => {
  const { closeModal } = useModal();
  const [option, setOption] = useState<ModificationType>('Auto-update');

  return (
    <Modal.Container style={{ maxWidth: '100' }}>
      <Modal.Header
        divider
        title={translate('purchases.purchaseTool.modifyPurchase')}
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <Modal.Body flex={1}>
        <ModifyPanelBody {...{ ...props, option, setOption }} />
      </Modal.Body>
      <ModifyFooter {...{ ...props, option }} />
    </Modal.Container>
  );
};

const ModifyFooter = (props: ModifyPanelProps & { option: ModificationType }) => {
  const { option } = props;
  const clearAll = useClearAll();

  const [silentOn, setSilentOn] = useState(false);
  const [refreshPDF, setRefreshPDF] = useState(false);
  const { openModal, closeModal } = useModal();

  const [modifyPurchaseApi, { isLoading: isModifyingApi }] =
    purchaseActionApi.useModifyPurchaseFromProviderMutation();
  const [modifyPurchaseAuto, { isLoading: isModifyingAuto }] =
    purchaseActionApi.useUpdatePurchaseProviderMutation();

  const { disabled, selectedTickets, purchaseRequest, providerOptions } =
    useModifyData(props);
  const [paymentSessionIds] =
    purchaseActionManager.state.useGlobalState('paymentSessionIds');
  // const footerButton = useAppSelector((s) => s.basket.selectTickets.footerButton);

  const isModifying = isModifyingApi || isModifyingAuto;
  return (
    <Modal.Footer
      confirmProps={{
        disabled:
          disabled ||
          isModifying ||
          // (footerButton.status !== 'valid' && option === 'Api') ||
          ((!purchaseRequest || !providerOptions) && option === 'Auto-update'),
        isLoading: isModifying,
      }}
      divider
      confirm={translate(
        silentOn
          ? 'purchases.purchaseTool.silentlyModify'
          : 'purchases.purchaseTool.modifyTickets',
      )}
      onConfirm={async () => {
        // const { routePrice } = basketManager.getSessionRequestData();
        if (
          // (!routePrice && option === 'Api') ||
          !purchaseRequest &&
          !providerOptions &&
          option === 'Auto-update'
        ) {
          return;
        }

        const modifyPurchase = async () => {
          switch (option) {
            case 'Auto-update':
              return await modifyPurchaseAuto({
                supportId: props.supportId,
                purchaseId: props.purchaseId,
                silentUpdate: silentOn,
                refreshPdf: refreshPDF,
                purchase: purchaseRequest as PurchaseUpdate,
                providerOptions,
              } as PurchaseClientAutoUpdateRequest);
            default:
              return await modifyPurchaseApi({
                supportId: props.supportId,
                purchaseId: props.purchaseId,
                ticketIds: selectedTickets,
                silentUpdate: silentOn,
                paymentSessionIds,
                routePrice: {} as RoutePrice,
              });
          }
        };

        const response = await modifyPurchase();

        const ModalKeyResponse = 'modifyPurchaseResponse';

        handleResponse({
          response,
          onSuccess: ({ data }) => {
            openModal(
              <Modal.Container>
                <Modal.Header
                  title="Modification response"
                  onPress={() => {
                    closeModal(ModalKeyResponse);
                  }}
                  divider
                />
                <Modal.Body p="3">
                  {/* {option === 'Api' && (
                    <ApiResponseMessage
                      data={data as PurchaseClientModifyProviderResponse}
                      routePrice={routePrice}
                    />
                  )} */}
                  {option === 'Auto-update' && (
                    <AutoUpdateResponseMessageWrapper
                      data={data as PurchaseClientAutoUpdateResponse}
                    />
                  )}
                </Modal.Body>
              </Modal.Container>,
              { key: ModalKeyResponse },
            );

            closeModal(ModalKey);
          },
        });
        clearAll();
      }}
    >
      <HStack flex={1} space="3">
        {/* {footerButton.error ? (
          <Text.Body2Medium color="error-mid">{footerButton.error}</Text.Body2Medium>
        ) : null} */}
        <View flex={1} />
        {option === 'Auto-update' && (
          <HStack space="1">
            <Toggle
              color="primary-mid"
              value={refreshPDF}
              onChange={(v) => setRefreshPDF(v)}
            />
            <Text.Body2Regular color="monochrome-extraDark">
              {translate('purchases.purchaseTool.purchaseCompare.refreshPDF')}
            </Text.Body2Regular>
          </HStack>
        )}
        <HStack space="1">
          <Toggle color="primary-mid" value={silentOn} onChange={(v) => setSilentOn(v)} />
          <Text.Body2Regular color="monochrome-extraDark">
            {translate('purchases.purchaseTool.purchaseCompare.silentUpdate')}
          </Text.Body2Regular>
        </HStack>

        {/* <BasketTotalBase /> */}
      </HStack>
    </Modal.Footer>
  );
};

const ModifyPanelBody = (
  props: ModifyPanelProps & {
    option: ModificationType;
    setOption: (o: ModificationType) => void;
  },
) => {
  const { spacing } = useAppTheme();
  const { isLoadingBookingData, isSupportLoading } = useModifyInitial(props);
  return (
    <DelayRender isLoading={isLoadingBookingData || isSupportLoading}>
      <HStack
        space="6"
        align="flex-start"
        style={{ alignSelf: 'flex-start', height: '100%' }}
        flex={1}
      >
        <ScrollView
          flex={1}
          style={{
            alignSelf: 'stretch',
            paddingHorizontal: spacing[4],
            paddingVertical: spacing[4],
          }}
        >
          <PurchaseTicketsToSelectQuery {...{ ...props, option: props.option }} />
        </ScrollView>
        <Divider vertical />
        <ScrollView
          style={{
            alignSelf: 'stretch',
            width: 750,
            paddingHorizontal: spacing[3],
            paddingTop: spacing[3],
            paddingBottom: spacing[6],
          }}
        >
          <TripManagerContainer {...props} />
        </ScrollView>
      </HStack>
    </DelayRender>
  );
};

const PurchaseTicketsToSelectQuery = (
  props: ModifyPanelProps & { option: ModificationType },
) => {
  const { purchase, supportRequest, isLoading } = useModifyData(props);
  return (
    <DelayRender isLoading={isLoading} noDelay loadingProps={{ style: { opacity: 0 } }}>
      {purchase && supportRequest ? (
        <PurchaseTicketsToSelect
          noBorder
          title={translate('purchases.purchaseTool.selectTicketsToModify')}
          purchase={purchase}
          supportRequest={supportRequest}
        />
      ) : null}
    </DelayRender>
  );
};

const TripManagerContainer = (
  props: ModifyPanelProps & {
    option: ModificationType;
    setOption: (o: ModificationType) => void;
  },
) => {
  const [selectedTickets] = purchaseActionManager.state.useGlobalState('selectedTickets');
  const { option, setOption } = props;

  if (!selectedTickets.length) {
    return null;
  }

  return (
    <VStack space="1">
      <VStack space="1">
        <ModificationTypeSelector option={option} setOption={setOption} />
        <Text.Small color="monochrome-dark">
          {translate('purchases.purchaseTool.paymentSession')}
        </Text.Small>
        <PaymentSessionSelector {...props} />
      </VStack>
      <Selection option={option} props={props} />
    </VStack>
  );
};

const ModificationTypeSelector = ({
  option,
  setOption,
}: {
  option: ModificationType;
  setOption: (option: ModificationType) => void;
}) => {
  return (
    <SelectInputBase
      noFeedback
      feedback=""
      values={[option]}
      type="single-select"
      setValues={(option) => {
        if (option?.at(1)) {
          setOption(option.at(1) as ModificationType);
        }
      }}
      options={[
        // { label: 'Customer & Provider', value: 'Api' },
        { value: 'Auto-update' },
      ]}
    />
  );
};

const Selection = ({
  option,
  props,
}: {
  option: ModificationType;
  props: ModifyPanelProps;
}) => {
  switch (option) {
    case 'Api':
      return <ApiTripManager {...props} />;
    case 'Manual':
      return <ManualTripManager {...props} />;
    case 'Auto-update':
      return <AutoUpdateTripManagerWrapper {...props} />;
  }
};

export const useModifyPanel = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (props: ModifyPanelProps) => {
      purchaseActionManager.reset();

      openModal(<ModifyPanel {...props} />, {
        key: ModalKey,
        modalStyle: isWeb
          ? {
              minWidth: '',
              maxWidth: '',
            }
          : undefined,
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

export const useClearAll = () => {
  const setResCode = useModifyAutoUpdateStore((s) => s.setResCode);

  return () => {
    setResCode('');
    purchaseActionManager.reset();
    resetBasketManager();
  };
};

const AutoUpdateResponseMessageWrapper = ({
  data,
}: {
  data: PurchaseClientAutoUpdateResponse;
}) => {
  const jsonViewer = useJsonViewerModal();

  return (
    <VStack>
      <AutoUpdateResponseMessage data={data as PurchaseClientAutoUpdateResponse} />
      <Button
        variant="outline"
        onPress={() => {
          jsonViewer.open({ data, title: 'Purchase after modification' });
        }}
      >
        {translate('purchases.purchaseTool.viewPurchase')}
      </Button>
    </VStack>
  );
};

// const ApiResponseMessage = ({
//   data,
//   routePrice,
// }: {
//   data: PurchaseClientModifyProviderResponse;
//   routePrice: RoutePrice;
// }) => {
//   const jsonViewer = useJsonViewerModal();

//   return (
//     <VStack space="3">
//       <HStack space="6">
//         <Text.Small color="monochrome-extraDark" type="small">
//           Credit before:
//         </Text.Small>
//         <Text.Body2Regular>
//           {getPriceWithSymbol(data.creditBefore, routePrice.ccy)}
//         </Text.Body2Regular>
//       </HStack>
//       <HStack space="6">
//         <Text.Small color="monochrome-extraDark" type="small">
//           {translate('purchases.purchaseTool.creditAfter')}
//         </Text.Small>
//         <Text.Body2Regular>
//           {getPriceWithSymbol(data.creditAfter, routePrice.ccy)}
//         </Text.Body2Regular>
//       </HStack>
//       <HStack space="6">
//         <Text.Small color="monochrome-extraDark" type="small">
//           {translate('purchases.purchaseTool.valueOfModification')}
//         </Text.Small>
//         <Text.Body2Regular>
//           {getPriceWithSymbol(data.valueOfModifyProvider, routePrice.ccy)}
//         </Text.Body2Regular>
//       </HStack>

//       <Button
//         variant="outline"
//         onPress={() => {
//           jsonViewer.open({ data, title: 'Purchase after modification' });
//         }}
//       >
//         {translate('purchases.purchaseTool.viewPurchase')}
//       </Button>
//     </VStack>
//   );
// };
