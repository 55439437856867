import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { ScreenPropAdminUsers } from '~/navigation/Navigation.types';

import { AdminUserItem } from './AdminUsers.Item';
import { AdminUserList, AdminUserListNative } from './AdminUsers.List';

export const AdminUsersScreen = (props: ScreenPropAdminUsers) => {
  return (
    <PageContainer>
      <AdminUsersLayout {...props} />
    </PageContainer>
  );
};

export const AdminUsersLayout = (props: ScreenPropAdminUsers) => {
  const adminUserId = props.route.params?.id;

  const [id, setId] = useState<string | undefined>(adminUserId);

  useEffect(() => {
    setId(adminUserId);
  }, [adminUserId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <AdminUserList
          onPress={(item) => {
            setId(item.id);
          }}
        />
      ) : (
        <AdminUserListNative />
      )}

      {id ? <AdminUserItem id={id} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
