import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { GetAdminUserResponse } from '@naus-code/naus-admin-types';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useCallback } from 'react';

import { Globals } from '~/components/Globals/Globals';
import { useAndroidBackModal } from '~/components/Modals/hooks/androidBack';
import { WebNavBar } from '~/components/Page/Page.WebNav';
import { TabScreen } from '~/components/Tabs/Tabs.web';
import { NavigationMenu, NavigationSubMenu } from '~/navigation/Navigation.Menu';
import { navigationRef } from '~/navigation/Navigation.utils';
import { useGetAuthUser, useGetAuthUserRole } from '~/screens/Auth/Auth.Context';
import { usersApi } from '~/screens/Users/Users.api';
import { firebase } from '~/utils/firebase';

import { AuthLogo } from '../assets';
import { PageHeaderContainer } from '../components/Page/Page.Header';
import { APP_VERSION } from '../utils/constants.utils';
import { translate } from '../utils/translation.utils';
import {
  DrawerMenuGroup,
  DrawerMenuItem,
  useDrawerGoNavigator,
} from './Navigation.Common';
import { ScreenGroupItem, ScreenItem } from './Navigation.types';

const Drawer = createDrawerNavigator();
const Stack = createNativeStackNavigator();

function withTabScreen<P extends object>(Screen: React.ComponentType<P>) {
  const ScreenWithModals = (props: P) => {
    return (
      <View bg="white" flex={1}>
        <WebNavBar />
        <View style={{ height: 1, opacity: 0 }} />
        <TabScreen>
          <Screen {...props} />
        </TabScreen>
      </View>
    );
  };
  ScreenWithModals.displayName = Screen.displayName;
  return ScreenWithModals;
}

export const NestScreen = React.forwardRef((_: any, ref: any) => {
  const screenList = Globals.flattenScreens();

  return (
    // <BrowserTabs>
    <Stack.Navigator
      initialRouteName={isNativeMobile ? 'MobileMenu' : undefined}
      //@ts-expect-error
      ref={ref as any}
    >
      {isNativeMobile ? (
        <>
          <Stack.Screen
            key="Home"
            name="Home"
            component={NavigationMenu}
            options={{
              // headerShown: s.headerShown || false,
              headerShown: false,
              // sceneContainerStyle: {
              //   backgroundColor: "white",
              // },
            }}
          />

          <Stack.Screen
            key="SubHome"
            name="SubHome"
            component={NavigationSubMenu}
            options={{
              // headerShown: s.headerShown || false,
              headerShown: false,
              // sceneContainerStyle: {
              //   backgroundColor: "white",
              // },
            }}
          />
        </>
      ) : null}
      {screenList.flatMap((s) => {
        if (useCanRenderScreen(s)) {
          [];
        }
        return (
          <Stack.Screen
            key={s.name}
            name={s.name}
            component={withTabScreen(s.component)}
            options={{
              // headerShown: s.headerShown || false,
              headerShown: false,
              // sceneContainerStyle: {
              //   backgroundColor: "white",
              // },
            }}
          />
        );
      })}
    </Stack.Navigator>
    // </BrowserTabs>
  );
});

export function DrawerNavigator() {
  const DrawContentComponent = useCallback((props: DrawerContentComponentProps) => {
    return <DrawerContent {...props} />;
  }, []);

  useAndroidBackModal();

  return (
    <Drawer.Navigator
      // @ts-expect-error
      ref={navigationRef as any}
      // initialRouteName="AdminUsers"
      defaultStatus={isWeb ? 'open' : undefined}
      screenOptions={{
        drawerType: isWeb ? 'permanent' : 'back',
        drawerStyle: [
          isWeb && {
            width: 182,
            // alignItems: "center",
          },
        ],
      }}
      backBehavior={isNativeMobile ? 'history' : undefined}
      drawerContent={DrawContentComponent}
    >
      <Drawer.Screen
        key="DrawerRootScreen"
        name="DrawerRootScreen"
        component={NestScreen}
        options={{
          // headerShown: s.headerShown || false,
          headerShown: false,
          sceneContainerStyle: {
            backgroundColor: 'white',
          },
        }}
      />
    </Drawer.Navigator>
  );
}

const DrawerContent = (_props: DrawerContentComponentProps) => {
  const screenList = useGetAvailableScreens(Globals.getDrawerScreenList());

  const onNavigate = useDrawerGoNavigator();

  return (
    <VStack
      flex={1}
      style={[{ width: '100%', zIndex: -1 }, isNativeMobile && { flex: 1 }]}
    >
      {isWeb ? (
        <PageHeaderContainer>
          <AuthLogo width={60} height={60} />
        </PageHeaderContainer>
      ) : null}
      <ScrollView
        showsVerticalScrollIndicator={false}
        flex={isWeb ? 1 : undefined}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <VStack space="5" py="5" style={[isNativeMobile && { flex: 1 }]}>
          {screenList
            .filter((s) => !s.nonMenu)
            .map((screenItem, ix) => {
              if (screenItem?.component) {
                return (
                  <DrawerMenuItem
                    key={ix}
                    label={screenItem.label}
                    name={screenItem.name}
                    icon={screenItem.icon || 'question-mark'}
                    onPress={() => {
                      onNavigate({
                        screenItem,
                        groupScreen: screenItem.name as any,
                      });
                    }}
                  />
                );
              }

              return <DrawerMenuGroup key={ix} {...screenItem} />;
            })}
        </VStack>
      </ScrollView>
      {isNativeMobile ? (
        <Button
          variant="text"
          iconStart="sign-out"
          onPress={async () => {
            await firebase.signOut();
          }}
        >
          {translate('auth.signOut')}
        </Button>
      ) : null}

      <VStack py="3">
        <Text.Small style={{ fontSize: 9 }} color="monochrome-light" align="center">
          v{APP_VERSION}
        </Text.Small>
      </VStack>
    </VStack>
  );
};

function useGetAvailableScreens(screensToFilter: (ScreenItem | ScreenGroupItem)[]) {
  return screensToFilter.filter(useCanRenderScreen);
}

export function useCanRenderScreen(screenItem: ScreenItem) {
  const role = useGetAuthUserRole();
  const { currentUser } = useGetAuthUser();
  const { data: user } = usersApi.useGetUserItemQuery(currentUser?.uid || '');

  if (!role || !user) {
    return false;
  }

  return (
    screenItem.access.indexOf(role) > -1 ||
    role === 'SYSTEM' ||
    canSeeScreenRules(screenItem, user)
  );
}
export function useCanRenderScreenLazy() {
  const role = useGetAuthUserRole();
  const { currentUser } = useGetAuthUser();
  const { data: user } = usersApi.useGetUserItemQuery(currentUser?.uid || '');

  return {
    canRender: (screenItem) => {
      if (!role || !user) {
        return false;
      }

      return (
        screenItem.access.indexOf(role) > -1 ||
        role === 'SYSTEM' ||
        canSeeScreenRules(screenItem, user)
      );
    },
  };
}

const canSeeScreenRules = (
  screenItem: ScreenItem | ScreenGroupItem,
  user: GetAdminUserResponse,
) => {
  const screenRules = screenItem.rules;
  if (!screenRules) {
    return false;
  }
  for (const rule of screenRules) {
    if (user.adminUser.rules.userRules?.[rule]) {
      return true;
    }
  }
};
