import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useCallback } from 'react';

import { LinkText } from '~/components/LinkText';
import { PressableOptionLabel } from '~/components/SupportConversation/InputPanel/Conversation.Common';
import { useChangeLang } from '~/components/SupportRequest/Modals/ChangeSupportLang';
import { AppText } from '~/components/Text';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { getAvailableLangs, getFullLang, translate } from '~/utils/translation.utils';

export const SupportRequestAutoTranslateHeader = () => {
  return (
    <>
      <View
        px="2.5"
        py="3"
        bg="monochrome-extraLight"
        justify="center"
        style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
      >
        <AppText.MiscTag type="heading1">
          {translate('supportRequests.autoTranslate')}
        </AppText.MiscTag>
      </View>
      <Divider bg="monochrome-light" style={{ maxWidth: isWeb ? 400 : undefined }} />
    </>
  );
};

export const SupportRequestAutoTranslateContent = ({
  supportId,
}: {
  supportId: string;
}) => {
  return (
    <VStack space="2.5" flex={1} style={{ maxWidth: isWeb ? 400 : undefined }} pb="2.5">
      <ChangeSupportRequestLang supportId={supportId} />
      <Divider />
      <AutoTranslateAgent supportId={supportId} />
      <Divider />
      <AutoTranslateCustomer supportId={supportId} />
    </VStack>
  );
};

const AutoTranslateCustomer = ({ supportId }: { supportId: string }) => {
  const { data: supportReqRes, isFetching } =
    supportRequestApi.useGetSupportRequestQuery(supportId);
  const [toggleAutoTranslates, { isLoading }] =
    supportRequestActionsApi.useToggleAutoUpdateCustomerMutation();

  if (!supportReqRes) {
    return <LoadingIndicator />;
  }

  const lang = supportReqRes.supportRequest.lang;
  return (
    <HStack align="center" px="3">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <PressableOptionLabel
          style={{ flex: 1 }}
          disabled={isFetching || lang === 'en'}
          state={!!supportReqRes.supportRequest.autoTranslateCustomer}
          text={
            lang === 'en'
              ? translate('supportRequests.autoTranslateCustomerDisabled')
              : translate('supportRequests.autoTranslateCustomer', {
                  from: getFullLang(lang),
                  to: translate('lang.english'),
                })
          }
          onPress={() => {
            toggleAutoTranslates({
              check: supportReqRes.check,
              supportId: supportReqRes.supportRequest._id,
            });
          }}
        />
      )}
    </HStack>
  );
};

const ChangeSupportRequestLang = ({ supportId }: { supportId: string }) => {
  const { data: supportReqRes } = supportRequestApi.useGetSupportRequestQuery(supportId);

  const supportRequestInfo = supportReqRes?.supportRequest;
  const check = supportReqRes?.check;

  const { open: openChangeLang } = useChangeLang();
  const onPressLang = useCallback(() => {
    const availableLanguages = getAvailableLangs();
    if (supportRequestInfo && check) {
      openChangeLang(
        availableLanguages,
        supportRequestInfo,
        supportRequestInfo.lang,
        check,
      );
    }
  }, [openChangeLang, check, supportRequestInfo]);

  return (
    <HStack px="3">
      <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
        {translate('supportRequests.targetTranslation')}
      </AppText.ExtraSmall>
      <LinkText onPress={onPressLang}>
        {getFullLang(supportRequestInfo?.lang ?? '-')}
      </LinkText>
    </HStack>
  );
};

const AutoTranslateAgent = ({ supportId }: { supportId: string }) => {
  const { data: supportReqRes, isFetching } =
    supportRequestApi.useGetSupportRequestQuery(supportId);

  const [toggleAutoTranslates, { isLoading }] =
    supportRequestActionsApi.useToggleAutoUpdateAgentMutation();

  if (!supportReqRes) {
    return <LoadingIndicator />;
  }
  const lang = supportReqRes.supportRequest.lang;
  const disabled = isFetching || lang === 'en';

  return (
    <HStack align="center" px="3" flex={1}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <PressableOptionLabel
          style={{ flex: 1 }}
          disabled={disabled}
          state={!!supportReqRes.supportRequest.autoTranslateAgent}
          text={
            lang === 'en'
              ? translate('supportRequests.autoTranslateAgentDisabled')
              : translate('supportRequests.autoTranslateAgent', {
                  from: translate('lang.english'),
                  to: getFullLang(lang),
                })
          }
          onPress={() => {
            toggleAutoTranslates({
              check: supportReqRes.check,
              supportId: supportReqRes.supportRequest._id,
            });
          }}
        />
      )}
    </HStack>
  );
};
