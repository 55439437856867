import {
  ButtonInteractionState,
  IconButton,
} from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import {
  AppViewProps,
  ScrollView,
  View,
} from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { KeyVal } from '@naus-code/naus-server-utils-common-types';
import isEqual from 'lodash/isEqual';
import { createStore } from 'react-hooks-global-state';
import { Pressable } from 'react-native';

import { Tab } from '~/components/Tabs/Tab.types';
import { writeUrl } from '~/hooks/nav.hook';

export const __DONT__IMPORT__Tabs = () => {
  return <VStack flex={1} bg="white"></VStack>;
};

const TAB_HEIGHT = 35;
const TAB_WIDTH = 175;

const TabItem = ({ tab }: { tab: Tab }) => {
  const currentTabKey = useCurrentTabKey();
  const selected = tab.key === currentTabKey;

  return (
    <Pressable
      onPress={() => {
        writeUrl(tab.screenKey as any, tab.params);
        TabActions.selectTab(tab.key);
      }}
    >
      {({ hovered }: ButtonInteractionState) => {
        return (
          <HStack
            bg={
              hovered
                ? 'monochrome-extraLight'
                : selected
                  ? 'primary-extraLight'
                  : 'monochrome-base'
            }
            style={{
              width: TAB_WIDTH,
              height: TAB_HEIGHT,
              borderTopEndRadius: 8,
            }}
            align="center"
            space="2"
            p="2"
          >
            <View
              bg={selected ? 'secondary-mid' : 'monochrome-base'}
              br={999}
              style={[!selected && { opacity: 0 }, { width: 5, height: 5 }]}
            />
            <Text.ExtraSmall
              numberOfLines={1}
              color={selected ? 'black' : 'monochrome-mid'}
              style={[selected && { fontWeight: 'bold' }, { flex: 1 }]}
            >
              {tab.title}
            </Text.ExtraSmall>
            <IconButton
              color="monochrome"
              icon="close"
              size="small"
              onPress={() => {
                TabActions.closeTab(tab.key);
              }}
            />
          </HStack>
        );
      }}
    </Pressable>
  );
};

export const TabSelector = () => {
  const tabs = useTabs();

  if (isNativeMobile) {
    return null;
  }
  return (
    <ScrollView
      style={{
        backgroundColor: 'white',
        height: TAB_HEIGHT,
        maxHeight: TAB_HEIGHT,
      }}
      contentContainerStyle={{ flexGrow: 1 }}
      horizontal
    >
      <HStack align="flex-end">
        {tabs.map((t) => (
          <TabItem key={t.key} tab={t} />
        ))}
      </HStack>
    </ScrollView>
  );
};

export const TabScreen = (props: AppViewProps) => {
  const tabs = useTabs();
  const currentTabKey = useCurrentTabKey();

  if (isNativeMobile) {
    return <>{props.children}</>;
  }

  const Screen = tabs.find((t) => t.key === currentTabKey)?.screen;
  if (Screen) {
    return (
      <View flex={1} bg="white" {...props}>
        {tabs.map((tab) => {
          return (
            <View
              flex={1}
              key={tab.key}
              style={[
                tab.key !== currentTabKey && {
                  height: 0,
                  maxHeight: 0,
                  opacity: 0,
                  minHeight: 0,
                },
              ]}
            >
              {tab.screen}
            </View>
          );
        })}
      </View>
    );
  }

  return null;
};

const initialTabState = {
  tabs: [] as Tab[],
  currentTabKey: '' as string,
};

const reducer = (
  state: typeof initialTabState,
  action:
    | { type: 'addTab'; tab: Tab }
    | { type: 'closeTab'; key: string }
    | { type: 'selectTab'; key: string; params?: KeyVal<any> },
): typeof initialTabState => {
  switch (action.type) {
    case 'addTab': {
      const index = state.tabs.findIndex((tab) => tab.key === action.tab.key);
      if (index !== -1) {
        const clonedTabs = [...state.tabs];
        clonedTabs[index] = action.tab;
        return { ...state, tabs: clonedTabs, currentTabKey: action.tab.key };
      } else {
        return {
          ...state,
          tabs: [...state.tabs, action.tab],
          currentTabKey: action.tab.key,
        };
      }
    }
    case 'closeTab': {
      return {
        ...state,
        tabs: state.tabs.filter((tab) => tab.key !== action.key),
        currentTabKey:
          action.key === state.currentTabKey
            ? state.tabs[0]?.key || state.currentTabKey
            : state.currentTabKey,
      };
    }

    case 'selectTab': {
      const foundTab = state.tabs.find((tab) => tab.key === action.key);
      if (foundTab) {
        return {
          ...state,
          currentTabKey: foundTab.key,
          tabs: state.tabs.map((tab) => {
            if (tab.key === action.key) {
              return {
                ...tab,
                params: isEqual(action.params, tab.params) ? tab.params : action.params,
              };
            }

            return tab;
          }),
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
};

export const tabStore = createStore(reducer, initialTabState);

export const TabActions = {
  addTab: (tab: Tab) => {
    tabStore.dispatch({
      type: 'addTab',
      tab,
    });
  },
  closeTab: (key: string) => {
    tabStore.dispatch({
      type: 'closeTab',
      key,
    });
  },
  selectTab: (key: string, params?: KeyVal<any>): boolean => {
    const found = tabStore.getState().tabs.some((tab) => tab.key === key);

    if (found) {
      tabStore.dispatch({
        type: 'selectTab',
        key,
        params,
      });
    }

    return found;
  },
};

export const useTabs = () => {
  return tabStore.useStoreState('tabs');
};

export const useCurrentTabKey = () => {
  return tabStore.useStoreState('currentTabKey');
};
