import { inputHeight, useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Button, IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import {
  DropdownMenu,
  DropdownMenuList,
} from '@droplet-tech-code/core-elements/module/ui/Dropdown';
import { DateInput } from '@droplet-tech-code/core-elements/module/ui/Inputs/DateInput/DateInput';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { FormInput } from '@droplet-tech-code/core-elements/module/ui/Inputs/FormInputs';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';

import { searchApi } from '~/components/Search/Search.api';
import { SearchParams } from '~/components/Search/Search.List';
import { DATE_SERVER_FORMAT } from '~/utils/date.utils';
import { translate } from '~/utils/translation.utils';

export const SearchPanel = ({
  depDate,
  originInitialValue,
  destinationInitialValue,
  originId,
  destinationId,
  // options,
  form,
  onSearch,
}: {
  form: FormHandler;
  originInitialValue?: string;
  destinationInitialValue?: string;
  onSearch: () => void;
} & Partial<SearchParams>) => {
  const getOriginIdRef = useRef(() => '' as string);
  const getDestIdRef = useRef(() => '' as string);
  const getCleanFetch = useRef(() => false as boolean);
  const optionsRef = useRef(() => ({
    passengers: 0,
    vehicles: 0,
  }));

  useEffect(() => {
    form.registerInput({
      id: 'originId',
      type: 'text',

      input: {
        getValue: getOriginIdRef.current,
        isValid: () => true,
        setValue: () => {},
      },
    });
    form.registerInput({
      id: 'destinationId',
      type: 'text',
      input: {
        getValue: getDestIdRef.current,
        isValid: () => true,
        setValue: () => {},
      },
    });

    form.registerInput({
      id: 'cleanFetch',
      type: 'toggle',
      input: {
        getValue: getCleanFetch.current,
        isValid: () => true,
        setValue: () => {},
      },
    });

    form.registerInput({
      id: 'options',
      type: 'custom',
      input: {
        getValue: optionsRef.current,
        isValid: () => true,
        setValue: () => {},
      },
    });
  }, []);

  const depDateFinal = depDate || dayjs().format(DATE_SERVER_FORMAT);
  return (
    <VStack space="0.5">
      <HStack space="2" align="flex-start">
        <View flex={1}>
          <SearchLocationInput
            title="Origin"
            getValue={getOriginIdRef}
            initialFormValue={originId}
            originId={originId}
            initialSearchText={originInitialValue + (originId ? ` (${originId})` : '')}
          />
        </View>
        <View flex={1}>
          <SearchLocationInput
            title="Destination"
            getValue={getDestIdRef}
            initialFormValue={destinationId}
            destinationId={destinationId}
            initialSearchText={
              destinationInitialValue + (destinationId ? ` (${destinationId})` : '')
            }
          />
        </View>
        <View flex={1}>
          <DateInput
            calender
            form={form}
            config={{
              type: 'date',
              calender: true,
              noFeedback: true,
              id: 'depDate',
              format: DATE_SERVER_FORMAT,
              label: 'Dep date',
              initialValue: depDateFinal,
            }}
          />
        </View>
      </HStack>
      <HStack space="4">
        {/* <Counters getValue={optionsRef} initialValue={options} /> */}
        <CleanFetch getValue={getCleanFetch} />
        <View flex={1} />
        <IconButton icon="search" variant="filled" color="secondary" onPress={onSearch} />
      </HStack>
    </VStack>
  );
};

const CleanFetch = ({ getValue }: { getValue: MutableRefObject<() => boolean> }) => {
  const [checked, setChecked] = useState(false);
  return (
    <HStack space="3">
      <Text.Body2Regular>{translate('supportRequests.cleanSearch')}</Text.Body2Regular>
      <CheckBox
        checked={checked}
        onPress={(checked) => {
          setChecked(checked);
          getValue.current = () => {
            return checked;
          };
        }}
      />
    </HStack>
  );
};

const SearchLocationInput = ({
  destinationId,
  initialFormValue = '',
  initialSearchText = '',
  originId,
  title = 'Location',
  getValue,
}: {
  initialFormValue?: string;
  initialSearchText?: string;
  destinationId?: string;
  originId?: string;
  title?: string;
  getValue: MutableRefObject<() => string>;
}) => {
  const { components } = useAppTheme();

  const valueRef = useRef(initialFormValue);

  const [value, setValue] = useState<string>(initialFormValue);
  const [text, setText] = useState(initialSearchText);
  const { data, isLoading } = searchApi.useSearchLocationsQuery({
    destinationId,
    originId,
    text,
  });

  useEffect(() => {
    getValue.current = () => {
      return valueRef.current;
    };
  }, []);

  const [showDropdown, setDropdown] = useState(false);

  const itemHeight = Number(components.buttonInput?.height || inputHeight);

  const options =
    data?.locations.map((l) => ({
      value: l.id,
      label: `${l.title} (${l.id})`,
    })) || [];

  return (
    <VStack space="2">
      <Pressable
        style={[showDropdown && { opacity: 0 }]}
        onPress={() => {
          setDropdown(true);
        }}
      >
        <FormInput
          noFeedback
          // feedbackOnlyWithError={config.feedbackOnlyWithError}
          autoCorrect={false}
          autoCapitalize="none"
          value={text}
          feedback={''}
          error={false}
          disabled={false}
          label={title}
          optional={false}
          onChangeText={(txt) => setText(txt)}
          iconStart="search"
          iconEnd={(isLoading ? <LoadingIndicator /> : null) as any}
        />
      </Pressable>
      <View bg="error" style={{ position: 'relative' }}>
        <DropdownMenu
          visible={showDropdown}
          // visible
          modalKey="select-input-dropdown"
          onClose={() => setDropdown(false)}
          height={Math.min(options.length * itemHeight + options.length + 1 + 0, 420)}
          style={{ overflow: 'visible' }}
        >
          <View
            style={{
              // backgroundColor: 'white',
              position: 'absolute',
              top: -79,
              left: -1,
            }}
          >
            <FormInput
              noFeedback
              focusOnMount
              autoCorrect={false}
              autoCapitalize="none"
              value={text}
              feedback={''}
              error={false}
              disabled={false}
              label={title || 'Location'}
              optional={false}
              onChangeText={(txt) => setText(txt)}
              iconStart="search"
              iconEnd={(isLoading ? <LoadingIndicator /> : null) as any}
            />
          </View>
          <DropdownMenuList
            focusOnMount={false}
            isSearchable={false}
            isClearable={false}
            value={[value]}
            onPress={(item) => {
              const newValue = item.value;

              if (value === newValue) {
                setDropdown(false);
                return;
              }

              valueRef.current = newValue;
              setValue(newValue);
              setText(item.label);
              setDropdown(false);
            }}
            data={options}
            renderItem={(item) => {
              const isSelected = value === item.value;

              return (
                <HStack space="3" flex={1}>
                  <Text.Body2Regular
                    color={isSelected ? 'primary-mid' : undefined}
                    style={{ flex: 1 }}
                  >
                    {item.label || item.value}
                  </Text.Body2Regular>
                </HStack>
              );
            }}
          />
          {isNativeMobile ? (
            <Button
              onPress={() => {
                setDropdown(false);
              }}
              style={{
                borderRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              text="app.inputs.done"
            />
          ) : null}
        </DropdownMenu>
      </View>
    </VStack>
  );
};
