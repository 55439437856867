import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  TripScheduledCancellation,
  TripScheduledChange,
  TripScheduledChangeCreatePurchasesRequest,
  TripScheduledChangeVerifyPurchasesResponse,
  TripScheduledModification,
} from '@naus-code/naus-admin-types';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';

import { PressableCard } from '~/components/Card/PressableCard';
import { AppText } from '~/components/Text';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import {
  ChangeSchedulePurchasesList,
  ScheduleChangePurchasesListHeaderRow,
} from '../ChangeList/ScheduleChanges.Common';
import { useInvalidateScheduledChangesList } from '../ChangeList/ScheduledChanges.List';
import { scheduledChangesApi } from '../SchedulesChanges.api';

export const CreateScheduleChangeItem = ({
  form,
  changeTypeRef,
  cancellationForm,
  modificationForm,
  data,
}: {
  form: MutableRefObject<FormHandler>;
  changeTypeRef: MutableRefObject<'modification' | 'cancellation'>;
  cancellationForm: MutableRefObject<FormHandler>;
  modificationForm: MutableRefObject<FormHandler>;
  data: TripScheduledChangeVerifyPurchasesResponse;
}) => {
  const initArray = data.scheduledChange.selectedPurchasesToUpdate.map(
    (purchase) => purchase.id,
  );
  const { navigate } = useAppNavigation();
  const [selected, setSelected] = useState<string[]>(initArray);

  useEffect(() => {
    setSelected(initArray);
  }, [data]);

  const [createScheduledChange, { isLoading: isCreatingScheduleChange }] =
    scheduledChangesApi.useCreateScheduledChangeMutation();

  const { invalidate: invalidatePending } = useInvalidateScheduledChangesList();

  const onSetSelected = useCallback(
    (id: string) => {
      const newSelectedList = [...selected];
      const indexOfitem = selected.indexOf(id);
      if (indexOfitem < 0) {
        newSelectedList.push(id);
        setSelected(newSelectedList);
      } else {
        newSelectedList.splice(indexOfitem, 1);
        setSelected(newSelectedList);
      }
    },
    [selected],
  );

  const onCreateScheduleChange = async (
    formData: TripScheduledChangeCreatePurchasesRequest,
  ) => {
    const response = await createScheduledChange(formData);

    handleResponse({
      response,
      onSuccess: () => {
        invalidatePending();
        navigate('ScheduledChanges');
      },
      successMessage: translate('scheduledChanges.successfullyCreated'),
    });
  };

  const widthDic = {
    '0': '200px',
    '1': '80px',
    '2': '70px',
    '3': '70px',
    '4': '70px',
    '5': '70px',
  };
  return (
    <VStack flex={1}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <ScrollView>
          <VStack p="2.5" space="3">
            <ScheduleChangePurchasesListHeaderRow
              setAllSelected={() => setSelected(initArray)}
              selectNone={() => setSelected([])}
              widthDic={widthDic}
            />
            <ChangeSchedulePurchasesList
              widthDic={widthDic}
              data={data}
              selected={selected}
              onSetSelected={onSetSelected}
            />
          </VStack>
        </ScrollView>
      </ScrollView>
      {selected.length > 0 && (
        <HStack p="2.5">
          <View flex={1} />
          <PressableCard
            p="5"
            bc="primary-mid"
            isLoading={isCreatingScheduleChange}
            onPress={() => {
              const { value: formData } = form.current.getValue<TripScheduledChange>();
              const { value: valueCancellation } =
                cancellationForm.current.getValue<TripScheduledCancellation>();
              const { value: valueModification } =
                modificationForm.current.getValue<TripScheduledModification>();
              onCreateScheduleChange({
                scheduledChange: {
                  ...formData,
                  ...valueCancellation,
                  ...valueModification,
                  type: changeTypeRef.current,
                  selectedPurchaseIdsToUpdate: selected,
                },
              });
            }}
          >
            <AppText.MiscTag color="primary-mid">
              {translate('scheduledChanges.createScheduledChange')}
            </AppText.MiscTag>
          </PressableCard>
        </HStack>
      )}
    </VStack>
  );
};
