import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { useFilterSupportType } from '~/components/SupportRequest/Modals/FilterSupportRequests';
import { translate } from '~/utils/translation.utils';

export const PendingSupportRequestsListHeader = ({
  count,
  refetch,
  filterProps,
  isLoading,
  onFilter,
}: {
  count: number;
  isLoading: boolean;
  filterProps: {
    uniqueCategories: string[];
    uniqueUserIds: [string, string][];
    filterActive: boolean;
    onlyOwn: boolean;
  };
  onFilter: ({
    category,
    userIds,
    showOwnRequestsOnly,
  }: {
    category: string;
    userIds: Set<string>;
    showOwnRequestsOnly?: boolean;
  }) => void;
  refetch: () => void;
}) => {
  const { filterActive, onlyOwn, uniqueUserIds, uniqueCategories } = filterProps;
  return (
    <PageHeader
      title={translate('supportRequests.pendingSupportRequests')}
      Subtitle={PendingCount({ count })}
      withBack={isNativeMobile}
      childRight={
        <Filter
          count={count}
          filterActive={filterActive}
          onFilter={onFilter}
          onlyOwn={onlyOwn}
          uniqueUserIds={uniqueUserIds}
          categories={uniqueCategories}
        />
      }
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress: () => {
          refetch();
          onFilter({
            category: '',
            showOwnRequestsOnly: false,
            userIds: new Set<string>(),
          });
        },
        isLoading,
      }}
    />
  );
};

const PendingCount = ({ count }: { count: number }) => {
  return (
    <Text.Small color="monochrome-mid">{`${count}  ${translate(
      'supportRequests.pendingSupportRequests',
    )}`}</Text.Small>
  );
};

const Filter = ({
  onFilter,
  filterActive,
  categories,
  count,
  onlyOwn,
  uniqueUserIds,
}: {
  onFilter: ({
    category,
    userIds,
    showOwnRequestsOnly,
  }: {
    category: string;
    userIds: Set<string>;
    showOwnRequestsOnly?: boolean;
  }) => void;
  filterActive: boolean;
  categories: string[];
  count: number;
  onlyOwn: boolean;
  uniqueUserIds: [string, string][];
}) => {
  const { open } = useFilterSupportType();
  return (
    <View style={{ position: 'relative' }}>
      <IconButton
        color={filterActive ? 'success' : 'monochrome'}
        variant={'semi-filled'}
        icon="filter"
        onPress={() => {
          open(onFilter, categories, onlyOwn, uniqueUserIds);
        }}
      />

      {filterActive ? (
        <View
          style={{
            width: 20,
            height: 20,
            borderRadius: 999,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: -5,
            right: -5,
          }}
          bg="success-mid"
        >
          <Text.ExtraSmall color="white">{count}</Text.ExtraSmall>
        </View>
      ) : null}
    </View>
  );
};
