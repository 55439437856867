import {
  CompanyConfigClientCreateFerryCompanyDiscountRequest,
  CompanyConfigClientCreateFerryCompanyExtraTicketRequest,
  CompanyConfigClientCreateFerryCompanyPassengerTicketRequest,
  CompanyConfigClientCreateFerryCompanyVehicleTicketRequest,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { companyConfigApi } from '~/screens/Configs/Company/CompanyConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useAddFerryCompanyDiscountModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [addFerryCompanyDiscount, { isLoading: isAddingDiscount }] =
    companyConfigApi.useAddFerryCompanyDiscountConfigMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useAddFerryCompanyDiscountConfigFormConfigQuery({
        companyId,
      });
    return { data, isLoading };
  }, [companyId]);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientCreateFerryCompanyDiscountRequest['config'];
    check?: string;
  }) => {
    return await addFerryCompanyDiscount({
      config: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.addFerryCompanyDiscount'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isAddingDiscount,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddFerryPassengerTicketModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateFerryPassenger, { isLoading: isUpdatingFerryPassenger }] =
    companyConfigApi.useAddFerryCompanyPassengerConfigMutation();
  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useAddFerryCompanyPassengerConfigFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientCreateFerryCompanyPassengerTicketRequest['config'];
    check?: string;
  }) => {
    return await updateFerryPassenger({
      config: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.addPassengerTicket'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryPassenger,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddFerryVehicleTicketModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateFerryVehicle, { isLoading: isUpdatingFerryVehicle }] =
    companyConfigApi.useAddFerryCompanyVehicleConfigMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useAddFerryCompanyVehicleConfigFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientCreateFerryCompanyVehicleTicketRequest['config'];
    check?: string;
  }) => {
    return await updateFerryVehicle({
      config: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.addVehicleTicket'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryVehicle,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useAddFerryExtraTicketModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateFerryExtra, { isLoading: isUpdatingFerryExtra }] =
    companyConfigApi.useAddFerryCompanyExtraConfigMutation();
  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useAddFerryCompanyExtraConfigFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientCreateFerryCompanyExtraTicketRequest['config'];
    check?: string;
  }) => {
    return await updateFerryExtra({
      config: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.addExtraTicket'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryExtra,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
