import { ModalProvider } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { PopoverProvider } from '@droplet-tech-code/core-elements/module/ui/Popover/Popover';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';

import { useNavigateByTab } from '~/hooks/nav.hook';

import { useGetAuthUser } from '../screens/Auth/Auth.Context';
import { AuthScreen } from '../screens/Auth/Auth.Screen';
import { DrawerNavigator } from './Navigation.Drawer';

const Stack = createNativeStackNavigator();

const RootScreen = () => {
  return (
    <>
      <ModalProvider>
        <DrawerNavigator />
      </ModalProvider>
      <PopoverProvider />
    </>
  );
};

export const NavigationRouter = () => {
  const navigationRef = useNavigationContainerRef();
  const { currentUser } = useGetAuthUser();
  const tabNavigate = useNavigateByTab();

  useEffect(() => {
    if (isWeb) {
      const paths = window.location.pathname.split('/');
      const lastPath = paths[paths.length - 1];

      if (lastPath) {
        let params = {};
        try {
          const search = location.search.substring(1);
          params = JSON.parse(
            '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
            (key, value) => {
              return key === '' ? value : decodeURIComponent(value);
            },
          );
        } catch (err) {
          console.error(err);
        }

        const screen = lastPath
          .split('-')
          .map((t) => t.slice(0, 1).toUpperCase() + t.slice(1))
          .join('');

        tabNavigate(screen as any, params);
      }
    }
  }, []);

  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator>
        {currentUser ? (
          <Stack.Screen
            name="Root"
            component={RootScreen}
            options={{ headerShown: false }}
          />
        ) : (
          <Stack.Screen
            name="SignIn"
            component={AuthScreen}
            options={{ headerShown: false }}
          />
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};
