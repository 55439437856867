import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { useEffect, useState } from 'react';

import {
  CREDIT_KEY,
  getDifferencesAndSortAndSeeOnlyChanges,
  HistoryCreditItem,
  HistoryCreditItemWithDifference,
} from '~/screens/Finance/CreditLine/CreditLine.utils';
import {
  ccyToSymbolDictionary,
  getPriceWithSymbol,
} from '~/screens/Support/Purchases/purchase.utils';
import { getLocalStorageItem } from '~/utils/utils.helper';

export const HistoryCredits = ({
  companyCode,
  showAll,
}: {
  companyCode: string;
  showAll?: boolean;
}) => {
  const [history, setHistory] = useState<HistoryCreditItemWithDifference[]>([]);
  useEffect(() => {
    (async () => {
      const localHistory =
        (await getLocalStorageItem<HistoryCreditItem[]>(CREDIT_KEY + companyCode)) || [];

      setHistory(
        getDifferencesAndSortAndSeeOnlyChanges(
          localHistory,

          !showAll,
        ),
      );
    })();
  }, [showAll, companyCode]);

  return <HistoryCreditsBase history={history} />;
};

export const HistoryCreditsBase = ({
  history,
}: {
  history: HistoryCreditItemWithDifference[];
}) => {
  if (history.length) {
    return (
      <VStack space="1">
        {history.map((historyItem) => {
          return (
            <HStack space="2" key={historyItem.timestamp}>
              <Text.Small>
                {dayjs(historyItem.timestamp).format('DD-MMM-YY HH:mm:ss')}
              </Text.Small>
              <Text.Body2Regular>
                {getPriceWithSymbol(
                  historyItem.credit ?? 0,
                  ccyToSymbolDictionary[historyItem.ccy],
                  { thousandsComma: true },
                )}
              </Text.Body2Regular>

              {typeof historyItem.difference === 'number' &&
              !Number.isNaN(historyItem.difference) &&
              history.length > 1 &&
              historyItem.difference !== 0 ? (
                <Text.Small color={historyItem.difference >= 0 ? 'success' : 'error'}>
                  ({historyItem.difference > 0 ? '+' : ''}
                  {getPriceWithSymbol(historyItem.difference, '', {
                    thousandsComma: true,
                  })}
                  )
                </Text.Small>
              ) : null}
            </HStack>
          );
        })}
      </VStack>
    );
  }

  return null;
};

const ModalKey = 'useFullHistory';
export const useFullHistory = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (companyCode: string) => {
      openModal(
        <Modal.Container>
          <Modal.Header
            title="Full history"
            divider
            onPress={() => {
              closeModal(ModalKey);
            }}
          />
          <Modal.Body p="3" scroll>
            <HistoryCredits companyCode={companyCode} showAll />
          </Modal.Body>
        </Modal.Container>,
        {
          key: ModalKey,
          type: 'action-sheet-full',
        },
      );
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
