import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { getPlugins } from '~/components/Form/Form.Plugins';

import { areaLocationApi } from './AreaLocations.api';

export const AreaLocationItem = ({ id }: { id: string; onClose: () => void }) => {
  const formRef = useRef(new FormHandler());
  return (
    <>
      <AreaLocationItemComponent id={id} form={formRef.current} />
    </>
  );
};

const AreaLocationItemComponent = ({ id, form }: { id: string; form: FormHandler }) => {
  const { data: areaLocationGetForm, isLoading: isLoadingAreaLocationConfig } =
    areaLocationApi.useGetAreaLocationFormConfigQuery();

  const { data } = areaLocationApi.useGetAreaLocationQuery(id);
  const areaLocationData = data?.area;
  return (
    <FormContent
      isLoading={isLoadingAreaLocationConfig}
      form={areaLocationGetForm}
      flex={1}
      bg="white"
      p="3"
    >
      {areaLocationGetForm ? (
        <FormBuilder
          value={areaLocationData}
          data={areaLocationGetForm}
          form={form}
          readonly
          extensions={getPlugins()}
        />
      ) : null}
    </FormContent>
  );
};
