import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

export const useBookingByReferenceInputModal = () => {
  const navigation = useAppNavigation();

  return useCreateManualFormModal({
    title: translate('bookings.bookingRef'),
    subtitle: translate('bookings.enterReference'),
    formData: [
      {
        items: [
          {
            type: 'text',
            label: translate('bookings.reference'),
            id: 'bookingRef',
          },
        ],
      },
    ],
    onConfirm: (value) => {
      navigation.navigate('BookingsByRef', value);
    },
  });
};
