import { PurchaseClientWithMetaWithTickets } from '@naus-code/naus-admin-types';

import { ScreenGroupItem, ScreenItem, ScreenList } from '~/navigation/Navigation.types';
import type { PurchaseMix } from '~/screens/Support/Purchases/purchase.utils';

export const Globals = {
  usePurchaseHistoryViewer: (() => ({
    open: () => {},
    close: () => {},
  })) as () => {
    open: (purchase: PurchaseMix) => void;
    close: () => void;
  },

  CustomerItem: (() => {
    return <></>;
  }) as ({
    customerId,
    supportEmail,
  }: {
    customerId: string;
    supportEmail: string;
  }) => JSX.Element | null,

  getScreenDictionary: (() => []) as () => (ScreenItem | ScreenGroupItem)[],

  flattenScreens: () => [] as ScreenList,
  getDrawerScreenList: () => [] as (ScreenItem | ScreenGroupItem)[],
  PurchaseMenu: (() => <></>) as ({
    purchase,
    supportId,
  }: {
    purchase: PurchaseClientWithMetaWithTickets;
    supportId?: string | undefined;
  }) => JSX.Element,
};
