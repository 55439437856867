import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { OperatingSchedule } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { Table } from '~/components/Table/Table';
import { translate } from '~/utils/translation.utils';

export const OfficeTimes = ({
  officeSchedule,
}: {
  officeSchedule: { [key: string]: OperatingSchedule };
}) => {
  const officeScheduleEntries = Object.entries(officeSchedule);
  if (officeScheduleEntries.length === 0) {
    return <></>;
  }
  const daySchedules = officeScheduleEntries.map(([_day, schedule]) => schedule);
  const headers = [
    translate('supportConfig.day'),
    translate('supportConfig.startHour'),
    translate('supportConfig.startMinute'),
    translate('supportConfig.endHour'),
    translate('supportConfig.endMinute'),
  ];

  const scheduleDataTable = scheduleToDataTable(daySchedules);

  return (
    <CardWithHeader headerText={translate('supportConfig.officeTimes')}>
      <Table headers={headers} data={scheduleDataTable} rowHeight={5} />
    </CardWithHeader>
  );
};

const scheduleToDataTable = (daySchedules: OperatingSchedule[]) => {
  return daySchedules.map((schedule, index) => {
    const dayOfTheWeek = dayjs().day(index).format('dddd');
    if (schedule === undefined) {
      return [dayOfTheWeek, '-', '-', '-', '-'];
    }
    const { startHour, startMinute, endHour, endMinute } = schedule;

    return [dayOfTheWeek, startHour + '', startMinute + '', endHour + '', endMinute + ''];
  });
};
