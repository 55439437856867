import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { CompanyConfigClientCreateFerryCompanyExtraTicketRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { companyConfigApi } from '~/screens/Configs/Company/CompanyConfigs.api';
import { translate } from '~/utils/translation.utils';

const updateExtraTicketModalKey = 'UpdateExtraTicket-ModalKey';

export const useUpdateExtraTicketConfigModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: ({
      companyId,
      extraTicketCode,
      check,
    }: {
      companyId: string;
      extraTicketCode: string;
      check: string;
    }) => {
      openModal(
        <UpdateExtraTicketConfigComponent
          companyId={companyId}
          extraTicketCode={extraTicketCode}
          check={check}
        />,
        { key: updateExtraTicketModalKey, type: 'action-sheet-full' },
      );
    },
    close: () => {
      closeModal(updateExtraTicketModalKey);
    },
  };
};

const UpdateExtraTicketConfigComponent = ({
  companyId,
  extraTicketCode,
  check,
}: {
  companyId: string;
  extraTicketCode: string;
  check: string;
}) => {
  const formRef = useRef(new FormHandler());
  const { closeModal } = useModal();

  const { data, isLoading: isLoadingForm } =
    companyConfigApi.useUpdateFerryCompanyExtraConfigFormConfigQuery({
      companyId,
      ticketCode: extraTicketCode,
      check,
    });

  const [updateExtraTicketConfig, { isLoading: isUpdating }] =
    companyConfigApi.useUpdateFerryCompanyExtraConfigMutation();
  if (!data) {
    return null;
  }
  const { check: formCheck } = data;

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('companyConfig.updateExtraTicket')}
        onPress={() => closeModal(updateExtraTicketModalKey)}
      />
      <ModalScrollView>
        <FormContent isLoading={isLoadingForm} form={data.config} flex={1}>
          <FormBuilder data={data.config} form={formRef.current} />
        </FormContent>
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isUpdating}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              CompanyConfigClientCreateFerryCompanyExtraTicketRequest['config']
            >();

          if (valid) {
            const response = await updateExtraTicketConfig({
              config: value,
              companyId,
              check: formCheck,
            });
            handleResponse({
              response,
              onSuccess: () => {
                closeModal(updateExtraTicketModalKey);
              },
              onError: () => {
                closeModal(updateExtraTicketModalKey);
              },
            });
          }
        }}
        onCancel={() => closeModal(updateExtraTicketModalKey)}
      />
    </Modal.Container>
  );
};
