import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const PurchasesPRCListHeader = ({
  providerCode,
  onReset,
  onPress,
}: {
  providerCode: string;
  onReset: () => void;
  onPress?: () => void;
}) => {
  return (
    <PageHeader
      title={translate('purchases.purchasesByProviderResCode')}
      Subtitle={<Text.Small>{providerCode}</Text.Small>}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress,
      }}
      childRight={<IconButton icon="reset" color="monochrome" onPress={onReset} />}
    />
  );
};
