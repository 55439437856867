import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { CustomerClient, SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import React from 'react';

import { CustomerDeletedPill } from '~/components/Customer/utils/CusotmerInfo.utils';
import { LinkText } from '~/components/LinkText';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { useCustomerModal } from '../../Customer/Customer.Modal';
import { customerApi } from '../../Customer/utils/Customer.api';

export const SupportRequestCustomerHeader = ({
  supportRequestInfo,
}: {
  supportRequestInfo?: SupportRequestBackendClient;
}) => {
  const { data } = customerApi.useGetCustomerQuery(supportRequestInfo?._customerId ?? '');

  return (
    <>
      <View
        px="2.5"
        py="3"
        bg="monochrome-extraLight"
        justify="center"
        style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
      >
        <HStack space="2.5">
          <AppText.MiscTag type="heading1">
            {translate('supportRequests.customer')}
          </AppText.MiscTag>
          {data?.customer && <CustomerDeletedPill customer={data.customer} />}
        </HStack>
      </View>
      <Divider bg="monochrome-light" style={{ maxWidth: isWeb ? 400 : undefined }} />
    </>
  );
};

export const SupportRequestCustomerContent = ({
  supportRequestInfo,
}: {
  supportRequestInfo?: SupportRequestBackendClient;
}) => {
  const { open: openCustomerModal } = useCustomerModal();

  if (supportRequestInfo === undefined) {
    return <></>;
  }
  const { p_email, p_customerName, _customerId } = supportRequestInfo;
  const { data: customerData } = customerApi.useGetCustomerQuery(_customerId);

  return (
    <VStack
      space="2.5"
      flex={isNativeMobile ? 1 : undefined}
      style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
    >
      <HStack px="3">
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {translate('supportRequests.emailUpper')}
        </AppText.ExtraSmall>
        <LinkText
          onPress={() => {
            openCustomerModal(_customerId, p_email);
          }}
        >
          {customerData?.customer.p_email || p_email || 'NA'}
        </LinkText>
      </HStack>
      <Divider />
      <HStack px="3">
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {translate('supportRequests.nameUpper')}
        </AppText.ExtraSmall>
        <AppText.Small>{`${p_customerName}`}</AppText.Small>
      </HStack>

      <Divider />

      {customerData && <CustomerDeviceInfo customer={customerData.customer} />}
      {customerData && <Divider />}
    </VStack>
  );
};

const CustomerDeviceInfo = ({ customer }: { customer: CustomerClient }) => {
  const lastDevice = [...customer.devices].pop();
  let platform = lastDevice ? lastDevice._id.split('_').join(' ') : 'N/A';
  if (lastDevice?.platform === 'browser') {
    platform = 'browser';
  }
  return (
    <HStack px="3">
      <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
        {translate('supportRequests.Device')}
      </AppText.ExtraSmall>
      <AppText.Small>{`${platform} - ${lastDevice?.version || 'N/A'}`}</AppText.Small>
    </HStack>
  );
};
