import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { OperatingSchedule } from '@naus-code/naus-server-common-types';

import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { supportConfigApi } from '../supportConfig.api';
import { useAddOffice } from './Modals/Office.Add';

export const OfficesList = ({
  onPressOffice,
}: {
  onPressOffice: (id: string) => void;
}) => {
  const { data } = supportConfigApi.useGetSupportConfigQuery();

  const { openModal: openAddOffice } = useAddOffice();

  if (data === undefined) {
    return <></>;
  }
  const allOfficeConfigs = data.config.officeConfig.officeOperatingConfig;
  const { defaultOfficeId } = data.config.officeConfig;

  return (
    <VStack space="2.5">
      <ConfigListSectionTitle>
        <HStack>
          <Text.Small flex={1} color="monochrome-mid">
            {translate('supportConfig.offices')}
          </Text.Small>
          <IconButton icon="plus" color="monochrome" onPress={openAddOffice} />
        </HStack>
      </ConfigListSectionTitle>

      {allOfficeConfigs.map((officeConfig, index) => {
        const {
          officeName,
          officeId,
          isActive,
          defaultDaysOfTheWeek,
          country,
          timezone,
        } = officeConfig;

        const iconLeft: IconsId | undefined = isActive ? undefined : 'disconnected';

        const operatingWeekDaysString = getOperatingWeekDaysString(defaultDaysOfTheWeek);

        const currentOnPress = () => {
          onPressOffice(officeId);
        };

        return (
          <ConfigListItem
            key={index}
            text={`${officeName} [${country}]`}
            onPress={currentOnPress}
            iconLeftType={iconLeft}
            iconRightType="chevron-right"
          >
            <AppText.MiscTag flex={1}>
              {officeId === defaultOfficeId ? translate('supportConfig.default') : ''}
            </AppText.MiscTag>
            <AppText.MiscTag>{`${timezone} ${operatingWeekDaysString}`}</AppText.MiscTag>
          </ConfigListItem>
        );
      })}
    </VStack>
  );
};

const getOperatingWeekDaysString = (operatingSchedule: {
  [key: string]: OperatingSchedule;
}): string => {
  const operatingScheduleEntries = Object.entries(operatingSchedule);
  if (operatingScheduleEntries.length === 0) {
    return '[-,-,-,-,-,-,-]';
  }

  let operatingWeekDays = '[';

  for (const weekDaySchedule of operatingScheduleEntries) {
    const [day, schedule] = weekDaySchedule;
    if (schedule === undefined) {
      operatingWeekDays += '-,';
    } else {
      operatingWeekDays += day[0] + ',';
    }
  }
  operatingWeekDays = operatingWeekDays.slice(0, -1);
  operatingWeekDays += ']';

  return operatingWeekDays;
};
