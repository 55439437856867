import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import {
  PaginatedFlatList,
  paginationUtil,
} from '~/components/FlatList/FlatList.Paginated';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { SupportRequestListItemComponent } from '../SupportRequest.ListItem';
import { supportRequestApi } from '../SupportRequests.api';
import { ClosedSupportRequestsListHeader } from './SupportRequestsClosed.ListHeader';

export interface SupportRequestListProps {
  onPress: (item: SupportRequestListItemResponse) => void;
  id?: string;
}

export const SupportRequestListNative = () => {
  const navigate = useAppNavigation();
  return (
    <SupportRequestList
      onPress={(item) => {
        navigate.navigate('SupportRequest-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const SupportRequestList = (props: SupportRequestListProps) => {
  const selectedRef = useRef('');
  const { spacing } = useAppTheme();

  const currentSupportReqId = props.id ?? '';

  const renderItem = useCallback(
    (supportRequest: SupportRequestListItemResponse) => {
      return (
        <SupportRequestListItemComponent
          selected={currentSupportReqId === supportRequest.id}
          key={supportRequest.id}
          supportRequest={supportRequest}
          onPress={() => {
            selectedRef.current = supportRequest.id;
            props.onPress(supportRequest);
          }}
        />
      );
    },
    [currentSupportReqId],
  );

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <ClosedSupportRequestsListHeader />
      <PaginatedFlatList
        listId="SupportRequestsClosed"
        feedback={translate('supportRequests.emptySupportRequest')}
        hook={supportRequestApi.useGetSupportRequestClosedQuery}
        moreReqOptions={{ sortBy: 'dateCloseSR' }}
        pageSize={20}
        simple
        getId={(item) => item.id}
        showsVerticalScrollIndicator={isWeb}
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};

export const useInvalidateClosedSupportRequestList = () => {
  const [invalidateClosedSupportRequestList, { isLoading }] =
    supportRequestApi.useInvalidateGetSupportRequestClosedMutation();
  return {
    invalidate: async () => {
      const lastItemId = paginationUtil.lists.SupportRequestsClosed;
      if (lastItemId) {
        await invalidateClosedSupportRequestList({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
          sortBy: 'dateCloseSR',
        });
      }
    },
    isLoading,
  };
};
