import {
  Divider,
  DottedLine,
} from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { RouteTripNoDetails } from '@naus-code/naus-client-types';
import { useRef } from 'react';

import { ManualRequestedTrip, OriginalRequestedTrip } from '../ Modify.SelectedTrip';
import { SearchPanelQuery } from '../Modify.Search';
import { ModifyBasketPanel } from '../Modify.Tickets';
import { ModifyPanelProps, useModifyData } from '../Modify.utils';

export const ApiTripManager = (props: ModifyPanelProps) => {
  const selectRouteRef = useRef<RouteTripNoDetails | undefined>();
  const { openModal } = useModal();
  const { purchase } = useModifyData(props);
  const openSearchPanel = (purchase) => {
    openModal(
      <SearchPanelQuery {...props} selectRouteRef={selectRouteRef} purchase={purchase} />,
      { key: 'searchTripQuery' },
    );
  };

  return (
    <VStack pt="5">
      <VStack space="5">
        <Divider />
        <VStack space="3">
          <OriginalRequestedTrip {...props} onEdit={() => openSearchPanel(purchase)} />
          <ManualRequestedTrip
            {...props}
            selectRouteRef={selectRouteRef}
            onEdit={() => openSearchPanel(purchase)}
          />
        </VStack>
        <DottedLine />
        <ModifyBasketPanelQuery {...props} />
      </VStack>
    </VStack>
  );
};

const ModifyBasketPanelQuery = (props: ModifyPanelProps) => {
  const {
    selectedTickets,
    selectedRoute,
    manuallySelectedRoute,
    supportRequest,
    error,
    initialRoutePrice,
    isLoading,
  } = useModifyData(props);

  const noSelectedRoute = !(supportRequest?.requestOptions || manuallySelectedRoute);

  if (error && !isLoading && selectedTickets.length !== 0 && !noSelectedRoute) {
    return (
      <View align="center" justify="center" flex={1}>
        <Text.Body2Regular align="center" color="warning-mid">
          {error}
        </Text.Body2Regular>
      </View>
    );
  }

  if (!initialRoutePrice || !selectedRoute) {
    return null;
  }

  if (noSelectedRoute) {
    return null;
  }

  return (
    <ModifyBasketPanel
      key={selectedRoute.key}
      initialRoutePrice={initialRoutePrice}
      ticketIds={selectedTickets}
      purchaseId={props.purchaseId}
      routeTrip={selectedRoute}
    />
  );
};
