import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { LocationListItemResponse } from '@naus-code/naus-admin-types';

export interface PortLocationListItemComponentProps {
  portLocation: LocationListItemResponse;
}

export const PortLocationListItemComponent = ({
  portLocation,
}: PortLocationListItemComponentProps) => {
  const { id, title, country } = portLocation;
  return (
    <VStack flex={1}>
      <Text.Body2Medium color="monochrome">{id}</Text.Body2Medium>
      <HStack flex={1}>
        <Text.Small flex={1} bold numberOfLines={1}>
          {title}
        </Text.Small>
        <Text.Small>{country}</Text.Small>
      </HStack>
    </VStack>
  );
};
