import { ContentFeedbackHandler } from '@droplet-tech-code/core-elements/module/ui/Feedback/Feedback.Handler';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';

import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { ImageContainer } from '~/components/SupportConversation/Modals/OpenImage';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { translate } from '~/utils/translation.utils';

const ModalKey = 'seeAgencies';

const PurchaseAgencies = ({ purchaseId }: { purchaseId: string }) => {
  const { data, isLoading } = purchaseActionApi.useGetPurchaseAgenciesQuery(purchaseId);

  const { closeModal } = useModal();
  return (
    <Modal.Container>
      <ContentFeedbackHandler
        data={data}
        dataEmpty={!data}
        feedback={translate('common.noDataFound')}
        isLoading={isLoading}
      >
        <Modal.Header
          title={translate('purchases.purchaseTool.seeAgencies')}
          onPress={() => {
            closeModal(ModalKey);
          }}
          divider
        />
        {data ? (
          <ModalScrollView>
            <VStack space="3" p="2.5" flex={1}>
              {data.map((agent, index) => {
                return (
                  <VStack key={index} p="3" br={16} bc="monochrome-light" b={1}>
                    <HStack space="1.5">
                      <View style={{ width: 30, height: 30, overflow: 'hidden' }} br={8}>
                        <ImageContainer imgUrl={agent.companyImg} />
                      </View>

                      <VStack>
                        <Text.Body2Regular color="monochrome-extraDark">
                          {agent.companyName} ({agent.companyId})
                        </Text.Body2Regular>
                        <Text.Body2Medium>{agent.title}</Text.Body2Medium>
                      </VStack>
                    </HStack>

                    <Text.Body2Regular color="monochrome-extraDark">
                      {agent.locationName} ({agent.locationId})
                    </Text.Body2Regular>
                    <HStack space="1">
                      <Text.Small color="monochrome-extraDark">
                        {translate('purchases.purchaseTool.hasCoordinates')}
                      </Text.Small>
                      {agent.hasCoordinates ? (
                        <Icon icon="check" color="success-light" />
                      ) : (
                        <Icon icon="close" color="error-mid" />
                      )}
                    </HStack>
                  </VStack>
                );
              })}
            </VStack>
          </ModalScrollView>
        ) : null}
      </ContentFeedbackHandler>
    </Modal.Container>
  );
};

export const usePurchaseAgencies = () => {
  const { closeModal, openModal } = useModal();

  return {
    open: (purchaseId: string) => {
      openModal(<PurchaseAgencies purchaseId={purchaseId} />, {
        key: ModalKey,
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
