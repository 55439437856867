import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';

import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { PurchaseItem } from '~/screens/Support/Purchases/PurchaseItem';
import { translate } from '~/utils/translation.utils';

export const ViewPurchaseKey = 'AddModal-ViewPurchase';
export const ViewPurchaseModal = ({ purchaseId }: { purchaseId: string }) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <VStack flex={1}>
        <Modal.Header
          title={translate('scheduledChanges.purchaseDetails')}
          divider
          onPress={() => {
            closeModal(ViewPurchaseKey);
          }}
        />
        <ModalScrollView>
          <View p="3">
            <PurchaseItem purchaseId={purchaseId} />
          </View>
        </ModalScrollView>
        {/* <Modal.Footer
          onCancel={() => {
            closeModal(ViewPurchaseKey);
          }}
        /> */}
      </VStack>
    </Modal.Container>
  );
};

export const useViewPurchase = () => {
  const { openModal } = useModal();

  return {
    open: (purchaseId: string) => {
      openModal(<ViewPurchaseModal purchaseId={purchaseId} />, {
        key: ViewPurchaseKey,
        type: 'action-sheet-full',
      });
    },
  };
};
