import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useModalVerify } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { GetTripScheduledChangeResponse } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';

import { Card } from '~/components/Card/Card';
import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { Field } from '~/components/Card/Field';
import { Table } from '~/components/Table/Table';
import { isModificationChange } from '~/screens/ScheduledChanges/ChangeList/ScheduleChanges.utils';
import { translate } from '~/utils/translation.utils';

import { scheduledChangesApi } from '../SchedulesChanges.api';
import {
  ChangeSchedulePurchasesList,
  ScheduleChangePurchasesListHeaderRow,
} from './ScheduleChanges.Common';
import { useInvalidateScheduledChangesList } from './ScheduledChanges.List';

export const ScheduledChangeItem = ({
  scheduledChangeId,
  unsetScheduledChangeId,
}: {
  scheduledChangeId: string;
  unsetScheduledChangeId: () => void;
}) => {
  const { data } = scheduledChangesApi.useGetScheduledChangeQuery(scheduledChangeId);
  const { verifyAction } = useModalVerify();

  const [activateScheduleChange, { isLoading: isActivatingScheduledChange }] =
    scheduledChangesApi.useActivateScheduledChangeFormConfigMutation();

  const [deleteScheduleChange, { isLoading: isDeletingScheduledChange }] =
    scheduledChangesApi.useDeleteScheduledChangeFormConfigMutation();

  const { invalidate: invalidatePending } = useInvalidateScheduledChangesList();

  const widthDic = {
    '0': '200px',
    '1': '80px',
    '2': '70px',
    '3': '70px',
    '4': '70px',
    '5': '70px',
  };

  const onActivateScheduleChange = useCallback(async () => {
    const response = await activateScheduleChange(scheduledChangeId);

    handleResponse({
      response,
      successMessage: translate('scheduledChanges.successfullyActivated'),
      onSuccess: () => {
        invalidatePending();
      },
    });
  }, [scheduledChangeId]);

  const onDeleteScheduleChange = useCallback(async () => {
    await verifyAction({
      title: translate('scheduledChanges.deleteScheduledChanges'),
    }).then(async (verified) => {
      if (verified) {
        const response = await deleteScheduleChange(scheduledChangeId);
        unsetScheduledChangeId();

        handleResponse({
          response,
          successMessage: translate('scheduledChanges.successfullyDeleted'),
          onSuccess: () => {
            invalidatePending();
          },
        });
      }
    });
  }, [scheduledChangeId]);

  if (data === undefined) {
    return null;
  }

  return (
    <VStack flex={1}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <ScrollView>
          <VStack space="2.5" p="2.5">
            <VStack space="2.5">
              <ScheduledChangeCard scheduledChangeData={data} />
              <ScheduledChangesStopTable scheduledChangeData={data} />
            </VStack>
            {/* <Divider b={5} br={10} bc="primary-extraLight" /> */}
            <Card p="3">
              <VStack space="3">
                <>
                  <ScheduleChangePurchasesListHeaderRow
                    setAllSelected={() => {}}
                    selectNone={() => {}}
                    widthDic={widthDic}
                    notSelectable
                  />
                  <Divider />
                </>
                <ChangeSchedulePurchasesList
                  widthDic={widthDic}
                  data={data}
                  selected={[]}
                  onSetSelected={() => {}}
                  notSelectable
                />
              </VStack>
            </Card>
          </VStack>
        </ScrollView>
      </ScrollView>
      {userHasAuthorization() && data.scheduledChange.status === 'Draft' && (
        <>
          <Divider />
          <HStack space="2.5" p="2.5" bg="white">
            <View flex={1} />
            <Button
              isLoading={isDeletingScheduledChange}
              fitToContent
              variant="outline"
              onPress={onDeleteScheduleChange}
            >
              {translate('scheduledChanges.deleteScheduledChanges')}
            </Button>
            <Button
              isLoading={isActivatingScheduledChange}
              fitToContent
              onPress={onActivateScheduleChange}
            >
              {translate('scheduledChanges.activateScheduleChanges')}
            </Button>
          </HStack>
        </>
      )}
    </VStack>
  );
};

const ScheduledChangeCard = ({
  scheduledChangeData,
}: {
  scheduledChangeData: GetTripScheduledChangeResponse;
}) => {
  const { scheduledChange } = scheduledChangeData;
  const { title, adminUser, startDate, endDate, dateCreated } = scheduledChange;
  return (
    <CardWithHeader headerText={translate('scheduledChanges.scheduledChangeDetails')}>
      <HStack space="6" p="2.5" justify="center">
        <VStack space="1" style={{ height: '100%' }}>
          <Field
            label={translate('scheduledChanges.title')}
            value={title}
            labelColor="monochrome-mid"
            valueType="small"
          />
          <Field
            label={translate('scheduledChanges.adminUser')}
            value={adminUser}
            labelColor="monochrome-mid"
            valueType="small"
          />
          <Field
            label={translate('scheduledChanges.startDate')}
            value={startDate}
            labelColor="monochrome-mid"
            valueType="small"
          />
          <Field
            label={translate('scheduledChanges.endDate')}
            value={endDate}
            labelColor="monochrome-mid"
            valueType="small"
          />
        </VStack>
        <VStack space="1" style={{ height: '100%' }}>
          {scheduledChange.adminUserIdActivated && (
            <Field
              label={translate('scheduledChanges.adminUserIdActivated')}
              value={scheduledChange.adminUserIdActivated}
              labelColor="monochrome-mid"
              valueType="small"
            />
          )}
          <Field
            label={translate('scheduledChanges.dateCreated')}
            value={dateCreated}
            isDate
            labelColor="monochrome-mid"
            valueType="small"
          />
          {scheduledChange.dateActivated && (
            <Field
              label={translate('scheduledChanges.dateActivated')}
              value={scheduledChange.dateActivated}
              isDate
              labelColor="monochrome-mid"
              valueType="small"
            />
          )}
          {scheduledChange.description && (
            <HStack align="flex-start">
              <Text.Small color="monochrome-mid" flex={1}>
                {translate('scheduledChanges.description')}
              </Text.Small>
              <Text.Small style={{ width: 200 }} numberOfLines={6}>
                {scheduledChange.description}
              </Text.Small>
            </HStack>
          )}
          <Field
            label="Status"
            value={scheduledChange.status}
            labelColor="monochrome-mid"
            color={scheduledChange.status === 'Draft' ? 'secondary-light' : 'success-mid'}
            valueType="small"
          />
        </VStack>
      </HStack>
    </CardWithHeader>
  );
};

const ScheduledChangesStopTable = ({
  scheduledChangeData,
}: {
  scheduledChangeData: GetTripScheduledChangeResponse;
}) => {
  const { scheduledChange } = scheduledChangeData;
  const stopChangeData = scheduledChange.stopsClient;

  const headerRow = [
    translate('scheduledChanges.stopIndex'),
    translate('scheduledChanges.originPortName'),
    translate('scheduledChanges.destinationPortName'),
    translate('scheduledChanges.newDepartureTime'),
    translate('scheduledChanges.oldDepartureDateOffset'),
    translate('scheduledChanges.newDepartureDateOffset'),
    translate('scheduledChanges.newArrivalTime'),
    translate('scheduledChanges.oldArrivalDateOffset'),
    translate('scheduledChanges.newArrivalDateOffset'),
  ];

  const data = stopChangeData.map((stop, index) => {
    if (isModificationChange(stop)) {
      return [
        index + '',
        stop.originPortName,
        stop.destinationPortName,
        stop.newDepartureTime,
        stop.oldDepartureDateOffset + '',
        stop.newDepartureDateOffset + '',
        stop.newArrivalTime,
        stop.oldArrivalDateOffset + '',
        stop.newArrivalDateOffset + '',
      ];
    }

    return [
      index + '',
      stop.originPortName,
      stop.destinationPortName,
      '',
      '',
      '',
      '',
      '',
      '',
    ];
  });

  if (!data.length) {
    return null;
  }

  return (
    <CardWithHeader headerText={translate('scheduledChanges.stopChangeDetails')}>
      <Table flex={1} p={undefined} headers={headerRow} data={data} />
    </CardWithHeader>
  );
};

const userHasAuthorization = () => {
  //will only allow users with rights to access TODO!
  return true;
};
