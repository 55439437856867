import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { BookingListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef, useState } from 'react';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import {
  PaginatedFlatList,
  paginationUtil,
} from '~/components/FlatList/FlatList.Paginated';
import { useChangeParam } from '~/components/Modals/ChangeParamModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { BookingListItemComponent } from './Booking.ListItem';
import { bookingApi } from './Bookings.api';
import { BookingsListHeaderCustomerId } from './BookingsByCustomerId/BookingsByCustomerId.ListHeader';
import { BookingsListHeaderEmail } from './BookingsByEmail/BookingsByEmail.ListHeader';

export interface BookingListProps {
  onPress: (booking: BookingListItemResponse) => void;
  email?: string;
  customerId?: string;
}

export const BookingListNative = ({
  email,
  customerId,
}: {
  email?: string;
  customerId?: string;
}) => {
  const navigate = useAppNavigation();
  return (
    <BookingList
      email={email}
      customerId={customerId}
      onPress={(item) => {
        navigate.navigate('Booking-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const BookingList = (props: BookingListProps) => {
  const selectedRef = useRef('');
  const { spacing } = useAppTheme();
  const [email, setEmail] = useState(props.email);
  const [customerId, setCustomerId] = useState(props.customerId);

  const [invalidateByEmail, { isLoading: isLoadingEmail }] =
    bookingApi.useInvalidateBookingListByEmailMutation();
  const [invalidateById, { isLoading: isLoadingId }] =
    bookingApi.useInvalidateBookingListByIdMutation();

  const renderItem = useCallback(
    (booking: BookingListItemResponse) => {
      return <BookingListItemComponent key={booking.id} booking={booking} />;
    },
    [email, props.customerId],
  );

  const { open: openEditParam } = useChangeParam();

  const onEditEmail = (param: string) => {
    setEmail(param);
  };
  const onEditCustomerId = (param: string) => {
    setCustomerId(param);
  };

  const ItemSeparatorComponent = useItemSeparatorComponent();

  if (!props.customerId && !props.email) {
    return null;
  }

  return (
    <VStack flex={1}>
      {customerId && !email && (
        <BookingsListHeaderCustomerId
          customerId={customerId}
          onReset={() => {
            openEditParam({
              initialValue: customerId,
              title: translate('bookings.bookingsByCustomerId'),
              subtitle: translate('bookings.enterCustomerId'),
              label: translate('bookings.customerId'),
              onConfirm: onEditCustomerId,
            });
          }}
          onPress={() => {
            const options = paginationUtil.lastReq['BookingsByEmail'];
            if (!options) {
              return;
            }
            invalidateById({
              ...options,
              paginationRequest: {
                ...options.paginationRequest,
                refreshList: true,
              },
            });
          }}
        />
      )}
      {email && (
        <BookingsListHeaderEmail
          email={email}
          onReset={() => {
            openEditParam({
              initialValue: email,
              title: translate('bookings.bookingsByEmail'),
              subtitle: translate('bookings.enterEmail'),
              label: translate('bookings.email'),
              type: 'email',
              onConfirm: onEditEmail,
            });
          }}
          onPress={() => {
            const options = paginationUtil.lastReq['BookingsByEmail'];
            if (!options) {
              return;
            }
            invalidateByEmail({
              ...options,
              paginationRequest: {
                ...options.paginationRequest,
                refreshList: true,
              },
            });
          }}
        />
      )}
      <PaginatedFlatList
        key={customerId ? customerId : email}
        listId="BookingsByEmail"
        isLoading={isLoadingEmail || isLoadingId}
        feedback={translate('bookings.emptyBookings')}
        hook={
          customerId
            ? bookingApi.useGetBookingListByCustomerQuery
            : bookingApi.useGetBookingListByEmailQuery
        }
        moreReqOptions={customerId ? { customerId } : { email }}
        pageSize={20}
        getId={(item) => item.id}
        showsVerticalScrollIndicator={isWeb}
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },
          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
