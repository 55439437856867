import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  SupportRequestFillDynamicTemplateAnswersResponse,
  SupportRequestGetTemplateAnswersResponse,
} from '@naus-code/naus-admin-types';
import { SupportConversationHandlerLink } from '@naus-code/naus-client-types';
import { TemplateAnswer } from '@naus-code/naus-server-common-types';
import { MutableRefObject } from 'react';
import { create } from 'zustand';

import { useManualFillIn } from './Modals/ManualFillIn';
import { TemplateAnswerListItem } from './TemplateAnswers.ListItem';
import { TemplateAnswerListItemDynamic } from './TemplateAnswers.ListItem.Dynamic';

export const useTemplateAnswerStore = create<{
  viewLocaleFromHover: string;
  viewLocaleFromDropdown: string;
  setViewAsHover: (locale: string) => void;
  setViewAsDropdown: (locale: string) => void;
}>((set) => ({
  viewLocaleFromHover: '',
  viewLocaleFromDropdown: '',
  setViewAsHover: (locale) => {
    set({ viewLocaleFromHover: locale });
  },
  setViewAsDropdown: (locale) => {
    set({ viewLocaleFromDropdown: locale });
  },
}));

export const addAnswerDynamic = async ({
  answer,
  onAddTemplateAnswer,
  addDynamicTemplateAnswer,
}: {
  answer: TemplateAnswer;
  onAddTemplateAnswer: (
    answer: string,
    links?: SupportConversationHandlerLink[],
    responseType?: string,
    list?: string[],
  ) => void;
  addDynamicTemplateAnswer: (templateAnswerId: string) => Promise<
    | {
        data: SupportRequestFillDynamicTemplateAnswersResponse;
      }
    | {
        error: unknown;
      }
  >;
}) => {
  const response = await addDynamicTemplateAnswer(answer.id);
  handleResponse({
    response,
    onSuccess: ({ data }) => {
      const replacedText = data.templateAnswer || data.templateAnswerEmpty;
      const links = data.links;
      const responseType = answer.responseType;

      onAddTemplateAnswer(replacedText, links, responseType, data.list);
    },
  });
};

export const getTemplateListWithIds = ({
  category,
  templateList,
  categorySetRef,
}: {
  category: string;
  templateList: SupportRequestGetTemplateAnswersResponse['templateAnswers'];
  categorySetRef: React.MutableRefObject<Set<string>>;
}) => {
  return templateList
    .map((listItem, index) => {
      if (listItem.answers.length > 0) {
        categorySetRef.current.add(listItem.category);
      }
      return { ...listItem, id: index + '' };
    })
    .filter((templateList) => {
      if (category === 'All') {
        return templateList;
      }
      if (category === templateList.category) {
        return templateList;
      }
    });
};

const isManuallyFilledIn = (text: string) => {
  return text.indexOf('{{') >= 0;
};

interface TextRef {
  valid: boolean;
  value: { answer?: string };
}

const remapLinks = (links: TemplateAnswer['links'], supportLang: string) => {
  return links?.map((link) => {
    return {
      text: link.text[supportLang] ?? '',
      type: link.type,
      groupId: link.groupId,
      id: link.linkId,
      link: link.linkWeb,
    } as SupportConversationHandlerLink;
  });
};

const getCategoryIcon = (category: string): IconsId => {
  switch (category) {
    case 'Cancellations': {
      return 'cancel';
    }
    case 'Modifications': {
      return 'edit';
    }
    case 'Errors': {
      return 'warning';
    }
    case 'How-To': {
      return 'question-mark';
    }
    case 'General': {
      return 'support-agent';
    }
    default:
      return 'lightning-bolt';
  }
};

export const ListItemRenderItem = ({
  id,
  answers,
  category,
  supportLang,
  categorySetRef,
  textRef,
  formRef,
  addDynamicTemplateAnswer,
}: {
  id: string;
  answers: TemplateAnswer[];
  category: string;
  supportLang: string;
  categorySetRef: MutableRefObject<Set<string>>;
  formRef: MutableRefObject<FormHandler>;
  textRef: MutableRefObject<FormHandler>;
  addDynamicTemplateAnswer: (templateAnswerId: string) => Promise<
    | {
        data: SupportRequestFillDynamicTemplateAnswersResponse;
      }
    | {
        error: unknown;
      }
  >;
}) => {
  const { open: openManualFillIn } = useManualFillIn();

  const onAddTemplateAnswer = (
    answer: string,
    links?: SupportConversationHandlerLink[],
    responseType?: string,
    list?: string[],
  ) => {
    const currentText = textRef.current.getValue() as TextRef;
    textRef.current.setInputValue(
      'answer',
      currentText.value.answer ? currentText.value.answer + '\n' + answer : answer,
    );

    if (links) {
      formRef.current.setInputValue('links', links);
    }
    if (responseType) {
      formRef.current.setInputValue('responseType', responseType);
    }
    if (list) {
      formRef.current.setInputValue('list', list);
    }
  };

  if (!categorySetRef.current.has(category)) {
    return <></>;
  }

  return (
    <VStack p="2.5" space="2.5" key={id}>
      <HStack space="1">
        <Icon color="monochrome-dark" icon={getCategoryIcon(category)} />
        <Text.Small color="monochrome-dark">{category}</Text.Small>
      </HStack>
      <Divider />
      <VStack space="2.5" flex={1}>
        {answers.map((answer) => {
          return (
            <>
              {answer.isDynamic ? (
                <TemplateAnswerListItemDynamic
                  supportLang={supportLang}
                  addAnswer={async () => {
                    addAnswerDynamic({
                      answer,
                      onAddTemplateAnswer,
                      addDynamicTemplateAnswer,
                    });
                  }}
                  addAnswerManual={async () => {
                    const list = answer.list?.map((text) => text[supportLang]);
                    openManualFillIn(answer.text, (text) =>
                      onAddTemplateAnswer(
                        text,
                        remapLinks(answer.links, supportLang),
                        answer.responseType,
                        list,
                      ),
                    );
                  }}
                  answer={answer}
                  key={answer.id}
                />
              ) : (
                <TemplateAnswerListItem
                  supportLang={supportLang}
                  addAnswer={() => {
                    if (isManuallyFilledIn(answer.text[supportLang])) {
                      const list = answer.list?.map((text) => text[supportLang]);
                      openManualFillIn(answer.text, (text) =>
                        onAddTemplateAnswer(
                          text,
                          remapLinks(answer.links, supportLang),
                          answer.responseType,
                          list,
                        ),
                      );
                    } else {
                      const responseType = answer.responseType;
                      const list = answer.list?.map((text) => text[supportLang]);

                      onAddTemplateAnswer(
                        answer.text[supportLang],
                        remapLinks(answer.links, supportLang),
                        responseType,
                        list,
                      );
                    }
                  }}
                  answer={answer}
                  key={answer.id}
                />
              )}
            </>
          );
        })}
      </VStack>
    </VStack>
  );
};
