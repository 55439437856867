import { Icon, IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  FerryCompanyPassengerCabinTicketConfig,
  FerryCompanyPassengerSeatTicketConfig,
  FerryCompanyPassengerTicketConfig,
} from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { useUpdatePassengerOperationConfigConfigModal } from '~/components/Config/CompanyConfig/Modals/Tickets/UpdatePassengerOperationsTicketModal';
import { useUpdatePassengerTicketConfigModal } from '~/components/Config/CompanyConfig/Modals/Tickets/UpdatePassengerTicketModal';
import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const PassengerConfig = ({
  passengerConfig,
  check,
  companyId,
}: {
  check: string;
  companyId: string;
  passengerConfig: FerryCompanyPassengerTicketConfig;
}) => {
  const { name, disabled, type, code } = passengerConfig;
  const { open: openUpdatePassengerConfigModal } = useUpdatePassengerTicketConfigModal();
  const { open: openUpdatePassengerOperationConfigModal } =
    useUpdatePassengerOperationConfigConfigModal();

  return (
    <CardWithHeader
      headerText={name}
      iconRightType="edit"
      iconRightOnPress={() =>
        openUpdatePassengerConfigModal({
          companyId,
          check,
          passengerTicketCode: code,
        })
      }
      iconLeftType={disabled ? 'disconnected' : undefined}
    >
      <VStack p="2.5">
        {type === 'cabin' ? (
          <PassengerInfoCabin passengerConfig={passengerConfig} />
        ) : (
          <PassengerInfoSeat passengerConfig={passengerConfig} />
        )}
        <ConfigListItem
          text={translate('companyConfig.operationsCancellationsMod')}
          iconRightType="edit"
          onPress={() => {
            openUpdatePassengerOperationConfigModal({
              check,
              passengerConfigCode: passengerConfig.code,
              companyId,
            });
          }}
        />
      </VStack>
    </CardWithHeader>
  );
};

const PassengerInfoCabin = ({
  passengerConfig,
}: {
  passengerConfig: FerryCompanyPassengerCabinTicketConfig;
}) => {
  const {
    name,
    code,
    dataConfigId,
    extraPriceValidation,
    genderSpecific,

    customExtras,
    capacity,
    wholeUse,
  } = passengerConfig;

  return (
    <SwitchStack py="2.5" space="2.5">
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.companyCode')}
          </AppText.ExtraSmall>
          <Text.Small>{code}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.name')}
          </AppText.ExtraSmall>
          <Text.Small>{name}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.capacity')}
          </AppText.ExtraSmall>
          <Text.Small>{capacity}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.DataId')}
          </AppText.ExtraSmall>
          <Text.Small>{dataConfigId}</Text.Small>
        </HStack>
      </VStack>
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.wholeUse')}
          </AppText.ExtraSmall>
          <Text.Small>{wholeUse ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.genderSpecific')}
          </AppText.ExtraSmall>
          <Text.Small>{genderSpecific ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        {getFeatures(passengerConfig).length === 0 ? null : (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.features')}
            </AppText.ExtraSmall>
            <Text.Small>{featureIcons(passengerConfig)}</Text.Small>
          </HStack>
        )}
        {extraPriceValidation ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.extraValidation')}
            </AppText.ExtraSmall>
            <Text.Small>
              {`[${Object.entries(extraPriceValidation)
                .filter(([_key, value]) => value !== undefined)
                .map(([key, _value]) => key)
                .join(',')}]`}
            </Text.Small>
          </HStack>
        ) : null}
        {customExtras ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.customExtras')}
            </AppText.ExtraSmall>
            <Text.Small>{`[${customExtras.join(',')}]`}</Text.Small>
          </HStack>
        ) : null}
      </VStack>
    </SwitchStack>
  );
};

const PassengerInfoSeat = ({
  passengerConfig,
}: {
  passengerConfig: FerryCompanyPassengerSeatTicketConfig;
}) => {
  const { name, code, dataConfigId, category, extraPriceValidation, customExtras } =
    passengerConfig;

  return (
    <SwitchStack py="2.5" space="2.5">
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.companyCode')}
          </AppText.ExtraSmall>
          <Text.Small>{code}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.name')}
          </AppText.ExtraSmall>
          <Text.Small>{name}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.DataId')}
          </AppText.ExtraSmall>
          <Text.Small>{dataConfigId}</Text.Small>
        </HStack>
      </VStack>
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.category')}
          </AppText.ExtraSmall>
          <Text.Small>{category}</Text.Small>
        </HStack>
        {extraPriceValidation ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.extraValidation')}
            </AppText.ExtraSmall>
            <Text.Small>
              {`[${Object.entries(extraPriceValidation)
                .filter(([_key, value]) => value !== undefined)
                .map(([key, _value]) => key)
                .join(',')}]`}
            </Text.Small>
          </HStack>
        ) : null}
        {customExtras ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.customExtras')}
            </AppText.ExtraSmall>
            <Text.Small>{`[${customExtras.join(',')}]`}</Text.Small>
          </HStack>
        ) : null}
      </VStack>
    </SwitchStack>
  );
};

const featureIcons = (passengerConfig: FerryCompanyPassengerCabinTicketConfig) => {
  const existingFeatures = getFeatures(passengerConfig);

  return (
    <HStack>
      {existingFeatures.map(([key, _val], index) => {
        return (
          <Icon
            color="monochrome"
            size="medium"
            icon={iconFromFacility(key as Facilities)}
            key={index}
          />
        );
      })}
    </HStack>
  );
};

const getFeatures = (passengerConfig: FerryCompanyPassengerCabinTicketConfig) => {
  return Object.entries(passengerConfig).filter(
    ([key, val]) => val !== undefined && facilitiesSet.has(key),
  );
};

const iconFromFacility = (facility: Facilities): IconsId => {
  switch (facility) {
    case 'luxury':
      return 'luxury-cabin';
    case 'window':
      return 'cabin-window'; //luxury-cabin-old
    case 'balcony':
      return 'blacony'; //balcony
    case 'facilities':
      return 'onboard-facilities'; //facilities
    case 'pet':
      return 'pet-paw';
    case 'pure':
      return 'leaf';
    case 'wc':
      return 'wc'; //wc
    case 'basin':
      return 'basin'; //basin
    case 'lateCheckout':
      return 'clock';
    case 'bunk':
      return 'bunkbed'; //bunkbed
    default:
      return 'ferry';
  }
};

const facilitiesSet = new Set([
  'luxury',
  'window',
  'balcony',
  'facilities',
  'pet',
  'pure',
  'wc',
  'basin',
  'lateCheckout',
  'bunk',
]);

type Facilities =
  | 'luxury'
  | 'window'
  | 'balcony'
  | 'facilities'
  | 'pet'
  | 'pure'
  | 'wc'
  | 'basin'
  | 'lateCheckout'
  | 'bunk';
