import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { SystemClientGetCreditResponse } from '@naus-code/naus-admin-types';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { getLocalStorageItem } from '~/utils/utils.helper';

export interface HistoryCreditItem extends SystemClientGetCreditResponse {
  credit: number;
  timestamp: string;
}

export interface HistoryCreditItemWithDifference extends HistoryCreditItem {
  difference: number;
}

export const CREDIT_KEY = '@Credit_V4-';

export const CREDIT_COMPANIES = [
  'AGD',
  'ALP',
  'ANSF',
  'ASL',
  'ATC',
  'DSW',
  'FFE',
  'GST',
  'KRS',
  'LFG',
  'MSF',
  'SES',
  'SJT',
  'SRN',
  'SSF',
  'VNN',
].map((c) => ({ id: c }));

export const saveCreditLineItem = async ({
  creditData,
  localHistoryCached,
}: {
  creditData: SystemClientGetCreditResponse;
  localHistoryCached?: HistoryCreditItem[];
}) => {
  const localHistoryBase =
    localHistoryCached ??
    (await getLocalStorageItem<HistoryCreditItem[]>(CREDIT_KEY + creditData.companyId)) ??
    [];

  const localHistory = [...localHistoryBase];
  const lastItem = localHistory?.[localHistory.length - 1];

  if (
    !lastItem ||
    (dayjs().diff(dayjs(lastItem.timestamp).toJSON(), 'seconds') > 5 &&
      typeof creditData.credit === 'number')
  ) {
    localHistory.push({
      ...creditData,
      credit: creditData.credit ?? 0,
      timestamp: dayjs().toJSON(),
    });
  }

  const limit = 25;
  const newHistory =
    localHistory.length > limit - 1 ? localHistory.slice(1, limit) : localHistory;

  await AsyncStorage.setItem(
    CREDIT_KEY + creditData.companyId,
    JSON.stringify(newHistory),
  );

  return localHistory;
};

export const getDifferencesAndSortAndSeeOnlyChanges = (
  items: HistoryCreditItem[],
  changes: boolean,
): HistoryCreditItemWithDifference[] => {
  const sortedList = items
    .slice()
    .sort((a, b) => dayjs(b.timestamp).valueOf() - dayjs(a.timestamp).valueOf());

  // sortedList = sortedList
  //   // TEST ONLY
  //   .map((item, ix) => {
  //     if (ix === 3) {
  //       return {
  //         ...item,
  //         credit: item.credit - 2200,
  //       };
  //     }

  //     if (ix === 6) {
  //       return {
  //         ...item,
  //         credit: item.credit - 1300,
  //       };
  //     }

  //     if (ix === 7) {
  //       return {
  //         ...item,
  //         credit: item.credit + 38200,
  //       };
  //     }

  //     return item;
  //   }) as HistoryCreditItemWithDifference[];

  const withChanges = changes
    ? sortedList
        .filter((item, ix, list) => {
          const prevValue = list[ix - 1]?.credit;
          const currentValue = item.credit;
          return !(prevValue === currentValue);
        })
        .slice(0, 3)
    : sortedList;

  return withChanges.map((item, ix, list) => {
    const nextValue = list[ix + 1]?.credit;
    const currentValue = item.credit;
    const difference = currentValue - nextValue;
    return {
      ...item,
      difference: Number.isNaN(difference) ? 0 : difference,
    };
  });
};
