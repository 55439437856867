import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';

import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { useUpdateBooking } from '~/components/Config/SystemConfig/Modals/UpdateBooking';
import { useUpdateServiceCost } from '~/components/Config/SystemConfig/Modals/UpdateServiceCost';
import { useUpdateNotifier } from '~/components/Config/SystemConfig/Modals/UpdateTrackNotifier';
import { translate } from '~/utils/translation.utils';

export const FerryFormConfigList = ({ check }: { check: string }) => {
  const { openModal: openServiceCostModal } = useUpdateServiceCost({ check });
  const { openModal: openTrackNotifierModal } = useUpdateNotifier({ check });
  const { openModal: openBookingModal } = useUpdateBooking({ check });

  return (
    <VStack space="2.5">
      <ConfigListItem
        text={translate('systemConfig.serviceCost')}
        iconRightType="edit"
        onPress={openServiceCostModal}
      />
      <ConfigListItem
        text={translate('systemConfig.notifier')}
        iconRightType="edit"
        onPress={openTrackNotifierModal}
      />
      <ConfigListItem
        text={translate('systemConfig.bookingRules')}
        iconRightType="edit"
        onPress={openBookingModal}
      />
    </VStack>
  );
};
