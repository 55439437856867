import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { DefaultOpenPolicy, SpecialOpenPolicyGroup } from '@naus-code/naus-client-types';

import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { Table } from '~/components/Table/Table';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const OpenPolicies = ({
  defaultPolicies,
  specialPolicies,
}: {
  defaultPolicies: DefaultOpenPolicy[];
  specialPolicies?: SpecialOpenPolicyGroup[];
}) => {
  return (
    <VStack>
      <DefaultPolicies defaultPolicies={defaultPolicies} />
      {specialPolicies !== undefined ? (
        <SpecialPolicies specialPolicies={specialPolicies} />
      ) : null}
    </VStack>
  );
};
const DefaultPolicies = ({
  defaultPolicies,
}: {
  defaultPolicies: DefaultOpenPolicy[];
}) => {
  return (
    <VStack>
      <ConfigListSectionTitle>
        <Text.Small color="monochrome-mid">
          {translate('companyConfig.defaultOpenPolicies')}
        </Text.Small>
      </ConfigListSectionTitle>
      <PoliciesTable defaultPolicies={defaultPolicies} />
    </VStack>
  );
};

const SpecialPolicies = ({
  specialPolicies,
}: {
  specialPolicies: SpecialOpenPolicyGroup[];
}) => {
  return (
    <>
      {specialPolicies.map((policy, index) => {
        return (
          <VStack key={index}>
            <Divider bg="primary-base" thickness={15} br={10} />
            <SpecialPolicyInfo specialPolicy={policy} />

            <PoliciesTable defaultPolicies={policy.policies} />
          </VStack>
        );
      })}
    </>
  );
};

const SpecialPolicyInfo = ({
  specialPolicy,
}: {
  specialPolicy: SpecialOpenPolicyGroup;
}) => {
  const { name, isActive, periods, regions, locationIds, departingFrom, arrivingTo } =
    specialPolicy;
  return (
    <VStack>
      <ConfigListSectionTitle>
        <HStack>
          <Text.Small flex={1} color="monochrome-mid">
            {name}
          </Text.Small>
          <Icon color="monochrome" icon={isActive ? 'check-box-on' : 'check-box-off'} />
        </HStack>
      </ConfigListSectionTitle>
      <VStack py="2.5" space="2.5">
        <HStack h={24} flex={1}>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.periods')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 40 }} />
          <Text.Small
            flex={1}
            align="right"
            numberOfLines={1}
            style={{ overflow: 'hidden' }}
          >{`[${periods
            .map((period) => `${period.periodStart}->${period.periodEnd}`)
            .join(',')}]`}</Text.Small>
        </HStack>

        <HStack h={24} flex={1}>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.regions')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 40 }} />
          <Text.Small
            flex={1}
            align="right"
            numberOfLines={1}
            style={{ overflow: 'hidden' }}
          >{`[${regions?.join(',') || ' '}]`}</Text.Small>
        </HStack>
        <HStack h={24} flex={1}>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.locationIds')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 40 }} />
          <Text.Small
            flex={1}
            align="right"
            numberOfLines={1}
            style={{ overflow: 'hidden' }}
          >{`[${locationIds?.join(',') || ' '}]`}</Text.Small>
        </HStack>
        <HStack h={24} flex={1}>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.departingFrom')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 40 }} />
          <Text.Small
            flex={1}
            align="right"
            numberOfLines={1}
            style={{ overflow: 'hidden' }}
          >{`[${departingFrom?.join(',') || ' '}]`}</Text.Small>
        </HStack>
        <HStack h={24} flex={1}>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.arrivingTo')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 40 }} />
          <Text.Small
            flex={1}
            align="right"
            numberOfLines={1}
            style={{ overflow: 'hidden' }}
          >{`[${arrivingTo?.join(',') || ' '}]`}</Text.Small>
        </HStack>
        <Divider bg="black" />
      </VStack>
    </VStack>
  );
};

const PoliciesTable = ({ defaultPolicies }: { defaultPolicies: DefaultOpenPolicy[] }) => {
  const headers = [
    translate('companyConfig.id'),
    translate('companyConfig.name'),
    translate('companyConfig.hoursBeforeEnd'),
    translate('companyConfig.daysBeforeEnd'),
    translate('companyConfig.canOpen'),
  ];

  const data = defaultPolicies.map((policy) => [
    policy.id,
    policy.name,
    policy.hoursBeforeEnd + '',
    Math.floor(policy.hoursBeforeEnd / 24)
      ? Math.floor(policy.hoursBeforeEnd / 24) + ''
      : undefined,
    policy.canBeConverted ? 'TRUE' : 'FALSE',
  ]);

  return <Table flex={1} p={undefined} headers={headers} data={data} />;
};
