import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { GetNoticeResponse, UpdateNoticeRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { noticesApi } from '~/screens/Notices/notices.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

export const EditNoticeModalKey = 'EditModal-Notice';
const EditNoticeModal = ({ notice }: { notice: GetNoticeResponse['notice'] }) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [updateNotice, { isLoading: isUpdatingNotice }] =
    noticesApi.useUpdateNoticeMutation();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('notices.editNotice')}
        divider
        onPress={() => {
          closeModal(EditNoticeModalKey);
        }}
      />
      <ModalScrollView>
        <EditNoticeForm form={formRef.current} notice={notice} />
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isUpdatingNotice}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<UpdateNoticeRequest['notice']>();

          if (valid) {
            const response = await updateNotice({
              notice: { ...notice, ...value },
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(EditNoticeModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(EditNoticeModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useEditNotice = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (notice: GetNoticeResponse['notice']) => {
      openModal(<EditNoticeModal notice={notice} />, {
        key: EditNoticeModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(EditNoticeModalKey);
    },
  };
};

const EditNoticeForm = ({
  form,
  notice,
}: {
  form: FormHandler;
  notice: GetNoticeResponse['notice'];
}) => {
  const { data: createNoticeConfig, isLoading: isLoadingNoticeConfig } =
    noticesApi.useCreateNoticeFormConfigQuery();

  return (
    <VStack flex={1}>
      <FormContent isLoading={isLoadingNoticeConfig} form={createNoticeConfig}>
        {createNoticeConfig ? (
          <FormBuilder data={createNoticeConfig} form={form} initialValue={notice} />
        ) : null}
      </FormContent>
    </VStack>
  );
};
