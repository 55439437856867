import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { SearchBar } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { useCallback, useEffect } from 'react';

import { FlatList, ListRenderItem } from '~/components/FlatList/FlatList';
import { ListItem } from '~/components/FlatList/FlatList.Item';
import { PageContainer } from '~/components/Page/Page.Container';
import { Pill } from '~/components/Pill/Pill';
import {
  MissingVesselItem,
  useMissingVesselItemModal,
} from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vessels/MissingVesselConfigs.Item';
import { useMissingVesselStore } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vessels/MissingVesselConfigs.store';

import { companyDataConfigApi } from '../companyDataConfigsApi';

export const MissingVesselQueryList = () => {
  const { data, isLoading } = companyDataConfigApi.useGetMissingVesselsQuery();

  const setBaseTicket = useMissingVesselStore((s) => s.setBaseTicket);

  useEffect(() => {
    setBaseTicket(data?.list ?? []);
  }, [data?.list]);

  return (
    <PageContainer>
      <DelayRender isLoading={isLoading}>
        {data ? <MissingVesselList /> : null}
      </DelayRender>
    </PageContainer>
  );
};

const MissingVesselList = () => {
  const { spacing } = useAppTheme();
  const renderItem: ListRenderItem<MissingVesselItem> = useCallback(
    (item) => <MissingVesselListItem item={item} />,
    [],
  );

  const filteredTickets = useMissingVesselStore((s) => s.filteredTickets);

  return (
    <VStack flex={1}>
      <SearchBarContainer />
      <Divider />
      <FlatList
        idKey="key"
        simple
        ListHeaderComponent={<ListHeaderComponent />}
        contentContainerStyle={{
          padding: spacing[3],
        }}
        data={filteredTickets}
        renderItem={renderItem}
      />
    </VStack>
  );
};

const ListHeaderComponent = () => {
  const baseTickets = useMissingVesselStore((s) => s.baseTickets);
  const filteredTickets = useMissingVesselStore((s) => s.filteredTickets);
  const selectedTickets = useMissingVesselStore((s) => s.selectedTickets);

  return (
    <HStack p="3" space="1.5">
      {filteredTickets.length !== baseTickets.length ? (
        <Text.Small color="monochrome-mid">
          {filteredTickets.length} of {baseTickets.length} missing items
        </Text.Small>
      ) : (
        <Text.Small color="monochrome-mid">{baseTickets.length} missing items</Text.Small>
      )}
      <Text.Small color="monochrome-light">•</Text.Small>
      <Text.Small color="monochrome-mid">{selectedTickets.length} selected</Text.Small>
    </HStack>
  );
};

const SearchBarContainer = () => {
  const setFilteredTickets = useMissingVesselStore((s) => s.setFilteredTickets);
  const baseTickets = useMissingVesselStore((s) => s.baseTickets);
  const onSearch = useCallback(
    (filteredData: MissingVesselItem[]) => setFilteredTickets(filteredData),
    [],
  );
  return (
    <HStack>
      <SearchBar
        inputContainerStyle={{ flex: 1 }}
        data={baseTickets}
        onSearch={onSearch}
      />
    </HStack>
  );
};

const MissingVesselListItem = ({ item }: { item: MissingVesselItem }) => {
  const formItem = useMissingVesselItemModal();
  const onPressItem = useCallback(() => {
    formItem.open(item);
  }, [item]);

  const checkTicket = useMissingVesselStore((s) => s.checkTicket);
  const isEdited = useMissingVesselStore((s) => s.editedTickets[item.key]);
  const isChecked = useMissingVesselStore(
    (s) => (item: MissingVesselItem) => s.isChecked(item, s.selectedTicketDictionary),
  );

  return (
    <HStack space="3" flex={1} px="3">
      <CheckBox
        checked={isChecked(item)}
        onPress={() => {
          checkTicket(item);
        }}
      />
      <View flex={1}>
        <ListItem onPress={onPressItem}>
          <HStack space="3" flex={1}>
            <Pill color="primary">
              <Text.Small color="primary">{item.data.category}</Text.Small>
            </Pill>
            <Text.Body1Regular>{item.data.name}</Text.Body1Regular>
            <Text.Small color="monochrome-dark">
              {item.data.category} | {item.data.operatorId}
            </Text.Small>

            <View flex={1} />
            {isEdited ? (
              <Pill color="warning-mid">
                <Text.Small bold color="warning-mid">
                  Edited
                </Text.Small>
              </Pill>
            ) : null}
          </HStack>
        </ListItem>
      </View>
    </HStack>
  );
};
