import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { BookingListItemResponse } from '@naus-code/naus-admin-types';

import { getNoticeCreatedDate } from '~/utils/date.utils';

export interface BookingListItemComponentProps {
  booking: BookingListItemResponse;
}

export const BookingListItemComponent = ({ booking }: BookingListItemComponentProps) => {
  const { p_firstName, p_lastName, p_email, dateCreated, booking_ref } = booking;
  return (
    <HStack flex={1}>
      <VStack flex={1}>
        <Text.Body2Medium numberOfLines={1}>{booking_ref}</Text.Body2Medium>
        <Text.Body2Regular
          numberOfLines={1}
        >{`${p_firstName} ${p_lastName}`}</Text.Body2Regular>
      </VStack>
      <VStack>
        <Text.Body2Regular align="right" color="monochrome-extraDark">
          {getNoticeCreatedDate(dateCreated)}
        </Text.Body2Regular>
        <Text.Small color="monochrome-mid" align="right">
          {p_email}
        </Text.Small>
      </VStack>
    </HStack>
  );
};
