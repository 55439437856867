import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const BookingsListHeaderEmail = ({
  email,
  onReset,
  onPress,
}: {
  email: string;
  onReset: () => void;
  onPress?: () => void;
}) => {
  return (
    <PageHeader
      title={translate('bookings.bookingsByEmail')}
      Subtitle={Subtitle({ email })}
      withBack={isNativeMobile}
      iconRight={{
        color: 'monochrome',
        icon: 'refresh',
        onPress,
      }}
      childRight={<IconButton icon="reset" color="monochrome" onPress={onReset} />}
    />
  );
};

const Subtitle = ({ email }: { email: string }) => {
  return <AppText.Small color="monochrome-mid">{email}</AppText.Small>;
};
