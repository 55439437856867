import {
  CompanyConfigClientCreateCompanyRequest,
  CompanyConfigClientUpdateActiveConfigRequest,
  CompanyConfigClientUpdateFerryOperationsRequest,
  CompanyConfigClientUpdateOperatorContactsRequest,
  CompanyConfigClientUpdateOperatorETicketConfigRequest,
  CompanyConfigClientUpdateOperatorOperationsRequest,
  CompanyConfigClientUpdateOperatorTermsRequest,
  CompanyConfigUpdateFerryCompanyOperatorRequest,
} from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { companyConfigApi } from '~/screens/Configs/Company/CompanyConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useUpdateFerryCompanyModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateFerryCompany, { isLoading: isUpdatingFerryCompany }] =
    companyConfigApi.useUpdateFerryCompanyConfigMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryCompanyConfigFormConfigQuery(companyId);
    return { data, isLoading };
  }, [companyId]);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientCreateCompanyRequest['companyDetails'];
    check?: string;
  }) => {
    return await updateFerryCompany({
      companyDetails: value,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryCompany'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryCompany,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateActiveConfigModal = (companyId: string, check: string) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateActiveConfig, { isLoading: isUpdatingActiveConfig }] =
    companyConfigApi.useUpdateFerryActiveConfigMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryActiveConfigFormConfigQuery(companyId);
    return { data, isLoading };
  }, [companyId]);

  const onConfirm = async ({
    value,
  }: {
    value: CompanyConfigClientUpdateActiveConfigRequest['activeConfig'];
  }) => {
    return await updateActiveConfig({
      activeConfig: value,
      companyId,
      check,
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateActiveConfig'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingActiveConfig,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryCompanyOperatorModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateFerryCompanyOperator, { isLoading: isUpdatingFerryCompanyOperator }] =
    companyConfigApi.useUpdateFerryCompanyOperatorConfigMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryCompanyOperatorConfigFormConfigQuery(operatorId);
    return { data, isLoading };
  }, [operatorId]);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigUpdateFerryCompanyOperatorRequest['operator'];
    check?: string;
  }) => {
    return await updateFerryCompanyOperator({
      operator: value,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryCompanyOperator'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryCompanyOperator,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryOperatorContactModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateFerryOperatorContact, { isLoading: isUpdatingFerryOperatorContact }] =
    companyConfigApi.useUpdateFerryOperatorContactConfigMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryOperatorContactConfigFormConfigQuery({
        operatorId,
        companyId,
      });
    return { data, isLoading };
  }, [operatorId, companyId]);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientUpdateOperatorContactsRequest['contactDetails'];
    check?: string;
  }) => {
    return await updateFerryOperatorContact({
      contactDetails: value,
      operatorId,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryOperatorContact'),
        onConfirm,
        useFormHookHandler,
        loadingAction: isUpdatingFerryOperatorContact,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryOperatorETicketModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateFerryOperatorETicket, { isLoading: isUpdatingFerryOperatorETicket }] =
    companyConfigApi.useUpdateFerryOperatorETicketConfigMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryOperatorETicketConfigFormConfigQuery({
        operatorId,
        companyId,
      });
    return { data, isLoading };
  }, [operatorId, companyId]);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientUpdateOperatorETicketConfigRequest['eTicketConfig'];
    check?: string;
  }) => {
    return await updateFerryOperatorETicket({
      eTicketConfig: value,
      operatorId,
      companyId,
      check: check ?? '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryOperatorETicket'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryOperatorETicket,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryOperatorTermsModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [updateFerryOperatorTerms, { isLoading: isUpdatingFerryOperatorTerms }] =
    companyConfigApi.useUpdateFerryOperatorTermsConfigMutation();
  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryOperatorTermsConfigFormConfigQuery({
        operatorId,
        companyId,
      });
    return { data, isLoading };
  }, [operatorId, companyId]);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientUpdateOperatorTermsRequest['terms'];
    check?: string;
  }) => {
    return await updateFerryOperatorTerms({
      terms: value,
      operatorId,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryOperatorTerms'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryOperatorTerms,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryOperatorCustomerOperationModal = (
  operatorId: string,
  companyId: string,
) => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [
    updateFerryOperatorCustomerOperation,
    { isLoading: isUpdatingFerryOperatorCustomerOperation },
  ] = companyConfigApi.useUpdateFerryOperatorCustomerOperationMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useUpdateFerryOperatorCustomerOperationFormConfigQuery({
        operatorId,
        companyId,
      });
    return { data, isLoading };
  }, [operatorId, companyId]);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientUpdateOperatorOperationsRequest['customOperationsConfig'];
    check?: string;
  }) => {
    return await updateFerryOperatorCustomerOperation({
      customOperationsConfig: value,
      operatorId,
      companyId,
      check: check || '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryOperatorCustomerOperation'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryOperatorCustomerOperation,
      });
    },
    close: () => {
      closeForm();
    },
  };
};

export const useUpdateFerryCompanyOperationsModal = (companyId: string) => {
  const { open: openForm, close: closeForm } = useFormModal();
  const [updateFerryCompanyOperation, { isLoading: isUpdatingFerryCompanyOperations }] =
    companyConfigApi.useUpdateFerryOperationsConfigMutation();
  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      companyConfigApi.useGetUpdateFerryOperationsFormConfigQuery(companyId);
    return { data, isLoading };
  }, [companyId]);

  const onConfirm = async ({
    value,
    check,
  }: {
    value: CompanyConfigClientUpdateFerryOperationsRequest['config'];
    check?: string;
  }) => {
    return await updateFerryCompanyOperation({
      config: value,
      companyId,
      check: check || '',
    });
  };
  return {
    open: () => {
      openForm({
        modalTitle: translate('companyConfig.updateFerryCompanyOperations'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingFerryCompanyOperations,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
