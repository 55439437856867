import { create } from 'zustand';

export const useModifyAutoUpdateStore = create<{
  resCode?: string;
  setResCode: (_: string) => void;
}>((set) => ({
  setResCode: (code) => {
    set({ resCode: code });
  },
}));
