import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { IconCircle } from '@droplet-tech-code/core-elements/module/ui/Icon';
import {
  useModal,
  useModalVerify,
} from '@droplet-tech-code/core-elements/module/ui/Modal';
import {
  PopoverContainer,
  popoverControl,
  PopoverList,
} from '@droplet-tech-code/core-elements/module/ui/Popover';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { copyToClipboard } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  SupportConversationHandler,
  SupportConversationHandlerViewed,
} from '@naus-code/naus-client-types';
import { useCallback } from 'react';

import { Field } from '~/components/Card/Field';
import { MenuActionItem } from '~/components/MenuActionItem';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { translate } from '~/utils/translation.utils';

import { useEditReply } from '../Modals/EditReply';

const DeleteButton = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <MenuActionItem
      text={translate('common.delete')}
      icon="bin"
      isLoading={isLoading}
      disabled={isLoading}
    />
  );
};

const EditButton = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <MenuActionItem
      text={translate('common.edit')}
      icon="edit"
      isLoading={isLoading}
      disabled={isLoading}
    />
  );
};

export const OptionsPopover = ({
  supportRequestId,
  check,
  item,
  offsetY,
}: {
  supportRequestId: string;
  check: string;
  item: SupportConversationHandler;
  offsetY: number;
}) => {
  const [deleteRequest, { isLoading: isLoadingDelete }] =
    supportRequestActionsApi.useDeleteReplyMutation();
  const { verifyAction, cancelVerify } = useModalVerify();
  const { closeModal } = useModal();
  const { open: openEdit } = useEditReply();
  const [_, { isLoading: isLoadingEdit }] =
    supportRequestActionsApi.useEditReplyMutation();

  const onDelete = async () => {
    const response = await deleteRequest({
      supportId: supportRequestId,
      messageId: item.id,
      check,
    });
    handleResponse(
      { response },
      // () => {
      //   showToast(translate("supportConversations.deleteSuccess"));
      // },
      // (error) => {
      //   showToast(getErrorResponse(error).message, { type: "error" });
      // }
    );
  };

  return (
    <PopoverContainer
      width={250}
      maxHeight={320}
      offsetY={offsetY}
      Popover={
        <PopoverList
          list={[
            {
              item: <DeleteButton isLoading={isLoadingDelete} />,
              onPress: async () => {
                closeModal('openPopover');
                popoverControl.closePopover();
                await verifyAction({
                  title: translate('supportConversations.thisWillDelete'),
                }).then((confirm) => {
                  if (confirm) {
                    onDelete();
                  } else {
                    cancelVerify();
                  }
                });
              },
            },
            {
              item: <EditButton isLoading={isLoadingEdit} />,
              onPress: async () => {
                closeModal('openPopover');
                popoverControl.closePopover();
                openEdit(supportRequestId, check, item);
              },
            },
          ]}
        />
      }
    >
      {({ hovered }: ButtonInteractionState) => {
        {
          return (
            <IconCircle
              iconStyle={{
                alignSelf: 'center',
                justifyContent: 'center',
              }}
              size="small"
              icon="menu"
              color="monochrome"
              hovered={hovered}
            />
          );
        }
      }}
    </PopoverContainer>
  );
};

//To be fixed
export const HandlerMetaPopover = ({
  handler,
  initials,
  offsetY,
}: {
  initials: string;
  handler: SupportConversationHandlerViewed;
  offsetY: number;
}) => {
  const copy = useCallback((text: string) => {
    copyToClipboard(text);
  }, []);

  return (
    <PopoverContainer
      // width={250}
      maxHeight={320}
      offsetY={offsetY}
      // hoverEnable
      Popover={
        <PopoverList
          list={[
            {
              item: (
                <VStack p="2.5">
                  <Field
                    label={translate('supportConversations.name')}
                    value={handler.name}
                  />
                  <Field
                    label={translate('supportConversations.timestamp')}
                    value={handler.timestamp}
                    isDate
                  />
                  <HStack>
                    <Text.Small color="monochrome-dark" style={{ flex: 1 }}>
                      {translate('supportConversations.handlerId')}
                    </Text.Small>
                    <Text.Body2Medium
                      numberOfLines={1}
                      flex={1}
                      onPress={() => copy(handler.handlerId)}
                    >
                      {handler.handlerId}
                    </Text.Body2Medium>
                  </HStack>
                </VStack>
              ),
            },
          ]}
        />
      }
    >
      {({ hovered }: ButtonInteractionState) => {
        return (
          <HStack p="1.5" br={50} bg={hovered ? 'primary-light' : 'primary-extraLight'}>
            <Text.ExtraSmall>{initials}</Text.ExtraSmall>
          </HStack>
        );
      }}
    </PopoverContainer>
  );
};
