import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { CompanyConfigClientCreateCompanyRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { getPlugins } from '~/components/Form/Form.Plugins';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { companyConfigApi } from './CompanyConfigs.api';

export const AddCompanyConfigModalKey = 'AddModal-CompanyConfig';
const AddCompanyConfigModal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const { data: companyConfigCreateConfig } =
    companyConfigApi.useCreateFerryCompanyConfigFormConfigQuery();

  const [createCompanyConfig, { isLoading: isCreatingCompanyConfig }] =
    companyConfigApi.useAddFerryCompanyConfigMutation();

  if (companyConfigCreateConfig === undefined) {
    return null;
  }

  const check = companyConfigCreateConfig.check;
  return (
    <>
      <Modal.Header
        title={translate('companyConfig.newCompanyConfig')}
        subtitle={translate('companyConfig.enterNewCompanyConfig')}
        divider
        onPress={() => {
          closeModal(AddCompanyConfigModalKey);
        }}
      />
      <Modal.Body p="0">
        <CreateCompanyConfigForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingCompanyConfig}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              CompanyConfigClientCreateCompanyRequest['companyDetails']
            >();

          if (valid) {
            const response = await createCompanyConfig({
              companyDetails: value,
              check,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddCompanyConfigModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddCompanyConfigModalKey);
        }}
      />
    </>
  );
};

export const useAddCompanyConfig = () => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<AddCompanyConfigModal />, {
        key: AddCompanyConfigModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
const CreateCompanyConfigForm = ({ form }: { form: FormHandler }) => {
  const { data: companyConfigCreateConfig, isLoading: isLoadingCompanyConfigConfig } =
    companyConfigApi.useCreateFerryCompanyConfigFormConfigQuery();

  if (companyConfigCreateConfig === undefined) {
    return null;
  }

  const formConfig = companyConfigCreateConfig.config;

  return (
    <FormContent
      isLoading={isLoadingCompanyConfigConfig}
      form={companyConfigCreateConfig}
      flex={1}
    >
      {companyConfigCreateConfig ? (
        <FormBuilder data={formConfig} form={form} extensions={getPlugins()} />
      ) : null}
    </FormContent>
  );
};
