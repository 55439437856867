import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppTextProps, Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SystemTripConfig } from '@naus-code/naus-server-common-types';
import { useCallback } from 'react';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { systemConfigApi } from '../systemConfigs.api';
import { FerryFormConfigList } from './FerryFormConfig.List';

export const FerryFormConfig = () => {
  const { data, isLoading } = systemConfigApi.useGetSystemConfigQuery();
  if (isLoading) {
    return <LoadingIndicator />;
  }

  const config: SystemTripConfig | undefined = data?.config.tripSystemConfig;
  const check = data?.check;

  if (config === undefined || check === undefined) {
    return <></>;
  }

  const {
    companyProviders,
    residentValidationProviders,
    loyaltyValidationProviders,
    vesselTypes,
  } = config;
  const companyProvidersString = companyProviders.join(', ');
  const residentValidationProvidersString = residentValidationProviders.join(', ');
  const loyaltyValidationProvidersString = loyaltyValidationProviders.join(', ');
  const vesselTypesString = vesselTypes.join(', ');

  return (
    <CardWithHeader headerText={translate('systemConfig.tripConfig')}>
      <VStack flex={1} p="2.5">
        <SwitchStack>
          <VStack p="2.5" space="2.5" style={isWeb && { width: '50%' }}>
            <SearchToggle config={config} check={check} />
            <BookingToggle config={config} check={check} />
            <TrackingToggle config={config} check={check} />
          </VStack>
          <VStack p="2.5" space="2.5" style={isWeb && { width: '50%' }}>
            <HStack flex={1}>
              <AppText.ExtraSmall flex={1}>
                {translate('systemConfig.providers')}
              </AppText.ExtraSmall>
              <Text.Small>{`[${companyProvidersString}]`}</Text.Small>
            </HStack>

            <HStack>
              <AppText.ExtraSmall flex={1}>
                {translate('systemConfig.residentProviders')}
              </AppText.ExtraSmall>
              <Text.Small>{`[${residentValidationProvidersString}]`}</Text.Small>
            </HStack>

            <HStack>
              <AppText.ExtraSmall flex={1}>
                {translate('systemConfig.loyaltyProviders')}
              </AppText.ExtraSmall>
              <Text.Small>{`[${loyaltyValidationProvidersString}]`}</Text.Small>
            </HStack>

            <HStack>
              <AppText.ExtraSmall flex={1}>
                {translate('systemConfig.vesselType')}
              </AppText.ExtraSmall>
              <Text.Small>{`[${vesselTypesString}]`}</Text.Small>
            </HStack>
          </VStack>
        </SwitchStack>
        <FerryFormConfigList check={check} />
      </VStack>
    </CardWithHeader>
  );
};

const SearchToggle = ({ config, check }: { config: SystemTripConfig; check: string }) => {
  const { isSearchEnabled } = config.activeConfig;
  const [disableSearch] = systemConfigApi.useDisableSearchMutation();
  const [enableSearch] = systemConfigApi.useEnableSearchMutation();
  const toggleSearch = useCallback(async () => {
    if (isSearchEnabled.enabled) {
      await disableSearch({ check });
    } else {
      await enableSearch({ check });
    }
  }, [check]);
  return (
    <TextWithSwitch
      text={translate('systemConfig.search')}
      value={isSearchEnabled.enabled}
      onPress={toggleSearch}
    />
  );
};

const BookingToggle = ({
  config,
  check,
}: {
  config: SystemTripConfig;
  check: string;
}) => {
  const { isBookingEnabled } = config.activeConfig;
  const [disableBooking] = systemConfigApi.useDisableBookingMutation();
  const [enableBooking] = systemConfigApi.useEnableBookingMutation();
  const toggleBooking = useCallback(async () => {
    if (isBookingEnabled.enabled) {
      await disableBooking({ check });
    } else {
      await enableBooking({ check });
    }
  }, [check]);
  return (
    <TextWithSwitch
      text={translate('systemConfig.book')}
      value={isBookingEnabled.enabled}
      onPress={toggleBooking}
    />
  );
};

const TrackingToggle = ({
  config,
  check,
}: {
  config: SystemTripConfig;
  check: string;
}) => {
  const { isTrackingEnabled } = config.activeConfig;
  const [disableTracking] = systemConfigApi.useDisableTrackingMutation();
  const [enableTracking] = systemConfigApi.useEnableTrackingMutation();
  const toggleTracking = useCallback(async () => {
    if (isTrackingEnabled.enabled) {
      await disableTracking({ check });
    } else {
      await enableTracking({ check });
    }
  }, [check]);
  return (
    <TextWithSwitch
      text={translate('systemConfig.track')}
      value={isTrackingEnabled.enabled}
      onPress={toggleTracking}
    />
  );
};

export const TextWithSwitch = ({
  text,
  value,
  type,
  color,
  onPress,
}: {
  text: string;
  color?: ColorKeys;
  type?: AppTextProps['type'];
  value: boolean;
  onPress: () => void;
}) => {
  return (
    <HStack>
      <AppText.ExtraSmall
        type={type || 'extraSmall'}
        color={color || 'monochrome-extraDark'}
        flex={1}
      >
        {text}
      </AppText.ExtraSmall>
      <Toggle value={value} color="primary-light" onChange={onPress} />
    </HStack>
  );
};
