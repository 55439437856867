import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import {
  Divider,
  DottedLine,
} from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SystemClientGetCreditResponse } from '@naus-code/naus-admin-types';
import { useEffect, useState } from 'react';
import { Pressable } from 'react-native';

import {
  CREDIT_KEY,
  HistoryCreditItem,
  HistoryCreditItemWithDifference,
  saveCreditLineItem,
} from '~/screens/Finance/CreditLine/CreditLine.utils';
import {
  ccyToSymbolDictionary,
  getPriceWithSymbol,
} from '~/screens/Support/Purchases/purchase.utils';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { translate } from '~/utils/translation.utils';
import { getLocalStorageItem } from '~/utils/utils.helper';

import { financeApi } from '../Finance.api';
import { HistoryCreditsBase } from './CreditLine.History';
import { useCreditLineStore } from './CreditLine.store';

export const CompanyCreditByPurchaseId = ({ purchaseId }: { purchaseId: string }) => {
  const [showCredit, setShowCredit] = useState(false);

  return (
    <VStack>
      <HStack justify="flex-end">
        <Pressable
          onPress={() => {
            setShowCredit(!showCredit);
          }}
        >
          <HStack p="3" justify="flex-end">
            <Text.Small color="primary" align="right">
              {translate('purchases.showCredit')}
            </Text.Small>
            <Icon icon={showCredit ? 'chevron-up' : 'chevron-down'} />
          </HStack>
        </Pressable>
      </HStack>
      {showCredit && (
        <>
          <Divider hairline />
          <CompanyCredit purchaseId={purchaseId} />
        </>
      )}
    </VStack>
  );
};

const CompanyCredit = ({ purchaseId }: { purchaseId: string }) => {
  const { data: creditData, isLoading } =
    purchaseActionApi.useGetPurchaseCompanyCreditQuery(purchaseId);
  const [localCreditHistory, setLocalCreditHistory] = useState<
    HistoryCreditItemWithDifference[]
  >([]);
  useEffect(() => {
    if (creditData && typeof creditData.credit === 'number') {
      saveCreditLineItem({
        creditData,
      });
      setLocalCreditHistory([
        {
          ...creditData,
          credit: creditData?.credit || 0,
          timestamp: dayjs().toJSON(),
          difference: 0,
        },
      ]);
    }
  }, [creditData]);

  if (isLoading) {
    return (
      <VStack py="3">
        <LoadingIndicator />
      </VStack>
    );
  }
  if (!creditData || creditData.credit === undefined) {
    return (
      <View py="3">
        <Text.Body2Regular color="warning-light">
          {translate('purchases.noCredit')}
        </Text.Body2Regular>
      </View>
    );
  }

  return (
    <VStack space="3" pt="3">
      <CurrentCreditLine
        creditData={creditData}
        onSetLocalHistory={(newCredit) => {
          setLocalCreditHistory([
            {
              ...newCredit,
              difference: newCredit.credit - ([...localCreditHistory].pop()?.credit || 0),
            },
            ...localCreditHistory,
          ]);
        }}
      />
      <DottedLine style={{ marginBottom: isWeb ? -25 : undefined }} />
      <HistoryCreditsBase history={localCreditHistory} />
    </VStack>
  );
};

const CurrentCreditLine = ({
  creditData,
  onSetLocalHistory,
}: {
  onSetLocalHistory: (newCredit: HistoryCreditItemWithDifference) => void;
  creditData: SystemClientGetCreditResponse;
}) => {
  const [fetchFinancial, { isLoading: isCompanyCreditLoading }] =
    financeApi.useLazyGetOperatorFinancialCreditLineQuery();

  const setCreditLine = useCreditLineStore((s) => s.setCreditLine);

  const onRefresh = async () => {
    const localHistory = await getLocalStorageItem<HistoryCreditItem[]>(
      CREDIT_KEY + creditData.companyId,
    );
    const response = await fetchFinancial(creditData.companyId);
    handleResponse({
      response,
      disableSuccessToast: true,
      onSuccess: ({ data }) => {
        if (typeof data?.credit === 'number') {
          setCreditLine(creditData.companyId, data.credit, data.ccy);
          saveCreditLineItem({
            creditData: data,
            localHistoryCached: localHistory,
          });
          onSetLocalHistory({
            ...data,
            credit: data.credit ?? 0,
            timestamp: dayjs().toJSON(),
            difference: 0,
          });
        }
      },
    });
  };
  return (
    <HStack space="3" flex={1}>
      <Text.Small>{dayjs().format('DD-MMM-YY HH:mm:ss')}</Text.Small>
      <Text.Body2Regular flex={1}>
        {getPriceWithSymbol(
          creditData.credit ?? 0,
          ccyToSymbolDictionary[creditData.ccy],
        )}
      </Text.Body2Regular>
      <IconButton icon="refresh" onPress={onRefresh} isLoading={isCompanyCreditLoading} />
    </HStack>
  );
};
