import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ViewStyle } from '@droplet-tech-code/core-elements/module/types/components';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';

import { RowItem, TableOptions } from '~/components/Table/Table.types';
import { LinkedIndicator } from '~/screens/Finance/Transactions/Transactions.Common';

export const TableRow = <T,>({ row, style }: { row: RowItem<T>; style?: ViewStyle }) => {
  return (
    <HStack style={[row.rowStyle, style]} space="3">
      {row.cells.map((cell) => {
        const cellDef = cell.cellDef;
        return (
          <View
            key={cell.colKey as string}
            style={[
              cellDef.cellStyle,
              cellDef.getCellStyle?.(cell.value, cell.item),
              cellDef.width !== undefined && { width: cellDef.width },
            ]}
          >
            <Text.Body2Regular
              style={[cellDef.textStyle, cellDef.getTextStyle?.(cell.value, cell.item)]}
            >
              {cellDef.formatValue?.(cell.value, cell.item) || (cell.value as any)}
            </Text.Body2Regular>
          </View>
        );
      })}
    </HStack>
  );
};

export interface TableHeaderProp {
  colKey: string;
  style: ViewStyle;
  width?: number | string;
}

export const TableHeader = ({
  headers,
  options,
}: {
  headers: TableHeaderProp[];
  options: TableOptions<any>;
}) => {
  const { spacing } = useAppTheme();
  return (
    <>
      <HStack space="1.5" bg="white">
        <LinkedIndicator />
        <HStack
          key="0"
          style={{
            padding: spacing[3],
          }}
          space="3"
          bg="white"
        >
          {headers.map((header) => {
            const coldDef = options.colDef[header.colKey];
            return (
              <View
                key={header.colKey}
                style={[
                  coldDef.cellStyle,
                  header.style,
                  header.width !== undefined && { width: header.width },
                ]}
              >
                <Text.Body2Medium key={header.colKey} style={[coldDef.textStyle]}>
                  {options.colDef[header.colKey].headerLabel || header.colKey}
                </Text.Body2Medium>
              </View>
            );
          })}
        </HStack>
      </HStack>
      <Divider key="1" mb="3" />
    </>
  );
};
