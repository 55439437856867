import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

export const useVoucherByTinyIdModal = () => {
  const navigation = useAppNavigation();

  return useCreateManualFormModal({
    title: translate('voucher.voucherTinyId'),
    subtitle: translate('voucher.enterTinyId'),
    formData: [
      {
        items: [
          {
            type: 'text',
            label: translate('voucher.tinyId'),
            id: 'tinyId',
          },
        ],
      },
    ],
    onConfirm: (value) => {
      navigation.navigate('VoucherByTinyId', {
        voucherTinyId: value.tinyId,
      });
    },
  });
};
