import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  SupportRequestBackendClient,
  SupportRequestChangeSupportTypeRequest,
} from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { translate } from '~/utils/translation.utils';

export const ChangeSupportTypeKey = 'AddModal-PurchaseByDate';
export const ChangeSupportTypeModal = ({
  supportRequestInfo,
  check,
}: {
  check: string;
  supportRequestInfo: SupportRequestBackendClient;
}) => {
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();

  const { data: formData, isLoading } =
    supportRequestActionsApi.useGetChangeSupportTypeFormConfigQuery();

  const [changeSupportType, { isLoading: isChangingSupportType }] =
    supportRequestActionsApi.useChangeSupportTypeMutation();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.changeType')}
        subtitle={translate('supportRequests.enterNewRequestType')}
        divider
        onPress={() => {
          closeModal(ChangeSupportTypeKey);
        }}
      />
      <Modal.Body p="0">
        {isLoading ? (
          <View h={100} align="center" justify="center">
            <LoadingIndicator />
          </View>
        ) : (
          <>
            {formData ? (
              <FormBuilder data={formData} form={form.current} />
            ) : (
              <Text.Body1Medium>{translate('feedback.emptyData')}</Text.Body1Medium>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isChangingSupportType}
        onConfirm={async () => {
          const { valid, value } =
            form.current.getValue<SupportRequestChangeSupportTypeRequest>();
          if (valid) {
            const response = await changeSupportType({
              ...value,
              check,
              supportId: supportRequestInfo._id,
            });
            handleResponse(
              {
                response,
                successMessage: translate('supportRequests.successfullyChangedType'),
              },
              // () => {
              //   showToast(translate("supportRequests.successfullyChangedType"));
              // },
              // (error) => {
              //   showToast(getErrorResponse(error).message, { type: "error" });
              // }
            );
            closeModal(ChangeSupportTypeKey);
          }
        }}
        onCancel={() => {
          closeModal(ChangeSupportTypeKey);
        }}
      />
    </Modal.Container>
  );
};

export const useChangeSupportType = () => {
  const { openModal } = useModal();

  return {
    open: (check: string, supportRequestInfo: SupportRequestBackendClient) => {
      openModal(
        <ChangeSupportTypeModal supportRequestInfo={supportRequestInfo} check={check} />,
        {
          key: ChangeSupportTypeKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};
