import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import React, { useEffect, useRef, useState } from 'react';
import { Animated, Pressable } from 'react-native';
import { Easing } from 'react-native-reanimated';

export interface Section {
  header: () => JSX.Element;
  content: () => JSX.Element;
}

export const Accordion = ({
  single,
  sections,
  scrollEnabled,
  pressable = true,
  expandedIndex = [],
  disabled: parentDisabled,
}: {
  single?: boolean;
  pressable?: boolean;
  sections: Section[];
  scrollEnabled?: boolean;
  expandedIndex?: number[];
  disabled?: boolean;
}) => {
  const headerHeight = 56;
  const bodyHeight = 300;
  const {
    palette,
    // components
  } = useAppTheme();

  const [activeIndexes, setActiveIndex] = useState<number[]>(expandedIndex);
  const refHeight = useRef<number | undefined>(isWeb ? undefined : bodyHeight);
  const disabled = parentDisabled === undefined && (sections.length <= 1 || !pressable);

  const changeSetActive = (newIndex: number) => {
    if (single) {
      setActiveIndex(activeIndexes[0] === newIndex ? [] : [newIndex]);
      return;
    }

    const doesItExist = activeIndexes.some((ai) => ai === newIndex);
    if (doesItExist) {
      return setActiveIndex(activeIndexes.filter((ai) => ai !== newIndex));
    }

    return setActiveIndex([...activeIndexes, newIndex]);
  };

  return (
    <VStack
      flex={1}
      style={[
        { overflow: 'hidden' },
        // isNativeMobile && {
        //   borderWidth: 1,
        //   borderRadius: components.textInput?.borderRadius ?? 16,
        //   borderColor: palette.monochrome.extraLight,
        // },
      ]}
    >
      {sections.map((section, ix) => {
        const expanded = activeIndexes.some((ai) => ai === ix);
        const prevExpanded = activeIndexes.some((ai) => ai === ix - 1);
        return (
          <React.Fragment key={ix}>
            <View
              mb="2.5"
              style={{
                height: headerHeight,
              }}
            >
              {disabled ? (
                section.header()
              ) : (
                <Pressable
                  style={[
                    { flex: 1 },
                    ix > 0 &&
                      prevExpanded && {
                        borderTopWidth: 1,
                        borderColor: palette.monochrome.extraLight,
                      },
                  ]}
                  onPress={() => {
                    changeSetActive(ix);
                  }}
                >
                  {section.header()}
                </Pressable>
              )}
            </View>

            <Collapsible
              scrollEnabled={scrollEnabled}
              refHeight={refHeight}
              expanded={expanded}
              section={section}
            />
          </React.Fragment>
        );
      })}
    </VStack>
  );
};

const Collapsible = ({
  expanded,
  section,
  refHeight,
  scrollEnabled,
  offset = 0,
}: {
  offset?: number;
  expanded: boolean;
  section: Section;
  refHeight: React.MutableRefObject<number | undefined>;
  scrollEnabled?: boolean;
  closedInitially?: boolean;
}) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const [height] = useState(new Animated.Value(isWeb ? 0 : refHeight.current ?? 0));

  useEffect(() => {
    Animated.timing(height, {
      toValue: expanded ? refHeight.current ?? 0 : 0,
      duration: 100,
      useNativeDriver: false,
      easing: Easing.ease,
    }).start();
  }, [expanded, mounted, height]);

  const InnerContent = scrollEnabled ? (
    <ScrollView
      style={[isWeb && { flex: 1 }]}
      showsVerticalScrollIndicator
      scrollEnabled
      contentContainerStyle={[{ flexGrow: 1 }]}
    >
      {section.content()}
    </ScrollView>
  ) : (
    section.content()
  );

  return (
    <Animated.View
      // style={[
      //   expanded && isWeb && { flex: 1 },
      //   { height: height, overflow: "visible" },
      // ]}
      onLayout={(e) => {
        if (refHeight.current === undefined && isWeb) {
          refHeight.current = e.nativeEvent.layout.height - offset;
          setMounted(true);
        }
      }}
    >
      {InnerContent}
    </Animated.View>
  );
};
