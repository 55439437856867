import { spacing } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { TripScheduledChangeItemResponse } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import {
  PaginatedFlatList,
  paginationUtil,
} from '~/components/FlatList/FlatList.Paginated';
import { translate } from '~/utils/translation.utils';

import { scheduledChangesApi } from '../SchedulesChanges.api';
import { ScheduledChangeHeader } from './ScheduledChange.Header';
import { ScheduledChangeListItem } from './ScheduledChanges.ListItem';

export const ScheduledChangesList = ({ onPress }: { onPress: (id: string) => void }) => {
  const renderItem = useCallback((scheduledChange: TripScheduledChangeItemResponse) => {
    return (
      <ScheduledChangeListItem
        scheduledChange={scheduledChange}
        onPress={() => onPress(scheduledChange.id)}
      />
    );
  }, []);

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <ScheduledChangeHeader />
      <PaginatedFlatList
        listId="ScheduledChangesList"
        feedback={translate('scheduledChanges.empty')}
        hook={scheduledChangesApi.useGetScheduledListQuery}
        pageSize={20}
        simple
        getId={(item) => item.id}
        showsVerticalScrollIndicator={isWeb}
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        renderItem={renderItem}
        onPressItem={(item) => onPress(item.id)}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};

export const useInvalidateScheduledChangesList = () => {
  const [invalidateScheduledChanges, { isLoading }] =
    scheduledChangesApi.useInvalidateScheduledChangesMutation();
  return {
    invalidate: async () => {
      const lastItemId = paginationUtil.lists.ScheduledChangesList;
      if (lastItemId) {
        await invalidateScheduledChanges({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
        });
      }
    },
    isLoading,
  };
};
