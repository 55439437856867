import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropPausedSupportRequests } from '~/navigation/Navigation.types';

import { SupportRequestItem } from '../SupportRequestItem';
import { SupportRequestList, SupportRequestListNative } from './SupportRequest.List';

export const PausedSupportRequestsScreen = (props: ScreenPropPausedSupportRequests) => {
  return (
    <PageContainer>
      <PausedSupportRequestsLayout {...props} />
    </PageContainer>
  );
};

export const PausedSupportRequestsLayout = (props: ScreenPropPausedSupportRequests) => {
  const supportRequestId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(supportRequestId);
  }, [supportRequestId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <SupportRequestList onPress={(item) => setId(item.id)} id={id} />
      ) : (
        <SupportRequestListNative />
      )}
      {id ? (
        <SupportRequestItem supportId={id} onClose={() => setId(undefined)} />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
