import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { ReactElement } from 'react';
import { FlexAlignType } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { AppText } from '../Text';

export interface TableProps extends AppViewProps {
  headers: string[];
  data: (string | undefined)[][];
  cellAlign?: FlexAlignType;
  insertColumn?: React.ReactNode;
  insertIndex?: number;
  colWidth?: number;
  rowHeight?: number;
  ItemMenu?: (id: string) => ReactElement<{ id: string }>;
  Button?: (id: string) => ReactElement<{ id: string }>;
  dataIds?: string[];
}

export const Table = (props: TableProps) => {
  //data is given as array of rows

  const { headers, data: rawData } = props;

  const data = rawData.map((data) => data.map((dataPoint) => dataPoint || '-')); //replace undefined with "-"

  const indexes = cleanHeaders(headers, data);

  const columns = [] as string[][];

  const len = data[0] || [];

  if (!len.length) {
    return null;
  }

  for (let i = 0; i < len.length; i++) {
    if (indexes.indexOf(i) < 0) {
      continue;
    } //we do not want
    columns.push([headers[i]]);
    for (let j = 0; j < data.length; j++) {
      const element = data[j][i];
      columns[columns.length - 1].push(element);
    }
  }

  return (
    <ScrollView horizontal contentContainerStyle={{ flexGrow: 1 }}>
      <HStack flex={1} space="6" p="2.5">
        <VStack space="2.5" py="1" style={{ minWidth: props.colWidth || 50 }} flex={1}>
          {(columns[0] || []).map((indexColumnText, index) => {
            return (
              <View h={props.rowHeight} key={index} justify="center">
                <AppText.ExtraSmall align="left" color="monochrome-mid">
                  {indexColumnText}
                </AppText.ExtraSmall>
              </View>
            );
          })}
        </VStack>
        {columns.slice(1).map((dataCol: string[], index) => {
          if (props.insertColumn !== undefined && props.insertIndex === index) {
            return (
              <HStack space="6">
                {props.insertColumn}
                <VStack space="2.5" key={index} py="1">
                  {dataCol.map((dataText, ind) => {
                    return (
                      <View
                        w={props.colWidth}
                        h={props.rowHeight}
                        key={ind}
                        justify="center"
                        align={props.cellAlign || 'center'}
                      >
                        <AppText.Small color={ind === 0 ? 'monochrome-dark' : 'black'}>
                          {dataText}
                        </AppText.Small>
                      </View>
                    );
                  })}
                </VStack>
              </HStack>
            );
          }
          return (
            <VStack space="2.5" key={index} py="1">
              {dataCol.map((dataText, ind) => {
                return (
                  <View
                    w={props.colWidth}
                    h={props.rowHeight}
                    key={ind}
                    justify="center"
                    align={props.cellAlign || 'center'}
                  >
                    <AppText.Small color={ind === 0 ? 'monochrome-dark' : 'black'}>
                      {dataText}
                    </AppText.Small>
                  </View>
                );
              })}
            </VStack>
          );
        })}
        {!!props.ItemMenu && !!props.dataIds && (
          <VStack space="2.5" py="1" style={{ minWidth: props.colWidth || 50 }}>
            <View h={props.rowHeight} />
            {props.dataIds.map((rowId) => {
              if (!props.ItemMenu) {
                return null;
              }
              return (
                <View w={props.colWidth} h={props.rowHeight} key={rowId} justify="center">
                  {props.ItemMenu(rowId)}
                </View>
              );
            })}
          </VStack>
        )}
        {!!props.Button && !!props.dataIds && (
          <VStack space="2.5" py="1" style={{ minWidth: props.colWidth || 50 }}>
            <View h={props.rowHeight} />
            {props.dataIds.map((rowId) => {
              if (!props.Button) {
                return null;
              }
              return (
                <View w={props.colWidth} h={props.rowHeight} key={rowId} justify="center">
                  {props.Button(rowId)}
                </View>
              );
            })}
          </VStack>
        )}
      </HStack>
    </ScrollView>
  );
};

export const cleanHeaders = (headers: string[], data: string[][]) => {
  const indexes = [] as number[];
  for (let i = 0; i < headers.length; i++) {
    for (let y = 0; y < data.length; y++) {
      if (data[y][i] !== '-') {
        indexes.push(i);
        break;
      }
    }
  }
  return indexes;
};
