import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import {
  HStack,
  SwitchStack,
  VStack,
} from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { Card } from '~/components/Card/Card';
import { SupportConversationActionToggles } from '~/components/SupportConversation/InputPanel/Conversation.ActionToggles';
import {
  AdvancedButton,
  SupportConversationAdvanced,
} from '~/components/SupportConversation/InputPanel/Conversation.Advanced';
import { ConversationPreviewImages } from '~/components/SupportConversation/InputPanel/Conversation.PreviewImages';
import { SupportConversationTextBox } from '~/components/SupportConversation/InputPanel/Conversation.TextBox';
import { SupportConversationInputPanelProps } from '~/components/SupportConversation/InputPanel/Conversation.utils';

import { TemplateButton } from './Conversation.Templates';

export const SupportConversationInputPanel = (
  props: SupportConversationInputPanelProps,
) => {
  if (props.isSimple) {
    return (
      <VStack
        p={'2.5'}
        space={isWeb ? '2.5' : '1'}
        style={[
          isNativeMobile && {
            maxHeight: 600,
            paddingBottom: 12,
          },
        ]}
      >
        <Divider />
        <ConversationPreviewImages />
        <View flex={isWeb ? 1 : undefined} style={{ alignSelf: 'stretch' }}>
          <SupportConversationTextBox {...props} />
        </View>
        <TemplateButton {...props} />
      </VStack>
    );
  }
  return (
    <Card style={[isWeb && { width: '100%' }]}>
      <VStack
        p={'2.5'}
        space={isWeb ? '2.5' : '1'}
        style={[
          isNativeMobile && {
            maxHeight: 600,
            paddingBottom: 12,
          },
        ]}
      >
        <ConversationPreviewImages />
        <HStack space={isWeb ? '2.5' : '1'}>
          <View flex={1}>
            <SupportConversationTextBox {...props} />
          </View>
          <SupportConversationActionToggles {...props} />
        </HStack>
        <SwitchStack space={isWeb ? '2.5' : '1'}>
          <View flex={isWeb ? 1 : undefined}>
            <TemplateButton {...props} />
          </View>
          <AdvancedButton />
        </SwitchStack>
        <SupportConversationAdvanced {...props} />
      </VStack>
    </Card>
  );
};
