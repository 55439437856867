import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { SupportConfigUpdateSupportTypeRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { supportConfigApi } from '~/screens/Configs/Support/supportConfig.api';
import { translate } from '~/utils/translation.utils';

const updateSupportTypeModalKey = 'UpdateSupportType-ModalKey';

export const useUpdateSupportTypeModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: ({ supportTypeId }: { supportTypeId: string }) => {
      openModal(<UpdateSupportTypeComponent supportTypeId={supportTypeId} />, {
        type: 'action-sheet-full',
        key: updateSupportTypeModalKey,
      });
    },
    close: () => {
      closeModal(updateSupportTypeModalKey);
    },
  };
};

const UpdateSupportTypeComponent = ({ supportTypeId }: { supportTypeId: string }) => {
  const formRef = useRef(new FormHandler());
  const { closeModal } = useModal();
  const { data, isLoading: isLoadingForm } =
    supportConfigApi.useUpdateSupportTypeFormConfigQuery(supportTypeId);
  const [updateSupportType, { isLoading: isUpdatingSupportType }] =
    supportConfigApi.useUpdateSupportTypeMutation();

  if (isLoadingForm) {
    return <LoadingIndicator />;
  }

  if (!data) {
    return null;
  }

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportConfig.updateSupportType')}
        onPress={() => {
          closeModal(updateSupportTypeModalKey);
        }}
      />
      <ModalScrollView>
        <FormContent isLoading={isLoadingForm} form={data.config} flex={1}>
          <FormBuilder data={data.config} form={formRef.current} />
        </FormContent>
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isUpdatingSupportType}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              SupportConfigUpdateSupportTypeRequest['supportType']
            >();

          if (valid) {
            const response = await updateSupportType({
              supportType: value,
              check: data.check,
            });
            handleResponse({
              response,
              onSuccess: () => {
                closeModal(updateSupportTypeModalKey);
              },
              onError: () => {
                closeModal(updateSupportTypeModalKey);
              },
            });
          }
        }}
        onCancel={() => closeModal(updateSupportTypeModalKey)}
      />
    </Modal.Container>
  );
};
