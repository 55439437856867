import { ImageUploadResponse } from '@naus-code/naus-client-types';

import { FormCoordinates } from './Form.Coordinates';
import { FormImage } from './Form.Image';

export const getPlugins = (props?: {
  onImageUpload?: (formData: FormData) => Promise<ImageUploadResponse>;
}) => {
  return {
    custom: [
      {
        id: 'imgUrl',
        component: (customProps) => (
          <FormImage {...customProps} onUpload={props?.onImageUpload} />
        ),
      },
      {
        id: 'coordinates',
        component: (customProps) => <FormCoordinates {...customProps} />,
      },
    ],
  };
};
