import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { SupportHistory } from '@naus-code/naus-client-types';
import React from 'react';
import { Pressable } from 'react-native';

import { LiveDateText } from '~/components/DateText';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { useHistoryItem } from '../Modals/HistoryItem';

export const SupportRequestHistoryHeader = ({
  supportRequestInfo,
}: {
  supportRequestInfo?: SupportRequestBackendClient;
}) => {
  return (
    <>
      <HStack
        px="2.5"
        py="3"
        bg="monochrome-extraLight"
        style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
      >
        <AppText.MiscTag flex={1} type="heading1">
          {`${translate('supportRequests.history')} (${
            supportRequestInfo?.supportHistory.length || '0'
          })`}
        </AppText.MiscTag>
      </HStack>
      <Divider bg="monochrome-light" style={{ maxWidth: isWeb ? 400 : undefined }} />
    </>
  );
};

export const SupportRequestHistoryContent = ({
  supportRequestInfo,
}: {
  supportRequestInfo?: SupportRequestBackendClient;
}) => {
  if (supportRequestInfo === undefined) {
    return <></>;
  }
  const { supportHistory } = supportRequestInfo;

  return (
    <VStack space="2.5" style={{ maxWidth: isWeb ? 400 : undefined }}>
      {supportHistory.map((history) => {
        return (
          <SupportRequestHistoryListItem
            key={history.timestamp + history.type}
            {...history}
          />
        );
      })}
    </VStack>
  );
};

const SupportRequestHistoryListItem = (history: SupportHistory) => {
  const { timestamp, type, description, adminUser, adminUserId } = history;
  const { open: openHistoryItem } = useHistoryItem();
  return (
    <Pressable
      onPress={() => {
        openHistoryItem(history);
      }}
    >
      <VStack space="2.5" px="3">
        <HStack>
          <Text.Body2Regular flex={1} numberOfLines={1} color="monochrome-dark">
            {type}
          </Text.Body2Regular>
          <LiveDateText
            dateTime={timestamp}
            formatOption="fullDateTime"
            type="small"
            color="monochrome-dark"
          />
        </HStack>
        <Text.Body2Regular style={{ paddingBottom: 5 }} color="monochrome-extraDark">
          {description}
        </Text.Body2Regular>
        {adminUser ? (
          <Text.ExtraSmall color="monochrome-mid">{`${adminUser}-${adminUserId}`}</Text.ExtraSmall>
        ) : (
          <></>
        )}
        <Divider />
      </VStack>
    </Pressable>
  );
};
