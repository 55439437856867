import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon/Icon';
import { IconsId } from '@droplet-tech-code/core-elements/module/ui/Icon/Icon.map';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { getScreenHeight } from '@droplet-tech-code/core-elements/module/utils/utils.ui';
import { useRef } from 'react';
import { Pressable, PressableProps, View as RNView } from 'react-native';

import { useSupportRequestByCustomer } from '~/components/Customer/Modals/SupportRequests';
import { useReservationFromProviderNavigateModal } from '~/components/Purchases/Modals/ReservationFromProviderNavigation';
import { useSupportRequestByPurchase } from '~/components/Purchases/Modals/SupportRequests';
import { useCreateVoucherModal } from '~/components/Voucher/Modals/CreateVoucherModal';
import { useVoucherByAdminReferenceModal } from '~/components/Voucher/Modals/VoucherByAdminRefModal';
import { useVoucherByEmailModal } from '~/components/Voucher/Modals/VoucherByEmailModal';
import { useVoucherByTinyIdModal } from '~/components/Voucher/Modals/VoucherByTinyId';
import { useAppNavigation } from '~/hooks/nav.hook';
import { isHoveredState, menuFloaterState } from '~/navigation/Navigation.MenuFloat';
import { ScreenGroupItem, ScreenItem } from '~/navigation/Navigation.types';
import { useBookingByCustomerIdInputModal } from '~/screens/Support/Bookings/BookingsByCustomerId/BookingByCustomerId.Utils';
import { useBookingByEmailInputModal } from '~/screens/Support/Bookings/BookingsByEmail/BookingByEmail.Utils';
import { useBookingByReferenceInputModal } from '~/screens/Support/Bookings/BookingsByRef/BookingByRef.Utils';
import { usePurchaseByCRCInputModal } from '~/screens/Support/Purchases/PurchasesByCompanyCode/PurchaseByCRC.Utils';
import { usePurchaseByCTNInputModal } from '~/screens/Support/Purchases/PurchasesByCompanyTicketNumber/PurchaseByCTN.Utils';
import { usePurchaseByCustomerIdInputModal } from '~/screens/Support/Purchases/PurchasesByCustomerId/PurchaseByCustomerId.Utils';
import { usePurchaseByDateInputModal } from '~/screens/Support/Purchases/PurchasesByDate/PurchaseByDate.Utils';
import { usePurchaseByEmailInputModal } from '~/screens/Support/Purchases/PurchasesByEmail/PurchaseByEmail.Utils';
import { usePurchaseByPRCInputModal } from '~/screens/Support/Purchases/PurchasesByProvider/PurchaseByPRC.Utils';
import { useSupportRequestByIdInputModal } from '~/screens/Support/Requests/SupportRequestById/SupportRequestById.Utils';

import { useCanRenderScreenLazy } from './Navigation.Drawer';

export interface DrawerMenuItemProps {
  name: string;
  label: string;
  onPress: () => void;
  onHoverIn?: PressableProps['onHoverIn'];
  onHoverOut?: PressableProps['onHoverOut'];
  icon: IconsId;
  dark?: boolean;
}

export const WebMenuItem = (props: DrawerMenuItemProps) => {
  return (
    <Pressable
      onPress={props.onPress}
      onHoverIn={props.onHoverIn}
      onHoverOut={props.onHoverOut}
    >
      {({ hovered }: ButtonInteractionState) => {
        return (
          <HStack space="1" align="center" p="2">
            <Icon
              icon={props.icon}
              // variant="plain"
              // shade={!hovered && props.dark ? "dark" : undefined}
              color={hovered ? 'primary' : props.dark ? 'primary' : 'monochrome'}
            />
            <Text.Small
              color={hovered ? 'primary-mid' : props.dark ? 'black' : 'monochrome-dark'}
            >
              {props.label}
            </Text.Small>
          </HStack>
        );
      }}
    </Pressable>
  );
};

export const NativeMenuItem = (props: DrawerMenuItemProps) => {
  return (
    <Pressable onPress={props.onPress}>
      <HStack space="3" px="6" py="2">
        <Icon icon={props.icon} color="monochrome-dark" />
        <Text.Body2Medium color="monochrome-dark">{props.name}</Text.Body2Medium>
      </HStack>
    </Pressable>
  );
};

export const useDrawerGoNavigator = () => {
  const purchaseByEmailModal = usePurchaseByEmailInputModal();
  const purchaseByCustomerIdModal = usePurchaseByCustomerIdInputModal();
  const purchaseByCRCModal = usePurchaseByCRCInputModal();
  const purchaseByPRCModal = usePurchaseByPRCInputModal();
  const purchaseByCTNModal = usePurchaseByCTNInputModal();
  const purchaseByDateModal = usePurchaseByDateInputModal();
  const bookingsByEmailModal = useBookingByEmailInputModal();
  const bookingsByCustomerIdModal = useBookingByCustomerIdInputModal();
  const bookingsByRefModal = useBookingByReferenceInputModal();
  const supportByCustomerModal = useSupportRequestByCustomer();
  const supportByPurchaseModal = useSupportRequestByPurchase();
  const reservationFromProviderModal = useReservationFromProviderNavigateModal();
  const voucherCreate = useCreateVoucherModal();
  const voucherByAdminRefModal = useVoucherByAdminReferenceModal();
  const voucherByTinyId = useVoucherByTinyIdModal();
  const voucherByEmailModal = useVoucherByEmailModal();
  const supportByIdModal = useSupportRequestByIdInputModal();

  const navigate = useAppNavigation();

  return ({ screenItem }: { screenItem: ScreenItem; groupScreen: 'Support' }) => {
    switch (screenItem.name) {
      case 'PurchasesByEmail':
      case 'PurchasesByEmailFinance':
        purchaseByEmailModal.open();
        return;
      case 'PurchasesByCustomerId':
      case 'PurchasesByCustomerIdFinance':
        purchaseByCustomerIdModal.open();
        return;
      case 'PurchasesByCRC':
        purchaseByCRCModal.open();
        return;
      case 'PurchasesByPRC':
        purchaseByPRCModal.open();
        return;
      case 'PurchasesByCTN':
        purchaseByCTNModal.open();
        return;
      case 'PurchasesByDate':
        purchaseByDateModal.open();
        return;
      case 'BookingsByEmail':
      case 'BookingsByEmailFinance':
        bookingsByEmailModal.open();
        return;
      case 'BookingsByCustomerId':
      case 'BookingsByCustomerIdFinance':
        bookingsByCustomerIdModal.open();
        return;
      case 'BookingsByRef':
      case 'BookingsByRefFinance':
        bookingsByRefModal.open();
        return;
      case 'CustomerSupportRequests':
        supportByCustomerModal.open();
        return;
      case 'PurchaseSupportRequests':
        supportByPurchaseModal.open();
        return;
      case 'ReservationFromProvider':
        reservationFromProviderModal.open();
        return;
      case 'CreateVoucher':
        voucherCreate.open();
        return;
      case 'VouchersByAdminRef':
        voucherByAdminRefModal.open();
        return;
      case 'VoucherByTinyId':
        voucherByTinyId.open();
        return;
      case 'VouchersByEmail':
        voucherByEmailModal.open();
        return;
      case 'SupportRequestById':
      case 'SupportRequestByIdFinance':
        supportByIdModal.open();
        return;
      default:
        navigate.navigate(screenItem.name as any);
        break;
    }
  };
};

export const DrawerMenuItem = (props: DrawerMenuItemProps) => {
  return isWeb ? <WebMenuItem {...props} /> : <NativeMenuItem {...props} />;
};

export const DrawerMenuGroup = (props: ScreenGroupItem) => {
  const { spacing } = useAppTheme();
  const viewRef = useRef<RNView | null>();
  const layoutRef = useRef<{ x: number; y: number }>({ x: 9, y: 0 });
  const [layout, setMenuLayout] = menuFloaterState.useGlobalState('layout');
  const { canRender } = useCanRenderScreenLazy();
  const onNavigate = useDrawerGoNavigator();
  const xOffset = -200;

  return (
    <Pressable
      ref={(e) => {
        viewRef.current = e;
      }}
      onLayout={() => {
        viewRef.current?.measure((pageX, pageY) => {
          layoutRef.current.x = pageX;
          layoutRef.current.y = pageY;
        });
      }}
      onHoverOut={() => {
        isHoveredState[2] = false;
        window.setTimeout(() => {
          if (isHoveredState.every((s) => !s)) {
            setMenuLayout({
              visible: false,
              layout: { ...layout, x: xOffset, y: 0 },
              children: null,
            });
          }
        }, 50);
      }}
      onHoverIn={() => {
        isHoveredState[2] = true;
        setMenuLayout({
          visible: true,
          layout: {
            ...layout,
            height: 1000,
            ...layoutRef.current,
            x: layoutRef.current.x + 60,
          },
          children: (
            <ScrollView
              style={{
                maxHeight: getScreenHeight() - spacing[6] * 2,
              }}
              contentContainerStyle={{
                flexGrow: 1,
                padding: spacing[3],
              }}
            >
              {props.children.map((c, ix) => {
                if (c.nonMenu) {
                  return null;
                }
                if (!canRender(c)) {
                  return null;
                }
                return (
                  <VStack key={ix} space="2" style={{ minWidth: 300 }}>
                    <VStack mt="3">
                      <Text.Small color="monochrome-mid">{c.groupLabel}</Text.Small>
                    </VStack>
                    <Divider />

                    <VStack space="3">
                      {c.list.map((listItem, iy) => {
                        if (listItem.nonMenu) {
                          return null;
                        }

                        return (
                          <WebMenuItem
                            key={iy}
                            {...listItem}
                            dark
                            onPress={() => {
                              isHoveredState[0] = false;
                              isHoveredState[1] = false;
                              isHoveredState[2] = false;
                              isHoveredState[3] = false;

                              setMenuLayout({
                                visible: false,
                                layout: {
                                  ...layout,
                                  x: xOffset,
                                  y: 0,
                                },
                                children: null,
                              });

                              onNavigate({
                                screenItem: listItem,
                                groupScreen: props.name as any,
                              });
                            }}
                            onHoverIn={() => {
                              isHoveredState[3] = true;
                            }}
                            onHoverOut={() => {
                              isHoveredState[3] = false;
                              window.setTimeout(() => {
                                if (isHoveredState.every((s) => !s)) {
                                  setMenuLayout({
                                    visible: false,
                                    layout: { ...layout, x: xOffset, y: 0 },
                                    children: null,
                                  });
                                }
                              }, 50);
                            }}
                          />
                        );
                      })}
                    </VStack>
                  </VStack>
                );
              })}
            </ScrollView>
          ),
        });
      }}
    >
      {({ hovered }: ButtonInteractionState) => {
        return (
          <View
            style={{ position: 'relative' }}
            bg={hovered ? 'primary-base' : undefined}
          >
            <HStack space="1" align="center" p="2">
              <Icon icon={props.icon} color={hovered ? 'primary' : 'monochrome'} />
              <Text.Small color={hovered ? 'primary-mid' : 'monochrome-dark'}>
                {props.name}
              </Text.Small>

              <Icon icon="chevron-right" color="monochrome-extraDark" />
            </HStack>
          </View>
        );
      }}
    </Pressable>
  );
};
