import { BaseInputProps } from '@droplet-tech-code/core-elements/module/ui/Inputs/BaseInputs';
import { FormInput } from '@droplet-tech-code/core-elements/module/ui/Inputs/FormInputs/FormInput';
import { TextInputProps } from '@droplet-tech-code/core-elements/module/ui/Inputs/TextInput/TextInput';
import { useTextForm } from '@droplet-tech-code/core-elements/module/ui/Inputs/TextInput/TextInput.hooks';
import {
  SupportConversation,
  SupportConversationHandler,
  SupportConversationHandlerLink,
} from '@naus-code/naus-client-types';
import { Linking, TextInputProps as RNTextInputProps } from 'react-native';

import { DOMAIN_NAME } from '~/utils/constants.utils';

const getHowToPage = (slug: string) => {
  return `${DOMAIN_NAME}help-centre/how-to/${slug}`;
};

const getOpenFaq = (groupId: string, slug: string) => {
  return `${DOMAIN_NAME}help-centre/faq/${groupId}#${slug}`;
};

export const isHandlerMessage = (
  conversation: SupportConversation,
): conversation is SupportConversationHandler => {
  return conversation.type === 'handler';
};

export const openSupportLink = async (link: SupportConversationHandlerLink) => {
  switch (link.type) {
    case 'faq': {
      await Linking.openURL(getOpenFaq(link.groupId, link.id));
      break;
    }

    case 'how-to': {
      await Linking.openURL(getHowToPage(link.id));
      break;
    }

    case 'external':
    default: {
      await Linking.openURL(link.link);
      break;
    }
  }
};

export const ChatInput = ({
  secureTextEntry,
  iconEnd,
  iconStart,
  textInputProps,
  onChangeText,
  onChangeForm,
  ...props
}: TextInputProps & {
  onChangeForm: () => void;
  textInputProps?: RNTextInputProps;
  secureTextEntry?: BaseInputProps['secureTextEntry'];
  iconEnd?: BaseInputProps['iconEnd'];
  iconStart?: BaseInputProps['iconStart'];
  onChangeText?: (txt: string) => void;
}) => {
  const {
    value,
    setValue,
    isValid,
    feedback,
    error,
    // allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
    config,
  } = useTextForm(props);
  const multiline = props.config.multiline;
  return (
    <FormInput
      noFeedback={noFeedback}
      feedbackOnlyWithError={config.feedbackOnlyWithError}
      autoCorrect={false}
      autoCapitalize="none"
      value={value}
      feedback={feedback}
      error={error}
      disabled={disabled}
      label={label}
      optional={optional}
      readonly={readonly}
      onChangeText={(txt) => {
        setValue(txt);
        onChangeForm();
        onChangeText?.(txt);
      }}
      onBlur={() => {
        isValid({ showFeedback: true, onBlur: true });
        // formProps.onBlur?.(e);
      }}
      numberOfLines={multiline}
      multiline={typeof multiline === 'number'}
      iconStart={iconStart}
      iconEnd={iconEnd}
      secureTextEntry={secureTextEntry}
      {...textInputProps}
    />
  );
};

class ChatCache {
  cache = {};
  addToCache(supportId, value) {
    this.cache[supportId] = value;
  }
  delete(supportId) {
    this.cache[supportId] = undefined;
  }
}

export const chatCache = new ChatCache();
