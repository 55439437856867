import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { ContentFeedbackHandler } from '@droplet-tech-code/core-elements/module/ui/Feedback/Feedback.Handler';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';

import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { translate } from '~/utils/translation.utils';

const ModalKey = 'seeCompanyTerms';

const PurchaseCompanyTerms = ({ purchaseId }: { purchaseId: string }) => {
  const { data, isLoading } =
    purchaseActionApi.useGetPurchaseCompanyTermsQuery(purchaseId);

  const { closeModal } = useModal();
  return (
    <Modal.Container>
      <ContentFeedbackHandler
        data={data}
        dataEmpty={!data}
        feedback={translate('common.noDataFound')}
        isLoading={isLoading}
      >
        <Modal.Header
          title={translate('purchases.purchaseTool.seeCompanyTerms')}
          onPress={() => {
            closeModal(ModalKey);
          }}
          divider
        />
        {data ? (
          <ModalScrollView>
            <VStack space="4" flex={1}>
              <Text.Body2Regular>
                {translate('purchases.purchaseTool.updated', {
                  date: data.terms.lastUpdated,
                })}
              </Text.Body2Regular>

              {data.terms.termItems.map((item, ix) => {
                const langKeys = Object.keys(item.text);

                if (!langKeys.length) {
                  return null;
                }

                const text = item.text['en'] || item.text[langKeys[0]];

                return (
                  <VStack key={ix}>
                    <View p="2.5">
                      <Text.Body2Medium>
                        {item.categoryId.slice(0, 1).toLocaleUpperCase() +
                          item.categoryId.slice(1)}
                      </Text.Body2Medium>
                    </View>
                    <Divider thickness={2} mt="0.5" />
                    <View p="2.5">
                      <Text.Body2Regular color="monochrome-extraDark">
                        {text}
                      </Text.Body2Regular>
                    </View>
                  </VStack>
                );
              })}
            </VStack>
          </ModalScrollView>
        ) : null}
      </ContentFeedbackHandler>
    </Modal.Container>
  );
};

export const usePurchaseCompanyTerms = () => {
  const { closeModal, openModal } = useModal();

  return {
    open: (purchaseId: string) => {
      openModal(<PurchaseCompanyTerms purchaseId={purchaseId} />, {
        key: ModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
