import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  CompanyDataClientAddMissingDiscountListRequest,
  CompanyDataClientAddMissingExtraListRequest,
  CompanyDataClientAddMissingPassengerListRequest,
  CompanyDataClientAddMissingVehicleListRequest,
  CompanyDataClientMissingDiscountListResponse,
  CompanyDataClientMissingExtraListResponse,
  CompanyDataClientMissingLocationListRequest,
  CompanyDataClientMissingLocationListResponse,
  CompanyDataClientMissingPassengerListResponse,
  CompanyDataClientMissingVehicleListResponse,
  CompanyDataClientMissingVesselListRequest,
  CompanyDataClientMissingVesselListResponse,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'config/company-data';
export const companyDataConfigApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    //--------------------VESSELS-----------------//
    getMissingVessels: builder.query<CompanyDataClientMissingVesselListResponse, void>({
      query: () => ({
        url: `${base_url}/missing-vessels/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingVessels'],
    }),

    getMissingVesselsForm: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/missing-vessels/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingVesselsForm'],
    }),

    addMissingVessels: builder.mutation<void, CompanyDataClientMissingVesselListRequest>({
      query: (data) => ({
        url: `${base_url}/add-vessels/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getMissingVessels', 'getFerryCompanyConfig'],
    }),
    //--------------------PASSENGERS-----------------//
    getMissingPassengers: builder.query<
      CompanyDataClientMissingPassengerListResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/missing-passenger-tickets/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingPassengers'],
    }),

    addMissingPassengers: builder.mutation<
      void,
      CompanyDataClientAddMissingPassengerListRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-passenger-tickets/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getMissingPassengers', 'getFerryCompanyConfig'],
    }),
    //--------------------VEHICLES-----------------//
    getMissingVehicles: builder.query<CompanyDataClientMissingVehicleListResponse, void>({
      query: () => ({
        url: `${base_url}/missing-vehicle-tickets/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingVehicles'],
    }),

    getMissingVehiclesForm: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/missing-vehicle-tickets/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingVehiclesForm'],
    }),

    addMissingVehicles: builder.mutation<
      void,
      CompanyDataClientAddMissingVehicleListRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-vehicle-tickets/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'addMissingVehicles',
        'getMissingVehicles',
        'getFerryCompanyConfig',
      ],
    }),
    //--------------------EXTRAS-----------------//
    getMissingExtras: builder.query<CompanyDataClientMissingExtraListResponse, void>({
      query: () => ({
        url: `${base_url}/missing-extra-tickets/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingExtras'],
    }),

    getMissingExtrasForm: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/missing-extra-tickets/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingExtrasForm'],
    }),

    addMissingExtras: builder.mutation<void, CompanyDataClientAddMissingExtraListRequest>(
      {
        query: (data) => ({
          url: `${base_url}/add-extra-tickets/`,
          method: 'POST',
          data,
        }),
        invalidatesTags: [
          'addMissingExtras',
          'getMissingExtras',
          'getFerryCompanyConfig',
        ],
      },
    ),
    //--------------------LOCATIONS-----------------//
    getMissingLocations: builder.query<
      CompanyDataClientMissingLocationListResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/missing-locations/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingLocations'],
    }),

    getMissingLocationsForm: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/missing-locations/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingLocationsForm'],
    }),

    addMissingLocations: builder.mutation<
      void,
      CompanyDataClientMissingLocationListRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-locations/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'addMissingLocations',
        'getMissingLocations',
        'getFerryCompanyConfig',
      ],
    }),
    //--------------------DISCOUNTS-----------------//
    getMissingDiscounts: builder.query<
      CompanyDataClientMissingDiscountListResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/missing-discounts/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingDiscounts'],
    }),

    getMissingDiscountsForm: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/missing-discounts/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getMissingDiscountsForm'],
    }),

    addMissingDiscounts: builder.mutation<
      void,
      CompanyDataClientAddMissingDiscountListRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-discounts/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'addMissingDiscounts',
        'getMissingDiscounts',
        'getFerryCompanyConfig',
      ],
    }),
  }),
});
