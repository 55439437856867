import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { useRef } from 'react';

export const ChangeParamaModalKey = 'ChangeParamaModal';

export const useChangeParam = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: ({
      title,
      subtitle,
      initialValue,
      label,
      type,
      onConfirm,
    }: {
      title: string;
      subtitle: string;
      initialValue?: string;
      label: string;
      type?: 'email';
      onConfirm: (param: string) => void;
    }) => {
      openModal(
        <ChangeParamModal
          title={title}
          label={label}
          subtitle={subtitle}
          initialValue={initialValue}
          type={type}
          onConfirm={onConfirm}
        />,
        {
          type: 'action-sheet',
          key: ChangeParamaModalKey,
        },
      );
    },
    close: () => {
      closeModal(ChangeParamaModalKey);
    },
  };
};

const ChangeParamModal = ({
  title,
  subtitle,
  label,
  type,
  initialValue,
  onConfirm,
}: {
  title: string;
  subtitle: string;
  type?: 'email';
  initialValue?: string;
  label: string;
  onConfirm: (param: string) => void;
}) => {
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();

  const entryType = type || 'text';
  return (
    <Modal.Container>
      <Modal.Header
        title={title}
        subtitle={subtitle}
        onPress={() => {
          closeModal(ChangeParamaModalKey);
        }}
      />
      <Modal.Body>
        <FormBuilder
          data={[
            {
              items: [
                {
                  type: entryType,
                  label,
                  id: 'param',
                  initialValue: initialValue,
                },
              ],
            },
          ]}
          form={form.current}
        />
      </Modal.Body>
      <Modal.Footer
        onConfirm={() => {
          const { valid, value } = form.current.getValue<{ param: string }>();
          if (valid) {
            onConfirm(value.param);
            closeModal(ChangeParamaModalKey);
          }
        }}
        onCancel={() => {
          closeModal(ChangeParamaModalKey);
        }}
      />
    </Modal.Container>
  );
};
