import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';

import { AuthLogo } from '~/assets';
import { DevUrlInputModal, DevUrlModalKey } from '~/components/DevMode/DevMode.Input';
import { Globals } from '~/components/Globals/Globals';
import { PageHeader } from '~/components/Page/Page.Header';
import { useAppNavigation } from '~/hooks/nav.hook';
import { useDrawerGoNavigator } from '~/navigation/Navigation.Common';
import { ScreenPropSubHome } from '~/navigation/Navigation.types';
import { APP_URLS, APP_VERSION } from '~/utils/constants.utils';
import { firebase } from '~/utils/firebase';

export const NavigationMenu = () => {
  const navigation = useAppNavigation();
  const { openModal } = useModal();
  return (
    <ScrollView
      bg="white"
      flex={1}
      contentContainerStyle={{ flexGrow: 1, paddingTop: 24 }}
    >
      <View justify="center" align="center" mb="3">
        <AuthLogo width={120} height={120} />
      </View>

      <VStack mb="3" justify="center" align="center" space="3">
        <Text.Small align="center" color="monochrome-mid">
          v{APP_VERSION}
        </Text.Small>

        <Text.Small
          onPress={() => {
            openModal(<DevUrlInputModal />, {
              type: 'action-sheet',
              key: DevUrlModalKey,
            });
          }}
          align="center"
          color="monochrome-mid"
        >
          {APP_URLS.BASE}
        </Text.Small>
      </VStack>

      <VStack flex={1} bg="white" space="3" p="3">
        {Globals.getScreenDictionary().map((screen, ix) => {
          if (screen.nonMenu) {
            return null;
          }

          return (
            <View key={ix}>
              <Button
                variant="outline"
                color="primary"
                iconStart={screen.icon}
                onPress={() => {
                  navigation.navigate('SubHome', { name: screen.name as any });
                }}
              >
                {screen.name}
              </Button>
            </View>
          );
        })}
      </VStack>
      <VStack p="3">
        <Button
          variant="contained"
          color="primary"
          iconEnd="sign-out"
          onPress={async () => {
            firebase.signOut();
          }}
        >
          Log out
        </Button>
      </VStack>
    </ScrollView>
  );
};

export const NavigationSubMenu = (props: ScreenPropSubHome) => {
  const foundScreen = Globals.getScreenDictionary().find(
    (item) => item.name === props.route.params.name,
  );

  const onNavigate = useDrawerGoNavigator();

  if (!foundScreen || !foundScreen.children) {
    return <></>;
  }

  return (
    <VStack flex={1} bg="white">
      <PageHeader title={foundScreen.label} withBack />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <VStack flex={1} bg="white" space="3" p="3">
          {foundScreen.children.map((child, ix) => {
            return (
              <VStack p="3" key={ix} space="3">
                <VStack space="1">
                  <Text.Body2Medium color="monochrome-dark">
                    {child.groupLabel}
                  </Text.Body2Medium>
                  <Divider />
                </VStack>

                <VStack space="3">
                  {child.list.map((item, iy) => {
                    if (item.nonMenu) {
                      return null;
                    }

                    return (
                      <Button
                        key={iy}
                        variant="outline"
                        color="primary"
                        onPress={() => {
                          onNavigate({
                            screenItem: item,
                            groupScreen: child.groupName as any,
                          });
                        }}
                      >
                        {item.label}
                      </Button>
                    );
                  })}
                </VStack>
              </VStack>
            );
          })}
        </VStack>
      </ScrollView>
    </VStack>
  );
};
