import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { getFullLang, translate } from '~/utils/translation.utils';

export const ChangeLangKey = 'AddModal-ChangeLang';
export const ChangeLangModal = ({
  availableLangs,
  supportRequestInfo,
  currentLang,
  check,
}: {
  availableLangs: string[];
  supportRequestInfo: SupportRequestBackendClient;
  currentLang: string;
  check: string;
}) => {
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();

  const supportId = supportRequestInfo._id;
  const [changeLang, { isLoading: isAddingNote }] =
    supportRequestActionsApi.useChangeSupportLangMutation();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportRequests.changeLang')}
        subtitle={translate('supportRequests.chooseLang')}
        divider
        onPress={() => {
          closeModal(ChangeLangKey);
        }}
      />
      <Modal.Body p="0">
        <FormBuilder
          data={[
            {
              items: [
                {
                  type: 'single-select',
                  list: availableLangs.map((lang) => {
                    return { label: getFullLang(lang), value: lang };
                  }),
                  initialValue: getFullLang(currentLang),
                  label: translate('supportRequests.lang'),
                  id: 'lang',
                },
              ],
            },
          ]}
          form={form.current}
        />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isAddingNote}
        onConfirm={async () => {
          const { valid, value } = form.current.getValue<{ lang: string }>();
          if (valid) {
            const response = await changeLang({ ...value, supportId, check });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(ChangeLangKey);
              },
            });
            closeModal(ChangeLangKey);
          }
        }}
        onCancel={() => {
          closeModal(ChangeLangKey);
        }}
      />
    </Modal.Container>
  );
};

export const useChangeLang = () => {
  const { openModal } = useModal();

  return {
    open: (
      availableLangs: string[],
      supportRequestInfo: SupportRequestBackendClient,
      currentLang: string,
      check: string,
    ) => {
      openModal(
        <ChangeLangModal
          availableLangs={availableLangs}
          supportRequestInfo={supportRequestInfo}
          currentLang={currentLang}
          check={check}
        />,
        {
          key: ChangeLangKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};
