import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { GetCustomerResponse } from '@naus-code/naus-admin-types';

import { Globals } from '~/components/Globals/Globals';
import { useIsSystemUser } from '~/hooks/auth.hook';
import { translate } from '~/utils/translation.utils';

import { ModalScrollView } from '../Modals/Modal.utils';
import { Pill } from '../Pill/Pill';
import { CustomerModalKey } from './Customer.Modal';
import { CustomerActions } from './CustomerActions';
import { CustomerCard } from './CustomerCard';
import { useUpdateCustomerModal } from './Modals/UpdateCustomerModal';
import { CustomerDeletedPill } from './utils/CusotmerInfo.utils';
import { customerApi } from './utils/Customer.api';

export const CustomerItem = ({
  customerId,
  supportEmail,
}: {
  customerId: string;
  supportEmail: string;
}) => {
  const { data } = customerApi.useGetCustomerQuery(customerId);
  const { closeModal } = useModal();
  if (data === undefined) {
    return null;
  }
  const { customer } = data;

  return (
    <Modal.Container w={isWeb ? 700 : undefined} h={isWeb ? 600 : undefined}>
      <HStack pr="2.5">
        <View flex={1}>
          <Modal.Header
            title={translate('customer.customerInfo')}
            children={<NiseaUserPill customer={customer} />}
            onPress={() => closeModal(CustomerModalKey)}
          />
        </View>
        <CustomerAccess customer={customer} />
      </HStack>
      <Divider />
      <ModalScrollView>
        <CustomerCard customer={customer} />
        <CustomerActions customer={customer} supportEmail={supportEmail} />
      </ModalScrollView>
    </Modal.Container>
  );
};

const NiseaUserPill = ({ customer }: { customer: GetCustomerResponse['customer'] }) => {
  const isSystemUser = useIsSystemUser();

  if (!isSystemUser || !customer.isNisea) {
    return null;
  }

  return (
    <Pill color="primary">
      <Text.Small color="primary">{'NISEA-USER '}</Text.Small>
    </Pill>
  );
};

const CustomerAccess = ({ customer }: { customer: GetCustomerResponse['customer'] }) => {
  const { open: openUpdateCustomerModal } = useUpdateCustomerModal();

  if (customer.deleted) {
    return <CustomerDeletedPill customer={customer} />;
  }
  return (
    <IconButton
      icon="edit"
      color="monochrome"
      onPress={() => {
        openUpdateCustomerModal({ customerId: customer._id });
      }}
    />
  );
};

Globals.CustomerItem = CustomerItem;
