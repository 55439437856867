import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';

export const Card = (props: CardProps) => {
  const themeShading = useAppTheme().shading;
  const shading = props.shadingOff ? undefined : themeShading.regular;

  return (
    <View bg={props.bg || 'white'} br={10} {...props} style={[shading, props.style]} />
  );
};

interface CardProps extends AppViewProps {
  shadingOff?: boolean;
}
