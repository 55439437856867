import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { useCallback, useState } from 'react';

import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { VoucherModalKey } from '~/components/Voucher/Voucher.utils';
import { translate } from '~/utils/translation.utils';

import { CustomerVoucherList } from '../CustomerVoucher.List';
import { VoucherItem } from '../Voucher.Item';

export const useVoucherModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (customerId: string, customerEmail?: string) => {
      openModal(
        <VoucherModalComponent customerId={customerId} customerEmail={customerEmail} />,
        {
          key: VoucherModalKey,
          type: 'action-sheet-full',
        },
      );
    },
    close: () => {
      closeModal(VoucherModalKey);
    },
  };
};

const VoucherModalComponent = ({
  customerId,
  customerEmail,
}: {
  customerId: string;
  customerEmail?: string;
}) => {
  const [voucherId, setVoucherId] = useState<string | undefined>(undefined);
  const onClose = useCallback(() => {
    if (voucherId) {
      setVoucherId(undefined);
    } else {
      closeModal(VoucherModalKey);
    }
  }, [voucherId]);
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('voucher.customerVouchers')}
        divider
        onPress={() => {
          closeModal(VoucherModalKey);
        }}
      />
      <ModalScrollView>
        {voucherId ? (
          <VoucherItem voucherId={voucherId} customerEmail={customerEmail} />
        ) : (
          <CustomerVoucherList
            customerId={customerId}
            onPress={(selectedId) => setVoucherId(selectedId)}
          />
        )}
      </ModalScrollView>
      <Modal.Footer divider onCancel={onClose} />
    </Modal.Container>
  );
};
