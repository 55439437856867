import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { GetBookingResponse } from '@naus-code/naus-admin-types';
import { useEffect } from 'react';

import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { purchaseActionManager } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Common/ActionPanel.utils';
import { ModifyPanelProps } from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Modify/Modify.utils';

export const PaymentSessionSelector = (props: ModifyPanelProps) => {
  const { data: bookingRes, isLoading: isLoadingBookingData } =
    bookingApi.useGetBookingQuery(props.bookingId ?? '');

  return (
    <DelayRender isLoading={isLoadingBookingData}>
      {bookingRes ? <SessionIdSelector bookingRes={bookingRes} /> : null}
    </DelayRender>
  );
};

const SessionIdSelector = ({ bookingRes }: { bookingRes: GetBookingResponse }) => {
  const [paymentSessionIds, updatePaymentSessionIds] =
    purchaseActionManager.state.useGlobalState('paymentSessionIds');

  useEffect(() => {
    const firstItem = bookingRes.booking.paymentSessions[0];

    if (firstItem && bookingRes.booking.paymentSessions.length === 1) {
      updatePaymentSessionIds([firstItem.id]);
    }
  }, []);

  return (
    <SelectInputBase
      noFeedback
      feedback=""
      setValues={(newValues) => {
        updatePaymentSessionIds(newValues ?? []);
      }}
      type="multi-select"
      options={bookingRes.booking.paymentSessions.map((session) => {
        return {
          label: `${session.type.toLocaleUpperCase()}, ${getPriceWithSymbol(
            session.paymentSummary.total +
              (session.paymentSummary.voucher?.redeemValue ?? 0),
            session.paymentSummary.currency,
          )} on ${dayjs(session.lastUpdated).format('YY-MMM-DD HH:mm')}`,
          value: session.id,
        };
      })}
      values={paymentSessionIds ?? []}
    />
  );
};
