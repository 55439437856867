import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { useCallback } from 'react';

import { FormModalKey, useFormModal } from '~/components/Modals/FormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { purchaseApi } from '../Purchases.api';

export const usePurchaseByCTNInputModal = () => {
  const { open: openForm, close: closeForm } = useFormModal();
  const { closeModal } = useModal();

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } =
      purchaseApi.useGetPurchasesByCompanyTicketNumberConfigQuery();
    return { data: { config }, isLoading };
  }, []);

  const navigation = useAppNavigation();

  const onConfirm = ({ value }) => {
    closeModal(FormModalKey);
    navigation.navigate('PurchasesByCTN', { ...value, companyId: value.companyCode });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('purchases.purchasesForCTN'),
        useFormHookHandler,
        onConfirm,
        loadingAction: false,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
