import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { ScrollView } from '@droplet-tech-code/core-elements/module/ui/View';
import { GetVesselResponse } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { PageContainer } from '~/components/Page/Page.Container';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { VesselEdit } from '~/screens/Vessels/Item/Vessel.Edit';
import { VesselPreviews } from '~/screens/Vessels/Item/Vessel.Preview';

import { vesselApi } from '../Vessels.api';
import { VesselItemHeader } from './Vessel.ItemHeader';

export interface VesselItemProps {
  onClose: () => void;
  id: string;
}

export const VesselItem = (props: VesselItemProps) => {
  const { data, refetch } = vesselApi.useGetVesselQuery(props.id);

  return (
    <>
      {data ? (
        <VesselItemComponent data={data} onClose={props.onClose} refresh={refetch} />
      ) : (
        <></>
      )}
    </>
  );
};

const VesselItemComponent = ({
  data,
  onClose,
  refresh,
}: {
  data: GetVesselResponse;
  onClose: () => void;
  refresh: () => void;
}) => {
  const { shading } = useAppTheme();
  const formRef = useRef(new FormHandler());
  const vessel = data.vessel;
  return (
    <>
      <VesselItemHeader
        vessel={vessel}
        onClose={onClose}
        formRef={formRef}
        refresh={refresh}
      />

      <PageContainer bg="monochrome-base">
        <SwitchStack
          flex={1}
          space="2.5"
          style={{ alignSelf: 'stretch', alignItems: 'flex-start' }}
        >
          <VStack p="3" style={{ height: '100%' }}>
            <ScrollView
              style={{
                borderRadius: 12,
                ...shading.light,
              }}
              contentContainerStyle={{
                flexGrow: 1,
                backgroundColor: 'white',
                padding: 12,
              }}
            >
              <VesselEdit vesselId={vessel.id} vessel={vessel} formRef={formRef} />
            </ScrollView>
          </VStack>
          <VStack p="3" style={{ height: '100%' }}>
            <VesselPreviews data={data} />
          </VStack>
        </SwitchStack>
      </PageContainer>
    </>
  );
};
