import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { IconCircle } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isWeb,
  onlyUnique,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { MutableRefObject } from 'react';
import { Pressable } from 'react-native';

import { useTemplateAnswerStore } from '~/components/TemplateAnswers/TemplateAnswers.utils';
import { supportRequestActionsApi } from '~/screens/Support/Requests/supportRequestActions.api';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { getFullLang, translate } from '~/utils/translation.utils';

import { ModalScrollView } from '../Modals/Modal.utils';
import { TemplateAnswersBody } from './TemplateAnswersBody';

export const InsertTemplateAnswerKey = 'AddModal-InsertTemplateAnswer';
export const InsertTemplateAnswerModal = ({
  supportRequestInfo,
  textRef,
  formRef,
}: {
  formRef: MutableRefObject<FormHandler>;
  textRef: MutableRefObject<FormHandler>;
  supportRequestInfo: SupportRequestBackendClient;
}) => {
  const { closeModal } = useModal();

  const lang = supportRequestInfo.lang;
  const supportId = supportRequestInfo._id;

  const [addTemplateAnswer] =
    supportRequestActionsApi.useFillDynamicTemplateAnswerMutation();

  return (
    <Modal.Container>
      <VStack style={[isWeb && { maxWidth: screen.availWidth * 0.5 }]} flex={1}>
        <Modal.Header
          title={translate('supportRequests.templateAnswers', {
            locale: getFullLang(lang),
          })}
          divider
          onPress={() => {
            closeModal(InsertTemplateAnswerKey);
          }}
        >
          <ViewTemplateAnswerIn supportId={supportId} />
        </Modal.Header>
        <ModalScrollView style={[isWeb && { height: screen.availHeight * 0.7 }]}>
          <TemplateAnswersBody
            supportLang={lang}
            textRef={textRef}
            formRef={formRef}
            addDynamicTemplateAnswer={async (templateAnswerId: string) => {
              return addTemplateAnswer({ supportId, templateAnswerId, lang });
            }}
          />
        </ModalScrollView>
      </VStack>
    </Modal.Container>
  );
};

export const useInsertTemplateAnswer = () => {
  const { openModal } = useModal();

  return {
    openModal: (
      supportRequestInfo: SupportRequestBackendClient,
      textRef: MutableRefObject<FormHandler>,
      formRef: MutableRefObject<FormHandler>,
    ) => {
      openModal(
        <InsertTemplateAnswerModal
          supportRequestInfo={supportRequestInfo}
          textRef={textRef}
          formRef={formRef}
        />,
        {
          key: InsertTemplateAnswerKey,
          type: 'action-sheet-full',
        },
      );
    },
  };
};

const ViewTemplateAnswerIn = ({ supportId }: { supportId: string }) => {
  const { data, isLoading } = supportRequestActionsApi.useGetTemplateAnswersQuery();
  const { data: supportReqRes, isLoading: loadingSupport } =
    supportRequestApi.useGetSupportRequestQuery(supportId);

  const viewLocaleFromDropdown = useTemplateAnswerStore((s) => s.viewLocaleFromDropdown);
  const setViewAsHover = useTemplateAnswerStore((s) => s.setViewAsHover);
  const setViewAsDropdown = useTemplateAnswerStore((s) => s.setViewAsDropdown);

  const { palette } = useAppTheme();

  if (isLoading || loadingSupport || !supportReqRes) {
    return <LoadingIndicator />;
  }

  const availableLanguages =
    data?.templateAnswers
      .map((ta) => {
        return ta.answers.map((a) => Object.keys(a.text)).flatMap((a) => a);
      })
      .flatMap((a) => a)
      .filter(onlyUnique)
      .map((locale) => {
        return {
          value: locale,
          label: getFullLang(locale),
        };
      }) ?? [];

  const lang = supportReqRes.supportRequest.lang;

  return (
    <HStack space="6" align="center">
      {viewLocaleFromDropdown !== 'en' && (
        <Pressable
          onHoverIn={() => setViewAsHover('en')}
          onHoverOut={() => setViewAsHover('')}
        >
          {({ hovered }: ButtonInteractionState) => {
            return (
              <VStack align="center" justify="center" space="1.5">
                <Text.Small style={{ opacity: 0 }} color="monochrome-mid">
                  {translate('supportRequests.preview')}
                </Text.Small>
                <IconCircle
                  icon="eye"
                  color="warning"
                  outerStyle={[
                    hovered && { backgroundColor: palette.warning.extraLight },
                  ]}
                />
                <View />
              </VStack>
            );
          }}
        </Pressable>
      )}

      <SelectInputBase
        values={[viewLocaleFromDropdown || lang]}
        setValues={(nv) => {
          const value = nv?.[0];
          if (value) {
            setViewAsDropdown(value);
          }
        }}
        options={availableLanguages}
        type="single-select"
        noFeedback
        isClearable={false}
        isSearchable={false}
        feedback=""
        label={translate('supportRequests.viewIn')}
      />
    </HStack>
  );
};
