import { Button, IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { EmailInput } from '@droplet-tech-code/core-elements/module/ui/Inputs/EmailInput/EmailInput';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { TextInput } from '@droplet-tech-code/core-elements/module/ui/Inputs/TextInput/TextInput';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast/Toast';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { firebase } from '~/utils/firebase';

import { LinkText } from '../../components/LinkText';
import { rootApi } from '../../store/redux.utils';
import { translate } from '../../utils/translation.utils';
import { resetUserPassword, signInWithEmailAndPassword } from './Auth.utils';

const USERNAME_KEY = 'USERNAME';
const PASSWORD_KEY = 'PASSWORD';
export const AuthForm = () => {
  const formHandler = useRef(new FormHandler()).current;
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [visiblePass, setVisiblePass] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const login = async () => {
    const { valid, value } = formHandler.getValue<{
      [USERNAME_KEY]: string;
      [PASSWORD_KEY]: string;
    }>({ showFeedback: true, validate: true });
    if (!valid) {
      return;
    }

    setLoading(true);
    const email = value[USERNAME_KEY];
    const password = value[PASSWORD_KEY];
    try {
      const userCredential = await signInWithEmailAndPassword(
        email.trim(),
        password.trim(),
      );
      if (!userCredential) {
        throw { message: 'Could not sign in' };
      }

      const userId = userCredential.user?.uid;
      if (!userId) {
        throw { message: 'No user id detected' };
      }
    } catch (error: any) {
      showToast(error?.message || translate('auth.errorLog'));
      await firebase.signOut();
    }
    if (mounted.current) {
      setLoading(false);
    }
  };

  return (
    <VStack space="4" style={{ position: 'relative' }}>
      <EmailInput
        form={formHandler}
        config={{
          id: USERNAME_KEY,
          label: translate('auth.email'),
          type: 'email',
        }}
      />
      <TextInput
        form={formHandler}
        config={{
          id: PASSWORD_KEY,
          label: translate('auth.password'),
          type: 'text',
        }}
        secureTextEntry={!visiblePass}
        iconEnd={
          <IconButton
            onPress={() => {
              setVisiblePass(!visiblePass);
            }}
            icon={visiblePass ? 'eye-disable' : 'eye'}
          />
        }
      />

      <HStack space="2.5">
        <LinkText
          onPress={async () => {
            try {
              const values = formHandler.getSingleValue({
                id: USERNAME_KEY,
                showFeedback: true,
                validate: true,
              });
              if (!values) {
                showToast(translate('auth.enterEmail'));
                return;
              }
              const email = values[USERNAME_KEY];
              await resetUserPassword(email);
              showToast(translate('auth.resetLink'));
            } catch (error: any) {
              showToast(translate('auth.noResetLink'));
            }
          }}
        >
          {translate('auth.forgotPassword')}
        </LinkText>
        <View flex={1} />

        {/* <DevModeToggle /> */}
      </HStack>
      <View mt="2.5">
        <Button
          onPress={async () => {
            if (!loading) {
              dispatch(rootApi.util.resetApiState());
              await login();
            }
          }}
          isLoading={loading}
        >
          {translate('auth.signIn')}
        </Button>
      </View>
    </VStack>
  );
};
