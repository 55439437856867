import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  GetTripScheduledChangeResponse,
  TripScheduledChangeCreatePurchasesRequest,
  TripScheduledChangeCreatePurchasesResponse,
  TripScheduledChangeListResponse,
  TripScheduledChangeVerifyPurchasesRequest,
  TripScheduledChangeVerifyPurchasesResponse,
} from '@naus-code/naus-admin-types';
import { PaginatedListRequest } from '@naus-code/naus-client-types';
import { isEqual } from 'lodash';

import { rootApi } from '~/store/redux.utils';
import { mergeAndRemoveDuplicates } from '~/utils/utils.helper';

const base_url = 'scheduled-change';
export const scheduledChangesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getScheduledList: builder.query<
      TripScheduledChangeListResponse,
      PaginatedListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'getScheduledList'],
    }),

    invalidateScheduledChanges: builder.mutation<
      TripScheduledChangeListResponse,
      PaginatedListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            scheduledChangesApi.util.updateQueryData('getScheduledList', req, (draft) => {
              draft.isFinal = data.isFinal;
              draft.list = data.list;
            }),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),

    // @Get('/get-scheduled-change/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getScheduledChange(@Params('id') id: string): Promise<GetTripScheduledChangeResponse> {
    //     return this._scheduledChangeService.getScheduledChange(id);
    // }

    getScheduledChange: builder.query<GetTripScheduledChangeResponse, string>({
      query: (id) => ({
        url: `${base_url}/get-scheduled-change/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getScheduledChange'],
    }),

    // @Post('/verify-scheduled-change/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // verifyScheduledChange(
    //     @Body() body: TripScheduledChangeVerifyPurchasesRequest,
    // ): Promise<TripScheduledChangeVerifyPurchasesResponse> {
    //     return this._scheduledChangeService.verifyScheduledChange(body);
    // }

    verifyScheduledChange: builder.query<
      TripScheduledChangeVerifyPurchasesResponse,
      TripScheduledChangeVerifyPurchasesRequest
    >({
      query: (data) => ({
        url: `${base_url}/verify-scheduled-change/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'verifyScheduledChange'],
    }),

    verifyFormScheduledChange: builder.query<
      ConfigForData,
      TripScheduledChangeCreatePurchasesRequest
    >({
      query: (data) => ({
        url: `${base_url}/form-config/verify-scheduled-change/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'verifyFormScheduledChange'],
    }),

    // @Get('/form-config/create-scheduled-change/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // createScheduledChangeFormConfig(): Promise<ConfigForData> {
    //     return this._scheduledChangeService.getScheduledChangeCreateForm();
    // }

    getCreateScheduledChangeFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-scheduled-change/`,
        method: 'GET',
      }),
      providesTags: ['*', 'createScheduledChangeFormConfig'],
    }),

    // @Post('/create-scheduled-change/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // createScheduledChange(
    //     @Body() body: TripScheduledChangeCreatePurchasesRequest,
    // ): Promise<TripScheduledChangeCreatePurchasesResponse> {
    //     return this._scheduledChangeService.createScheduledChange(body);
    // }

    createScheduledChange: builder.mutation<
      TripScheduledChangeCreatePurchasesResponse,
      TripScheduledChangeCreatePurchasesRequest
    >({
      query: (data) => ({
        url: `${base_url}/create-scheduled-change/`,
        method: 'Post',
        data,
      }),
      invalidatesTags: ['getScheduledList'],
    }),

    // @Get('/activate-scheduled-change/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // activateScheduledChangeFormConfig(
    //     @Params('id') id: string,
    // ): Promise<TripScheduledChangeCreatePurchasesResponse> {
    //     return this._scheduledChangeService.activateScheduledChange(id);
    // }

    activateScheduledChangeFormConfig: builder.mutation<
      TripScheduledChangeCreatePurchasesResponse,
      string
    >({
      query: (id) => ({
        url: `${base_url}/activate-scheduled-change/${id}`,
        method: 'GET',
      }),
      invalidatesTags: [
        'getScheduledChange',
        'getScheduledList',
        'createScheduledChangeFormConfig',
      ],
    }),

    // @Get('/delete-scheduled-change/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // deleteScheduledChangeFormConfig(@Params('id') id: string): Promise<SuccessResponse> {
    //     return this._scheduledChangeService.deleteScheduledChange(id);
    // }

    deleteScheduledChangeFormConfig: builder.mutation<void, string>({
      query: (id) => ({
        url: `${base_url}/delete-scheduled-change/${id}`,
        method: 'GET',
      }),
      invalidatesTags: [
        'getScheduledChange',
        'getScheduledList',
        'createScheduledChangeFormConfig',
      ],
    }),
  }),
});
