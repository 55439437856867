import { ContentFeedbackHandler } from '@droplet-tech-code/core-elements/module/ui/Feedback/Feedback.Handler';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  copyToClipboard,
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { translate } from '~/utils/translation.utils';

import { CardWithHeader } from '../Card/CardWithHeader';
import { Field } from '../Card/Field';
import { LiveDateText } from '../DateText';
import { LinkText } from '../LinkText';
import { SwitchStack } from '../Stack/SwitchStack';
import { AppText } from '../Text';
import { BookingMenu } from './BookingPopover.utils';
import { usePaymentSelector } from './Modals/PaymentSessionSelector';

export const BookingInfo = ({
  bookingId,
  supportId,
}: {
  bookingId?: string;
  supportId?: string;
}) => {
  const {
    currentData,
    data,
    isLoading: isLoadingBookingData,
    isError,
  } = bookingApi.useGetBookingQuery(bookingId ?? '');

  const { open: openPaymentSession } = usePaymentSelector();

  const bookingInfo = data?.booking;

  if (bookingId === undefined) {
    return null;
  }

  if (isLoadingBookingData) {
    return (
      <CardWithHeader
        headerText={`${translate('bookings.bookingInfo')}`}
        w={isWeb ? 600 : undefined}
      >
        <View p="2.5">
          <LoadingIndicator />
        </View>
      </CardWithHeader>
    );
  }

  if (bookingInfo === undefined || (!currentData && isError)) {
    return (
      <CardWithHeader
        headerText={`${translate('bookings.bookingInfo')}`}
        w={isWeb ? 600 : undefined}
      >
        <VStack p="2.5">
          <ContentFeedbackHandler
            data={currentData}
            removeTopMargin
            isLoading={isLoadingBookingData}
            feedback={`${translate('bookings.bookingNotFound')} ${bookingId ?? 'NA'}`}
            EmptyDataFeedBack={<View />}
          >
            {<></>}
          </ContentFeedbackHandler>
        </VStack>
      </CardWithHeader>
    );
  }
  const {
    p_email,
    p_firstName,
    p_lastName,
    p_phone,
    lang,
    paymentSessions,
    dateCreated,
    booking_ref,
  } = bookingInfo;

  return (
    <CardWithHeader
      headerText={`${translate('bookings.bookingInfo')}`}
      rightChild={() => BookingMenu({ booking: bookingInfo, supportId })}
      w={isWeb ? 600 : undefined}
    >
      <SwitchStack p="2.5" space={isNativeMobile ? '1' : '2.5'}>
        <VStack flex={isNativeMobile ? undefined : 1} space="1">
          <HStack flex={1}>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
              {translate('bookings.nameUpper')}
            </AppText.ExtraSmall>
            <Text.Small>{`${p_firstName}, ${p_lastName}`}</Text.Small>
          </HStack>
          <HStack>
            <Field
              label={translate('bookings.emailUpper')}
              value={p_email}
              isCopyable
              valueType="small"
            />
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
              {translate('bookings.phoneUpper')}
            </AppText.ExtraSmall>
            <Text.Small
              onPress={() => {
                copyToClipboard(p_phone);
              }}
              align="right"
            >
              {p_phone}
            </Text.Small>
          </HStack>
          <HStack flex={1} />
        </VStack>
        <VStack flex={isNativeMobile ? undefined : 1} space="1">
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
              {translate('bookings.languageUpper')}
            </AppText.ExtraSmall>
            <Text.Small align="right">{`${lang}`}</Text.Small>
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
              {translate('bookings.paymentSessions')}
            </AppText.ExtraSmall>
            <View align="flex-end">
              <LinkText
                onPress={() => {
                  openPaymentSession(bookingInfo);
                }}
              >{`${paymentSessions.length} sessions`}</LinkText>
            </View>
          </HStack>
          <HStack space="2.5" style={{ overflow: 'hidden' }}>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark" type="small">
              {translate('bookings.createdUpper')}
            </AppText.ExtraSmall>
            <LiveDateText
              align="right"
              dateTime={dateCreated}
              formatOption="lifetimeDependent&fullDateTime"
              type="small"
              isCopyable
            />
          </HStack>
          <HStack>
            <Field
              label={translate('bookings.bookingRef')}
              value={booking_ref}
              isCopyable
              valueType="small"
            />
          </HStack>
        </VStack>
      </SwitchStack>
    </CardWithHeader>
  );
};
