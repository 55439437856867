import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { purchaseApi } from '../Purchases.api';

export const usePurchaseByDateInputModal = () => {
  const { data: formData, isLoading } =
    purchaseApi.useGetPurchaseListByDateFormConfigQuery();
  const navigation = useAppNavigation();

  return useCreateManualFormModal({
    title: translate('purchases.purchasesForDate'),
    subtitle: translate('purchases.enterDateDetails'),
    formData: formData,
    isLoading: isLoading,
    onConfirm: (value) => {
      navigation.navigate('PurchasesByDate', value);
    },
  });
};
