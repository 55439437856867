import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { ScrollView } from 'react-native-gesture-handler';

import { useUpdateFerryCompanyOperationsModal } from '~/components/Config/CompanyConfig/Modals/Company/Modals';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import { ScreenPropCompanyOperationsConfig } from '~/navigation/Navigation.types';
import { translate } from '~/utils/translation.utils';

import { CompanyConfigItemHeader } from '../../CompanyConfig.ItemHeader';
import { companyConfigApi } from '../../CompanyConfigs.api';
import { BookingRules } from './BookingRules';
import { Cancellations } from './Cancellations/Cancellations';
import { Modifications } from './Modifications/Modifications';
import { Open } from './Open/Open';

export const CompanyOperationsConfig = ({ id }: { id: string }) => {
  const { data: companyConfigGetForm } =
    companyConfigApi.useGetFerryCompanyConfigQuery(id);
  const { open: openUpdateCompanyOperationsConfig } =
    useUpdateFerryCompanyOperationsModal(id);

  if (companyConfigGetForm === undefined) {
    return null;
  }

  const allOperations = companyConfigGetForm.config.operationsConfig;
  return (
    <VStack flex={1}>
      {isNativeMobile ? <CompanyConfigItemHeader id={id} /> : <></>}
      <ScrollView>
        <VStack p="2.5" space="2.5">
          <ConfigListSectionTitle>
            <HStack>
              <Text.Small flex={1} color="monochrome-mid">
                {translate('companyConfig.operationsCancellationsMod')}
              </Text.Small>
              <IconButton
                icon="edit"
                color="monochrome"
                onPress={openUpdateCompanyOperationsConfig}
              />
            </HStack>
          </ConfigListSectionTitle>
          <BookingRules allOperations={allOperations} />
          <Cancellations allOperations={allOperations} />
          <Modifications allOperations={allOperations} />
          <Open allOperations={allOperations} />
        </VStack>
      </ScrollView>
    </VStack>
  );
};

export const CompanyOperationsConfigScreen = (_: ScreenPropCompanyOperationsConfig) => {
  // TODO
  return <CompanyOperationsConfig id="" />;
};
