import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { VoucherListItemResponse } from '@naus-code/naus-admin-types';

import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

import { PressableCard } from '../Card/PressableCard';
import { LiveDateText } from '../DateText';
import { Pill } from '../Pill/Pill';

export const VoucherListItem = ({
  voucher,
  onPress,
}: {
  voucher: VoucherListItemResponse;
  onPress: () => void;
}) => {
  const { remainingValue, title, ccy, redeemedValue, isVoid } = voucher;

  return (
    <PressableCard flex={1} p="2.5" onPress={onPress}>
      <VStack flex={1}>
        <HStack justify="space-between">
          <Text.Small>{title}</Text.Small>
        </HStack>
        <HStack justify="space-between">
          <Text.Small color="success">
            {getPriceWithSymbol(remainingValue, ccy)}
          </Text.Small>
          <Text.Small color="error">{getPriceWithSymbol(redeemedValue, ccy)}</Text.Small>
        </HStack>
        <HStack justify="space-between">
          <HStack space="2.5">
            {voucher.dateExpire && (
              <>
                <Text.Small>{`${translate('voucher.expiryDate')}: `}</Text.Small>
                <LiveDateText
                  align="right"
                  numberOfLines={1}
                  dateTime={voucher.dateExpire}
                  formatOption="fullExpiryDependent&Date"
                  type="small"
                />
              </>
            )}
          </HStack>
          {isVoid ? (
            <Pill color="error">
              <Text.Small color="error">{translate('supportRequests.void')}</Text.Small>
            </Pill>
          ) : null}
        </HStack>
      </VStack>
    </PressableCard>
  );
};
