import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { TripScheduledChangeItemResponse } from '@naus-code/naus-admin-types';
import { Image, Pressable } from 'react-native';

import { LiveDateText } from '~/components/DateText';

export const ScheduledChangeListItem = ({
  scheduledChange,
  onPress,
}: {
  onPress: () => void;
  scheduledChange: TripScheduledChangeItemResponse;
}) => {
  const { shading } = useAppTheme();

  return (
    <Pressable onPress={onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <View
            br={10}
            bg={hovered ? 'primary-base' : 'white'}
            style={[{ overflow: 'hidden' }, shading.light]}
          >
            <ScheduledChangeListItemInternalComponent scheduledChange={scheduledChange} />
          </View>
        );
      }}
    </Pressable>
  );
};

const ScheduledChangeListItemInternalComponent = ({
  scheduledChange,
}: {
  scheduledChange: TripScheduledChangeItemResponse;
}) => {
  const { status, date, title, operatorImg, vesselName, type } = scheduledChange;
  const imageUrl = operatorImg;
  return (
    <HStack flex={1} p="2.5" space="3">
      <VStack align="flex-start" justify="flex-start" style={{ height: '100%' }}>
        <VesselImage imageUrl={imageUrl} />
      </VStack>

      <VStack flex={1} space="0.5">
        <HStack flex={1}>
          <Text.Small color={status === 'Draft' ? 'warning' : 'success'} bold flex={1}>
            {status}
          </Text.Small>

          <LiveDateText
            color="monochrome-mid"
            align="right"
            dateTime={date}
            formatOption="fullDateTime"
            type="small"
          />
        </HStack>
        <HStack>
          <Text.Body2Medium numberOfLines={1} flex={1}>{`[${title}]`}</Text.Body2Medium>
          <Text.Body2Regular numberOfLines={1} color="monochrome-extraDark">
            {`${type?.toLocaleUpperCase()}`}
          </Text.Body2Regular>
        </HStack>
        <Text.Small numberOfLines={1} color="monochrome-extraDark">
          {vesselName}
        </Text.Small>
      </VStack>
    </HStack>
  );
};

const VesselImage = ({ imageUrl }: { imageUrl?: string }) => {
  return (
    <View>
      <Image
        source={{
          uri: imageUrl,
        }}
        style={{ width: 45, height: 45, borderRadius: 10 }}
      />
    </View>
  );
};
