// import {
//   signInWithEmailAndPassword as signIn,
//   sendPasswordResetEmail,
// } from "@firebase/auth";
import { firebase } from "~/utils/firebase";

import { translate } from "../../utils/translation.utils";

export const resetUserPassword = async (email: string) => {
  if (email) {
    await firebase.sendPasswordResetEmail(email);
    return true;
  }

  return false;
};

export const signInWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  return await firebase
    .signIn(email, password)
    .catch((error) => {
      if (!error) {
        throw { message: translate("auth.errorLog") };
      }
      switch (error.code) {
        case "auth/invalid-email":
        case "auth/user-not-found":
          throw {
            message: translate("auth.invalidEmail"),
          };
        case "auth/user-disabled":
          throw {
            message: translate("auth.disable"),
          };
        case "auth/wrong-password":
          throw {
            message: translate("auth.invalidPassword"),
          };
        default:
          throw {
            message: translate("auth.errorLogSupport"),
          };
      }
    });
};

export const getUserUid = () => firebase.getCurrentUser()?.uid;
export const getFirebaseToken = async () => {
  return firebase.getCurrentUser()?.getIdToken() || "";
};

export const isUserSignedIn = () => {
  return firebase.getCurrentUser();
};
