import {
  AppTextProps,
  Text as CoreText,
} from '@droplet-tech-code/core-elements/module/ui/Text';
// TODO: exports to core-elements
export type TextFunc = (props: AppTextProps) => JSX.Element;
export type CoreTextKeys = keyof typeof CoreText;
export type AppTextKeys = CoreTextKeys | 'MiscTag' | 'SmallMedium';

const AppText = { ...CoreText } as { [key in AppTextKeys]: TextFunc };

AppText.MiscTag = (props) => {
  return (
    <CoreText
      type="extraSmall"
      {...props}
      style={[
        props.style,
        {
          fontSize: 10,
          lineHeight: 20,
          letterSpacing: 1.2,
          textTransform: 'uppercase',
        },
      ]}
    />
  );
};

AppText.SmallMedium = (props) => {
  return (
    <CoreText
      type="small"
      {...props}
      style={[
        props.style,
        {
          fontSize: 12,
          lineHeight: 18,
        },
      ]}
    />
  );
};

export { AppText };
