import { AppTextProps, Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { copyToClipboard } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useCallback } from 'react';

export const CopyableText = (props: CopyableTextProps) => {
  const copy = useCallback((text: string) => {
    copyToClipboard(text);
  }, []);
  return (
    <>
      <Text onPress={() => copy(props.value)} type={props.type || 'small'} {...props}>
        {props.value}
      </Text>
    </>
  );
};

interface CopyableTextProps extends AppTextProps {
  value: string;
}
