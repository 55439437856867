import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { ContentFeedbackHandler } from '@droplet-tech-code/core-elements/module/ui/Feedback/Feedback.Handler';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { GetAdminUserResponse, UserRules } from '@naus-code/naus-admin-types';

import { useUpdateAdminUserModal } from '~/components/AdminUsers/Modals/updateUserModal';
import { useUpdateAdminUserRulesModal } from '~/components/AdminUsers/Modals/updateUserRulesModal';
import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { PageContainer } from '~/components/Page/Page.Container';
import { useGetAuthUserRole, useGetAuthUserRules } from '~/screens/Auth/Auth.Context';
import { translate } from '~/utils/translation.utils';

import { usersApi } from '../Users.api';

export const AdminUserItem = (props: { id: string }) => {
  const { data, isLoading } = usersApi.useGetUserItemQuery(props.id);

  const userAccess = useGetAuthUserRules('accessUsers');

  const { open: adminModal } = useUpdateAdminUserModal(props.id);

  const adminUser = data?.adminUser;

  return (
    <PageContainer>
      <ContentFeedbackHandler
        data={data}
        isLoading={isLoading}
        dataEmpty={data === undefined}
        feedback={translate('users.notFound')}
      >
        <ScrollView w={800} contentContainerStyle={{ flexGrow: 1 }}>
          <VStack flex={1} space="2.5" p="2.5">
            <CardWithHeader
              headerText={translate('users.userInfo')}
              iconRightType={userAccess ? 'edit' : undefined}
              iconRightOnPress={userAccess ? adminModal : undefined}
            >
              <HStack p="2.5" space="4">
                <VStack flex={1}>
                  <FormItem
                    text={adminUser?._id}
                    label={translate('users.id')}
                    emptyText="-"
                  />
                  <FormItem
                    text={adminUser?.email}
                    label={translate('users.email')}
                    emptyText="-"
                  />
                </VStack>
                <VStack flex={1}>
                  <FormItem
                    text={adminUser?.name}
                    label={translate('users.name')}
                    emptyText="-"
                  />
                  <FormItem
                    text={adminUser?.role}
                    label={translate('users.role')}
                    emptyText="-"
                  />
                </VStack>
                <VStack flex={1}>
                  <FormItem
                    text={adminUser?.lastName}
                    label={translate('users.lastName')}
                    emptyText="-"
                  />
                  <FormItem
                    text={adminUser?.officeId}
                    label={translate('users.officeId')}
                    emptyText="-"
                  />
                </VStack>
              </HStack>
            </CardWithHeader>

            <CardWithHeader headerText={translate('users.userSupport')}>
              <HStack p="2.5" flex={1} space="4">
                <VStack flex={1}>
                  <FormItem
                    text={booleanToString(adminUser?.acceptsSupport)}
                    label={translate('users.acceptsSupport')}
                  />
                  <FormItem
                    text={adminUser?.pausedSupportRequests}
                    label={translate('users.pausedSupportRequests')}
                    emptyText="0"
                  />
                </VStack>
                <VStack flex={1}>
                  <FormItem
                    text={adminUser?.pendingSupportRequests}
                    label={translate('users.pendingSupportRequests')}
                    emptyText="0"
                  />
                  <FormItem
                    text={adminUser?.closedSupportRequests}
                    label={translate('users.closedSupportRequests')}
                    emptyText="0"
                  />
                </VStack>
                <VStack flex={1}>
                  <FormItem
                    text={adminUser?.openSupportRequests}
                    label={translate('users.openSupportRequests')}
                    emptyText="0"
                  />
                  <FormItem
                    text={adminUser?.pendingTasks}
                    label={translate('users.pendingTasks')}
                    emptyText="0"
                  />
                </VStack>
              </HStack>
            </CardWithHeader>

            <CardWithHeader headerText={translate('users.userHR')}>
              <HStack p="2.5" justify="space-between">
                <FormItem
                  text={adminUser?.subGroup}
                  label={translate('users.subgroup')}
                  emptyText="-"
                />
                <FormItem
                  text={adminUser?.manager}
                  label={translate('users.manager')}
                  emptyText="-"
                />
                <FormItem
                  text={concatArray(adminUser?.managees)}
                  label={translate('users.manages')}
                  emptyText="-"
                />
                <View />
              </HStack>
            </CardWithHeader>
            {data && <UserRulesCard data={data} />}
          </VStack>
        </ScrollView>
      </ContentFeedbackHandler>
    </PageContainer>
  );
};

export const FormItem = ({
  text,
  label,
  emptyText = ' ',
  color,
}: {
  label: string;
  text?: number | string;
  emptyText?: string;
  color?: ColorKeys;
}) => {
  return (
    <VStack row={isNativeMobile}>
      <Text.Small color="monochrome-mid">{label}</Text.Small>
      <Text.Body1Regular color={color}>{text || emptyText}</Text.Body1Regular>
    </VStack>
  );
};

function concatArray(stringArray: string[] | undefined): string {
  if (stringArray === undefined) {
    return '';
  }
  return stringArray.reduce((fullString, managee) => fullString + ' ' + managee, '');
}

function booleanToString(
  condition: boolean | undefined,
  text = { trueText: translate('users.yes'), falseText: translate('users.no') },
): string {
  return condition ? text.trueText : text.falseText;
}

const UserRulesCard = ({ data }: { data: GetAdminUserResponse }) => {
  type AllRules = Record<keyof UserRules, undefined>;
  const allRulesObj: AllRules = {
    accessAreas: undefined,
    accessCompanyConfig: undefined,
    accessEvents: undefined,
    accessLiveTrack: undefined,
    accessLocations: undefined,
    accessRegions: undefined,
    accessScheduledChanges: undefined,
    accessSupport: undefined,
    accessSupportConfig: undefined,
    accessSysConfig: undefined,
    accessTasks: undefined,
    accessTech: undefined,
    accessUsers: undefined,
    accessVessels: undefined,
    activateScheduledChanges: undefined,
    assignSupportRequests: undefined,
    canCreatePendingRefund: undefined,
    canApprovePendingRefund: undefined,
  };
  const allRules = Object.keys(allRulesObj) as (keyof UserRules)[];
  const rulesGrouped = [] as (keyof UserRules)[][];
  const nearestDivBy4 = Math.ceil(allRules.length / 4) * 4;
  for (let i = 0; i <= nearestDivBy4; i++) {
    if (i % 4 === 0 && i !== 0) {
      rulesGrouped.push(
        [allRules[i - 3], allRules[i - 2], allRules[i - 1], allRules[i]].filter(
          (r) => !!r,
        ),
      );
    }
  }

  const rulesEdit = useGetAuthUserRole() === 'SYSTEM';
  const { open: rulesModal } = useUpdateAdminUserRulesModal(data.adminUser._id);
  const adminUserRules = data?.adminUser?.rules?.userRules;

  return (
    <CardWithHeader
      headerText={translate('users.userRules')}
      iconRightType={rulesEdit ? 'edit' : undefined}
      iconRightOnPress={rulesEdit ? rulesModal : undefined}
    >
      <VStack flex={1} p="2.5">
        {rulesGrouped.map((group) => {
          return (
            <HStack flex={1}>
              {group.map((rule) => {
                return (
                  <View w={200}>
                    <FormItem
                      text={booleanToString(adminUserRules?.[rule], {
                        trueText: translate('users.granted'),
                        falseText: translate('users.notGranted'),
                      })}
                      color={adminUserRules?.[rule] ? 'success-light' : undefined}
                      label={translate(`users.${rule}`)}
                    />
                  </View>
                );
              })}
            </HStack>
          );
        })}
      </VStack>
    </CardWithHeader>
  );
};
