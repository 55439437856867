import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { GetSupportRequestResponse } from '@naus-code/naus-admin-types';
import { SupportTypeId } from '@naus-code/naus-client-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { useAppNavigation } from '~/hooks/nav.hook';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

const ModalKey = 'useCreateSupport';

export interface CreateSupportProps {
  customerId: string;
  bookingId?: string;
  purchaseId?: string;
  ticketIds?: string[];
}

const CreateSupport = ({
  customerId,
  bookingId,
  purchaseId,
  ticketIds,
}: CreateSupportProps) => {
  const { closeModal } = useModal();
  const { data: createConfig, isLoading } =
    supportRequestApi.useCreateSupportRequestFormConfigQuery();
  const navigate = useAppNavigation();

  const [createRequest, { isLoading: isCreating }] =
    supportRequestApi.useCreateSupportRequestMutation();
  const formRef = useRef(new FormHandler());

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('createSupport.createSupportRequest')}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <ModalScrollView>
        <FormContent isLoading={isLoading} form={createConfig}>
          <VStack>
            {createConfig ? (
              <FormBuilder data={createConfig} form={formRef.current} />
            ) : null}
          </VStack>
        </FormContent>
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isCreating}
        onConfirm={async () => {
          const { valid, value } = formRef.current.getValue<{
            message: any;
            supportRequest: {
              supportTypeId: SupportTypeId;
            };
            ref: string;
            hide: boolean;
            silent: boolean;
            scheduledChangeId: string;
          }>();
          if (valid) {
            const response = await createRequest({
              customerId,
              ...value,
              supportRequest: {
                bookingId,
                purchaseId,
                ticketIds,
                ref: value.ref,
                supportTypeId: value.supportRequest.supportTypeId,
              },
            });

            handleRTKResponse({
              response,
              onSuccess: (response: { data: GetSupportRequestResponse }) => {
                const supportId = response.data.supportRequest._id;
                navigate.navigate('PendingSupportRequests', {
                  id: supportId,
                });
                closeModal(ModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(ModalKey);
        }}
      />
    </Modal.Container>
  );
};
export const useCreateSupport = () => {
  const { closeModal, openModal } = useModal();

  return {
    open: (props: CreateSupportProps) => {
      openModal(<CreateSupport {...props} />, {
        key: ModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
