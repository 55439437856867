import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { IconCircle } from '@droplet-tech-code/core-elements/module/ui/Icon';
import {
  PopoverContainer,
  PopoverList,
} from '@droplet-tech-code/core-elements/module/ui/Popover';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { TransactionClient } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { FlatList, ListRenderItem } from '~/components/FlatList/FlatList';
import { RowItem, TableOptions } from '~/components/Table/Table.types';
import { TableHeader, TableRow } from '~/components/Table/Table_v2';
import {
  LinkedTransactionIndicator,
  TransactionHoverContainer,
  useGetTransactionOption,
} from '~/screens/Finance/Transactions/Transactions.Common';
import {
  useExtractTransactionDataForTable,
  useTransactionList,
  useTransactionPeriodData,
} from '~/screens/Finance/Transactions/Transactions.utils';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

export const CompanyTransactionQueryList = ({ list }: { list?: TransactionClient[] }) => {
  const { data, isLoading } = useTransactionPeriodData({ skip: !!list });

  const finalList = list ?? data?.list;

  return (
    <DelayRender isLoading={isLoading}>
      {finalList ? <CompanyTransactionList list={finalList} /> : null}
    </DelayRender>
  );
};

const PressableTableRowItem = ({ row }: { row: RowItem<TransactionClient> }) => {
  const optionList = useGetTransactionOption(row);

  return (
    <TransactionHoverContainer row={row}>
      <HStack space="1.5">
        <LinkedTransactionIndicator row={row} />
        <TableRow row={row} />
        <View flex={1} />
        <PopoverContainer
          width={250}
          maxHeight={320}
          offsetX={-5}
          Popover={<PopoverList list={optionList} />}
        >
          {({ hovered }: ButtonInteractionState) => {
            {
              return <IconCircle color="monochrome" icon="more" hovered={hovered} />;
            }
          }}
        </PopoverContainer>
      </HStack>
    </TransactionHoverContainer>
  );
};

export const CompanyTransactionList = ({ list }: { list: TransactionClient[] }) => {
  const { spacing } = useAppTheme();
  const baseTransactions = useTransactionList({ transactions: list });

  const tableOptions: TableOptions<TransactionClient> = {
    colDef: {
      date: {
        formatValue: (date: TransactionClient['date']) => {
          return dayjs(date).format('HH:mm DD/MM/YY');
        },
        width: 110,
        headerLabel: 'Date',
      },
      category: {
        width: 100,
        headerLabel: 'Category',
      },
      companyId: {
        width: 75,
        headerLabel: 'Company',
      },
      creditCode: {
        width: 90,
        headerLabel: 'Credit-code',
      },
      ccy: {
        width: 55,
        headerLabel: 'CCY',
      },
      value: {
        formatValue: (
          value: TransactionClient['value'],
          transaction: TransactionClient,
        ) => {
          const flow = transaction.type === 'out-flow' ? -1 : 1;
          return getPriceWithSymbol(value * flow, '');
        },
        width: 75,
        headerLabel: 'Value',
        textStyle: {
          textAlign: 'right',
        },
      },
      refundExtra: {
        formatValue: (refundExtra: number | undefined) => {
          return getPriceWithSymbol(refundExtra, '');
        },
        width: 110,
        headerLabel: 'Refund',
        textStyle: {
          textAlign: 'right',
        },
      },
    },
    rowDef: {
      rowStyle: {
        padding: spacing[3],
      },
    },
  };

  const transactions = useExtractTransactionDataForTable(
    baseTransactions.operatorList,
    tableOptions,
  );

  const renderItem: ListRenderItem<(typeof transactions)['rows'][0]> = useCallback(
    (row) => {
      return <PressableTableRowItem row={row} />;
    },
    [],
  );

  const ListHeaderComponent = useCallback(() => {
    return <TableHeader headers={transactions.headers} options={tableOptions} />;
  }, [transactions.headers]);

  return (
    <FlatList
      contentContainerStyle={[isNativeMobile && { paddingHorizontal: spacing[2] }]}
      feedback={translate('transactions.emptyTransactionList')}
      ListHeaderComponent={ListHeaderComponent}
      stickyHeaderIndices={[0]}
      simple
      data={transactions.rows}
      renderItem={renderItem}
    />
  );
};
