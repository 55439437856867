import { Button } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageContainer } from '~/components/Page/Page.Container';
import { PageHeader } from '~/components/Page/Page.Header';
import { MissingDiscountQueryList } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Discounts/MissingDiscountConfigs.List';
import { useMissingDiscountStore } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Discounts/MissingDiscountConfigs.store';
import { translate } from '~/utils/translation.utils';

import { companyDataConfigApi } from '../companyDataConfigsApi';

export const MissingDiscountConfigsScreen = () => {
  return (
    <PageContainer>
      <VStack flex={1}>
        <PageHeader
          title={translate('companyDataConfigs.missingDiscountConfigs')}
          withBack={isNativeMobile}
          childRight={<ImportButton />}
        />
        <MissingDiscountQueryList />
      </VStack>
    </PageContainer>
  );
};

const ImportButton = () => {
  const [importTickets, { isLoading: isImporting }] =
    companyDataConfigApi.useAddMissingDiscountsMutation();
  const selectAll = useMissingDiscountStore((s) => s.selectAll);
  const deselectAll = useMissingDiscountStore((s) => s.deselectAll);
  const selectedTickets = useMissingDiscountStore((s) => s.selectedTickets);
  const { data, isLoading } = companyDataConfigApi.useGetMissingDiscountsQuery();
  return (
    <HStack space="3">
      <Button
        disabled={!data || data.list.length === 0 || isLoading || isImporting}
        variant="outline"
        onPress={() => {
          if (!data) {
            return;
          }

          if (selectedTickets.length === 0) {
            selectAll(data.list);
          } else {
            deselectAll();
          }
        }}
      >
        {selectedTickets.length === 0 ? 'Select all' : 'Deselect all'}
      </Button>
      <Button
        isLoading={isImporting}
        disabled={!data || isLoading || selectedTickets.length === 0}
        onPress={async () => {
          const response = await importTickets({ list: selectedTickets });

          handleResponse({
            response,
          });
        }}
      >
        Import {selectedTickets.length} tickets
      </Button>
    </HStack>
  );
};
