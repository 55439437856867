import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { DevUrlInputModal, DevUrlModalKey } from '~/components/DevMode/DevMode.Input';
import { PageHeaderContainer } from '~/components/Page/Page.Header';
import { TabSelector } from '~/components/Tabs/Tabs.web';
import { firebase } from '~/utils/firebase';

export const WebNavBar = ({ children }: { children?: React.ReactNode }) => {
  const { openModal } = useModal();

  const { spacing } = useAppTheme();

  if (isNativeMobile) {
    return null;
  }

  return (
    <PageHeaderContainer>
      {children ? <HStack flex={1}>{children}</HStack> : null}
      <View
        style={{ height: '100%', marginLeft: -spacing[4] }}
        flex={1}
        justify="flex-end"
      >
        <TabSelector />
      </View>
      <HStack align="center" justify="flex-end" space="2">
        <IconButton
          icon="wifi"
          color="secondary"
          onPress={() => {
            openModal(<DevUrlInputModal />, {
              type: 'action-sheet-full',
              key: DevUrlModalKey,
            });
          }}
        />

        <IconButton
          color="monochrome"
          icon="sign-out"
          onPress={async () => {
            firebase.signOut();
          }}
        />
      </HStack>
    </PageHeaderContainer>
  );
};
