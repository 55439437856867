import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { ScrollView } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { GetPurchaseResponse } from '@naus-code/naus-admin-types';
import { useEffect, useRef, useState } from 'react';

import {
  CancelFooter,
  CancelFooterNative,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.Footer';
import {
  CancelPurchaseInput,
  CancelPurchaseTicketsToSelect,
  CancelPurchaseTicketsToSelectNative,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.Inputs';
import {
  cancelPurchaseManager,
  ModalActionPanelCancelKey,
} from '~/screens/Support/Purchases/PurchaseActions/ActionPanel/Cancel/Cancel.utils';
import { purchaseApi } from '~/screens/Support/Purchases/Purchases.api';
import { supportRequestApi } from '~/screens/Support/Requests/SupportRequests.api';
import { translate } from '~/utils/translation.utils';

export type CancelPurchaseProps = {
  purchaseId: string;
  supportId: string;
};

const ActionPanelCancelHeader = () => {
  const { closeModal } = useModal();

  return (
    <Modal.Header
      title={translate('purchases.purchaseTool.cancelPurchase')}
      divider
      onPress={() => {
        closeModal(ModalActionPanelCancelKey);
      }}
    />
  );
};

export const ActionPanelCancel = ({
  purchaseId,
  supportId,
}: Omit<CancelPurchaseProps, 'mode'>) => {
  return (
    <Modal.Container>
      <ActionPanelCancelHeader />
      <ProviderCancel supportId={supportId} purchaseId={purchaseId} />
    </Modal.Container>
  );
};

const ProviderCancel = (props: CancelPurchaseProps) => {
  const { data, isLoading } = purchaseApi.useGetPurchaseQuery(props.purchaseId);
  if (isNativeMobile) {
    return (
      <DelayRender noDelay={!!data} isLoading={isLoading}>
        {data ? (
          <ProviderCancelContainerNative {...props} purchase={data.purchase} />
        ) : null}
      </DelayRender>
    );
  }
  return (
    <DelayRender noDelay={!!data} isLoading={isLoading}>
      {data ? <ProviderCancelContainer {...props} purchase={data.purchase} /> : null}
    </DelayRender>
  );
};

const ProviderCancelContainerNative = (
  props: { purchase: GetPurchaseResponse['purchase'] } & CancelPurchaseProps,
) => {
  const formHandlerRef = useRef(new FormHandler());
  const { data: supportRequestRes, isLoading } =
    supportRequestApi.useGetSupportRequestQuery(props.supportId);
  const [currentPage, setCurrentPage] = useState(0);
  const changePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const [checkedItems, setCheckedItems] =
    cancelPurchaseManager.state.useGlobalState('selectedTickets');

  useEffect(() => {
    if (!supportRequestRes) {
      return () => {};
    }
    const { supportRequest } = supportRequestRes;

    const supportTicketIds = supportRequest.ticketIds;
    const purchaseTickets = props.purchase.tickets;

    const baseTickets = supportTicketIds
      ? purchaseTickets.filter((t) => supportTicketIds.some((id) => id === t._id))
      : purchaseTickets;

    const baseTicketIds = baseTickets.map((t) => t._id);

    setCheckedItems(baseTicketIds);

    return () => {
      cancelPurchaseManager.reset();
    };
  }, [supportRequestRes]);

  if (!supportRequestRes) {
    return null;
  }
  const { supportRequest } = supportRequestRes;

  return (
    <DelayRender isLoading={isLoading}>
      <VStack flex={1}>
        <ScrollView>
          <HStack flex={1} align="flex-start" style={[isWeb && { minHeight: 600 }]}>
            {currentPage === 0 ? (
              <CancelPurchaseTicketsToSelectNative
                {...props}
                supportRequest={supportRequest}
                setCheckedItems={setCheckedItems}
                checkedItems={checkedItems}
              />
            ) : null}
            {currentPage === 1 ? (
              <CancelPurchaseInput
                {...props}
                supportRequest={supportRequestRes.supportRequest}
                formHandlerRef={formHandlerRef}
                purchaseId={props.purchaseId}
              />
            ) : null}
          </HStack>
        </ScrollView>

        <CancelFooterNative
          {...props}
          formHandlerRef={formHandlerRef}
          supportRequest={supportRequestRes.supportRequest}
          setCurrentPage={changePage}
          currentPage={currentPage}
        />
      </VStack>
    </DelayRender>
  );
};

const ProviderCancelContainer = (
  props: { purchase: GetPurchaseResponse['purchase'] } & CancelPurchaseProps,
) => {
  const formHandlerRef = useRef(new FormHandler());
  const { data: supportRequestRes, isLoading } =
    supportRequestApi.useGetSupportRequestQuery(props.supportId);

  return (
    <DelayRender isLoading={isLoading}>
      {supportRequestRes ? (
        <VStack flex={1}>
          <ScrollView>
            <HStack flex={1} align="flex-start" style={[isWeb && { minHeight: 600 }]}>
              <CancelPurchaseTicketsToSelect
                {...props}
                supportRequest={supportRequestRes.supportRequest}
              />
              <Divider vertical />
              <CancelPurchaseInput
                {...props}
                supportRequest={supportRequestRes.supportRequest}
                formHandlerRef={formHandlerRef}
                purchaseId={props.purchaseId}
              />
            </HStack>
          </ScrollView>
          <CancelFooter
            {...props}
            formHandlerRef={formHandlerRef}
            supportRequest={supportRequestRes.supportRequest}
          />
        </VStack>
      ) : null}
    </DelayRender>
  );
};
