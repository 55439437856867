import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { CreateVesselRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { vesselApi } from '../Vessels.api';

export const AddVesselModalKey = 'AddModal-Vessel';
const AddVesselModal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [createVessel, { isLoading: isCreatingVessel }] =
    vesselApi.useCreateVesselMutation();

  return (
    <>
      <Modal.Header
        title={translate('vessels.newVessel')}
        subtitle={translate('vessels.enterNewVessel')}
        divider
        onPress={() => {
          closeModal(AddVesselModalKey);
        }}
      />
      <Modal.Body p="0">
        <CreateVesselForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingVessel}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<CreateVesselRequest['vessel']>();

          if (valid) {
            const response = await createVessel({ vessel: value });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddVesselModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddVesselModalKey);
        }}
      />
    </>
  );
};

export const useAddVessel = () => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<AddVesselModal />, {
        key: AddVesselModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
const CreateVesselForm = ({ form }: { form: FormHandler }) => {
  const { data: vesselCreateConfig, isLoading: isLoadingVesselConfig } =
    vesselApi.useCreateVesselFormConfigQuery();

  return (
    <FormContent isLoading={isLoadingVesselConfig} form={vesselCreateConfig} flex={1}>
      {vesselCreateConfig ? <FormBuilder data={vesselCreateConfig} form={form} /> : null}
    </FormContent>
  );
};
