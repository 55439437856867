import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropVessels } from '~/navigation/Navigation.types';

import { VesselItem } from './Item/Vessel.Item';
import { VesselList, VesselListNative } from './List/Vessel.List';

export const VesselsScreen = (props: ScreenPropVessels) => {
  return (
    <PageContainer>
      <VesselLayout {...props} />
    </PageContainer>
  );
};

export const VesselLayout = (props: ScreenPropVessels) => {
  const vesselId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(vesselId);
  }, [vesselId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? <VesselList onPress={(item) => setId(item.id)} /> : <VesselListNative />}
      {id ? <VesselItem key={id} id={id} onClose={() => setId(undefined)} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
