import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';

import { voucherApi } from '~/screens/Vouchers/voucherApi';

import { VoucherListItem } from './Voucher.ListItem';

export const CustomerVoucherList = ({
  customerId,
  onPress,
}: {
  customerId: string;
  onPress: (selectedId: string) => void;
}) => {
  const { data: voucherList } = voucherApi.useGetVoucherListByCustomerIdQuery(customerId);
  if (voucherList === undefined) {
    return null;
  }
  const vouchers = voucherList.vouchers;

  return (
    <VStack flex={1} p="2.5" space="2.5">
      {vouchers.map((voucher, index) => {
        return (
          <VoucherListItem
            key={index}
            voucher={voucher}
            onPress={() => {
              onPress(voucher.id);
            }}
          />
        );
      })}
    </VStack>
  );
};
