import { paginationUtil } from '~/components/FlatList/FlatList.Paginated';
import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { useAppNavigation } from '~/hooks/nav.hook';
import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { translate } from '~/utils/translation.utils';

export const useBookingByEmailInputModal = () => {
  const navigation = useAppNavigation();

  return useCreateManualFormModal({
    title: translate('bookings.bookingsForEmail'),
    subtitle: translate('bookings.enterEmail'),
    formData: [
      {
        items: [
          {
            type: 'email',
            label: translate('bookings.email'),
            id: 'email',
          },
        ],
      },
    ],
    onConfirm: (value) => {
      navigation.navigate('BookingsByEmail', value);
    },
  });
};

export const useInvalidateCustomerBookingListByEmail = () => {
  const [invalidateCustomerBookingListEmail, { isLoading }] =
    bookingApi.useInvalidateBookingListByEmailMutation();

  return {
    invalidate: async (email: string) => {
      const lastItemId = paginationUtil.lists.BookingsByEmail;
      if (lastItemId) {
        await invalidateCustomerBookingListEmail({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
          email,
        });
      }
    },
    isLoading,
  };
};
