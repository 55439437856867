import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { BookingClientWithPurchasesAndTickets } from '@naus-code/naus-admin-types';
import { PaymentSessionProductSummary } from '@naus-code/naus-client-types';
import { MutableRefObject, useRef, useState } from 'react';

import { bookingApi } from '~/screens/Support/Bookings/Bookings.api';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { DATE_SERVER_FORMAT } from '~/utils/date.utils';
import { translate } from '~/utils/translation.utils';

interface SendInvoiceProps {
  email: string;
  booking: BookingClientWithPurchasesAndTickets;
}

interface InvoiceDataForm extends FormHandler {
  email: string;
  invoiceDate: string;
  companyName: string;
  companyAddress: string;
  companyNumber: string;
  country: string;
  taxNumber: string;
  taxOffice?: string;
}

const SendInvoiceModalKey = 'SendInvoice-ModalKey';

export const useSendInvoiceModal = (props: SendInvoiceProps) => {
  const { openModal } = useModal();

  return {
    open: () => {
      openModal(<SendInvoiceModalComponent {...props} />, {
        key: SendInvoiceModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};

const SendInvoiceModalComponent = (props: SendInvoiceProps) => {
  const form = useRef(new FormHandler());
  const [sendInvoice, { isLoading }] = bookingApi.useSendInvoiceMutation();
  const [selectedPaymentSession, setSelectedPaymentSession] = useState<
    PaymentSessionProductSummary | undefined
  >();

  const { closeModal } = useModal();

  const onConfirm = async (value: InvoiceDataForm) => {
    if (!selectedPaymentSession) {
      return;
    }
    const req = {
      _id: props.booking._id,
      email: value.email,
      companyInvoice: {
        companyName: value.companyName,
        companyAddress: value.companyAddress,
        country: value.country,
        taxNumber: value.taxNumber,
        taxOffice: value.taxOffice,
        companyNumber: value.companyNumber,
      },
      paymentSessionId: selectedPaymentSession.id,
      invoiceDate: value.invoiceDate,
    };
    const response = await sendInvoice(req);
    handleResponse({ response, onSuccess: () => {} });
  };

  return (
    <Modal.Container style={{ width: 600 }}>
      <Modal.Header
        title={translate('bookings.sendInvoice')}
        divider
        onPress={() => {
          closeModal(SendInvoiceModalKey);
        }}
      />
      <Modal.Body>
        <VStack>
          <FormBuilder
            data={[
              {
                items: [
                  {
                    type: 'email',
                    label: translate('bookings.email'),
                    id: 'email',
                    initialValue: props.email,
                  },
                  {
                    type: 'date',
                    format: DATE_SERVER_FORMAT,
                    label: translate('bookings.invoice.invoiceDate'),
                    id: 'invoiceDate',
                    initialValue: dayjs().format(DATE_SERVER_FORMAT).toString(),
                  },
                ],
              },
            ]}
            form={form.current}
          />
          <View p="2.5" style={{ marginTop: -20 }}>
            <SelectInputBase
              noFeedback
              label={translate('bookings.paymentSessions')}
              feedback=""
              inputContainerStyle={{ flex: undefined }}
              setValues={(newValues) => {
                const selectedPaymentSessionId = newValues?.at(0);
                const selectPaymentSession = props.booking.paymentSessions.find(
                  (paymentSession) => paymentSession.id === selectedPaymentSessionId,
                );

                setSelectedPaymentSession(selectPaymentSession);
              }}
              type="single-select"
              options={props.booking.paymentSessions.map((session) => {
                return {
                  label: `${alreadySentInvoice(
                    session,
                  )} ${session.type.toLocaleUpperCase()}, ${getPriceWithSymbol(
                    session.paymentSummary.total +
                      (session.paymentSummary.voucher?.redeemValue ?? 0),
                    session.paymentSummary.currency,
                  )} on ${dayjs(session.lastUpdated).format('YY-MMM-DD HH:mm')}`,
                  value: session.id,
                };
              })}
              values={[selectedPaymentSession?.id || '']}
            />
          </View>
          <CompanyInvoiceDetails
            key={selectedPaymentSession?.id}
            paymentSession={selectedPaymentSession}
            form={form}
          />
        </VStack>
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } = form.current.getValue<InvoiceDataForm>();
          if (valid) {
            onConfirm(value);
            closeModal(SendInvoiceModalKey);
          }
        }}
        onCancel={() => {
          closeModal(SendInvoiceModalKey);
        }}
      />
    </Modal.Container>
  );
};

const alreadySentInvoice = (paymentSession: PaymentSessionProductSummary) => {
  if (paymentSession.paymentSummary.companyInvoice) {
    return '--ALREADY SENT--';
  }
  return '';
};

const CompanyInvoiceDetails = ({
  paymentSession,
  form,
}: {
  paymentSession?: PaymentSessionProductSummary;
  form: MutableRefObject<FormHandler>;
}) => {
  if (!paymentSession) {
    return null;
  }
  const invoiceData = paymentSession.paymentSummary.companyInvoice;

  return (
    <FormBuilder
      contentContainerStyle={{ opacity: invoiceData ? 0.5 : undefined }}
      readonly={!!invoiceData}
      data={[
        {
          items: [
            {
              type: 'text',
              label: translate('bookings.invoice.companyName'),
              id: 'companyName',
              initialValue: invoiceData?.companyName ?? '',
            },
            {
              type: 'text',
              label: translate('bookings.invoice.companyNo'),
              id: 'companyNumber',
              initialValue: invoiceData?.companyNumber ?? '',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              label: translate('bookings.invoice.companyAddress'),
              id: 'companyAddress',
              initialValue: invoiceData?.companyAddress ?? '',
            },
            {
              type: 'text',
              label: translate('bookings.invoice.country'),
              id: 'country',
              initialValue: invoiceData?.country ?? '',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              label: translate('bookings.invoice.taxNumber'),
              id: 'taxNumber',
              initialValue: invoiceData?.taxNumber ?? '',
            },
            {
              type: 'text',
              label: translate('bookings.invoice.taxOffice'),
              id: 'taxOffice',
              optional: true,
              initialValue: invoiceData?.taxOffice ?? '',
            },
          ],
        },
      ]}
      form={form.current}
    />
  );
};
