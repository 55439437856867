import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { RecentPayments, RecentPaymentStatus } from '@naus-code/naus-admin-types';

import { LiveDateText } from '~/components/DateText';
import { Table } from '~/components/Table/Table';
import { translate } from '~/utils/translation.utils';

import { customerApi } from '../utils/Customer.api';

const ModalKey = 'Customer-RecentPayments';

export const useRecentPayments = () => {
  const { closeModal, openModal } = useModal();

  return {
    open: (customerId: string) => {
      openModal(<CustomerRecentPayments customerId={customerId} />, {
        key: ModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

const CustomerRecentPayments = ({ customerId }: { customerId: string }) => {
  const { data, isLoading } = customerApi.useGetRecentPaymentsQuery(customerId);
  const { closeModal } = useModal();
  if (isLoading) {
    return (
      <View p="5">
        <LoadingIndicator />
      </View>
    );
  }
  if (!data) {
    closeModal(ModalKey);
    return null;
  }
  const recentPayments = data.list;

  const headers = [
    translate('customer.payment.status'),
    translate('customer.payment.statusProvider'),
    translate('customer.payment.value'),
    translate('customer.payment.ccy'),
    translate('customer.payment.last4'),
    translate('customer.payment.brand'),
  ];
  const tableData = paymentDataForTable(recentPayments);

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('customer.recentPayments')}
        divider
        onPress={() => {
          closeModal(ModalKey);
        }}
      />
      <Modal.Body>
        <HStack p="2.5">
          <StatusLightCol recentPayments={recentPayments} />
          <Table
            headers={headers}
            data={tableData}
            rowHeight={35}
            cellAlign="flex-start"
          />
          <LiveDateCol recentPayments={recentPayments} />
        </HStack>
      </Modal.Body>
    </Modal.Container>
  );
};

const paymentDataForTable = (recentPayments: RecentPayments[]) => {
  return recentPayments.map((payment) => [
    payment.status,
    payment.statusProvider,
    payment.value / 100 + '',
    payment.ccy,
    payment.last4,
    payment.brand,
  ]);
};

const LiveDateCol = ({ recentPayments }: { recentPayments: RecentPayments[] }) => {
  return (
    <VStack py="1" space="2.5" key={'color-column'}>
      <View h={35} justify="center" align="flex-start">
        <Text.Small align="center" color="monochrome-dark">
          {translate('customer.payment.date')}
        </Text.Small>
      </View>
      {recentPayments.map((payment, index) => {
        const { date } = payment;
        return (
          <View p="1" br={20} align="flex-start" justify="center" key={index} h={35}>
            <LiveDateText dateTime={date} formatOption="lifetimeDependent" type="small" />
          </View>
        );
      })}
    </VStack>
  );
};

const StatusLightCol = ({ recentPayments }: { recentPayments: RecentPayments[] }) => {
  return (
    <VStack py="1" space="2.5" key={'color-column'}>
      <View h={35} justify="center" align="center">
        <Text.Small align="center" color="monochrome-dark">
          {''}
        </Text.Small>
      </View>
      {recentPayments.map((payment, index) => {
        const { status } = payment;
        const color = colorFromStatus(status);
        return (
          <View p="1" br={20} align="center" justify="center" key={index} h={35}>
            <View
              p="1"
              bg={color}
              br={20}
              key={index}
              style={{ height: 13, width: 13 }}
            />
          </View>
        );
      })}
    </VStack>
  );
};

const colorFromStatus = (status: RecentPaymentStatus): ColorKeys => {
  if (status === 'succeeded') {
    return 'success-light';
  }
  if (status === 'pending') {
    return 'primary-light';
  }
  if (status === 'canceled') {
    return 'monochrome-light';
  }
  if (status === 'onHold') {
    return 'warning-light';
  }
  return 'black';
};
