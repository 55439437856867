import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { AppViewProps, View } from '@droplet-tech-code/core-elements/module/ui/View';

export const Pill = (props: PillProps) => {
  return (
    <View px="2.5" py="0.5" b={1} br={15} bc={props.color}>
      {props.children}
    </View>
  );
};

interface PillProps extends AppViewProps {
  color?: ColorKeys;
}
