import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { VoucherListItemResponse } from '@naus-code/naus-admin-types';
import { Pressable } from 'react-native';

import { LiveDateText } from '~/components/DateText';
import { Pill } from '~/components/Pill/Pill';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { getAppTheme } from '~/utils/theme.utils';
import { translate } from '~/utils/translation.utils';

interface VoucherListItemComponentProps {
  voucher: VoucherListItemResponse;
  onPress: () => void;
  selected: boolean;
}

// export const VoucherListItem = ({
//   voucher,
//   onPress,
// }: {
//   voucher: VoucherListItemResponse;
//   onPress: () => void;
// }) => {
//   return (
//     <Pressable onPress={onPress}>
//       <InternalVoucherListItem voucher={voucher} />
//     </Pressable>
//   );
// };
export const InternalVoucherListItem = ({
  voucher,
}: {
  voucher: VoucherListItemResponse;
}) => {
  const { remainingValue, title, ccy, redeemedValue, isVoid, adminReference } = voucher;

  return (
    <VStack space="2.5" flex={1}>
      <HStack justify="space-between">
        <Text.Body2Regular>{title}</Text.Body2Regular>
        <Text.Body2Regular>{adminReference}</Text.Body2Regular>
      </HStack>
      <HStack>
        <Text.Small>{getPriceWithSymbol(remainingValue + redeemedValue, ccy)}</Text.Small>
      </HStack>
      <HStack justify="space-between">
        <HStack space="2.5">
          {voucher.dateExpire && (
            <>
              <Text.Small>{`${translate('voucher.expiryDate')}: `}</Text.Small>
              <LiveDateText
                align="right"
                numberOfLines={1}
                dateTime={voucher.dateExpire}
                formatOption="fullExpiryDependent&Date"
                type="small"
              />
            </>
          )}
        </HStack>
        {isVoid ? (
          <Pill color="error">
            <Text.Small color="error">{translate('supportRequests.void')}</Text.Small>
          </Pill>
        ) : null}
      </HStack>
    </VStack>
  );
};

export const VoucherListItemComponent = ({
  voucher,
  onPress,
  selected,
}: VoucherListItemComponentProps) => {
  const { shading } = getAppTheme();
  return (
    <Pressable onPress={onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <View
            p="2.5"
            br={10}
            bg={hovered ? 'primary-base' : 'white'}
            bc={selected ? 'primary-mid' : hovered ? 'primary-extraLight' : 'white'}
            b={2}
            style={[{ overflow: 'hidden' }, shading.light]}
          >
            <InternalVoucherListItem voucher={voucher} />
          </View>
        );
      }}
    </Pressable>
  );
};
