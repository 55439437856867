import { onlyUnique } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import packageJson from '../../package.json';

export const isDev = __ENV__ === 'development';
export const APP_VERSION = packageJson.version || '1.0.0';

const APP_BASE_URL = isDev
  ? 'https://naus-admin-dev.azurewebsites.net/'
  : 'https://naus-admin-prod.azurewebsites.net/';

export const DOMAIN_NAME = 'https://openferry.com/';

export const APP_URLS = {
  URLS: [] as string[],
  BASE: APP_BASE_URL,
};

export const appUrlStorageKey = '@Admin-app-v1';

AsyncStorage.getItem(appUrlStorageKey).then((item) => {
  if (typeof item === 'string') {
    try {
      APP_URLS.URLS = JSON.parse(item);
    } catch (error) {
      console.error(error);
    }
  }

  APP_URLS.URLS.push(APP_BASE_URL);
  APP_URLS.URLS = APP_URLS.URLS.filter(onlyUnique);

  APP_URLS.BASE = APP_URLS.URLS.length
    ? APP_URLS.URLS[APP_URLS.URLS.length - 1]
    : APP_URLS.BASE;
});

export const getBaseUrl = () => {
  return APP_URLS.BASE;
};

console.info('Welcome to ADMIN APP development console: v', APP_VERSION);
