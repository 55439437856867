import { CancelCalculationInfoBreakdown } from '~/components/SupportRequest/RequestOptions/RequestOptions.Cancel';
import { ModifyCalculationInfoBreakdown } from '~/components/SupportRequest/RequestOptions/RequestOptions.Modify';
import { OpenCalculationInfoBreakdown } from '~/components/SupportRequest/RequestOptions/RequestOptions.Open';
import { RefundMethodProps } from '~/components/SupportRequest/RequestOptions/RequestOptions.utils';

export const RefundMethodInfo = ({ options }: RefundMethodProps) => {
  switch (options.type) {
    case 'cancel_v2': {
      return <CancelCalculationInfoBreakdown options={options} />;
    }

    case 'modify_v2': {
      return <ModifyCalculationInfoBreakdown options={options} />;
    }
    case 'open_v2': {
      return <OpenCalculationInfoBreakdown options={options} />;
    }

    default: {
      return null;
    }
  }
};
