import { SystemConfigUpdateAppVersionRequest } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { systemConfigApi } from '~/screens/Configs/System/systemConfigs.api';
import { translate } from '~/utils/translation.utils';

export const useUpdateSystemAppVersionModal = () => {
  const { open: openForm } = useFormModal();

  const [updateAppVersion, { isLoading: isUpdatingApp }] =
    systemConfigApi.useUpdateSystemAppVersionConfigMutation();

  const useFormHookHandler = useCallback(() => {
    const { data, isLoading } =
      systemConfigApi.useUpdateSystemAppVersionFormConfigQuery();
    return { data, isLoading };
  }, []);

  const onConfirm = ({
    value,
    check,
  }: {
    value: SystemConfigUpdateAppVersionRequest['appVersionConfig'];
    check?: string;
  }) => {
    return updateAppVersion({
      appVersionConfig: value,
      check: check ?? '',
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('systemConfig.updateAppVersion'),
        useFormHookHandler,
        onConfirm,
        loadingAction: isUpdatingApp,
      });
    },
  };
};
