import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import {
  getErrorResponse,
  handleResponse,
} from '@droplet-tech-code/core-elements/module/utils/error';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { Vessel } from '@naus-code/naus-client-types';
import { MutableRefObject } from 'react';

import { PageHeader } from '~/components/Page/Page.Header';
import { vesselApi } from '~/screens/Vessels/Vessels.api';

export const VesselItemHeader = ({
  vessel,
  onClose,
  formRef,
  refresh,
}: {
  vessel: Vessel;
  refresh: () => void;
  onClose: () => void;
  formRef: MutableRefObject<FormHandler>;
}) => {
  const [updateVessel] = vesselApi.useUpdateVesselMutation();

  return (
    <>
      <PageHeader
        title={`${vessel.name} [${vessel.id}]`}
        Subtitle={<Text.Body1Medium>{vessel.operatorId}</Text.Body1Medium>}
        withBack={isNativeMobile}
        iconLeft={{
          color: 'monochrome',
          icon: 'close',
          onPress: onClose,
        }}
        childRight={
          <HStack space="3">
            <IconButton icon="refresh" onPress={refresh} />

            <IconButton
              icon="send"
              onPress={async () => {
                const { valid, value } = formRef.current.getValue<Vessel>();

                if (valid) {
                  const response = await updateVessel({
                    vessel: value,
                  });

                  handleResponse({
                    response,
                    onSuccess: () => {},
                    onError: (error) => {
                      showToast(getErrorResponse(error).message, {
                        type: 'error',
                      });
                    },
                  });
                }
              }}
            />

            {/* <IconButton
              icon="extra-cog"
              onPress={() => {
                openModal(
                  <Modal.Body p="0" scrollEnabled>
                    <JSONTree
                      data={vessel as any}
                      theme={{
                        scheme: "google",
                        author: "",
                        base00: "#1d1f21",
                        base01: "#282a2e",
                        base02: "#373b41",
                        base03: "#969896",
                        base04: "#b4b7b4",
                        base05: "#c5c8c6",
                        base06: "#e0e0e0",
                        base07: "#ffffff",
                        base08: "#CC342B",
                        base09: "#F96A38",
                        base0A: "#FBA922",
                        base0B: "#198844",
                        base0C: "#3971ED",
                        base0D: "#3971ED",
                        base0E: "#A36AC7",
                        base0F: "#3971ED",
                      }}
                      valueRenderer={(v) => {
                        return (
                          <View>
                            <Text.Small bold color="secondary-mid">
                              {v as any}
                            </Text.Small>
                          </View>
                        );
                      }}
                    />{" "}
                  </Modal.Body>,
                  {
                    key: "vessel-json",
                  }
                );
              }}
            /> */}
          </HStack>
        }
      />
    </>
  );
};
