import { Palette } from '@droplet-tech-code/core-elements/module/theme';

export const palette: Palette = {
  primary: {
    base: '#F7FAFD',
    extraLight: '#E0EFFF',
    light: '#a5c9f0',
    mid: '#1A72D1',
    dark: '#004998',
    extraDark: '#003A79',
  },
  success: {
    base: '#F5FCF2',
    extraLight: '#A9E48D',
    light: '#6AB744',
    mid: '#47991D',
    dark: '#356B1B',
    extraDark: '#2B5717',
  },
  error: {
    base: '#FFF9F6',
    extraLight: '#FDC9B3',
    light: '#FC8250',
    mid: '#CF3F04',
    dark: '#892A03',
    extraDark: '#6D2102',
  },
  secondary: {
    base: '#FFF9F1',
    extraLight: '#FFDBAB',
    light: '#F9AF50',
    mid: '#EA8516',
    dark: '#9A5718',
    extraDark: '#6F3B10',
  },
  monochrome: {
    base: '#FFFFFF',
    extraLight: '#ededed',
    light: '#D2D4DB',
    mid: '#9FA4B1',
    dark: '#697285',
    extraDark: '#545F74',
  },
  warning: {
    base: '#FFF9F1',
    extraLight: '#FFDBAB',
    light: '#F9AF50',
    mid: '#EA8516',
    dark: '#9A5718',
    extraDark: '#6F3B10',
  },
  backdrop: '#353B47',
  white: '#FFFFFF',
  black: '#000000',
};
