import { CustomComponentProps } from '@droplet-tech-code/core-common-types';
import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
// import { DocumentPicker } from "@droplet-tech-code/core-elements/module/ui/Inputs/DocumentPicker/DocumentPicker";
import { ImageUpload } from '@droplet-tech-code/core-elements/module/ui/Inputs/ImagePicker/ImagePicker';
import { getInitialValue } from '@droplet-tech-code/core-elements/module/ui/Inputs/Inputs.utils';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { ImageUploadResponse } from '@naus-code/naus-client-types';
import { useState } from 'react';
import { Image } from 'react-native';

export const FormImage = (
  props: CustomComponentProps<any> & {
    onUpload?: (formData: FormData) => Promise<ImageUploadResponse>;
  },
) => {
  const [uri, setUri] = useState<string | undefined>(getInitialValue(props));

  const [layoutState, setLayout] = useState<
    { height: number; width: number } | undefined
  >();

  const { palette } = useAppTheme();

  if (!uri) {
    return (
      <VStack p="3">
        <View
          style={{
            width: 200,
            height: 300,
            backgroundColor: palette.monochrome.light,
          }}
        />
      </VStack>
    );
  }

  return (
    <VStack
      flex={1}
      onLayout={(ev) => {
        const layout = ev.nativeEvent.layout;
        if (!layoutState) {
          setLayout({ height: layout.height, width: layout.width });
        }
      }}
    >
      {props.onUpload !== undefined && layoutState ? (
        <ImageUpload
          onImageBlob={async (formData: FormData) => {
            return await props.onUpload!(formData);
          }}
        >
          <Image
            onError={() => {
              setUri(undefined);
            }}
            style={{
              alignSelf: 'center',
              width: layoutState.width,
              height: layoutState.height,
              borderRadius: 15,
              overflow: 'hidden',
            }}
            source={{
              uri,
            }}
            resizeMode="contain"
          />
        </ImageUpload>
      ) : (
        <Image
          onError={() => {
            setUri(undefined);
          }}
          source={{
            uri,
          }}
          style={{ width: layoutState?.width ?? 500, height: 300 }}
        />
      )}
    </VStack>
  );
};
