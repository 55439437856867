import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { useRef } from 'react';

import { translate } from '~/utils/translation.utils';

export const ManualFormModalKey = 'AddModal-ManualFormModalKey';

interface ManualFormProps {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  formData?: ConfigForData;
  onConfirm: (value: any) => void;
}

export const ManualFormModal = ({
  formData,
  onConfirm,
  isLoading,
  title,
  subtitle,
}: ManualFormProps) => {
  const form = useRef(new FormHandler());
  const { closeModal } = useModal();

  return (
    <Modal.Container style={{ flex: undefined }}>
      <Modal.Header
        title={title}
        subtitle={subtitle}
        divider
        onPress={() => {
          closeModal(ManualFormModalKey);
        }}
      />
      <Modal.Body style={{ flex: undefined }}>
        {formData ? (
          <FormBuilder data={formData} form={form.current} />
        ) : (
          <View h={10}>
            <Text.Body1Medium>{translate('feedback.emptyData')}</Text.Body1Medium>
          </View>
        )}
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isLoading}
        onConfirm={async () => {
          const { valid, value } = form.current.getValue();
          if (valid) {
            onConfirm(value);
            closeModal(ManualFormModalKey);
          }
        }}
        onCancel={() => {
          closeModal(ManualFormModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useCreateManualFormModal = (props: ManualFormProps) => {
  const { openModal } = useModal();
  return {
    open: () => {
      openModal(<ManualFormModal {...props} />, {
        key: ManualFormModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
