import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';

import { useCreateManualFormModal } from '~/components/Modals/ManualFormModal';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { translate } from '~/utils/translation.utils';

interface SendETicketProps {
  email: string;
  purchaseId: string;
  bookingId: string;
}

export const useSendETicketModal = (props: SendETicketProps) => {
  const [sendETicket, { isLoading }] = purchaseActionApi.useSendETicketMutation();

  return useCreateManualFormModal({
    title: translate('purchases.purchaseTool.sendETicket'),
    subtitle: translate('bookings.enterEmail'),
    formData: [
      {
        items: [
          {
            type: 'email',
            label: translate('bookings.email'),
            id: 'email',
            initialValue: props.email,
          },
        ],
      },
    ],
    onConfirm: (value) => {
      sendETicket({
        bookingId: props.bookingId,
        purchaseId: props.purchaseId,
        email: value.email,
      }).then((response) => {
        handleResponse({
          response,
          onSuccess: () => {},
        });
      });
    },
    isLoading: isLoading,
  });
};
