import { FormGroup, FormGroupItem } from '@droplet-tech-code/core-common-types';

export const formatThreeColumnForm = (formGroupList: FormGroup[]): FormGroupItem[] => {
  const formGroupItemsArray = [] as FormGroupItem[];
  let temp = [] as FormGroup[];
  for (let i = 0; i < formGroupList.length; i++) {
    temp.push(formGroupList[i]);
    if (i % 3 === 2) {
      formGroupItemsArray.push({
        type: 'form',
        items: temp,
        label: 'Manual Fill',
      });
      temp = [];
    }
  }
  if (temp.length > 0) {
    formGroupItemsArray.push({
      type: 'form',
      items: temp,
      label: 'Manual Fill',
    });
  }
  return formGroupItemsArray;
};

export const replaceText = (formData: any, textString: string) => {
  let newString = textString;
  const entries = Object.entries(formData) as string[][];
  for (const entry of entries) {
    const [key, value] = entry;
    newString = newString.replaceAll(`{{${key}}}`, value);
  }
  return newString;
};

export const getFromBetween = (textString: string, sub1: string, sub2: string) => {
  if (textString.indexOf(sub1) < 0 || textString.indexOf(sub2) < 0) {
    return false;
  }
  const SP = textString.indexOf(sub1) + sub1.length;
  const string1 = textString.substring(0, SP);
  const string2 = textString.substring(SP);
  const TP = string1.length + string2.indexOf(sub2);
  return textString.substring(SP, TP);
};

export const removeFromBetween = (
  textString: string,
  sub1: string,
  sub2: string,
  replacement?: string,
) => {
  if (textString.indexOf(sub1) < 0 || textString.indexOf(sub2) < 0) {
    return textString;
  }
  const removal = sub1 + getFromBetween(textString, sub1, sub2) + sub2;
  return textString.replace(removal, replacement || '');
};

export const getAllResults = (
  textString: string,
  sub1: string,
  sub2: string,
  cache?: string[],
) => {
  const results = cache || ([] as string[]);
  const resultSet = new Set(results);
  // first check to see if we do have both substrings
  if (textString.indexOf(sub1) < 0 || textString.indexOf(sub2) < 0) {
    return;
  }
  // find one result
  const result = getFromBetween(textString, sub1, sub2);
  // push it to the results array
  if (result) {
    results.push(result);
    resultSet.add(result);
  }
  // remove the most recently found one from the string
  const newString = removeFromBetween(textString, sub1, sub2);

  // if there's more substrings
  if (newString.indexOf(sub1) > -1 && newString.indexOf(sub2) > -1) {
    return getAllResults(newString, sub1, sub2, results);
  } else {
    // return results;
    return Array.from(resultSet);
  }
};

// export const getFromBetween2: TextInjector = {
//   results: [] as string[],
//   string: "",
//   getFromBetween: function (sub1: string, sub2: string) {
//     if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) {
//       return false;
//     }
//     const SP = this.string.indexOf(sub1) + sub1.length;
//     const string1 = this.string.substring(0, SP);
//     const string2 = this.string.substring(SP);
//     const TP = string1.length + string2.indexOf(sub2);
//     return this.string.substring(SP, TP);
//   },
//   removeFromBetween: function (
//     sub1: string,
//     sub2: string,
//     replacement?: string
//   ) {
//     if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) {
//       return false;
//     }
//     const removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
//     this.string = this.string.replace(removal, replacement);
//   },
//   getAllResults: function (sub1: string, sub2: string) {
//     // first check to see if we do have both substrings
//     if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;
//     // find one result
//     const result = this.getFromBetween(sub1, sub2);
//     // push it to the results array
//     if (result) {
//       this.results.push(result);
//     }
//     // remove the most recently found one from the string
//     this.removeFromBetween(sub1, sub2);

//     // if there's more substrings
//     if (this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
//       this.getAllResults(sub1, sub2);
//     }
//   },
//   get: function (string: string, sub1: string, sub2: string) {
//     this.results = [];
//     this.string = string;
//     this.getAllResults(sub1, sub2);
//     return this.results;
//   },
// };

export interface TextInjector {
  results: string[];
  string: string;
  getFromBetween: (sub1: string, sub2: string) => any;
  removeFromBetween: (
    sub1: string,
    sub2: string,
    replacement?: string,
  ) => false | undefined;
  getAllResults: (sub1: string, sub2: string) => void;
  get: (string: string, sub1: string, sub2: string) => any;
}
