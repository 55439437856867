import { ColorKeys } from '@droplet-tech-code/core-elements/module/theme';
import { TicketClient } from '@naus-code/naus-admin-types';
import { SupportRequestOptions } from '@naus-code/naus-client-types';

export interface RefundMethodProps {
  options: SupportRequestOptions;
}

export const getRefundMethodColor = (props: RefundMethodProps): ColorKeys => {
  switch (props.options.type) {
    case 'cancel':
    case 'cancel_v2': {
      return 'error-mid';
    }

    case 'modify':
    case 'modify_v2': {
      return 'warning-mid';
    }

    case 'open':
    case 'open_v2': {
      return 'primary-mid';
    }

    default: {
      return 'monochrome-dark';
    }
  }
};

export const getUserTicketName = (ticket?: TicketClient): string => {
  if (!ticket) {
    return '';
  }

  if (ticket.ticketType === 'Passenger') {
    return `${ticket.p_firstName} ${ticket.p_lastName} (${ticket.p_sex})`;
  }

  if (ticket.ticketType === 'Vehicle') {
    return `${ticket.p_plate} (V)`;
  }

  return `${ticket.name} (E)`;
};
