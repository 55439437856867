import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { NoticeListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import {
  PaginatedFlatList,
  paginationUtil,
} from '~/components/FlatList/FlatList.Paginated';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { noticesApi } from '../notices.api';
import { NoticesListItemComponent } from '../Notices.ListItem';
import { PendingNoticesListHeader } from './PendingNotices.ListHeader';

export interface PendingNoticesListProps {
  onPress: (item: NoticeListItemResponse) => void;
}

export const PendingNoticesListNative = () => {
  const navigate = useAppNavigation();
  return (
    <PendingNoticesList
      onPress={(item) => {
        navigate.navigate('Notice-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const PendingNoticesList = (props: PendingNoticesListProps) => {
  const selectedRef = useRef('');
  const { spacing } = useAppTheme();

  const renderItem = useCallback((pendingNotice: NoticeListItemResponse) => {
    return <NoticesListItemComponent key={pendingNotice.id} notice={pendingNotice} />;
  }, []);

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <PendingNoticesListHeader />
      <PaginatedFlatList
        listId="PendingNotices"
        feedback={translate('notices.pendingNotices')}
        hook={noticesApi.useGetPendingNoticesListQuery}
        pageSize={20}
        getId={(item) => item.id}
        showsVerticalScrollIndicator={isWeb}
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};

export const useInvalidatePendingNoticeList = () => {
  const [invalidatePendingNotices, { isLoading }] =
    noticesApi.useInvalidatePendingNoticesMutation();
  return {
    invalidate: async () => {
      const lastItemId = paginationUtil.lists.PendingNotices;
      if (lastItemId) {
        await invalidatePendingNotices({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
        });
      }
    },
    isLoading,
  };
};
