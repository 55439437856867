import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { TransactionClient } from '@naus-code/naus-admin-types';
import { create } from 'zustand';

import { TRANSACTION_SERVER_DATE_FORMAT } from '~/utils/date.utils';

export interface TransactionState {
  hoveredBookingId?: string;
  selectedTransaction?: TransactionClient;
  range: {
    from: string;
    to: string;
  };
  creditCodes: { [key: string]: boolean };
  operatorIds: { [key: string]: boolean };
}

export interface TransactionStore extends TransactionState {
  setState: (_: Partial<TransactionState>) => void;

  searchRange: (_: TransactionState['range']) => void;
  filterCreditCodes: (_: TransactionState['creditCodes']) => void;
  filterOperatorIds: (_: TransactionState['operatorIds']) => void;
}

export const useTransactionStore = create<TransactionStore>((set) => {
  return {
    hoveredBookingId: undefined,
    selectedTransaction: undefined,
    range: {
      from: dayjs().subtract(2, 'weeks').format(TRANSACTION_SERVER_DATE_FORMAT),
      to: dayjs().format(TRANSACTION_SERVER_DATE_FORMAT),
    },
    operatorIds: {},
    creditCodes: {},

    setState: (state) => set(state),

    searchRange: (range) => {
      set({
        range,
        selectedTransaction: undefined,
        hoveredBookingId: undefined,
        creditCodes: {},
        operatorIds: {},
      });
    },
    filterCreditCodes: (creditCodes) => {
      set({
        creditCodes,
        // selectedTransaction: undefined,
        hoveredBookingId: undefined,
      });
    },
    filterOperatorIds: (operatorIds) => {
      set({
        operatorIds,
        // selectedTransaction: undefined,
        hoveredBookingId: undefined,
      });
    },
  };
});
