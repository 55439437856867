import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';
import {
  PaginatedFlatList,
  paginationUtil,
} from '~/components/FlatList/FlatList.Paginated';
import { useAppNavigation } from '~/hooks/nav.hook';
import { translate } from '~/utils/translation.utils';

import { SupportRequestListItemComponent } from '../SupportRequest.ListItem';
import { supportRequestApi } from '../SupportRequests.api';
import { CustomerSupportRequestsListHeader } from './SupportRequestsCustomer.ListHeader';

export interface SupportRequestListProps {
  onPress: (item: SupportRequestListItemResponse) => void;
  supportRequestId?: string;
  customerId?: string;
  email: string;
  isLoading?: boolean;
}

export const SupportRequestListNative = ({
  email,
  customerId,
}: {
  email: string;
  customerId?: string;
}) => {
  const navigate = useAppNavigation();
  return (
    <SupportRequestList
      email={email}
      customerId={customerId}
      onPress={(item) => {
        navigate.navigate('SupportRequest-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const SupportRequestList = (props: SupportRequestListProps) => {
  const { spacing } = useAppTheme();
  const selectedRef = useRef('');

  const renderItem = useCallback(
    (supportRequest: SupportRequestListItemResponse) => {
      return (
        <SupportRequestListItemComponent
          selected={props.supportRequestId === supportRequest.id}
          key={supportRequest.id}
          supportRequest={supportRequest}
          onPress={() => {
            props.onPress(supportRequest);
          }}
        />
      );
    },
    [props],
  );

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <CustomerSupportRequestsListHeader email={props.email} />
      <PaginatedFlatList
        isLoading={props.isLoading}
        listId="SupportRequestsCustomer"
        feedback={translate('supportRequests.emptySupportRequest')}
        hook={
          props.customerId
            ? supportRequestApi.useGetSupportRequestListByCustomerIdQuery
            : supportRequestApi.useGetSupportRequestListByEmailQuery
        }
        moreReqOptions={
          props.customerId ? { customerId: props.customerId } : { email: props.email }
        }
        pageSize={20}
        simple
        getId={(item) => item.id}
        showsVerticalScrollIndicator={isWeb}
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};

export const useInvalidateCustomerRequestListById = () => {
  const [invalidateCustomerSupportRequestListId, { isLoading }] =
    supportRequestApi.useInvalidateSupportRequestListByCustomerIdMutation();

  return {
    invalidate: async (customerId: string) => {
      const lastItemId = paginationUtil.lists.SupportRequestsCustomer;
      if (lastItemId) {
        await invalidateCustomerSupportRequestListId({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
          customerId,
        });
      }
    },
    isLoading,
  };
};

export const useInvalidateCustomerRequestListByEmail = () => {
  const [invalidateCustomerSupportRequestListEmail, { isLoading }] =
    supportRequestApi.useInvalidateSupportRequestListByEmailMutation();

  return {
    invalidate: async (email: string) => {
      const lastItemId = paginationUtil.lists.SupportRequestsCustomer;
      if (lastItemId) {
        await invalidateCustomerSupportRequestListEmail({
          paginationRequest: {
            itemCount: 0,
            refreshList: true,
            lastItemId,
          },
          email,
        });
      }
    },
    isLoading,
  };
};
