import { useEffect,useState } from "react";

import { fetchManager } from "~/utils/network.utils";

export const SrcImageProvider = ({
  src,
  children,
}: {
  src: string;
  children: (_: {
    src: string | undefined;
    loading: boolean;
  }) => React.ReactNode;
}) => {
  const [finalSrc, setSrc] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const headers = await fetchManager.getHeaders();
      const options = {
        headers: {
          ...headers,
        },
      };

      fetch(src, options)
        .then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            if (typeof reader.result === "string") {
              setSrc(reader.result);
            }
          };
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [src, fetchManager]);
  return <>{children({ src: finalSrc, loading })}</>;
};
