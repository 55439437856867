import {
  ClientGetFormConfigWithCheckResponse,
  SystemConfigAddAdminUserSubGroupRequest,
  SystemConfigAddFerryTermCategoryRequest,
  SystemConfigAddLanguage,
  SystemConfigAddLoyaltyValidatorRequest,
  SystemConfigAddResidentValidatorRequest,
  SystemConfigAddTaskCategoryRequest,
  SystemConfigAddTripProviderRequest,
  SystemConfigAddVesselTypeRequest,
  SystemConfigClientGetResponse,
  SystemConfigClientGetTermsConfigResponse,
  SystemConfigClientUpdateCompanyTermsConfigRequest,
  SystemConfigClientUpdatePrivacyTermsConfigRequest,
  SystemConfigDisableActiveConfigRequest,
  SystemConfigDisableVoucherPaymentRequest,
  SystemConfigEnableActiveConfigRequest,
  SystemConfigEnableVoucherPaymentRequest,
  SystemConfigGetFerryConfigResponse,
  SystemConfigGetOperationConfigResponse,
  SystemConfigUpdateAppVersionRequest,
  SystemConfigUpdateFerryBookingConfigRequest,
  SystemConfigUpdateFerryNotifierConfigRequest,
  SystemConfigUpdateFerryServiceCostConfigRequest,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

// @"POST"('/upload/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
// uploadSystemConfig(@Body() config: SystemConfig): Promise<{ systemConfig: SystemConfig }> {
//     return this._systemConfigService.uploadSystemConfig(config);
// }

const base_url = 'config/system';
export const systemConfigApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystemConfig: builder.query<SystemConfigClientGetResponse, void>({
      query: () => ({
        url: `${base_url}/get/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getSystemConfig'],
    }),
    //---------------------- FERRY CONFIG ----------------------//
    enableSearch: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigEnableActiveConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/enable-search/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    disableSearch: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigDisableActiveConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/disable-search/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    enableBooking: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigEnableActiveConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/enable-booking/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    disableBooking: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigDisableActiveConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/disable-booking/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    enableTracking: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigEnableActiveConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/enable-tracking/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    disableTracking: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigDisableActiveConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/disable-tracking/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    addTripProvider: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigAddTripProviderRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-ferry-provider/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    addResidentValidationProvider: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigAddResidentValidatorRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-resident-validation-provider/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    addLoyaltyValidationProvider: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigAddLoyaltyValidatorRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-loyalty-validation-provider/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    addVesselType: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigAddVesselTypeRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-vessel-type/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig'],
    }),
    addTermCategory: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigAddFerryTermCategoryRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-term-category/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'getSystemConfig',
        'updatePrivacyTermsConfigFormConfig',
        'updateCompanyTermsFormConfig',
      ],
    }),
    updateFerryBookingConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/update-ferry-booking-config/`,
        method: 'GET',
      }),
      providesTags: ['*', 'updateFerryBookingConfigFormConfig'],
    }),
    updateFerryBookingConfig: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigUpdateFerryBookingConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-booking-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['updateFerryBookingConfig'],
    }),
    updateFerryNotifierConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/update-ferry-notifier-config/`,
        method: 'GET',
      }),
      providesTags: ['*', 'updateFerryNotifierConfigFormConfig'],
    }),
    updateFerryNotifierConfig: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigUpdateFerryNotifierConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-notifier-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['updateFerryNotifierConfig'],
    }),
    updateFerryServiceCostConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/update-ferry-service-cost-config/`,
        method: 'GET',
      }),
      providesTags: ['*', 'updateFerryServiceCostConfigFormConfig'],
    }),
    updateFerryServiceCostConfig: builder.mutation<
      SystemConfigGetFerryConfigResponse,
      SystemConfigUpdateFerryServiceCostConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-ferry-service-cost-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['updateFerryServiceCostConfig'],
    }),
    //----------------- TERMS CONFIG -----------------//
    // @Get('/form-config/update-company-terms-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateCompanyTermsConfigFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._systemConfigService.updateCompanyTermsConfigFormConfig();
    // }
    getUpdateCompanyTermsConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/update-company-terms-config/`,
        method: 'GET',
      }),
      providesTags: ['*', 'updateCompanyTermsFormConfig'],
    }),

    // @Post('/update-ferry-notifier-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateCompanyTermsConfig(
    //     @Body() body: SystemConfigUpdateCompanyTermsConfigRequest,
    // ): Promise<SystemConfigGetTermsConfigResponse> {
    //     return this._systemConfigService.updateCompanyTermsConfig(body);
    // }

    updateCompanyTermsConfig: builder.mutation<
      SystemConfigClientGetTermsConfigResponse,
      SystemConfigClientUpdateCompanyTermsConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-company-terms-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig', 'updateCompanyTermsFormConfig'],
    }),

    // @Get('/form-config/update-privacy-terms-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updatePrivacyTermsConfigFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //     return this._systemConfigService.updateCompanyTermsConfigFormConfig();
    // }
    getUpdatePrivacyTermsConfigFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/update-privacy-terms-config/`,
        method: 'GET',
      }),
      providesTags: ['*', 'updatePrivacyTermsConfigFormConfig'],
    }),

    // @Post('/update-company-terms-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updatePrivacyTermsConfig(
    //     @Body() body: SystemConfigClientUpdatePrivacyTermsConfigRequest,
    // ): Promise<SystemConfigClientGetTermsConfigResponse> {
    //     return this._systemConfigService.updatePrivacyTermsConfig(body);
    // }

    updatePrivacyTermsConfig: builder.mutation<
      SystemConfigClientGetTermsConfigResponse,
      SystemConfigClientUpdatePrivacyTermsConfigRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-privacy-terms-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig', 'updatePrivacyTermsConfigFormConfig'],
    }),
    //================= OTHER CONFIG ===================//
    //----------------- OPERATION CONFIG -----------------//

    // @Get('/get-operation-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //   getOperationConfig(): Promise<SystemConfigGetOperationConfigResponse> {
    //       return this._systemConfigService.getOperationConfig();
    //   }
    getOperationConfig: builder.query<SystemConfigGetOperationConfigResponse, void>({
      query: () => ({
        url: `${base_url}/get-operation-config/`,
        method: 'GET',
      }),
      providesTags: ['getOperationConfig'],
    }),

    //   @Post('/add-task-category/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //   addTaskCategory(
    //       @Body() body: SystemConfigAddTaskCategoryRequest,
    //   ): Promise<SystemConfigGetOperationConfigResponse> {
    //       return this._systemConfigService.addTaskCategory(body);
    //   }
    addTaskCategory: builder.mutation<
      SystemConfigGetOperationConfigResponse,
      SystemConfigAddTaskCategoryRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-task-category/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getOperationConfig'],
    }),

    //   @Post('/add-admin-user-sub-group/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //   addAdminUserSubGroup(
    //       @Body() body: SystemConfigAddAdminUserSubGroupRequest,
    //   ): Promise<SystemConfigGetOperationConfigResponse> {
    //       return this._systemConfigService.addAdminUserSubGroup(body);
    //   }
    addAdminUserSubGroup: builder.mutation<
      SystemConfigGetOperationConfigResponse,
      SystemConfigAddAdminUserSubGroupRequest
    >({
      query: (data) => ({
        url: `${base_url}/add-admin-user-sub-group/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getOperationConfig'],
    }),

    //   @Post('/enable-voucher-payment/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //   enableVoucherPayment(
    //       @Body() body: SystemConfigEnableVoucherPaymentRequest,
    //   ): Promise<SystemConfigEnableVoucherPaymentRequest> {
    //       return this._systemConfigService.enableVoucherPayment(body);
    //   }
    enableVoucherPayment: builder.mutation<
      SystemConfigGetOperationConfigResponse,
      SystemConfigEnableVoucherPaymentRequest
    >({
      query: (data) => ({
        url: `${base_url}/enable-voucher-payment/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getOperationConfig'],
    }),

    //   @Post('/disable-voucher-payment/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //   disableVoucherPayment(
    //       @Body() body: SystemConfigDisableVoucherPaymentRequest,
    //   ): Promise<SystemConfigGetOperationConfigResponse> {
    //       return this._systemConfigService.disableVoucherPayment(body);
    //   }
    disableVoucherPayment: builder.mutation<
      SystemConfigGetOperationConfigResponse,
      SystemConfigDisableVoucherPaymentRequest
    >({
      query: (data) => ({
        url: `${base_url}/disable-voucher-payment/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getOperationConfig'],
    }),

    //   @Get('/form-config/add-system-language/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //   addSystemLanguageFormConfig(): Promise<ClientGetFormConfigWithCheckResponse> {
    //       return this._systemConfigService.addSystemLanguageFormConfig();
    //   }
    addSystemLanguageFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/add-system-language/`,
        method: 'GET',
      }),
      providesTags: ['addSystemLanguageFormConfig'],
    }),

    //   @Post('/add-system-language/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    //   addSystemLanguage(
    //       @Body() body: SystemConfigAddLanguage,
    //   ): Promise<SystemConfigGetOperationConfigResponse> {
    //       return this._systemConfigService.addSystemLanguage(body);
    //   }
    addSystemLanguage: builder.mutation<
      SystemConfigGetOperationConfigResponse,
      SystemConfigAddLanguage
    >({
      query: (data) => ({
        url: `${base_url}/add-system-language/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getOperationConfig', 'addSystemLanguageFormConfig'],
    }),
    //--------------APP VERSION CONFIG---------------//

    // @Get('/form-config/update-app-version-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateSystemAppVersionFormConfig(): Promise<update-app-version-config> {
    //     return this._systemConfigService.updateSystemAppVersionFormConfig();
    // }
    updateSystemAppVersionFormConfig: builder.query<
      ClientGetFormConfigWithCheckResponse,
      void
    >({
      query: () => ({
        url: `${base_url}/form-config/update-app-version-config/`,
        method: 'GET',
      }),
      providesTags: ['*', 'updateSystemAppVersionFormConfig'],
    }),

    // @Post('/update-app-version-config/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateSystemAppVersionConfig(
    //     @Body() body: SystemConfigUpdateAppVersionRequest,
    // ): Promise<SystemConfigClientGetResponse> {
    //     return this._systemConfigService.updateSystemAppVersionConfig(body);
    // }

    updateSystemAppVersionConfig: builder.mutation<
      SystemConfigGetOperationConfigResponse,
      SystemConfigUpdateAppVersionRequest
    >({
      query: (data) => ({
        url: `${base_url}/update-app-version-config/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getSystemConfig', 'updateSystemAppVersionFormConfig'],
    }),
  }),
});
