import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useEffect, useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import type { ScreenPropPendingNotices } from '~/navigation/Navigation.types';

import { NoticeItem } from '../Notice.Item';
import { PendingNoticesList, PendingNoticesListNative } from './PendingNotice.List';

export const PendingNoticesScreen = (props: ScreenPropPendingNotices) => {
  return (
    <PageContainer>
      <PendingNoticesLayout {...props} />
    </PageContainer>
  );
};

export const PendingNoticesLayout = (props: ScreenPropPendingNotices) => {
  const pendingNoticesId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(props.route.params?.id);

  useEffect(() => {
    setId(pendingNoticesId);
  }, [pendingNoticesId]);

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <PendingNoticesList onPress={(item) => setId(item.id)} />
      ) : (
        <PendingNoticesListNative />
      )}
      {id ? <NoticeItem noticeId={id} onClose={() => setId(undefined)} /> : <></>}
    </ScreenTwoPageLayout>
  );
};
