import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  CustomerClientPassenger,
  RoutePrice,
  RouteTrip,
} from '@naus-code/naus-client-types';
import { useEffect } from 'react';

import { useBasketAttentionManager } from '~/components/BasketManager/BasketManager.Attention';
import { basketManager } from '~/components/BasketManager/BasketManager.slice';
import { BasketTickets } from '~/components/BasketManager/BasketManager.Tickets';
import { searchApi } from '~/components/Search/Search.api';
import { createPause } from '~/utils/utils.helper';

interface ModifyProps {
  initialRoutePrice: RoutePrice;
  purchaseId: string;
  ticketIds: string[];
  routeTrip: RouteTrip;
  // tripKeys: string[];
}

export const ModifyBasketPanel = (props: ModifyProps) => {
  const [getNewRoutePrice] = searchApi.useVerifyPricePurchaseModifyMutation();

  useBasketAttentionManager({
    options: {
      currency: props.initialRoutePrice.ccy,
      tripKeys: [props.routeTrip.key],
    },
  });

  useEffect(() => {
    (async () => {
      if (props.initialRoutePrice.trips[0].key !== props.routeTrip.key) {
        return;
      }

      basketManager.resumeState({
        routePrice: props.initialRoutePrice,
        selectedTrips: [props.routeTrip],
        overridePriceFetch: async (priceData) => {
          const newRoutePriceRes = await getNewRoutePrice({
            purchaseId: props.purchaseId,
            ticketIds: props.ticketIds,
            route: priceData,
          });

          if (isErrorResponse(newRoutePriceRes)) {
            return {
              routePrice: structuredClone(priceData),
              // verifyAttention
            };
          }

          return {
            routePrice: structuredClone(newRoutePriceRes.data.routePrice),
            // verifyAttention
          };
        },
        vehicleExtraValidation: {},
        passengerExtraValidation: {},
        savedPets: [],
        savedVehicles: [],
        savedPassengers: props.initialRoutePrice.passengers.map(
          (p) =>
            ({
              id: p.id,
              name: p.name,
              lastName: p.lastName,
              sex: p.sex ?? 'M',
              birthDate: p.birthDate,
              // loyalty: p.loyalty
            }) as CustomerClientPassenger,
        ),
      });

      await createPause(100);
      basketManager.fetchAndUpdatePrices();
    })();
  }, [props.purchaseId, props.ticketIds, props.initialRoutePrice, props.routeTrip]);

  return (
    <DelayRender>
      <VStack space="1">
        <BasketTickets routeTrips={[props.routeTrip]} />
      </VStack>
    </DelayRender>
  );
};
