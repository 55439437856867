import { CreateAdminUserRequest } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { useFormModal } from '~/components/Modals/FormModal';
import { usersApi } from '~/screens/Users/Users.api';
import { translate } from '~/utils/translation.utils';

export const useCreateAdminUserModal = () => {
  const { open: openForm, close: closeForm } = useFormModal();

  const [createUser] = usersApi.useCreateUserMutation();

  const useFormHookHandler = useCallback(() => {
    const { data: config, isLoading } = usersApi.useGetUserConfigCreateQuery();
    return { data: { config }, isLoading };
  }, []);

  const onConfirm = async ({ value }: { value: CreateAdminUserRequest['adminUser'] }) => {
    return await createUser({
      adminUser: {
        ...value,
        rules: {
          userRules: {},
        },
      },
    });
  };

  return {
    open: () => {
      openForm({
        modalTitle: translate('users.createUserInfo'),
        useFormHookHandler,
        onConfirm,
        loadingAction: false,
      });
    },
    close: () => {
      closeForm();
    },
  };
};
