import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { RouteTripNoDetails, SearchRouteMetaTrip } from '@naus-code/naus-client-types';

import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';

export const isRouteTripNoDetails = (
  trip: RouteTripNoDetails | SearchRouteMetaTrip,
): trip is RouteTripNoDetails => {
  return Object.hasOwn(trip, 'isFull');
};

export const isTripRouteFull = (
  trip: RouteTripNoDetails | SearchRouteMetaTrip,
): trip is RouteTripNoDetails => {
  return isRouteTripNoDetails(trip) && !!trip.isFull;
};

export const getTripPrice = (trip: RouteTripNoDetails | SearchRouteMetaTrip) => {
  const price = getPriceWithSymbol(trip.pricePp, trip.ccySymbol);
  if (isTripRouteFull(trip)) {
    if (trip.soldOut) {
      return '-';
    }
    if (trip.disabled) {
      return '-';
    }
  }
  if (trip.pricePp === 0 || !trip.pricePp) {
    return '-';
  }
  return price;
};

export const isTripDisabled = (trip: RouteTripNoDetails | SearchRouteMetaTrip) => {
  if (isTripRouteFull(trip)) {
    return (
      !!trip.disabled || !!trip.soldOut || dayjs(trip.bookAbilityExpire).isBefore(dayjs())
    );
  }
  return false;
};

const TRIP_BOOK_THRESHOLD_MIN = 10;
export const getTripInnerNotice = (
  trip: RouteTripNoDetails | SearchRouteMetaTrip,
): string | undefined => {
  if (isTripRouteFull(trip)) {
    if (dayjs(trip.startTimestamp).isBefore(dayjs())) {
      return translate('ticketSearch.pastTrip');
    }

    if (dayjs(trip.bookAbilityExpire).isBefore(dayjs())) {
      return translate('ticketSearch.tooCloseToDep');
    }

    if (
      dayjs(trip.bookAbilityExpire).diff(dayjs(), 'minutes') < TRIP_BOOK_THRESHOLD_MIN
    ) {
      return translate('ticketSearch.tooCloseToDep');
    }

    if (trip.soldOut) {
      return translate('ticketSearch.soldOut');
    }

    if (trip.disabled) {
      return trip.disabled.message ?? translate('ticketSearch.unavailable');
    }

    if (trip.pricePp === 0 || trip.pricePp === undefined) {
      return translate('ticketSearch.unavailable');
    }

    if (trip.isDisabledCompanyModify) {
      return 'Different operator';
    }
  }
};
