import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';

import { supportConfigApi } from '../supportConfig.api';
import { useUpdateOffice } from './Modals/Office.Update';
import { OfficeInfo } from './OfficeInfo';
import { OfficeSpecialDates } from './OfficeSpecialDates';
import { OfficeTimes } from './OfficeTimes';

export const SupportConfigOfficeItem = ({ officeId }: { officeId: string }) => {
  const { data } = supportConfigApi.useGetSupportConfigQuery();
  const { openModal: openUpdateModal } = useUpdateOffice({ officeId });

  const officeInfo = data?.config.officeConfig.officeOperatingConfig.find(
    (officeInfo) => {
      if (officeInfo.officeId === officeId) {
        return officeInfo;
      }
    },
  );

  if (officeInfo === undefined || data?.check === undefined) {
    return <></>;
  }

  return (
    <>
      <PageHeader
        title={officeInfo.officeName}
        withBack={isNativeMobile}
        iconRight={{
          color: 'monochrome',
          icon: 'edit',
          onPress: () => openUpdateModal(),
        }}
      />
      <VStack space="2.5" flex={1} p="2.5">
        <OfficeInfo officeInfo={officeInfo} check={data.check} />
        <OfficeTimes officeSchedule={officeInfo.defaultDaysOfTheWeek} />
        {officeInfo.specialDates ? (
          <OfficeSpecialDates officeSpecialDates={officeInfo.specialDates} />
        ) : (
          <></>
        )}
      </VStack>
    </>
  );
};
