import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { isRTKErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { CompanyConfigClientCreateOperatorRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { getPlugins } from '~/components/Form/Form.Plugins';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { companyConfigApi } from '../../CompanyConfigs.api';

export const AddCompanyOperatorConfigModalKey = 'AddModal-CompanyOperatorConfig';
const AddCompanyOperatorConfigModal = ({ companyId }: { companyId: string }) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const { data: companyConfigCreateConfig } =
    companyConfigApi.useCreateFerryCompanyOperatorConfigFormConfigQuery();

  const [createCompanyOperatorConfig, { isLoading: isCreatingCompanyOperatorConfig }] =
    companyConfigApi.useAddFerryCompanyOperatorConfigMutation();

  if (companyConfigCreateConfig === undefined) {
    return null;
  }

  const check = companyConfigCreateConfig.check;
  return (
    <>
      <Modal.Header
        title={translate('companyConfig.newCompanyOperatorConfig')}
        subtitle={translate('companyConfig.enterNewCompanyOperatorConfig')}
        divider
        onPress={() => {
          closeModal(AddCompanyOperatorConfigModalKey);
        }}
      />
      <Modal.Body p="0">
        <CreateCompanyOperatorConfigForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingCompanyOperatorConfig}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              CompanyConfigClientCreateOperatorRequest['operator']
            >();

          if (valid) {
            const response = await createCompanyOperatorConfig({
              companyId,
              operator: value,
              check,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddCompanyOperatorConfigModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddCompanyOperatorConfigModalKey);
        }}
      />
    </>
  );
};

export const useAddCompanyOperatorConfig = () => {
  const { openModal } = useModal();

  return {
    openModal: (companyId: string) => {
      openModal(<AddCompanyOperatorConfigModal companyId={companyId} />, {
        key: AddCompanyOperatorConfigModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
const CreateCompanyOperatorConfigForm = ({ form }: { form: FormHandler }) => {
  const {
    data: companyConfigCreateConfig,
    isLoading: isLoadingCompanyOperatorConfigConfig,
  } = companyConfigApi.useCreateFerryCompanyOperatorConfigFormConfigQuery();

  const [addImage] =
    companyConfigApi.useAddFerryCompanyOperatorConfigImageTokenMutation();

  const uploadImage = async (formData: FormData) => {
    const res = await addImage(formData);

    if (isRTKErrorResponse(res)) {
      throw res.error;
    }

    return res.data;
  };

  if (companyConfigCreateConfig === undefined) {
    return null;
  }

  const formConfig = companyConfigCreateConfig.config;

  return (
    <FormContent
      isLoading={isLoadingCompanyOperatorConfigConfig}
      form={companyConfigCreateConfig}
      flex={1}
    >
      {companyConfigCreateConfig ? (
        <FormBuilder
          data={formConfig}
          form={form}
          extensions={getPlugins({ onImageUpload: uploadImage })}
        />
      ) : null}
    </FormContent>
  );
};
