import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { SupportConfigUpdateOfficeConfigRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { getPlugins } from '~/components/Form/Form.Plugins';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { supportConfigApi } from '../../supportConfig.api';

export const UpdateOfficeModalKey = 'UpdateModal-Office';
const UpdateOfficeModal = ({ officeId }: { officeId: string }) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [updateOffice, { isLoading: isUpdatingOffice }] =
    supportConfigApi.useUpdateOfficeMutation();
  const { data: updateOfficeConfig } =
    supportConfigApi.useGetUpdateOfficeFormConfigQuery(officeId);

  if (updateOfficeConfig === undefined) {
    return <></>;
  }
  if (updateOfficeConfig?.check === undefined) {
    return <></>;
  }

  return (
    <Modal.Container>
      <Modal.Header
        title={translate('supportConfig.updateOffice')}
        subtitle={translate('supportConfig.updateOfficeDetails')}
        divider
        onPress={() => {
          closeModal(UpdateOfficeModalKey);
        }}
      />
      <ModalScrollView>
        <UpdateOfficeForm form={formRef.current} officeId={officeId} />
      </ModalScrollView>
      <Modal.Footer
        divider
        isLoading={isUpdatingOffice}
        onConfirm={async () => {
          const { valid, value } =
            formRef.current.getValue<
              SupportConfigUpdateOfficeConfigRequest['officeConfig']
            >();

          if (valid) {
            const response = await updateOffice({
              check: updateOfficeConfig.check,
              officeConfig: value,
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(UpdateOfficeModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(UpdateOfficeModalKey);
        }}
      />
    </Modal.Container>
  );
};

export const useUpdateOffice = ({ officeId }: { officeId: string }) => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<UpdateOfficeModal officeId={officeId} />, {
        key: UpdateOfficeModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};
const UpdateOfficeForm = ({
  form,
  officeId,
}: {
  form: FormHandler;
  officeId: string;
}) => {
  const { data: updateOfficeConfig, isLoading: isLoadingOfficeConfig } =
    supportConfigApi.useGetUpdateOfficeFormConfigQuery(officeId);

  if (updateOfficeConfig === undefined) {
    return <></>;
  }

  const { config } = updateOfficeConfig;

  return (
    <FormContent isLoading={isLoadingOfficeConfig} form={updateOfficeConfig} flex={1}>
      {updateOfficeConfig ? (
        <FormBuilder data={config} form={form} extensions={getPlugins()} />
      ) : null}
    </FormContent>
  );
};
