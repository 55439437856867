import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { isRTKErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { CreateAreaRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { getPlugins } from '~/components/Form/Form.Plugins';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { areaLocationApi } from './AreaLocations.api';

export const AddAreaLocationModalKey = 'AddModal-AreaLocation';
const AddAreaLocationModal = () => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [createAreaLocation, { isLoading: isCreatingAreaLocation }] =
    areaLocationApi.useCreateAreaLocationMutation();

  return (
    <>
      <Modal.Header
        title={translate('areaLocations.newAreaLocation')}
        subtitle={translate('areaLocations.enterNewAreaLocation')}
        divider
        onPress={() => {
          closeModal(AddAreaLocationModalKey);
        }}
      />
      <Modal.Body p="0">
        <CreateAreaLocationForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingAreaLocation}
        onConfirm={async () => {
          const { valid, value } = formRef.current.getValue<CreateAreaRequest['area']>();

          if (valid) {
            const response = await createAreaLocation({ area: value });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddAreaLocationModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddAreaLocationModalKey);
        }}
      />
    </>
  );
};

export const useAddAreaLocation = () => {
  const { openModal } = useModal();

  return {
    openModal: () => {
      openModal(<AddAreaLocationModal />, {
        key: AddAreaLocationModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};

const CreateAreaLocationForm = ({ form }: { form: FormHandler }) => {
  const { data: areaLocationCreateConfig, isLoading: isLoadingAreaLocationConfig } =
    areaLocationApi.useGetAreaLocationFormConfigQuery();

  const [uploadAreaImage] = areaLocationApi.useCreateAreaLocationImageUploadMutation();

  const uploadImage = async (formData: FormData) => {
    const res = await uploadAreaImage(formData);

    if (isRTKErrorResponse(res)) {
      throw res.error;
    }

    return res.data;
  };

  return (
    <FormContent
      isLoading={isLoadingAreaLocationConfig}
      form={areaLocationCreateConfig}
      flex={1}
    >
      {areaLocationCreateConfig ? (
        <FormBuilder
          data={areaLocationCreateConfig}
          form={form}
          extensions={getPlugins({ onImageUpload: uploadImage })}
        />
      ) : null}
    </FormContent>
  );
};
