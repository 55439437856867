import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  isNativeMobile,
  isWeb,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';
import { SupportRequestOptions } from '@naus-code/naus-client-types';
import React from 'react';

import { LinkText } from '~/components/LinkText';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

import { usePaymentSession } from '../Modals/PaymentSession';
import { RefundMethodInfo } from '../RequestOptions/RequestOptions';
import { ModifySnapshot } from '../RequestOptions/RequestOptions.ModifySnapshot';

export const SupportRequestDataHeader = () => {
  return (
    <>
      <View
        px="2.5"
        py="3"
        bg="monochrome-extraLight"
        justify="center"
        style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
      >
        <AppText.MiscTag type="heading1">
          {translate('supportRequests.data')}
        </AppText.MiscTag>
      </View>
      <Divider bg="monochrome-light" style={{ maxWidth: isWeb ? 400 : undefined }} />
    </>
  );
};
export const SupportRequestDataContent = ({
  supportRequestInfo,
}: {
  supportRequestInfo: SupportRequestBackendClient;
}) => {
  const { open: openPaymentSession } = usePaymentSession();

  return (
    <VStack
      flex={isNativeMobile ? 1 : undefined}
      style={{ maxWidth: isWeb ? 400 : undefined, height: '100%' }}
    >
      {supportRequestInfo.requestOptions && (
        <VStack space="2.5" px="3">
          <VStack flex={1} space="2">
            <ModifySnapshot
              options={supportRequestInfo.requestOptions as SupportRequestOptions}
            />
            <RefundMethodInfo options={supportRequestInfo.requestOptions} />
          </VStack>
          <Divider />
        </VStack>
      )}
      {supportRequestInfo.paymentSession && (
        <VStack space="2.5" px="3">
          <HStack flex={1} justify="flex-end">
            <LinkText
              onPress={() => {
                if (supportRequestInfo.paymentSession) {
                  openPaymentSession(
                    supportRequestInfo.paymentSession,
                    supportRequestInfo.bookingId,
                  );
                }
              }}
            >
              {translate('supportRequests.paymentSession')}
            </LinkText>
          </HStack>
          <Divider />
        </VStack>
      )}
    </VStack>
  );
};
