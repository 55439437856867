import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { HStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { TransactionClientGetListResponse } from '@naus-code/naus-admin-types';
import { useMemo } from 'react';

import { CompanyAnalysis } from '~/screens/Finance/Transactions/Analysis/Analysis.Company';
import { CustomerAnalysis } from '~/screens/Finance/Transactions/Analysis/Analysis.Customer';
import { OperatorAnalysis } from '~/screens/Finance/Transactions/Analysis/Analysis.Operator';
import {
  getAnalysis,
  useTransactionList,
  useTransactionPeriodData,
} from '~/screens/Finance/Transactions/Transactions.utils';

export const AnalysisOverview = () => {
  const { data, isLoading } = useTransactionPeriodData();
  return (
    <DelayRender isLoading={isLoading}>
      {data ? <AnalysisOverviewLayout data={data} /> : null}
    </DelayRender>
  );
};

const AnalysisOverviewLayout = ({ data }: { data: TransactionClientGetListResponse }) => {
  const { operatorList, customerList } = useTransactionList({ transactions: data.list });
  const groupedAnalysis = useMemo(
    () =>
      getAnalysis([...operatorList, ...customerList], { operator: true, customer: true }),
    [operatorList, customerList],
  );

  return (
    <HStack align="flex-start" space="3">
      <OperatorAnalysis groupedAnalysis={groupedAnalysis} />
      <CustomerAnalysis groupedAnalysis={groupedAnalysis} />
      <CompanyAnalysis groupedAnalysis={groupedAnalysis} />
    </HStack>
  );
};
