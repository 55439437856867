import {
  TransactionClientGetListResponse,
  TransactionClientGetPeriodListRequest,
  TransactionClientGetResponse,
} from '@naus-code/naus-admin-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'transaction';

export const transactionApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionByBooking: builder.query<TransactionClientGetListResponse, string>({
      query: (id) => ({
        url: `${base_url}/get-by-booking-id/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getTransactionByBooking'],
    }),
    deleteTransaction: builder.mutation<void, string>({
      query: (id) => ({
        url: `${base_url}/delete/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['getTransactionByBooking'],
    }),
    // @Get('/get/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getTransaction(@Params('id') id: string): Promise<TransactionClientGetResponse> {
    //     return this._transactionService.getTransaction(id);
    // }
    getTransaction: builder.query<TransactionClientGetResponse, string>({
      query: (id) => ({
        url: `${base_url}/get/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getTransaction'],
    }),

    // @Post('/get-period/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getTransactionsPeriod(
    //     @Body() body: TransactionClientGetPeriodListRequest,
    // ): Promise<TransactionClientGetListResponse> {
    //     return this._transactionService.getTransactionsPeriod(body);
    // }
    getTransactionsPeriod: builder.query<
      TransactionClientGetListResponse,
      TransactionClientGetPeriodListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-period`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'getTransactionsPeriod'],
    }),
  }),
});
