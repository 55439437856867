import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { StackProps, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

export const FormContent = <T,>({
  isLoading,
  form,
  children,
  ...props
}: StackProps & { isLoading: boolean; form: T | undefined }) => {
  const inProgress = isLoading || !form;

  return (
    <VStack
      flex={1}
      {...props}
      style={[
        isWeb &&
          inProgress && {
            minHeight: 200,
          },
        props.style,
      ]}
    >
      {inProgress ? (
        <View flex={1} justify="center" align="center">
          <LoadingIndicator />
        </View>
      ) : (
        children
      )}
    </VStack>
  );
};
