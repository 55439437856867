import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { useState } from 'react';

import { PageContainer, ScreenTwoPageLayout } from '~/components/Page/Page.Container';
import { ScreenPropPurchaseSupportRequests } from '~/navigation/Navigation.types';

import { SupportRequestItem } from '../SupportRequestItem';
import { SupportRequestList, SupportRequestListNative } from './SupportRequest.List';

export const PurchaseSupportRequestsScreen = (
  props: ScreenPropPurchaseSupportRequests,
) => {
  return (
    <PageContainer>
      <PurchaseSupportRequestsLayout {...props} />
    </PageContainer>
  );
};

export const PurchaseSupportRequestsLayout = (
  props: ScreenPropPurchaseSupportRequests,
) => {
  const supportRequestId = props.route.params?.id;
  const [id, setId] = useState<string | undefined>(supportRequestId);
  const purchaseId = props.route.params.purchaseId;

  return (
    <ScreenTwoPageLayout>
      {isWeb ? (
        <SupportRequestList
          purchaseId={purchaseId}
          supportRequestId={id}
          onPress={(supportRequest) => setId(supportRequest.id)}
        />
      ) : (
        <SupportRequestListNative purchaseId={purchaseId} />
      )}
      {id ? (
        <SupportRequestItem supportId={id} onClose={() => setId(undefined)} />
      ) : (
        <></>
      )}
    </ScreenTwoPageLayout>
  );
};
