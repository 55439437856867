import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  CreateVesselRequest,
  GetVesselResponse,
  UpdateVesselRequest,
  VesselListResponse,
} from '@naus-code/naus-admin-types';
import { ImageUploadResponse } from '@naus-code/naus-client-types';

import { rootApi } from '~/store/redux.utils';

const base_url = 'data/vessel';

export const vesselApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getVessel: builder.query<GetVesselResponse, string>({
      query: (id) => ({
        url: `${base_url}/get-vessel/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getVessel'],
    }),

    getVesselList: builder.query<VesselListResponse, void>({
      query: () => ({
        url: `${base_url}/get-list/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getVesselList'],
    }),

    createVesselFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/create-vessel/`,
        method: 'GET',
      }),
      providesTags: ['*', 'createVesselFormConfig'],
    }),

    createVessel: builder.mutation<GetVesselResponse, CreateVesselRequest>({
      query: (data) => ({
        url: `${base_url}/create/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['createVessel'],
    }),

    // @Get('/form-config/update-vessel/:id', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // updateVesselFormConfig(@Params('id') id: string): Promise<ConfigForData> {
    //     return this._vesselService.getVesselUpdateForm(id);
    // }

    getUpdateVesselFormConfig: builder.query<ConfigForData, string>({
      query: (vesselId) => ({
        url: `${base_url}/form-config/update-vessel/${vesselId}`,
        method: 'GET',
        // data,
      }),
      providesTags: ['*', 'getUpdateVesselFormConfig'],
    }),

    // @Post('/update-vessel/', [applyRoleValidation(['ADMIN', 'SYSTEM']), applyDevValidation()])
    // updateVessel(@Body() body: UpdateVesselRequest): Promise<GetVesselResponse> {
    //     return this._vesselService.updateVessel(body);
    // }
    updateVessel: builder.mutation<GetVesselResponse, UpdateVesselRequest>({
      query: (data) => ({
        url: `${base_url}/update-vessel/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getVessel', 'getVesselList'],
    }),

    // @Post('/img-upload/create-vessel/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // createVesselUploadImage(@RequestDeco() body: Request): Promise<ImageUploadResponse> {
    //     return this._vesselService.getVesselCreateImageToken(body);
    // }
    // ceateVesselUploadImage

    createVesselUploadImage: builder.mutation<ImageUploadResponse, FormData>({
      query: (data) => ({
        url: `${base_url}/img-upload/create-vessel/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['getUpdateVesselFormConfig', 'getVessel'],
    }),

    // @Post('/generate-image/', [applyRoleValidation(['ADMIN', 'SYSTEM']), applyDevValidation()])
    // generateImage(@RequestDeco() body: Request): Promise<any> {
    //     return this._vesselService.generateImage(body);
    // }
    generateImage: builder.mutation<FormData, { formData: FormData; vesselId: string }>({
      query: ({ formData, vesselId }) => ({
        url: `${base_url}/generate-image/${vesselId}`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),

    //   @Post('/update-vessel-image/id', [
    //     applyRoleValidation(['ADMIN', 'SYSTEM']),
    //     applyDevValidation(),
    // ])
    // updateVesselImage(
    //     @RequestDeco() body: Request,
    //     @Params('id') id: string,
    // ): Promise<GetVesselResponse> {
    //     return this._vesselService.updateVesselImages(body, id);
    // }

    updateVesselImage: builder.mutation<
      GetVesselResponse,
      { formData: FormData; vesselId: string }
    >({
      query: (data) => ({
        url: `${base_url}/update-vessel-image/${data.vesselId}`,
        method: 'POST',
        data: data.formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['getUpdateVesselFormConfig', 'getVessel', 'getVesselList'],
    }),
  }),
});
