import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import {
  ConfigDetectorCompanyData,
  FerryCompanyExtraTicketConfig,
} from '@naus-code/naus-server-common-types';
import { useRef } from 'react';

import { companyDataConfigApi } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/companyDataConfigsApi';
import { useMissingExtraStore } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Extras/MissingExtraConfigs.store';

import EXTRA_ITEM_CONFIG from './MissingExtraConfigs.Config.json';

export type MissingExtraItem = ConfigDetectorCompanyData<FerryCompanyExtraTicketConfig>;

const ModalKey = 'useMissingExtraItemModal';

const MissingExtraItem = ({ item }: { item: MissingExtraItem }) => {
  const { closeModal } = useModal();
  const { data, isLoading } = companyDataConfigApi.useGetMissingExtrasQuery();
  const editTicket = useMissingExtraStore((s) => s.editTicket);
  const formRef = useRef(new FormHandler());
  return (
    <Modal.Container>
      <Modal.Header title={item.key} onPress={() => closeModal(ModalKey)} />
      <Modal.Body flex={1}>
        <DelayRender isLoading={isLoading}>
          {data ? (
            <FormBuilder
              form={formRef.current}
              data={(EXTRA_ITEM_CONFIG as ConfigForData) ?? data.config}
              initialValue={item}
            />
          ) : null}
        </DelayRender>
      </Modal.Body>
      <Modal.Footer
        confirm="Save & close"
        divider
        confirmProps={{
          disabled: isLoading || !data,
        }}
        onConfirm={() => {
          const { valid, value } = formRef.current.getValue<MissingExtraItem>();
          if (valid && data) {
            editTicket(value);
            closeModal(ModalKey);
          }
        }}
        onCancel={() => closeModal(ModalKey)}
      />
    </Modal.Container>
  );
};

export const useMissingExtraItemModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (item: MissingExtraItem) => {
      openModal(<MissingExtraItem item={item} />, { key: ModalKey });
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};
