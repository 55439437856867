import {
  Divider,
  DottedLine,
} from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { notEmpty } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  SelectTicketEditDiscountItem,
  SelectTicketEditPassengerCabinItem,
  SelectTicketEditPassengerListItemEdit,
  SelectTicketEditPassengerSeatingItem,
  SelectTicketEditVehicleListItemEdit,
} from '@naus-code/naus-client-common/src/BasketManager/types';
import { RouteTrip } from '@naus-code/naus-client-types';

import { usePassengerEdit } from '~/components/BasketManager/BasketManager.hooks';
import { BasketLegPricing } from '~/components/BasketManager/BasketManager.Pricing';
import { basketManager } from '~/components/BasketManager/BasketManager.slice';
import { getPriceWithSymbol } from '~/screens/Support/Purchases/purchase.utils';
import { useAppSelector } from '~/store/redux.utils';
import { translate } from '~/utils/translation.utils';
import { onlyUniqueByFunc } from '~/utils/utils.helper';

export const BasketTickets = ({ routeTrips }: { routeTrips: RouteTrip[] }) => {
  const basket = useAppSelector((s) => s.basket);

  const trips = basket.selectTicketsEdit.trips;
  const selectTicketTrips = basket.selectTickets.trips;

  return (
    <VStack space="3">
      {trips.map((trip) => {
        const selectTrip = selectTicketTrips.find(
          (selectTrip) => selectTrip.tripKey === trip.tripKey,
        );
        return (
          <VStack key={trip.tripKey} space="3">
            <VStack space="3">
              {trip.passengers.map((pTicket, index) => {
                const info = pTicket.carouselItem;

                const passenger = selectTrip?.passengers.find(
                  (p) => p.passengerId === pTicket.passengerId,
                );
                const loyalty = [
                  passenger?.loyalty?.loyaltyName,
                  passenger?.loyalty?.loyaltyNumber,
                ]
                  .filter(notEmpty)
                  .join(' ');

                const ccy = routeTrips.find((t) => t.ccySymbol)?.ccySymbol || 'EUR';

                if (!passenger) {
                  return null;
                }

                const fullValue =
                  (passenger.value ?? 0) +
                  passenger.extras.reduce((memo, next) => memo + (next.value ?? 0), 0);

                const priceDiff = getPriceWithSymbol(
                  passenger.value !== undefined ? fullValue : undefined,
                  ccy,
                );

                return (
                  <VStack space="3" key={pTicket.passengerId}>
                    {index !== 0 && <DottedLine />}
                    <Text.Body2Regular>
                      {`Passenger ${info.index + 1} - ${info.name ?? 'Guest'}`}
                    </Text.Body2Regular>
                    {loyalty.length ? (
                      <Text.Small>
                        {`${translate('basketManager.loyalty')}: ${loyalty}`}
                      </Text.Small>
                    ) : null}

                    {passenger?.discount ? (
                      <Text.Small>
                        {`${translate('basketManager.discount')}: ${passenger.discount}`}
                      </Text.Small>
                    ) : null}
                    <PassengerInfo passengerId={pTicket.passengerId} />
                    <HStack space="3">
                      <SeatingTickets ticket={pTicket} tripKey={trip.tripKey} ccy={ccy} />
                      <PassengerDiscount ticket={pTicket} tripKey={trip.tripKey} />
                    </HStack>
                    <BasketLegPricing tripKey={trip.tripKey} modify value={priceDiff} />
                  </VStack>
                );
              })}
              <Divider />
              {trip.vehicles.map((vTicket, index) => {
                const info = vTicket.carouselItem;

                const vehicle = selectTrip?.vehicles.find(
                  (p) => p.vehicleId === vTicket.vehicleId,
                );
                const loyalty = [
                  vehicle?.loyalty?.loyaltyName,
                  vehicle?.loyalty?.loyaltyNumber,
                ]
                  .filter(notEmpty)
                  .join(' ');

                const ccy = routeTrips.find((t) => t.ccySymbol)?.ccySymbol ?? 'EUR';

                if (!vehicle) {
                  return null;
                }

                const fullValue =
                  (vehicle.value ?? 0) +
                  vehicle.extras.reduce((memo, next) => memo + (next.value ?? 0), 0);

                const priceDiff = getPriceWithSymbol(
                  vehicle.value !== undefined ? fullValue : undefined,
                  ccy,
                );
                return (
                  <VStack space="3" key={vTicket.vehicleId}>
                    {index !== 0 && <DottedLine />}
                    <Text.Body2Regular>
                      {`${translate('basketManager.vehicle')} ${info.index + 1} - ${
                        info.name ?? translate('basketManager.guest')
                      }`}
                    </Text.Body2Regular>
                    {loyalty.length ? (
                      <Text.Small>
                        {`${translate('basketManager.loyalty')}: ${loyalty}`}
                      </Text.Small>
                    ) : null}

                    {vehicle?.discount ? (
                      <Text.Small>
                        {`${translate('basketManager.discount')}: ${vehicle.discount}`}
                      </Text.Small>
                    ) : null}
                    <VehicleInfo vehicleId={vTicket.vehicleId} />
                    <VehicleDiscounts ticket={vTicket} tripKey={trip.tripKey} />
                    <BasketLegPricing tripKey={trip.tripKey} value={priceDiff} modify />
                  </VStack>
                );
              })}
            </VStack>
          </VStack>
        );
      })}
    </VStack>
  );
};

const PassengerInfo = ({ passengerId }: { passengerId: string }) => {
  const passenger = usePassengerEdit(passengerId);

  if (!passenger) {
    // console.error('Error: No passenger found, ', passengerId);
    showToast(`${translate('basketManager.noPassenger')} ${passengerId}`, {
      type: 'error',
    });
    return null;
  }

  return (
    <HStack space="3">
      {/* <SelectInputBase
        feedback=""
        noFeedback
        label={translate('basketManager.sex')}
        type="single-select"
        options={passenger.inputArea.genderList.map((g) => ({
          value: g.sex,
          label: `${g.sex}${g.disabled ? ' [DISABLED]' : ''}`,
        }))}
        values={passenger.inputArea.genderList
          .filter((g) => g.isSelected)
          .map((g) => g.sex)}
        setValues={(newValue) => {
          basketManager.changePassengerGender(passengerId, (newValue?.[0] ?? 'M') as any);
        }}
      /> */}

      {/* <SelectInputBase
        feedback=""
        noFeedback
        label={translate('basketManager.ageGroup')}
        type="single-select"
        options={passenger.inputArea.categoryList.map((c) => ({
          label: `${c.minAge}-${c.maxAge}${c.disabled ? ' [DISABLED]' : ''}`,
          value: `${c.minAge}-${c.maxAge}`,
        }))}
        values={passenger.inputArea.categoryList
          .filter((c) => c.selected)
          .map((c) => `${c.minAge}-${c.maxAge}`)}
        setValues={(newValue) => {
          if (!newValue) {
            return;
          }

          const stringAge = newValue[0].split('-');
          basketManager.changePassengerAgeGroup(passengerId, {
            minAge: Number(stringAge[0]),
            maxAge: Number(stringAge[1]),
          });
        }}
      /> */}
    </HStack>
  );
};

// const VehicleInfo = ({ vehicleId }: { vehicleId: string }) => {
//   const vehicle = useVehicleEdit(vehicleId);

//   if (!vehicle) {
//     // console.error('Error: No vehicle found, ', vehicleId);
//     showToast(`${translate('basketManager.noVehicle')} ${vehicleId}`, {
//       type: 'error',
//     });
//     return null;
//   }

//   return (
//     <SelectInputBase
//       feedback=""
//       noFeedback
//       label={translate('basketManager.lengthOfVehicle')}
//       type="single-select"
//       options={vehicle.inputArea.lengthList.map((l) => ({
//         label: `${l.minLength}-${l.maxLength}${l.disabled ? ' [DISABLED]' : ''}`,
//         value: `${l.minLength}-${l.maxLength}`,
//       }))}
//       values={vehicle.inputArea.lengthList
//         .filter((l) => l.selected)
//         .map((l) => `${l.minLength}-${l.maxLength}`)}
//       setValues={(newValue) => {
//         if (!newValue) {
//           return;
//         }

//         const stringLen = newValue[0].split('-');
//         basketManager.changeVehicleSizeGroup(vehicleId, {
//           minLength: Number(stringLen[0]),
//           maxLength: Number(stringLen[1]),
//         });
//       }}
//     />
//   );
// };

const VehicleDiscounts = ({
  tripKey,
  ticket,
}: {
  tripKey: string;
  ticket: SelectTicketEditVehicleListItemEdit;
}) => {
  const discountTickets: SelectTicketEditDiscountItem[] = (
    [
      ...(ticket.inputArea?.discountsMinimized ?? []),
      ...(ticket.inputArea.discounts ?? []),
    ] as any[]
  )
    .filter(
      onlyUniqueByFunc((item) => {
        if (item.type !== 'break') {
          return item.code;
        }
        return '';
      }),
    )
    .filter((item) => item.type !== 'break');

  const options = [
    ...discountTickets,
    { name: 'Full fare', discountCode: '', disabled: false },
  ].map((d) => ({
    label: `${d.name}${d.disabled ? ' [DISABLED]' : ''}`,
    value: d.discountCode,
  }));

  const value = discountTickets.find((s) => s.isSelected);
  return (
    <SelectInputBase
      feedback=""
      noFeedback
      label={translate('basketManager.discounts')}
      type="single-select"
      options={options}
      values={[value?.discountCode ?? '']}
      setValues={(newValue) => {
        if (!newValue) {
          return;
        }

        if (newValue[0]) {
          basketManager.changeVehicleDiscount(ticket.vehicleId, tripKey, newValue[0]);
        } else {
          basketManager.removeVehicleDiscount(ticket.vehicleId, tripKey);
        }
      }}
    />
  );
};

const PassengerDiscount = ({
  tripKey,
  ticket,
}: {
  tripKey: string;
  ticket: SelectTicketEditPassengerListItemEdit;
}) => {
  const discountTickets: SelectTicketEditDiscountItem[] = (
    [
      ...(ticket.inputArea.discounts || ticket.inputArea?.discountsMinimized || []),
    ] as any[]
  ).filter((item) => item.type !== 'break');

  const options = [
    ...discountTickets,
    { name: 'Full fare', discountCode: '', disabled: false },
  ].map((d) => ({
    label: `${d.name}${d.disabled ? ' [DISABLED]' : ''}`,
    value: d.discountCode,
  }));

  const value = discountTickets.find((s) => s.isSelected);
  return (
    <SelectInputBase
      feedback=""
      noFeedback
      label={translate('basketManager.discounts')}
      type="single-select"
      options={options}
      values={[value?.discountCode ?? '']}
      setValues={(newValue) => {
        if (!newValue) {
          return;
        }

        if (newValue[0]) {
          basketManager.changePassengerDiscount(ticket.passengerId, tripKey, newValue[0]);
        } else {
          basketManager.removePassengerDiscount(ticket.passengerId, tripKey);
        }
      }}
    />
  );
};

const SeatingTickets = ({
  tripKey,
  ticket,
  ccy,
}: {
  ccy: string;
  tripKey: string;
  ticket: SelectTicketEditPassengerListItemEdit;
}) => {
  const seatingTickets: (
    | SelectTicketEditPassengerSeatingItem
    | SelectTicketEditPassengerCabinItem
  )[] = (
    [...(ticket.inputArea.seating || ticket.inputArea?.seatingMinimized || [])] as any[]
  ).filter((item) => item.type !== 'break');

  const options = seatingTickets
    .map((option) => {
      if (option.type === 'passenger') {
        const limited =
          option.limitedAvailability !== undefined
            ? ` [${translate('basketManager.limited')}: ${option.limitedAvailability}]`
            : '';
        // const limited = option.limitedAvailability !== undefined ? `Limited: ${option.limitedAvailability}` : '';
        const price = getPriceWithSymbol(option.value, ccy);

        return {
          value: option.code,
          label: `${option.name} - ${price}${limited}`,
        };
      }

      const capacity =
        option.capacity !== undefined
          ? `[${translate('basketManager.capacity')}: ${option.capacity}]`
          : '';

      const limitedAvailability =
        option.limitedAvailability !== undefined
          ? `[${translate('basketManager.ticketsLeft')}: ${option.limitedAvailability}]`
          : '';

      const price = getPriceWithSymbol(option.value, ccy);

      const seatingFacilitiesList = [
        option.balcony ? `Balcony` : null,
        option.pure ? `Pure` : null,
        option.basin ? 'Basin' : undefined,
        option.bunk ? 'Bunk' : undefined,
        option.wc ? 'WC' : undefined,
        option.pet ? 'Pet' : undefined,
        option.window ? 'Window' : undefined,
        option.luxury ? 'Luxury' : undefined,
      ]
        .filter(notEmpty)
        .join(', ');

      const seatingFacilities = seatingFacilitiesList.length
        ? `[${seatingFacilitiesList}]`
        : '';

      return {
        value: option.code,
        label: `${option.name} - ${price} ${seatingFacilities}${capacity}${limitedAvailability}`,
      };
    })
    .filter(notEmpty);

  const value = seatingTickets.find((s) => s.isSelected);
  return (
    <SelectInputBase
      feedback=""
      noFeedback
      label={translate('basketManager.seating')}
      type="single-select"
      options={options}
      values={[value?.code || options[0].value]}
      setValues={(newValue) => {
        if (!newValue) {
          return;
        }

        basketManager.changePassengerTicket(ticket.passengerId, tripKey, newValue[0]);
      }}
    />
  );
};
