import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

interface OpenConfig {
  canOpen: boolean;
  canOpenAfterModify: boolean;
  openValidityDays: number;
  openValidityFrom: 'DATE_PURCHASED' | 'DATE_CONVERTED';
  openValidityTillEndOfPurchaseYear: boolean;
  canSubstituteDifferentRoute: boolean;
  canSubstituteDifferentPersons: boolean;
  requiresPrintForOpen: boolean;
  canOpenViaApi: boolean;
  canOpenPartialViaApi: boolean;
  allowCustomerToAutoOpen: boolean;
  allowCustomerToAutoOpenPartial: boolean;
}

export const OpenInfo = ({ openConfig }: { openConfig: OpenConfig }) => {
  return (
    <SwitchStack py="2.5" space="2.5">
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canOpen')}
          </AppText.ExtraSmall>
          <Text.Small> {openConfig.canOpen ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canOpenAPI')}
          </AppText.ExtraSmall>
          <Text.Small>{openConfig.canOpenViaApi ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canOpenAPIPartial')}
          </AppText.ExtraSmall>
          <Text.Small>{openConfig.canOpenPartialViaApi ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canOpenAPIAllowCustomer')}
          </AppText.ExtraSmall>
          <Text.Small>{openConfig.allowCustomerToAutoOpen ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canOpenAPIAllowCustomerPartial')}
          </AppText.ExtraSmall>
          <Text.Small>
            {openConfig.allowCustomerToAutoOpenPartial ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
      </VStack>
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canOpenAfterModify')}
          </AppText.ExtraSmall>
          <Text.Small>{openConfig.canOpenAfterModify ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.requiresPrintToOpen')}
          </AppText.ExtraSmall>
          <Text.Small>{openConfig.requiresPrintForOpen ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.openValidityDays')}
          </AppText.ExtraSmall>
          <Text.Small>{openConfig.openValidityDays}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall color="monochrome-extraDark">
            {translate('companyConfig.openValidityFrom')}
          </AppText.ExtraSmall>
          <View style={{ minWidth: 10 }} />
          <Text.Small flex={1} numberOfLines={1} align="right">
            {openConfig.openValidityFrom}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.openValidityTillEnd')}
          </AppText.ExtraSmall>
          <Text.Small>
            {openConfig.openValidityTillEndOfPurchaseYear ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canUseDiffRoute')}
          </AppText.ExtraSmall>
          <Text.Small>
            {openConfig.canSubstituteDifferentRoute ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.canUseDiffPassenger')}
          </AppText.ExtraSmall>
          <Text.Small>
            {openConfig.canSubstituteDifferentPersons ? 'TRUE' : 'FALSE'}
          </Text.Small>
        </HStack>
      </VStack>
    </SwitchStack>
  );
};
