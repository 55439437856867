import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { Field } from '~/components/Card/Field';
import { LiveDateText } from '~/components/DateText';
import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { PurchaseTrackingInfo } from '~/screens/Support/Purchases/PurchaseItem';
import { translate } from '~/utils/translation.utils';

const ModalKey = 'usePurchaseTrackingInfo';

export const usePurchaseTrackingInfo = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (purchaseTrackingInfo: PurchaseTrackingInfo) => {
      openModal(
        <PurchaseTrackingInfoModal purchaseTrackingInfo={purchaseTrackingInfo} />,
        {
          key: ModalKey,
          type: 'action-sheet-full',
        },
      );
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

const PurchaseTrackingInfoModal = ({
  purchaseTrackingInfo,
}: {
  purchaseTrackingInfo: PurchaseTrackingInfo;
}) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container w={500}>
      <Modal.Header
        onPress={() => {
          closeModal(ModalKey);
        }}
        title={translate('purchases.trackingInfo.trackingInfo')}
      />
      <Divider hairline />
      <ModalScrollView>
        <PurchaseTrackingInfoCard purchaseTrackingInfo={purchaseTrackingInfo} />
      </ModalScrollView>
    </Modal.Container>
  );
};

const PurchaseTrackingInfoCard = ({
  purchaseTrackingInfo,
}: {
  purchaseTrackingInfo: PurchaseTrackingInfo;
}) => {
  const trackId = purchaseTrackingInfo?.trackId;
  const etoNotification = purchaseTrackingInfo?.etoNotification;
  const etaNotification = purchaseTrackingInfo?.etaNotification;
  const actionNotification = purchaseTrackingInfo?.actionNotification;
  const trackIdTimestampSet = purchaseTrackingInfo?.trackIdTimestampSet;

  return (
    <VStack flex={isWeb ? 1 : undefined} space="2.5" align="flex-start">
      <VStack p="2.5">
        {trackId && (
          <Field
            label={translate('purchases.trackingInfo.trackId')}
            value={trackId}
            valueType="small"
            vertical
          />
        )}
        {trackIdTimestampSet && (
          <LiveDateText
            align="left"
            dateTime={trackIdTimestampSet}
            formatOption="fullDateTime"
            type="extraSmall"
            color="monochrome-mid"
          />
        )}
      </VStack>
      <Divider hairline />
      <TrackingField trackingMethod={actionNotification} type={'actionNotification'} />
      <Divider hairline />
      <TrackingField trackingMethod={etoNotification} type={'etoNotification'} />
      <Divider hairline />
      <TrackingField trackingMethod={etaNotification} type={'etaNotification'} />
    </VStack>
  );
};

const TrackingField = ({
  trackingMethod,
  type,
}: {
  trackingMethod?: {
    notification?: boolean;
    sms?: boolean;
    message?: string;
    timestamp?: string;
  };
  type: 'etaNotification' | 'etoNotification' | 'actionNotification';
}) => {
  return (
    <VStack p="2.5">
      <Text.Small color="monochrome-dark">
        {translate(`purchases.trackingInfo.${type}`)}
      </Text.Small>
      <Text.Small align="left">{trackingMethod?.message || '-'}</Text.Small>
      {trackingMethod?.timestamp && (
        <LiveDateText
          align="left"
          dateTime={trackingMethod.timestamp}
          formatOption="fullDateTime"
          type="extraSmall"
          color="monochrome-mid"
        />
      )}
      {(trackingMethod?.notification || trackingMethod?.sms) && (
        <>
          <Text.Small align="left" color="monochrome-dark">
            {translate('purchases.notifyingMethod')}
          </Text.Small>
          <HStack>
            {trackingMethod?.notification && (
              <>
                <Icon size="small" color="monochrome" icon="bell" />
              </>
            )}
            {trackingMethod?.sms && (
              <>
                <Icon size="small" color="monochrome" icon="phone" />
              </>
            )}
          </HStack>
        </>
      )}
    </VStack>
  );
};
