import { useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { useEffect } from 'react';
import { BackHandler } from 'react-native';

export const useAndroidBackModal = (onBack?: () => void) => {
  const { modals, closeModal } = useModal();
  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      if (modals.length) {
        const key = modals[modals.length - 1].key;
        closeModal(key);
        return true;
      }
      if (onBack) {
        onBack();
        return true;
      }
      return false;
    });
    return () => {
      return backHandler.remove();
    };
  }, [modals]);
};
