import { IconButton } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import {
  HStack,
  StackProps,
  VStack,
} from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Image } from 'react-native';

import { FlatList, ListRenderItem } from '~/components/FlatList/FlatList';
import { PageContainer } from '~/components/Page/Page.Container';
import { PageHeader } from '~/components/Page/Page.Header';
import { companyConfigApi } from '~/screens/Configs/Company/CompanyConfigs.api';
import {
  HistoryCredits,
  useFullHistory,
} from '~/screens/Finance/CreditLine/CreditLine.History';
import { useCreditLineStore } from '~/screens/Finance/CreditLine/CreditLine.store';
import {
  CREDIT_COMPANIES,
  CREDIT_KEY,
  HistoryCreditItem,
  saveCreditLineItem,
} from '~/screens/Finance/CreditLine/CreditLine.utils';
import { financeApi } from '~/screens/Finance/Finance.api';
import {
  ccyToSymbolDictionary,
  getPriceWithSymbol,
} from '~/screens/Support/Purchases/purchase.utils';
import { translate } from '~/utils/translation.utils';
import { getLocalStorageItem } from '~/utils/utils.helper';

const BorderedContainer = (props: StackProps) => (
  <HStack
    {...props}
    space="3"
    b={1}
    br={16}
    bc="monochrome-extraLight"
    p="3"
    bg="white"
  />
);

export const CreditLineScreen = () => {
  const globalRefreshRef = useRef<{ [key: string]: () => void }>({});

  const renderItem: ListRenderItem<{ id: string }> = useCallback((item) => {
    return <CompanyCreditItem companyCode={item.id} refreshRef={globalRefreshRef} />;
  }, []);

  return (
    <PageContainer>
      <PageHeader
        title={translate('operators.creditLine')}
        withBack={isNativeMobile}
        childRight={
          <HStack space="6">
            <TotalCredit />
            <IconButton
              icon="refresh"
              onPress={() => {
                Object.values(globalRefreshRef.current).forEach((cb) => cb());
              }}
            />
          </HStack>
        }
      />

      <VStack py="3" flex={1}>
        <FlatList data={CREDIT_COMPANIES} renderItem={renderItem} simple />
      </VStack>
    </PageContainer>
  );
};

const TotalCredit = () => {
  const currencyTotals = useCreditLineStore((s) =>
    Object.keys(s.total).map((currencyKey) => ({
      ccy: currencyKey,
      total: s.total[currencyKey],
    })),
  );

  return (
    <HStack space="3">
      {currencyTotals.map(({ ccy, total }) => {
        return (
          <VStack space="0.5" key={ccy}>
            <Text.Small align="center">{`${translate(
              'financials.creditLines.total',
            )} ${ccy}`}</Text.Small>
            <Text.Body2Medium align="center">
              {getPriceWithSymbol(total, ccyToSymbolDictionary[ccy], {
                thousandsComma: true,
              })}
            </Text.Body2Medium>
          </VStack>
        );
      })}
    </HStack>
  );
};

const CompanyCreditItem = ({
  companyCode,
  refreshRef,
}: {
  companyCode: string;
  refreshRef: MutableRefObject<{
    [key: string]: () => void;
  }>;
}) => {
  const { data: companyInfo, isLoading: isCompanyInfoLoading } =
    companyConfigApi.useGetFerryCompanyConfigQuery(companyCode);

  const setCreditLine = useCreditLineStore((s) => s.setCreditLine);
  const credit: number | undefined = useCreditLineStore(
    (s) => s.creditLine[companyInfo?.config.currency ?? '']?.[companyCode],
  );
  const [loading, setLoading] = useState(false);

  const [fetchFinancial, { isLoading: isCompanyCreditLoading, requestId }] =
    financeApi.useLazyGetOperatorFinancialCreditLineQuery();

  refreshRef.current[companyCode] = () => {
    obtainCachedFinancial(false);
  };

  const obtainCachedFinancial = async (onMount: boolean) => {
    const localHistory = await getLocalStorageItem<HistoryCreditItem[]>(
      CREDIT_KEY + companyCode,
    );

    const lastItem = localHistory?.[localHistory.length - 1];

    if (lastItem && onMount) {
      setCreditLine(companyCode, lastItem.credit, lastItem.ccy);
    } else {
      const response = await fetchFinancial(companyCode);
      handleResponse({
        response,
        disableSuccessToast: true,
        onSuccess: ({ data }) => {
          if (typeof data?.credit === 'number') {
            setCreditLine(companyCode, data.credit, data.ccy);
            saveCreditLineItem({
              creditData: data,
              localHistoryCached: localHistory,
            });
          }
        },
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    obtainCachedFinancial(true).finally(() => {
      setLoading(false);
    });
  }, [companyCode]);

  const fullHistory = useFullHistory();

  return (
    <DelayRender isLoading={loading || isCompanyCreditLoading || isCompanyInfoLoading}>
      {typeof credit === 'number' && companyInfo ? (
        <HStack space="3" align="flex-start" p="2.5">
          <BorderedContainer flex={isNativeMobile ? 1 : undefined}>
            <Image
              source={{
                uri:
                  companyInfo.config.id === 'KRS'
                    ? 'https://www.liknoss.com/themes/liknoss/images/liknoss_logo.jpg'
                    : companyInfo.config.imgUrl ||
                      `https://nisea.azureedge.net/nisea-cdn/ferryLogos/${getCompanyFerryName(
                        companyInfo.config.id,
                        companyInfo.config.name,
                      ).replace(
                        /\s/g,
                        '',
                      )}.jpg?sv=2018-03-28&ss=f&srt=o&sp=r&se=2034-07-05T00:01:43Z&st=2019-07-04T16:01:43Z&spr=https&sig=0noyFbDephG4BIUA2jHxJsIDRDfP3CWaQtnVVHOwQq0%3D`,
              }}
              style={{ width: 50, height: 50, borderRadius: 12 }}
              resizeMode="contain"
            />
            <VStack space="1" flex={1}>
              <Text.Body1Medium>
                {getCompanyFerryName(companyInfo.config.id, companyInfo.config.name)}{' '}
                {companyInfo.config.id !== 'KRS' ? `(${companyInfo.config.id})` : null}
              </Text.Body1Medium>

              <VStack space="2">
                <Text.Body2Regular>
                  {`${translate('financials.creditLines.credit')} `}
                  <Text.Body2Medium color="primary-mid">
                    {getPriceWithSymbol(
                      credit,
                      ccyToSymbolDictionary[companyInfo.config.currency],
                      { thousandsComma: true },
                    )}
                  </Text.Body2Medium>
                </Text.Body2Regular>

                <DottedLine />
                <HistoryCredits companyCode={companyCode} key={String(requestId)} />
              </VStack>
            </VStack>

            <IconButton
              isLoading={isCompanyCreditLoading}
              icon="history"
              color="secondary"
              onPress={() => {
                fullHistory.open(companyCode);
              }}
            />
            <IconButton
              isLoading={isCompanyCreditLoading}
              icon="refresh"
              onPress={() => {
                refreshRef.current[companyCode]?.();
              }}
            />
          </BorderedContainer>
        </HStack>
      ) : null}
    </DelayRender>
  );
};

function getCompanyFerryName(companyCode: string, companyName: string) {
  switch (companyCode) {
    case 'AFD':
    case 'AGD':
      return 'Aegean Flying Dolphins';
    case 'ATC':
      return 'Blue Star Ferries';
    case 'ANSF':
      return 'ANEKLines';
    case 'LVF':
    case 'LFG':
      return 'Levante Ferries';
    case 'KRS':
      return 'Liknoss';
    default:
      return companyName;
  }
}
