import { CustomComponentProps } from '@droplet-tech-code/core-common-types';
import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { getInitialValue } from '@droplet-tech-code/core-elements/module/ui/Inputs/Inputs.utils';
import { GoogleMaps } from '@droplet-tech-code/core-elements/module/ui/Maps';
import { useEffect, useState } from 'react';

export const FormCoordinates = (props: CustomComponentProps<any>) => {
  const [coordinates, setCoordinates] = useState<Coordinates | undefined>(
    getInitialValue(props),
  );

  useEffect(() => {
    (props.form as FormHandler).registerInput({
      id: props.config.id,
      type: props.config.type,
      input: {
        getValue: () => coordinates,
        setValue: (coords) => setCoordinates(coords),
        isValid: () => true,
      },
    });
  }, [coordinates]);

  return (
    <GoogleMaps
      height={500}
      webApiKey={'https://maps.googleapis.com/maps/api/js?key=' + __GOOGLE_KEY__}
      onPress={(event) => {
        const { lat, lng } = event;
        setCoordinates({ latitude: lat, longitude: lng });
      }}
      markers={
        coordinates
          ? [
              {
                lat: Number(coordinates.latitude.toFixed(4)),
                lng: Number(coordinates.longitude.toFixed(4)),
                label: `Lat: ${coordinates.latitude}, Lon: ${coordinates.longitude}`,
              },
            ]
          : undefined
      }
    />
  );
};

interface Coordinates {
  latitude: number;
  longitude: number;
}

// export function isValidCoordinates(coords: Coordinates | undefined) {
//   return true;
// }
