import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { EventListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback } from 'react';

import { PaginatedFlatList } from '~/components/FlatList/FlatList.Paginated';
import { PageContentWide } from '~/components/Page/Page.Header';
import { eventsApi } from '~/screens/Events/Events.api';
import { translate } from '~/utils/translation.utils';

import { EventsListItemComponent } from './Events.ListItem';

export const EventList = ({
  onPress,
}: {
  onPress: (v: EventListItemResponse) => void;
}) => {
  const { spacing } = useAppTheme();

  const renderItem = useCallback((event: EventListItemResponse) => {
    return <EventsListItemComponent key={event.id} event={event} />;
  }, []);

  return (
    <View
      flex={1}
      row={isWeb}
      style={[
        isWeb && {
          // maxWidth: 805,
          width: '100%',
          marginHorizontal: 'auto',
          flexDirection: 'row',
        },
      ]}
    >
      <VStack flex={1}>
        {/* {isWeb ? (
          <View px="3" mt="6" bg="error-light">
            <PageContentWide title={translate("events.events")} />
          </View>
        ) : null} */}

        <PaginatedFlatList
          listId="Events"
          feedback={translate('events.noEvents')}
          hook={eventsApi.useGetEventListQuery}
          pageSize={20}
          getId={(item) => item.id}
          showsVerticalScrollIndicator={isWeb}
          contentContainerStyle={[
            isWeb && { alignSelf: 'center' },
            { paddingHorizontal: spacing[3] },
          ]}
          renderItem={renderItem}
          onPressItem={onPress}
          stickyHeaderIndices={[0]}
          ListHeaderComponent={
            <View bg="white">
              <PageContentWide title={translate('events.events')} />
            </View>
          }
          // StickyHeaderComponent={() => {
          //   return <PageContentWide title={translate("events.events")} />;
          // }}
        />
      </VStack>
    </View>
  );
};
