import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { FerryCompanyDiscount } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { useUpdateDiscountConfigModal } from '~/components/Config/CompanyConfig/Modals/Tickets/UpdateDiscountModal';
import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const DiscountConfig = ({
  companyId,
  check,
  discountConfig,
}: {
  companyId: string;
  check: string;
  discountConfig: FerryCompanyDiscount;
}) => {
  const { name, disabled } = discountConfig;
  const { open: openUpdateDiscountModal } = useUpdateDiscountConfigModal();
  return (
    <CardWithHeader
      headerText={name}
      iconRightType="edit"
      iconRightOnPress={() =>
        openUpdateDiscountModal({
          companyId,
          discountCode: discountConfig.code,
          check,
        })
      }
      iconLeftType={disabled ? 'disconnected' : undefined}
    >
      <VStack p="2.5">
        <DiscountInfo discountConfig={discountConfig} />
        <ConfigListItem
          text={translate('companyConfig.operationsCancellationsMod')}
          iconRightType="edit"
          onPress={() => {}}
        />
      </VStack>
    </CardWithHeader>
  );
};

const DiscountInfo = ({ discountConfig }: { discountConfig: FerryCompanyDiscount }) => {
  const {
    name,
    code,
    percentage,
    appliesToSpecificTicketIds,
    requiresExtraInputString,
    requiresLoyalty,
    requiresData,
    showInStatic,
  } = discountConfig;

  return (
    <SwitchStack py="2.5" space="2.5">
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.companyCode')}
          </AppText.ExtraSmall>
          <Text.Small>{code}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.name')}
          </AppText.ExtraSmall>
          <Text.Small>{name}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.percentage')}
          </AppText.ExtraSmall>
          <Text.Small>{`${percentage}%`}</Text.Small>
        </HStack>
        {appliesToSpecificTicketIds ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.DataId')}
            </AppText.ExtraSmall>
            <Text.Small>{`[${appliesToSpecificTicketIds?.join(',')}]`}</Text.Small>
          </HStack>
        ) : (
          <></>
        )}
      </VStack>
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.requiresExtraInput')}
          </AppText.ExtraSmall>
          <Text.Small>{requiresExtraInputString ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.requiresLoyalty')}
          </AppText.ExtraSmall>
          <Text.Small>{requiresLoyalty ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.requiresData')}
          </AppText.ExtraSmall>
          <Text.Small>{requiresData ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.showInStatic')}
          </AppText.ExtraSmall>
          <Text.Small>{showInStatic ? 'TRUE' : 'FALSE'}</Text.Small>
        </HStack>
      </VStack>
    </SwitchStack>
  );
};
