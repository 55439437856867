import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { SupportRequestBackendClient } from '@naus-code/naus-admin-types';

import { translate } from '~/utils/translation.utils';

import { AppText } from '../Text';

export const RatingInfo = ({
  feedback,
}: {
  feedback: SupportRequestBackendClient['feedback'];
}) => {
  const { rating, timestamp } = feedback!;
  return (
    <VStack space="2.5">
      <HStack>
        <AppText.ExtraSmall color="monochrome-extraDark" flex={1}>
          {translate('supportRequests.feedback')}
        </AppText.ExtraSmall>
        <Stars number={rating} />
      </HStack>
      {feedback?.review && (
        <AppText.ExtraSmall color="black">{`"${feedback.review}"`}</AppText.ExtraSmall>
      )}
      <HStack>
        <View flex={1} />
        <AppText.ExtraSmall color="monochrome-extraDark">{timestamp}</AppText.ExtraSmall>
      </HStack>
    </VStack>
  );
};

const Stars = ({ number }: { number: number }) => {
  const placeholderArr = new Array(number).fill(0);
  return (
    <HStack>
      {placeholderArr.map((_index) => {
        return <Icon icon="star-filled" size="small" color="monochrome" />;
      })}
    </HStack>
  );
};
