import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { FerryCompanyExtraTicketConfig } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { useUpdateExtraTicketConfigModal } from '~/components/Config/CompanyConfig/Modals/Tickets/UpdateExtraTicketModal';
import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const ExtraConfig = ({
  check,
  companyId,
  extraConfig,
}: {
  check: string;
  companyId: string;
  extraConfig: FerryCompanyExtraTicketConfig;
}) => {
  const { name, category, disabled } = extraConfig;
  const { open: openUpdateVehicleConfig } = useUpdateExtraTicketConfigModal();
  return (
    <CardWithHeader
      headerText={`[${category}] - ${name}`}
      iconRightType="edit"
      iconRightOnPress={() => {
        openUpdateVehicleConfig({
          companyId,
          check,
          extraTicketCode: extraConfig.code,
        });
      }}
      iconLeftType={disabled ? 'disconnected' : undefined}
    >
      <VStack p="2.5">
        <ExtraInfo extraConfig={extraConfig} />
        <ConfigListItem
          text={translate('companyConfig.operationsCancellationsMod')}
          iconRightType="edit"
          onPress={() => {}}
        />
      </VStack>
    </CardWithHeader>
  );
};

const ExtraInfo = ({ extraConfig }: { extraConfig: FerryCompanyExtraTicketConfig }) => {
  const { name, code, dataConfigId, category, customExtras, compatiblePassengerAccIds } =
    extraConfig;

  return (
    <SwitchStack py="2.5" space="2.5">
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.companyCode')}
          </AppText.ExtraSmall>
          <Text.Small>{code}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.name')}
          </AppText.ExtraSmall>
          <Text.Small>{name}</Text.Small>
        </HStack>
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.DataId')}
          </AppText.ExtraSmall>
          <Text.Small>{dataConfigId}</Text.Small>
        </HStack>
      </VStack>
      <VStack flex={isWeb ? 1 : undefined} space="2.5">
        <HStack>
          <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
            {translate('companyConfig.category')}
          </AppText.ExtraSmall>
          <Text.Small>{`${category}`}</Text.Small>
        </HStack>

        {customExtras ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.customExtras')}
            </AppText.ExtraSmall>
            <Text.Small>{`[${customExtras.join(',')}]`}</Text.Small>
          </HStack>
        ) : null}
        {compatiblePassengerAccIds ? (
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.customExtras')}
            </AppText.ExtraSmall>
            <Text.Small>{`[${compatiblePassengerAccIds.join(',')}]`}</Text.Small>
          </HStack>
        ) : null}
      </VStack>
    </SwitchStack>
  );
};
