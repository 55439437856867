import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { VesselListItemResponse } from '@naus-code/naus-admin-types';
import { Image, Pressable } from 'react-native';

export interface VesselListItemComponentProps {
  vessel: VesselListItemResponse;
  onPress: () => void;
}

export const VesselListItemComponent = ({
  vessel,
  onPress,
}: VesselListItemComponentProps) => {
  const { shading } = useAppTheme();
  return (
    <Pressable onPress={onPress}>
      {({ hovered }: ButtonInteractionState) => {
        return (
          <View
            br={10}
            bg={hovered ? 'primary-base' : 'white'}
            style={[{ overflow: 'hidden' }, shading.light]}
          >
            <VesselListItemInternalComponent vessel={vessel} />
          </View>
        );
      }}
    </Pressable>
  );
};

const VesselListItemInternalComponent = ({
  vessel,
}: {
  vessel: VesselListItemResponse;
}) => {
  const { id, title, operator, operatorImg } = vessel;
  const imageUrl = operatorImg;
  return (
    <HStack p="3">
      <VesselImage imageUrl={imageUrl} />
      <VStack px="2.5" py="1" flex={1} style={{ alignSelf: 'stretch' }}>
        <Text.Small flex={1} bold numberOfLines={1}>
          {`${title} [${id}]`}
        </Text.Small>
        <Text.Small>{operator}</Text.Small>
        <View flex={1}></View>
      </VStack>
    </HStack>
  );
};

const VesselImage = ({ imageUrl }: { imageUrl?: string }) => {
  return (
    <View>
      <Image
        source={{
          uri: imageUrl || 'https://picsum.photos/200/300',
        }}
        style={{ width: 70, height: 70, borderRadius: 12 }}
        resizeMode="contain"
      />
    </View>
  );
};
