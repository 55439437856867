import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { CompanyContactDetail } from '@naus-code/naus-server-common-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const AgencyContactDetailsCard = ({
  agencyContactDetails,
}: {
  agencyContactDetails: CompanyContactDetail[];
}) => {
  if (agencyContactDetails.length === 0) {
    return null;
  }
  return (
    <CardWithHeader
      headerText={translate('companyConfig.agencyContactDetails')}
      iconRightType="edit"
      iconRightOnPress={() => {}}
    >
      <VStack space="2.5">
        {agencyContactDetails.map((contact, index) => {
          return (
            <HStack key={index}>
              <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
                {contact.title}
              </AppText.ExtraSmall>
              <Text.Small>{contact.data}</Text.Small>
            </HStack>
          );
        })}
      </VStack>
    </CardWithHeader>
  );
};
