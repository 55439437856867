import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';

import { ConfigListItem } from '~/components/Config/ConfigListItem';
import { ConfigListSectionTitle } from '~/components/Config/ConfigLitst.SectionTitle';
import {
  useAddTermCategory,
  useUpdateCompanyTermsConfig,
  useUpdatePrivacyTermsConfig,
} from '~/components/Config/SystemConfig/Modals/Terms/UpdateSystemTerms';
import { translate } from '~/utils/translation.utils';

import { systemConfigApi } from '../systemConfigs.api';

export const TermsConfig = () => {
  const { data } = systemConfigApi.useGetSystemConfigQuery();
  const { open: openCompanyTerms } = useUpdateCompanyTermsConfig();
  const { open: openPrivacyTerms } = useUpdatePrivacyTermsConfig();
  const { open: openAddTermCategory } = useAddTermCategory({
    check: data?.check,
  });
  if (data === undefined) {
    return null;
  }
  return (
    <VStack space="2.5">
      <ConfigListSectionTitle>
        <Text.Small color="monochrome-mid">{translate('systemConfig.terms')}</Text.Small>
      </ConfigListSectionTitle>
      <ConfigListItem
        text={translate('systemConfig.addTermCategory')}
        iconRightType="plus"
        onPress={() =>
          openAddTermCategory(
            data.config.operationConfig.languagesAccepted.map((lang) => lang.text['en']),
          )
        }
      />
      <ConfigListItem
        text={translate('systemConfig.companyTerms')}
        iconRightType="edit"
        onPress={() => openCompanyTerms()}
      />
      <ConfigListItem
        text={translate('systemConfig.privacyTerms')}
        iconRightType="edit"
        onPress={() => openPrivacyTerms()}
      />
      <ConfigListItem
        text={translate('systemConfig.pickupServiceTerms')}
        iconRightType="edit"
      />
      <ConfigListItem
        text={translate('systemConfig.travelGuiderTerms')}
        iconRightType="edit"
      />
      <ConfigListItem
        text={translate('systemConfig.travelPackTerms')}
        iconRightType="edit"
      />
    </VStack>
  );
};
