import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import {
  GetPurchaseResponse,
  PurchaseGetCancelCalculationResponse,
  SupportRequestBackendClient,
} from '@naus-code/naus-admin-types';
import { MutableRefObject } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

export const ModalActionPanelCancelKey = 'ActionPanelCancel';

export interface CancelPurchaseProps {
  purchaseId: string;
  supportRequest: SupportRequestBackendClient;
  purchase: GetPurchaseResponse['purchase'];
}

export interface CancelPurchasePropsNative {
  purchaseId: string;
  supportRequest: SupportRequestBackendClient;
  purchase: GetPurchaseResponse['purchase'];
  checkedItems: string[];
  setCheckedItems: (checkedItems: string[]) => void;
}

export interface CancelPurchaseFooterPropsNative {
  purchaseId: string;
  supportRequest: SupportRequestBackendClient;
  purchase: GetPurchaseResponse['purchase'];
  currentPage: number;
  setCurrentPage: (number) => void;
}

export type CancellationMethod =
  | 'visual'
  | 'customer'
  | 'provider'
  | 'customer_and_provider';

class CancelPurchaseManager {
  state = this._assignState();
  private _assignState() {
    return createGlobalState<{
      type: CancellationMethod | undefined;
      selectedTickets: string[];
    }>({
      type: 'customer_and_provider',
      selectedTickets: [],
    });
  }
  reset() {
    this.state = this._assignState();
  }
}

export interface CancelPurchaseForm {
  formHandlerRef: MutableRefObject<FormHandler>;
  purchaseCalculation?: PurchaseGetCancelCalculationResponse;
  supportRequest?: SupportRequestBackendClient;
}

export const cancelPurchaseManager = new CancelPurchaseManager();

export const getCancelRefundType = (
  supportRequest: SupportRequestBackendClient | undefined,
) => {
  if (
    supportRequest?.requestOptions?.type === 'cancel' ||
    supportRequest?.requestOptions?.type === 'cancel_v2'
  ) {
    const method = supportRequest?.requestOptions?.refundMethod;

    if (method.type === 'voucher') {
      return 'voucher' as const;
    }
    if (method.type === 'bank') {
      return 'bank' as const;
    }
  }
};

export const getServiceFee = (
  refundMethod: 'bank' | 'voucher' | undefined,
  props: CancelPurchaseForm,
) => {
  if (refundMethod === 'bank') {
    return props.purchaseCalculation?.serviceRefundFee;
  }

  if (refundMethod === 'voucher') {
    return props.purchaseCalculation?.serviceFeeVoucher;
  }
};
