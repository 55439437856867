import { LoadingIndicator } from '@droplet-tech-code/core-elements/module/ui/Loading';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportConversation } from '@naus-code/naus-client-types';
import { Image } from 'react-native';

import { SrcImageProvider } from '../ChatImage';

export const OpenImageModalKey = 'AddModal-OpenImage';

const OpenImageModal = ({
  imgUrl,
  chat,
}: {
  imgUrl: string;
  chat: SupportConversation;
}) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <VStack style={{ maxWidth: 1000 }} flex={1}>
        <Modal.Header
          title={chat.id}
          subtitle={chat.imgIds?.reduce((prev, id) => id + prev, '')}
          divider
          onPress={() => {
            closeModal(OpenImageModalKey);
          }}
        />
        <Modal.Body p="2.5">
          <ImageContainer imgUrl={imgUrl} />
        </Modal.Body>
        {/* <Modal.Footer
          divider
          onCancel={async () => {
            closeModal(OpenImageModalKey);
          }}
        /> */}
      </VStack>
    </Modal.Container>
  );
};

export const useOpenImage = () => {
  const { openModal } = useModal();

  return {
    open: (imgUrl: string, chat: SupportConversation) => {
      openModal(<OpenImageModal imgUrl={imgUrl} chat={chat} />, {
        key: OpenImageModalKey,
        type: 'action-sheet-full',
      });
    },
  };
};

export const ImageContainer = ({ imgUrl }: { imgUrl: string }) => {
  return (
    <SrcImageProvider src={imgUrl}>
      {({ src, loading }) => {
        if (loading) {
          return <LoadingIndicator />;
        }

        if (isNativeMobile) {
          return (
            <Image
              source={{
                uri: src,
              }}
              style={{
                height: 400,
                width: '100%',
              }}
            />
          );
        }

        return (
          <img
            src={src}
            crossOrigin="anonymous"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        );
      }}
    </SrcImageProvider>
  );
};
