import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';
import { translate } from '~/utils/translation.utils';

export const ScheduledChangeHeader = () => {
  return (
    <PageHeader
      title={translate('scheduledChanges.createScheduledChange')}
      withBack={isNativeMobile}
    />
  );
};
