import {
  FormBuilder,
  FormHandler,
} from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { CreateVoucherForCustomerRequest } from '@naus-code/naus-admin-types';
import { useRef } from 'react';

import { FormContent } from '~/components/Form/Form.Container';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { customerApi } from '../utils/Customer.api';

export const AddCustomerVoucherModalKey = 'AddModal-CustomerVoucher';
const AddCustomerVoucherModal = ({ customerId }: { customerId: string }) => {
  const { closeModal } = useModal();
  const formRef = useRef(new FormHandler());

  const [createCustomerVoucher, { isLoading: isCreatingCustomerVoucher }] =
    customerApi.useCreateCustomerVoucherMutation();

  return (
    <>
      <Modal.Header
        title={translate('customer.newVoucher')}
        divider
        onPress={() => {
          closeModal(AddCustomerVoucherModalKey);
        }}
      />
      <Modal.Body p="0">
        <CreateCustomerVoucherForm form={formRef.current} />
      </Modal.Body>
      <Modal.Footer
        divider
        isLoading={isCreatingCustomerVoucher}
        onConfirm={async () => {
          const { valid, value: valueData } =
            formRef.current.getValue<CreateVoucherForCustomerRequest['voucher']>();

          if (valid) {
            const response = await createCustomerVoucher({
              customerId,
              voucher: { ...valueData, value: valueData.value * 100 },
            });
            handleRTKResponse({
              response,
              onSuccess: () => {
                closeModal(AddCustomerVoucherModalKey);
              },
            });
          }
        }}
        onCancel={() => {
          closeModal(AddCustomerVoucherModalKey);
        }}
      />
    </>
  );
};

export const useAddCustomerVoucher = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (customerId: string) => {
      openModal(<AddCustomerVoucherModal customerId={customerId} />, {
        key: AddCustomerVoucherModalKey,
        type: 'action-sheet-full',
      });
    },
    close: () => {
      closeModal(AddCustomerVoucherModalKey);
    },
  };
};

// export const useAddCustomerVoucher = () => {
//   const [createCustomerVoucher, { isLoading: isCreatingCustomerVoucher }] =
//     customerApi.useCreateCustomerVoucherMutation();
//   const { data: formData, isLoading: isLoadingForm } =
//     customerApi.useCreateCustomerVoucherFormConfigQuery();
//   const modalTitle = translate("customer.newVoucher");
//   const { open: openFormModal } = useFormModal();

//   return {
//     open: (customerId: string) => {
//       const onConfirm = (value: CreateVoucherForCustomerRequest["voucher"]) => {
//         createCustomerVoucher({
//           customerId,
//           voucher: { ...value, value: value.value * 100 },
//         });
//       };
//       openFormModal({
//         onConfirm,
//         modalTitle,
//         configForm: formData,
//         LoadingAction: isCreatingCustomerVoucher,
//         LoadingForm: isLoadingForm,
//       });
//     },
//   };
// };

const CreateCustomerVoucherForm = ({ form }: { form: FormHandler }) => {
  const { data: customerCreateVoucherConfig, isLoading: isLoadingVoucherConfig } =
    customerApi.useCreateCustomerVoucherFormConfigQuery();

  return (
    <FormContent
      isLoading={isLoadingVoucherConfig}
      form={customerCreateVoucherConfig}
      flex={1}
    >
      {customerCreateVoucherConfig ? (
        <FormBuilder data={customerCreateVoucherConfig} form={form} />
      ) : null}
    </FormContent>
  );
};
