import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';

import { ModalScrollView } from '~/components/Modals/Modal.utils';
import { translate } from '~/utils/translation.utils';

import { ReservationFromProvider } from '../ReservationFromProvider';

const ModalKey = 'ReservationFromProviderModal';

export const useReservationFromProviderModal = () => {
  const { openModal, closeModal } = useModal();
  return {
    open: (resCode: string, provider: string, ccy: string) => {
      openModal(
        <ReservationFromProviderModal resCode={resCode} provider={provider} ccy={ccy} />,
        {
          key: ModalKey,
          type: 'action-sheet-full',
        },
      );
    },
    close: () => {
      closeModal(ModalKey);
    },
  };
};

const ReservationFromProviderModal = ({
  resCode,
  provider,
  ccy,
}: {
  resCode: string;
  provider: string;
  ccy: string;
}) => {
  const { closeModal } = useModal();

  return (
    <Modal.Container>
      <Modal.Header
        onPress={() => {
          closeModal(ModalKey);
        }}
        title={translate('purchases.purchaseTool.viewReservationFromProvider')}
        divider
      />

      <ModalScrollView>
        <ReservationFromProvider resCode={resCode} provider={provider} ccy={ccy} />
      </ModalScrollView>
    </Modal.Container>
  );
};
