import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { CheckBox } from '@droplet-tech-code/core-elements/module/ui/Checkbox';
import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { SearchBar } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { useCallback, useEffect } from 'react';

import { FlatList, ListRenderItem } from '~/components/FlatList/FlatList';
import { ListItem } from '~/components/FlatList/FlatList.Item';
import { PageContainer } from '~/components/Page/Page.Container';
import { Pill } from '~/components/Pill/Pill';
import { companyDataConfigApi } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/companyDataConfigsApi';
import {
  MissingVehicleItem,
  useMissingVehicleItemModal,
} from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vehicles/MissingVehicleConfigs.Item';
import { useMissingVehicleStore } from '~/screens/Configs/MissingConfigs/MissingCompanyConfigs/Vehicles/MissingVehicleConfigs.store';

export const MissingVehicleQueryList = () => {
  const { data, isLoading } = companyDataConfigApi.useGetMissingVehiclesQuery();

  const setBaseTicket = useMissingVehicleStore((s) => s.setBaseTicket);

  useEffect(() => {
    setBaseTicket(data?.list ?? []);
  }, [data?.list]);

  return (
    <PageContainer>
      <DelayRender isLoading={isLoading}>
        {data ? <MissingVehicleList /> : null}
      </DelayRender>
    </PageContainer>
  );
};

const MissingVehicleList = () => {
  const { spacing } = useAppTheme();
  const renderItem: ListRenderItem<MissingVehicleItem> = useCallback(
    (item) => <MissingVehicleListItem item={item} />,
    [],
  );
  const list = useMissingVehicleStore((s) => s.filteredTickets);

  return (
    <VStack flex={1}>
      <SearchBarContainer />
      <Divider />
      <FlatList
        idKey="key"
        simple
        ListHeaderComponent={<ListHeaderComponent />}
        contentContainerStyle={{
          padding: spacing[3],
        }}
        data={list}
        renderItem={renderItem}
      />
    </VStack>
  );
};

const ListHeaderComponent = () => {
  const baseTickets = useMissingVehicleStore((s) => s.baseTickets);
  const filteredTickets = useMissingVehicleStore((s) => s.filteredTickets);
  const selectedTickets = useMissingVehicleStore((s) => s.selectedTickets);
  return (
    <HStack p="3" space="1.5">
      {filteredTickets.length !== baseTickets.length ? (
        <Text.Small color="monochrome-mid">
          {filteredTickets.length} of {baseTickets.length} missing items
        </Text.Small>
      ) : (
        <Text.Small color="monochrome-mid">{baseTickets.length} missing items</Text.Small>
      )}
      <Text.Small color="monochrome-light">•</Text.Small>
      <Text.Small color="monochrome-mid">{selectedTickets.length} selected</Text.Small>
    </HStack>
  );
};

const SearchBarContainer = () => {
  const setFilteredTickets = useMissingVehicleStore((s) => s.setFilteredTickets);
  const baseTickets = useMissingVehicleStore((s) => s.baseTickets);
  const onSearch = useCallback(
    (filteredData: MissingVehicleItem[]) => setFilteredTickets(filteredData),
    [],
  );
  return (
    <HStack>
      <SearchBar
        inputContainerStyle={{ flex: 1 }}
        data={baseTickets}
        onSearch={onSearch}
      />
    </HStack>
  );
};

const MissingVehicleListItem = ({ item }: { item: MissingVehicleItem }) => {
  const formItem = useMissingVehicleItemModal();
  const onPressItem = useCallback(() => {
    formItem.open(item);
  }, [item]);

  const checkTicket = useMissingVehicleStore((s) => s.checkTicket);
  const isEdited = useMissingVehicleStore((s) => s.editedTickets[item.key]);
  const isChecked = useMissingVehicleStore(
    (s) => (item: MissingVehicleItem) => s.isChecked(item, s.selectedTicketDictionary),
  );

  return (
    <HStack space="3" flex={1} px="3">
      <CheckBox
        checked={isChecked(item)}
        onPress={() => {
          checkTicket(item);
        }}
      />
      <View flex={1}>
        <ListItem onPress={onPressItem}>
          <HStack space="3" flex={1}>
            <Pill color="primary">
              <Text.Small color="primary">
                {item.data.category.join(', ').toLocaleUpperCase()}
              </Text.Small>
            </Pill>
            <Text.Body1Regular>{item.data.name}</Text.Body1Regular>
            <Text.Small color="monochrome-dark">
              {item.data.code} | {item.companyId}
            </Text.Small>
            <View flex={1} />
            {isEdited ? (
              <Pill color="warning-mid">
                <Text.Small bold color="warning-mid">
                  Edited
                </Text.Small>
              </Pill>
            ) : null}
          </HStack>
        </ListItem>
      </View>
    </HStack>
  );
};
