import { notEmpty } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { CompanyDataClientMissingLocationListResponse } from '@naus-code/naus-admin-types';
import { create } from 'zustand';

type TicketList = CompanyDataClientMissingLocationListResponse['list'];
type TicketItem = TicketList[0];

export interface TicketCheckDictionary {
  [key: string]: TicketItem | undefined;
}

export interface MissingLocationState {
  baseTickets: TicketList;
  filteredTickets: TicketList;

  selectedTicketDictionary: TicketCheckDictionary;
  editedTickets: TicketCheckDictionary;
  selectedTickets: TicketList;

  isChecked: (item: TicketItem, dic: TicketCheckDictionary) => boolean;
  checkTicket: (item: TicketItem) => void;
  deselectAll: () => void;
  selectAll: (_: TicketList) => void;
  setBaseTicket: (_: TicketList) => void;
  setFilteredTickets: (_: TicketList) => void;
  editTicket: (newTicket: TicketItem) => void;
}

export const getMissingLocationTicketId = (item: TicketItem) => item.key + item.data.id;

export const useMissingLocationStore = create<MissingLocationState>((set) => ({
  baseTickets: [],
  filteredTickets: [],
  selectedTicketDictionary: {},
  editedTickets: {},
  selectedTickets: [],

  isChecked: (item, selectedTicketDictionary) => {
    return !!selectedTicketDictionary[getMissingLocationTicketId(item)];
  },
  deselectAll: () => set(() => ({ selectedTicketDictionary: {}, selectedTickets: [] })),
  selectAll: (list) =>
    set(() => {
      const selectedTicketDictionary: TicketCheckDictionary = {};
      list.forEach((item) => {
        selectedTicketDictionary[getMissingLocationTicketId(item)] = item;
      });
      return { selectedTicketDictionary, selectedTickets: list };
    }),

  checkTicket: (item) => {
    const ticketId = getMissingLocationTicketId(item);
    set((state) => {
      const selectedTicketDictionary = {
        ...state.selectedTicketDictionary,
        [ticketId]: !state.selectedTicketDictionary[ticketId] ? item : undefined,
      };
      const selectedTickets = Object.keys(selectedTicketDictionary)
        .map((key) => selectedTicketDictionary[key])
        .filter(notEmpty);

      return {
        selectedTickets,
        selectedTicketDictionary,
      };
    });
  },

  setBaseTicket: (baseTickets) => {
    set(() => {
      return {
        selectedTickets: [],
        filteredTickets: [],
        selectedTicketDictionary: {},
        editedTickets: {},
        baseTickets,
      };
    });
  },

  setFilteredTickets: (tickets) => {
    set(() => {
      return {
        filteredTickets: tickets,
        selectedTickets: [],
        selectedTicketDictionary: {},
      };
    });
  },

  editTicket: (ticket) => {
    set((s) => {
      const editedTickets = { ...s.editedTickets };
      editedTickets[ticket.key] = ticket;
      return {
        editedTickets,
        baseTickets: s.baseTickets.map((bTicket) =>
          ticket.key === bTicket.key ? ticket : bTicket,
        ),
      };
    });
  },
}));
