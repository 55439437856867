import {
  LoadingIndicator,
  LoadingIndicatorProps,
} from '@droplet-tech-code/core-elements/module/ui/Loading';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';

export const LoadingPage = (props: LoadingIndicatorProps) => {
  return (
    <View flex={1} style={{ height: '100%' }} align="center" justify="center" bg="white">
      <LoadingIndicator {...props} />
    </View>
  );
};
