import { useAppTheme } from '@droplet-tech-code/core-elements/module/theme';
import { Divider } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { useSearchState } from '@droplet-tech-code/core-elements/module/ui/Search/Search';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { LocationListItemResponse } from '@naus-code/naus-admin-types';
import { useCallback, useRef } from 'react';
import { RefreshControl } from 'react-native';

import { useItemSeparatorComponent } from '~/components/FlatList/FlatList.Common';

import { FlatList } from '../../../components/FlatList/FlatList';
import { useAppNavigation } from '../../../hooks/nav.hook';
import { translate } from '../../../utils/translation.utils';
import { AreaLocationListHeader } from './AreaLocation.ListHeader';
import { AreaLocationListItemComponent } from './AreaLocation.ListItem';
import { areaLocationApi } from './AreaLocations.api';

export interface AreaLocationListProps {
  onPress: (item: LocationListItemResponse) => void;
}

export const AreaLocationListNative = () => {
  const navigate = useAppNavigation();
  return (
    <AreaLocationList
      onPress={(item) => {
        navigate.navigate('AreaLocation-Item', {
          id: item.id,
        });
      }}
    />
  );
};

export const AreaLocationList = (props: AreaLocationListProps) => {
  const { list, Search, isLoading, isFetching, refetch } = useSearchState({
    hook: areaLocationApi.useGetAreaLocationListQuery,
    style: {
      height: 47,
    },
  });

  const selectedRef = useRef('');

  const { spacing } = useAppTheme();

  const renderItem = useCallback((areaLocation) => {
    return (
      <AreaLocationListItemComponent key={areaLocation.id} areaLocation={areaLocation} />
    );
  }, []);

  const ItemSeparatorComponent = useItemSeparatorComponent();

  return (
    <VStack flex={1}>
      <AreaLocationListHeader />
      {Search}
      <Divider />
      <FlatList
        refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={() => refetch()} />
        }
        contentContainerStyle={[
          {
            paddingTop: spacing[2.5],
          },

          isNativeMobile && { paddingHorizontal: spacing[2] },
        ]}
        feedback={translate('areaLocations.emptyAreaLocation')}
        isLoading={isLoading}
        data={list}
        renderItem={renderItem}
        onPressItem={(v) => {
          selectedRef.current = v.id;
          props.onPress(v);
        }}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
    </VStack>
  );
};
